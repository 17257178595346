import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { or, propSatisfies, pathOr } from 'ramda';
import { matchPath } from 'react-router';
import { Bling as GPT } from 'react-gpt';
import { connect } from 'react-redux';

@connect(state => ({
  location: state.router.location,
  routeName: state.App.routeName,
  routeConfig: state.App.routeConfig,
}))
export default class Ad extends Component {
  static propTypes = {
    // id: PropTypes.string,
    sizes: PropTypes.array,
    adSlot: PropTypes.string,
    location: PropTypes.object,
    style: PropTypes.object,
    width: PropTypes.number,
    height: PropTypes.number,
    subscriptionActive: PropTypes.bool,
    noAdOnLoad: PropTypes.bool,
    routeConfig: PropTypes.array,
    routeName: PropTypes.string,
    shouldUpdateAdsException: PropTypes.bool,
    /*
    if you want a key in these props to trigger a refresh,
    add them to customFilterProps() in modules/App/container.js
    before passing them to GPT instance
    */
  };

  shouldComponentUpdate(nextProps) {
    const { shouldUpdateAdsException } = this.props;
    return shouldUpdateAdsException || !this.newHighLevelRoute(nextProps);
  }

  newHighLevelRoute = (nextProps) => {
    const { routeConfig, routeName } = this.props;
    const pathname = pathOr('', ['location', 'pathname'], nextProps);
    const nextRoute = routeConfig
      .find(i => matchPath(pathname.replace('/v2', ''), i));
    return propSatisfies(
      p => p !== routeName,
      'name',
      or(nextRoute, {}),
    );
  }

  render = () => {
    const {
      subscriptionActive,
      noAdOnLoad,
      adSlot,
      height,
      sizes,
      style,
      width,
      location,
    } = this.props;

    const w = width || (sizes && sizes[0]) || 'auto';
    const h = height || (sizes && sizes[1]) || 'auto';

    if (subscriptionActive || noAdOnLoad) {
      return null;
    }
    const adStyle = {
      ...style,
      width: w,
      height: h,
    };

    return (
      <div className='Ad' style={adStyle}>
        <GPT
          renderWhenViewable={false}
          noAdOnLoad={noAdOnLoad}
          location={location}
          adUnitPath={adSlot}
          forceSafeFrame
          slotSize={sizes}
        />
      </div>
    );
  }
}
