// TODO: initialize this as reference data on startup
import { sortByComplexString } from '../../modules/FlightAlerts/redux/Shared/selectors';

const carrierOptionsFromFlightAlertsService = {
  ATT: {
    name: 'AT&T Wireless',
    description: 'AT&T Wireless',
    sms_gateway_url: 'txt.att.net',
    country_code: 'US',
    code: 'ATT',
  },
  CLAROBR: {
    name: 'CLARO (Brazil)',
    description: 'CLARO (Brazil)',
    sms_gateway_url: 'clarotorpedo.com.br',
    country_code: 'BR',
    code: 'CLAROBR',
  },
  QWEST: {
    name: 'Qwest',
    description: 'Qwest',
    sms_gateway_url: 'qwestmp.com',
    country_code: 'US',
    code: 'QWEST',
  },
  SPRINT: {
    name: 'Sprint PCS',
    description: 'Sprint PCS',
    sms_gateway_url: 'messaging.sprintpcs.com',
    country_code: 'US',
    code: 'SPRINT',
  },
  TMOBILE: {
    name: 'T-Mobile',
    description: 'T-Mobile',
    sms_gateway_url: 'tmomail.net',
    country_code: 'US',
    code: 'TMOBILE',
  },
  USCELLULAR: {
    name: 'US Cellular',
    description: 'US Cellular',
    sms_gateway_url: 'email.uscc.net',
    country_code: 'US',
    code: 'USCELLULAR',
  },
  SKYTEL: {
    name: 'Skytel',
    description: 'Skytel',
    sms_gateway_url: 'skytel.com',
    country_code: 'US',
    code: 'SKYTEL',
  },
  ALLTEL: {
    name: 'Alltel Wireless',
    description: 'Alltel Wireless',
    sms_gateway_url: 'message.alltel.com',
    country_code: 'US',
    code: 'ALLTEL',
  },
  VERIZON: {
    name: 'Verizon',
    description: 'Verizon',
    sms_gateway_url: 'vtext.com',
    country_code: 'US',
    code: 'VERIZON',
  },
  CELLONE: {
    name: 'Cellular One',
    description: 'Cellular One',
    sms_gateway_url: 'mobile.celloneusa.com',
    country_code: 'US',
    code: 'CELLONE',
  },
  SUNCOM: {
    name: 'Suncom',
    description: 'Suncom',
    sms_gateway_url: 'tms.suncom.com',
    country_code: 'US',
    code: 'SUNCOM',
  },
  VIRGIN: {
    name: 'Virgin Mobile',
    description: 'Virgin Mobile',
    sms_gateway_url: 'vmobl.com',
    country_code: 'US',
    code: 'VIRGIN',
  },
  ROGERS: {
    name: 'Rogers',
    description: 'Rogers',
    sms_gateway_url: 'pcs.rogers.com',
    country_code: 'CA',
    code: 'ROGERS',
  },
  VODACOM: {
    name: 'Vodacom',
    description: 'Vodacom',
    sms_gateway_url: 'voda.co.za',
    country_code: 'ZA',
    code: 'VODACOM',
  },
  VIRGINCA: {
    name: 'Virgin Mobile (Canada)',
    description: 'Virgin Mobile (Canada)',
    sms_gateway_url: 'vmobile.ca',
    country_code: 'CA',
    code: 'VIRGINCA',
  },
  IWIRELESS: {
    name: 'iWireless',
    description: 'iWireless',
    sms_gateway_url: 'iwirelesshometext.com',
    country_code: 'US',
    code: 'IWIRELESS',
  },
  MTS: {
    name: 'MTS (Canada)',
    description: 'MTS (Canada)',
    sms_gateway_url: 'text.mtsmobility.com',
    country_code: 'CA',
    code: 'MTS',
  },
  YCC: {
    name: 'YCC (Russia)',
    description: 'YCC',
    sms_gateway_url: 'sms.ycc.ru',
    country_code: 'RU',
    code: 'YCC',
  },
  '711SPEAKOUT': {
    name: '7-11 Speakout',
    description: '7-11 Speakout',
    sms_gateway_url: 'cingularme.com',
    country_code: 'US',
    code: '711SPEAKOUT',
  },
  ALASKACOMM: {
    name: 'Alaska Communications',
    description: 'Alaska Communications Systems',
    sms_gateway_url: 'msg.acsalaska.com',
    country_code: 'US',
    code: 'ALASKACOMM',
  },
  BOOST: {
    name: 'Boost Mobile',
    description: 'Boost Mobile',
    sms_gateway_url: 'myboostmobile.com',
    country_code: 'US',
    code: 'BOOST',
  },
  CLARO: {
    name: 'Claro (Nicaragua)',
    description: 'Claro (Nicaragua)',
    sms_gateway_url: 'ideasclaro-ca.com',
    country_code: 'NI',
    code: 'CLARO',
  },
  NETCOM: {
    name: 'Netcom (Norway)',
    description: 'Netcom (Norway)',
    sms_gateway_url: 'sms.netcom.no',
    country_code: 'NO',
    code: 'NETCOM',
  },
  CRICKET: {
    name: 'Cricket',
    description: 'Cricket',
    sms_gateway_url: 'mms.mycricket.com',
    country_code: 'US',
    code: 'CRICKET',
  },
  EMTEL: {
    name: 'Emtel (Mauritius)',
    description: 'Emtel (Mauritius)',
    sms_gateway_url: 'emtelworld.net',
    country_code: 'MU',
    code: 'EMTEL',
  },
  FIDO: {
    name: 'Fido (Canada)',
    description: 'Fido (Canada)',
    sms_gateway_url: 'fido.ca',
    country_code: 'CA',
    code: 'FIDO',
  },
  GLOBALSTAR: {
    name: 'Globalstar',
    description: 'Globalstar',
    sms_gateway_url: 'msg.globalstarusa.com',
    country_code: 'US',
    code: 'GLOBALSTAR',
  },
  HELIO: {
    name: 'Helio',
    description: 'Helio',
    sms_gateway_url: 'messaging.sprintpcs.com',
    country_code: 'US',
    code: 'HELIO',
  },
  IRIDIUM: {
    name: 'Iridium',
    description: 'Iridium',
    sms_gateway_url: 'msg.iridium.com',
    country_code: 'US',
    code: 'IRIDIUM',
  },
  ITCOMPANY: {
    name: 'IT Company (Australia)',
    description: 'IT Company (Australia)',
    sms_gateway_url: 'itcompany.com.au',
    country_code: 'AU',
    code: 'ITCOMPANY',
  },
  METEOR: {
    name: 'Meteor (Ireland)',
    description: 'Meteor (Ireland)',
    sms_gateway_url: 'sms.mymeteor.ie',
    country_code: 'IE',
    code: 'METEOR',
  },
  METROPCS: {
    name: 'MetroPCS',
    description: 'MetroPCS',
    sms_gateway_url: 'mymetropcs.com',
    country_code: 'US',
    code: 'METROPCS',
  },
  MOVICOM: {
    name: 'Movicom (Argentina)',
    description: 'Movicom',
    sms_gateway_url: 'movimensaje.com.ar',
    country_code: 'AR',
    code: 'MOVICOM',
  },
  VIAERO: {
    name: 'Viaero',
    description: 'Viaero Wireless',
    sms_gateway_url: 'viaerosms.com',
    country_code: 'US',
    code: 'VIAERO',
  },
  PRESIDENTS: {
    name: "President's Choice (Canada)",
    description: "President's Choice (Canada)",
    sms_gateway_url: 'txt.bell.ca',
    country_code: 'CA',
    code: 'PRESIDENTS',
  },
  SASKTEL: {
    name: 'Sasktel (Canada)',
    description: 'Sasktel (Canada)',
    sms_gateway_url: 'sms.sasktel.com',
    country_code: 'CA',
    code: 'SASKTEL',
  },
  SOLO: {
    name: 'Solo Mobile (Canada)',
    description: 'Solo Mobile (Canada)',
    sms_gateway_url: 'txt.bell.ca',
    country_code: 'CA',
    code: 'SOLO',
  },
  TELUS: {
    name: 'Telus Mobility (Canada)',
    description: 'Telus Mobility (Canada)',
    sms_gateway_url: 'msg.telus.com',
    country_code: 'CA',
    code: 'TELUS',
  },
  THUMB: {
    name: 'Thumb Cellular',
    description: 'Thumb Cellular',
    sms_gateway_url: 'sms.thumbcellular.com',
    country_code: 'US',
    code: 'THUMB',
  },
  CLARONI: {
    name: 'CLARO (Nicaragua)',
    description: 'CLARO (Nicaragua)',
    sms_gateway_url: 'ideasclaro-ca.com',
    country_code: 'NI',
    code: 'CLARONI',
  },
  TELENOR: {
    name: 'Telenor (Norway)',
    description: 'Telenor (Norway)',
    sms_gateway_url: 'mobilpost.no',
    country_code: 'NO',
    code: 'TELENOR',
  },
  BELL: {
    name: 'Bell Mobility (Canada)',
    description: 'Bell Mobility (Canada)',
    sms_gateway_url: 'txt.bell.ca',
    country_code: 'CA',
    code: 'BELL',
  },
  '3RIVER': {
    name: '3 River Wireless',
    description: '3 River Wireless',
    sms_gateway_url: 'sms.3rivers.net',
    country_code: 'US',
    code: '3RIVER',
  },
  CREDO: {
    name: 'CREDO Mobile',
    description: 'CREDO Mobile',
    sms_gateway_url: 'messaging.sprintpcs.com',
    country_code: 'US',
    code: 'CREDO',
  },
  TMOBILEAT: {
    name: 'T-Mobile (Austria)',
    description: 'T-Mobile (Austria)',
    sms_gateway_url: 'sms.t-mobile.at',
    country_code: 'AU',
    code: 'TMOBILEAT',
  },
  VODAFONEIT: {
    name: 'Vodafone (Italy)',
    description: 'Vodafone (Italy)',
    sms_gateway_url: 'sms.vodafone.it',
    country_code: 'IT',
    code: 'VODAFONEIT',
  },
  VODAFONEJP: {
    name: 'Vodafone (Japan)',
    description: 'Vodafone (Japan)',
    sms_gateway_url: 'c.vodafone.ne.jp',
    country_code: 'JP',
    code: 'VODAFONEJP',
  },
  COMCELCO: {
    name: 'COMCEL (Colombia)',
    description: 'COMCEL (Colombia)',
    sms_gateway_url: 'comcel.com.co',
    country_code: 'CO',
    code: 'COMCELCO',
  },
  VODAFONEDE: {
    name: 'Vodafone (Germany)',
    description: 'Vodafone (Germany)',
    sms_gateway_url: 'vodafone-sms.de',
    country_code: 'DE',
    code: 'VODAFONEDE',
  },
  VODAFONENZ: {
    name: 'Vodafone (NZ)',
    description: 'Vodafone (NZ)',
    sms_gateway_url: 'sms.vodafone.net.nz',
    country_code: 'NZ',
    code: 'VODAFONENZ',
  },
  MATANUSKA: {
    name: 'Matanuska',
    description: 'Matanuska Telephone Association',
    sms_gateway_url: 'sms.mtawireless.com',
    country_code: 'US',
    code: 'MATANUSKA',
  },
  GCI: {
    name: 'GCI',
    description: 'GCI',
    sms_gateway_url: 'mobile.gci.net',
    country_code: 'US',
    code: 'GCI',
  },
  CELLSOUTH: {
    name: 'Cellular South',
    description: 'Cellular South',
    sms_gateway_url: 'csouth1.com',
    country_code: 'US',
    code: 'CELLSOUTH',
  },
  ACS: {
    name: 'American Messaging',
    description: 'American Messaging',
    sms_gateway_url: 'alphapage.myairmail.com',
    country_code: 'US',
    code: 'ACS',
  },
  CINCINNATIBELL: {
    name: 'Cincinnati Bell',
    description: 'Cincinnati Bell',
    sms_gateway_url: 'gocbw.com',
    country_code: 'US',
    code: 'CINCINNATIBELL',
  },
  SUNRISES: {
    name: 'Sunrise Switzerland',
    description: 'Sunrise Switzerland',
    sms_gateway_url: 'gsm.sunrise.ch',
    country_code: 'CH',
    code: 'SUNRISES',
  },
  COMCEL: {
    name: 'Comcel (Colombia)',
    description: 'Comcel',
    sms_gateway_url: 'comcel.com',
    country_code: 'CO',
    code: 'COMCEL',
  },
  COMCELGT: {
    name: 'COMCEL (Guatemala)',
    description: 'COMCEL (Guatemala)',
    sms_gateway_url: 'comcel.com.gt',
    country_code: 'GT',
    code: 'COMCELGT',
  },
  MOVISTAR: {
    name: 'Movistar (Colombia)',
    description: 'Movistar (Colombia)',
    sms_gateway_url: 'movistar.com.co',
    country_code: 'CO',
    code: 'MOVISTAR',
  },
  DIGICELAW: {
    name: 'Digicel (Aruba)',
    description: 'Digicel (Aruba)',
    sms_gateway_url: 'digitextaw.com',
    country_code: 'AW',
    code: 'DIGICELAW',
  },
  DIGICELBB: {
    name: 'Digicel (Barbados)',
    description: 'Digicel (Barbados)',
    sms_gateway_url: 'digitextbb.com',
    country_code: 'BB',
    code: 'DIGICELBB',
  },
  DIGICELBO: {
    name: 'Digicel (Bonaire)',
    description: 'Digicel (Bonaire)',
    sms_gateway_url: 'digitextbo.com',
    country_code: 'BO',
    code: 'DIGICELBO',
  },
  DIGICELKY: {
    name: 'Digicel (Cayman)',
    description: 'Digicel (Cayman)',
    sms_gateway_url: 'digitextky.com',
    country_code: 'KY',
    code: 'DIGICELKY',
  },
  DIGICELCU: {
    name: 'Digicel (Curacao)',
    description: 'Digicel (Curacao)',
    sms_gateway_url: 'digitextcu.com',
    country_code: 'CU',
    code: 'DIGICELCU',
  },
  DIGICELGD: {
    name: 'Digicel (Grenada)',
    description: 'Digicel (Grenada)',
    sms_gateway_url: 'digitextgd.com',
    country_code: 'GD',
    code: 'DIGICELGD',
  },
  DIGICELJM: {
    name: 'Digicel (Jamaica)',
    description: 'Digicel (Jamaica)',
    sms_gateway_url: 'digitextjm.com',
    country_code: 'JM',
    code: 'DIGICELJM',
  },
  DIGICELLC: {
    name: 'Digicel (St. Lucia)',
    description: 'Digicel (St. Lucia)',
    sms_gateway_url: 'digitextlc.com',
    country_code: 'LC',
    code: 'DIGICELLC',
  },
  DIGICELVC: {
    name: 'Digicel (St. Vincent)',
    description: 'Digicel (St. Vincent)',
    sms_gateway_url: 'digitextvc.com',
    country_code: 'VC',
    code: 'DIGICELVC',
  },
  ORANGE: {
    name: 'Orange',
    description: 'Orange',
    sms_gateway_url: 'orange.net',
    country_code: 'FR',
    code: 'ORANGE',
  },
  VIVO: {
    name: 'Vivo (Brazil)',
    description: 'Vivo (Brazil)',
    sms_gateway_url: 'torpedoemail.com.br',
    country_code: 'BR',
    code: 'VIVO',
  },
  VODAPHONEES: {
    name: 'Vodaphone (Spain)',
    description: 'Vodaphone (Spain)',
    sms_gateway_url: 'vodaphone.es',
    country_code: 'ES',
    code: 'VODAPHONEES',
  },
  LIME: {
    name: 'LIME (Cable & Wireless)',
    description: 'LIME (Cable & Wireless)',
    sms_gateway_url: 'txt2lime.com',
    country_code: 'US',
    code: 'LIME',
  },
  TELCEL: {
    name: 'Telcel (Mexico)',
    description: 'Telcel (Mexico)',
    sms_gateway_url: 'itelcel.com',
    country_code: 'MX',
    code: 'TELCEL',
  },
  MOVISTARES: {
    name: 'Movistar (Spain)',
    description: 'Movistar',
    sms_gateway_url: 'correo.movistar.net',
    country_code: 'ES',
    code: 'MOVISTARES',
  },
  SPIKKO: {
    name: 'Spikko (Israel)',
    description: 'Spikko (Israel)',
    sms_gateway_url: 'spikkosms.com',
    country_code: 'IL',
    code: 'SPIKKO',
  },
  NTELOS: {
    name: 'nTelos',
    description: 'nTelos',
    sms_gateway_url: 'pcs.ntelos.net',
    country_code: 'US',
    code: 'NTELOS',
  },
  CLAROAR: {
    name: 'Claro (Argentina)',
    description: 'Claro (Argentina)',
    sms_gateway_url: 'sms.ctimovil.com.ar',
    country_code: 'AR',
    code: 'CLAROAR',
  },
  REDCOAL: {
    name: 'Redcoal (Australia)',
    description: 'Redcoal (Australia)',
    sms_gateway_url: 'redcoal.net',
    country_code: 'AU',
    code: 'REDCOAL',
  },
  VERIZONMMS: {
    name: 'Verizon MMS',
    description: 'Verizon MMS',
    sms_gateway_url: 'vzwpix.com',
    country_code: 'US',
    code: 'VERIZONMMS',
  },
  TRACFONEMMS: {
    name: 'Tracfone MMS',
    description: 'Tracfone MMS',
    sms_gateway_url: 'mmst5.tracfone.com',
    country_code: 'US',
    code: 'TRACFONEMMS',
  },
  ATTMMS: {
    name: 'AT&T MMS',
    description: 'AT&T MMS',
    sms_gateway_url: 'mms.att.net',
    country_code: 'US',
    code: 'ATTMMS',
  },
  JAMES: {
    name: 'James Valley Wireless',
    description: 'James Valley Wireless',
    sms_gateway_url: 'sms.jamesvalley.com',
    country_code: 'US',
    code: 'JAMES',
  },
  COPPERVALLEY: {
    name: 'Copper Valley Telecom',
    description: 'Copper Valley Telecom',
    sms_gateway_url: 'cvwsms.com',
    country_code: 'US',
    code: 'COPPERVALLEY',
  },
  O2: {
    name: 'O2 (UK)',
    description: 'O2 (UK)',
    sms_gateway_url: 'o2.co.uk',
    country_code: 'GB',
    code: 'O2',
  },
  ALLPAGE: {
    name: 'ALL Page Wireless',
    description: 'ALL Page Wireless',
    sms_gateway_url: 'cbeyond.sprintpcs.com',
    country_code: 'US',
    code: 'ALLPAGE',
  },
  PANACEA: {
    name: 'Panacea Mobile',
    description: 'Panacea Mobile',
    sms_gateway_url: 'pi.panaceamobile.com',
    country_code: 'US',
    code: 'PANACEA',
  },
  TELIUSTALK: {
    name: 'TellusTalk',
    description: 'TellusTalk',
    sms_gateway_url: 'esms.nu',
    country_code: 'US',
    code: 'TELIUSTALK',
  },
  KOODOO: {
    name: 'Koodo Mobile',
    description: 'Koodo Mobile',
    sms_gateway_url: 'msg.koodomobile.com',
    country_code: 'CA',
    code: 'KOODOO',
  },
  AMERITECH: {
    name: 'Ameritech',
    description: 'Ameritech',
    sms_gateway_url: 'paging.acswireless.com',
    country_code: 'US',
    code: 'AMERITECH',
  },
  BLUESKY: {
    name: 'Bluesky Communications',
    description: 'Bluesky Communications',
    sms_gateway_url: 'psms.bluesky.as',
    country_code: 'US',
    code: 'BLUESKY',
  },
  CSPIRE: {
    name: 'C Spire Wireless',
    description: 'C Spire Wireless',
    sms_gateway_url: 'cspire1.com',
    country_code: 'US',
    code: 'CSPIRE',
  },
  CLEARTALK: {
    name: 'Cleartalk Wireless',
    description: 'Cleartalk Wireless',
    sms_gateway_url: 'sms.cleartalk.us',
    country_code: 'US',
    code: 'CLEARTALK',
  },
  EDGEWIRELESS: {
    name: 'Edge Wireless',
    description: 'Edge Wireless',
    sms_gateway_url: 'sms.edgewireless.com',
    country_code: 'US',
    code: 'EDGEWIRELESS',
  },
  HAWAIIAN: {
    name: 'Hawaiian Telcom Wireless',
    description: 'Hawaiian Telcom Wireless',
    sms_gateway_url: 'hawaii.sprintpcs.com',
    country_code: 'US',
    code: 'HAWAIIAN',
  },
  POCKET: {
    name: 'Pocket Wireless',
    description: 'Pocket Wireless',
    sms_gateway_url: 'sms.pocket.com',
    country_code: 'US',
    code: 'POCKET',
  },
  SIMPLEMOBILE: {
    name: 'Simple Mobile',
    description: 'Simple Mobile',
    sms_gateway_url: 'smtext.com',
    country_code: 'US',
    code: 'SIMPLEMOBILE',
  },
  WESTCENTRAL: {
    name: 'West Central Wireless',
    description: 'West Central Wireless',
    sms_gateway_url: 'sms.wcc.net',
    country_code: 'US',
    code: 'WESTCENTRAL',
  },
  ALIANT: {
    name: 'Aliant',
    description: 'Aliant',
    sms_gateway_url: 'sms.wirefree.informe.ca',
    country_code: 'CA',
    code: 'ALIANT',
  },
  TRACFONE: {
    name: 'TracFone (prepaid)',
    description: 'TracFone (prepaid)',
    sms_gateway_url: 'mmst5.tracfone.com',
    country_code: 'CA',
    code: 'TRACFONE',
  },
  WINDMOBILE: {
    name: 'Freedom Mobile',
    description: 'Freedom Mobile',
    sms_gateway_url: 'txt.windmobile.ca',
    country_code: 'CA',
    code: 'WINDMOBILE',
  },
  PCTELECOM: {
    name: 'PC Telecom',
    description: 'PC Telecom',
    sms_gateway_url: 'mobiletxt.ca',
    country_code: 'CA',
    code: 'PCTELECOM',
  },
  VODAFONEUK: {
    name: 'Vodafone (UK)',
    description: 'Vodafone (UK)',
    sms_gateway_url: 'vodafone.net',
    country_code: 'GB',
    code: 'VODAFONEUK',
  },
  SASKTEL2: {
    name: 'SaskTel',
    description: 'SaskTel',
    sms_gateway_url: 'sms.sasktel.com',
    country_code: 'CA',
    code: 'SASKTEL2',
  },
  O2DE: {
    name: 'O2 (DE)',
    description: 'O2 (DE)',
    sms_gateway_url: 'o2online.de',
    country_code: 'DE',
    code: 'O2DE',
  },
  ESENDEX: {
    name: 'Esendex',
    description: 'Esendex',
    sms_gateway_url: 'echoemail.net',
    country_code: 'GB',
    code: 'ESENDEX',
  },
  EPLUS: {
    name: 'E-Plus',
    description: 'E-Plus',
    sms_gateway_url: 'smsmail.eplus.de',
    country_code: 'DE',
    code: 'EPLUS',
  },
  BIGREDGIANT: {
    name: 'BigRedGiant Mobile Solutions',
    description: 'BigRedGiant Mobile Solutions',
    sms_gateway_url: 'tachyonsms.co.uk',
    country_code: 'GB',
    code: 'BIGREDGIANT',
  },
  TIM: {
    name: 'TIM',
    description: 'TIM',
    sms_gateway_url: 'timnet.com',
    country_code: 'IT',
    code: 'TIM',
  },
  BOUYGUES: {
    name: 'Bouygues Télécom',
    description: 'Bouygues Télécom',
    sms_gateway_url: 'mms.bouyguestelecom.fr',
    country_code: 'FR',
    code: 'BOUYGUES',
  },
  MOBISTAR: {
    name: 'Mobistar',
    description: 'Mobistar',
    sms_gateway_url: 'mobistar.be',
    country_code: 'BE',
    code: 'MOBISTAR',
  },
  TELIA: {
    name: 'Telia',
    description: 'Telia',
    sms_gateway_url: 'gsm1800.telia.dk',
    country_code: 'DK',
    code: 'TELIA',
  },
  OGVODAFONE: {
    name: 'OgVodafone',
    description: 'OgVodafone',
    sms_gateway_url: 'sms.is',
    country_code: 'IS',
    code: 'OGVODAFONE',
  },
  MOBILIX: {
    name: 'Mobilix',
    description: 'Mobilix',
    sms_gateway_url: 'sms.mobilix.dk',
    country_code: 'DK',
    code: 'MOBILIX',
  },
  SONOFON: {
    name: 'SONOFON',
    description: 'SONOFON',
    sms_gateway_url: 'note.sonofon.dk',
    country_code: 'DK',
    code: 'SONOFON',
  },
  TDC: {
    name: 'TDC (Tele Danmark Communication)',
    description: 'TDC (Tele Danmark Communication)',
    sms_gateway_url: 'sms.tdm.dk',
    country_code: 'DK',
    code: 'TDC',
  },
  SIMINN: {
    name: 'Siminn',
    description: 'Siminn',
    sms_gateway_url: 'box.is',
    country_code: 'IS',
    code: 'SIMINN',
  },
  TIGOLUXEMBOURG: {
    name: 'tigo (Luxembourg)',
    description: 'tigo (Luxembourg)',
    sms_gateway_url: 'sms.tigo.com.co',
    country_code: 'LU',
    code: 'TIGOLUXEMBOURG',
  },
  MEO: {
    name: 'Meo',
    description: 'Meo',
    sms_gateway_url: 'mail.tmn.pt',
    country_code: 'PT',
    code: 'MEO',
  },
  PTLUXEMBOURG: {
    name: 'P&T Luxembourg',
    description: 'P&T Luxembourg',
    sms_gateway_url: 'sms.luxgsm.lu',
    country_code: 'LU',
    code: 'PTLUXEMBOURG',
  },
  BOXINTERNET: {
    name: 'Box Internet Services SMS Gateway',
    description: 'Box Internet Services SMS Gateway',
    sms_gateway_url: 'sms.boxis.net',
    country_code: 'CH',
    code: 'BOXINTERNET',
  },
  SWISSCOM: {
    name: 'swisscom',
    description: 'swisscom',
    sms_gateway_url: 'bluewin.ch',
    country_code: 'CH',
    code: 'SWISSCOM',
  },
  PELEPHONE: {
    name: 'pelephone (Israel)',
    description: 'pelephone (Israel)',
    sms_gateway_url: 'pelephone.net.il',
    country_code: 'IL',
    code: 'PELEPHONE',
  },
  CSL: {
    name: 'CSL',
    description: 'CSL',
    sms_gateway_url: 'mgw.mmsc1.hkcsl.com',
    country_code: 'HK',
    code: 'CSL',
  },
  VODAFONEHJP1: {
    name: 'Vodafone Hokuriku/Central North',
    description: 'Vodafone Hokuriku/Central North',
    sms_gateway_url: 'r.vodafone.ne.jp',
    country_code: 'JP',
    code: 'VODAFONEHJP1',
  },
  VODAFONEJP0: {
    name: 'Vodafone Hokkaido',
    description: 'Vodafone Hokkaido',
    sms_gateway_url: 'd.vodafone.ne.jp',
    country_code: 'JP',
    code: 'VODAFONEJP0',
  },
  CELLCOM: {
    name: 'Cellcom',
    description: 'Cellcom',
    sms_gateway_url: 'cellcom.quiktxt.com',
    country_code: 'US',
    code: 'CELLCOM',
  },
  VODAFONEJP3: {
    name: 'Vodafone Kanto/Koushin/East - including Tokyo',
    description: 'Vodafone Kanto/Koushin/East - including Tokyo',
    sms_gateway_url: 't.vodafone.ne.jp',
    country_code: 'JP',
    code: 'VODAFONEJP3',
  },
  VODAFONEJP2: {
    name: 'Vodafone Kansai/West - including Osaka',
    description: 'Vodafone Kansai/West - including Osaka',
    sms_gateway_url: 'k.vodafone.ne.jp',
    country_code: 'JP',
    code: 'VODAFONEJP2',
  },
  CONSUMERCELLUAR: {
    name: 'Consumer Cellular',
    description: 'Consumer Cellular',
    sms_gateway_url: 'cingularme.com',
    country_code: 'US',
    code: 'CONSUMERCELLUAR',
  },
  VODAFONEJP4: {
    name: 'Vodafone Kyuushu/Okinawa',
    description: 'Vodafone Kyuushu/Okinawa',
    sms_gateway_url: 'q.vodafone.ne.jp',
    country_code: 'JP',
    code: 'VODAFONEJP4',
  },
  VODAFONEJP5: {
    name: 'Vodafone Shikoku',
    description: 'Vodafone Shikoku',
    sms_gateway_url: 's.vodafone.ne.jp',
    country_code: 'JP',
    code: 'VODAFONEJP5',
  },
  VODAFONEJP6: {
    name: 'Vodafone Touhoku/Niigata/North',
    description: 'Vodafone Touhoku/Niigata/North',
    sms_gateway_url: 'h.vodafone.ne.jp',
    country_code: 'JP',
    code: 'VODAFONEJP6',
  },
  VODAFONEJP7: {
    name: 'Vodafone Chuugoku/Western',
    description: 'Vodafone Chuugoku/Western',
    sms_gateway_url: 'n.vodafone.ne.jp',
    country_code: 'JP',
    code: 'VODAFONEJP7',
  },
  WILLCOM: {
    name: 'Willcom',
    description: 'Willcom',
    sms_gateway_url: 'pdx.ne.jp',
    country_code: 'JP',
    code: 'WILLCOM',
  },
  CHINAMOBILE: {
    name: 'china mobile',
    description: 'china mobile',
    sms_gateway_url: '139.com',
    country_code: 'CN',
    code: 'CHINAMOBILE',
  },
  SLINTERACTIVE: {
    name: 'SL Interactive',
    description: 'SL Interactive',
    sms_gateway_url: 'slinteractive.com.au',
    country_code: 'AU',
    code: 'SLINTERACTIVE',
  },
  SMSBROADCAST: {
    name: 'SMS Broadcast',
    description: 'SMS Broadcast',
    sms_gateway_url: 'send.smsbroadcast.com.au',
    country_code: 'AU',
    code: 'SMSBROADCAST',
  },
  SMSCENTRAL: {
    name: 'SMS Central',
    description: 'SMS Central',
    sms_gateway_url: 'sms.smscentral.com.au',
    country_code: 'AU',
    code: 'SMSCENTRAL',
  },
  ILLINOISVC: {
    name: 'Illinois Valley Cellular',
    description: 'Illinois Valley Cellular',
    sms_gateway_url: 'ivctext.com',
    country_code: 'US',
    code: 'ILLINOISVC',
  },
  GOOGLEFI: {
    name: 'Google Fi',
    description: 'Google Fi',
    sms_gateway_url: 'msg.fi.google.com',
    country_code: 'US',
    code: 'GOOGLEFI',
  },
};

const carrierOptions = Object.keys(carrierOptionsFromFlightAlertsService)
  .map(key => ({ value: key, label: carrierOptionsFromFlightAlertsService[key].name }))
  .sort((sortByComplexString('value')));

export default carrierOptions;
