import React, { Component } from 'react';
import PropTypes from 'prop-types';
import componentBase from '../../../../src/lib/component-base';

@componentBase('SubscriptionHeader')
export default class SubscriptionHeader extends Component {
  static propTypes = {
    text: PropTypes.string,
  };

  render() {
    const text = this.props && this.props.text ? this.props.text : 'FlightStats Subscription Signup';
    return (
      <header className='subscription-header'>
        <h1>{text}</h1>
      </header>
    );
  }
}
