import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const LogoLink = (props) => {
  const {
    anchorClass,
    imgClass,
    linkTo,
    logo,
    logoAdditionalOnClick,
    mobileClass,
    reportButtonPress,
  } = props;

  if (linkTo) {
    return (
      <Link
        className={`${anchorClass}-link${mobileClass}`}
        onClick={() => {
          logoAdditionalOnClick && logoAdditionalOnClick();
          reportButtonPress('Logo');
        }}
        to={linkTo}
      >
        <img src={logo} height='75px' className={imgClass} alt='FlightStats logo' />
      </Link>
    );
  }
  return (
    <a className={`${anchorClass}-no-link${mobileClass}`}>
      <img src={logo} className={imgClass} alt='FlightStats logo' />
    </a>
  );
};

LogoLink.propTypes = {
  anchorClass: PropTypes.string,
  imgClass: PropTypes.string,
  linkTo: PropTypes.string,
  logo: PropTypes.string,
  logoAdditionalOnClick: PropTypes.func,
  mobileClass: PropTypes.string,
  reportButtonPress: PropTypes.func,
};

export default LogoLink;
