import premiumIcon from '../../../static/images/account-icons/PREMIUMTag-Icon-SOLID.svg';
import ListIcon from '../../../static/images/subscription-icons/List-Icon.svg';
import depArrIcon from '../../../static/images/menu-icons/DepAr-Icon-WHITE.svg';
import alertIcon from '../../../static/images/menu-icons/FlightAlert-Icon-WHITE.svg';
import historyIcon from '../../../static/images/menu-icons/Historical-Icon-WHITE.svg';
import delayMapIcon from '../../../static/images/menu-icons/DelMa-Icon-WHITE.svg';
import curConIcon from '../../../static/images/menu-icons/CurCon-Icon-WHITE.svg';
import trackerIcon from '../../../static/images/menu-icons/Tracker-Icon-WHITE.svg';
import freeIcon from '../../../static/images/subscription-icons/FREETag-Icon-WHITE.svg';
import saveIcon from '../../../static/images/subscription-icons/SavePref-White.svg';
import standardSubscriptionIcon from '../../../static/images/subscription-icons/Standard-Icon-WHITE.svg';
import professionalSubscriptionIcon from '../../../static/images/subscription-icons/Professional-Icon-WHITE.svg';
import chartIcon from '../../../static/images/subscription-icons/VenDiagram-Icon-WHITE.png';
import globeIcon from '../../../static/images/subscription-icons/WorMa-Icon-White.png';
import statsIcon from '../../../static/images/subscription-icons/Stats-White.svg';
import noAdsIcon from '../../../static/images/subscription-icons/NoADS-Icon.svg';
import historicalFlightIcon from '../../../static/images/subscription-icons/Historical-Flight-WHITE-Icon.svg';
import historicalAirportIcon from '../../../static/images/subscription-icons/Historical-AirportRoute-WHITE-Icon.svg';

import subscriptionLevelIconFree from '../../../static/images/subscription-icons/FREETag-Icon-BLACK.svg';
import subscriptionLevelIconStandard from '../../../static/images/subscription-icons/Standard-Icon-SOLID.svg';
import subscriptionLevelIconPro from '../../../static/images/subscription-icons/Professional-Icon-SOLID.svg';
import subscriptionLevelIconEnterprise from '../../../static/images/subscription-icons/Enterprise-Icon-BLACK.svg';

import dangerIcon from '../../../static/images/subscription-icons/HoldMembership-BLACK-Icon.svg';
import CreditCardIcon from '../../../static/images/CCard-Icon.svg';
import creditTokensIcon from '../../../static/images/subscription-icons/TokenStack-Icon.svg';
import BillingAddressIcon from '../../../static/images/Location-Icon-BLACK.svg';
import viewPaymentHistoryIcon from '../../../static/images/subscription-icons/payment-histories-icon.svg';
import paymentHistoryIcon from '../../../static/images/PaymentHistory-Icon.svg';
import subscriptionIcon from '../../../static/images/subscription-icons/SubscriptionGauge-Icon.svg';

export const levelIcons = {
  0: subscriptionLevelIconFree,
  1: subscriptionLevelIconStandard,
  3: subscriptionLevelIconPro,
  11: subscriptionLevelIconEnterprise,
};

export const icons = {
  alertIcon,
  BillingAddressIcon,
  chartIcon,
  CreditCardIcon,
  creditTokensIcon,
  curConIcon,
  dangerIcon,
  delayMapIcon,
  depArrIcon,
  freeIcon,
  globeIcon,
  historicalAirportIcon,
  historicalFlightIcon,
  historyIcon,
  ListIcon,
  noAdsIcon,
  paymentHistoryIcon,
  premiumIcon,
  professionalSubscriptionIcon,
  saveIcon,
  standardSubscriptionIcon,
  statsIcon,
  subscriptionIcon,
  trackerIcon,
  viewPaymentHistoryIcon,
};

export const featureChartTitles = [
  {
    icon: icons.trackerIcon, title: 'Flight Tracker', freeFeature: true, standardFeature: true, proFeature: true,
  },
  {
    icon: icons.curConIcon, title: 'Airport Conditions', freeFeature: true, standardFeature: true, proFeature: true,
  },
  {
    icon: icons.delayMapIcon, title: 'Airport Delay Maps', freeFeature: true, standardFeature: true, proFeature: true,
  },
  {
    icon: icons.saveIcon, title: 'Saved Preferences', freeFeature: true, standardFeature: true, proFeature: true,
  },
  // { icon: icons.statsIcon,
  // title: 'Airline & Airport Performance Statistics',
  // freeFeature: true, standardFeature:
  // true, proFeature: true },
  // { icon: icons.globeIcon,
  // title: 'Global Cancellations & Delays',
  //  freeFeature: true,
  // standardFeature: true,
  // proFeature: true },
  {
    icon: icons.noAdsIcon, title: 'No Ads', standardFeature: true, proFeature: true,
  },
  {
    icon: icons.depArrIcon, title: 'Airport Departures And Arrivals', standardFeature: true, proFeature: true,
  },
  {
    icon: icons.alertIcon, title: 'Flight Alerts', standardFeature: true, standardText: '25 rules/month', proFeature: true, proText: '250 rules/month', proDiffer: true,
  },
  {
    icon: icons.historicalFlightIcon, title: 'Historical Search By Flight', proFeature: true, proText: '250 flights/month',
  },
  { icon: icons.historicalAirportIcon, title: 'Historical Search By Airport/Route', proFeature: true },
];

export const highlightBackgrounds = {
  freeFeature: 'highlightFreeBackground',
  standardFeature: 'highlightStandardBackground',
  proFeature: 'highlightProBackground',
};
