import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import componentBase from '../../../../../../src/lib/component-base';
import DelayGraph from './DelayGraph';
import WeatherConditions from './WeatherConditions';
import FSFlatButton from '../../../../../../src/components/lib/FSFlatButton';
import onResize from '../../../../../../src/lib/on-resize';
import { initialLoadFinish } from '../../../../redux/actions';
import { getFSHost } from '../../../../../../src/lib/hostname';
import { istanbulAirportCodeCheck, istanbulAirportNameCheck } from '../../../../../../shared/lib/airport-code-helpers';

@onResize()
@componentBase('Sidebar')
export default class Sidebar extends React.Component {
  static propTypes = {
    airport: PropTypes.object,
    currentWeather: PropTypes.object,
    dispatch: PropTypes.func,
    delayIndex: PropTypes.object,
    initialLoad: PropTypes.bool,
  }

  static defaultProps = {
    airport: {
      airportCode: '--',
      airportName: '--',
    },
  }

  componentWillMount() {
    if (this.props.airport && this.props.initialLoad) {
      this.props.dispatch(initialLoadFinish());
    }
  }

  componentDidMount() {
    this.initResizeListener();
  }

  componentWillUnmount() {
    this.removeResizeListener();
  }

  handleClick = (e, path) => {
    e.preventDefault();
    this.handleNavigation(path);
    return false;
  }

  handleResize = () => {
    this.forceUpdate();
  }

  handleNavigation = (path) => {
    window.location.href = `/v2${path}`;
  }

  renderButtons() {
    const { airport: { airportCode } } = this.props;
    const parsedCode = istanbulAirportCodeCheck(airportCode);
    const departureLabel = `${parsedCode} Departures`;
    const departureAlt = `View current ${parsedCode} airport departures`;
    const departureLink = `/flight-tracker/departures/${parsedCode}`;
    const arrivalLabel = `${parsedCode} Arrivals`;
    const arrivalAlt = `View current ${parsedCode} airport arrivals`;
    const arrivalLink = `/flight-tracker/arrivals/${parsedCode}`;
    const conditionsLabel = `${parsedCode} Conditions`;
    const conditionsAlt = `View current ${parsedCode} airport conditions`;
    const conditionsLink = `/airport-conditions/${parsedCode}`;

    const labelStyle = {
      fontSize: '14px',
      padding: '1px',
    };

    return (
      <div className='sidebar-button-container row'>
        <div className='col-sm-12 col-xs-12 col-lg-4 left-button-spacer'>
          <FSFlatButton
            className='sidebar-button button-left-departure'
            href={`${getFSHost()}${departureLink}`}
            label={departureLabel}
            labelStyle={labelStyle}
            alt={departureAlt}
            onClick={e => this.handleClick(e, departureLink)}
          />
        </div>
        <div className='col-sm-12 col-xs-12 col-lg-4 center-button-spacer'>
          <FSFlatButton
            className='sidebar-button button-center-arrivals'
            href={`${getFSHost()}${arrivalLink}`}
            label={arrivalLabel}
            labelStyle={labelStyle}
            alt={arrivalAlt}
            onClick={e => this.handleClick(e, arrivalLink)}
          />
        </div>
        <div className='col-sm-12 col-xs-12 col-lg-4 right-button-spacer'>
          <FSFlatButton
            className='sidebar-button button-right-conditions'
            label={conditionsLabel}
            containerElement={<Link to={conditionsLink} />}
            labelStyle={labelStyle}
            alt={conditionsAlt}
          />
        </div>
      </div>
    );
  }

  render() {
    if (this.props.initialLoad) {
      // show spinner instead of 'choose an airport'
      return (
        <div />
      );
    }
    if (this.props.airport && !this.props.initialLoad) {
      const titleStyle = {
        margin: '.75em 0 0',
        padding: 0,
        lineHeight: 'unset',
        fontSize: '1.5rem',
      };
      const {
        airport: {
          airportCode,
          airportName,
          addressLine1,
          addressLine2,
        },
        delayIndex,
        currentWeather,
      } = this.props;
      const parsedCode = istanbulAirportCodeCheck(airportCode);
      const parsedName = istanbulAirportNameCheck(airportName);
      return (
        <div className='row sidebar'>
          <div className='sidebar-header'>
            <div className='sidebar-airport-name'>
              <h3>{parsedCode ? `(${parsedCode})` : ''} {parsedName} </h3>
            </div>
            <div className='sidebar-airport-address'>
              <h4>
                {
                  `
                    ${addressLine1 || ''}
                    ${addressLine2 || ''}
                  `
                }
              </h4>
            </div>
          </div>
          <p style={titleStyle}>Current Delay Status </p>
          <DelayGraph delayIndex={delayIndex} />
          <p style={titleStyle}>Current Weather Conditions </p>
          <WeatherConditions currentWeather={currentWeather} />
          {this.renderButtons(this.mediaBreakpoint)}
        </div>
      );
    }
    if (!this.props.airport) {
      return <p>choose an airport</p>;
    }
    return (
      <div />
    );
  }
}
