import moment from 'moment';
import { flightNumberSort, dateSort, stringSort, sortByComplexString } from '../Shared/selectors';
import { DAY, MONTH } from './constants';

export const filterByMonth = (FlightAlertHistory) => {
  const obj = {
    ...FlightAlertHistory,
  };
  //    this is returning results of everything between two months, --> IS MONTHLY INCLUSIVE <--    //
  // return true if start FilterDate<= scheduledDeparture <= endFilterDate

  // moment isBetween is exclusive (...), so need very end of previous month and very beginning of upcoming month
  obj.startFilterDate = moment.utc(obj.startFilterDate).startOf('month').subtract(1, 'ms');
  obj.endFilterDate = moment.utc(obj.endFilterDate).endOf('month').add(1, 'ms');

  const result = obj.data.filter(datum => (
    moment.utc(datum.scheduledDeparture).isBetween(obj.startFilterDate, obj.endFilterDate, 'months')
  ));

  obj.endFilterDate = null;
  obj.startFilterDate = null;

  return result;
};

export const filterBySearchMonth = (FlightAlertHistory) => {
  const obj = {
    ...FlightAlertHistory,
  };
  const result = obj.data.filter((datum) => {
    const momentizedScheduledDeparture = moment.utc(datum.scheduledDeparture);
    const momentizedFilterMonth = moment.utc(obj.filterBySearchMonth);
    return momentizedScheduledDeparture.isSame(momentizedFilterMonth, MONTH);
  });

  obj.filterBySearchMonth = null;

  return result;
};

export const filterBySearchDate = (FlightAlertHistory) => {
  const obj = {
    ...FlightAlertHistory,
  };
  const result = obj.data.filter((datum) => {
    const momentizedscheduledDeparture = moment.utc(datum.scheduledDeparture);
    const momentizedFilterDay = moment.utc(obj.filterBySearchDate);

    return momentizedscheduledDeparture.isSame(momentizedFilterDay, DAY);
  });

  obj.filterBySearchDate = null;

  return result;
};

export const sortByColumnName = (FlightAlertHistory) => {
  const obj = {
    ...FlightAlertHistory,
  };

  const { searchColumnName, currentData, searchColumnASCorDESC } = obj;
  // have to be able to click to sort one way, then click again and sort another way (ascending, descending)
  const UpOrDown = searchColumnASCorDESC;
  let result = [].concat(currentData);

  if (searchColumnName === 'date') {
    dateSort(result, UpOrDown);
  } else if (searchColumnName === 'flight-number') {
    result = flightNumberSort(result, UpOrDown);
  } else if (searchColumnName === 'destination') {
    result = stringSort(result, UpOrDown, 'arrivalAirportFsCode');
  } else if (searchColumnName === 'origin') {
    result = stringSort(result, UpOrDown, 'departureAirportFsCode');
  } else if (searchColumnName === 'departure-time') {
    result = dateSort(result, UpOrDown, 'scheduledDeparture');
  } else if (searchColumnName === 'description') {
    result = result.sort(sortByComplexString('alertDescription'));
    if (UpOrDown === 'DESC') {
      result = result.reverse();
    }
  }

  obj.searchColumnName = null;
  obj.searchColumnASCorDESC = null;

  return result;
};

export const filteredData = (state) => {
  const FlightAlertHistory = state;
  if (FlightAlertHistory && FlightAlertHistory.rehydrated) {
    const filterClone = FlightAlertHistory.filteredData.slice();
    const dataSet = filterClone;
    if (FlightAlertHistory.keywordFilterResults) {
      delete FlightAlertHistory.keywordFilterResults;
    }
    FlightAlertHistory.rehydrated = false;
    return dataSet;
  }

  if (FlightAlertHistory && FlightAlertHistory.keywordFilterResults) {
    return FlightAlertHistory.currentData;
  }

  FlightAlertHistory.keywordFilterResults = null;
  if (FlightAlertHistory && FlightAlertHistory.filterBySearchMonth) {
    return filterBySearchMonth(FlightAlertHistory);
  } else if (FlightAlertHistory && FlightAlertHistory.endFilterDate && FlightAlertHistory.startFilterDate) {
    return filterByMonth(FlightAlertHistory);
  } else if (FlightAlertHistory && FlightAlertHistory.searchColumnName && FlightAlertHistory.searchColumnASCorDESC) {
    return sortByColumnName(FlightAlertHistory);
  }

  return FlightAlertHistory.data;
};
