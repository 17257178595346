import React from 'react';
import PropTypes from 'prop-types';
import componentBase from '../../../../../../../../../src/lib/component-base';
import { formattedTime, prefersAMPMTimeFormat } from '../../../../../../../../../src/lib/date-time-format';

@componentBase('TimeComponent')
export default class TimeComponent extends React.Component {
  static propTypes = {
    time: PropTypes.object,
  };

  static defaultProps = {
    time: null,
  };

  render() {
    let time = '--';
    let ampm = '';
    let timezone = '';
    let ampmSpace = null;
    const timezoneSpace = <span>&nbsp;</span>;
    let timeDetailsAMPM = '';
    let timeDetailsTimezone = '';

    if (this.props.time) {
      time = formattedTime(this.context.currentUser, this.props.time, 'time24', 'time');
      ampm = formattedTime(this.context.currentUser, this.props.time, null, 'ampm');
      timezone = this.props.time.timezone;
    }

    if (prefersAMPMTimeFormat(this.context.currentUser)) {
      ampmSpace = <span>&nbsp;</span>;
    }

    if (time !== '--') {
      timeDetailsAMPM = (<p className='timeDetails' style={{ paddingLeft: '1px', textTransform: 'uppercase' }}>{ampmSpace}{ampm} </p>);
      timeDetailsTimezone = (<p className='timeDetails' style={{ paddingLeft: '2px', textTransform: 'uppercase' }}>{timezoneSpace}{timezone}</p>);
    }

    return (
      <div>
        <h4 style={{ display: 'inline-block', margin: '0', fontWeight: 'bold' }}>{time}</h4>
        {timeDetailsAMPM}
        {timeDetailsTimezone}
      </div>
    );
  }
}
