import { memoizeWith, identity } from 'ramda';
import * as t from './actionTypes';

export const loadReferenceData = () => async (dispatch, getStore, client) => {
  try {
    const fn = memoizeWith(identity, u => client.get(u));

    const getAirports = fn('/reference/airport');

    const [airports] = await Promise.all([getAirports]);

    return dispatch({
      type: t.LOAD_REFERENCE_DATA,
      data: { airports },
    });
  } catch (e) {
    return dispatch({
      type: t.LOAD_FAIL,
      data: { error: 'Couldn\'t fetch data... Please try again later.' },
    });
  }
};
