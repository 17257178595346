import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '@flightstats/component-lib';

const OrangeBar = styled.div`
  height: 5px;
  background-color: ${colors.fsOrange};
  width: 100%;
  margin-top: 5px;
`;

const TitleText = styled.h1`
  font-size: 25px;
  font-weight: 600;
  padding: 0 10px;
`;

const PageTitle = ({ titleText }) => {
  return (
    <div className='pageTitle'>
      <TitleText>
        {titleText}
      </TitleText>
      <OrangeBar />
    </div>
  );
};

PageTitle.propTypes = {
  params: PropTypes.object,
};

export default PageTitle;
