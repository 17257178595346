import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BasicCallout } from '@flightstats/component-lib';
import componentBase from '../../../src/lib/component-base';
import FSFlatButton from '../../../src/components/lib/FSFlatButton';
import { openTab } from '../../../src/utils/openTab';

@componentBase('BasicCalloutWrapper')
export default class BasicCalloutWrapper extends Component {
  openURLHandler = (link, label, openNewTab) => (
    (e) => {
      e.preventDefault();
      if (openNewTab) {
        openTab(link, e);
        this.context.reportButtonPress(label);
      } else {
        this.context.goto(link, label);
      }
    }
  );

  render = () => {
    const {
      backgroundColor,
      borderColor,
      bulletText,
      buttonLabel,
      icon,
      onClickURL,
      onClickGALabel,
      openNewTab,
      prefixURL,
      standardButtonColor,
      subText,
      titleText,
    } = this.props;

    return (
      <BasicCallout
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        bulletText={bulletText}
        icon={icon}
        title={titleText}
        text={subText}
        button={
          <FSFlatButton
            href={prefixURL ? `${prefixURL}${onClickURL}` : onClickURL}
            label={buttonLabel}
            backgroundColor={standardButtonColor ? '#FAA718' : 'rgb(109,216,54)'}
            hoverColor={standardButtonColor ? '#81A2CA' : '#64bc23'}
            style={{ width: '100%', minWidth: '240px' }}
            onClick={this.openURLHandler(onClickURL, onClickGALabel, openNewTab)}
          />
        }
      />
    );
  };
}

BasicCalloutWrapper.propTypes = {
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
  bulletText: PropTypes.arrayOf(PropTypes.string),
  buttonLabel: PropTypes.string,
  icon: PropTypes.string,
  onClickGALabel: PropTypes.string,
  onClickURL: PropTypes.string,
  openNewTab: PropTypes.bool,
  prefixURL: PropTypes.string,
  standardButtonColor: PropTypes.bool,
  subText: PropTypes.string,
  titleText: PropTypes.string,
};
