import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import debounce from 'debounce';
import moment from 'moment';
import Helmet from 'react-helmet';
import { Historical, Current } from '../../redux';
import componentBase from '../../../../src/lib/component-base';
import FlightAlertsHistoryGraph from './children/FlightAlertsHistoryGraph/FlightAlertsHistoryGraph';
import FlightAlertsHistorySummary from './children/FlightAlertsHistorySummary/FlightAlertsHistorySummary';
import ArchivedFlights from './children/ArchivedFlights/ArchivedFlights';
import { dateFormatString } from '../../../../src/lib/date-time-format';
import { _calculateAlertCost, _getNumberAlertsSent, _getNumberOfIssues } from '../../lib/helpers';
import onResize from '../../../../src/lib/on-resize';
import FlightAlertsHeader from '../FlightAlertsIndex/children/FlightAlertsHeader/FlightAlertsHeader';
import { addMetaCanonical } from '../../../../shared/lib/meta-tags';

const { filteredData } = Historical.selectors;
const { constructWordMap } = Historical.transformer;
const {
  getHistoricalFlights,
  setFilter,
  generateMonthlySummaries,
  updateCost,
  setColumnFilter,
  setKeywordFilter,
  setFilterFromMonthSelect,
  rehydrate,
} = Historical.actions;
const { getCurrentServerDate } = Current.actions;

const metaTags = (location) => {
  const title = 'Flight Alerts History';
  const description = 'See the history of flights that FlightStats has monitored on your behalf';
  const keywords = 'flight alert history';
  const metaWithoutCanonical = {
    title,
    meta: [
      { name: 'keywords', content: keywords },
      { name: 'description', content: description },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
    ],
  };
  const pathname = location && location.pathname;
  return addMetaCanonical(metaWithoutCanonical, pathname);
};

@connect(state => ({
  FlightAlertHistory: state.FlightAlertHistory,
  currentDate: state.FlightAlerts.currentDate,
}))

@onResize()

@componentBase('FlightAlertHistory')

export default class FlightAlertHistory extends React.Component {
  static propTypes = {
    FlightAlertHistory: PropTypes.object,
    currentBrushInxStart: PropTypes.number,
    currentBrushInxEnd: PropTypes.number,
    currentDate: PropTypes.string,
    dispatch: PropTypes.func,
    name: PropTypes.string,
    receiptCounts: PropTypes.array,
    location: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);

    this.debouncedFilterAlertsByKeyword = this.debouncedFilterAlertsByKeyword().bind(this);
    this.filterAlertsByColumn = this.filterAlertsByColumn.bind(this);
    this.makeHasStackedBarChartUpdatedTrue = this.makeHasStackedBarChartUpdatedTrue.bind(this);

    this.state = {
      currentDate: this.props.currentDate,
      currentFilterStart: '--',
      currentFilterEnd: '--',
      // initial time period is difference in months from
      // smallest to largest scheduledDeparture in alert list
      costForTimePeriod: '--',
      hasStackedBarChartUpdated: false,
    };
  }

  componentDidMount() {
    this.initResizeListener();
    this.props.dispatch(getHistoricalFlights());
    this.props.dispatch(getCurrentServerDate());

    if (this.props.FlightAlertHistory.data) {
      const frozenState = this.frozenState(this.context.store);
      if (frozenState) {
        this.props.dispatch(rehydrate(frozenState));
      } else {
        this.props.dispatch(generateMonthlySummaries(this.props.FlightAlertHistory.data, this.mediaBreakpoint.name));
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const { data: newData = [] } = nextProps.FlightAlertHistory;
    const { data: oldData = [] } = this.props.FlightAlertHistory;

    if (newData.length !== oldData.length) {
      setTimeout(() => {
        this.props.dispatch(generateMonthlySummaries(newData, this.mediaBreakpoint.name));
      }, 50);
    }

    const { rehydrated, costForTimePeriod } = this.props.FlightAlertHistory;
    const { receiptCounts = [] } = nextProps;

    if (!rehydrated && costForTimePeriod === '--' && receiptCounts.length) {
      const cost = _calculateAlertCost(receiptCounts);
      this.props.dispatch(updateCost(cost));
    }
  }

  componentWillUnmount() {
    this.removeResizeListener();
  }

  filterAlertsByColumn = (colName, orderBy, currentData) => {
    // orderBy either 'ASC' or 'DESC'
    this.props.dispatch(setColumnFilter(colName, orderBy, currentData));
  }

  filterAlertsByKeyword = (keywords, currentData, receiptCounts) => {
    // const searchKeywords = keywords.trim().split(' ');
    if (keywords && keywords.length && keywords[0].length) {
      this.props.dispatch(setKeywordFilter(keywords, currentData));
    } else if (receiptCounts && receiptCounts.length) {
      const { currentBrushInxStart, currentBrushInxEnd } = this.props.FlightAlertHistory;
      const startInx = currentBrushInxStart || 0;
      const endInx = currentBrushInxEnd || (receiptCounts.length - 1);
      let sampleMonthStart = receiptCounts[startInx];
      let sampleMonthEnd = receiptCounts[endInx];
      sampleMonthStart = sampleMonthStart.oneScheduledDepartureThisMonth;
      sampleMonthEnd = sampleMonthEnd.oneScheduledDepartureThisMonth;

      this.props.dispatch(setFilter(
        sampleMonthStart, sampleMonthEnd, receiptCounts,
        this.props.FlightAlertHistory.currentBrushInxStart,
        this.props.FlightAlertHistory.currentBrushInxEnd,
      ));
    }
  }

  handleResize = () => {
    this.forceUpdate();
  }

  filterDataAndUpdateFilterPeriodAndCalculateCost = (dateObj, receiptCounts) => {
    const { startIndex, endIndex } = dateObj;
    const beginningMonth = receiptCounts[startIndex];
    const endMonth = receiptCounts[endIndex];
    // any of the dates from the month will do since we are filtering by month anyway
    const beginningMonthSampleDate = beginningMonth.oneScheduledDepartureThisMonth;
    const endMonthSampleDate = endMonth.oneScheduledDepartureThisMonth;

    // the new date range needs to have its cost calculated
    const alertsCost = _calculateAlertCost(receiptCounts.slice(startIndex, endIndex + 1));
    this.props.dispatch(setFilter(beginningMonthSampleDate, endMonthSampleDate, alertsCost, startIndex, endIndex));
  };

  filterDataAndUpdateFilterPeriodAndCalculateCostFromMonthSelect = (dateObj, receiptCounts) => {
    const { startIndex, endIndex } = dateObj;
    const beginningMonth = receiptCounts[startIndex];
    const endMonth = receiptCounts[endIndex];
    // any of the dates from the month will do since we are filtering by month anyway
    const beginningMonthSampleDate = beginningMonth.oneScheduledDepartureThisMonth;
    const endMonthSampleDate = endMonth.oneScheduledDepartureThisMonth;

    // the new date range needs to have its cost calculated
    const alertsCost = _calculateAlertCost(receiptCounts.slice(startIndex, endIndex + 1));
    this.props.dispatch(setFilterFromMonthSelect(
      beginningMonthSampleDate,
      endMonthSampleDate,
      alertsCost,
      startIndex,
      endIndex,
    ));
  };

  debouncedFilterDataAndUpdateFilterPeriodAndCalculateCost =
    (() => debounce(this.filterDataAndUpdateFilterPeriodAndCalculateCost, 500))()

  debouncedFilterAlertsByKeyword = () => debounce(this.filterAlertsByKeyword, 250)

  filterAlertsByMonth = (momentSearchDate) => {
    const filterDate = momentSearchDate.format();
    this.props.dispatch(setFilter(filterDate, null, 'month', this.props.currentBrushInxStart, this.props.currentBrushInxEnd));
  };

  generateStackedBarChartDimensions = (dimensions, mediaBreakpoint) => {
    const newDimensions = Object.assign({}, dimensions);
    newDimensions.height = 300;
    newDimensions.barWidth = 20;
    newDimensions.name = mediaBreakpoint.name;

    if (mediaBreakpoint.name === 'tablet') {
      newDimensions.height = 350;
      newDimensions.barWidth = 24;
    } else if (mediaBreakpoint.name === 'desktopSm' ||
      mediaBreakpoint.name === 'desktopLg') {
      newDimensions.barWidth = 24;
    }

    return newDimensions;
  };

  localFreezeState = (addToFrozenState) => {
    const newFrozenState = Object.assign({}, addToFrozenState);

    this.freezeState(this.context.store, newFrozenState);
  }

  makeHasStackedBarChartUpdatedTrue = () => {
    this.setState({ hasStackedBarChartUpdated: true });
  }

  render() {
    const {
      rehydrated,
      currentData,
      waitForInitialProcessing,
      data,
      keywordFilterResults,
      receiptCounts,
      latestAlert,
      currentBrushInxEnd,
      earliestAlert,
      currentBrushInxStart,
      costForTimePeriod,
      noHistoricalResults,
    } = this.props.FlightAlertHistory;

    let dataSet = waitForInitialProcessing && data.length ?
      [{ wait: true }] :
      filteredData(this.props.FlightAlertHistory);

    if (dataSet && dataSet[0] && !dataSet[0].wait) {
      dataSet = constructWordMap(dataSet);
    }

    const alerts = waitForInitialProcessing ?
      '--' :
      _getNumberAlertsSent(dataSet);
    const flightsMonitored = waitForInitialProcessing ?
      '--' :
      dataSet.length;
    const identifiedIssues = waitForInitialProcessing ?
      '--' :
      _getNumberOfIssues(dataSet);
    const headerDate = this.state.currentDate ?
      moment.utc(this.state.currentDate).format(dateFormatString(this.context.currentUser)) :
      '';

    let stackedBarChartDimensions = {};
    if (this.mediaBreakpoint) {
      stackedBarChartDimensions =
        this.generateStackedBarChartDimensions(stackedBarChartDimensions, this.mediaBreakpoint);
    }

    return (
      <div className='flight-alerts-history'>
        <Helmet {...metaTags(this.props.location)} />
        <FlightAlertsHeader date={headerDate} leftSide='Flight Alerts Console' />
        <div>
          <div className='graph-and-summary-wrapper'>
            <FlightAlertsHistoryGraph
              filteredData={dataSet}
              currentData={currentData}
              data={data}
              filterAlertsByMonth={momentSearchDate => this.filterAlertsByMonth(momentSearchDate)}
              filterAlertsByTimeframe={dateObj =>
                this.debouncedFilterDataAndUpdateFilterPeriodAndCalculateCost(dateObj, receiptCounts)} // eslint-disable-line max-len
              dimensions={stackedBarChartDimensions}
              receiptCounts={receiptCounts}
              currentFilterStart={earliestAlert}
              currentFilterEnd={latestAlert}
              currentBrushInxStart={currentBrushInxStart}
              currentBrushInxEnd={currentBrushInxEnd}
              freezeState={stateToFreeze => this.localFreezeState(stateToFreeze)}
              flightsMonitored={flightsMonitored}
              identifiedIssues={identifiedIssues}
              cost={costForTimePeriod}
              mediaBreakpoint={stackedBarChartDimensions.name}
              noHistoricalResults={noHistoricalResults}
              rehydrated={!!rehydrated}
              stackedBarChartUpdatedSwitch={this.makeHasStackedBarChartUpdatedTrue}
              hasStackedBarChartUpdated={this.state.hasStackedBarChartUpdated}
            />
            <FlightAlertsHistorySummary
              filteredData={dataSet}
              currentData={currentData}
              currentFilterStart={earliestAlert}
              currentFilterEnd={latestAlert}
              cost={costForTimePeriod}
              mediaBreakpoint={stackedBarChartDimensions.name}
              alerts={alerts}
              flightsMonitored={flightsMonitored}
              identifiedIssues={identifiedIssues}
              rehydrated={rehydrated}
            />
          </div>
          <ArchivedFlights
            filteredData={dataSet}
            renderedData={keywordFilterResults}
            filterByColumn={this.filterAlertsByColumn}
            filterAlertsByKeyword={this.debouncedFilterAlertsByKeyword}
            receiptCounts={receiptCounts}
            filterAlertsByTimeframeFromMonthSelect={dateObj =>
              this.filterDataAndUpdateFilterPeriodAndCalculateCostFromMonthSelect(dateObj, receiptCounts)} // eslint-disable-line max-len
            noHistoricalResults={noHistoricalResults}
          />
        </div>

      </div>
    );
  }
}
