import React from 'react';
import PropTypes from 'prop-types';
import { propSatisfies } from 'ramda';
import componentBase from '../../../src/lib/component-base';
import { VariantLink } from './variants/VariantLink';
import { OriginalLink } from './variants/OriginalLink';

const isTheVariant = propSatisfies(v => v === 'with-carat', 'variant');

@componentBase('ResponsiveTable')
export default class ResponsiveTable extends React.Component {
  static propTypes = {
    header: PropTypes.array,
    rows: PropTypes.array,
    // variant: PropTypes.string,
  };

  static defaultProps = {
    header: [],
    rows: [],
  };

  getHeaderColumn = ({ title, titleAlt, style }, i) => {
    const primaryClassNames = ['flights-list-header-text'];
    const secondaryClassNames = ['flights-list-header-text'];

    if (titleAlt) {
      primaryClassNames.push('hidden-xs');
      secondaryClassNames.push('visible-xs');
    }

    if (i === 0) {
      primaryClassNames.push('leftText');
    }

    return (
      <div className={(this.props.rows && this.props.rows[0] && this.props.rows[0][i] && this.props.rows[0][i].primary.className) || `col-xs-${this.getColumnWidth()}`} key={`header-column-${i}`}>
        <p className={primaryClassNames.join(' ')} style={style || { marginLeft: '5px' }}>{title}</p>
        {titleAlt ? <p className={secondaryClassNames.join(' ')} style={style || { marginLeft: '5px' }}>{titleAlt}</p> : null}
      </div>
    );
  };

  getColumnLength = () =>
    ((this.props.header && this.props.header.length) ? this.props.header.length : 0);

  getColumnWidth = () => Math.floor(12 / this.getColumnLength());

  formatBodyRow = (items, i) => {
    const item = items[0];
    const { href } = item;
    const shouldVariant = isTheVariant(this.props);
    const innerRows = (
      <div className='multi-flights-list-row row' key={`inner-row-${i}-${href}`}>
        <div className='row'>
          {items.map(({ primary }, j) => this.formatBodyCell(primary, i, j))}
        </div>
        <div className='row'>
          {items
            .filter(itm => itm.secondary)
            .map(({ secondary }, j) => this.formatBodyCell(secondary, i, j))}
        </div>
      </div>
    );
    const LinkComponent = !shouldVariant ? VariantLink : OriginalLink;
    return href ? (
      <LinkComponent
        item={item}
        innerRows={innerRows}
        key={`outer-row-${i}-${href}`}
      />
    ) : innerRows;
  };

  formatBodyCell = ({ element, elementAlt, className }, i, j) => (
    <div key={`body-cell-${i}-${j}`} className={`table-cell ${className || `col-xs-${this.getColumnWidth()}`}`}>
      {element}{elementAlt}
    </div>
  );

  renderTableHeader = () => {
    if (!this.props.header || !this.props.header[0]) return null;
    const width = !isTheVariant(this.props) ? 'calc(100% - 33px)' : '100%';
    return (
      <div className='multi-flights-list-header row' style={{ paddingTop: '5px', width }}>
        {this.props.header.map(this.getHeaderColumn)}
      </div>
    );
  }

  renderTableRows = () => this.props.rows.map(this.formatBodyRow);

  render() {
    return (
      <div className='multi-flights-list container'>
        {this.renderTableHeader()}
        {this.renderTableRows()}
      </div>
    );
  }
}
