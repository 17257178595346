// import { createSelector } from 'reselect';
import { NAME } from './constants';

export const getFlight = state => state[NAME];
export const isLoaded = globalState => (globalState[NAME] && globalState[NAME].loaded);

/*
export const getCounts = createSelector(
  getFlight,
  (flight) => ({
    flight: allTodos.length,
    completed: completedTodos.length,
    active: activeTodos.length
  })
);
*/
