// date-time-format

const isAlternate = (user, attribute) => (
  user && user[attribute] !== '0'
);

export const prefersAMPMTimeFormat = user => (
  isAlternate(user, 'timeFormat')
);

export const prefersMDYDateFormat = user => (
  isAlternate(user, 'dateFormat')
);

// defaultOption: returned if user prefers DMY dates
// alternateOption: returned if user prefers MDY dates
export const dateFormatSelect = (user, defaultOption, alternateOption) => (
  prefersMDYDateFormat(user) ? alternateOption : defaultOption
);

// defaultOption: returned if user prefers 24-hour time
// alternateOption: returned if user prefers AM/PM time
export const timeFormatSelect = (user, defaultOption, alternateOption) => (
  prefersAMPMTimeFormat(user) ? alternateOption : defaultOption
);

export const dateFormatString = user => (
  dateFormatSelect(user, 'DD-MMM-YYYY', 'MMM-DD-YYYY')
);

export const timeFormatString = user => (
  timeFormatSelect(user, 'HH:mm', 'h:mm A')
);
// for airport current-conditions transformer the fields are currentDate and currentDateMDY
export const formattedDate = (user, obj, defaultTimeField, alternateTimeField) => (
  obj && obj[dateFormatSelect(user, defaultTimeField, alternateTimeField)]
);

// for airport current-conditions transformer the fields are currentTime and currentTimeAMPM
// for flight-tracker-multi-flight transformer the fields are currentTime and currentTime1
export const formattedTime = (user, obj, defaultTimeField, alternateTimeField) =>
  obj && obj[timeFormatSelect(user, defaultTimeField, alternateTimeField)];
