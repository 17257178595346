import React from 'react';
import PropTypes from 'prop-types';
import { CompactMenu } from '../../../NewHeader/compact-menu';
import { accountMenuData, compactMenuData } from '../../../NewHeader/compact-header-data';

const MobileMenuNew = (props) => {
  const {
    onNavItemSelected,
    open,
    user,
  } = props;

  const closeMenu = () => onNavItemSelected();

  return (
    <div
      className={`mobile-menu ${open ? 'open-menu' : ''}`}
      onClick={() => {
        closeMenu();
      }}
      onKeyPress={() => {}}
    >
      <CompactMenu
        menuData={compactMenuData}
        accountMenuData={accountMenuData}
        user={user}
      />
    </div>
  );
};

MobileMenuNew.propTypes = {
  gotoHandler: PropTypes.func,
  logoutHandler: PropTypes.func,
  onNavItemSelected: PropTypes.func,
  open: PropTypes.bool,
  // reportSendInteraction: PropTypes.func,
  user: PropTypes.object,
};

export default MobileMenuNew;
