import React from 'react';
import PropTypes from 'prop-types';
import TimeToDOM from '../../../../../../../src/components/TimeToDOM/TimeToDOM';

const ArchivedAlert = (props) => {
  const date = `${props.scheduledDeparture[1]}${props.scheduledDeparture[0]}`;
  const mobileDate = props.scheduledDeparture[1];
  const {
    departureAirportFsCode,
    arrivalAirportFsCode,
  } = props;

  return (
    <div className='archived-flight-element archived-flight-list'>
      <div className='date '>
        <span className='hide-mobile'>{date}</span>
        <span className='mobile'>{mobileDate}</span>
      </div>
      <div className='flight-number hide-mobile'>{props.flightNumber}</div>
      <div className='origin hide-mobile'>{departureAirportFsCode}</div>
      <div className='destination hide-mobile'>{arrivalAirportFsCode}</div>
      <div className='departure-time hide-mobile'>
        <TimeToDOM time={props.scheduledDeparture[2]} user={props.user} isUtc componentName='archived-alerts' />
        {` ${props.timeZone}`}
      </div>
      <div className='description'>{props.alertDescription}</div>
    </div>
  );
};

ArchivedAlert.propTypes = {
  scheduledDeparture: PropTypes.array,
  flightNumber: PropTypes.string,
  arrivalAirportFsCode: PropTypes.string,
  departureAirportFsCode: PropTypes.string,
  user: PropTypes.object,
  alertDescription: PropTypes.string,
  timeZone: PropTypes.string,
};

export default ArchivedAlert;
