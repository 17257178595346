import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SelectField } from '@flightstats/component-lib';
import { push } from 'connected-react-router';
import identity from 'ramda/src/identity';
import pathOr from 'ramda/src/pathOr';

import FSFlatButton from '../../../../../src/components/lib/FSFlatButton';
import componentBase from '../../../../../src/lib/component-base';
import AirportSelect from '../../../../../src/components/lib/AirportSelect';
import { getDateMenuItems } from '../../../lib/deparr-helpers';
import { setDepArrAirportOptions, setDepArrDateFieldIndex, setSecondaryAction } from '../../../redux/actions';

@componentBase('DeparturesArrivalsSearch')
export default class DeparturesArrivalsSearch extends Component {
  static propTypes = {
    airport: PropTypes.object,
    dateFieldIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dispatch: PropTypes.func,
    match: PropTypes.object,
    user: PropTypes.object,
    searchAirport: PropTypes.object,
    isArrival: PropTypes.bool,
    isDeparture: PropTypes.bool,
    routeParams: PropTypes.object,
    secondaryAction: PropTypes.bool,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errText: '',
    };
  }

  componentDidMount() {
    this.prefillValues();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.secondaryAction) {
      nextProps.dispatch(setSecondaryAction(false));
    }
  }

  componentWillUnmount() {
    this.props.dispatch(setSecondaryAction(false));
  }

  getDateMenuItems = () => {
    if (!this.dateMenuItems) {
      this.dateMenuItems = getDateMenuItems(this.props.user);
    }
    return this.dateMenuItems;
  };

  prefillValues = () => {
    let airportCode = null;
    const frozenState = this.frozenState(this.context.store);
    let frozenAirport = frozenState && frozenState.airport;
    const {
      airport,
      dateFieldIndex,
      dispatch,
      match,
      user,
    } = this.props;
    const params = match && match.params;
    if (!airport) {
      if (frozenAirport) {
        const { _id } = frozenAirport;
        airportCode = _id;
      } else if (params.airportCode) {
        airportCode = params.airportCode;
      } else if (user && user.departureAirportCode) {
        airportCode = user.departureAirportCode;
      }
    }

    frozenAirport = airportCode ? { fs: airportCode, iata: airportCode, _id: airportCode, _source: { name: '' } } : null;

    const frozenDateFieldIndex = (frozenState && frozenState.dateFieldIndex) || 1;
    /* eslint-disable no-underscore-dangle */
    const airportId = airport && airport._id;
    /* eslint-enable no-underscore-dangle */
    if (airportCode !== airportId) {
      dispatch(setDepArrAirportOptions({
        airport: frozenAirport,
        isArrival: false,
        isDeparture: false,
      }));
    }
    if (frozenDateFieldIndex && !dateFieldIndex) {
      dispatch(setDepArrDateFieldIndex(frozenDateFieldIndex));
    }
  }

  airportCode = () => {
    const { searchAirport, routeParams } = this.props;
    return (
      pathOr(false, ['value', '_source', 'iata'], searchAirport) ||
      routeParams.airportCode
    );
  }

  submit = (direction, optionalDate) => {
    const { searchAirport, airport } = this.props;
    if (pathOr(false, ['value', '_source', 'fs'], searchAirport) || pathOr(false, ['_id'], airport)) {
      this.setState({
        errText: '',
      });

      if (direction === 'departures') {
        this.context.reportButtonPress('ViewDepartures');
      } else {
        this.context.reportButtonPress('ViewArrivals');
      }
      const date = optionalDate || this.getDateMenuItems()[this.props.dateFieldIndex].momentDate;
      const url = `/airports/departing-arriving/${this.airportCode()}/${direction}/${date.format('YYYY/MM/DD')}`;
      this.props.dispatch(push(url));
    } else {
      // Show alert
      this.setState({
        errText: 'Please enter a valid airport name or code',
      });
    }
  };

  handleDeparturesClick = () => {
    this.submit('departures');
  };

  handleArrivalsClick = () => {
    this.submit('arrivals');
  };

  submitFromEnterKey = () => {
    if (this.props.isArrival) {
      this.handleArrivalsClick();
    } else if (this.props.isDeparture) {
      this.handleDeparturesClick();
    }
  };

  handleAirportChange = (option, triggeredByEnter) => {
    const { isArrival, isDeparture, dispatch } = this.props;
    dispatch(setDepArrAirportOptions({
      searchAirport: option,
      isArrival,
      isDeparture,
      triggeredByEnter,
    }));
  };

  handleAirportSearchRemove = () => {
    this.props.dispatch(setDepArrAirportOptions({}));
  }

  handleDateChange = (option) => {
    if (this.props.airport) {
      if (this.props.isDeparture) {
        this.submit('departures', option.momentDate);
      } else {
        this.submit('arrivals', option.momentDate);
      }
    }

    switch (option.value) {
      case '0':
        this.context.reportUserEvent('ChangeDate', 'PastDay');
        break;
      case '1':
        this.context.reportUserEvent('ChangeDate', 'CurrentDay');
        break;
      case '2':
        this.context.reportUserEvent('ChangeDate', 'Future');
        break;
      default:
        // noop
    }
    this.props.dispatch(setDepArrDateFieldIndex(option.value));
  };

  render() {
    const { dateFieldIndex } = this.props;
    const dateMenuItems = this.getDateMenuItems();
    const dateValue = [dateFieldIndex]
      .map(item => item || 1)
      .map(v => v.toString())
      .reduce(o => o);

    let errorText = '';

    if (this.state.errText) {
      errorText = (
        <div className='row'>
          <div className='col-lg-4 col-md-4 col-sm-4' style={{ padding: '0 15px' }}>
            <div className='search-error-text-block'>
              <p className='search-error-text'>{this.state.errText}</p>
            </div>
          </div>
          <div className='col-lg-2 col-md-2 col-sm-2' style={{ marginLeft: 15 }} />
          <div className='col-lg-4 col-md-4 col-sm-5 hidden-xs' style={{ marginTop: '20px', padding: 0 }} />
        </div>
      );
    }

    const labelStyle = {
      color: this.context.muiTheme.rawTheme.palette.primary2Color,
    };

    return (
      <div className='row departures-arrivals-search'>
        <h4>Search For An Airport</h4>
        <form>
          {errorText}
          <div className='col-lg-4 col-md-4 col-sm-4' style={{ padding: '0 15px' }}>
            <label style={labelStyle} htmlFor='airport'>Airport</label>
            <AirportSelect
              name='airport'
              inputTitle='Search For An Airport'
              className='fs-select-search-field'
              airport={this.props.searchAirport}
              onChange={this.handleAirportChange}
              onDelete={() => this.handleAirportSearchRemove}
              secondaryAction={this.props.secondaryAction ? this.submitFromEnterKey : identity}
            />
          </div>
          <div className='col-lg-2 col-md-2 col-sm-2' style={{ padding: '0 15px' }}>
            <label style={labelStyle} htmlFor='date'>Date</label>
            <SelectField
              value={dateValue}
              results={dateMenuItems}
              searchable={false}
              handleMenuSelection={this.handleDateChange}
              bgColor='white'
              height='34px'
              borderColor='transparent'
              width='auto'
              menuBorderWidth='0'
              selectWrapperPosition='relative'
            />
          </div>
          <div className='col-lg-4 col-md-4 col-sm-5 col-xs-12' style={{ marginTop: '20px', padding: 0 }}>
            <FSFlatButton
              label='Departures'
              onClick={this.handleDeparturesClick}
              style={FSFlatButton.style({ margin: null, minWidth: null })}
            />
            <FSFlatButton
              className='arrivals'
              label='Arrivals'
              onClick={this.handleArrivalsClick}
              style={FSFlatButton.style({ margin: null, minWidth: null, float: null })}
            />
          </div>
        </form>
      </div>
    );
  }
}
