import React from 'react';
import PropTypes from 'prop-types';
import { ChartIcon, ChartItem } from '../../../../shared/SubscriptionFeatureChart/ChartCell';
import PlanTitleButton from './PlanTitleButton';
import { getChartItemChildren } from '../../../../../lib/helpers';

const PlanSelectionChart = (props) => {
  const {
    featureChartTitles,
    freeIcon,
    handlePlanSelection,
    handlePlanHoverState,
    planHovered,
    planSelected,
    professionalSubscriptionIcon,
    standardSubscriptionIcon,
    user,
  } = props;

  const getDrabClassName = tier => (planSelected === tier || planHovered === tier ? '' : 'drab');

  const getFeatureHighlights = (feature) => {
    const {
      freeFeature,
      proFeature,
      standardFeature,
    } = feature;
    return {
      free: freeFeature ? 'highlight-background' : '',
      standard: standardFeature ? 'highlight-background' : '',
      pro: proFeature ? 'highlight-background' : '',
    };
  };

  const isFreeButtonDisabled = (userObj) => {
    /*
      if the user has an account and an active subscription, cannot choose free,
      they have to cancel subscription in account settings
    */
    if (userObj) {
      const { subscriptionBraintreeId, subscriptionStatus } = userObj;
      if (subscriptionBraintreeId && subscriptionStatus === 'active') {
        return true;
      }
    }
    return false;
  };

  const renderPlanTitleRow = (visibleClass = '') => (
    <div className={`${visibleClass} plan-title-buttons-container`}>
      <div className='services'>
        SERVICES
      </div>
      <div className='plan-title-row'>
        <div className={`${getDrabClassName('free')} free-plan highlight-background`}>
          <ChartIcon
            isChartHeader
            icon={freeIcon}
            title='Free Account'
            showAccountSelection
          />
        </div>
        <div className={`${getDrabClassName('standard')} standard-plan highlight-background`}>
          <ChartIcon
            isChartHeader
            icon={standardSubscriptionIcon}
            title='Standard Account'
            subtitle='$2.99 per month'
            showAccountSelection
          />
        </div>
        <div className={`${getDrabClassName('premium')} premium-plan highlight-background`}>
          <ChartIcon
            isChartHeader
            icon={professionalSubscriptionIcon}
            title='Professional Account'
            subtitle='$24.99 per month'
            showAccountSelection
          />
        </div>
      </div>
      <div className='plan-title-button-row'>
        <div className='highlight-background free' >
          <PlanTitleButton
            handlePlanSelection={handlePlanSelection}
            handlePlanHover={handlePlanHoverState}
            planTitle='free'
            selected={planSelected === 'free'}
            disabled={isFreeButtonDisabled(user)}
          />
        </div>
        <div className='highlight-background standard' >
          <PlanTitleButton
            handlePlanSelection={handlePlanSelection}
            handlePlanHover={handlePlanHoverState}
            planTitle='standard'
            selected={planSelected === 'standard'}
            disabled={false}
          />
        </div>
        <div className='highlight-background premium' >
          <PlanTitleButton
            handlePlanSelection={handlePlanSelection}
            handlePlanHover={handlePlanHoverState}
            planTitle='premium'
            selected={planSelected === 'premium'}
            disabled={false}
          />
        </div>

      </div>
    </div>
  );

  const renderFeatureTitles = () => featureChartTitles.map((feature, index) => {
    const {
      icon,
      title,
      standardText,
      proText,
    } = feature;

    const highlights = getFeatureHighlights(feature);
    const children = getChartItemChildren(feature);
    let iconCustomClass = '';
    if (!!standardText || !!proText) {
      iconCustomClass = 'with-subtitle';
    }
    if (index === featureChartTitles.length - 1) {
      iconCustomClass = 'icon-last-child';
    }

    const key = `title_${index}`;

    return (
      <div className='plan-header-row' key={key}>
        <div className='plan-chart-header-container'>
          <ChartIcon
            customClassName={iconCustomClass}
            isChartHeader={false}
            icon={icon}
            title={title}
            showAccountSelection
          />
        </div>
        <div className='chart-item-row'>
          <ChartItem
            containerClassNames={`${highlights.free} ${getDrabClassName('free')} free-plan`}
            children={children[0]}
          />
          <ChartItem
            containerClassNames={`${highlights.standard} ${getDrabClassName('standard')} standard-plan`}
            children={children[1]}
          />
          <ChartItem
            containerClassNames={`${highlights.pro} ${getDrabClassName('premium')} premium-plan`}
            children={children[2]}
          />
        </div>
      </div>
    );
  });


  return (
    <div>
      {renderPlanTitleRow()}
      {renderFeatureTitles()}
      {renderPlanTitleRow('visible-xs')}
    </div>
  );
};

PlanSelectionChart.propTypes = {
  featureChartTitles: PropTypes.array,
  freeIcon: PropTypes.string,
  handlePlanSelection: PropTypes.func,
  handlePlanHoverState: PropTypes.func,
  planHovered: PropTypes.string,
  planSelected: PropTypes.string,
  professionalSubscriptionIcon: PropTypes.string,
  standardSubscriptionIcon: PropTypes.string,
  user: PropTypes.object,
};

export default PlanSelectionChart;
