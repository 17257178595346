import React from 'react';
import PropTypes from 'prop-types';
import Pagination from 'react-js-pagination';
import FlatButton from 'material-ui/FlatButton';
import FontIcon from 'material-ui/FontIcon';
import pathOr from 'ramda/src/pathOr';
import equals from 'ramda/src/equals';

import componentBase from '../../../../../../src/lib/component-base';
import onResize from '../../../../../../src/lib/on-resize';
import { breakpoints } from '../../../../../../src/constants/style-constants';
import StandardFSDropDown from '../../../../../../src/components/lib/StandardFSDropDown';
import ToggleSwitch from '../../../../../../src/components/lib/ToggleSwitch';
import List from './List';
import { createDimensionMenuItems, groupBy, menuItemsToArray } from './_helpers';
import constants from './_constants';

const MAX_ITEMS_PER_PAGE = 25;

@onResize()
@componentBase('ListWrapper')
class ListWrapper extends React.Component {
  static propTypes = {
    dimensions: PropTypes.object,
    depArr: PropTypes.string,
    handleCodeshareToggle: PropTypes.func,
    defaultSorted: PropTypes.array,
    params: PropTypes.object,
    toggleOn: PropTypes.bool,
  }

  constructor(props, context) {
    super(props, context, 'ListWrapper');
    this.state = {
      pageIndex: 1,
      sortAscending: true,
      columnSort: constants.LIST_SORT_KEYS.scheduledGate,
    };
    this.pageCount = 1;
    this.mobileSortMenuItems = this._getMobileSortMenuItems();
    this.hideCodeshares = null;
    this.pagesSummary = this.getPagesSummary();
  }

  componentDidMount() {
    this.initResizeListener();
  }

  componentWillUnmount() {
    this.removeResizeListener();
  }

  handleResize = () => {
    this.forceUpdate();
  }

  getPagesSummary = (pages) => {
    if (!pages) {
      return {
        firstFlightId: null,
        lastFlightId: null,
        resultsLength: null,
      };
    }

    return {
      firstFlightId: pathOr(null, ['0', 'flightId'], pages),
      lastFlightId: pathOr(null, [`${pages && pages.length - 1}`, 'flightId'], pages),
      resultsLength: pages && pages.length,
    };
  }

  // Sets which column to sort ascending/decending order
  _handleSortClick = (crossfilterToSort, columnKey) => {
    // this.resetFiltersEnabled = true;
    let GAEventLabelValue = columnKey;
    if (GAEventLabelValue === 'destinationOrigin') {
      GAEventLabelValue = this.props.depArr;
    }
    if (this.sortedList === crossfilterToSort) {
      if (this.state.sortAscending === true) {
        this.setState({
          sortAscending: false,
        });
        this.context.reportUserEvent('TableSort', GAEventLabelValue, 1);
      } else {
        this.setState({
          sortAscending: true,
        });
        this.context.reportUserEvent('TableSort', GAEventLabelValue, 0);
      }
    } else {
      this.sortedList = crossfilterToSort;
      this.setState({
        sortAscending: true,
        columnSort: columnKey,
      });
      this.context.reportUserEvent('TableSort', GAEventLabelValue, 0);
    }
  };

  _handleMobileSortSelected = (seletedItem) => {
    const selectedKey = seletedItem.key;
    this.resetFiltersEnabled = true;
    if (selectedKey !== this.state.columnSort) {
      switch (selectedKey) {
        case constants.LIST_SORT_KEYS.baggage:
          this._handleSortClick(this.props.dimensions.flightsByBaggage, selectedKey);
          break;
        case constants.LIST_SORT_KEYS.destination:
          this._handleSortClick(this.props.dimensions.flightsByDestOriginAirportCode, selectedKey);
          break;
        case constants.LIST_SORT_KEYS.origin:
          this._handleSortClick(this.props.dimensions.flightsByDestOriginAirportCode, selectedKey);
          break;
        case constants.LIST_SORT_KEYS.airline:
          this._handleSortClick(this.props.dimensions.flightsByFlightCode, selectedKey);
          break;
        case constants.LIST_SORT_KEYS.equipment:
          this._handleSortClick(this.props.dimensions.flightsByEquipment, selectedKey);
          break;
        case constants.LIST_SORT_KEYS.terminal:
          this._handleSortClick(this.props.dimensions.flightsByTerminal, selectedKey);
          break;
        case constants.LIST_SORT_KEYS.gate:
          this._handleSortClick(this.props.dimensions.flightsByGate, selectedKey);
          break;
        case constants.LIST_SORT_KEYS.scheduledGate:
          this._handleSortClick(this.props.dimensions.flightsByScheduledGate, selectedKey);
          break;
        case constants.LIST_SORT_KEYS.actualGate:
          this._handleSortClick(this.props.dimensions.flightsByActualGate, selectedKey);
          break;
        case constants.LIST_SORT_KEYS.scheduledRunway:
          this._handleSortClick(this.props.dimensions.flightsByScheduledRunway, selectedKey);
          break;
        case constants.LIST_SORT_KEYS.actualRunway:
          this._handleSortClick(this.props.dimensions.flightsByActualRunway, selectedKey);
          break;
        case constants.LIST_SORT_KEYS.status:
          this._handleSortClick(this.props.dimensions.flightsByStatus, selectedKey);
          break;
        default:
          break;
      }
    }
  };

  _getMobileSortMenuItems = () => {
    const mobileSortItems = {
      [constants.LIST_SORT_KEYS.airline]: {
        checked: false,
        label: 'Sort by: Airline',
        disabled: false,
      },
      [constants.LIST_SORT_KEYS.equipment]: {
        checked: false,
        label: 'Sort by: Equipment',
        disabled: false,
      },
      [constants.LIST_SORT_KEYS.terminal]: {
        checked: false,
        label: 'Sort by: Terminal',
        disabled: false,
      },
      [constants.LIST_SORT_KEYS.gate]: {
        checked: false,
        label: 'Sort by: Gate',
        disabled: false,
      },
      [constants.LIST_SORT_KEYS.scheduledGate]: {
        checked: false,
        label: 'Sort by: Scheduled Gate',
        disabled: false,
      },
      [constants.LIST_SORT_KEYS.actualGate]: {
        checked: false,
        label: 'Sort by: Actual Gate',
        disabled: false,
      },
      [constants.LIST_SORT_KEYS.scheduledRunway]: {
        checked: false,
        label: 'Sort by: Scheduled Runway',
        disabled: false,
      },
      [constants.LIST_SORT_KEYS.actualRunway]: {
        checked: false,
        label: 'Sort by: Actual Runway',
        disabled: false,
      },
      [constants.LIST_SORT_KEYS.status]: {
        checked: false,
        label: 'Sort by: Status',
        disabled: false,
      },
    };
    if (this.props.depArr === 'arrivals') {
      mobileSortItems[constants.LIST_SORT_KEYS.baggage] = {
        checked: false,
        label: 'Sort by: Baggage',
        disabled: false,
      };
      mobileSortItems[constants.LIST_SORT_KEYS.origin] = {
        checked: false,
        label: 'Sort by: Origin',
        disabled: false,
      };
    } else {
      mobileSortItems[constants.LIST_SORT_KEYS.destination] = {
        checked: false,
        label: 'Sort by: Destination',
        disabled: false,
      };
    }

    return createDimensionMenuItems(mobileSortItems, 'MobileSort');
  };

  // Determines if the header text needs an arrow and which direction
  _columnHeaderTitle = (title, titleKey) => {
    let str = title;
    if (this.mediaBreakpoint && (this.mediaBreakpoint.name === 'desktopSm' && this.state.columnSort === titleKey)) {
      if (str === 'Scheduled') {
        str = 'Sched';
      } else if (str === 'Terminal') {
        str = 'Term';
      } else if (str === 'Destination') {
        str = 'Dest';
      } else if (str === 'Origin') {
        str = 'Orig';
      }
    }
    if (window.innerWidth < breakpoints.desktopLg) {
      if (str === 'Equipment') {
        str = 'Equip';
      }
      if (str === 'Baggage') {
        str = 'Bag';
      }
    }

    let caret = '';
    if (this.state.columnSort === titleKey) {
      if (this.state.sortAscending) {
        caret = <FontIcon className='material-icons' style={{ fontSize: '13px', top: '3px', display: 'inline' }}>&#xE316;</FontIcon>;
      } else {
        caret = <FontIcon className='material-icons' style={{ fontSize: '13px', top: '3px', display: 'inline' }}>&#xE313;</FontIcon>;
      }
    }

    return (<p style={{ wordSpacing: '-4px', maxHeight: '15px' }}>{caret} {str}</p>);
  };

  // Creates column header button
  _columnHeader = (title, titleKey, list) => (
    <div className={`${title} ${this.props.depArr}`} >
      <FlatButton
        disableTouchRipple
        backgroundColor='transparent'
        primary
        onClick={this._handleSortClick.bind(this, list, titleKey)}
        labelStyle={{ padding: '0 5px' }}
        style={{
 margin: null, minWidth: '100%', height: '38px', textTransform: 'capitalize', fontSize: '11pt', fontWeight: '0',
}}
      >
        {this._columnHeaderTitle(title, titleKey)}
      </FlatButton>
    </div>
  );

  _matchCodesharesToMainFlight = () => {
    if (this.state.columnSort === 'airline') return;

    const localFlightList = this.flightsList;
    const flightsByGroups = groupBy(localFlightList, (item) => {
      if (item.mainFlight) {
        return [item.mainFlight];
      }
      return [item.flight];
    });

    const flightsToShow = [];

    for (let i = 0; i < flightsByGroups.length; i++) {
      const flights = flightsByGroups[i];

      if (flights.length === 1) {
        flightsToShow.push(flights[0]);
      } else {
        // Parse to get main flight on top, codeshares under
        for (let j = 0; j < flights.length; j++) {
          const flight = flights[j];
          if (!flight.isCodeshare) {
            flightsToShow.push(flight);
            flights.splice(j, 1);
            break;
          }
        }

        for (let j = 0; j < flights.length; j++) {
          flightsToShow.push(flights[j]);
        }
      }
    }

    this.flightsList = flightsToShow;
  };

  _getPage = (index) => {
    // this.flightsList = this.props.list;
    // this.flightsList = this.props.defaultSorted; //this.props.dimensions.flightsByScheduledGate.bottom(Infinity);

    if (this.sortedList) {
      this.flightsList = this.state.sortAscending ? this.sortedList.bottom(Infinity) : this.sortedList.top(Infinity);
    } else {
      this.flightsList = this.props.defaultSorted; // this.props.dimensions.flightsByScheduledGate.bottom(Infinity);
    }

    this.totalSortedFlights = this.flightsList.length;

    this.pageCount = Math.ceil(this.totalSortedFlights / MAX_ITEMS_PER_PAGE);
    this.pages = [];

    this._matchCodesharesToMainFlight();

    if (this.totalSortedFlights <= MAX_ITEMS_PER_PAGE) {
      return this.flightsList;
    }

    for (let i = 0; i < this.pageCount; i++) {
      const rangeStart = i * constants.PAGE_SIZE;
      const rangeEnd = (i === (this.pageCount - 1)) ? this.totalSortedFlights : ((i + 1) * MAX_ITEMS_PER_PAGE);
      this.pages[i] = this.flightsList.slice(rangeStart, rangeEnd);
    }

    const newPagesSummary = this.getPagesSummary(this.flightsList);
    if (this.listHasChanged(this.pagesSummary, newPagesSummary)) {
      // going back to index 1
      this.setState({
        pageIndex: 1,
      }, () => (this.pages[this.state.pageIndex - 1]));
    }

    this.pagesSummary = newPagesSummary;

    return this.pages[index - 1];
  };

  _handlePageClick = (pageNumber) => {
    this.context.reportUserEvent('Select Page', `Page ${pageNumber}`);
    this.setState({
      pageIndex: pageNumber,
    });
  };

  handleCodeshareToggle = () => {
    if (this.hideCodeshares) {
      this.props.handleCodeshareToggle();
      this.setState({ pageIndex: 1 });
    }
  };

  listHasChanged = (pagesSummary, newPagesSummary) => {
    /*
      in the array of results, if the first flightId is different,
      or the last flightId is different,
      or the length of the results array has changed,
      we say the list has changed.
      it is possible that the list changes, but these conditions are not met.
      that should be rare, though :^) - et
    */
    if (!equals(pagesSummary, newPagesSummary)) {
      return true;
    }
    return false;
  }

  render() {
    let mobileSort = null;
    if (this.mediaBreakpoint && (this.mediaBreakpoint.name === 'tablet' || this.mediaBreakpoint.name === 'mobile')) {
      const mobileSortTitle = this.mobileSortMenuItems[this.state.columnSort].label;
      mobileSort = (
        <div>
          <StandardFSDropDown
            title={mobileSortTitle}
            className='select-background'
            style={{ backgroundColor: '#FAA718', borderColor: '#FAA718' }}
            onChange={this._handleMobileSortSelected}
            list={menuItemsToArray(this.mobileSortMenuItems, false, true)}
          />
        </div>
      );
    }

    const list = this._getPage(this.state.pageIndex);
    const hideLowerElements = this.totalSortedFlights === 0;
    const moreThanOnePage = this.pageCount > 1;
    const max3PageCount = Math.min(this.pageCount, 3);

    const paginator = () => (
      moreThanOnePage ? (
        <Pagination
          activePage={this.state.pageIndex}
          itemsCountPerPage={MAX_ITEMS_PER_PAGE}
          totalItemsCount={this.totalSortedFlights}
          pageRangeDisplayed={max3PageCount}
          onChange={this._handlePageClick}
          activeClass='paginatorSelectedIndex'
          nextPageText='→'
          prevPageText='←'
        />
      ) : null
    );

    const rowBelowChart = (
      <div className='flight-filters'>
        <div style={{ verticalAlign: 'top', padding: '10px 14px 5px 10px' }}>
          <div className='codeshare-toggle-container'>
            <div className='row'>
              <div className='col-xs-4'>
                <em>Codeshares</em>
              </div>
              <div className='col-xs-3 right-align'>
                <em>Show</em>
              </div>
              <div className='col-xs-2 center-align right-spaced'>
                <ToggleSwitch
                  name='hideCodeshares'
                  ref={ref => (this.hideCodeshares = ref)}
                  onValue='hide'
                  offValue='show'
                  callback={this.handleCodeshareToggle}
                  on={this.props.toggleOn}
                />
              </div>
              <div className='col-xs-2 mobile-justification'>
                <em>Hide</em>
              </div>
            </div>
          </div>
        </div>
        {mobileSort}
        <div className={`paginatorContainer ${moreThanOnePage ? 'paginatorContainerTop' : ''}`}>
          {paginator('Top')}
        </div>
      </div>
    );

    let baggageClaimHeaderTitle = null;
    if (this.props.depArr === 'arrivals') {
      baggageClaimHeaderTitle = (<div className='baggage-header-title'>{this._columnHeader('Baggage', 'baggage', this.props.dimensions.flightsByBaggage)}</div>);
    }

    let airportColumnHeader = null;
    if (this.props.depArr === 'departures') {
      airportColumnHeader = this._columnHeader('Destination', constants.LIST_SORT_KEYS.destination, this.props.dimensions.flightsByDestOriginAirportCode);
    } else {
      airportColumnHeader = this._columnHeader('Origin', constants.LIST_SORT_KEYS.origin, this.props.dimensions.flightsByDestOriginAirportCode);
    }

    const timesLabel = this.props.depArr;

    const tableHeader = (
      <div className={`flight-list-header ${this.props.depArr}`}>
        {airportColumnHeader}
        {this._columnHeader('Flight', constants.LIST_SORT_KEYS.airline, this.props.dimensions.flightsByFlightCode)}
        {this._columnHeader('Equipment', constants.LIST_SORT_KEYS.equipment, this.props.dimensions.flightsByEquipment)}
        {this._columnHeader('Terminal', constants.LIST_SORT_KEYS.terminal, this.props.dimensions.flightsByTerminal)}
        {this._columnHeader('Gate', constants.LIST_SORT_KEYS.gate, this.props.dimensions.flightsByGate)}
        <div className={`flight-time-block opaque-column ${this.props.depArr}`}>
          <p>Gate {timesLabel} Times</p>
          {this._columnHeader('Scheduled', constants.LIST_SORT_KEYS.scheduledGate, this.props.dimensions.flightsByScheduledGate)}
          {this._columnHeader('Actual', constants.LIST_SORT_KEYS.actualGate, this.props.dimensions.flightsByActualGate)}
        </div>
        <div className={`flight-time-block ${this.props.depArr}`}>
          <p>Runway {timesLabel} Times</p>
          {this._columnHeader('Scheduled', constants.LIST_SORT_KEYS.scheduledRunway, this.props.dimensions.flightsByScheduledRunway)}
          {this._columnHeader('Actual', constants.LIST_SORT_KEYS.actualRunway, this.props.dimensions.flightsByActualRunway)}
        </div>
        {baggageClaimHeaderTitle}
        {this._columnHeader('Status', constants.LIST_SORT_KEYS.status, this.props.dimensions.flightsByStatus)}
      </div>
    );

    return (
      <div className='flight-filters-header'>
        <div className='opaque' style={{ marginTop: '10px' }}>
          {rowBelowChart}
          {!hideLowerElements &&
            <List
              header={tableHeader}
              depArr={this.props.depArr}
              params={this.props.params}
              timesLabel={timesLabel}
              list={list}
            />
          }
          <div className={`paginatorContainer ${moreThanOnePage ? 'paginatorContainerBottom' : ''}`}>
            {paginator('Bottom')}
          </div>
        </div>
      </div>
    );
  }
}

export default ListWrapper;
