import { is } from 'ramda';

/* eslint-disable no-useless-escape */
export const joinLiteralCharactersRegex = str => new RegExp(`\/(${str
  .split('')
  .map(char => `[${char}]`)
  .join('')})\/`,
'i',
); // 'ab*' => /\/([a][b][*])\//i
/* eslint-enable no-useless-escape */

export const upperCaseParam = (str, code) => `${str}/`
  .replace(
    joinLiteralCharactersRegex(code),
    `/${code}/`.toUpperCase(),
  )
  .substr(0, str.length);

export const formatCanonicalParams = (pathname, { airportCode, airlineCode, arrivalAirportCode }) =>
  [airportCode, airlineCode, arrivalAirportCode]
    .reduce((str, code) => (is(String, code) ? upperCaseParam(str, code) : str), pathname);
