import React from 'react';
import PropTypes from 'prop-types';
import { SelectField } from '@flightstats/component-lib';
import { identity, path, pathOr, type } from 'ramda';
import moment from 'moment';
import { push } from 'connected-react-router';

import FSFlatButton from '../../../../../../src/components/lib/FSFlatButton';
import AirlineSelect from '../../../../../../src/components/lib/AirlineSelect';
import ToggleSwitch from '../../../../../../src/components/lib/ToggleSwitch';
import { dateFormatString } from '../../../../../../src/lib/date-time-format';
import {
  loadByArrivingAirportCarrierAndDate,
  loadByDepartureAirportCarrierAndDate,
  loadByRouteCarrierAndDate,
  setMultiFlightResultsSearchError,
  setMultiFlightResultsSearchAirlineInput,
  updateMultiFlightSearchDate,
  updateMultiFlightSearchTime,
  updateMultiFlightSearchCodeShareToggle,
  updatePageIndex,
} from '../../../../redux/actions';
import Ad from '../../../../../../shared/components/Ad/Ad';
import { setByAirportAirline } from '../../../../../FlightTracker/redux/Search/actions';

const refs = {};

const setRef = (ref, key) => (refs[key] = ref);

const changeDepartureTime = (dispatch, option, triggeredByEnter) => {
  dispatch(updateMultiFlightSearchTime(option, triggeredByEnter));
};

const changeDepartureDate = (dispatch, option, triggeredByEnter) => {
  dispatch(updateMultiFlightSearchDate(option, triggeredByEnter));
};

const changeAirline = (dispatch, option, triggeredByEnter) => {
  dispatch(setByAirportAirline(option, triggeredByEnter));
};

const handleRefineSearch = (
  dispatch,
  selectedFilterAirline = {},
  multiFlightSearchDate,
  multiFlightSearchTime,
  match = {},
  dates,
  times,
  user,
  multiFlightResultsAirlineSelectValue,
) => {
  const airline = selectedFilterAirline.option;
  const { triggeredByEnter } = selectedFilterAirline;
  changeAirline(dispatch, airline, triggeredByEnter);
  const showCodeshares = path(['hideCodeshares', 'checked'], refs);
  const defaultNoAirline = 'N/A';
  const source = pathOr(defaultNoAirline, ['value', '_source'], airline);
  const twoLetterCode = (source && (source.fs || source.iata || source.icao));
  const datePref = dateFormatString(user);
  const selectedDate = dates[multiFlightSearchDate].label;
  const momentizedSelectedDate = moment(selectedDate, datePref);
  const { params } = match;
  if (multiFlightResultsAirlineSelectValue && source === 'N/A') {
    dispatch(setMultiFlightResultsSearchError('Please enter a valid airline'));
    return;
  }
  const args = {
    ...match.params,
    carrier: twoLetterCode || params.carrier,
    year: selectedDate ? momentizedSelectedDate.clone().format('YYYY') : params.year,
    month: selectedDate ? momentizedSelectedDate.clone().format('M') : params.month,
    day: selectedDate ? momentizedSelectedDate.clone().format('D') : params.day,
    hour: `${multiFlightSearchTime}` || `${params.hour}`, // string because '0' is truthy while 0 is falsy
  };

  dispatch(updateMultiFlightSearchCodeShareToggle(showCodeshares));
  dispatch(updatePageIndex(1, '')); // reset pagination to 1

  const {
    year,
    month,
    day,
    hour,
  } = args;

  const queryString = `?year=${year}&month=${month}&date=${day}&hour=${hour}`;
  const adjustedArgs = source === defaultNoAirline ?
    {
      ...args,
      carrier: '',
      queryString,
    } :
    {
      ...args,
      queryString,
    };

  const {
    arrivalAirportCode,
    departureAirportCode,
    carrier,
  } = adjustedArgs;

  if (arrivalAirportCode && departureAirportCode) {
    let arrivalDeparturesUrl = `/flight-tracker/route/${departureAirportCode}/${arrivalAirportCode}`;
    if (carrier) {
      arrivalDeparturesUrl += `/${carrier}`;
    }
    arrivalDeparturesUrl += queryString;
    dispatch(loadByRouteCarrierAndDate(adjustedArgs));
    return dispatch(push(arrivalDeparturesUrl));
  }

  if (arrivalAirportCode) {
    let arrivalAirportUrl = `/flight-tracker/arrivals/${arrivalAirportCode}`;
    if (carrier) {
      arrivalAirportUrl += `/${carrier}`;
    }
    arrivalAirportUrl += queryString;
    dispatch(loadByArrivingAirportCarrierAndDate(adjustedArgs));
    return dispatch(push(arrivalAirportUrl));
  }

  let departureAirportUrl = `/flight-tracker/departures/${departureAirportCode}`;
  if (carrier) {
    departureAirportUrl += `/${carrier}`;
  }
  departureAirportUrl += queryString;

  dispatch(loadByDepartureAirportCarrierAndDate(adjustedArgs));
  return dispatch(push(departureAirportUrl));
};

const handleCodeshareCallback = (reportSelection) => {
  const showCodeshares = path(['hideCodeshares', 'checked'], refs);
  // callback runs as page is loaded with undefined, we are just tracking user interaction here
  (showCodeshares !== undefined) && reportSelection(`${showCodeshares ? 'Hide' : 'Show'}`);
};

export default class MultiFlightSearch extends React.Component {
  static propTypes = {
    byAirportAirline: PropTypes.object,
    dates: PropTypes.array,
    dispatch: PropTypes.func,
    isMobileScreen: PropTypes.bool,
    match: PropTypes.object,
    multiFlightSearchDate: PropTypes.number,
    multiFlightSearchTime: PropTypes.number,
    multiFlightSearchCodeShareToggle: PropTypes.bool,
    multiFlightResultsSearchError: PropTypes.string,
    multiFlightResultsAirlineSelectValue: PropTypes.string,
    resultsTopAd: PropTypes.object,
    reportUserEvent: PropTypes.func,
    timeOfTravelOptions: PropTypes.array,
    user: PropTypes.object,
  };

  constructor(props) {
    super(props);

    const { byAirportAirline } = props;

    this.state = {
      selectedFilterAirline: {
        option: byAirportAirline,
      }, // state okay here, don't need this data to persist
    };
  }

  createFilterTitle = (match) => {
    const {
      arrivalAirportCode,
      departureAirportCode,
    } = match.params;

    if (departureAirportCode && arrivalAirportCode) {
      return `${departureAirportCode} to ${arrivalAirportCode} Flight Status Filter`;
    } else if (departureAirportCode) {
      return `${departureAirportCode} Departures Filter`;
    }
    return `${arrivalAirportCode} Arrivals Filter`;
  }

  hasAtLeastAStandardSubscription = user => !!pathOr(0, ['subscriptionLevel'], user);

  reportSelection = (action) => {
    this.props.reportUserEvent('ChangeCriteria', `FlightTrackerByAirportDesktop-${action}`);
  };

  render() {
    const {
      dates,
      dispatch,
      isMobileScreen,
      match,
      multiFlightSearchDate,
      multiFlightSearchTime,
      multiFlightSearchCodeShareToggle,
      multiFlightResultsSearchError,
      multiFlightResultsAirlineSelectValue,
      resultsTopAd,
      timeOfTravelOptions,
      user,
    } = this.props;

    const hasAtLeastAStandardSubscription = this.hasAtLeastAStandardSubscription(user);
    const filterTitle = this.createFilterTitle(match);

    return (
      <div>
        <form className='multi-flight-search desktop'>
          <h2 className='section-header'>
            { filterTitle }
          </h2>
          <section className={`${multiFlightResultsSearchError ? 'error' : ''}`}>
            <h4>
              { multiFlightResultsSearchError }
            </h4>
          </section>
          <section className='section-body'>
            <div className={`${hasAtLeastAStandardSubscription ? 'subscribed' : 'left'}`}>
              <div className={`control${hasAtLeastAStandardSubscription ? ' subscribed' : ''}`}>
                <div className={`refine ${hasAtLeastAStandardSubscription ? 'subscribed' : 'top'}`}>
                  <div>
                    <span className='label'>
                      Airline (optional)
                    </span>
                    <AirlineSelect
                      name='airline'
                      airline={path(['selectedFilterAirline', 'option'], this.state)}
                      handleInput={
                        input => dispatch(setMultiFlightResultsSearchAirlineInput(input))
                      }
                      onChange={
                        (option, triggeredByEnter) => {
                          this.reportSelection('Airline');
                          dispatch(setMultiFlightResultsSearchError(''));
                          this.setState({
                            selectedFilterAirline: {
                              option,
                              triggeredByEnter,
                            },
                          });
                        }
                      }
                      onDelete={
                        () => {
                          this.reportSelection('DeleteAirline');
                          dispatch(setMultiFlightResultsSearchAirlineInput());
                          this.setState({
                            selectedFilterAirline: {
                              option: null, // null is the default value in redux
                            },
                          });
                        }
                      }
                      secondaryAction={identity}
                      selectOnBlur
                    />
                  </div>
                  <div>
                    <span className='label'>
                      Show Codeshares?
                    </span>
                    <div className='toggle'>
                      <span className='show'>
                        Show
                      </span>
                      <ToggleSwitch
                        callback={() => handleCodeshareCallback(this.reportSelection)}
                        name='hideCodeshares'
                        ref={ref => setRef(ref, 'hideCodeshares')}
                        onValue='true'
                        offValue='false'
                        on={multiFlightSearchCodeShareToggle}
                      />
                      <span className='hide'>
                        Hide
                      </span>
                    </div>
                  </div>
                </div>
                <div className={`refine ${hasAtLeastAStandardSubscription ? 'subscribed' : 'bottom'}`}>
                  <div>
                    <span className='label'>
                      Date
                    </span>
                    {
                      type(multiFlightSearchDate) === 'Number' ?
                        <SelectField
                          value={multiFlightSearchDate}
                          results={dates}
                          handleMenuSelection={
                            (option, triggeredByEnter) => {
                              this.reportSelection('Date');
                              changeDepartureDate(dispatch, option, triggeredByEnter);
                            }
                          }
                          iconColor='white'
                          textColor='white'
                          placeholderColor='white'
                          bgColor='rgba(191, 191, 191, 0.66)'
                          selectWrapperPosition='relative'
                          inputFieldBg={false}
                          secondaryAction={identity}
                        /> :
                        <input className='sham' disabled='disabled' />
                    }
                  </div>
                  <div>
                    <span className='label'>
                      Time
                    </span>
                    {
                      type(multiFlightSearchTime) === 'Number' ?
                        <SelectField
                          value={multiFlightSearchTime}
                          results={timeOfTravelOptions}
                          handleMenuSelection={
                            (option, triggeredByEnter) => {
                              this.reportSelection('TimePeriod');
                              changeDepartureTime(dispatch, option, triggeredByEnter);
                            }
                          }
                          iconColor='white'
                          textColor='white'
                          placeholderColor='white'
                          bgColor='rgba(191, 191, 191, 0.66)'
                          selectWrapperPosition='relative'
                          inputFieldBg={false}
                          secondaryAction={identity}
                        /> :
                        <input className='sham' disabled='disabled' />
                    }
                  </div>
                </div>
              </div>
              <div className={`refine button${hasAtLeastAStandardSubscription ? ' subscribed' : ''}`}>
                <FSFlatButton
                  label='REFINE SEARCH'
                  onClick={
                    () => {
                      handleRefineSearch(
                        dispatch,
                        this.state.selectedFilterAirline,
                        multiFlightSearchDate,
                        multiFlightSearchTime,
                        match,
                        dates,
                        timeOfTravelOptions,
                        user,
                        multiFlightResultsAirlineSelectValue,
                        this.props,
                      );
                    }
                  }
                />
              </div>
            </div>
            <div className={`${hasAtLeastAStandardSubscription ? 'hide' : 'right'}`}>
              {!isMobileScreen && <Ad
                key={resultsTopAd.id}
                {...resultsTopAd}
                subscriptionActive={hasAtLeastAStandardSubscription}
              />}
            </div>
          </section>
        </form>
      </div>
    );
  }
}
