import moment from 'moment-timezone';
import superagent from 'superagent';
import config from '../config';
import apiToken from './ApiToken';
import { randomString } from '../../shared/lib/random';

const methods = ['get', 'post', 'put', 'patch', 'del'];

function formatUrl(path) {
  const adjustedPath = `/v2/api${(path[0] !== '/' ? `/${path}` : path)}`;

  if (__SERVER__) {
    // Prepend host and port of the API server to the path.
    return `http://${config.apiHost}:${config.apiPort}${adjustedPath}`;
  }

  return adjustedPath;
}

class ApiClient {
  serverTimeDiffInSecs = null;
  csrfToken = null;

  constructor(req) {
    if (req) {
      this.setCSRFToken(req.csrf);
    }

    methods.forEach((method) => {
      this[method] = (path, { params, data } = {}) => new Promise((resolve, reject) => {
        const url = formatUrl(path);
        const request = superagent[method](url);
        const token = apiToken(this.serverTimeDiffInSecs, false);
        request.set('Authorization', `Bearer ${token}`);
        request.set('CSRF-Token', this.csrfToken);
        request.query({
          rqid: randomString(),
        });

        if (params) {
          request.query(params);
        }

        if (__SERVER__ && req && req.get('cookie')) {
          request.set('cookie', req.get('cookie'));
        }

        const endRequest = () => {
          request.end((err, res) => {
            if (err) {
              reject(err);
            } else {
              resolve(res.body);
            }
          });
        };

        const sendDataIfAble = () => {
          if (data) {
            request.send(data);
          }
        };

        const finish = () => {
          sendDataIfAble();
          endRequest();
        };

        if (!__SERVER__) {
          try {
            // eslint-disable-next-line no-undef
            const AWSClient = AwsWafIntegration;
            if (AWSClient) {
              AWSClient.getToken()
                .then((AWSWAFtoken) => {
                  request.set('X-Aws-Waf-Token', AWSWAFtoken);
                  finish();
                })
                .catch(() => {
                  // something happened trying to fetch AWS AWF token
                  // the WAF will not be happy
                  finish();
                });
            }
          } catch (_) {
            // node does not like even referencing variable AwsWafIntegration
            finish();
          }
        } else {
          finish();
        }
      });
    });
  }

  setServerTime(serverTime) {
    if (serverTime) {
      this.serverTimeDiffInSecs = moment.utc().unix() - serverTime;
    }
  }

  setCSRFToken(token) {
    this.csrfToken = token;
  }
}

export default ApiClient;
