import moment from 'moment-timezone';
import { generateToken } from '../../shared/lib/api-token';

export default (givenServerTime = 0, findDiff) => {
  const hostUnixTime = moment.utc().unix();
  const serverTimeDiffInMillis = (findDiff ? hostUnixTime - givenServerTime : givenServerTime);
  const serverTime = moment((hostUnixTime * 1000) + serverTimeDiffInMillis).utc();
  const hash = generateToken(serverTime.unix());

  return hash;
};
