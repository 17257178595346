import F from 'ramda/src/F';
import equals from 'ramda/src/equals';
import ifElse from 'ramda/src/ifElse';

const shouldLock = ({ visibility = [], subscriptionLevel }) =>
  !visibility.includes(subscriptionLevel);

export const setLockedFeatures = ifElse(
  ({ locked }) => equals(true, locked),
  ({ visibility, subscriptionLevel }) => shouldLock({ visibility, subscriptionLevel }),
  F,
);
