import React, { Component } from 'react';
import PropTypes from 'prop-types';
import componentBase from '../../../../../../src/lib/component-base';
import Icon from '../../../../../../src/components/lib/Icon';

@componentBase('PreferenceHeader')
export default class PreferenceHeader extends Component {
  static propTypes = {
    icon: PropTypes.string,
    title: PropTypes.string,
    customClass: PropTypes.string,
  };

  render() {
    const iconStyle = {
      width: '45px',
      height: '45px',
    };

    let mainclasses = 'row preferenceHeader';

    if (this.props.customClass) {
      mainclasses += ` ${this.props.customClass}`;
    }

    return (
      <div className={mainclasses}>
        <Icon className='preferenceIcon' src={this.props.icon} style={iconStyle} />
        <h2 className='preferenceTitle'>{this.props.title}</h2>
      </div>
    );
  }
}
