// 1 is standard subscription, 3 is professional subscription
import { logout } from '../../../../Account/redux/actions';

export const compactMenuData = [
  {
    id: 'X4Qh4q6xz_ETEmBRpRc2Y', // use static IDs to avoid using indices - for performance reasons
    heading: 'Flights',
    headingURL: '/flight-tracker/search',
    submenuWidth: '267px',
    submenuItems: [
      {
        id: 'Rg6DF3JOuNDUr5VbqBJTG',
        heading: 'Flight Tracker',
        link: '/flight-tracker/search',
        locked: false,
        next: true,
      },
      {
        id: 'p0V_oey6boM5yCkWC5f0D',
        heading: 'Historical Flight Status',
        link: '/historical-flight/search',
        locked: true,
        next: false,
        visibility: [11, 3],
      },
      {
        id: 'Vu3wUit2pq8ysXKtBBENN',
        heading: 'Alerts',
        link: '/flight-alerts/home',
        locked: true,
        next: false,
        visibility: [11, 3, 1],
      },
    ],
  },
  {
    id: 'clvjePlpJT0oWEvpKCOhh',
    heading: 'Airports',
    headingURL: '/airport-conditions/search',
    submenuWidth: '280px',
    submenuItems: [
      {
        id: 'vq~p7WD0H3SYvyqcmmBIZ',
        heading: 'Current Conditions',
        link: '/airport-conditions/search',
        locked: false,
        next: false,
      },
      {
        id: 'eGnG3rYDySZnJq_HjxY7J',
        heading: 'Departures and Arrivals',
        link: '/airports/departing-arriving/search',
        locked: true,
        next: false,
        visibility: [11, 3, 1],
      },
      {
        id: 'YJjDOKs3PwXf4Hq7jCvN9',
        heading: 'Airport Delays',
        link: '/airport-delays',
        locked: false,
        next: false,
      },
    ],
  },
  {
    id: 'clvjePlpJT0oWEvpKCOhh-bb8',
    heading: 'On-Time Performance',
    submenuWidth: '280px',
    submenuItems: [
      {
        id: 'CbzWSz3MnJOjNYx5mDtkz-r2d2',
        heading: 'On-Time Performance Reports',
        link: 'https://www.cirium.com/resources/on-time-performance/?cmpid=ilc%7Cotp%7C202401_glob_airline-airports%7Con-time-performance_m',
        locked: false,
        next: false,
      },
      {
        id: 'CbzWSz3MnJOjNYx5mDtkz-C3Po',
        heading: 'On-Time Monthly Update',
        link: 'https://www.cirium.com/resources/on-time-performance/?cmpid=ilc%7Cotp%7C202401_glob_airline-airports%7Con-time-performance_m',
        locked: false,
        next: false,
      },
      {
        id: 'CbzWSz3MnJOjNYx5mDtkz-BB8',
        heading: 'On-Time Performance Articles',
        link: 'https://www.cirium.com/thoughtcloud-categories/on-time-performance/?cmpid=ilc%7Cairlines-airports%7CCI909-2022-on-time-review',
        locked: false,
        next: false,
      },
    ],
  },
  {
    id: 'U5XGj0_izmjR5Lc8Bcdij',
    heading: 'Cirium',
    submenuRightJustified: true,
    submenuWidth: '180px',
    submenuItems: [
      {
        id: 'Nf8M4FOheh0jzfhACXC3U',
        heading: 'About',
        link: 'https://www.cirium.com/about/',
        locked: false,
        next: false,
      },
      {
        id: '8htDewLhhVLwXvyo8DpZ2',
        heading: 'Cirium',
        link: 'https://www.cirium.com',
        locked: false,
        next: false,
      },
      {
        id: 'xQNPZN5Gw20dJxD9w5nv8',
        heading: 'Thought Cloud',
        link: 'https://www.cirium.com/thoughtcloud/',
        locked: false,
        next: false,
      }
    ],
  },
  {
    id: '9f0xVEuL3g1JCzzUicCj9',
    heading: 'Resources',
    submenuRightJustified: true,
    submenuWidth: '180px',
    submenuItems: [
      {
        id: 'XvxRzKD8ARvmkpKCDAcors',
        heading: 'Support',
        link: 'https://helpdesk.cirium.com/s/topic/0TOJ5000000TNJ1OAO/flightstats-by-cirium',
        locked: false,
        next: false,
      },
      {
        id: 'fksg74DbHngmynWe9~hAWX',
        heading: 'Developer Studio',
        link: 'https://developer.cirium.com/?inctmp=flightstats-resources-menu',
        locked: false,
        next: false,
      },
      {
        id: 'OH_euKHQhiMlZmAkuvroOp',
        heading: 'Careers',
        link: 'https://relx.wd3.myworkdayjobs.com/ciriumcareers',
        locked: false,
        next: false,
      },
      {
        id: 'ENxlIrMvZIe~yhGCEVZFTU',
        heading: 'Contact',
        link: 'https://www.cirium.com/contact-us/?cmpid=ILC%7CCIR%7CCIR-flightstats-contact',
        locked: false,
        next: false,
      },
    ],
  },
];

export const accountMenuData = {
  id: 'X4Qh4q6xz_ETEmBRpRc2Y', // use static IDs to avoid using indices - for performance reasons
  heading: 'Account',
  submenuWidth: '267px',
  submenuItems: [
    {
      id: 'maxYAbXMtu7iF8sxa2bz_',
      heading: 'Create Account',
      link: '/register/plan',
      locked: false,
      next: false,
      isLoggedIn: false,
    },
    {
      id: 'pltkiqwkz9gs3QWwmkyOL',
      heading: 'Log In',
      link: '/account/login',
      locked: false,
      next: false,
      isLoggedIn: false,
    },
    {
      id: 'RLbnK9BNq3bvwQAyXGUhw',
      heading: 'Settings',
      link: '/my-account/profile',
      locked: false,
      next: false,
      isLoggedIn: true,
    },
    {
      id: 'eWFmbui6aNrHDccol9Lae',
      heading: 'Log Out',
      link: '/account/logout',
      locked: false,
      next: false,
      isLoggedIn: true,
      clickHandler: ({ dispatch }) => {
        dispatch(logout());
      },
    },
  ],
};
