import React, { Component } from 'react';
import PropTypes from 'prop-types';
import componentBase from '../../../../../../src/lib/component-base';
import AccountError from './AccountError';
import { PasswordValidation } from '../../../../../../shared/components/PasswordValidationRules/PasswordValidation';


@componentBase('ChangePassword')
export default class ChangePassword extends Component {
  static propTypes = {
    errors: PropTypes.object,
    setChangePasswordButtonDisabled: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      newPassword: '',
    };
    // refs below
    this.oldPassword = null;
    this.newPassword = null;
    this.passwordConfirm = null;
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  errorClass = field => (this.props.errors && this.props.errors[field] && 'error');

  render() {
    const { errors } = this.props;
    return (
      <div className='row account-large-modal'>
        <section className='change-password'>
          <div className='row'>
            <div className='col-xs-12 col-md-5'>
              <h3>Your Old Password</h3>
              <AccountError name='oldPassword' errorCode={errors && errors.oldPassword} />
              <input
                type='password'
                ref={ref => (this.oldPassword = ref)}
                placeholder='•••••••••'
                className={`account-input padded ${this.errorClass('oldPassword')}`}
              />
              <h3 className='spaced'>Your New Password</h3>
              <AccountError name='newPassword' errorCode={errors && errors.newPassword} />
              <input
                type='password'
                ref={ref => (this.newPassword = ref)}
                placeholder='•••••••••'
                className={`account-input padded ${this.errorClass('newPassword')}`}
                onChange={(event) => {
                  this.setState({ newPassword: event.target.value });
                }}
              />
              <h3 className='spaced'>Confirm Your New Password</h3>
              <AccountError name='passwordConfirm' errorCode={errors && errors.passwordConfirm} />
              <input
                type='password'
                ref={ref => (this.passwordConfirm = ref)}
                placeholder='•••••••••'
                className={`account-input padded ${this.errorClass('passwordConfirm')}`}
              />
            </div>
            <div className='col-xs-12 col-md-1'>
              &nbsp;
            </div>
            <div className='col-xs-12 col-md-5 tips password-validation-wrapper'>
              <PasswordValidation
                password={this.state.newPassword}
                validationCallback={(value) => {
                  const { valid } = value;
                  this.props.setChangePasswordButtonDisabled(valid);
                }}
              />
            </div>
          </div>
        </section>
      </div>
    );
  }
}
