import React from 'react';
import PropTypes from 'prop-types';
import PlanSelectionChart from './children/PlanSelectionChart';
import { icons, featureChartTitles } from '../../../../lib/constants';
import HighlightedSection from '../shared/HighlightedSection';
import NeedABusinessAccount from '../../../../../../shared/components/NeedABusinessAccount/NeedABusinessAccount';

const PlanSelection = (props) => {
  const {
    freeIcon,
    professionalSubscriptionIcon,
    standardSubscriptionIcon,
    premiumIcon,
    ListIcon,
  } = icons;

  const {
    createText,
    completeText,
    handlePlanSelection,
    handlePlanHoverState,
    handleRegistrationNavigation,
    isFree,
    planHovered,
    planSelected,
    routerPush,
    reportButtonPress,
    user,
  } = props;

  const selectedAccountAction = (e, createRoute, selectedPlan, hasAccount) => {
    if (!hasAccount) {
      handleRegistrationNavigation(e, createRoute);
    } else if (isFree(selectedPlan)) {
      handleRegistrationNavigation(e, completeText);
    } else {
      routerPush('/register/billing-information');
    }
    handlePlanSelection(selectedPlan);
    reportButtonPress(`Selected ${selectedPlan}`);
  };

  const featureChart = (
    <div className='new-account-plan-selection-wrapper subscription-feature-info-container' >
      <PlanSelectionChart
        featureChartTitles={featureChartTitles}
        chartHeaderText='Select a Plan'
        freeIcon={freeIcon}
        handlePlanSelection={handlePlanSelection}
        handlePlanHoverState={handlePlanHoverState}
        planHovered={planHovered}
        planSelected={planSelected}
        premiumIcon={premiumIcon}
        professionalSubscriptionIcon={professionalSubscriptionIcon}
        standardSubscriptionIcon={standardSubscriptionIcon}
        user={user}
      />
    </div>
  );

  return (
    <div className='plan-selected'>
      <HighlightedSection
        body={featureChart}
        buttonDisabled={false}
        buttonTitle='I WANT THIS PLAN'
        footerTitle={props.planSelected ? 'You have selected a ' : ''}
        handleAlreadyHaveAccountClick={props.handleAlreadyHaveAccountClick}
        icon={ListIcon}
        loggedIn={props.loggedIn}
        planSelected={props.planSelected}
        submit={e => selectedAccountAction(e, createText, planSelected, user)}
        subTitle={props.accountPresent}
        title='Select a Plan'
      />
      <div style={{ marginTop: '20px', marginBottom: '20px' }}>
        <NeedABusinessAccount />
      </div>
    </div>
  );
};

PlanSelection.propTypes = {
  accountPresent: PropTypes.string,
  createText: PropTypes.string,
  completeText: PropTypes.string,
  handleAlreadyHaveAccountClick: PropTypes.func,
  handlePlanSelection: PropTypes.func,
  handlePlanHoverState: PropTypes.func,
  handleRegistrationNavigation: PropTypes.func,
  isFree: PropTypes.func,
  loggedIn: PropTypes.bool,
  planHovered: PropTypes.string,
  planSelected: PropTypes.string,
  reportButtonPress: PropTypes.func,
  routerPush: PropTypes.func,
  user: PropTypes.object,
};

export default PlanSelection;
