import React from 'react';
import PropTypes from 'prop-types';
import FontIcon from 'material-ui/FontIcon';

const PassiveFrameIcon = props => (
  // panorama_fish_eye
  <FontIcon
    className='material-icons'
    color='rgba(255, 2, 0, 0.5)'
    hoverColor='rgb(255, 2, 0)'
    style={{ textAlign: 'center', display: 'inline', transition: 'all .4s linear' }}
    onClick={props.handleClick}
    value={props.value}
  >
    &#xE40C;
  </FontIcon>
);

PassiveFrameIcon.propTypes = {
  handleClick: PropTypes.func,
  value: PropTypes.number,
};
export default PassiveFrameIcon;
