// these requires MUST be deterministic!!
// export const logo = require('../../static/images/FS_lt_36.png');
export const airportInfoIcon = require('../../static/images/airport-info-icon.png');
export const dangerZoneThumb = require('../../static/images/dangerzone-thumb.png');

export const menuIcons = {
  arrivalPlane: require('../../static/images/menu-icons/arrival-plane-icon.png'),
  arrivalPlane2x: require('../../static/images/menu-icons/arrival-plane-icon@2x.png'),
  bullhorn: require('../../static/images/menu-icons/bullhorn-icon.png'),
  bullhorn2x: require('../../static/images/menu-icons/bullhorn-icon@2x.png'),
  clock: require('../../static/images/menu-icons/clock.png'),
  calendar: require('../../static/images/menu-icons/calendar-icon.png'),
  calendar2x: require('../../static/images/menu-icons/calendar-icon@2x.png'),
  departurePlane: require('../../static/images/menu-icons/departure-plane-icon.png'),
  departurePlane2x: require('../../static/images/menu-icons/departure-plane-icon@2x.png'),
  email: require('../../static/images/menu-icons/email-icon.png'),
  email2x: require('../../static/images/menu-icons/email-icon@2x.png'),
  history: require('../../static/images/menu-icons/history-icon.png'),
  history2x: require('../../static/images/menu-icons/history-icon@2x.png'),
  gears: require('../../static/images/menu-icons/gears.png'),
  plane: require('../../static/images/menu-icons/plane-icon.png'),
  plane2x: require('../../static/images/menu-icons/plane-icon@2x.png'),
  radar: require('../../static/images/menu-icons/radar-icon.png'),
  radar2x: require('../../static/images/menu-icons/radar-icon@2x.png'),
  weather: require('../../static/images/menu-icons/weather-icon.png'),
  weather2x: require('../../static/images/menu-icons/weather-icon@2x.png'),
  flightAlerts: require('../../static/images/menu-icons/FlightAlert-Icons-BLACK.svg'),
  lock: require('../../static/images/menu-icons/LockedLockBLACK.svg'),
};

export const weatherIcons = {
  cloudy: require('../../static/images/weather-icons/Cloudy-WHITE.svg'),
  flurries: require('../../static/images/weather-icons/Flurries-WHITE.svg'),
  fog: require('../../static/images/weather-icons/Fog-WHITE.svg'),
  hail: require('../../static/images/weather-icons/Hail-WHITE.svg'),
  hurricane: require('../../static/images/weather-icons/Hurricane-WHITE.svg'),
  megaStorm: require('../../static/images/weather-icons/MegaStorm-WHITE.svg'),
  moon: require('../../static/images/weather-icons/Moon-WHITE.svg'),
  partlyCloudyDay: require('../../static/images/weather-icons/PartlyCloudy-DAY-WHITE.svg'),
  partlyCloudyNight: require('../../static/images/weather-icons/PartlyCloudy-NIGHT-WHITE.svg'),
  rain: require('../../static/images/weather-icons/Rain-WHITE.svg'),
  snow: require('../../static/images/weather-icons/Snow-WHITE.svg'),
  stormy: require('../../static/images/weather-icons/Stormy-WHITE.svg'),
  sun: require('../../static/images/weather-icons/Sun-WHITE.svg'),
  tornado: require('../../static/images/weather-icons/Tornado-WHITE.svg'),
  veryCloudy: require('../../static/images/weather-icons/VeryCloudy-WHITE.svg'),
  veryRainy: require('../../static/images/weather-icons/VeryRainy-WHITE.svg'),
  verySnowy: require('../../static/images/weather-icons/VerySnowy-WHITE.svg'),
  windy: require('../../static/images/weather-icons/Windy-WHITE.svg'),
  windyRain: require('../../static/images/weather-icons/WindyRain-WHITE.svg'),
};
