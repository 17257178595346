import React from 'react';
import PropTypes from 'prop-types';

const CardAside = (props) => {
  const {
    children,
    noBorder,
    subscribed,
  } = props;

  const classes = {
    noBorder: noBorder ? 'no-border' : '',
    subscribed: subscribed ? 'subscribed' : '',
  };

  return (
    <aside
      className={`flexible-card-aside ${classes.noBorder} ${classes.subscribed}`}
    >
      {children}
    </aside>
  );
};

CardAside.propTypes = {
  children: PropTypes.node,
  noBorder: PropTypes.bool,
  subscribed: PropTypes.bool,
};

export default CardAside;
