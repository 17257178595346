import React from 'react';
import PropTypes from 'prop-types';
import { path, pathOr, propSatisfies } from 'ramda';
import getClientDimensions from '../../../../../shared/lib/get-client-dimensions/index';
import StatusAndPhase from './children/StatusAndPhase';
import Sky from './children/WeatherSkyConditions';
import Wind from './children/WeatherWindConditions';
import Visibility from './children/WeatherVisibilityConditions';
import FSFlatButton from '../../../../../src/components/lib/FSFlatButton';
import Ad from '../../../../../shared/components/Ad/Ad';
import componentBase from '../../../../../src/lib/component-base';
import { AirportCurrentConditions as airportCurrentConditionsAds } from '../../ads';
import onResize from '../../../../../src/lib/on-resize';
import { Colors } from '../../../../../src/constants/style-constants';
import handleFirstLoad from '../../../../../shared/lib/handle-first-component-load';
import { getFSHost } from '../../../../../src/lib/hostname';

const rgbaRed = 'rgba(227, 70, 34, .8)';
const rgbaOrange = 'rgba(250, 168, 23, .8)';
const rgbaYellow = 'rgba(227, 216, 34, .8)';
const rgbaGreen = 'rgba(125, 214, 59, .8)';
const rgbaRedUnknown = 'rgba(227, 70, 34, .2)';
const rgbaOrangeUnknown = 'rgba(250, 168, 23, .2)';
const rgbaYellowUnknown = 'rgba(227, 216, 34, .2)';
const rgbaGreenUnknown = 'rgba(125, 214, 59, .2)';

let Raphael;
let AirportMap = null;

const otpURL = 'https://www.cirium.com/studios/reports/on-time-performance/';

@onResize()
@componentBase('AirportConditionsTracker')
export default class AirportConditionsTracker extends React.Component {
  static propTypes = {
    airport: PropTypes.object,
    airportCode: PropTypes.string,
    currentWeather: PropTypes.object,
    delayIndex: PropTypes.object,
    loaded: PropTypes.bool,
    match: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      firstLoad: true,
    };

    const trackerDivHeight = 125;

    const graphDivHeight = 99;
    const graphBottomDivBorderHeight = 1;
    this.muiDefaultProps = {
      divHeights: {
        tracker: trackerDivHeight,
        indexGraph: graphDivHeight,
        statusAndPhase: 25,
      },
      graphAndPhase: {
        parentDivStyle: {
          padding: 0,
          height: `${trackerDivHeight - 2}px`,
          border: '1px solid white',
        },
        graph: {
          knownGradient: {
            background: `linear-gradient(to right, ${rgbaGreen}, ${rgbaYellow}, ${rgbaOrange}, ${rgbaRed})`, /* Standard syntax */
          },
          unknownGradient: {
            background: `linear-gradient(to right, ${rgbaGreenUnknown}, ${rgbaYellowUnknown}, ${rgbaOrangeUnknown}, ${rgbaRedUnknown})`, /* Standard syntax */
          },
          parentDivStyle: {
            padding: 0,
            height: `${graphDivHeight}px`,
            borderBottom: `${graphBottomDivBorderHeight}px solid #FFFFFF`,
          },
          subDivStyle: {
            padding: 0,
            height: `${graphDivHeight}px`,
            backgroundImage: 'linear-gradient(0deg, transparent 3%, rgba(255, 255, 255, .2) 4%, rgba(255, 255, 255, .2) 5%, transparent 2%, transparent 3%, rgba(255, 255, 255, .2) 4%, rgba(255, 255, 255, .2) 5%, transparent 15%, transparent),' +
            'linear-gradient(90deg, transparent 3%, rgba(255, 255, 255, .2) 4%, rgba(255, 255, 255, .2) 5%, transparent 2%, transparent 3%, rgba(255, 255, 255, .2) 4%, rgba(255, 255, 255, .2) 5%, transparent 15%, transparent)',
            backgroundSize: '5px 5px',
          },
        },
      },
      buttonLabelStyle: {
        width: '90%',
        textAlign: 'center',
        textTransform: 'capitalize',
        fontSize: '12pt',
        paddingLeft: '0',
        paddingRight: '0',
        wordWrap: 'normal',
        lineHeight: '5px',
      },
    };
  }

  componentDidMount() {
    Raphael = require('raphael');
    if (!AirportMap) {
      AirportMap = require('./children/AirportMap').default;
    }
    this.forceUpdate();
    this.initResizeListener();
  }

  componentWillReceiveProps(nextProps) {
    const newState = this.handleFirstLoad(this.state.firstLoad, this.codesMatch(nextProps));
    this.setState(newState);
    this.handleResize(nextProps);
  }

  componentWillUnmount() {
    this.removeResizeListener();
  }

  getAirportCode = () => pathOr(this.airportCode, ['match', 'params', 'airportCode'], this.props);

  getButtonStyle = (refreshButton) => {
    const buttonStyle = FSFlatButton.style({
      minWidth: '148px',
      minHeight: '40px',
    });

    if (refreshButton) {
      delete buttonStyle.backgroundColor;
    }

    return FSFlatButton.style(buttonStyle);
  }

  getMapProps() {
    const { airport } = this.props;
    return {
      airportCode: path(['airportCode'], airport),
      airportName: path(['airportName'], airport),
      addressLine1: path(['addressLine1'], airport),
      addressLine2: path(['addressLine2'], airport),
      latitude: path(['latitude'], airport),
      longitude: path(['longitude'], airport),
    };
  }

  getStatusAndPhaseProps() {
    const { delayIndex } = this.props;

    return {
      score: path(['score'], delayIndex),
      status: path(['status'], delayIndex),
      trend: path(['trend'], delayIndex),
      observed: path(['observed'], delayIndex),
    };
  }

  getSkyProps() {
    const props = {
      available: false,
      icon: null,
      coverage: null,
      tempF: null,
      tempC: null,
    };

    if (this.props.currentWeather) {
      props.icon = this.props.currentWeather.icon;
      props.coverage = this.props.currentWeather.sky;
      props.tempF = this.props.currentWeather.tempF;
      props.tempC = this.props.currentWeather.tempC;

      if (props.icon || props.coverage || props.tempF || props.tempC) {
        props.available = true;
      }
    }

    return props;
  }

  getWindProps() {
    const props = {
      available: false,
      speed: null,
      direction: null,
    };

    if (this.props.currentWeather) {
      props.speed = this.props.currentWeather.wind;
      props.direction = this.props.currentWeather.direction;

      if (props.speed || props.direction) {
        props.available = true;
      }
    }

    return props;
  }

  getVisibilityProps() {
    const props = {
      available: false,
      visibility: null,
      otherWeatherAvailable: (this.getSkyProps().available ||
                              this.getWindProps().available),
    };

    if (this.props.currentWeather) {
      props.visibility = this.props.currentWeather.visibility;

      if (props.visibility) {
        props.available = true;
      }
    }

    return props;
  }

  codesMatch = nextProps => propSatisfies(
    p => p === this.getAirportCode(),
    'airportCode',
    nextProps.match.params,
  );

  handleResize(nextProps) {
    if (nextProps) {
      this.clearGraph();
      this.drawGraph(nextProps);
      this.forceUpdate();
    }
  }

  clearGraph() {
    if (this.paper) {
      this.paper.clear();
    }
  }

  drawGraph(props) {
    const [xWidth, xHeight] = getClientDimensions(document, 'trackerGraph');
    this.paper = this.paper || Raphael(this.refs.trackerGraph, '100%', xHeight); // eslint-disable-line

    // current index vertical line
    if (props.delayIndex &&
        props.delayIndex.score !== null &&
        props.delayIndex.observed) {
      const rawLoc = (xWidth / 5) * props.delayIndex.score;
      const vertLineLoc = rawLoc.toFixed(0);
      this.paper.path(`M${vertLineLoc},5 L${vertLineLoc},7`)
        .attr('stroke', 'rgba(255, 255, 255, 0.6)')
        .attr('stroke-width', '2');
      this.paper.path(`M${vertLineLoc},10 L${vertLineLoc},90`)
        .attr('stroke', 'rgba(255, 255, 255, 0.6)')
        .attr('stroke-width', '2');
      this.paper.path(`M${vertLineLoc},93 L${vertLineLoc},95`)
        .attr('stroke', 'rgba(255, 255, 255, 0.6)')
        .attr('stroke-width', '2');

      if (props.delayIndex.score < 2.5) {
        this.paper.path(`M${(rawLoc + 5).toFixed(0)},${xHeight / 2}L${(rawLoc + 29).toFixed(0)},${(xHeight / 2) - 20}L${(rawLoc + 29).toFixed(0)},${(xHeight / 2) + 20}Z`)
          .attr('stroke', 'white')
          .attr('stroke-width', '1')
          .attr('fill', 'white');
        this.paper.rect((rawLoc + 30).toFixed(0), (xHeight / 2) - 20, 50, 40)
          .attr('fill', 'rgba(255, 255, 255, .1)')
          .attr('stroke', 'white')
          .attr('stroke-width', '2');
        this.paper.text((rawLoc + 55).toFixed(0), xHeight / 2, `${props.delayIndex.score}`)
          .attr('font-size', '18pt')
          .attr('fill', 'rgba(0, 0, 0, 1)');
      } else {
        this.paper.path(`M${(rawLoc - 5).toFixed(0)},${xHeight / 2}L${(rawLoc - 29).toFixed(0)},${(xHeight / 2) - 20}L${(rawLoc - 29).toFixed(0)},${(xHeight / 2) + 20}Z`)
          .attr('stroke', 'white')
          .attr('stroke-width', '1')
          .attr('fill', 'white');
        this.paper.rect((rawLoc - 80).toFixed(0), (xHeight / 2) - 20, 50, 40)
          .attr('fill', 'rgba(255, 255, 255, .1)')
          .attr('stroke', 'white')
          .attr('stroke-width', '2');
        this.paper.text((rawLoc - 55).toFixed(0), (xHeight / 2), `${props.delayIndex.score}`)
          .attr('font-size', '18pt')
          .attr('fill', 'rgba(0, 0, 0, 1)');
      }
    }
  }

  reportEvent = () => this.context.reportEvent('Airport', this.getAirportCode(), null, null, true)

  departuresURL = () => `/flight-tracker/departures/${this.getAirportCode()}`;
  arrivalsURL = () => `/flight-tracker/arrivals/${this.getAirportCode()}`;

  handleFirstLoad = handleFirstLoad(this.reportEvent)

  handleRefreshDataClick() {
    this.context.reportButtonPress('Refresh');
    location.reload();
  }

  render() {
    const skyProps = this.getSkyProps();
    const windProps = this.getWindProps();
    const visProps = this.getVisibilityProps();
    const renderAirportCode = this.getAirportCode();

    const subscriptionActive =
      this.context &&
      this.context.currentUser &&
      this.context.currentUser.subscriptionActive;

    let map = <div />;
    if (AirportMap && this.props.loaded && this.props.airport) {
      map = (<AirportMap {...this.getMapProps()} subscriptionActive={subscriptionActive} />);
    }

    const muiDefaultPropsGraph = this.muiDefaultProps.graphAndPhase.graph;
    const gradient = path(['delayIndex', 'observed'], this.props) ?
      muiDefaultPropsGraph.knownGradient :
      muiDefaultPropsGraph.unknownGradient;

    this.muiDefaultProps.graphAndPhase.graph.parentDivStyle = gradient;

    const graphStyle = {
      ...this.muiDefaultProps.graphAndPhase.graph.parentDivStyle,
      gradient,
    };

    const graph = (
      <div
        id='trackerGraph'
        className='row'
        style={graphStyle}
      >
        <div className='row' style={this.muiDefaultProps.graphAndPhase.graph.subDivStyle}>
          <div className='col-sm-12' style={{ padding: 0, height: 'inherit' }} ref='trackerGraph' />
        </div>
      </div>
    );

    let noWeather = null;
    let noWeatherXS = null;
    if (!skyProps.available && !windProps.available && !visProps.available) {
      noWeather = (
        <div className='hidden-xs col-sm-12' style={{ padding: 0, height: `${this.muiDefaultProps.divHeights.tracker}px`, top: `-${this.muiDefaultProps.divHeights.tracker}px` }}>
          <div
            className='col-sm-12'
            style={{
              padding: 0,
              height: `${this.muiDefaultProps.divHeights.tracker - 2}px`,
              border: '1px solid white',
              background: 'rgba(0, 0, 0, .7)',
            }}
          >
            <div className='col-sm-12' style={{ padding: '0', height: '70%' }}>
              <div
                className='col-sm-12'
                style={{
                  position: 'absolute',
                  padding: '0',
                  bottom: '0',
                  textAlign: 'center',
                }}
              >
                <span style={{ fontSize: '18pt' }}>Current Weather Conditions<br />Are Not Available</span>
              </div>
            </div>
          </div>
        </div>
      );
      noWeatherXS = (
        <div className='visible-xs col-sm-12' style={{ padding: 0, height: `${this.muiDefaultProps.divHeights.tracker * 3}px`, top: `-${this.muiDefaultProps.divHeights.tracker * 3}px` }}>
          <div
            className='col-sm-12'
            style={{
              padding: 0,
              height: `${(this.muiDefaultProps.divHeights.tracker * 3) - 2}px`,
              border: '1px solid white',
              background: 'rgba(0, 0, 0, .7)',
            }}
          >
            <div className='col-sm-12' style={{ padding: '0', width: '100%', height: '55%' }}>
              <div
                className='col-sm-12'
                style={{
                  position: 'absolute',
                  width: 'inherit',
                  padding: '0',
                  bottom: '0',
                  textAlign: 'center',
                }}
              >
                <span style={{ fontSize: '18pt' }}>Current Weather Conditions<br />Are Not Available</span>
              </div>
            </div>
          </div>
        </div>
      );
    }

    const viewDeparturesLabel = `VIEW ${renderAirportCode} DEPARTURES`;
    const viewArrivalsLabel = `VIEW ${renderAirportCode} ARRIVALS`;
    const airportOTPReportLabel = 'VIEW OTP REPORT';

    const adComponents = [
      <Ad
        key={airportCurrentConditionsAds.mapTop.id}
        {...airportCurrentConditionsAds.mapTop}
        subscriptionActive={subscriptionActive}
      />,
    ];

    if (this.mediaBreakpoint && this.mediaBreakpoint.value > 769) {
      adComponents.push(<Ad
        key={airportCurrentConditionsAds.mapBottom.id}
        {...airportCurrentConditionsAds.mapBottom}
        subscriptionActive={subscriptionActive}
      />);
    }

    return (
      <div className='row airport-conditions-tracker' style={{ padding: '0 3vw' }}>
        <div className='col-sm-12 map-container' style={{ padding: 0 }}>
          { map }
          <div className={subscriptionActive ? 'AirportConditionsTrackerAds subscription-active' : 'AirportConditionsTrackerAds'}>
            {adComponents}
          </div>
        </div>
        <div className='col-sm-12'>
          <div className='row'>
            <div className='col-sm-6 delay-index-container'>
              <div className='col-md-12 title'>
                <span style={{ fontSize: '18pt' }}>Current Delay Status</span>
              </div>
              <div className='col-md-12' style={{ padding: 0 }}>
                <div className='col-md-12' style={this.muiDefaultProps.graphAndPhase.parentDivStyle}>
                  { graph }
                  <StatusAndPhase {...this.getStatusAndPhaseProps()} />
                </div>
              </div>
            </div>

            <div className='col-sm-6 weather-container'>
              <div className='col-md-12 title'>
                <span style={{ fontSize: '18pt' }}>Current Weather Conditions</span>
              </div>
              <div className='col-md-12' style={{ padding: 0 }}>
                <div className='col-sm-4 col-xs-4' style={{ padding: 0, height: `${this.muiDefaultProps.divHeights.tracker}px` }}>
                  <div className='col-sm-12' style={{ padding: 0, height: `${this.muiDefaultProps.divHeights.tracker - 2}px`, border: '1px solid white' }}>
                    <Sky {...skyProps} />
                  </div>
                </div>
                <div className='col-sm-4 col-xs-4' style={{ padding: 0, height: `${this.muiDefaultProps.divHeights.tracker}px` }}>
                  <div className='col-sm-12' style={{ padding: 0, height: `${this.muiDefaultProps.divHeights.tracker - 2}px`, border: '1px solid white' }}>
                    <Wind {...windProps} />
                  </div>
                </div>
                <div className='col-sm-4 col-xs-4' style={{ padding: 0, height: `${this.muiDefaultProps.divHeights.tracker}px` }}>
                  <div className='col-md-12' style={{ padding: 0, height: `${this.muiDefaultProps.divHeights.tracker - 2}px`, border: '1px solid white' }}>
                    <Visibility {...visProps} />
                  </div>
                </div>
                { noWeather }
                { noWeatherXS }
              </div>
            </div>
          </div>
        </div>
        <div className='col-xs-12 buttons-div' style={{ margin: '20px 0' }}>
          <div className='col-xs-12 col-sm-3 col-md-3 col-lg-3 button-container'>
            <a href={`${getFSHost()}${this.departuresURL()}`}>
              <FSFlatButton
                label={viewDeparturesLabel}
                className='button-left'
                onClick={(e) => {
                  e.preventDefault();
                  this.context.reportButtonPress('ViewDepartures');
                  window.location.href = `/v2${this.departuresURL()}`;
                  return false;
                }}
                style={this.getButtonStyle(false)}
                labelStyle={this.muiDefaultProps.buttonLabelStyle}
              />
            </a>
          </div>
          <div className='col-xs-12 col-sm-3 col-md-3 col-lg-3 button-container'>
            <a href={`${getFSHost()}${this.arrivalsURL()}`}>
              <FSFlatButton
                label={viewArrivalsLabel}
                className='button-middle'
                onClick={(e) => {
                  e.preventDefault();
                  this.context.reportButtonPress('ViewArrivals');
                  window.location.href = `/v2${this.arrivalsURL()}`;
                  return false;
                }}
                style={this.getButtonStyle(false)}
                labelStyle={this.muiDefaultProps.buttonLabelStyle}
              />
            </a>
          </div>
          <div className='col-xs-12 col-sm-3 col-md-3 col-lg-3 button-container'>
            <a href={otpURL}>
              <FSFlatButton
                href={otpURL}
                label={airportOTPReportLabel}
                className='button-middle'
                onClick={(e) => {
                  e.preventDefault();
                  this.context.reportButtonPress('ViewAirportOTPReport');
                  window.location.href = otpURL;
                  return false;
                }}
                style={this.getButtonStyle(false)}
                labelStyle={this.muiDefaultProps.buttonLabelStyle}
              />
            </a>
          </div>
          <div className='col-xs-12 col-sm-3 col-md-3 col-lg-3 button-container'>
            <FSFlatButton
              id='refreshDataButton'
              label='REFRESH DATA'
              backgroundColor={Colors.blue6}
              hoverColor={Colors.blue8}
              className='button-right'
              onClick={e => this.handleRefreshDataClick(e)}
              style={this.getButtonStyle(true)}
              labelStyle={this.muiDefaultProps.buttonLabelStyle}
            />
          </div>
        </div>
        <div className='col-xs-12' style={{ borderBottom: '2px solid #9B9B9B' }} />
      </div>
    );
  }
}
