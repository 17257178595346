import React from 'react';
import PropTypes from 'prop-types';
import componentBase from '../../../../../../src/lib/component-base';
import handleFirstLoad from '../../../../../../shared/lib/handle-first-component-load';

@componentBase('StatusAdvisoryComponent')
export default class StatusAdvisoryComponent extends React.Component {
  static propTypes = {
    status: PropTypes.string,
    redirected: PropTypes.bool,
    diverted: PropTypes.bool,
    departureAirportFS: PropTypes.string,
    divertedAirport: PropTypes.object,
    advisoryDisplayStatus: PropTypes.string,
  };

  static defaultProps = {
    status: null,
    redirected: null,
    diverted: null,
    departureAirportFS: null,
    advisoryDisplayStatus: null,
    divertedAirport: null,
  };

  constructor(props, context) {
    super(props, context, 'StatusAdvisoryComponent');

    this.muiDefaultProps = {
      statusTextStyle: {
        margin: 0,
        padding: '5px',
        fontSize: '35px',
        verticalAlign: 'middle',
      },
      maxHeight: 700,
      autoWidth: true,
    };
    this.state = {
      firstLoad: true,
    };
  }

  componentWillReceiveProps() {
    const newState = this.handleFirstLoad(this.state.firstLoad);
    this.setState(newState);
  }

  reportEvent = () => this.context
    .reportEvent('StatusAdvisory', this.props.advisoryDisplayStatus, null, null, true)

  handleFirstLoad = handleFirstLoad(this.reportEvent)

  render() {
    let displayDescription;
    let descriptionClassName;
    if (this.props.status === 'Cancelled') {
      displayDescription = 'One or more of our data sources have indicated that this flight has been cancelled. Please contact the airline for more details.';
      descriptionClassName = 'most-advisory-margin';
    } else if (this.props.redirected) {
      if (this.props.divertedAirport && this.props.divertedAirport.fs && this.props.divertedAirport.fs === this.props.departureAirportFS) {
        displayDescription = `This flight is currently redirected back to the origin airport. It appears that it is returning to ${this.props.departureAirportFS}. Contact the airline for more details.`;
        descriptionClassName = 'most-advisory-margin';
      } else if (this.props.divertedAirport && this.props.divertedAirport.fs) {
        displayDescription = `This flight is currently redirected to ${this.props.divertedAirport.fs}. Occasionally flights get temporarily redirected around bad weather but there is a chance that this flight may in fact land at ${this.props.divertedAirport.fs}. Please contact the airline for more details.`;
        descriptionClassName = 'medium-advisory-margin';
      } else {
        displayDescription = 'This flight is currently redirected which means that the flight is headed to a different airport than originally scheduled. Occasionally flights get temporarily redirected around bad weather but there is a chance that this flight may in fact landed at an alternate airport. Contact the airline for more details.';
        descriptionClassName = 'no-advisory-margin';
      }
    } else if (this.props.diverted) {
      displayDescription = 'The flight has landed at different airport than originally scheduled. Usually the airline will depart at a later time to get to the original destination. Please contact the airline for more details.';
      descriptionClassName = 'medium-advisory-margin';
    } else if (this.props.advisoryDisplayStatus === 'Unknown Final Status') {
      displayDescription = 'We were unable to determine the final status of this flight. Please contact the airline for more details.';
      descriptionClassName = 'most-advisory-margin';
    } else if (this.props.advisoryDisplayStatus === 'Not Operational') {
      displayDescription = 'We have reason to believe that this flight is not operational on this day. This usually means that we have an old schedule in our system. Please contact the airline for more details.';
      descriptionClassName = 'most-advisory-margin';
    }

    return (
      <div className={`row status-advisory-component ${this.props.status === 'Unknown' ? 'white' : 'red'}`}>
        <div className='col-xs-12 col-sm-5 col-md-5 col-lg-4' style={{ paddingLeft: '0' }}>
          <h4 style={this.muiDefaultProps.statusTextStyle}>
            {this.props.advisoryDisplayStatus ? this.props.advisoryDisplayStatus : this.props.status}
          </h4>
        </div>
        <div className='col-xs-12 col-sm-7 col-md-7 col-lg-8' style={{ position: 'relative' }}>
          <p className={`description-text ${descriptionClassName}`}>
            { displayDescription }
          </p>
        </div>
      </div>
    );
  }
}
