import React, { Component } from 'react';
import PropTypes from 'prop-types';
import componentBase from '../../../../../src/lib/component-base';

@componentBase('ImageFrame')
export default class ImageFrame extends Component {
  static propTypes = {
    equalIndices: PropTypes.bool,
    divWidth: PropTypes.number,
    gaValue: PropTypes.string,
    indexIcons: PropTypes.arrayOf(PropTypes.element),
    navigationIcons: PropTypes.element,
    source: PropTypes.string,
    style: PropTypes.object,
    text: PropTypes.string,
    handleRightSwipe: PropTypes.func,
    handleLeftSwipe: PropTypes.func,
    activeSubscription: PropTypes.bool,
  };

  state = {
    clientX: null,
    clientY: null,
    moved: false,
  };


  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  timer = () => {}

  handleTouchStart = (e) => {
    const { touches } = e;
    if (touches && touches.length) {
      const { clientX, clientY } = touches[0];
      this.setState({ clientX, clientY });
    }

    this.timer = setTimeout(() => {
      if (!this.state.moved) {
        this.handleClick();
      }
    }, 200);
    return null;
  }


  handleTouchMove = (e) => {
    if (!this.state.clientX || !this.state.clientY) {
      this.handleClick();
      return null;
    }
    if (this.state.moved) {
      return null;
    }

    // adapted from:
    // http://stackoverflow.com/questions/2264072/detect-a-finger-swipe-through-javascript-on-the-iphone-and-android
    const { touches } = e;
    if (touches && touches.length) {
      const { clientX, clientY } = touches[0];
      const xDiff = clientX - this.state.clientX;
      const yDiff = clientY - this.state.clientY;
      if (Math.abs(xDiff) > Math.abs(yDiff)) { // vertical swipe
        this.setState({
          moved: true,
        });
        if (xDiff > 0) { // left swipe
          this.handleLeftSwipe();
        } else { // right swipe
          this.handleRightSwipe();
        }
        this.timer = setTimeout(() => this.setState({
          moved: false,
        }), 400);
        return null;
      }
    }
    // assume any other touch was an attempted 'click'
    this.handleClick();
    return null;
  }

  handleRightSwipe = () => {
    this.props.handleRightSwipe();
  }

  handleLeftSwipe = () => {
    this.props.handleLeftSwipe();
  }

  handleClick = () => {
    if (!this.props.activeSubscription) {
      this.context.reportUserEvent.bind(this, 'Click', 'SubscriptionMarketing', this.props.gaValue);
      this.context.goto(this.subscribeUrl(), 'Carousel Image');
    }
  }

  subscribeUrl() {
    if (this.context.currentUser) {
      return '/account/subscribe';
    }

    return '/register/plan';
  }

  render() {
    return (
      <div
        style={{ ...this.props.style, backgroundImage: `url(${this.props.source})` }}
        className='carousel-image-frame'
        onTouchStart={this.handleTouchStart}
        onTouchMove={this.handleTouchMove}
        onClick={this.handleTouchStart}
      >
        <div className='image-frame-overlay-container'>
          <div className='initially-hidden-icon-container'>
            {this.props.text ? <p>{this.props.equalIndices ? this.props.text : null}</p> : null}
            {this.props.equalIndices ? this.props.navigationIcons : null}
            <div className='index-icons-container' style={{ clear: 'both', width: `${this.props.divWidth}px` }}>
              {this.props.equalIndices ? this.props.indexIcons : null}
            </div>
          </div>
        </div>
      </div>);
  }
}
