import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { SelectField } from '@flightstats/component-lib';
import { equals, path } from 'ramda';
import moment from 'moment';

import { media } from '../../../src/lib/css-media';
import { Colors } from '../../../src/constants/style-constants';

const DescriptionDropdownWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  ${media.mobile`
    flex-direction: column;
  `}
`;

const MobileSelector = styled.div`
  margin-bottom: 10px;
  min-width: 200px;
  display: none;
  ${media.tablet`
    display: block;
  `}
`;

const Hyperlink = styled(props => <Link {...props} />)`
  color: ${Colors.fsOrange};
  text-decoration: underline;
`;

const TabBar = styled.div`
  width: 100%;
  display: flex;
  ${media.tablet`
    display: none;
  `}
`;

const Tab = styled.div`
  background-color: ${props => (props.selected ? Colors.fsOrange : Colors.blue6)};
  font-size: ${props => props.size};
  font-weight: ${props => (props.selected ? 600 : 300)};
  color: ${Colors.white};
  padding: ${props => (props.padding ? props.padding : '10px')};
  border-radius: 5px 5px 0 0;
  text-align: center;
  cursor: pointer;
  width: 100%;
  margin: 0 1px;
  &:hover {
    background-color: ${props => (props.selected ? Colors.fsOrange : Colors.blue5)};
  }
`;

const tab = (title, index, selectedIndex, clickHandler) => (
  <Tab
    key={index}
    selected={selectedIndex === index}
    size='1.25rem'
    onClick={() => clickHandler(index)}
  >
    {title}
  </Tab>
);

const Panel = styled.div`
  border: 1px solid ${Colors.fsOrange};
  display: ${props => (props.selected ? 'block' : 'none')};
  min-height: 210px;
  padding: 10px;
  margin: 0 1px;
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const OneFlightPanel = styled.div`
  border: 1px solid ${Colors.fsOrange};
  display: ${props => (props.selected ? 'flex' : 'none')};
  flex-direction: column;
  min-height: 210px;
  padding: 10px;
  margin: 0 1px;
  text-align: center;
`;

const FadeInDiv = styled.div`
  animation-name: ${fadeIn};
  animation-duration: 666ms;
  animation-timing-function: ease;
`;

const CenteredText = styled(FadeInDiv)`
  align-self: center;
  text-align: center;
  font-size: 18px;
`;

const BoldCenteredText = styled(CenteredText)`
  font-weight: 700;
  font-size: 20px;
  margin: 10px 0;
`;

const DateWrapper = styled(FadeInDiv)`
  align-self: flex-start;
`;

const NoOtherFlightsWrapper = styled(FadeInDiv)`
  font-size: 1rem;
  margin-top: 20px;
`;

const tablePanel = (content, index, selectedIndex, params = {}, titles) => {
  const {
    day,
    month,
    year,
  } = params;

  const momentizedDateParams = moment(`${month}-${day}-${year}`, 'MM-DD-YYYY');
  const momentizedTitleDate = moment(titles[index], ['MMM-DD', 'DD-MMM']);

  if (
    (path(['props', 'children', '2', 'length'], content) === 1) &&
    momentizedDateParams.isSame(momentizedTitleDate)
  ) {
  // only one flight to be displayed and it is currently being displayed

    return (
      <OneFlightPanel
        key={index}
        selected={selectedIndex === index}
      >
        <DateWrapper>
          {
            /* Flights for Sunday.... */
            path(['props', 'children', '0'], content)
          }
        </DateWrapper>
        <BoldCenteredText>
          One Flight This Day
        </BoldCenteredText>
        <CenteredText>
          You are currently viewing the only flight for this day
        </CenteredText>
      </OneFlightPanel>
    );
  }
  return (
    <Panel
      key={index}
      selected={selectedIndex === index}
    >
      <FadeInDiv>
        {content}
      </FadeInDiv>
    </Panel>
  );
};

const findSelectedIndexFromProps = (props) => {
  let selectedIndex = -1;

  const {
    params = {},
  } = props;

  const {
    month,
    day,
    year,
  } = params;

  if (month && day && year) {
    selectedIndex = props.titles.findIndex((title) => {
      const momentizedDateParams = moment(`${month}-${day}-${year}`, 'MM-DD-YYYY');
      const momentizedTitleDate = moment(title, ['MMM-DD', 'DD-MMM']);
      return momentizedDateParams.isSame(momentizedTitleDate);
    });
  }

  selectedIndex = selectedIndex === -1 ? 3 : selectedIndex;

  return selectedIndex;
};

const analyticsLabelForDayIndex = index => (
  [
    'PastFlight-3Days',
    'PastFlight-2Days',
    'PastFlight-1Day',
    'Today',
    'UpcomingFlight-1Day',
    'UpcomingFlight-2Days',
    'UpcomingFlight-3Days',
  ][index]
);

export default class TabsContent extends Component {
  static propTypes = {
    description: PropTypes.object,
    panels: PropTypes.array,
    params: PropTypes.object,
    reportUserEvent: PropTypes.func,
    titles: PropTypes.array,
  };

  constructor(props) {
    super(props);

    const selectedIndex = findSelectedIndexFromProps(props);
    this.state = { selectedIndex };
  }

  componentWillReceiveProps(nextProps) {
    if (!equals(this.props.params, nextProps.params)) {
      /*
        if route changes, select the tab that is specified by the parameters
        ex: Also Marketed As links will grab new data and update params
      */
      const selectedTab = findSelectedIndexFromProps(nextProps);
      this.changeTab(selectedTab);
    }
  }

  onDropdownChange = reportUserEvent => (option) => {
    this.handleTabChange(reportUserEvent, option.value);
  }

  onTabClick = reportUserEvent => (index) => {
    this.handleTabChange(reportUserEvent, index);
  };

  handleTabChange = (reportUserEvent, index) => {
    this.changeTab(index);
    const label = analyticsLabelForDayIndex(index);
    reportUserEvent('ChangeCriteria', label);
  };

  changeTab = index => this.setState({ selectedIndex: index });

  changeTabHandler = reportUserEvent => (index) => {
    this.changeTab(index);
    const label = analyticsLabelForDayIndex(index);
    reportUserEvent('ChangeCriteria', label);
  };

  buildDropdownOptions = titles => (
    titles.map((title, index) => ({ label: title, value: index }))
  );

  buildPanels = (panels, selectedIndex, params, titles, hasFlightsOnOtherDays) => {
    if (hasFlightsOnOtherDays) {
      return panels.map(
        (content, index) => tablePanel(content, index, selectedIndex, params, titles),
      );
    }

    // no other fights on days not selected
    return (
      <OneFlightPanel key={selectedIndex} selected>
        <NoOtherFlightsWrapper>
          <Fragment>
            {
              `Sorry, we do not have any other information for this flight in the past 3 days or upcoming in the the next 3 days.
              For information on this flight prior to 3 days please use our `
            }
          </Fragment>
          <Hyperlink to='/historical-flight/search'>
            Historical Flight Status
          </Hyperlink>
          <Fragment>
            &nbsp;Feature
          </Fragment>
        </NoOtherFlightsWrapper>
      </OneFlightPanel>
    );
  }

  buildMobileSelector = (
    otherFlightsPlusMinusThreeDays,
    selectedIndex,
    titles,
    foregroundColor,
    backgroundColor,
  ) => {
    if (otherFlightsPlusMinusThreeDays === 0) {
      return <Fragment />;
    }
    return (
      <MobileSelector>
        <SelectField
          value={selectedIndex}
          results={this.buildDropdownOptions(titles)}
          handleMenuSelection={this.onDropdownChange}
          iconColor={foregroundColor}
          textColor={foregroundColor}
          placeholderColor={foregroundColor}
          bgColor={backgroundColor}
          borderColor={foregroundColor}
          selectWrapperPosition='relative'
          inputFieldBg={false}
        />
      </MobileSelector>
    );
  }

  countNumberOfOtherStops = panels => (panels.reduce((accumulator, currentPanel, index) => {
    const selectedIndexFromProps = findSelectedIndexFromProps(this.props);
    if (index === selectedIndexFromProps) {
      return accumulator;
    }
    const numOfStops = path(['props', 'children', '2', 'length'], currentPanel);
    if (typeof numOfStops === 'number') {
      return accumulator + numOfStops;
    }
    return accumulator;
  }, 0));

  render() {
    const {
      description,
      titles,
      panels,
      params,
      reportUserEvent,
    } = this.props;

    const {
      selectedIndex,
    } = this.state;

    const foregroundColor = Colors.white;
    const backgroundColor = 'rgba(191, 191, 191, 0.66)';
    const otherFlightsPlusMinusThreeDays = this.countNumberOfOtherStops(panels);
    const hasFlightsOnOtherDays = (otherFlightsPlusMinusThreeDays > 0);

    return (
      <Fragment>
        <DescriptionDropdownWrapper>
          {description}
          {hasFlightsOnOtherDays && (
            <MobileSelector>
              <SelectField
                value={selectedIndex}
                results={this.buildDropdownOptions(titles)}
                handleMenuSelection={this.onDropdownChange(reportUserEvent)}
                iconColor={foregroundColor}
                textColor={foregroundColor}
                placeholderColor={foregroundColor}
                bgColor={backgroundColor}
                borderColor={foregroundColor}
                selectWrapperPosition='relative'
                inputFieldBg={false}
              />
            </MobileSelector>
          )}
        </DescriptionDropdownWrapper>
        <TabBar>
          {titles.map((title, index) =>
            tab(title, index, selectedIndex, this.onTabClick(reportUserEvent)))}
        </TabBar>
        {this.buildPanels(panels, selectedIndex, params, titles, hasFlightsOnOtherDays)}
      </Fragment>
    );
  }
}
