import React from 'react';
import PropTypes from 'prop-types';
import componentBase from '../../../../../src/lib/component-base';
import { formattedDate } from '../../../../../src/lib/date-time-format';

@componentBase('AirportWeatherForecast')
export default class AirportWeatherForecast extends React.Component {
  static propTypes = {
    forecastWeather: PropTypes.array,
  };

  static defaultProps = {
    forecastWeather: null,
  };

  _forecastBlock = (icon, title, description, topBlock, hasSecondaryWeatherForecast) => {
    let borderClass = 'row-eq-height forecastMargin';
    let colClass = 'col-xs-12 col-sm-12 col-md-6 col-lg-6';
    if (topBlock && hasSecondaryWeatherForecast) {
      borderClass = `${borderClass} forecastBorder`;
    } else if (topBlock && !hasSecondaryWeatherForecast) {
      colClass = 'col-xs-12 col-sm-12 col-md-12 col-lg-12';
    }

    return (
      <div className={colClass}>
        <div className={borderClass}>
          <div className='col-xs-3 col-sm-2 col-md-2 col-lg-2' style={{ display: 'inline-block', width: '70px' }}>
            <img src={this.context.images.weatherIcons[icon]} className='airport-weather-icon' alt='Airport weather' />
          </div>
          <div className='col-xs-9 col-sm-10 col-md-10 col-lg-10' style={{ display: 'inline-block' }}>
            <b>{title}</b>
            <p>{description}</p>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.props.forecastWeather) {
      let result = [];
      if (this.props.forecastWeather && this.props.forecastWeather.length > 0) {
        result = [<h4 className='details-header' key='weather-forcast-header'>Current Weather Forecast</h4>];
        for (let i = 0; i < this.props.forecastWeather.length; i++) {
          result.push(<div key={`weather-forcast-${i}`} className='row' style={{ backgroundColor: 'rgba(255,255,255,.2)', marginBottom: '15px' }}>
            <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12' style={{ backgroundColor: 'rgba(255,255,255,.3)' }}>
              <h4 style={{ margin: '5px 5px 5px 10px' }}>{formattedDate(this.context.currentUser, this.props.forecastWeather[i], 'date', 'dateMDY')}</h4>
            </div>
            {this._forecastBlock(this.props.forecastWeather[i].icon, this.props.forecastWeather[i].day1, this.props.forecastWeather[i].des1, true, (this.props.forecastWeather[i].icon2 != null))}
            {this.props.forecastWeather[i].icon2 ? this._forecastBlock(this.props.forecastWeather[i].icon2, this.props.forecastWeather[i].day2, this.props.forecastWeather[i].des2) : null}
          </div>);
        }
      }
      return (<div className='row border-bottom-row' style={{ padding: '10px 20px' }}>{result}</div>);
    }

    return (<div />);
  }
}
