import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { prefersAMPMTimeFormat } from '../../lib/date-time-format';

const Spannify24hr = props => (
  <span className={`${props.componentName}-time-hours`}>{props.hours}</span>
);

Spannify24hr.propTypes = {
  componentName: PropTypes.string,
  hours: PropTypes.string,
};

const SpannifyAmPm = props => (
  <span>
    <span className={`${props.componentName}-time-hours`}>{props.hours}</span>
    <span className={`${props.componentName}-time-ampm`}>{props.ampm}</span>
  </span>
);

SpannifyAmPm.propTypes = {
  componentName: PropTypes.string,
  hours: PropTypes.string,
  ampm: PropTypes.string,
};

// converts time in the form 7:20AM to <span>7:20</span> <span>AM</span>
// time seperated from am/pm as different styles needed each part
const TimeToDOM = (props) => {
  const timeToFormat = props.isUtc ? moment.utc(props.time) : moment(props.time, 'hh:mmA');

  if (!timeToFormat.isValid()) {
    return (<span className={`${props.componentName}-unknown-time`}>--</span>);
  }

  if (!prefersAMPMTimeFormat(props.user)) {
    // make into 24 hour time, no am/pm
    const hours = timeToFormat.format('HH:mm');

    return (
      <Spannify24hr hours={hours} componentName={props.componentName} />
    );
  }

  //  am/pm format
  const hours = timeToFormat.format('h:mm');
  const ampm = timeToFormat.format(' A');

  return (
    <SpannifyAmPm hours={hours} ampm={ampm} componentName={props.componentName} />
  );
};

TimeToDOM.propTypes = {
  isUtc: PropTypes.bool,
  componentName: PropTypes.string,
  time: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  user: PropTypes.object,
};

export default TimeToDOM;
