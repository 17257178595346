import { connectRouter } from 'connected-react-router';
import getHistory from './history'; // You'll need to create this file if it doesn't exist

import { redux as App } from './App';
import { redux as Account } from './Account';
import { redux as AirportCurrentConditions } from './AirportCurrentConditions';
import { redux as AirportDelayMap } from './AirportDelayMap';
import { redux as AirportDeparturesArrivals } from './AirportDeparturesArrivals';
import { Current as FlightAlerts, Historical as FlightAlertsHistory } from './FlightAlerts';
import { Search as FlightTrackerSearch } from './FlightTracker';
import { Single as SingleFlightTracker } from './SingleFlight';
import * as MultiFlightTracker from './MultiFlight/redux';
import { Single as SingleHistoricalFlightStatus, Multi as MultiHistoricalFlightStatus } from './HistoricalFlightStatus';
import { redux as OnTimePerformance } from './OnTimePerformance';
import { redux as Modal } from './Modal';
import { sitemapName, sitemapReducer } from './Sitemap/redux';

const reducer = {
  [App.constants.NAME]: App.reducer,
  [Account.constants.NAME]: Account.reducer,
  [AirportCurrentConditions.constants.NAME]: AirportCurrentConditions.reducer,
  [AirportDelayMap.constants.NAME]: AirportDelayMap.reducer,
  [AirportDeparturesArrivals.constants.NAME]: AirportDeparturesArrivals.reducer,
  [FlightAlerts.constants.NAME]: FlightAlerts.reducer,
  [FlightAlertsHistory.constants.NAME]: FlightAlertsHistory.reducer,
  [FlightTrackerSearch.constants.NAME]: FlightTrackerSearch.reducer,
  [Modal.constants.NAME]: Modal.reducer,
  [MultiFlightTracker.constants.NAME]: MultiFlightTracker.reducer,
  [MultiHistoricalFlightStatus.constants.NAME]: MultiHistoricalFlightStatus.reducer,
  [OnTimePerformance.constants.NAME]: OnTimePerformance.reducer,
  [SingleFlightTracker.constants.NAME]: SingleFlightTracker.reducer,
  [SingleHistoricalFlightStatus.constants.NAME]: SingleHistoricalFlightStatus.reducer,
  [sitemapName]: sitemapReducer,
};

export default reducer;
