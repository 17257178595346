import React from 'react';
import PropTypes from 'prop-types';
import { pathOr } from 'ramda';
import moment from 'moment';
import { colors } from '@flightstats/component-lib';

import { FlightTracker as flightTrackerAds } from '../../../shared/ads';
import AdvancedSearchByAirportRoute from '../../../../../../shared/components/AdvancedSearchByAirportRoute/AdvancedSearchByAirportRoute';
import NoResult from '../../../../../../shared/components/NoResult/NoResult';
import { OtherFlights } from '../../../../../../shared/components/OtherFlights/OtherFlights';
import { dateFormatString } from '../../../../../../src/lib/date-time-format';

const NoFlight = (props) => {
  const {
    carrier,
    day,
    dispatch,
    flightNumber,
    isMobile,
    month,
    otherDays,
    reportUserEvent,
    user,
    year,
  } = props;

  const subscriptionActive = pathOr(false, ['subscriptionActive'], user);
  const { top: topAd, bottom: bottomAd } = flightTrackerAds;

  const defaultSecondaryCSSWrapperStyle = {
    // styled components gives a className mismatch error with this content
    fontSize: '1rem',
    lineHeight: '16px',
    fontWeight: 'normal',
    color: `${colors.white}`,
    margin: '10px 0',
  };

  const defaultSecondaryCSSParagraph = {
    fontSize: '1rem',
    lineHeight: '16px',
  };

  const defaultSecondaryCSSUnorderedList = {
    margin: '10px 0',
    fontSize: '1rem',
  };

  const departureDate = year && month && day ?
    moment().year(year).month(month - 1).date(day) :
    moment();

  const title = otherDays ?
    `IT APPEARS THAT ${carrier} ${flightNumber} DOES NOT FLY ON ${departureDate.format(dateFormatString(user))}` :
    'THIS FLIGHT COULD NOT BE LOCATED IN OUR SYSTEM';

  const secondaryContent = otherDays ? (
    <div style={defaultSecondaryCSSWrapperStyle}>
      <p style={defaultSecondaryCSSParagraph}>
        At this time, we do not have a record for this flight for this date.
        Select a flight below or use our Search by Airport or Route feature.
      </p>
    </div>
  ) : (
    <div style={defaultSecondaryCSSWrapperStyle}>
      <p style={defaultSecondaryCSSParagraph}>
        There are several possible reasons why we do not have a record matching this flight,
        including the following:
      </p>
      <ul style={defaultSecondaryCSSUnorderedList}>
        <li>It may not operate on the date requested</li>
        <li>The flight number or airline code is incorrect</li>
        <li>The airline may have changed the number</li>
        <li>It may actually be operated by another airline</li>
        <li>It may be a charter flight not tracked in our system</li>
      </ul>
    </div>
  );

  const secondaryCSS = () => 'li { list-style: disc; margin-left: 20px; }';

  return (
    <div className='NoFlight'>
      <NoResult
        sectionTitle='Flight Status Not Available'
        primaryContent={title}
        secondaryContent={secondaryContent}
        secondaryCSS={secondaryCSS()}
        adUnit={isMobile && otherDays ? null : topAd}
        subscriptionActive={subscriptionActive}
      />
      {!!otherDays && <OtherFlights
        carrier={carrier}
        flightNumber={flightNumber}
        otherDays={otherDays}
        reportUserEvent={reportUserEvent}
        subscriptionActive={subscriptionActive}
        title='Information About This Flight On Other Days'
        user={user}
      />}
      <AdvancedSearchByAirportRoute
        adUnit={bottomAd}
        dispatch={dispatch}
        title='Search by Airport or Route'
        user={user}
      />
    </div>
  );
};

NoFlight.propTypes = {
  carrier: PropTypes.string,
  day: PropTypes.string,
  dispatch: PropTypes.func,
  flightNumber: PropTypes.string,
  isMobile: PropTypes.bool,
  month: PropTypes.string,
  otherDays: PropTypes.array,
  reportUserEvent: PropTypes.func,
  user: PropTypes.object,
  year: PropTypes.string,
};

export default NoFlight;
