const ErrorMessages = {
  FLIGHT_LOAD_ERROR1: 'Sorry, there was a problem loading that flight',
  FLIGHT_LOAD_ERROR2: 'Sorry, we could not find any matches based on your query',
  FLIGHTS_LOAD_ERROR1: 'Sorry, no flights found matching your search criteria',
  FLIGHTS_LOAD_ERROR2: 'Sorry, no flights found matching your search criteria',
  FLIGHT_ALERT_ERROR1: 'Sorry, we could not find any flights based on your query',
  FLIGHT_ALERT_ERROR2: 'The flight\'s status does not allow it to be monitored',
  FLIGHT_ALERT_ERROR3: 'The flight\'s status of cancelled does not allow it to be monitored',
  SEARCH_ISSUES: 'Sorry, we couldn\'t find your flight. Please try again',
  TOO_MANY_REQUESTS: 'Your usage of this website is unusually high and we are temporarily disabling access for your user. You can try again after a while. If you are sharing your account with another user, please create separate accounts.',
  NO_CREDITS: 'Please buy more credits.',
  DEFAULT: 'An error occurred.',
};

export const errorMessageForCode = (code = 'DEFAULT') => (
  ErrorMessages[code] || ErrorMessages.DEFAULT
);
