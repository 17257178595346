import React from 'react';
import PropTypes from 'prop-types';
import componentBase from '../../../../../src/lib/component-base';
import GenericModal from '../../../../../shared/components/GenericModal/GenericModal';
import UpgradeIcon from '../../../../../static/images/subscription-icons/UpgradeArrow-Icon.svg';
import { catchErrors } from '../../../../Account/redux/selectors';

@componentBase('UpgradeSubscriptionModal')
export default class UpgradeSubscriptionModal extends GenericModal {
  static propTypes = {
    closeModal: PropTypes.func,
    endSpinner: PropTypes.func,
    getProfileReduxAction: PropTypes.func,
    modalOpen: PropTypes.bool,
    startSpinner: PropTypes.func,
    upgradeReduxAction: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      buttonsDisabled: false,
    };

    this.handleOptionSelect = this.handleOptionSelect.bind(this);
  }

  updateProfileOnSuccess = async (transactionId, purchase) => {
    const {
      endSpinner,
      getProfileReduxAction,
      routerPush,
    } = this.props;
    try {
      const profile = await getProfileReduxAction();
      const { result: profileResult } = profile;
      if (profileResult) {
        const defaultId = `${profileResult.subscriptionBraintreeId}-U`;
        const recordedPurchase = { ...purchase };
        recordedPurchase.id = transactionId || defaultId;
        this.context.reportPurchase(recordedPurchase);
        endSpinner();
        routerPush('/account/success');
        this.context.reportUserUpgraded(3);
      } else {
        const message = 'no profile result in getProfile after subscription upgrade';
        console.log(message, profile);
        endSpinner();
        routerPush('/account/success');
        this.context.reportUserUpgraded(3);
      }
    } catch (ex) {
      const message = 'error in getProfile after subscription upgrade';
      console.log(message, ex, catchErrors(ex));
    }
  }

  handleOptionSelect = async () => {
    // Disable buttons so user doesn't try to upgrade more than once
    this.setState({
      buttonsDisabled: true,
    });
    const {
      closeModal,
      endSpinner,
      startSpinner,
      upgradeReduxAction,
    } = this.props;

    startSpinner();

    try {
      const upgrade = await upgradeReduxAction();
      const { result } = upgrade;

      if (result && result.status === 200) {
        closeModal();

        const transactionId = result &&
          result.data &&
          result.data.transactionId;

        const purchase = {
          affiliation: 'Subscription',
          name: 'Professional-Upgrade',
          revenue: result && result.data && result.data.amount,
        };
        this.updateProfileOnSuccess(transactionId, purchase);
      } else {
        this.setState({
          buttonsDisabled: false,
        });
        endSpinner();
        const message = 'Error: you were not upgraded successfully';
        this.context.toast.error(message, 'Error', false, 3);
      }
    } catch (ex) {
      const message = 'error in the upgrade subscription block';
      console.log(message, ex, catchErrors(ex));
      this.setState({
        buttonsDisabled: false,
      });
      this.context.toast.error(catchErrors(ex), 'Error', false, 3);
      endSpinner();
      this.props.closeModal();
    }
  }

  render() {
    const body = (
      <div className='upgrade-subscription-modal'>
        <p className='costText'>$24.99 per month (prorated)</p>
        <ul className='bulletedList'>
          <li><p>Get 250 Flight Alert rules per month</p></li>
          <li><p>Turn on Historical Flight Status</p></li>
          <li><p>Lookup 250 Historical Flights per month</p></li>
        </ul>
        <div className='disclaimer'>
          <p>
            When you click UPGRADE NOW,
            your credit card on file will be
            charged and your subscription will be upgraded.
          </p>
        </div>
      </div>
    );

    return (
      <GenericModal
        modalOpen={this.props.modalOpen}
        closeModal={this.props.closeModal}
        handleOptionSelect={this.handleOptionSelect}
        mainActionTitle='UPGRADE NOW'
        body={body}
        headerIcon={UpgradeIcon}
        headerString='Upgrade To Professional'
        disabled={this.state.buttonsDisabled}
      />
    );
  }
}
