import { NAME } from './constants';

export const SET_AIRLINE = `${NAME}/SET_AIRLINE`;
export const SET_ARRIVAL_AIRPORT = `${NAME}/SET_ARRIVAL_AIRPORT`;
export const SET_BY_AIRPORT_AIRLINE = `${NAME}/SET_BY_AIRPORT_AIRLINE`;
export const SET_BY_AIRPORT_DEP_DATE = `${NAME}/SET_BY_AIRPORT_DEP_DATE`;
export const SET_DEPARTURE_AIRPORT = `${NAME}/SET_DEPARTURE_AIRPORT`;
export const SET_DEPARTURE_DATE = `${NAME}/SET_DEPARTURE_DATE`;
export const SET_DEPARTURE_TIME = `${NAME}/SET_DEPARTURE_TIME`;
export const SET_FLIGHT_NUMBER = `${NAME}/SET_FLIGHT_NUMBER`;
export const SET_FLIGHT_TRACKER_SEARCH_ERROR = `${NAME}/SET_FLIGHT_TRACKER_SEARCH_ERROR`;
export const SET_FLIGHT_TRACKER_QUERY_ERROR = `${NAME}/SET_FLIGHT_TRACKER_QUERY_ERROR`;
export const SET_RANDOM_FLIGHT_PATH = `${NAME}/SET_RANDOM_FLIGHT_PATH`;
export const SET_RANDOM_FLIGHT_PATH_SUCCESS = `${NAME}/SET_RANDOM_FLIGHT_PATH_SUCCESS`;
export const SET_RANDOM_FLIGHT_PATH_FAIL = `${NAME}/SET_RANDOM_FLIGHT_PATH_FAIL`;
export const SET_UNIVERSAL_SEARCH_FLIGHT = `${NAME}/SET_UNIVERSAL_SEARCH_FLIGHT`;
export const SET_ADVANCED_SEARCH_URL = `${NAME}/SET_ADVANCED_SEARCH_URL`;
export const SET_SECONDARY_SEARCH_ACTION = `${NAME}/SET_SECONDARY_SEARCH_ACTION`;
export const SET_SECONDARY_ADVANCED_SEARCH_ACTION = `${NAME}/SET_SECONDARY_ADVANCED_SEARCH_ACTION`;
export const SET_SECONDARY_AIRLINE = `${NAME}/SET_SECONDARY_AIRLINE`;
export const SET_SECONDARY_BY_AIRPORT_AIRLINE = `${NAME}/SET_SECONDARY_BY_AIRPORT_AIRLINE`;
export const SET_SECONDARY_BY_AIRPORT_DEP_DATE = `${NAME}/SET_SECONDARY_BY_AIRPORT_DEP_DATE`;
export const SET_SECONDARY_DEP_AIRPORT = `${NAME}/SET_SECONDARY_DEPARR`;
export const SET_SECONDARY_ARR_AIRPORT = `${NAME}/SET_SECONDARY_ARR_AIRPORT`;
export const SET_SECONDARY_ALL_BOOL = `${NAME}/SET_SECONDARY_ALL_BOOL`;
export const SET_SECONDARY_DEP_DATE = `${NAME}/SET_SECONDARY_DEP_DATE`;
export const SET_SECONDARY_TIME = `${NAME}/SET_SECONDARY_TIME`;
export const UPDATING_FLIGHT_NUMBER_INPUT = `${NAME}/UPDATING_FLIGHT_NUMBER_INPUT`;

export const SET_FLIGHT_TRACKER_CLEAR_ERRORS = `${NAME}/SET_FLIGHT_TRACKER_CLEAR_ERRORS`;
export const SET_ADVANCED_SEARCH_BY_FLIGHT_ERROR = `${NAME}/SET_ADVANCED_SEARCH_BY_FLIGHT_ERROR`;
export const SET_ADVANCED_SEARCH_BY_AIRPORT_ERROR = `${NAME}/SET_ADVANCED_SEARCH_BY_AIRPORT_ERROR`;
