// @flow
import React from 'react';
import equals from 'ramda/src/equals';
import { CompactMenuHeading } from './compact-menu-heading';
import type { AccountMenuDataSubmenuItem } from './compact-menu';
import type { User } from '../../../../common-types';

type CompactAccountMenuProps = {
  heading: string,
  submenuItems: AccountMenuDataSubmenuItem,
  user: User,
};

const isLoggedIn = (user = {}) => !!user && !!user.email;

export const CompactAccountMenu = ({
  heading,
  submenuItems = [],
  user,
}: CompactAccountMenuProps) => (
  <CompactMenuHeading
    heading={heading}
    submenuItems={submenuItems.filter((item) =>
      equals(item.isLoggedIn, isLoggedIn(user))
    )}
  />
);
