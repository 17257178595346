import React from 'react';
import PropTypes from 'prop-types';

const baseStyleProps = {
  color: 'black',
  fontSize: '16px',
  lineHeight: '16px',
};

const AccountModalUnsubscribeWarning = ({ renewalDate, textStyle }) => (
  <section style={{
    backgroundColor: 'rgba(89,27,13,0.25)',
    border: '1px solid #e34622',
    margin: '18px 0 36px',
    padding: '6px 6px 15px',
  }}
  >
    <h3 style={{
      ...textStyle,
      color: '#e34622',
    }}
    >
      Before you unsubscribe
    </h3>
    <p style={{ ...baseStyleProps }}>
      {`Please note that your subscription is set to renew ${renewalDate}.`}
      <br />
      <span style={{ fontWeight: '600' }}>
        Cancelling today will immediately end your premium benefits.
      </span>
      <br />
      {
        `You may want to consider unsubscribing a day before
        to continue taking advantage of these features.`
      }
    </p>
  </section>
);

AccountModalUnsubscribeWarning.propTypes = {
  renewalDate: PropTypes.string,
  textStyle: PropTypes.object,
};

export default AccountModalUnsubscribeWarning;
