// these sizes are arbitrary and you can set them to whatever you wish
import { css } from 'styled-components';

export const baseFontSizeInPixels = 13;

export const mediaSizes = {
  giant: 1200,
  desktop: 992,
  tablet: 768,
  mobile: 500,
};

const createBreakpoints = () => {
  const breakpoints = {};
  Object.keys(mediaSizes).forEach((label) => {
    const pxSize = mediaSizes[label];
    breakpoints[label] = (...args) => css`
      @media (max-width: ${pxSize}px) {
        ${css(...args)}
      }
    `;
  });
  return breakpoints;
};

export const media = createBreakpoints();
