import React from 'react';
import PropTypes from 'prop-types';
import componentBase from '../../../src/lib/component-base';
import { prefersAMPMTimeFormat } from '../../../src/lib/date-time-format';

const planeOrangeIcon = require('../../../static/images/plane-orange.svg');

@componentBase('FlightAlertsDetailHeader')
export default class FlightAlertsDetailHeader extends React.Component {
  static propTypes = {
    containerClassName: PropTypes.string,
    flightInfo: PropTypes.object,
  }

  render() {
    // this.logger('props: ', this.props, 'state: ', this.state);
    const {
      flightInfo: {
        departureTime,
        departureTime24,
        departureTimeMeridian,
        departureTimeZone,
        arrivalTime,
        arrivalTime24,
        arrivalTimeMeridian,
        arrivalTimeZone,
        departureAirport,
        arrivalAirport,
      },
    } = this.props;

    const depTime = prefersAMPMTimeFormat(this.context.currentUser) ?
      departureTime : departureTime24;
    const depTimeAMPM = prefersAMPMTimeFormat(this.context.currentUser) ?
      departureTimeMeridian : null;

    const arrTime = prefersAMPMTimeFormat(this.context.currentUser) ?
      arrivalTime : arrivalTime24;
    const arrTimeAMPM = prefersAMPMTimeFormat(this.context.currentUser) ?
      arrivalTimeMeridian : null;
    const arrivalAirportRegion = (arrivalAirport.airportStateCode
      && arrivalAirport.airportStateCode.length > 0) ?
      `${arrivalAirport.airportStateCode},` : '';
    const departureAirportRegion = (departureAirport.airportStateCode
      && departureAirport.airportStateCode.length > 0) ?
      `${departureAirport.airportStateCode},` : '';

    return (
      <div className={`flight-alerts-detail-header-container ${this.props.containerClassName || ''}`}>
        <div className={`flight-info-header-group ${this.props.containerClassName || ''}`}>
          <p className='flight-info-header'>Origin</p>
          <p className='flight-info-header'>Destination</p>
          <p className='flight-info-header'>Departure Time</p>
          <p className='flight-info-header'>Arrival Time</p>
        </div>
        <div className={`flight-info-chart ${this.props.containerClassName || ''}`}>
          <div className='info-pack-group'>
            <p className='flight-info-cell-alert'>{departureAirport.code}</p>
            <p className='flight-info-cell-alert'>{arrivalAirport.code}</p>
            <div className='time-info-group'>
              <p className='time-info-cell-alert'>
                <span className='time-span-alert'>{depTime}</span>
                <span className='time-info-span'> {depTimeAMPM} {departureTimeZone}</span>
              </p>
              <p className='time-info-cell-alert'>
                <span className='time-span-alert'>{arrTime}</span>
                <span className='time-info-span'> {arrTimeAMPM} {arrivalTimeZone}</span>
              </p>
            </div>
          </div>
          <div className='info-pack-group row'>
            <div className='col-xs-5'>
              <p className='flight-info-pack'>
                <span className='flight-info-span' style={{ display: 'block' }}>
                  {departureAirport.airportName}
                </span>
                <span className='flight-info-span' style={{ display: 'block' }}>
                  {`${departureAirport.airportCityName}, ${departureAirportRegion} ${departureAirport.airportCountryName}`}
                </span>
              </p>
            </div>
            <div className='col-xs-2'>
              <div className='flight-info-icon'>
                <img alt='flying plane icon' src={planeOrangeIcon} />
              </div>
            </div>
            <div className='col-xs-5'>
              <p className='flight-info-pack'>
                <span className='flight-info-span' style={{ display: 'block' }}>
                  {arrivalAirport.airportName}
                </span>
                <span className='flight-info-span' style={{ display: 'block' }}>
                  {`${arrivalAirport.airportCityName}, ${arrivalAirportRegion} ${arrivalAirport.airportCountryName}`}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
