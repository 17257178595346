import moment from 'moment';
import { isEmpty } from 'ramda';

import * as t from './actionTypes';
import {
  validateCarrierFSCode,
} from '../../../src/utils/validation';

const dispatchOnlyType = type => ({
  type,
});

const validationError = type => ({
  type,
  error: 'validation',
});

const loadExtendedDetailsByCarrierFlightNumberAndDate = ({
  carrier, flightNumber, year, month, day, flightId,
}) => {
  let y = year;
  let m = month;
  let d = day;

  if (!year || !month || !day) {
    const now = moment();
    y = now.year();
    m = now.month() + 1;
    d = now.date();
  }

  const url = flightId ? `/extendedDetails/${carrier}/${flightNumber}/${y}/${m}/${d}/${flightId}` : `/extendedDetails/${carrier}/${flightNumber}/${y}/${m}/${d}`;

  return async (dispatch, getStore, client) => {
    if (!validateCarrierFSCode(carrier)) {
      return dispatch(validationError(t.LOAD_FAIL));
    }
    dispatch(dispatchOnlyType(t.LOAD_EXTENDED_DETAILS_BY_CARRIER_FLIGHT_NUMBER_AND_DATE));

    try {
      const result = await client.get(url);

      dispatch({
        type: t.LOAD_EXTENDED_SUCCESS,
        result,
      });
    } catch (error) {
      dispatch({ type: t.LOAD_FAIL });
    }
  };
};

const setSingleFlightTrackerError = error => ({
  type: t.SET_SINGLE_FLIGHT_TRACKER_ERROR,
  error,
});

const getAirlineCarrier = ({
  carrier, flightNumber, year, month, day, hour,
}) => {
  let y = year;
  let m = month;
  let d = day;

  if (!year || !month || !day) {
    const now = moment.utc();
    y = now.year();
    m = now.month() + 1;
    d = now.date();
  }

  const url = hour ? `/airline-carrier/${carrier}/${flightNumber}/${y}/${m}/${d}/${hour}` : `/airline-carrier/${carrier}/${flightNumber}/${y}/${m}/${d}`;

  return async (dispatch, getStore, client) => {
    if (!validateCarrierFSCode(carrier)) {
      return dispatch(validationError(t.GET_AIRLINE_CARRIER_FAIL));
    }
    dispatch(dispatchOnlyType(t.LOAD_BY_CARRIER_FLIGHT_NUMBER_AND_DATE));

    try {
      const result = await client.get(url);
      if (isEmpty(result)) {
        return dispatch({ type: t.GET_AIRLINE_CARRIER_FAIL });
      }

      return dispatch({
        type: t.GET_AIRLINE_CARRIER_SUCCESS,
        result,
      });
    } catch (error) {
      return dispatch({
        type: t.GET_AIRLINE_CARRIER_FAIL,
      });
    }
  };
};

const loadByCarrierFlightNumberAndDate = ({
  carrier, flightNumber, year, month, day, hour,
}) => {
  let y = year;
  let m = month;
  let d = day;

  if (!year || !month || !day) {
    const now = moment();
    y = now.year();
    m = now.month() + 1;
    d = now.date();
  }

  const url = hour ? `/flight/${carrier}/${flightNumber}/${y}/${m}/${d}/${hour}` : `/flight/${carrier}/${flightNumber}/${y}/${m}/${d}`;

  return async (dispatch, getStore, client) => {
    if (!validateCarrierFSCode(carrier)) {
      return dispatch(validationError(t.LOAD_BY_CARRIER_FLIGHT_NUMBER_AND_DATE_FAIL));
    }
    dispatch(dispatchOnlyType(t.LOAD_BY_CARRIER_FLIGHT_NUMBER_AND_DATE));

    try {
      const result = await client.get(url);
      if (isEmpty(result)) {
        return dispatch({ type: t.LOAD_BY_CARRIER_FLIGHT_NUMBER_AND_DATE_FAIL });
      }

      return dispatch({
        type: t.LOAD_BY_CARRIER_FLIGHT_NUMBER_AND_DATE_SUCCESS,
        result,
      });
    } catch (error) {
      return dispatch({
        type: t.LOAD_BY_CARRIER_FLIGHT_NUMBER_AND_DATE_FAIL,
      });
    }
  };
};

const loadOtherDaysFlights = ({ carrier, flightNumber }) => {
  const url = `/flights/otherDays/${carrier}/${flightNumber}`;
  return async (dispatch, getStore, client) => {
    dispatch(dispatchOnlyType(t.LOAD_OTHER_DAYS_FLIGHTS));

    try {
      const result = await client.get(url);
      if (isEmpty(result)) {
        return dispatch({ type: t.LOAD_OTHER_DAYS_FLIGHTS_FAIL });
      }

      return dispatch({
        type: t.LOAD_OTHER_DAYS_FLIGHTS_SUCCESS,
        result,
      });
    } catch (error) {
      return dispatch({
        type: t.LOAD_OTHER_DAYS_FLIGHTS_FAIL,
      });
    }
  };
};

const updateSegments = segments => ({
  type: t.UPDATE_SEGMENTS,
  segments,
});

const setSingleFlightSecondarySearchAction = bool => ({
  type: t.SET_SECONDARY_SEARCH_ACTION,
  bool,
});

const setFlightTrackerOutOfDateRange = bool => ({
  type: t.SET_FLIGHT_TRACKER_OUT_OFF_DATE_RANGE,
  bool,
});

const resetTrackerState = () => dispatchOnlyType(t.RESET_TRACKER_STATE);

export {
  getAirlineCarrier,
  loadByCarrierFlightNumberAndDate,
  loadExtendedDetailsByCarrierFlightNumberAndDate,
  loadOtherDaysFlights,
  resetTrackerState,
  setFlightTrackerOutOfDateRange,
  setSingleFlightSecondarySearchAction,
  setSingleFlightTrackerError,
  updateSegments,
};
