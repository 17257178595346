import React from 'react';
import PropTypes from 'prop-types';

import { FSButton } from '../../../../../../src/components/lib/FSButton';

import DepArAdTransition1 from '../../../../../../static/images/DepArAdTransition1.jpg';
import DepArAdTransition2 from '../../../../../../static/images/DepArAdTransition2.jpg';

const url = (match = {}) => {
  const { params } = match;
  const {
    arrivalAirportCode,
    departureAirportCode,
    day,
    month,
    year,
  } = params;

  // defaults to departures search if both departureAirportCode and arrivalAirportCode are present
  if (departureAirportCode) {
    return `/airports/departing-arriving/${departureAirportCode}/departures/${year}/${month}/${day}`;
  }
  return `/airports/departing-arriving/${arrivalAirportCode}/arrivals/${year}/${month}/${day}`;
};

const MultiFlightSearchFooter = ({
  desktop,
  hide,
  match,
  mobile,
}) => {
  const desktopClass = desktop ? ' desktop-show' : '';
  const mobileClass = mobile ? ' mobile-show' : '';
  const showHide = hide ? '' : ' sub';

  return (
    <section className={`multi-section-footer${desktopClass}${mobileClass}${showHide}`}>
      <span className='left'>
        <div>
          Looking for filters and detailed flight listings?
        </div>
        <div>
          Airport Departures and Arrivals is part of our $2.99 Subscription
        </div>
        <div className='button'>
          <FSButton href={`/v2${url(match)}`}>
            LEARN MORE
          </FSButton>
        </div>
      </span>
      <span className='right'>
        <img className='bottom' alt='departures-and-arrivals' src={DepArAdTransition1} />
        <img className='top' alt='departures-and-arrivals' src={DepArAdTransition2} />
      </span>
    </section>
  );
};

MultiFlightSearchFooter.propTypes = {
  desktop: PropTypes.bool,
  hide: PropTypes.bool,
  match: PropTypes.object,
  mobile: PropTypes.bool,
};

export default MultiFlightSearchFooter;
