import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { identity } from 'ramda';

import componentBase from '../../../../../../../src/lib/component-base';
import FSFlatButton from '../../../../../../../src/components/lib/FSFlatButton';
import AirlineSelect from '../../../../../../../src/components/lib/AirlineSelect';
import FSDatePicker from '../../../../../../../shared/components/FSDatePicker/FSDatePicker';
import { dateFormatString } from '../../../../../../../src/lib/date-time-format';
import BuyCreditsGenericModal from '../../../../../../../shared/components/BuyCreditsGenericModal/BuyCreditsGenericModal';

const transparentSilver = 'rgba(192,192,192,0.66)';

@componentBase('CreateFlightAlert')
export default class CreateFlightAlert extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func,
    handleDateChange: PropTypes.func,
    handleFlightNumberChange: PropTypes.func,
    handleAirlineChange: PropTypes.func,
    searchDate: PropTypes.object,
    queryError: PropTypes.string,
    airline: PropTypes.object,
    flightNumber: PropTypes.string,
    creditsMonthlyAllowance: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    reserveCredits: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    buyCreditsModalOpen: PropTypes.bool,
    closeBuyCreditsModal: PropTypes.func,
    openBuyCreditsModal: PropTypes.func,
    setSecondaryActionBool: PropTypes.func,
    secondaryAction: PropTypes.bool,
    user: PropTypes.object,
  };

  constructor(props) {
    super(props);


    this.state = {
      disableClick: false,
    };

    this.disableClick = this.disableClick.bind(this);
    this.handleFlightNumberChange = this.handleFlightNumberChange.bind(this);
    this.dateChangeHandler = this.dateChangeHandler.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.renderAddNewAlertConsole = this.renderAddNewAlertConsole.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.secondaryAction) {
      this.props.setSecondaryActionBool(false);
    }
  }

  componentWillUnmount() {
    this.props.setSecondaryActionBool(false);
  }

  closeModal = bool => this.props.closeBuyCreditsModal(bool);

  dateChangeHandler = (searchDate) => {
    this.props.handleDateChange(searchDate);
  }

  hasCredits = () => {
    const {
      creditsMonthlyAllowance,
      reserveCredits,
    } = this.props;
    // null + 5 would return true, but undef + 5 or null + '5' would return false
    // so let's make sure that falsey values are always zero for this case
    const monthly = creditsMonthlyAllowance || 0;
    const reserve = reserveCredits || 0;
    return monthly + reserve > 0;
  }

  disableClick = (disable) => {
    this.setState({
      disableClick: disable,
    });
  }

  handleAirlineChange = stateKey => (e, triggeredByEnter) => {
    const update = {};
    update[stateKey] = (e && e.value);
    this.props.handleAirlineChange(update, triggeredByEnter);
  }

  handleFlightNumberChange = (e) => {
    this.props.handleFlightNumberChange(e);
  }

  handleKeyDown = (e) => {
    if (e.key === 'Enter') this.props.handleSubmit();
  }

  handleSubmit = () => {
    if (this.hasCredits()) {
      this.props.handleSubmit();
    } else {
      this.props.openBuyCreditsModal();
    }
  }

  renderAddNewAlertConsole = (errorText, airlineCode) => (this.hasCredits() ?
    (
      <div>
        <h1>Set Up a New Flight Alert</h1>
        { errorText }
        <div className='airline-search'>
          <h4>Airline</h4>
          <span
            onFocus={() => this.disableClick(true)}
            onBlur={() => this.disableClick(false)}
          >
            <AirlineSelect
              name='airline'
              airlineCode={airlineCode}
              onChange={this.handleAirlineChange('airline')}
              shouldFocus
              styleProps={{
                bgColor: transparentSilver,
                textColor: 'white',
                placeholderColor: 'white',
                iconColor: 'white',
                height: '34px',
              }}
              secondaryAction={this.props.secondaryAction ? this.handleSubmit : identity}
            />
          </span>
        </div>
        <div className='flight-number-search'>
          <h4>Flight Number</h4>
          <input
            className='new-alert-input'
            type='text'
            onChange={this.handleFlightNumberChange}
            placeholder='Example: 300'
            value={this.props.flightNumber}
          />
        </div>
        <div className='date-search'>
          <h4>Date</h4>
          <FSDatePicker
            className='new-alert-input'
            key='searchDate'
            locale='en'
            dateFormat={dateFormatString(this.props.user)}
            selected={this.props.searchDate}
            minDate={moment()}
            maxDate={moment().add(300, 'day')}
            onChange={this.dateChangeHandler}
            showMonthDropdown
            showYearDropdown
            dropdownMode='select'
            readOnly={false}
          />
        </div>
      </div>
    ) : (
      // this is what renders when user run out of reserve credits
      <div className='no-credits-setup'>
        <p>
          This service requires the use of credits.
          Currently, you have no reserve credits.
        </p>
      </div>
    ));

  render() {
    // this.logger('props: ', this.props);
    const hasCredits = this.hasCredits();
    const errorText = this.props.queryError ? (
      <div tabIndex='-1' className='flight-alert-errors' id='flight-alert-errors'>
        <h2>{ this.props.queryError }</h2>
      </div>
    ) : null;
    /* eslint-disable no-underscore-dangle */
    // the airline data we use has an underscore dangle
    const airlineCode = (this.props.airline && this.props.airline._id);
    /* eslint-enable no-underscore-dangle */
    const addNewAlertConsole = this.renderAddNewAlertConsole(errorText, airlineCode);

    return (
      <div
        className={`create-alert${hasCredits ? ' set-up' : ''}`}
        onKeyDown={this.handleKeyDown}
      >
        { addNewAlertConsole }
        <BuyCreditsGenericModal
          modalOpen={this.props.buyCreditsModalOpen}
          closeModal={this.closeModal}
        />
        <div className='new-flight-alert'>
          <FSFlatButton
            label={!hasCredits ? 'BUY MORE CREDITS' : 'SET UP A NEW FLIGHT ALERT'}
            type='submit'
            primary
            disabled={this.state.disableClick}
            onClick={this.handleSubmit}
            style={FSFlatButton.style({ color: 'white' })}
          />
        </div>
      </div>
    );
  }
}
