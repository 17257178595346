import { isBusinessUser } from '../../../../../src/utils/isBusinessUser';

export const isUsersLastHistoricalCredit = (currentUser) => {
  const { historicalReservedCredits, historicalMonthlyCredits } = currentUser;
  const businessUser = isBusinessUser(currentUser);
  // returns TRUE if user is not (a) a business user
  // (b) the total of all users historical credits equals exactly one
  const userHasOneCredit = (historicalMonthlyCredits + historicalReservedCredits) === 1;
  return !businessUser && userHasOneCredit;
};
