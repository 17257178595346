// @flow
/* eslint-disable */

const isEmpty = (value) => value === undefined || value === null || value === '';
const join = (rules) => (value, data) => rules.map(rule => rule(value, data)).filter(error => !!error)[0];

export const flightNumberInt = flightNumber => parseInt(flightNumber, 10);

export const naiveValidation = (items: Array<any>): boolean => items.every(item => !!item);

export function email(value: string) {
  // Let's not start a debate on email regex. This is just for an example app!
  if (!isEmpty(value) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    return 'Invalid email address';
  }
}

export function required(value: string | number) {
  if (isEmpty(value)) {
    return 'Required';
  }
}

export function minLength(min: number) {
  return (value: string) => {
    if (!isEmpty(value) && value.length < min) {
      return `Must be at least ${min} characters`;
    }
  };
}

export function maxLength(max: number) {
  return (value: string) => {
    if (!isEmpty(value) && value.length > max) {
      return `Must be no more than ${max} characters`;
    }
  };
}

export function integer(value: number) {
  if (!Number.isInteger(Number(value))) {
    return 'Must be an integer';
  }
}

export function oneOf(enumeration: Array<mixed>) {
  return (value: string | number) => {
    if (!~enumeration.indexOf(value)) {
      return `Must be one of: ${enumeration.join(', ')}`;
    }
  };
}

export function match(field: string) {
  return (value: string | number | boolean, data: {}) => {
    if (data) {
      if (value !== data[field]) {
        return 'Do not match';
      }
    }
  };
}

export function createValidator(rules: {}) {
  return (data: {} = {}) => {
    const errors = {};
    Object.keys(rules).forEach((key) => {
      const rule = join([].concat(rules[key])); // concat enables both functions and arrays of functions
      const error = rule(data[key], data);
      if (error) {
        errors[key] = error;
      }
    });
    return errors;
  };
}

export const validateAirportFSCode = (fsCode: string = ''): boolean => fsCode.length <= 4;

export const validateCarrierFSCode = (fsCode: string = ''): boolean => fsCode.length <= 3;

// Can validate AA100 or AA 100 carrier code/flight number input
// carrierNumberRegEx.exec('AA100') => [original string, AA, 100];
// Needs to return as a new regex since each call is a reference to the same eval
export const carrierFlightNumberRegex = () => new RegExp(/s*((?:[a-zA-Z]{2,3}|[a-zA-Z]\d|\d[a-zA-Z])\*?)\s*([0-9](?:\d{0,6}))?\s*.*/g);
export const flightNumberOnlyRegex = () => new RegExp(/\s(\d+)/g);

// Function to return { carrier, flightNumber } or null if no match
export function carrierFlightNumber(input: string) {
  const regexMatch = input && input.length > 2 && carrierFlightNumberRegex().exec(input);
  if (regexMatch && regexMatch.length === 3 && regexMatch[2] != null) {
      return {
        carrier: regexMatch[1],
        flightNumber: regexMatch[2],
      };
  }
  return null;
}
/* eslint-enable */
