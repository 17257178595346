import React from 'react';
import PropTypes from 'prop-types';

const FsSection = ({ style, className, children }) => (
  <section style={style} className={className}>
    {children}
  </section>
);

FsSection.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default FsSection;
