import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

export default class FlightAlertsHistorySummary extends React.Component {
  static propTypes = {
    filteredData: PropTypes.array,
    cost: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    currentFilterStart: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    currentFilterEnd: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    mediaBreakpoint: PropTypes.string,
    waitForInitialProcessing: PropTypes.bool,
    alerts: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    flightsMonitored: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    identifiedIssues: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    rehydrated: PropTypes.bool,
  };

  shouldComponentUpdate(nextProps) {
    if (nextProps.rehydrated) return true;
    return ((this.props.currentFilterStart !== nextProps.currentFilterStart) || (this.props.currentFilterEnd !== nextProps.currentFilterEnd));
  }

  _getAlertsSentText = (alertsSent = 0) => {
    let text = 'No Messages Sent';
    if (alertsSent === '--') {
      text = alertsSent;
    }
    if (alertsSent > 0) {
      text = `${alertsSent} Messages`;
    }
    return text;
  };

  _getCostText = (numAlerts) => {
    let text = numAlerts;
    if (numAlerts !== '--') { // if not default props
      text = `$${numAlerts}`;
    }
    return text;
  }

  _getFlightsMonitoredText = (flightsMonitored = 0) => {
    let text = 'No Flights Monitored';
    if (flightsMonitored === '--') {
      text = flightsMonitored;
    }
    if (flightsMonitored === 1) {
      text = `${flightsMonitored} Flight`;
    } else if (flightsMonitored > 0) {
      text = `${flightsMonitored} Flights`;
    }
    return text;
  };

  _getIssuesText = (identifiedIssues = 0) => {
    let text = 'No Issues Identified';
    if (identifiedIssues === '--') {
      text = identifiedIssues;
    } else if (identifiedIssues > 0) {
      text = `${identifiedIssues} Issues Identified`;
    }
    return text;
  };

  _getSubscriptionText = (numFlights = 0) => {
    let text = 'No Flights';
    if (numFlights === '--') {
      text = numFlights;
    } else if (numFlights > 0) {
      text = `${numFlights} Flights`;
    }
    return text;
  };

  _getTimeRange = (start, end) => {
    if (start === '--' || end === '--') {
      return '';
    }
    if (!start && !end) {
      return 'No Activity';
    }
    const momentizedStart = moment.utc(start);
    const momentizedEnd = moment.utc(end);
    const beginningOfTimeFrame = momentizedStart.format('MMMM-YYYY');
    const endingOfTimeFrame = momentizedEnd.format('MMMM-YYYY');
    if (momentizedStart.isSame(momentizedEnd, 'months')) { // timeframe spanned one month
      return `${beginningOfTimeFrame} Activity`;
    }
    return `${beginningOfTimeFrame} to ${endingOfTimeFrame} Activity`;
  };

  render() {
    const searchStartToEnd = this._getTimeRange(this.props.currentFilterStart, this.props.currentFilterEnd);
    const alertsSentText = this._getAlertsSentText(this.props.alerts);
    const flightsMonitoredText = this._getFlightsMonitoredText(this.props.flightsMonitored);
    const identifiedIssuesText = this._getIssuesText(this.props.identifiedIssues);
    const subscriptionIncludesText = this._getSubscriptionText();
    const costText = this._getCostText(this.props.cost);

    return (
      <div className={`flight-alerts-history-summary ${this.props.mediaBreakpoint}`}>
        <div className='title'>
          <h1>{ searchStartToEnd }</h1>
        </div>
        <div className='alerts-sent'>
          <div>
            <span className='title'>Alerts Sent</span>
            <span className='text'>{ alertsSentText }</span>
          </div>
        </div>
        <div className='flights-being-monitored'>
          <div>
            <span className='title'>Flights Monitored</span>
            <span className='text'>{ flightsMonitoredText }</span>
          </div>
        </div>
        <div className='identified-issues'>
          <div>
            <span className='title'>Identified Issues</span>
            <span className='text'>{ identifiedIssuesText }</span>
          </div>
        </div>
        <div className='cost'>
          <div>
            <span className='title'>Cost</span>
            <span className='text'>{ costText }</span>
          </div>
        </div>
        <div className='subscription-includes'>
          <div>
            <span className='title'>Subscription Includes</span>
            <span className='text'>{ subscriptionIncludesText }</span>
          </div>
        </div>
      </div>
    );
  }
}
