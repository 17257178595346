import React from 'react';
import PropTypes from 'prop-types';
import reportException from '../../../shared/lib/report-exception';


export default class LoadScript extends React.Component {
  static propTypes = {
    async: PropTypes.bool,
    onCreate: PropTypes.func,
    onUnmount: PropTypes.func,
    url: PropTypes.string,
  }

  static defaultProps = {
    async: true,
    onCreate: () => {},
  }

  componentDidMount() {
    const {
      onCreate, url,
    } = this.props;

    try {
      const script = document.createElement('script');
      script.async = this.props.async;
      script.src = url;
      script.onload = () => {
        onCreate();
        return true;
      };

      document.body.appendChild(script);
    } catch (err) {
      reportException(err, {}, 'load-script-component-error');
    }
  }

  shouldComponentUpdate() {
    return false;
  }

  componentWillUnmount() {
    const { onUnmount } = this.props;
    if (onUnmount) onUnmount();
  }

  render() {
    return null;
  }
}
