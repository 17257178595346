import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { pathOr } from 'ramda';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  anyFlightTrackerRoute,
  getComponentAdName,
  shouldAdStateChange,
} from '../../shared/utils';
import { Colors } from '../../../../../src/constants/style-constants';
import componentBase from '../../../../../src/lib/component-base';
import BannerAd from '../../../../../shared/components/BannerAd/BannerAd';
import { ExternalLink } from '../../../../../shared/components/ExternalLink/ExternalLink';
import * as ads from '../../../ads';

const relxGroup = require('../../../../../static/images/OrangeWhite-TM.svg');

const relxLink = 'https://www.relx.com';
const ciriumContactUsLink = 'https://www.cirium.com/about/contact-us/';

const RelxImage = styled.img`
  position: relative;
  height: 34px;
  top: -15px;
  right: -12px;
  float: right;

  @media (min-width: 500px) {
    top: -10px;
    height: 40px;
  }
`;

const legalLinks = [
  {
    linkTo: 'https://risk.lexisnexis.com/group/cookie-policy',
    label: 'Cookie Policy',
  },
  {
    linkTo: 'https://risk.lexisnexis.com/group/privacy-policy',
    label: 'Privacy Policy',
  },
  {
    linkTo: '/company/legal/terms-of-use',
    label: 'Terms of Use',
  },
  {
    linkTo: 'https://risk.lexisnexis.com/contact-us#security',
    label: 'Security ',
  },
];

const year = moment().format('YYYY');

const versionStyle = {
  color: Colors.gray3,
};

const copyrightStyle = {
  ...versionStyle,
  lineHeight: '12pt',
  width: '300px',
};

@componentBase('FooterComponent')
export default class FooterComponent extends React.Component {
  static propTypes = {
    componentName: PropTypes.string,
    isAccountPage: PropTypes.bool,
    location: PropTypes.object,
    loggedIn: PropTypes.bool,
    release: PropTypes.string,
    user: PropTypes.object,
    parsedComponentName: PropTypes.string,
  };

  constructor(props) {
    super(props);
    const shouldNotDisplayAds = !!props.user && props.user.subscriptionActive;
    this.state = {
      shouldNotDisplayAds,
      shouldUpdateAdsException: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    const shouldNotDisplayAds = shouldAdStateChange(this.props, nextProps);
    const shouldUpdateAdsException = anyFlightTrackerRoute(this.props, nextProps);
    this.setState({
      shouldNotDisplayAds,
      shouldUpdateAdsException: !shouldNotDisplayAds && shouldUpdateAdsException,
    });
  }

  footerStyle(subscriptionActive) {
    const style = {
      background: Colors.blue1,
    };
    if (!this.props.isAccountPage && subscriptionActive) delete style.background;
    return style;
  }

  render() {
    const { location = {} } = this.props;

    const {
      shouldNotDisplayAds,
      shouldUpdateAdsException,
    } = this.state;

    let ad = null;

    const subscriptionActive = pathOr(false, ['user', 'subscriptionActive'], this.props);
    const { pathname } = location;
    const { parsedComponentName } = this.props;
    const adComponentName = getComponentAdName(parsedComponentName, pathname);

    const footerAds = ads[adComponentName];

    if (!shouldNotDisplayAds && footerAds) {
      const adUnits = {
        mobileAd: footerAds.footerMobile,
        desktopAd: footerAds.footer,
      };

      ad = (
        <BannerAd
          {...adUnits}
          subscriptionActive={subscriptionActive}
          shouldUpdateAdsException={shouldUpdateAdsException}
          style={{
            margin: '0',
          }}
        />
      );
    }

    const logo = (
      <div className='fs-logo-address'>
        <div className='fs-contact-info'>
           <ExternalLink linkTo={ciriumContactUsLink}>
            Contact Us
          </ExternalLink>
          <p className='fs-footer-copyright' style={versionStyle}>Version {this.props.release}</p>
        </div>
        <ExternalLink linkTo={relxLink} nofollow >
          <RelxImage src={relxGroup} alt='RELX Group' />
        </ExternalLink>
      </div>
    );

    const terms = (
      <div className='fs-company-legal'>
        { /* <!-- OneTrust Cookies Preferences button start --> */ }
        <a id='ot-sdk-btn' className='ot-sdk-show-settings'>Cookie Settings</a>
        { /* <!-- OneTrust Cookies Preferences button end --> */ }
        {legalLinks.map(({ label, linkTo }, idx) => (
          <ExternalLink linkTo={linkTo} key={label} >
            <p style={{ marginTop: idx === 0 ? '6px' : 'inherit' }}>{label}</p>
          </ExternalLink>
        ))}
        <Link to='/sitemap'>
          <p>Sitemap</p>
        </Link>
        <p className='fs-footer-copyright' style={copyrightStyle}>
          <ExternalLink linkTo='https://risk.lexisnexis.com/group/copyright'>
            Copyright
          </ExternalLink>
          {' '}© {year} LNRS Data Services
        </p>
      </div>
    );

    return (
      <footer className={`footer-component ${this.props.componentName ? this.props.componentName : ''}`} style={this.footerStyle(subscriptionActive)}>
        <div className={subscriptionActive ? 'footer-top subscription-active' : 'footer-top'}>
          { ad }
        </div>
        <div className={subscriptionActive ? 'footer-bottom subscription-active' : 'footer-bottom'}>
          <div className='footer-left'>
            { terms }
          </div>
          <div className='footer-right'>
            { logo }
          </div>
        </div>
      </footer>
    );
  }
}
