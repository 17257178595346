import { pathOr } from 'ramda';
import * as t from './actionTypes';
import { getTrackerState } from './selectors';
import {
  validateAirportFSCode,
} from '../../../src/utils/validation';

const loading = type => ({
  type,
});

export const clearError = () => ({ type: t.CLEAR_ERROR });

export const loadDetailsByCode = ({ airportCode }) => async (dispatch, getStore, client) => {
  dispatch(loading(t.LOAD_DETAILS_BY_CODE));

  if (!validateAirportFSCode(airportCode)) {
    return dispatch({
      type: t.LOAD_FAIL,
      error: 'validation',
    });
  }

  try {
    const data = await client.get(`/airport/${airportCode}`);
    const selectedState = getTrackerState(data);

    const result = {
      ...data,
      ...selectedState,
    };

    return dispatch({
      type: t.LOAD_DETAILS_SUCCESS,
      result,
    });
  } catch (e) {
    const errorMessage = pathOr('Unknown error', ['response', 'body', 'message'], e);
    return dispatch({
      type: t.LOAD_FAIL,
      error: errorMessage,
    });
  }
};

export const setAirport = (airport, triggeredByEnter) => ({
  type: t.SET_AIRPORT,
  airport,
  triggeredByEnter,
});

export const setSecondaryAction = bool => ({
  type: t.SET_SECONDARY_ACTION,
  bool,
});
