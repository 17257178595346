import React, { Component } from 'react';
import PropTypes from 'prop-types';
import componentBase from '../../../src/lib/component-base';

@componentBase('IconHeader')
export default class IconHeader extends Component {
  static propTypes = {
    containerStyle: PropTypes.object,
    headerStyle: PropTypes.object,
    icon: PropTypes.string,
    iconStyle: PropTypes.object,
    onClickSubtitle: PropTypes.func,
    subtitle: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object, // jsx
    ]),
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object, // jsx
    ]),
    titleStyle: PropTypes.object,
  };

  handleSubtitleClick = (e) => {
    if (this.props.onClickSubtitle) {
      this.props.onClickSubtitle(e);
    }
  }

  render() {
    const subtitleStyle = {};

    if (!this.props.subtitle) {
      subtitleStyle.display = 'none !important';
    }

    return (
      <div className='icon-header' style={this.props.headerStyle}>
        <div className='icon-header-title-container' style={this.props.containerStyle}>
          <img className='icon' src={this.props.icon} style={this.props.iconStyle} alt='Icon' />
          <p className='title' style={this.props.titleStyle}>{this.props.title}</p>
        </div>
        <div className='icon-header-subtitle-container' onClick={e => this.handleSubtitleClick(e)} style={subtitleStyle}>
          <div className='subtitle'>{this.props.subtitle}</div>
        </div>
      </div>
    );
  }
}
