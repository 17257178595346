import { __ as placeHolder, cond, has, isEmpty, isNil } from 'ramda';

/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
const gaId = global.__GAID__ || __GAID__;
const goId = global.__GAGOID__ || __GAGOID__;
const gawIdPro = global.__GAWIDPRO__ || __GAWIDPRO__;
const gawIdStandard = global.__GAWIDSTANDARD__ || __GAWIDSTANDARD__;
const testMode = (global.__GAID_TEST__ || __GAID_TEST__) === 'true';
const gawId = (global.__GAWID__ || __GAWID__);
/* eslint-enable no-undef */
/* eslint-enable no-underscore-dangle */

let hasReportedPageview = false;

const isWindow = () => (typeof window !== 'undefined');

const validReportingObject = obj => [!!obj, typeof obj === 'object', !isEmpty(obj)]
  .every(val => val);

export const gtagScript = (gaId && !testMode ? `
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag('js', new Date());
  gtag('config', ${gawId}, { send_page_view: false, anonymize_ip: true });
  ` : ''
);

const testModeGtag = (...args) => { console.log('firing gtag command in test mode: ', { ...args }); };

const warnNoGtag = () => {
  console.warn('google ga must be initialized before calling events!');
};

const getGtag = cond([
  [() => testMode, () => testModeGtag],
  [() => ((!isWindow() || !window.gtag) && !isNil(gaId)), () => warnNoGtag],
  [() => isNil(gaId), () => () => {}],
  [() => true, () => window.gtag],
]);

const tagEvent = (eventName, data) => {
  if (!hasReportedPageview) {
    console.log('PVNR', eventName);
  }
  const tag = getGtag();
  tag('event', eventName, data);
};

export const set = (fields) => {
  if (!validReportingObject(fields)) {
    console.warn('ga set command requires fields object with at least one key: value pair');
    console.log('fields', fields);
    return false;
  }
  const tag = getGtag();
  tag('set', { ...fields });
  return true;
};

export const pageview = (canonical) => {
  const pagePath = { page_path: canonical };
  set(pagePath);
  if (hasReportedPageview) {
    // Full page load has already occurred
    tagEvent('page_view', pagePath);
  } else {
    const tag = getGtag();
    tag('config', gaId, {
      send_page_view: true,
      ...pagePath,
      optimize_id: goId,
      custom_map: {
        dimension1: 'userId',
        dimension2: 'domain',
        dimension3: 'subscription',
        dimension4: 'loggedIn',
        dimension5: 'subscriptionLevel',
      },
    });
    hasReportedPageview = true;
  }
};

const newParamsValueMap = {
  category: 'event_category',
  label: 'event_label',
  nonInteraction: 'non_interaction',
};
const newParamsHas = has(placeHolder, newParamsValueMap);

const formatDefaultAndCustomParams = (params, key) => (newParamsHas(key) ?
  { [newParamsValueMap[key]]: params[key] } :
  { [key]: params[key] }
);

export const event = (eventName, params) => {
  if (!eventName || !validReportingObject(params)) {
    console.warn(
      `ga event command requires eventName and
      params object with at least one key: value pair`,
    );
    return false;
  }
  const formattedParams = Object.keys(params)
    .reduce((a, b) => ({ ...a, ...formatDefaultAndCustomParams(params, b) }), {});
  tagEvent(eventName, { ...formattedParams });
  return true;
};

export const purchaseEvent = (transaction, item) => {
  const { transaction_id: transactionId } = transaction;
  const { id: purchaseId, name } = item;
  const notValidArgs = [
    !validReportingObject(transaction),
    !validReportingObject(item),
    !transactionId,
    !purchaseId && !name,
  ].some(v => v);
  if (notValidArgs) {
    console.warn(`
      ga event command requires transaction and item objects
      with at least one key: value pair.
      ga ecommerce requires transaction to have an id.
      ga ecommerce requires purchase item to have an id or a name.
    `);
    return false;
  }
  event('purchase', {
    ...transaction,
    items: [item],
  });
  return true;
};

export const standardConversionEvent = () => {
  const tag = getGtag();
  tag('config', gawIdStandard, {
    send_page_view: false,
  });
  event('conversion', {
    send_to: gawIdStandard,
    send_page_view: false,
  });
};

export const proConversionEvent = () => {
  const tag = getGtag();
  tag('config', gawIdPro, {
    send_page_view: false,
  });
  event('conversion', {
    send_to: gawIdPro,
    send_page_view: false,
  });
};


export const pagePerSessionEvent = (destinationPath: string): void => {
  const eventName = 'increasedPagePerSession';
  const params = {
    nonInteraction: false,
    category: 'pagesPerSession',
    destinationPath,
  };
  event(eventName, params);
};

export const reportButtonPress = (label, value) => {
  const eventDict = {
    label,
    category: 'User',
    nonInteraction: false,
  };

  if (!isNil(value)) {
    eventDict.value = value;
  }

  event('buttonPress', eventDict);
};
