// meta-tags/index.js

const anyStringMatches = (arr, val) => arr.some(arrVal => val.includes(arrVal));

const removeDateFromURL = [
  'flight-tracker',
  'flight-details',
  'flight-alerts',
];

const removeDateFromLongerURL = [
  'departing-arriving',
  'flight-tracker/route',
];

const parsePathname = (pathname) => {
  if (pathname === '/') {
    return pathname;
  }
  let spliceLength = 0;
  // this check for longer URLs MUST happen first!
  if (anyStringMatches(removeDateFromLongerURL, pathname)) {
    spliceLength = 4;
  } else if (anyStringMatches(removeDateFromURL, pathname)) {
    spliceLength = 3;
  }

  return (!spliceLength > 0) ? pathname :
    `/${pathname.split('/').splice(1, spliceLength).join('/')}`;
};

export const addMetaCanonical = (metaTags, pathname) => {
  if (!metaTags || !pathname) {
    return metaTags;
  }

  const { meta = [] } = metaTags;
  const canonical = `https://www.flightstats.com/v2${parsePathname(pathname)}`;

  meta.push({ property: 'og:url', content: canonical });

  return {
    ...metaTags,
    canonical,
    link: [{ rel: 'canonical', href: canonical }],
    meta,
  };
};
