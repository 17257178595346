import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import Toast from './Toast';

const ToastList = ({ toasts }) => {
  if (!toasts) {
    return <noscript />;
  }

  const toastItems = Object.keys(toasts).map(key => toasts[key]);

  return (
    <div id='toast-container'>
      <CSSTransition
        classNames='toast'
        timeout={{ enter: 200, exit: 200 }}
      >
        <>
          {toastItems.map(item => (
            <Toast
              id={item.id}
              key={item.id}
              title={item.title}
              message={item.message}
              iconClass={item.iconClass}
              active={item.active}
            />
          ))}
        </>
      </CSSTransition>
    </div>
  );
};

ToastList.propTypes = {
  toasts: PropTypes.object,
};

export default ToastList;
