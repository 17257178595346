import React from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'material-ui/FlatButton';
import FSFlatButton from '../../../../../../src/components/lib/FSFlatButton';
import componentBase from '../../../../../../src/lib/component-base';

import SelectedExableComponent from '../../../../../../src/components/lib/SelectedExableComponent';

@componentBase('ActiveFilters')
class ActiveFilters extends React.Component {
  static propTypes = {
    updateMenuObjectAndSelectedArray: PropTypes.func.isRequired,
    handleResetFiltersClick: PropTypes.func.isRequired,
    depArr: PropTypes.string.isRequired,
    destOrigin: PropTypes.string,
    airportCode: PropTypes.string.isRequired,
    items: PropTypes.object.isRequired,
    menuItems: PropTypes.object.isRequired,
    filterSelectedValues: PropTypes.object.isRequired,
    currentTimespanKey: PropTypes.string.isRequired,
    flightsShown: PropTypes.number.isRequired,
  };

  constructor(props, context) {
    super(props, context, 'ActiveFilters');
  }

  onClearSelectedItemClick = (clearedItem, filterType) => {
    const result = {
      ...clearedItem,
    };

    result.checked = false;
    this.props.updateMenuObjectAndSelectedArray(result, filterType);
  };

  getSelectedListDisplayString = (display, selectedValues, menuItemsObject) => {
    if (!selectedValues || !selectedValues.length) {
      return '';
    }

    const list = [];
    const filterType = display.replace(': ', '');
    for (const item in selectedValues) {
      if (menuItemsObject && selectedValues[item] && menuItemsObject[selectedValues[item]]) {
        list.push(<SelectedExableComponent
          key={item}
          item={menuItemsObject[selectedValues[item]]}
          filterType={filterType}
          onClearClick={this.onClearSelectedItemClick}
        />);
      }
    }
    return (
      <div><p>{display}</p> <div>{list}</div></div>
    );
  };

  getSelectedListDisplay = (filterType) => {
    let display;
    switch (filterType) {
      case 'airline':
        return this.getSelectedListDisplayString('Airlines: ', this.props.filterSelectedValues.airline, this.props.menuItems.airline);
      case 'destOrigin':
        display = `${this.props.destOrigin}: `;
        return this.getSelectedListDisplayString(
          display,
          this.props.filterSelectedValues.destOrigin,
          this.props.menuItems.destOrigin,
        );
      case 'status':
        return this.getSelectedListDisplayString('Status: ', this.props.filterSelectedValues.status, this.props.menuItems.status);
      case 'delay':
        return this.getSelectedListDisplayString('Delay: ', this.props.filterSelectedValues.delay, this.props.menuItems.delay);
      case 'equipment':
        return this.getSelectedListDisplayString('Equipment: ', this.props.filterSelectedValues.equipment, this.props.menuItems.equipment);
      case 'terminal':
        return this.getSelectedListDisplayString('Terminal: ', this.props.filterSelectedValues.terminal, this.props.menuItems.terminal);
      case 'gate':
        return this.getSelectedListDisplayString('Gate: ', this.props.filterSelectedValues.gate, this.props.menuItems.gate);
      case 'baggage':
        if (this.props.depArr === 'arrivals') {
          return this.getSelectedListDisplayString('Baggage: ', this.props.filterSelectedValues.baggage, this.props.menuItems.baggage);
        }
        return '';
      case 'timespan':
        display = 'Timespan: ';
        if (this.props.currentTimespanKey) {
          display += this.props.items.timespans[this.props.currentTimespanKey].label;
        } else if (this.initialTimespanValue) {
          display += this.initialTimespanValue;
        } else {
          display = '';
        }
        return display;
      default:
        this.logger('unexpected default case', filterType);
    }

    return '';
  };

  render() {
    let resetFilterButton = null;
    const resetFilterButtonError = (
      <FlatButton
        label='Reset Filters'
        backgroundColor='#E34622'
        onClick={this.props.handleResetFiltersClick}
        style={FSFlatButton.style({
 display: 'inline-block', paddingLeft: 0, paddingRight: 0, float: 'right', marginRight: '5px',
})}
      />
    );

    let errTextBlock = null;
    if (!this.props.flightsShown > 0) {
      const errText = `No Results for ${this.props.depArr} ${this.props.depArr === 'departures' ? 'from ' : 'to '} ${this.props.airportCode} based upon current filters`;
      errTextBlock = (
        <div className='row' style={{ backgroundColor: 'rgba(227,70,34,0.5)', padding: '10px 20px', marginBottom: '0' }}>
          <div className='col-xs-12 col-sm-8 col-md-9 col-lg-9' style={{ paddingTop: '5px' }}>
            <h4 style={{ fontSize: '15pt', textTransform: 'capitalize' }}>{errText}</h4>
          </div>
          <div className='col-xs-12 col-sm-4 col-md-3 col-lg-3'>
            {resetFilterButtonError}
          </div>
        </div>);
    } else {
      resetFilterButton = (
        <FlatButton
          label='Reset Filters'
          backgroundColor={this.context.muiTheme.rawTheme.palette.orange}
          onClick={this.props.handleResetFiltersClick}
          style={FSFlatButton.style({ display: 'inline-block', float: 'right', padding: 0 })}
        />
      );
    }

    return (
      <div className='flight-filters-header'>
        {errTextBlock}
        <div className='opaque' style={{ padding: '10px' }}>
          {resetFilterButton}
          <h5 className='fs-bold fs-mobile-live-filters'>Live Filters</h5>
          {this.getSelectedListDisplay('timespan')}
          {this.getSelectedListDisplay('airline')}
          {this.getSelectedListDisplay('destOrigin')}
          {this.getSelectedListDisplay('status')}
          {this.getSelectedListDisplay('delay')}
          {this.getSelectedListDisplay('equipment')}
          {this.getSelectedListDisplay('terminal')}
          {this.getSelectedListDisplay('gate')}
          {this.getSelectedListDisplay('baggage')}
        </div>
        <p className='fs-light' style={{ padding: '10px' }}>Selecting any combination of filters affects the flights displayed</p>
      </div>
    );
  }
}

export default ActiveFilters;
