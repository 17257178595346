import { pathOr } from 'ramda';
import * as t from './actionTypes';

const initialState = {
  loading: false,
  loaded: false,
  airline: null,
  byAirportAirline: null,
  byAirportDepartureDate: null,
  byAirportError: null,
  byFlightError: null,
  flightNumber: '',
  error: null,
  departureTime: 6,
  departureDate: null,
  flight: null,
  arrivalAirport: null,
  departureAirport: null,
  secondarySearchAction: false,
  secondaryAdvancedSearchAction: false,
  secondaryAdvancedSearchActionAirline: false,
  secondaryAdvancedSearchActionByAirportAirline: false,
  secondaryAdvancedSearchActionByAirportDepDate: false,
  secondaryAdvancedSearchActionDepAirport: false,
  secondaryAdvancedSearchActionArrAirport: false,
  secondaryAdvancedSearchActionDepDate: false,
  secondaryAdvancedSearchActionTime: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case t.SET_ADVANCED_SEARCH_BY_FLIGHT_ERROR:
      return {
        ...state,
        byFlightError: action.errorCode,
      };
    case t.SET_ADVANCED_SEARCH_BY_AIRPORT_ERROR:
      return {
        ...state,
        byAirportError: action.errorCode,
      };
    case t.SET_AIRLINE:
      return {
        ...state,
        airline: action.airline,
        secondaryAdvancedSearchActionAirline: action.secondaryAdvancedSearchAction,
      };
    case t.SET_ARRIVAL_AIRPORT:
      return {
        ...state,
        arrivalAirport: action.arrivalAirport,
        secondaryAdvancedSearchActionArrAirport: action.secondaryAdvancedSearchAction,
      };
    case t.SET_BY_AIRPORT_AIRLINE:
      return {
        ...state,
        byAirportAirline: action.byAirportAirline,
        secondaryAdvancedSearchActionByAirportAirline: action.secondaryAdvancedSearchAction,
      };
    case t.SET_BY_AIRPORT_DEP_DATE:
      return {
        ...state,
        byAirportDepartureDate: action.byAirportDepDate,
        secondaryAdvancedSearchActionByAirportDepDate: action.secondaryAdvancedSearchAction,
      };
    case t.SET_DEPARTURE_AIRPORT:
      return {
        ...state,
        departureAirport: action.departureAirport,
        secondaryAdvancedSearchActionDepAirport: action.secondaryAdvancedSearchAction,
      };
    case t.SET_DEPARTURE_DATE:
      return {
        ...state,
        departureDate: action.departureDate,
        secondaryAdvancedSearchActionDepDate: action.secondaryAdvancedSearchAction,
      };
    case t.SET_DEPARTURE_TIME:
      return {
        ...state,
        departureTime: action.departureTime,
        secondaryAdvancedSearchAction: action.secondaryAdvancedSearchAction,
      };
    case t.SET_FLIGHT_NUMBER:
      return {
        ...state,
        flightNumber: action.flightNumber,
      };
    case t.SET_FLIGHT_TRACKER_CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        queryError: null,
        byFlightError: null,
        byAirportError: null,
      };
    case t.SET_FLIGHT_TRACKER_SEARCH_ERROR:
      return {
        ...state,
        error: action.errorCode,
      };
    case t.SET_UNIVERSAL_SEARCH_FLIGHT:
      return {
        ...state,
        universalSearchValue: action.universalSearchFlight,
        secondarySearchAction: pathOr(false, ['universalSearchFlight', 'triggeredByEnter'], action),
      };
    case t.SET_SECONDARY_SEARCH_ACTION:
      return {
        ...state,
        secondarySearchAction: action.bool,
      };
    case t.SET_SECONDARY_ADVANCED_SEARCH_ACTION:
      return {
        ...state,
        secondaryAdvancedSearchAction: action.bool,
      };
    case t.SET_SECONDARY_AIRLINE:
      return {
        ...state,
        secondaryAdvancedSearchActionAirline: action.bool,
      };
    case t.SET_SECONDARY_BY_AIRPORT_AIRLINE:
      return {
        ...state,
        secondaryAdvancedSearchActionByAirportAirline: action.bool,
      };
    case t.SET_SECONDARY_BY_AIRPORT_DEP_DATE:
      return {
        ...state,
        secondaryAdvancedSearchActionByAirportDepDate: action.bool,
      };
    case t.SET_SECONDARY_DEP_AIRPORT:
      return {
        ...state,
        secondaryAdvancedSearchActionDepAirport: action.bool,
      };
    case t.SET_SECONDARY_ARR_AIRPORT:
      return {
        ...state,
        secondaryAdvancedSearchActionArrAirport: action.bool,
      };
    case t.SET_SECONDARY_DEP_DATE:
      return {
        ...state,
        secondaryAdvancedSearchActionDepDate: action.bool,
      };
    case t.SET_SECONDARY_TIME:
      return {
        ...state,
        secondaryAdvancedSearchActionTime: action.bool,
      };
    case t.SET_SECONDARY_ALL_BOOL:
      return {
        ...state,
        secondaryAdvancedSearchAction: action.bool,
        secondaryAdvancedSearchActionAirline: action.bool,
        secondaryAdvancedSearchActionByAirportAirline: action.bool,
        secondaryAdvancedSearchActionByAirportDepDate: action.bool,
        secondaryAdvancedSearchActionDepAirport: action.bool,
        secondaryAdvancedSearchActionArrAirport: action.bool,
        secondaryAdvancedSearchActionDepDate: action.bool,
        secondaryAdvancedSearchActionTime: action.bool,
      };
    case t.SET_RANDOM_FLIGHT_PATH:
      return {
        ...state,
        randomFlightPathLoading: action.randomFlightPathLoading,
      };
    case t.SET_RANDOM_FLIGHT_PATH_SUCCESS:
      return {
        ...state,
        randomFlightPathLoading: false,
        randomFlightPathError: null,
        randomFlightPath: action.randomFlightPath,
      };
    case t.SET_RANDOM_FLIGHT_PATH_FAIL:
      return {
        ...state,
        randomFlightPathError: action.error,
        randomFlightPathLoading: false,
        randomFlightPath: null,
      };
    case t.SET_FLIGHT_TRACKER_QUERY_ERROR:
      return {
        ...state,
        queryError: action.data.error,
      };
    default:
      return state;
  }
};
