import { chain, fromPairs, map, toPairs } from 'ramda';
import { parse } from 'qs';

const parseQueryString = query => parse(query, {
  ignoreQueryPrefix: true,
  allowDots: true,
});

const flattenObject = (obj: {}) => {
  const go = (objX: {}): any =>
    chain(([k, v]: [{}, {}]) => {
      if (typeof v === 'object') {
        return map(([kX, vX]) => [`${k}.${kX}`, vX], go(v));
      }
      return [[k, v]];
    }, toPairs(objX));
  return fromPairs(go(obj));
};

export const parseVariantsFromQueryString = (location = {}) => {
  const { search } = location;
  const { variants, variant } = parseQueryString(search);
  const items = variants || variant;
  return !items || typeof items === 'object' ?
    flattenObject(items) :
    { [items]: true };
};
