import * as t from './actionTypes';

export const clearOTP = () => ({
  type: t.CLEAR_OTP,
});

const startSpinner = () => ({
  type: t.START_SPINNER,
});

const endSpinner = () => ({
  type: t.END_SPINNER,
});

const loadingOTPByFlight = () => ({
  type: t.LOAD_OTP_BY_FLIGHT,
});

const loadOTPSuccess = result => ({
  type: t.LOAD_OTP_SUCCESS,
  result,
});

const loadOTPFail = error => ({
  type: t.LOAD_FAIL,
  error,
});

export const loadOTPByFlight = ({
  carrierCode,
  flightNumber,
  departureAirport,
  arrivalAirport,
}) => (
  (
    async (dispatch, getStore, client) => {
      dispatch(startSpinner());
      dispatch(loadingOTPByFlight());
      try {
        const departureAirportParam = departureAirport ? `/${departureAirport.toUpperCase()}` : '';
        const arrivalAirportParam = arrivalAirport ? `/${arrivalAirport.toUpperCase()}` : '';
        const result = await client.get(`/on-time-performance/${carrierCode.toUpperCase()}/${flightNumber}${departureAirportParam}${arrivalAirportParam}`);
        dispatch(loadOTPSuccess(result));
        dispatch(endSpinner());
      } catch (err) {
        dispatch(loadOTPFail(err));
        dispatch(endSpinner());
      }
    }
  )
);
