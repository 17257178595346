/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import componentBase from '../../../../../src/lib/component-base';
import AsyncElasticsearchSelect from '../../../../../src/components/lib/AsyncElasticsearchSelect';
import { setUniversalSearchFlight } from '../../../../FlightTracker/redux/Search/actions';
import { timeFormatString } from '../../../../../src/lib/date-time-format';
// assets
import orangePlane from '../../../../../static/images/plane-orange.svg';
import arrivalPlane from '../../../../../static/images/Arrivals-Icons-SOLID.svg';
import departurePlane from '../../../../../static/images/Departures-Icons-SOLID.svg';

const PlaceholderText = 'Try "aa100" or "AA JFK LHR" or "London Chicago"';

@componentBase('UnifiedSearch')
export default class UnifiedSearch extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    frozenState: PropTypes.object,
    isMobile: PropTypes.bool,
    secondaryAction: PropTypes.func,
    universalSearchFlight: PropTypes.object,
    user: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);

    this.errorText = null;
    this.flight = null;
    this.state = this.props.frozenState || {};
  }

  componentWillUnmount() {
    this.cyclingText && this.cyclingText.stop();
  }

  formatItem = (doc) => {
    const source = doc ? doc._source : null;
    if (!source) {
      return '';
    }

    const basicCalenderFormatConfig = {
      sameDay: '[Today]',
      nextDay: '[Tomorrow]',
      nextWeek: 'dddd',
      lastDay: '[Yesterday]',
      lastWeek: '[Last] dddd',
      sameElse: 'DD/MM/YYYY',
    };
    const formatCustomTexts = () => {
      const departureCalendarTime = moment.parseZone(source.departureDateTime).calendar();
      const arrivalCalendarTime = moment.parseZone(source.arrivalDateTime).calendar();
      const simpleArrivalCalendarTime = moment
        .parseZone(source.arrivalDateTime)
        .calendar(null, basicCalenderFormatConfig);
      const simpleDepartureCalendarTime = moment
        .parseZone(source.departureDateTime)
        .calendar(null, basicCalenderFormatConfig);
      const status = source.status && source.status.toUpperCase();
      switch (status) {
        case 'ACTIVE':
          return {
            dateTimePretty: `Arrives ${arrivalCalendarTime}`,
            searchSubText: `Arrives ${simpleArrivalCalendarTime}`,
          };
        case 'CANCELED':
          return {
            dateTimePretty: `Scheduled ${departureCalendarTime} but Cancelled`,
            searchSubText: `Scheduled ${simpleDepartureCalendarTime}`,
          };
        case 'CANCELLED':
          return {
            dateTimePretty: `Scheduled ${departureCalendarTime} but Cancelled`,
            searchSubText: `Scheduled ${simpleDepartureCalendarTime}`,
          };
        case 'DIVERTED':
          return {
            dateTimePretty: `Diverted ${arrivalCalendarTime}`,
            searchSubText: `Diverted ${simpleArrivalCalendarTime}`,
          };
        case 'LANDED':
          return {
            dateTimePretty: `Arrived ${arrivalCalendarTime}`,
            searchSubText: `Arrived ${simpleArrivalCalendarTime}`,
          };
        case 'REDIRECTED':
          return {
            dateTimePretty: `Departs ${departureCalendarTime}`,
            searchSubText: `Departs ${simpleDepartureCalendarTime}`,
          };
        case 'SCHEDULED':
          return {
            dateTimePretty: `Departs ${departureCalendarTime}`,
            searchSubText: `Departs ${simpleDepartureCalendarTime}`,
          };
        default:
          return {
            dateTimePretty: `Scheduled ${departureCalendarTime}`,
            searchSubText: `Scheduled ${simpleDepartureCalendarTime}`,
          };
      }
    };

    const airport = source.departureAirport && source.arrivalAirport ?
      `${source.departureAirport} → ${source.arrivalAirport}` :
      ' ';
    const {
      dateTimePretty,
      searchSubText,
    } = formatCustomTexts();
    const inputDisplayValue = `${source.carrierName} ${source.flightNumber} ${airport} ${dateTimePretty}`;
    const {
      arrivalDateTime,
      arrivalTimeZone,
      departureDateTime,
      departureTimeZone,
    } = source;
    const departureTime = moment.parseZone(departureDateTime);
    const arrivalTime = moment.parseZone(arrivalDateTime);
    const menuDepartureDateTime = departureTimeZone ?
      moment.tz(moment(departureDateTime), departureTimeZone) :
      moment(departureTime);
    const menuArrivalDateTime = arrivalTimeZone ?
      moment.tz(moment(arrivalDateTime), arrivalTimeZone) :
      moment(arrivalTime);

    // remove timezone tags that look ambiguous to the user like '+04'
    const removeUnformattedTimezoneTags = dateString => dateString.replace(/[+]\d{1,3}/, '');
    const dateString = `${timeFormatString(this.props.user)}${departureTimeZone ? ' z' : ''}`;

    return {
      searchResultMainText: `${source.carrierName} ${source.flightNumber}`,
      searchSubText,
      inputDisplayValue,
      secondaryResultText: {
        mainIcon: orangePlane,
        primary: {
          0: `${source.departureAirport}`,
          1: `${source.departureAirportCity}`,
          2: departurePlane,
          3: removeUnformattedTimezoneTags(`${menuDepartureDateTime.format(dateString)}`),
        },
        secondary: {
          0: `${source.arrivalAirport}`,
          1: `${source.arrivalAirportCity}`,
          2: arrivalPlane,
          3: removeUnformattedTimezoneTags(`${menuArrivalDateTime.format(dateString)}`),
        },
      },
    };
  };

  changeFlight = (entry, triggeredByEnter) => {
    if (entry && entry.value) {
      const value = entry.value._source;
      const ddt = moment.parseZone(value.departureDateTime);
      this.url = `/v2/flight-tracker/${value.carrier}/${value.flightNumber}?year=${ddt.year()}&month=${ddt.month() + 1}&date=${ddt.date()}&flightId=${value.flightId}`;
      this.flight = value;
    } else {
      this.url = null;
      this.flight = null;
    }

    this.props.dispatch(setUniversalSearchFlight({
      ...entry,
      flight: this.flight,
      url: this.url,
      triggeredByEnter,
    }));

    this.context.reportButtonPress('UnifiedSearch');
    // initiate a new request to the Next app
    window.location = process.env.NODE_ENV === 'development' ? `http://localhost:4000${this.url}` : this.url;
  }

  // reset universalSearchValue, just has to fail casting to bool
  handleDelete = () => (this.props.dispatch(setUniversalSearchFlight()))

  validate = () => {
    if (this.flight == null) { return 'UNIVERSAL_SEARCH_EMPTY'; }
    return null;
  }

  render() {
    const value = this.props.universalSearchFlight && this.props.universalSearchFlight.value;
    const noResultsTexts = {
      noResultsTitle: 'No Results Found',
      subTitlePrimary: 'Enter flight number, airline, or airports.',
      subTitleSecondary: 'Try "aa100" or "london jfk aa"',
    };

    return (
      <section className='fs-search-card'>
        <div className='universal-search'>
          <AsyncElasticsearchSelect
            name='universal-search'
            input={value}
            inputTitle='Enter your flight information'
            isMobile={this.props.isMobile}
            noResultsTexts={noResultsTexts}
            onChange={this.changeFlight}
            onDelete={this.handleDelete}
            placeholder={PlaceholderText}
            itemFormatter={this.formatItem}
            searchSource='structuredSearch'
            secondaryAction={this.props.secondaryAction}
            shouldFocus
          />
        </div>
      </section>
    );
  }
}
/* eslint-enable no-underscore-dangle */
