import PropTypes from 'prop-types';

const DefaultContextTypes = {
  history: PropTypes.object,
  params: PropTypes.object,
  location: PropTypes.object,
  map: PropTypes.object,
  mediaBreakpoint: PropTypes.object,
  userAgent: PropTypes.object,
  muiTheme: PropTypes.object,
  reportButtonPress: PropTypes.func,
  reportEvent: PropTypes.func,
  reportPurchase: PropTypes.func,
  reportUserEvent: PropTypes.func,
  reportQueryTimeFrame: PropTypes.func,
  reportSendInteraction: PropTypes.func,
  reportUserSubscribed: PropTypes.func,
  reportUserUnsubscribed: PropTypes.func,
  reportUserDeleteAccount: PropTypes.func,
  reportUserUpgraded: PropTypes.func,
  goto: PropTypes.func,
  gotoHandler: PropTypes.func,
  setSessionItem: PropTypes.func,
  router: PropTypes.object,
  store: PropTypes.object,
  toast: PropTypes.object,
  currentUser: PropTypes.object,
  push: PropTypes.func,
  images: PropTypes.object,
};

export default DefaultContextTypes;
