import { pathOr } from 'ramda';
import { push } from 'connected-react-router';

import * as t from './actionTypes';

export const setUserAgent = userAgent => ({
  type: t.SET_USER_AGENT,
  userAgent,
});

export const setRecaptchaSiteKey = siteKey => ({
  type: t.SET_RECAPTCHA_SITE_KEY,
  siteKey,
});

export const setRelease = release => ({
  type: t.SET_RELEASE,
  release,
});

export const setRouteName = routeName => ({
  type: t.SET_ROUTE_NAME,
  routeName,
});

export const addVariants = variants => ({
  type: t.ADD_VARIANTS,
  variants,
});

export const clearVariants = () => ({
  type: t.CLEAR_VARIANTS,
});

export const setRouteConfig = routeConfig => ({
  type: t.SET_ROUTE_CONFIG,
  routeConfig,
});

export const setPollingIntervalId = pollingIntervalId => ({
  type: t.SET_INTERVAL_ID,
  pollingIntervalId,
});

export const setSlotRefreshed = (adUnitId, clear) => (dispatch, getState) => {
  const slotsRefreshed = pathOr([], ['App', 'slotsRefreshed'], getState());
  const newState = clear ? [] : [...slotsRefreshed, adUnitId];
  return dispatch({
    type: t.SET_SLOT_REFRESHED,
    slotsRefreshed: newState,
  });
};
  // setBreakpoint: mediaBreakpoint => dispatch(appActions.setBreakpoint(mediaBreakpoint)),

export const setBreakpoint = mediaBreakpoint => ({
  type: t.SET_CURRENT_BREAKPOINT,
  mediaBreakpoint,
});

export const setAppHost = appHost => ({
  type: t.SET_APP_HOST,
  appHost,
});

export const routerPush = url => dispatch => dispatch(push(url));

