import React from 'react';
import PropTypes from 'prop-types';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Dialog from 'material-ui/Dialog';
import { confirmable } from 'react-confirm';
import FSFlatButton from '../../../../src/components/lib/FSFlatButton';
import defaultContextTypes from '../../../../src/components/default-context-types';

@confirmable
export default class TestAlertModalDialog extends React.Component {
  static contextTypes = defaultContextTypes;
  static childContextTypes = defaultContextTypes;
  static propTypes = {
    show: PropTypes.bool, // from confirmable. indicates if the dialog is shown or not.
    cancelLabel: PropTypes.string,
    title: PropTypes.string,
    cancel: PropTypes.func,
    proceed: PropTypes.func,
    modal: PropTypes.bool,
    dismiss: PropTypes.func, // from confirmable. call to only close the dialog.
    confirmation: PropTypes.string, // arguments of your confirm function
    options: PropTypes.object, // arguments of your confirm function
  };

  handleOnClick(index) {
    const { proceed } = this.props;
    return () => {
      proceed({
        button: index,
      });
    };
  }

  render() {
    const {
      cancelLabel = 'Cancel Test',
      title,
      confirmation,
      show,
      dismiss,
      cancel,
      modal,
      options,
    } = this.props;

    const actions = [
      <FSFlatButton id='confirm-modal-ok-button' label='Pre-Departure' primary onClick={this.handleOnClick('PRE_DEPARTURE_STATUS')} style={{ backgroundColor: 'rgb(140, 56, 46)', color: '#fff', margin: '2px' }} />,
      <FSFlatButton id='confirm-modal-ok-button' label='Pre-Arrival' primary onClick={this.handleOnClick('PRE_ARRIVAL_STATUS')} style={{ backgroundColor: 'rgb(140, 56, 46)', color: '#fff', margin: '2px' }} />,
      <FSFlatButton id='confirm-modal-ok-button' label='Departed Late' primary onClick={this.handleOnClick('DEPARTED_LATE')} style={{ backgroundColor: 'rgb(140, 56, 46)', color: '#fff', margin: '2px' }} />,
      <FSFlatButton id='confirm-modal-ok-button' label='Arrived Late' primary onClick={this.handleOnClick('ARRIVED_LATE')} style={{ backgroundColor: 'rgb(140, 56, 46)', color: '#fff', margin: '2px' }} />,
      <FSFlatButton id='confirm-modal-ok-button' label='En-Route' primary onClick={this.handleOnClick('EN_ROUTE')} style={{ backgroundColor: 'rgb(140, 56, 46)', color: '#fff', margin: '2px' }} />,
      <FSFlatButton id='confirm-modal-ok-button' label='Landed' primary onClick={this.handleOnClick('LANDED')} style={{ backgroundColor: 'rgb(140, 56, 46)', color: '#fff', margin: '2px' }} />,
      <FSFlatButton id='confirm-modal-ok-button' label='Cancelled' primary onClick={this.handleOnClick('CANCELLED')} style={{ backgroundColor: 'rgb(140, 56, 46)', color: '#fff', margin: '2px' }} />,
      <FSFlatButton id='confirm-modal-ok-button' label='Re-Instated' primary onClick={this.handleOnClick('REINSTATED')} style={{ backgroundColor: 'rgb(140, 56, 46)', color: '#fff', margin: '2px' }} />,
      <FSFlatButton id='confirm-modal-ok-button' label='Diverted' primary onClick={this.handleOnClick('DIVERTED')} style={{ backgroundColor: 'rgb(140, 56, 46)', color: '#fff', margin: '2px' }} />,
      <FSFlatButton id='confirm-modal-ok-button' label='Departure Delay' primary onClick={this.handleOnClick('DEPARTURE_DELAY')} style={{ backgroundColor: 'rgb(140, 56, 46)', color: '#fff', margin: '2px' }} />,
      <FSFlatButton id='confirm-modal-ok-button' label='Arrival Delay' primary onClick={this.handleOnClick('ARRIVAL_DELAY')} style={{ backgroundColor: 'rgb(140, 56, 46)', color: '#fff', margin: '2px' }} />,
      <FSFlatButton id='confirm-modal-ok-button' label='Departure Gate' primary onClick={this.handleOnClick('DEPARTURE_GATE')} style={{ backgroundColor: 'rgb(140, 56, 46)', color: '#fff', margin: '2px' }} />,
      <FSFlatButton id='confirm-modal-ok-button' label='Arrival Gate' primary onClick={this.handleOnClick('ARRIVAL_GATE')} style={{ backgroundColor: 'rgb(140, 56, 46)', color: '#fff', margin: '2px' }} />,
      <FSFlatButton id='confirm-modal-ok-button' label='Baggage' primary onClick={this.handleOnClick('BAGGAGE')} style={{ backgroundColor: 'rgb(140, 56, 46)', color: '#fff', margin: '2px' }} />,
      <FSFlatButton label={cancelLabel} secondary onClick={cancel} style={{ backgroundColor: 'rgb(118, 177, 92)', color: '#fff', margin: '2px' }} />,
    ];

    return (
      <div>
        <MuiThemeProvider>
          <Dialog
            className='mui-dialog'
            contentClassName='mui-dialog-content'
            bodyStyle={{ color: 'rgba(7, 28, 53, 0.8)' }}
            bodyClassName={options.hideText ? 'confirm-text-hidden' : 'confirm-text-default'}
            title={title}
            actions={actions}
            modal={modal}
            open={show}
            onRequestClose={dismiss}
            options={options}
          >
            {confirmation}
          </Dialog>
        </MuiThemeProvider>
      </div>
    );
  }
}
