import { func, string } from 'prop-types';
import React, { useEffect } from 'react';

import { validatePassword } from '../../lib/password-rules';

const Checkmark = () => (
  <svg className='checkmark' enableBackground='new 0 0 500 500' viewBox='0 0 500 500' xmlns='http://www.w3.org/2000/svg'><path d='m105.1 285.8 33.2-26.2 70.5 71.2 149-229.3 37 18.7-179.4 278.4z' fill='#fff' /></svg>
);

const Circle = () => (
  <svg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'>
    <circle fill='#fff' cx='50' cy='50' r='8' />
  </svg>
);

export const PasswordValidation = ({ password = '', validationCallback }) => {
  const { valid, errors: validationErrors } = validatePassword(password);
  useEffect(() => {
    if (validationCallback) {
      validationCallback({ valid });
    }
  }, [valid]);

  const hasPassword = password.length > 0;
  const lowerCaseError = !hasPassword || validationErrors.includes('PASSWORD_NO_LOWERCASE');
  const upperCaseError = !hasPassword || validationErrors.includes('PASSWORD_NO_UPPERCASE');
  const numberError = !hasPassword || validationErrors.includes('PASSWORD_NO_NUMBER');
  const specialCharsError = !hasPassword || validationErrors.includes('PASSWORD_NO_SPECIAL_CHAR');
  return (
    <div className='password-validation-wrapper'>
      <p>
        <span className={`${valid ? 'success' : 'error'} password-info`}>Longer passwords are more secure. Please choose a password between 8 and 30 characters long</span>
        <span className={`${valid ? 'success' : 'error'}`}>Your password must contain at least 3 of the following:</span>
      </p>

      <ul className={valid ? 'success' : 'invalid'}>
        <li className={!lowerCaseError ? 'success' : ''}>
          {!lowerCaseError ? <Checkmark /> : <Circle />}
          Lower case letters (a-z)
        </li>
        <li className={!upperCaseError ? 'success' : ''}>
          {!upperCaseError ? <Checkmark /> : <Circle />}
          Upper case letters (A-Z)
        </li>
        <li className={!numberError ? 'success' : ''}>
          {!numberError ? <Checkmark /> : <Circle />}
          Numbers (0-9)
        </li>
        <li className={!specialCharsError ? 'success' : ''}>
          {!specialCharsError ? <Checkmark /> : <Circle />}
          Special characters (eg. !@#$%^&*)
        </li>
      </ul>
    </div>
  );
};

PasswordValidation.propTypes = {
  password: string,
  validationCallback: func,
};
