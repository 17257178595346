import { pathOr } from 'ramda';
import * as t from './actionTypes';

const initialState = {
  loading: false,
  loaded: false,
  airport: {
    timerOn: false,
  },
  loadAttempts: 0,
  detailsHeader: {},
  forecastWeather: [],
  delayIndex: {},
  currentWeather: {},
  airportCode: '',
  airportSearchItem: {},
  callSecondaryAction: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case t.CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    case t.LOAD_DETAILS_BY_CODE:
      return {
        ...state,
        // error: null,
        loading: true,
        loadAttempts: state.loadAttempts + 1,
      };
    case t.LOAD_DETAILS_SUCCESS:
      const {
        detailsHeader,
        forecastWeather,
        delayIndex,
        currentWeather,
        airportCode,
        airport,
      } = action.result;

      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        loadAttempts: state.loadAttempts + 1,
        detailsHeader,
        forecastWeather,
        delayIndex,
        currentWeather,
        airportCode,
        airport,
      };
    case t.LOAD_FAIL:
      return {
        ...state,
        loadAttempts: state.loadAttempts + 1,
        loading: false,
        loaded: false,
        error: action.error,
      };
    case t.SET_AIRPORT:
      return {
        ...state,
        airportSearchItem: action.airport,
        callSecondaryAction: pathOr(false, ['triggeredByEnter'], action),
      };
    case t.SET_SECONDARY_ACTION:
      return {
        ...state,
        callSecondaryAction: action.bool,
      };
    default:
      return state;
  }
};
