import React, { Component } from 'react';
import PropTypes from 'prop-types';
import braintreeClient from 'braintree-web/client';
import braintreeHostedFields from 'braintree-web/hosted-fields';
import { pathOr } from 'ramda';

import Icon from '../../../../src/components/lib/Icon';
import componentBase from '../../../../src/lib/component-base';
import CreditCardIcon from '../../../../static/images/credit-card-icon.svg';
import VisaLogo from '../../../../static/images/visa-logo.png';
import AmexLogo from '../../../../static/images/amex-logo.png';
import MasterCardLogo from '../../../../static/images/mastercard-logo.png';
import {
  _errorMessage,
  _errorClass,
  errorStrings,
  clientDidCreateStyles,
  clientDidCreateFields,
  setSubmitButtonDisabled,
  scrollToId,
} from '../shared/utility';
import IconHeader from '../../../../shared/components/IconHeader/IconHeader';
import reportException from '../../../../shared/lib/report-exception';
import { subscriptionCostForLevel } from '../../../../shared/lib/subscription-level-matching';

const errorIds = {
  subscription: 'subscription-select',
  terms: 'terms-and-conditions',
};

const errorToIDDict = {
  // corresponds to ids of error elements on payment form
  [errorStrings.noSubscription]: errorIds.subscription,
  [errorStrings.noTermsAndConditions]: errorIds.terms,
};

@componentBase('BraintreePaymentForm')
export default class BraintreePaymentForm extends Component {
  static propTypes = {
    braintreeSubscription: PropTypes.object,
    braintreeSubscriptionError: PropTypes.object,
    braintreeSubmitButtonDisabled: PropTypes.bool,
    creatingBraintreeSubscription: PropTypes.bool,
    clientToken: PropTypes.string.isRequired,
    handleCheckBoxChange: PropTypes.func,
    hostedFields: PropTypes.object,
    hostedFieldsDidCreate: PropTypes.func,
    isGeneralPaymentForm: PropTypes.bool,
    onPaymentFormSubmitted: PropTypes.func.isRequired,
    tierSelection: PropTypes.number,
    termsLink: PropTypes.string,
    termsText: PropTypes.string,
  };

  constructor(props, context) {
    super(props, context);

    this.errorDisplayWording = {
      number: 'Credit Card Number',
      cvv: 'CVV',
      expirationMonth: 'Expiration Month',
      expirationYear: 'Expiration Year',
    };

    this.termsCheckbox = null;
    this.submitBtn = null;
  }

  state = {
    hostedFields: null,
    generalError: null,
  };

  componentDidMount() {
    if (!this.props.clientToken) {
      reportException('Missing Braintree client token', null, 'braintree-hosted-fields');
    } else {
      braintreeClient.create({
        authorization: this.props.clientToken,
      }, this.clientDidCreate);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.isGeneralPaymentForm) {
      const {
        braintreeSubscription,
        braintreeSubscriptionError,
      } = nextProps;
      if (braintreeSubscriptionError) {
        // TODO: handle the many different types of error
        return false;
      }
      if (braintreeSubscription) {
        this.context.goto('/account/success');
      }
    }

    const {
      hostedFields,
    } = nextProps;
    if (hostedFields) {
      this.setState({
        hostedFields,
      });
    }
    return false;
  }

  setGeneralError = (message) => {
    this.setState({ generalError: message });
    this._scrollToError(message);
  }

  setSubmitButtonDisabled = disabled => setSubmitButtonDisabled(disabled, this);

  _scrollToError = (message) => {
    const errorId = pathOr('payment-info-error-text', [message], errorToIDDict);
    scrollToId(errorId);
  }

  submitHandler = (event) => {
    event.preventDefault();
    this.clearErrors();

    // gotta find on payment form submitted
    this.props.onPaymentFormSubmitted();
    return false;
  }

  clientDidCreate = (err, client) => {
    if (err) {
      this.context.toast.error('Please try again later', 'Error', false, 4);
    } else {
      const styles = { ...clientDidCreateStyles };
      const fields = { ...clientDidCreateFields };
      braintreeHostedFields.create({
        client,
        styles,
        fields,
      }, this.hostedFieldsCreationHandler);
    }
  };

  hostedFieldsCreationHandler = (err, hostedFields) => {
    this.props.hostedFieldsDidCreate(err, hostedFields);
  }

  clearErrors = () => {
    this.setState({ invalidFieldKeys: null, generalError: null });
  }

  handleCheckBox = () => {
    const { checked } = this.termsCheckbox;

    this.props.handleCheckBoxChange && this.props.handleCheckBoxChange(checked);
  }

  sendPaymentMethod = () => new Promise((resolve, reject) => {
    const hostedFields = this.state.hostedFields || this.props.hostedFields;
    if (hostedFields) {
      hostedFields.tokenize((err, payload) => {
        if (err) {
          const newState = {};
          if (err.code === 'HOSTED_FIELDS_FIELDS_INVALID') {
            newState.invalidFieldKeys = err.details && err.details.invalidFieldKeys;
          } else if (err.code === 'HOSTED_FIELDS_FIELDS_EMPTY') {
            newState.invalidFieldKeys = ['number', 'cvv', 'expirationMonth', 'expirationYear'];
          } else {
            reportException(err, null, 'braintree-hosted-fields');
            newState.generalError = err.message;
          }

          newState.submitDisabled = false;
          this.setState(newState);
          this._scrollToError();
          return reject(err);
        }
        this.setState({ invalidFieldKeys: null });
        return resolve(payload);
      });
    } else {
      reject(new Error('Something went wrong. Please retry'));// this message going to be displayed on a toast if user tried to submit before tokenization
    }
  });

  render() {
    let error = '';
    if (this.state.generalError) {
      error = (
        <p className='account-error-text'>{this.state.generalError}</p>
      );
    }

    let monthlyRate = '';
    let submitPaymentButton = '';
    if (!this.props.isGeneralPaymentForm) {
      const { tierSelection } = this.props;
      const tierCost = subscriptionCostForLevel(tierSelection);

      monthlyRate = (
        <div className='row payment-form-footer'>
          <div className='col-xs-12' style={{ verticalAlign: 'middle' }}>
            <p style={{ textAlign: 'right' }}>MONTHLY RATE &nbsp; &#9654; &nbsp; {tierCost} USD &nbsp; </p>
          </div>
        </div>
      );

      const buttonDisabled =
        this.state.submitDisabled ||
        this.props.creatingBraintreeSubscription ||
        this.props.braintreeSubmitButtonDisabled;

      submitPaymentButton = (
        <div className='row'>
          <div className='col-xs-12'>
            {error}
            <div className='submit-payment-button'>
              <input
                className={`paymentButton${buttonDisabled ? ' disabled' : ''}`}
                ref={ref => (this.submitBtn = ref)}
                id='submit-btn'
                type='submit'
                value={buttonDisabled ? 'PLEASE WAIT' : 'SUBMIT PAYMENT'}
                style={{ minWidth: null }}
                disabled={buttonDisabled}
              />
            </div>
          </div>
        </div>
      );
    }

    const termsLink = this.props.termsLink || '/company/legal/flightstats-subscription-agreement-terms-and-conditions';
    const termsText = this.props.termsText || 'Subscription Agreement';
    /* eslint-disable no-script-url  */
    return (
      <div className='subscription-form-section braintree-payment-form'>
        <div id='payment-info-error-text' />
        <div className='section-head'>
          <IconHeader icon={CreditCardIcon} title='Payment Information' />
        </div>

        <form
          action='javascript:null'
          className='payment-form'
          method='POST'
          ref={ref => (this.paymentForm = ref)}
          onSubmit={this.submitHandler}
        >
          <input type='hidden' name='payment_method_nonce' />

          <div className='form-box'>
            <div className='row'>
              <div className='col-xs-12 fieldset'>
                <div className='row'>
                  <div className='col-xs-11'>
                    <div className='fieldContainer'>
                      <div id='billing-info-error-number' />
                      <label htmlFor='card-number' className={`accountFieldLabel info ${_errorClass('number', this.state)}`}>
                        {_errorMessage('number', 'Credit Card Number', this.state)}
                      </label>
                      <div id='card-number' className={`account-input ${_errorClass('number', this.state)}`} />
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-xs-2'>
                    <div id='billing-info-error-cvv' />
                    <label htmlFor='cvv' className={`accountFieldLabel info ${_errorClass('cvv', this.state)}`}>
                      {_errorMessage('cvv', 'CVV', this.state)}
                    </label>
                    <div id='cvv' className={`account-input ${_errorClass('cvv', this.state)}`} />
                  </div>
                  <div className='col-xs-offset-1 col-xs-4'>
                    <div id='billing-info-error-number' />
                    <label htmlFor='expiration-month-selector' className={`accountFieldLabel info ${_errorClass('expirationMonth', this.state)}`}>
                      <span className='visible-xs visible-sm visible-md'>{_errorMessage('expirationMonth', 'Exp. Month', this.state)}</span>
                      <span className='visible-lg'>{_errorMessage('expirationMonth', 'Expiration Month', this.state)}</span>
                    </label>
                    <div id='expirationMonth' className={`account-input ${_errorClass('expirationMonth', this.state)}`} />
                  </div>

                  <div className='col-xs-offset-1 col-xs-3 form-expiration-year'>
                    <div id='billing-info-error-number' />
                    <label htmlFor='expiration-year-selector' className={`accountFieldLabel info ${_errorClass('expirationYear', this.state)}`}>
                      <span className='visible-xs visible-sm visible-md'>{_errorMessage('expirationYear', 'Exp. Year', this.state)}</span>
                      <span className='visible-lg'>{_errorMessage('expirationYear', 'Expiration Year', this.state)}</span>
                    </label>
                    <div id='expirationYear' className={`account-input ${_errorClass('expirationYear', this.state)}`} />
                  </div>
                </div>
              </div>
            </div>

            <div className='row accepted-payment-types'>
              <div className='col-xs-12' style={{ textAlign: 'center' }}>
                <p>
                  Currently we accept&nbsp;
                  <br className='visible-xs visible-sm' />
                  <Icon src={VisaLogo} width='40px' height='24px' /> &nbsp; <Icon src={MasterCardLogo} width='40px' height='24px' /> &nbsp; <Icon src={AmexLogo} width='40px' height='24px' />
                </p>
              </div>
            </div>
            {monthlyRate}
          </div>

          <div className='row' style={{ margin: '12px 0 0 0' }}>
            <div className='terms-link'>
              <div id={`${errorIds.terms}`} />
              <input
                className='checkbox'
                ref={ref => (this.termsCheckbox = ref)}
                id='terms-checkbox'
                type='checkbox'
                value='1'
                onChange={() => this.handleCheckBox()}
              />
              <span className='text'>
                I have read and agree to the <a
                  href={termsLink}
                  target='_blank'
                  className='terms-link'
                  rel='noopener noreferrer'
                >
                  {`FlightStats ${termsText} Terms and Conditions`}
                </a>
              </span>
            </div>
          </div>
          {submitPaymentButton}
        </form>
      </div>
    );
    /* eslint-enable no-script-url  */
  }
}
