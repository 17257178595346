import React from 'react';
import PropTypes from 'prop-types';
import componentBase from '../../../src/lib/component-base';
import GenericModal from '../GenericModal/GenericModal';
import FSFlatButton from '../../../src/components/lib/FSFlatButton';

@componentBase('LargeGenericModal')
export default class LargeGenericModal extends GenericModal {
  static propTypes = {
    isModal: PropTypes.bool,
    modalOpen: PropTypes.bool,
    closeModal: PropTypes.func,
    title: PropTypes.string,
    image: PropTypes.string,
    actionTitle: PropTypes.string,
    bodyClassName: PropTypes.string,
    body: PropTypes.object,
    selectAction: PropTypes.func,
    secondaryActionTitle: PropTypes.string,
    secondaryActionStyle: PropTypes.object,
    secondaryActionBackgroundColor: PropTypes.string,
    secondaryActionHoverColor: PropTypes.string,
    disableButtons: PropTypes.bool,
    noSecondaryButton: PropTypes.bool,
    noPrimaryButton: PropTypes.bool,
    disablePrimaryButton: PropTypes.bool,
    disableSecondaryButton: PropTypes.bool,
  };

  static defaultProps = {
    disableButtons: false,
    disableSecondaryButton: false,
    disablePrimaryButton: false,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      buttonsDisabled: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.disableButtons || nextProps.disableButtons === false) {
      this.setState({ buttonsDisabled: nextProps.disableButtons });
    }
  }

  handleModalClose = (e) => {
    if (e && e.preventDefault) e.preventDefault(); // onRequestClose does not pass event with preventDefault
    this.setState({ buttonsDisabled: true }, () => {
      if (this.props.closeModal) {
        this.props.closeModal();
      }
    });
  }

  handleOptionSelect = () => {
    this.setState({ buttonsDisabled: true }, () => {
      this.props.selectAction();
    });
  }

  render() {
    const secondaryActionStyle = Object.assign({ marginBottom: '5px', marginRight: '10px', color: 'black' }, this.props.secondaryActionStyle);
    const actions = [];

    if (!this.props.noSecondaryButton) {
      actions.push(<FSFlatButton
        label={this.props.secondaryActionTitle ? this.props.secondaryActionTitle : 'CANCEL'}
        name='secondary-action'
        primary={false}
        onClick={this.handleModalClose}
        style={FSFlatButton.style(secondaryActionStyle)}
        backgroundColor={this.props.secondaryActionBackgroundColor}
        hoverColor={this.props.secondaryActionHoverColor}
        disabled={this.state.buttonsDisabled || this.props.disableSecondaryButton}
      />);
    }

    if (!this.props.noPrimaryButton && this.props.actionTitle) {
      actions.push(<FSFlatButton
        label={this.props.actionTitle}
        name='primary-action'
        primary
        onClick={this.handleOptionSelect.bind(this)}
        style={FSFlatButton.style({ marginBottom: '5px', marginRight: '10px' })}
        disabled={this.state.buttonsDisabled || this.props.disablePrimaryButton}
      />);
    }

    if (this.context.mediaBreakpoint && this.context.mediaBreakpoint.value < 768) {
      actions.reverse();
    }

    return (
      <GenericModal
        isModal={this.props.isModal}
        modalOpen={this.props.modalOpen}
        body={this.props.body}
        bodyClassName={this.props.bodyClassName}
        headerIcon={this.props.image}
        headerString={this.props.title}
        isLargeModal
        largeActions={actions}
        closeModal={this.handleModalClose}
      />
    );
  }
}
