import { NAME } from './constants';
import { flightNumberSort, dateSort, stringSort, sortByComplexString } from '../Shared/selectors';

export const getFlightAlerts = state => state[NAME];
export const isLoaded = globalState => (globalState[NAME] && globalState[NAME].loaded);
export const getSortedAlerts = (state) => {
  const { sortColumn, sortOrder, currentResult } = state[NAME];
  // console.log(sortColumn, sortOrder, currentResult);

  if (!currentResult) {
    return null;
  }

  switch (sortColumn) {
    case 'DATE':
      return sortOrder ? dateSort(currentResult, 'ASC') : dateSort(currentResult, 'DESC');
    case 'FLIGHT':
      return sortOrder ? flightNumberSort(currentResult, 'ASC') : flightNumberSort(currentResult, 'DESC');
    case 'ORIGIN':
      return sortOrder ? stringSort(currentResult, 'ASC', 'departureAirportFsCode') : stringSort(currentResult, 'DESC', 'departureAirportFsCode');
    case 'DEST':
      return sortOrder ? stringSort(currentResult, 'ASC', 'arrivalAirportFsCode') : stringSort(currentResult, 'DESC', 'arrivalAirportFsCode');
    case 'DEPART':
      return sortOrder ? dateSort(currentResult, 'ASC') : dateSort(currentResult, 'DESC');
    case 'DESCRIPT':
      return sortOrder ? currentResult.sort(sortByComplexString('alertDescription')) : currentResult.sort(sortByComplexString('alertDescription')).reverse();
    default:
      return sortOrder ? dateSort(currentResult, 'ASC') : dateSort(currentResult, 'DESC');
  }
};
