import React from 'react';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';
import componentBase from '../../../../../../src/lib/component-base';

const rgbaBlue = 'rgba(129, 162, 202, .8)';
const rgbaRed = 'rgba(227, 70, 34, .8)';
const rgbaOrange = 'rgba(250, 168, 23, .8)';
const rgbaYellow = 'rgba(227, 216, 34, .8)';
const rgbaGreen = 'rgba(125, 214, 59, .8)';

const backgroundColorKey = {
  very: rgbaGreen,
  low: rgbaGreen,
  moderate: rgbaYellow,
  significant: rgbaOrange,
  excessive: rgbaRed,
};

@componentBase('StatusAndPhase')
export default class StatusAndPhase extends React.Component {
  static propTypes = {
    defRowHeight: PropTypes.number,
    score: PropTypes.number,
    status: PropTypes.string,
    trend: PropTypes.string,
    observed: PropTypes.bool,
  };

  constructor(props, context) {
    super(props, context);

    const defHeight = 23;

    this.muiDefaultProps = {
      rowDivStyle: {
        padding: '0',
        height: `${defHeight}px`,
      },
      colDivStyleParent: {
        padding: 0,
        height: 'inherit',
      },
      colDivStyleChild: {
        padding: 0,
        height: 'inherit',
      },
      colDivWithBkgStyle: {
        padding: 0,
        height: 'inherit',
      },
    };
  }

  getStyle = (trendAndPhase) => {
    const style = {
      position: 'absolute',
      top: '50%',
      WebkitTransform: 'translateY(-50%)',
      msTransform: 'translateY(-50%)',
      transform: 'translateY(-50%)',
      fontSize: '12pt',
      padding: '0 5px',
    };

    if (trendAndPhase) {
      style.left = '120px';
      style.fontWeight = 'bold';
    }

    return style;
  }

  setStyling = (defaultStyleProps, colorProps = { color: 'white' }) => ({ ...defaultStyleProps, ...colorProps })

  getPhase() {
    const NA = 'NOT AVAILABLE';
    if (this.props.status && this.props.trend) {
      if (!this.props.observed) {
        return NA;
      }

      if (this.props.trend.toLowerCase() === 'no change') {
        return `${this.props.status.toUpperCase()}`;
      }

      return `${this.props.status.toUpperCase()} and ${this.props.trend.toLowerCase()}`;
    }

    return NA;
  }

  render() {
    // this.logger('[StatusAndPhase] render : props', this.props);
    if (this.props.status) {
      const key = this.props.status.split(' ')[0].toLowerCase();
      const background = propOr(rgbaBlue, key, backgroundColorKey);

      return (
        <div key={`${key}`} className='row' style={this.muiDefaultProps.rowDivStyle}>
          <div key={`${key}-bkg`} className='col-xs-12 col-sm-12 col-md-12 col-lg-12' style={Object.assign({}, this.muiDefaultProps.colDivWithBkgStyle, { background })}>
            <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12' style={this.muiDefaultProps.colDivStyleChild}>
              <span key={`${key}-span-one`} className='delay-status-text' style={this.setStyling(this.getStyle(false))}>
                DELAY STATUS
              </span>
              <span key={`${key}-span-three`} className='delay-status-phase-trend' style={this.setStyling(this.getStyle(true))}>
                {this.getPhase()}
              </span>
            </div>
          </div>
        </div>
      );
    }

    return (<div />);
  }
}
