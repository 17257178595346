import { NAME } from './constants';

export const LOAD_DETAILS_BY_CODE = `${NAME}/LOAD_DETAILS_BY_CODE`;
export const LOAD_DETAILS_SUCCESS = `${NAME}/LOAD_DETAILS_SUCCESS`;
export const LOAD_DELAY_INDICES = `${NAME}/LOAD_DELAY_INDICES`;
export const LOAD_DELAY_INDICES_SUCCESS = `${NAME}/LOAD_DELAY_INDICES_SUCCESS`;
export const LOAD_FAIL = `${NAME}/LOAD_FAIL`;
export const SET_AIRPORT = `${NAME}/SET_AIRPORT`;
export const UPDATE_MAP = `${NAME}/UPDATE_MAP`;
export const UPDATE_AIRPORT_CODE = `${NAME}/UPDATE_AIRPORT_CODE`;
export const START_SPINNER = `${NAME}/START_SPINNER`;
export const END_SPINNER = `${NAME}/END_SPINNER`;
export const INITIAL_LOAD_FINISH = `${NAME}/INITIAL_LOAD_FINISH`;
