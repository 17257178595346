import React from 'react';
import PropTypes from 'prop-types';
import IconHeader from '../../../../../../../shared/components/IconHeader/IconHeader';

const MarketingVideoCard = (props) => {
  const {
    featureBullets,
    featureIcon,
    featureTitle,
    featureHeading,
    featureSummary,
    featureClosing,
    videoSource,
    subscriptionButton,
  } = props;

  const renderFeatureBullets = () => (
    featureBullets.map((bullet, index) => {
      const key = `subscribe${index}`;
      return (
        <li
          className='subscribe-feature-bullet'
          key={key}
        >
          {bullet}
        </li>
      );
    })
  );

  return (
    <section className='subscription-feature-info'>
      <div className='row'>
        <article className='col-xs-12 col-sm-6'>
          <aside>
            <IconHeader
              icon={featureIcon}
              title={featureTitle}
            />
          </aside>
          <div className='marketing-feature-text'>
            {featureHeading ?
              <p>{featureHeading}</p> :
              null
            }
            {featureSummary ?
              <p>{featureSummary}</p> :
              null
            }
            {featureBullets ?
              <ul>{renderFeatureBullets()}</ul> :
              null
            }
            {featureClosing ?
              <p>{featureClosing}</p> :
              null
            }
          </div>
        </article>
        <div className='video-card-container col-xs-12 col-sm-6'>
          {videoSource && <iframe
            title='video-card'
            width='100%'
            height='100%'
            src={videoSource}
            frameBorder='0'
            allowFullScreen
          />}
        </div>
      </div>
      <div className='row'>
        <div className='col-xs-12 col-sm-6'>
          {subscriptionButton}
        </div>
      </div>
    </section>
  );
};

MarketingVideoCard.propTypes = {
  featureBullets: PropTypes.arrayOf(PropTypes.string),
  featureClosing: PropTypes.string,
  featureHeading: PropTypes.string,
  featureIcon: PropTypes.string,
  featureSummary: PropTypes.string,
  featureTitle: PropTypes.string,
  videoSource: PropTypes.string,
  subscriptionButton: PropTypes.object,
};

export default MarketingVideoCard;
