const polyfills = () => {
  // .remove for IE
  [Element.prototype, CharacterData.prototype, DocumentType.prototype].forEach((item) => {
    if (item.remove) {
      return;
    }
    Object.defineProperty(item, 'remove', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function remove() {
        this.parentNode.removeChild(this);
      },
    });
  });
};

export default polyfills;
