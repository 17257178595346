
// Return error.response.text, or if there's no response then
// the error itself should be returned, or an empty string by default.
const errorText = (error) => {
  if (!error) {
    return '';
  }

  const { response } = error;
  if (!response) {
    return error instanceof Error ? error : '';
  }

  const { text = '' } = response;
  return text;
};

export {
  errorText,
};
