import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { identity, pathOr, path } from 'ramda';
import FSFlatButton from '../../../src/components/lib/FSFlatButton';
import componentBase from '../../../src/lib/component-base';
import { errorMessageForCode } from '../../../src/lib/errors';
import { getFSHost } from '../../../src/lib/hostname';
import flexErrors from '../../../src/constants/flex-errors';
import { Colors } from '../../../src/constants/style-constants';
import {
  setFlightTrackerSearchError,
  // setUniversalSearchFlight,
  setSecondarySearchAction,
  setAdvancedSearchByFlightError,
} from '../../FlightTracker/redux/Search/actions';
import {
  setSingleFlightSecondarySearchAction,
} from '../../SingleFlight/redux/actions';
import UnifiedSearch from './children/UnifiedSearch/UnifiedSearch';
import { updateMultiFlightSearchCodeShareToggle } from '../../MultiFlight/redux/actions';

@connect(state => ({
  error: state.FlightTrackerSearch.error,
  universalSearchFlight: state.FlightTrackerSearch.universalSearchFlight,
  universalSearchValue: state.FlightTrackerSearch.universalSearchValue,
  queryError: state.FlightTrackerSearch.queryError,
  secondarySearchAction: state.FlightTrackerSearch.secondarySearchAction,
  airline: state.FlightTrackerSearch.airline,
  byFlightError: state.FlightTrackerSearch.byFlightError,
  flightNumber: state.FlightTrackerSearch.flightNumber,
  departureDate: state.FlightTrackerSearch.departureDate,
}))
@componentBase('FlightSearch')
export default class FlightSearch extends Component {
  static propTypes = {
    airline: PropTypes.object,
    departureDate: PropTypes.string,
    dispatch: PropTypes.func,
    error: PropTypes.string,
    gaTitlePrefix: PropTypes.string,
    isMobile: PropTypes.bool,
    queryError: PropTypes.string,
    flightNumber: PropTypes.string,
    history: PropTypes.object,
    secondaryAction: PropTypes.bool,
    secondarySearchAction: PropTypes.bool,
    routeName: PropTypes.string,
    title: PropTypes.string,
    universalSearchValue: PropTypes.object,
    user: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);

    this.submitUniversalSearch = this.submitUniversalSearch.bind(this);
    this.submitClassicFlightSearch = this.submitClassicFlightSearch.bind(this);
    this.reportButtonPress = this.reportButtonPress.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.secondarySearchAction) {
      /*
        secondary search gets fired only once
        (the render cycle where this condition is true)
      */
      nextProps.dispatch(setSecondarySearchAction(false));
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    // clear error message
    dispatch(setFlightTrackerSearchError(null));
    dispatch(setSecondarySearchAction(false));
  }

  advancedSearchClick = () => {
    this.reportButtonPress('GoToAdvancedSearch');
  }

  validate = () => {
    const { universalSearchValue } = this.props;
    if (!universalSearchValue) { return 'UNIVERSAL_SEARCH_EMPTY'; }
    return null;
  };

  // ///////////////////////////////
  // TODO: DRY this up
  buildSearchByFlightUrl = () => {
    const {
      airline,
      flightNumber,
      departureDate,
    } = this.props;

    const [year, month, day] = departureDate.split('/');
    const airlineCode = this.getCodes(airline);

    if (flightNumber && airlineCode) {
      return `/flight-tracker/${airlineCode}/${flightNumber}?year=${year}&month=${month}&date=${day}`;
    }

    return null;
  }

  gotoResults = (destination) => {
    const {
      dispatch,
      history,
    } = this.props;
    // reset search triggers
    dispatch(setSingleFlightSecondarySearchAction(true));
    const topOfStackHistory = path(['location', 'pathname'], history);
    if (destination && topOfStackHistory !== destination) {
      // multiple identical entries were being pushed into history on enter keydown
      dispatch(push(destination));
    }
  }

  searchByFlight = () => {
    const {
      dispatch,
      user,
    } = this.props;
    const errorCode = this.validateByFlightSearch();
    dispatch(setAdvancedSearchByFlightError(errorCode));
    const hideCodeshares = pathOr(false, ['hideCodeshares'], user);
    dispatch(updateMultiFlightSearchCodeShareToggle(hideCodeshares));

    if (errorCode == null) {
      this.gotoResults(this.buildSearchByFlightUrl());
    }
  }

  validateByFlightSearch = () => {
    const {
      airline,
      flightNumber,
    } = this.props;

    if (!airline) {
      // If there is no airline
      return 'MISSING_AIRLINE_CODE';
    } else if (!flightNumber) {
      // If there is no flight number
      return 'MISSING_FLIGHT_NUMBER';
    }

    return null;
  }

  getCodes = (airLinePort) => {
    if (airLinePort) {
      const { value } = airLinePort;
      const { _source } = value;
      return (_source && (_source.fs || _source.iata || _source.icao)) || null;
    }
    return null;
  }
  // ///////////////////////////////

  submitClassicFlightSearch(e) {
    e && e.preventDefault();
    this.reportButtonPress('Search By Flight');
    this.searchByFlight();
  }

  submitUniversalSearch(e) {
    e && e.preventDefault();
    this.reportButtonPress('Smart Search');

    const {
      dispatch,
      // history,
      // universalSearchValue = {},
    } = this.props;

    const errorCode = this.validate();
    dispatch(setFlightTrackerSearchError(errorCode));
    /*
    uncomment out to restore search button functionality
    gonna be a fake button for the foreseeable future

    const destination = universalSearchValue && universalSearchValue.url;

    if (!errorCode && destination) {
      dispatch(setUniversalSearchFlight());

      // reset search triggers
      dispatch(setSingleFlightSecondarySearchAction(true));
      const topOfStackHistory = path(['location', 'pathname'], history);
      if (destination && topOfStackHistory !== destination) {
        // multiple identical entries were being pushed into history on enter
        dispatch(push(destination));
      }
    }
    */
  }

  reportButtonPress = (label) => {
    this.context.reportButtonPress(label);
  }

  render = () => {
    const {
      error,
      queryError,
      title,
    } = this.props;

    let errorBlock = '';
    const errorText = (error && flexErrors(error)) ||
      (queryError && errorMessageForCode(queryError));

    if (errorText) {
      errorBlock = (
        <div className='search-error-text-block'>
          <p className='search-error-text'>{errorText}</p>
        </div>
      );
    }

    let titleBlock = '';
    if (title) {
      titleBlock = <h3 className='flight-search-title'>{title}</h3>;
    }

    const searchFormFields = (
      <Fragment>
        <p className='landing-text'>Enter your flight information</p>
        <UnifiedSearch
          {...this.props}
          secondaryAction={
            this.props.secondarySearchAction ? this.submitUniversalSearch : identity
          }
        />
      </Fragment>
    );

    return (
      <section className='FlightSearch'>
        <div className='flight-search-container'>
          <div className='fs-search'>
            <form onSubmit={this.submitUniversalSearch}>
              <div>
                {titleBlock}
                {errorBlock}
              </div>
              {searchFormFields}
              <div className='flight-tracker-smart-search-buttons-group'>
                <div className='flight-tracker-smart-search-button'>
                  <FSFlatButton
                    label='Search'
                    type='submit'
                    className='flight-tracker-search-button'
                    primary
                  />
                </div>
                <div className='flight-tracker-smart-search-button'>
                  <a href={`${getFSHost()}/flight-tracker/search`}>
                    <FSFlatButton
                      label='Advanced Search'
                      style={FSFlatButton.style({ color: 'white' })}
                      backgroundColor={Colors.blue6}
                      hoverColor={Colors.blue8}
                      className='flight-tracker-adv-search-button'
                      onClick={this.advancedSearchClick}
                    />
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    );
  }
}
