import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import Helmet from 'react-helmet';
import { CommonSection } from '@flightstats/component-lib';
import { actions } from '../redux';
import { isValidEmail } from '../../../src/helpers/Validators';
import FSFlatButton from '../../../src/components/lib/FSFlatButton';
import GoogleRecaptcha from '../../../shared/components/GoogleRecaptcha';
import componentBase from '../../../src/lib/component-base';
import EmailTextInput from './shared/EmailTextInput';

/* eslint-disable no-useless-escape */
const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
/* eslint-enable no-useless-escape */

@connect(
  state => ({
    forgotPasswordError: state.Account.forgotPasswordError,
    forgotPasswordReset: state.Account.forgotPasswordReset,
    forgotPasswordRecaptchaValue: state.Account.forgotPasswordRecaptchaValue,
    siteKey: state.App.siteKey,
  }),
  actions,
)
@componentBase('ForgotPassword')
export default class ForgotPassword extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    forgotPassword: PropTypes.func,
    forgotPasswordError: PropTypes.array,
    forgotPasswordReset: PropTypes.bool,
    resetAuthState: PropTypes.func,
    setForgotPasswordError: PropTypes.func,
    siteKey: PropTypes.string,
    forgotPasswordRecaptchaValue: PropTypes.string,
    forgotPasswordRecaptcha: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);

    if (this.props.forgotPasswordReset) {
      this.forgotPasswordReset = false;
    }

    this.errorDisplayWording = {
      MISSING_EMAIL: 'An email address is required',
      UNKNOWN_USER: 'The email address provided is not in our system',
      INVALID_EMAIL: 'The email address provided is invalid',
      UNKNOWN_ERROR: 'Error',
    };
    this.login = this.login.bind(this);
    this.setEmailRef = this.setEmailRef.bind(this);
    this.email = null;
  }

  componentDidMount() {
    this.props.resetAuthState();
  }

  componentWillReceiveProps(props) {
    this.forgotPasswordReset = props.forgotPasswordReset;
  }

  onCaptchaVerify = (value) => {
    // Called when recaptcha checked
    // Value is key from recaptcha
    if (value && !this.props.forgotPasswordRecaptchaValue) {
      this.props.forgotPasswordRecaptcha(value);
      this.handleSubmit();
    }
  };

  onEnterKeyPress = (e) => {
    if (e.charCode === 13) {
      this.handleSubmit(e);
    }
    this.lastKeyPressed = e.charCode;
  };

  getMetaTags = () => {
    const title = 'FlightStats - Forgot Password';
    return {
      title,
      meta: [{ name: 'robots', content: 'noindex' }],
    };
  };

  setEmailRef = (ref) => {
    this.email = ref;
  };

  handleSubmit = (event) => {
    const {
      forgotPassword,
      setForgotPasswordError,
      forgotPasswordRecaptchaValue,
    } = this.props;
    event && event.preventDefault();

    const value = this.email && this.email.value;
    const lowerCaseValue = value && value.toLowerCase();

    if (!lowerCaseValue.length) {
      setForgotPasswordError('MISSING_EMAIL');
    } else if (!this.testEmailAddress(lowerCaseValue)) {
      setForgotPasswordError('INVALID_EMAIL');
    } else if (forgotPasswordRecaptchaValue) {
      setForgotPasswordError();
      forgotPassword({
        email: lowerCaseValue,
        recaptchaKey: forgotPasswordRecaptchaValue,
      });
    } else {
      this.recaptchaRef.execute();
    }
  };

  login = () => {
    this.props.dispatch(push('/account/login'));
    this.forgotPasswordReset = false;
  };

  recaptchaRefSet = (e) => {
    if (e) {
      this.recaptchaRef = e;
    }
  };

  testEmailAddress = email => regex.test(email);

  sanitizedEmail = () => (
    this.email.value && this.email.value.trim().toLowerCase()
  );

  validateEmail = () => {
    if (this.lastKeyPressed !== 13 && isValidEmail(this.sanitizedEmail())) {
      this.props.resetAuthState();
    }
    this.lastKeyPressed = null;
  };

  innerFormContent = () => {
    if (this.forgotPasswordReset) {
      return (
        <div className='innerContent'>
          <div style={{ margin: '10px 0' }}>
            <p className='innerDescriptionText'>
              We sent you an email that contains a link to change your password.
            </p>
          </div>
          <br /><br />
          <div className='innerPadding'>
            <FSFlatButton
              label='Login'
              style={FSFlatButton.style({ minWidth: '200px' })}
              onClick={this.login}
            />
          </div>
        </div>
      );
    }

    let emailError = '';
    if (this.props.forgotPasswordError) {
      emailError = this.errorDisplayWording[this.props.forgotPasswordError[0]];
    }

    return (
      <div className='innerContent'>
        <form
          onSubmit={this.handleSubmit}
          onKeyPress={this.onEnterKeyPress}
          method='POST'
        >
          <div className='innerForm'>
            <EmailTextInput
              errorMessage={emailError}
              inputRef={this.setEmailRef}
              validateEmail={this.validateEmail}
            />
            <p className='innerDescriptionText'>
              {`Please provide us with the email address that you used to sign up
                for FlightStats and we will email you a link to reset your password`}
            </p>
            <div className='form-group captcha' id='captcha-holder'>
              <GoogleRecaptcha
                sitekey={this.props.siteKey}
                size='invisible'
                ref={elem => this.recaptchaRefSet(elem)}
                onChange={this.onCaptchaVerify}
                badge='inline'
              />
            </div>
          </div>
          <br />
          <div className='innerPadding'>
            <FSFlatButton
              label='Continue'
              onClick={this.handleSubmit}
              style={FSFlatButton.style({ minWidth: '200px' })}
            />
          </div>
        </form>
        <div className='innerPadding'>
          <p className='account-no-account-text'>
            Don&apos;t have a FlightStats account?
          </p>
          <Link
            key='createNewAccount'
            to='/register/plan'
            className='account-link'
            style={{ textDecoration: 'underline' }}
          >
            Create a New Account
          </Link>
        </div>
      </div>
    );
  }

  render() {
    const meta = this.getMetaTags();
    return (
      <div className='sharedAccountFormContainer'>
        <Helmet {...meta} />
        <h1 className='accountTitle'>Forgot Your Password</h1>
        <CommonSection title='Forgot Password'>
          {this.innerFormContent()}
        </CommonSection>
      </div>
    );
  }
}
