/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import componentBase from '../../lib/component-base';
import AsyncElasticsearchSelect from './AsyncElasticsearchSelect';

@componentBase('AirlineSelect')
export default class AirlineSelect extends Component {
  static propTypes = {
    name: PropTypes.string,
    airline: PropTypes.object,
    handleInput: PropTypes.func,
    inputTitle: PropTypes.string,
    isMobile: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDelete: PropTypes.func,
    selectOnBlur: PropTypes.bool,
    shouldFocus: PropTypes.bool,
    styleProps: PropTypes.object,
    secondaryAction: PropTypes.func,
  };

  onChange = (option, triggeredByEnter) => {
    if (this.props.onChange) {
      this.props.onChange(option, triggeredByEnter);
    }
  }

  onDelete = (option) => {
    if (this.props.onDelete) {
      this.props.onDelete();
    } else if (this.props.onChange) {
      this.props.onChange(option);
    }
  }

  onBlur = (event, searchTerm, option) => {
    if (this.props.onBlur) {
      this.props.onBlur(event, searchTerm, option);
    }
  }

  render() {
    const {
      name,
      airline,
      inputTitle,
      isMobile,
      styleProps,
      handleInput,
      secondaryAction,
    } = this.props;

    const formatQuery = (input) => {
      if (handleInput) {
        handleInput(input);
      }
      return ({ query: input });
    };
    const formatItem = item => `(${item._id}) ${item._source.shortName}`;
    const airlineCode = airline &&
                        airline.value &&
                        airline.value._source &&
                        airline.value._source.fs;

    return (
      <div>
        <AsyncElasticsearchSelect
          name={name || 'airline'}
          input={airlineCode}
          selectedItem={airline}
          initializerSource='airlineLookup'
          inputTitle={inputTitle || 'Airline'}
          isMobile={isMobile}
          placeholder='Example: AA or American Airlines'
          onChange={this.onChange}
          queryFormatter={formatQuery}
          itemFormatter={formatItem}
          searchSource='airline'
          selectOnBlur={this.props.selectOnBlur}
          shouldFocus={this.props.shouldFocus}
          styleProps={styleProps}
          secondaryAction={secondaryAction}
          onDelete={this.onDelete}
          onBlur={this.onBlur}
        />
      </div>
    );
  }
}
/* eslint-enable no-underscore-dangle */
