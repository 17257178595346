import moment from 'moment';

export const flightURL = (flight) => {
  const { flightId, schedule, resultHeader } = flight;
  const { carrier, flightNumber } = resultHeader;
  const { estimatedActualDepartureUTC } = schedule;
  const date = moment(estimatedActualDepartureUTC);
  const year = date.year();
  const month = date.month() + 1;
  const day = date.date();
  return `${carrier.fs}/${flightNumber}?year=${year}&month=${month}&date=${day}&flightId=${flightId}`;
};
