export const AirportSearch = {
  bottom: {
    id: 'div-gpt-ad-1500402233688-0',
    sizes: [300, 250],
    adSlot: '/1010197/BS_AirportConditionsSearch_top_300x250',
  },
};
export const AirportCurrentConditions = {
  mapTop: {
    id: 'div-gpt-ad-1470845383089-0',
    sizes: [300, 250],
    adSlot: '/1010197/BS_AirportConditions_top_300x250',
  },
  mapBottom: {
    id: 'div-gpt-ad-1470845383089-1',
    sizes: [300, 250],
    adSlot: '/1010197/BS_AirportConditions_second_300x250',
  },
};
