import React from 'react';
import componentBase from '../../../../../../src/lib/component-base';

@componentBase('MarkerLegend')
export default class MarkerLegend extends React.Component {
  constructor(props) {
    super(props);

    this.styleProps = {
      styleLegendImg: {
        display: 'inline',
        height: '15px',
        width: '15px',
        paddingRight: '10px',
        paddingLeft: '10px',
        marginBottom: '-3px',
      },
    };
  }
  renderLegendImages() {
    const images = [[require('../../../../../../static/images/delay-map/Red-Excessive.png'), 'Excessive'], [require('../../../../../../static/images/delay-map/RedYellow-Significant.png'), 'Significant'],
      [require('../../../../../../static/images/delay-map/Yellow-Moderate.png'), 'Moderate'], [require('../../../../../../static/images/delay-map/YellowGreen-Low.png'), 'Low'],
      [require('../../../../../../static/images/delay-map/Green-VeryLow.png'), 'Very Low'], [require('../../../../../../static/images/delay-map/White-Unknown.png'), 'Unavailable']];
    return images.map(i => <p key={`legend-p-${i[1]}`}><img key={`legend-${i[1]}`}src={i[0]} style={this.styleProps.styleLegendImg} alt={`airport delay status is ${i[1]}`} />{i[1]}</p>);
  }
  render() {
    return (
      <div className='legendContainer'>
        <div className='legend'>
          <div className='legendContent'>
            {this.renderLegendImages()}
          </div>
        </div>
      </div>
    );
  }
}
