import React from 'react';
import PropTypes from 'prop-types';
import crossfilter from 'crossfilter';
import componentBase from '../../lib/component-base';

@componentBase('Crossfilter')
class Crossfilter extends React.Component {
  static propTypes = {
    list: PropTypes.array.isRequired,
    children: PropTypes.object,
  };

  state = {};

  componentWillMount() {
    // Instantitating the crossfilter on the server isn't feasible as a crossfilter instance
    // includes unstringifiable values. Instead, we wait until this component mounts before
    // instantiating and then passing as a prop to children.
    this.list = this.props.list.map(o => Object.assign({}, o));
    this.crossfilter = crossfilter(this.list);
    this.forceUpdate();
  }

  componentWillUnmount() {
    this.crossfilter = null;
    this.list = null;
  }

  render() {
    this.list = this.list || [];
    const childrenWithProps = React.Children.map(this.props.children, child => React.cloneElement(child, { crossfilter: this.crossfilter, clonedList: this.list }));

    return (
      <div>
        {childrenWithProps}
      </div>
    );
  }
}

export default Crossfilter;
