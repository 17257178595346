import { tileLayer, DomEvent, Control, DomUtil } from 'leaflet';
import MapControlBase from './MapControlBase';
import componentBase from '../../lib/component-base';

const activeBtnBkg = `url(${require('../../../static/images/flight-tracker/active-button.png')})`;
const inactiveBtnBkg = `url(${require('../../../static/images/flight-tracker/inactive-button.png')}`;
const labelIconBkg = `url(${require('../../../static/images/flight-tracker/label-icon.png')}`;

@componentBase('LabelsTileLayer')
export default class LabelsTileLayer extends MapControlBase {
  constructor(props, context) {
    super(props);

    this.map = context.map;
  }

  componentDidMount() {
    const tileRoute = window.devicePixelRatio > 1 ? 'https://tiles-s3.flightstats.com/hybrid/{z}/{x}/{y}@2x.png' : 'https://tiles-s3.flightstats.com/hybrid/{z}/{x}/{y}.png';
    const labelsLayer = tileLayer(tileRoute, {
      subdomains: '1234',
      zIndex: 7,
      minZoom: 2,
      maxZoom: 6,
    });

    this.map.addLayer(labelsLayer);

    const Ctrl = Control.extend({
      options: {
        position: 'topleft',
      },

      reset: this.reset(),

      onAdd: (map) => {
        let initialLoad = true;

        const container = DomUtil.create('div', 'leaflet-bar leaflet-control');
        container.style.display = (this.props.hide ? 'none' : null);

        const preloadActive = DomUtil.create('div', container);
        preloadActive.style.backgroundImage = activeBtnBkg;

        const preloadInactive = DomUtil.create('div', container);
        preloadInactive.style.backgroundImage = inactiveBtnBkg;

        const preloadLabelIcon = DomUtil.create('div', container);
        preloadLabelIcon.style.backgroundImage = labelIconBkg;

        const parent = DomUtil.create('div', 'leaflet-bar-part', container);
        parent.style.width = '75px';
        parent.style.height = '75px';
        parent.style.backgroundImage = preloadActive.style.backgroundImage;
        parent.style.backgroundSize = '75px 75px';
        parent.style.backgroundRepeat = 'no-repeat';
        parent.style.cursor = 'pointer';
        parent.style.boxShadow = '2px 2px 10px rgba(0, 0, 0, 0.5)';

        const innerChild = DomUtil.create('div', 'leaflet-bar-part', parent);
        innerChild.style.width = '75px';
        innerChild.style.height = '75px';
        innerChild.style.backgroundImage = preloadLabelIcon.style.backgroundImage;
        innerChild.style.backgroundSize = '75px 75px';
        innerChild.style.backgroundRepeat = 'no-repeat';

        const innerChild1 = DomUtil.create('div', 'leaflet-bar-part', innerChild);
        innerChild1.style.width = '17px';
        innerChild1.style.height = '75px';
        innerChild1.style.float = 'left';

        const innerChild2 = DomUtil.create('div', 'leaflet-bar-part', innerChild);
        innerChild2.style.width = '58px';
        innerChild2.style.height = '75px';
        innerChild2.style.float = 'left';

        const innerChild2Text = DomUtil.create('div', 'leaflet-bar-part', innerChild2);
        innerChild2Text.style.width = 'inherit';
        innerChild2Text.style.position = 'absolute';
        //  innerChild2Text.style.top = '20%';
        innerChild2Text.style.top = '40%';
        innerChild2Text.style.textAlign = 'center';

        const innerChild2TextLabel = DomUtil.create('div', 'leaflet-bar-part', innerChild2Text);
        innerChild2TextLabel.style.fontSize = '12px';
        innerChild2TextLabel.innerHTML = 'Labels';

        const innerChild2TextSubLabel = DomUtil.create('div', 'leaflet-bar-part', innerChild2Text);
        innerChild2TextSubLabel.style.padding = '0 2px';
        innerChild2TextSubLabel.style.fontSize = '8px';
        innerChild2TextSubLabel.innerHTML = 'Countries, Oceans, Airports, etc.';
        innerChild2TextSubLabel.style.display = 'none'; // off for now...

        DomEvent
          .on(container, 'dblclick', DomEvent.stopPropagation)
          .on(container, 'dblclick', DomEvent.preventDefault)
          .on(container, 'click', (e) => {
            DomEvent.stopPropagation(e);
            DomEvent.preventDefault(e);

            if (map.hasLayer(labelsLayer)) {
              map.removeLayer(labelsLayer);
              parent.style.backgroundImage = preloadInactive.style.backgroundImage;
              this.context.reportButtonPress('TrackerLabels', 0);
            } else {
              map.addLayer(labelsLayer);
              parent.style.backgroundImage = preloadActive.style.backgroundImage;
              this.context.reportButtonPress('TrackerLabels', 1);
            }
          });

        map.on('zoomend', () => {
          if (map.getZoom() > 7) {
            container.style.display = 'none';

            if (!map.hasLayer(labelsLayer)) {
              map.addLayer(labelsLayer);
            }
          } else if (container.style.display === 'none' && initialLoad && this.props.hide) {
            initialLoad = false;
          } else if (container.style.display === 'none') {
            if (parent.style.backgroundImage === preloadInactive.style.backgroundImage) {
              map.removeLayer(labelsLayer);
            } else {
              parent.style.backgroundImage = preloadActive.style.backgroundImage;
              container.style.display = null;
            }
          }
        });

        return container;
      },
    });

    this.control = new Ctrl();
    this.control.reset();
    this.map.addControl(this.control);
  }
}
