// @flow
import { and, any, complement, compose, equals, pathOr, propSatisfies, prop } from 'ramda';
import type { User } from '../../../common-types';

type ParsedComponent = {
  parsedComponentName: string,
}

type LoggingOut = {
  loggingOut?: boolean,
}

type WithUser = {
  user?: User,
}

type AdState = {
  parsedComponentName: string,
  loggingOut?: boolean,
  user?: User,
}

type ReporterProps = {
  location: {
    pathname: ?string,
  },
};

type PathForBool = ((path: string) => boolean);
type LocationForBool = ((props: ReporterProps) => boolean);


const flightTrackerRepresentations: Array<string> = [
  'FlightTrackerMap',
  'FlightTrackerOtherStops',
  'FlightTracker',
];
const representativeAdNames: { [string]: string } = {
  FourOhFour: 'Homepage',
  FlightTrackerMap: 'FlightTracker',
  FlightTrackerOtherStops: 'FlightTracker',
};

const isFlightTracker = (val: string): boolean =>
  flightTrackerRepresentations.includes(val);
const isTrackerRoute: ((ParsedComponent) => boolean) = propSatisfies(
  isFlightTracker,
  'parsedComponentName',
);
const anyTrackerRoute: ((Array<ParsedComponent>) => boolean) = any(isTrackerRoute);
const getParsed: ((ParsedComponent) => string) = prop('parsedComponentName');
const notEquals: ((a: string, b: string) => boolean) = complement(equals);
const notSameRoute = (a: ParsedComponent, b: ParsedComponent): boolean =>
  notEquals(getParsed(a), getParsed(b));

/*
  header/footer ads need to know if we are moving between
  flightTrackerRepresentations routes
  return TRUE causes ad unit refresh
*/
export const anyFlightTrackerRoute = (
  p: ParsedComponent,
  np: ParsedComponent,
): boolean =>
  anyTrackerRoute([p, np]) &&
  notSameRoute(p, np);

export const getComponentAdName = (
  componentName: string,
  path: ?string,
): string => {
  if (path && path.includes('/map')) {
    return 'FlightTrackerMap';
  }

  return representativeAdNames[componentName] || componentName;
};

const isLoggingOut: ((LoggingOut) => boolean) = prop('loggingOut');
const notLoggingOut: ((LoggingOut) => boolean) = complement(isLoggingOut);
const nextAdComponentRequiresLogin: ((string) => boolean) = equals('DataExports');
const getSubscriptionActives: ((WithUser) => boolean) = pathOr(
  false, ['subscriptionActive', 'user'],
);

// return TRUE sets flag to NOT load ads
export const shouldAdStateChange = (p: AdState, np: AdState): boolean => {
  const parsedComponentName = getParsed(np);
  const adComponentName = getComponentAdName(parsedComponentName);
  const inLoggingOutState = and(isLoggingOut(p), notLoggingOut(np));
  const currentActive = getSubscriptionActives(p);
  const nextActive = getSubscriptionActives(np);
  const allActive = and(currentActive, nextActive);
  const dontMountAdOnRedirect = and(
    nextAdComponentRequiresLogin(adComponentName),
    !currentActive,
  );
  return [inLoggingOutState, dontMountAdOnRedirect, allActive]
    .reduce((a, b) => !!b || a);
};

export const getPathname = pathOr('', ['location', 'pathname']);


const singleFlightTrackerPath: PathForBool = path =>
  !!path &&
  path.includes('/flight-tracker') &&
  !path.includes('/search');

const notFlightTrackerPath: PathForBool = path =>
  !!path && !path.includes('/flight-tracker');

const isPagePerSessionIncrease: LocationForBool = compose(
  notFlightTrackerPath,
  getPathname,
);

const isFlightTrackerPath: LocationForBool = compose(
  singleFlightTrackerPath,
  getPathname,
);

export const shouldReportNavigation = (
  props: ReporterProps,
  nextProps: ReporterProps,
): boolean => isFlightTrackerPath(props) && isPagePerSessionIncrease(nextProps);
