import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ToastList from '../../components/Toast/ToastList';
import componentBase from '../../lib/component-base';

@connect(state => ({
  toasts: state.toast.toasts,
}))
@componentBase('ToastContainer')
export default class ToastContainer extends React.Component {
  static propTypes = {
    toasts: PropTypes.object,
  };

  render() {
    return <ToastList toasts={this.props.toasts} />;
  }
}
