import { addMetaCanonical } from '../../../../shared/lib/meta-tags';

export const meta = (props, flight) => {
  if (!props) return addMetaCanonical({ title: 'Historical Flight Status' }, props.match.url);

  const { match: { params } } = props;
  let carrier;
  let { flightNumber } = params;

  if (__SERVER__ && props.match) {
    if (params.carrier) {
      carrier = params.carrier ?
        global.referenceDataCache.airlineCache.getByFsCode(params.carrier) :
        null;
      if (carrier) {
        carrier.fs = params.carrier;
      } else {
        carrier = { fs: params.carrier, name: '' };
      }
    }
  } else if (flight) {
    carrier = flight.carrier;
    flightNumber = flight.carrier.flightNumber;
  } else {
    return addMetaCanonical({ title: 'Historical Flight Status' }, props.match.url);
  }


  const flightString = `(${carrier.fs}) ${carrier.name} ${flightNumber}`;
  const title = `${flightString} Historical Flight Status`;
  const description = `Historical flight status for flight ${flightString}`;
  const keywords = `${carrier.fs} ${flightNumber} historical flight status, ${carrier.name} ${flightNumber} historical flight status`;

  return addMetaCanonical({
    title,
    meta: [
      { name: 'description', content: description },
      { name: 'keywords', content: keywords },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
    ],
  }, props.match.url);
};
