export const AdvancedSearch = {
  byFlight: {
    id: 'div-gpt-ad-1500408749417-0',
    sizes: [300, 250],
    adSlot: '/1010197/BS_FlightTrackerAdvancedSearch_top_300x250',
  },
  byAirport: {
    id: 'div-gpt-ad-1516835313105-0',
    sizes: [300, 250],
    adSlot: '/1010197/BS_FlightTrackerAdvancedSearch_bottom_300x250',
  },
};

export const ExtendedDetails = {
  left: {
    id: 'div-gpt-ad-1501016578088-1',
    sizes: [300, 250],
    adSlot: '/1010197/BS_FlightTrackerExtendedDetails_top_300x250',
  },
  right: {
    id: 'div-gpt-ad-1501016578088-0',
    sizes: [300, 250],
    adSlot: '/1010197/BS_FlightTrackerExtendedDetails_second_300x250',
  },
};

export const UnifiedSearch = {
  top: {
    id: 'div-gpt-ad-1500408139456-0',
    sizes: [300, 250],
    adSlot: '/1010197/BS_FlightTrackerSearch_top_300x250',
  },
  second: {
    id: 'div-gpt-ad-1519243114086-0',
    sizes: [300, 250],
    adSlot: '/1010197/BS_FlightTrackerSearch_Second_300x250',
  },
  third: {
    id: 'div-gpt-ad-1519243114086-1',
    sizes: [300, 250],
    adSlot: '/1010197/BS_FlightTrackerSearch_Third_300x250',
  },
};

export const FullScreenMap = {
  id: 'div-gpt-ad-1500413150337-0',
  sizes: [728, 90],
  adSlot: '/1010197/BS_FlightTrackerFullMap_top_728x90',
};
