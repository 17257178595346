import React, { Component } from 'react';
import PropTypes from 'prop-types';
import componentBase from '../../../../../src/lib/component-base';
import { ChartIcon, ChartItem } from './ChartCell';
import { MobileSubTitle, MobileRow } from './MobileChart';
import { highlightBackgrounds } from '../../../lib/constants';
import { getChartItemChildren } from '../../../lib/helpers';
import IconHeader from '../../../../../shared/components/IconHeader/IconHeader';

@componentBase('SubscriptionFeatureChart')
export default class SubscriptionFeatureChart extends Component {
  static propTypes = {
    activeSubscription: PropTypes.bool,
    chartHeaderText: PropTypes.string,
    chartIcon: PropTypes.string,
    chartTitleHeader: PropTypes.string,
    featureTitles: PropTypes.arrayOf(PropTypes.object),
    freeIcon: PropTypes.string,
    highlightedRow: PropTypes.string,
    mobileSubscribeButton: PropTypes.object,
    professionalSubscriptionIcon: PropTypes.string,
    standardSubscriptionIcon: PropTypes.string,
    subscriptionLevel: PropTypes.number,
  };

  getFeatureHighlights = (feature) => {
    const {
      freeFeature,
      proFeature,
      standardFeature,
      title,
    } = feature;
    const { highlightedRow } = this.props;
    return {
      highlightRow: highlightedRow && title.includes(highlightedRow) ? 'highlight-row' : '',
      highlightFree: freeFeature ? 'highlight-background' : '',
      highlightStandard: standardFeature ? 'highlight-background' : '',
      highlightPro: proFeature ? 'highlight-background' : '',
    };
  }

  mapFeatureTitles = tier => this.props.featureTitles
    .filter(featureTitle => featureTitle[tier])
    .map((feature, index) => {
      const { icon, freeText, title } = feature;
      const key = `${tier}${index}`;

      return (
        <MobileRow
          icon={icon}
          key={key}
          optionalClassname={highlightBackgrounds[tier]}
          subtext={freeText}
          title={title}
        />
      );
    });

  renderFeatureTitleElements = () => {
    const { featureTitles } = this.props;
    if (featureTitles) {
      return featureTitles.map((feature, index) => {
        const {
          icon,
          title,
        } = feature;
        const {
          highlightFree,
          highlightPro,
          highlightRow,
          highlightStandard,
        } = this.getFeatureHighlights(feature);

        const children = getChartItemChildren(feature);
        const key = `title_${index}`;

        const Feature = (
          <div className={`hidden-xs feature-row-container ${highlightRow} row`} key={key} >
            <div className='col-sm-3 col-xs-12'>
              <ChartIcon icon={icon} title={title} />
            </div>
            <ChartItem
              containerClassNames={`col-xs-3 ${highlightFree}`}
              children={children[0]}
              key={`${title}-free`}
            />
            <ChartItem
              containerClassNames={`col-xs-3 ${highlightStandard}`}
              children={children[1]}
              key={`${title}-standard`}
            />
            <ChartItem
              containerClassNames={`col-xs-3 ${highlightPro}`}
              children={children[2]}
              key={`${title}-premium`}
            />
          </div>
        );
        return Feature;
      });
    }
    return null;
  }

  renderFeatureTitleElementsMobile = () => {
    const {
      activeSubscription,
      featureTitles,
      freeIcon,
      mobileSubscribeButton,
      professionalSubscriptionIcon,
      standardSubscriptionIcon,
      subscriptionLevel,
    } = this.props;

    if (featureTitles) {
      const freeFeatures = this.mapFeatureTitles('freeFeature');
      const standardFeatures = this.mapFeatureTitles('standardFeature');
      const proFeatures = this.mapFeatureTitles('proFeature');

      const standardButtonStyle = { marginBottom: '50px' };
      const proButtonStyle = {};

      if (activeSubscription) {
        standardButtonStyle.display = 'none';
      }

      if (activeSubscription && subscriptionLevel >= 3) {
        proButtonStyle.display = 'none';
      }

      return (
        <div className='visible-xs'>
          <div className='free-section'>
            <div className='highlightFreeFeatureHeader mobileHeader'>
              <ChartIcon
                icon={freeIcon}
                title='Free Account'
                isChartHeader
                isMobile
              />
            </div>
            {freeFeatures}
          </div>
          <div className='standard-section'>
            <div className='highlightStandardFeatureHeader mobileHeader'>
              <ChartIcon
                icon={standardSubscriptionIcon}
                title='Standard Subscription'
                subtitle='$2.99 per month'
                isChartHeader
                isMobile
              />
              <MobileSubTitle
                icon={freeIcon}
                text='Free Account included'
              />
            </div>
            {standardFeatures}
          </div>
          <div className='mobileSubscribe' style={standardButtonStyle}>
            {mobileSubscribeButton}
          </div>
          <div className='pro-section'>
            <div className='highlightProFeatureHeader mobileHeader'>
              <ChartIcon
                icon={professionalSubscriptionIcon}
                title='Professional Subscription'
                subtitle='$24.99 per month'
                isChartHeader
                isMobile
              />
              <MobileSubTitle
                icon={standardSubscriptionIcon}
                text='Standard Subscription included'
              />
            </div>
            {proFeatures}
          </div>
          <div className='mobileSubscribe' style={proButtonStyle}>
            {mobileSubscribeButton}
          </div>
        </div>
      );
    }
    return null;
  }

  render() {
    const {
      chartHeaderText,
      chartIcon,
      chartTitleHeader,
      freeIcon,
      professionalSubscriptionIcon,
      standardSubscriptionIcon,
    } = this.props;

    return (
      <div>
        <section className='chart-title-header subscription-feature-info'>
          <article>
            <aside>
              <IconHeader
                icon={chartIcon}
                title={chartTitleHeader}
              />
            </aside>
            <div className='marketing-feature-text'>
              <p>
                {chartHeaderText}
              </p>
            </div>
          </article>
        </section>
        <div className='subscription-feature-chart'>
          <div className='row chart-title-header-row hidden-xs'>
            <div
              className='subscription-feature-chart-column-header col-xs-3 col-sm-offset-3 highlightFreeFeatureHeader'
            >
              <ChartIcon
                icon={freeIcon}
                title='Free Account'
                isChartHeader
              />
            </div>
            <div
              className='subscription-feature-chart-column-header col-xs-3 highlightStandardFeatureHeader'
            >
              <ChartIcon icon={standardSubscriptionIcon} title='Standard Subscription' subtitle='$2.99 per month' isChartHeader />
            </div>
            <div
              className='subscription-feature-chart-column-header col-xs-3 highlightProFeatureHeader'
            >
              <ChartIcon
                icon={professionalSubscriptionIcon}
                title='Professional Subscription'
                subtitle='$24.99 per month'
                isChartHeader
              />
            </div>
          </div>
          <div className='feature-chart-rows'>
            {this.renderFeatureTitleElements()}
            {this.renderFeatureTitleElementsMobile()}
          </div>
        </div>
      </div>
    );
  }
}
