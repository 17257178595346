import { parse } from 'qs';
import moment from 'moment';
import constants from './_constants';

const alphaNumRegex = /(^[a-zA-Z]*)(\d*)([a-zA-Z]*?)$/;
const has = Object.prototype.hasOwnProperty;

const compareSortKey = (a, b) => {
  if (a.sortKey < b.sortKey) {
    return -1;
  } else if (a.sortKey > b.sortKey) {
    return 1;
  }
  return 0;
};


const sortByKey = (originalKeys, uniqueDimensionItems) => {
  const sortKeyObjects = [];
  const sortedKeys = [];

  for (let i = 0; i < originalKeys.length; i++) {
    const key = originalKeys[i];
    const item = uniqueDimensionItems[key];
    sortKeyObjects.push({ key, sortKey: item.sortKey });
  }

  const tempKeys = sortKeyObjects.sort(compareSortKey);

  for (let i = 0; i < tempKeys.length; i++) {
    const item = tempKeys[i];
    sortedKeys.push(item.key);
  }

  return sortedKeys;
};

const createDimensionMenuItems = (uniqueDimensionItems, primaryText, useSortKey) => {
  const menuItems = {};
  if (primaryText !== 'MobileSort') {
    menuItems.reset = {
      key: 'reset',
      value: 'reset',
      label: 'Reset Filter',
      checked: false,
      disabled: false,
      reset: true,
      sortKey: '0',
    };
  }

  const originalKeys = Object.keys(uniqueDimensionItems);
  let keys = [];

  if (useSortKey) {
    // Sort key is regex for gate, terminal, baggage, and equipment
    keys = sortByKey(originalKeys, uniqueDimensionItems);
  } else {
    // Everything else
    keys = originalKeys.sort();
  }

  if (keys && keys.length > 0) {
    keys.forEach((key) => {
      if (key !== '--' && key !== null) {
        const item = uniqueDimensionItems[key];
        menuItems[key] = {
          key,
          checked: item.checked,
          value: key,
          label: item.label,
          disabled: item.disabled,
          sortKey: item.sortKey,
        };
      }
    });
  }

  return menuItems;
};

const alphaNumRegexRegulator = (originalString) => {
  const regexMatch = alphaNumRegex.exec(originalString);
  if (regexMatch != null && regexMatch.length >= 3) {
    const newObj = {
      character: regexMatch[1],
      num: regexMatch[2],
    };
    let combinedParsedString = newObj.character;
    let newNum = newObj.num;

    if (newNum.length > 0) {
      while (newNum.length < 5) {
        newNum = `0${newNum}`;
      }
      combinedParsedString += newNum;
    }

    if (regexMatch.length === 4) {
      combinedParsedString += regexMatch[3];
    }
    return combinedParsedString;
  }
  return originalString;
};

const unAlphaNumRegexRegulator = (originalString) => {
  const regexMatch = alphaNumRegex.exec(originalString);
  if (originalString === null) {
    return '--';
  } else if (regexMatch != null && regexMatch.length >= 3) {
    const newObj = {
      character: regexMatch[1],
      num: regexMatch[2],
    };
    let combinedParsedString = newObj.character;

    if (newObj.num > 0) {
      combinedParsedString += parseInt(newObj.num, 10);
    }

    if (regexMatch.length === 4) {
      combinedParsedString += regexMatch[3];
    }

    return combinedParsedString;
  }
  return originalString;
};

const getQueryParams = (props) => {
  const queryParams = props.location && props.location.search;
  const params = parse(queryParams, { ignoreQueryPrefix: true });
  const { departureArrival } = props.match && props.match.params;
  const decode = search => decodeURIComponent(search).split(',');
  const showCodeshare = queryParams.codeshare;
  const {
    airlines,
    airports,
    bag,
    equip,
    gate,
    delays,
    status,
    terminal,
    timespan,
  } = params;
  return {
    airline: airlines ? decode(airlines) : [],
    destOrigin: airports ? decode(airports) : [],
    status: status ? decode(status) : [],
    terminal: terminal ? decode(terminal) : [],
    timespan: timespan ? decode(timespan) : timespan,
    gate: gate ? decode(gate) : [],
    equipment: equip ? decode(equip) : [],
    baggage: bag && departureArrival === 'arrivals' ? decode(bag) : [],
    delay: delays ? decode(delays) : [],
    codeshare: showCodeshare || 'hide',
  };
};

const filterSetForItem = (filter, item) => (filter && filter.indexOf(item) !== -1);

const floorTimeToBinSize = (time) => {
  const momTime = moment(time).local();
  const mod = momTime.format('m') % constants.BIN_SIZE_MINUTES;
  return momTime.subtract(mod, 'm').startOf('m').toDate();
};

const timespanValueLabel = (startTime, endTime, ampmFormat = false) => {
  const labelFormat = (ampmFormat ? 'h:mmA' : 'HH:mm');
  const newLabel = `${startTime.format(labelFormat)} - ${endTime.format(labelFormat)}`;
  const allDayLabelTimes = (ampmFormat ? '12:00AM - 12:00AM' : '00:00 - 00:00');
  return (newLabel === allDayLabelTimes) ? constants.ALL_DAY_LABEL : newLabel;
};

const menuItemsToArray = (menuItems, sortBySortKey, showAllValues) => {
  let keys = Object.keys(menuItems);

  if (sortBySortKey) {
    keys = sortByKey(keys, menuItems);
  }

  const standardMenuItems = keys.filter((key) => {
    if (key !== 'reset' && (showAllValues || !menuItems[key].checked)) {
      return true;
    }
    return false;
  }).map(key => key && menuItems[key]);
  const firstPosition = keys.find(k => k === 'reset');
  if (firstPosition) {
    standardMenuItems.unshift(menuItems.reset);
  }
  const remove = keys.find(k => k === 'Custom');
  if (remove) {
    standardMenuItems.splice(keys.indexOf('Custom'), 1);
  }

  return standardMenuItems;
};

const groupBy = (array, f) => {
  const groups = {};

  array.forEach((o) => {
    const group = JSON.stringify(f(o));
    groups[group] = groups[group] || [];
    groups[group].push(o);
  });

  return Object.keys(groups).map(group => groups[group]);
};

const resetAllMenuItems = (items) => {
  const result = {
    ...items,
  };

  for (const a in result) {
    if (has.call(result, a)) {
      result[a].checked = false;
    }
  }

  return result;
};

export {
  resetAllMenuItems,
  groupBy,
  menuItemsToArray,
  sortByKey,
  compareSortKey,
  timespanValueLabel,
  floorTimeToBinSize,
  createDimensionMenuItems,
  alphaNumRegexRegulator,
  filterSetForItem,
  getQueryParams,
  unAlphaNumRegexRegulator,
};
