import React from 'react';
import BasicCalloutWrapper from '../../../shared/components/BasicCalloutWrapper/BasicCalloutWrapper';

const contactRepLink = 'https://www.cirium.com/contact-us/?cmpid=ILC%7CCIR%7CCIR-flightstats-contact';

const NeedABusinessAccount = () => (
  <BasicCalloutWrapper
    openNewTab
    bulletText={['Minimum 10 users']}
    buttonLabel='Contact a representative'
    onClickGALabel='Contact a Rep'
    onClickURL={contactRepLink}
    subText='Talk to our representatives to get your account today!'
    titleText='Need a Business Account?'
  />
);

export default NeedABusinessAccount;
