import React, { Component } from 'react';
import PropTypes from 'prop-types';
import btoa from 'btoa';
import { scroller, Element } from 'react-scroll';

// Components
import IconHeader from '../../../../../../../shared/components/IconHeader/IconHeader';
import RegistrationTermsAndConditions from '../../shared/RegistrationTermsAndConditions';
import TextInput from '../../../../../../../shared/components/TextInput/TextInput';
import componentBase from '../../../../../../../src/lib/component-base';
import { PasswordValidation } from '../../../../../../../shared/components/PasswordValidationRules/PasswordValidation';
// Assets
import passwordIcon from '../../../../../../../static/images/account-icons/Password-WHITE.svg';
import emailIcon from '../../../../../../../static/images/account-icons/Email-WHITE.svg';
import checkmarkIcon from '../../../../../../../static/images/account-icons/Checkmark-Icon-WHITE.svg';

const textInputLabelStyle = {
  margin: '13px 0 0px',
};
const textInputErrorLabelStyle = {
  top: '5px',
};
const textInputSpanStyle = {
  color: 'white',
};
@componentBase('createAccountBody')
export default class createAccountBody extends Component {
  static propTypes = {
    endSpinner: PropTypes.func,
    invalidateRecaptcha: PropTypes.func,
    planSelected: PropTypes.string,
    recaptcha: PropTypes.object,
    registrationEmail: PropTypes.string,
    registrationError: PropTypes.string,
    registrationSignupEmail: PropTypes.func,
    registrationSignupPassword: PropTypes.func,
    registrationSignupPasswordConfirmation: PropTypes.func,
    registrationSignupTermsAndConditions: PropTypes.func,
    resetRecaptcha: PropTypes.func,
    submitForm: PropTypes.func,
    toast: PropTypes.object,
    userServiceRegistrationError: PropTypes.string,
    setPasswordIsValid: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);

    this.agreeTermsConditions = null;
    this.inputEmail = null;
    this.inputPassword = null;
    this.inputConfirmPassword = null;

    this.state = {
      newPassword: '',
    };
  }

  onEnterKeyPress = (e) => {
    if (e.keyCode === 13) {
      this.props.submitForm(e);
    }
  }

  setCheckboxRef = (e) => {
    this.agreeTermsConditions = e;
  }

  scrollToError = (fieldName) => {
    try {
      scroller.scrollTo(`account-error-${fieldName}`, {
        duration: 500,
        delay: 100,
        smooth: true,
      });
    } catch (e) {
      console.log('error in scrollToError');
    }
  };

  sanitizedEmail = email => email && email.toLowerCase().replace(' ', '');

  handleInputChange = (value, name) => {
    switch (name) {
      case 'email':
        this.props.registrationSignupEmail(this.sanitizedEmail(value));
        break;
      case 'password':
        this.props.registrationSignupPassword(btoa(value));
        break;
      case 'passwordConfirmation':
        this.props.registrationSignupPasswordConfirmation(btoa(value));
        break;
      case 'agreeTermsConditions':
        this.props.registrationSignupTermsAndConditions(value);
        break;
      default:
        break;
    }
  };

  handleErrorMessages = (error = '') => {
    let emailErrorText;
    let passwordErrorText;
    let confirmationErrorText;
    if (error) {
      switch (error) {
        case 'MISSING_EMAIL':
          emailErrorText = 'Please enter an email address';
          break;
        case 'INVALID_EMAIL':
          emailErrorText = 'Email address is invalid';
          break;
        case 'EMAIL_IN_USE':
          emailErrorText = 'Email address already in use';
          break;
        case 'PASSWORD_LENGTH_INVALID':
        case 'PASSWORD_SHORT':
        case 'PASSWORD_LONG':
          passwordErrorText = 'Password must be between 8 and 30 characters';
          break;
        case 'PASSWORD_NO_LOWERCASE':
        case 'PASSWORD_NO_UPPERCASE':
        case 'PASSWORD_NO_NUMBER':
        case 'PASSWORD_NO_SPECIAL_CHAR':
          passwordErrorText = 'Password must satisfy at least 3 of the guidelines*';
          break;
        case 'PASSWORDS_MUST_MATCH':
          passwordErrorText = 'Passwords do not match';
          break;
        case 'MUST_ACCEPT_TERMS_CONDITIONS':
          confirmationErrorText = 'You must accept the Terms and Conditions';
          break;
        default:
          // UNKNOWN_ERROR
          // MISSING_RECAPTCHA, using invisible recaptcha so no I'm not a robot checkbox
          emailErrorText = 'An unexpected error has occurred. Please try again';
          break;
      }
    }

    let scrollToField = null;

    if (emailErrorText) {
      scrollToField = 'email';
    } else if (passwordErrorText) {
      scrollToField = 'password';
    } else if (confirmationErrorText) {
      scrollToField = 'passwordConfirmation';
    }

    if (scrollToField) {
      this.scrollToError(scrollToField);
    }

    return {
      emailErrorText, passwordErrorText, confirmationErrorText,
    };
  }

  render = () => {
    const {
      planSelected,
      registrationError,
      userServiceRegistrationError,
      setPasswordIsValid,
    } = this.props;
    const error = userServiceRegistrationError || registrationError;
    const {
      emailErrorText,
      passwordErrorText,
      confirmationErrorText,
    } = this.handleErrorMessages(error);
    return (
      <div>
        <div className='row' onKeyUp={this.onEnterKeyPress}>
          <div className='col-xs-12 col-sm-5 col-md-4'>
            <IconHeader title='Enter Email' icon={emailIcon} />
            <div className='account-create-email'>
              <Element name='account-error-email' />
              <div className='text-input-wrapper'>
                <TextInput
                  value={this.props.registrationEmail}
                  inputRef={ref => (this.inputEmail = ref)}
                  errorMessage={emailErrorText}
                  inputClassName='account-create-input'
                  label='Email'
                  labelErrorStyle={textInputErrorLabelStyle}
                  labelStyle={textInputLabelStyle}
                  onInputChange={val => this.handleInputChange(val, 'email')}
                  placeholder='Example: name@domain.com'
                  name='email'
                  spanStyle={textInputSpanStyle}
                  type='email'
                />
              </div>
              <p className='account-create-subtitle'>NOTE: The email entered here will be your login email</p>
            </div>
          </div>
          <div className='col-xs-12 col-sm-offset-1 col-sm-5 col-md-offset-2 col-md-4'>
            <IconHeader title='Enter a Password' icon={passwordIcon} />
            <div className='account-create-password'>
              <Element name='account-error-password' />
              <div className='text-input-wrapper'>
                <TextInput
                  inputRef={ref => (this.inputPassword = ref)}
                  errorMessage={passwordErrorText}
                  inputClassName='account-create-input'
                  label='Enter a Password'
                  labelErrorStyle={textInputErrorLabelStyle}
                  labelStyle={textInputLabelStyle}
                  name='password'
                  onInputChange={(val) => {
                    this.handleInputChange(val, 'password');
                    this.setState({ newPassword: val });
                  }}
                  placeholder='Enter Password'
                  spanStyle={textInputSpanStyle}
                  type='password'
                />
              </div>
              <Element name='account-error-passwordConfirmation' />
              <div className='text-input-wrapper'>
                <TextInput
                  inputRef={ref => (this.inputConfirmPassword = ref)}
                  inputClassName='account-create-input'
                  label='Confirm Password'
                  labelErrorStyle={textInputErrorLabelStyle}
                  labelStyle={textInputLabelStyle}
                  name='passwordConfirmation'
                  onInputChange={val => this.handleInputChange(val, 'passwordConfirmation')}
                  placeholder='Confirm Password'
                  spanStyle={textInputSpanStyle}
                  type='password'
                />
              </div>
              <PasswordValidation
                password={this.state.newPassword}
                validationCallback={(isValid) => {
                const { valid } = isValid;
                setPasswordIsValid(valid);
              }}
              />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12 col-sm-6'>
            <IconHeader title='Confirmation' icon={checkmarkIcon} />
            <div className='account-create-secondary-div'>
              <p className={`account-create-input-title ${confirmationErrorText ? 'registration-form-error' : ''}`} style={{ maxWidth: '295px', minHeight: '14px', marginTop: '0' }}>{confirmationErrorText || ''}</p>
              <div>
                <input
                  type='checkbox'
                  name='agreeTermsConditions'
                  onChange={e => this.handleInputChange(e.target.checked, 'agreeTermsConditions', 'checkbox')}
                  ref={this.setCheckboxRef}
                  style={{ margin: '0 3px 10px 0', display: 'inline-block' }}
                />
                <RegistrationTermsAndConditions planSelected={planSelected} />
              </div>
              <div className='form-group captcha' id='captcha-holder'>
                { this.props.recaptcha }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
