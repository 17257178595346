// import { createSelector } from 'reselect';
import { NAME } from './constants';

export const getFlights = state => state[NAME];
export const isLoaded = globalState => (globalState[NAME] && globalState[NAME].loaded);

/*
export const getCounts = createSelector(
  getFlights,
  (flight) => ({
    flights: allTodos.length,
    completed: completedTodos.length,
    active: activeTodos.length
  })
);
*/
