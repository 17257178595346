import React from 'react';
import PropTypes from 'prop-types';
import FSFlatButton from '../../../src/components/lib/FSFlatButton';

const CreditsSummary = (props) => {
  const {
    doNotShowBuyMoreButton,
    buyMoreCredits,
    backgroundColor,
    creditsMonthlyAllowance,
    reserveCredits,
    allowanceText,
    reserveText,
  } = props;

  return (
    <div className='credits-summary row'>
      <div className={`credits-wrapper ${backgroundColor}`}>
        <div className={`credits-container alerts-container col-xs-12 ${doNotShowBuyMoreButton ? 'col-md-6' : 'col-md-4'}`}>
          <div className={`credits ${backgroundColor}`}>
            <span className='title'>{allowanceText}</span>
            <p className='subject'>{creditsMonthlyAllowance}</p>
          </div>
        </div>
        <div className={`reserve-credits-container alerts-container col-xs-12 ${doNotShowBuyMoreButton ? 'col-md-6' : 'col-md-8'}`}>
          <div className={`reserve-credits ${backgroundColor}`}>
            <div className={`${doNotShowBuyMoreButton ? 'no-buy-btn' : ''}`}>
              <span className='title'>{reserveText}</span>
              <p className='subject'>{reserveCredits}</p>
            </div>
            <div>
              {
                doNotShowBuyMoreButton ? null :
                <FSFlatButton
                  label='BUY MORE'
                  primary
                  onClick={buyMoreCredits}
                />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CreditsSummary.propTypes = {
  allowanceText: PropTypes.string,
  doNotShowBuyMoreButton: PropTypes.bool,
  buyMoreCredits: PropTypes.func,
  backgroundColor: PropTypes.string,
  creditsMonthlyAllowance: PropTypes.string,
  reserveCredits: PropTypes.string,
  reserveText: PropTypes.string,
};

export default CreditsSummary;
