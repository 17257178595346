import * as t from './actionTypes';

const initialState = {
  airports: [],
  airlines: [],
  loadAttempts: 0,
  error: '',
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case t.LOAD_REFERENCE_DATA:
      return {
        ...state,
        loadAttempts: state.loadAttempts + 1,
        ...action.data,
      };
    case t.LOAD_FAIL:
      return {
        ...initialState,
        error: action.data.error,
        loadAttempts: state.loadAttempts + 1,
      };
    default:
      return state;
  }
};
