import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GoogleRecaptcha from 'react-google-recaptcha';
import { connect } from 'react-redux';
import { complement, equals, isEmpty, identity } from 'ramda';

// components and helpers
import HighlightedSection from '../shared/HighlightedSection';
import CreateAccountBody from './children/CreateAccountBody';

// Validators
import {
  isValidEmail,
  isValidPassword,
} from '../../../../../../src/helpers/Validators';

// actions
import { accountFormError } from '../../../../redux/actions';

// redux extras
import ToastActions from '../../../../../../src/redux/modules/toast/actions';

// Assets
import checkClipboardIcon from '../../../../../../static/images/account-icons/CheckClipboard-WHITE.svg';

const isNotEmpty = complement(isEmpty);

const validateForm = ({
  registrationEmail,
  registrationPassword,
  registrationPasswordConfirmation,
  registrationTermsAndConditions,
  registrationRecaptcha,
}) => (
  [
    {
      type: 'email',
      validator: isNotEmpty,
      value: registrationEmail,
      registrationValidationError: 'MISSING_EMAIL',
    },
    {
      type: 'valid email',
      validator: isValidEmail,
      value: registrationEmail,
      registrationValidationError: 'INVALID_EMAIL',
    },
    {
      type: 'password',
      validator: isNotEmpty,
      value: atob(registrationPassword),
      registrationValidationError: 'PASSWORD_LENGTH_INVALID',
    },
    {
      type: 'valid password',
      validator: isValidPassword,
      value: atob(registrationPassword),
      registrationValidationError: 'PASSWORD_LENGTH_INVALID',
    },
    {
      type: 'password match',
      validator: equals.bind(this, true),
      value: equals(registrationPassword, registrationPasswordConfirmation),
      registrationValidationError: 'PASSWORDS_MUST_MATCH',
    },
    {
      type: 'terms and conditions',
      validator: identity,
      value: registrationTermsAndConditions,
      registrationValidationError: 'MUST_ACCEPT_TERMS_CONDITIONS',
    },
    {
      type: 'recaptcha',
      validator: isNotEmpty,
      value: registrationRecaptcha,
      registrationValidationError: 'MISSING_RECAPTCHA',
    },
  ].reduce((acc, next) => {
    if (!acc.passed) {
      return acc;
    }
    const { value, validator } = next;
    if (validator(value)) {
      return acc;
    }
    return {
      ...next,
      passed: false,
    };
  }, {
    passed: true,
  })
);

@connect(state => ({
  registrationEmail: state.Account.registrationEmail,
  registrationPassword: state.Account.registrationPassword,
  registrationPasswordConfirmation: state.Account.registrationPasswordConfirmation,
  registrationRecaptcha: state.Account.registrationRecaptcha,
  registrationTermsAndConditions: state.Account.registrationTermsAndConditions,
}))
export default class CreateAccountContainer extends Component {
  static propTypes = {
    accountPresent: PropTypes.string,
    billingInformationText: PropTypes.string,
    dispatch: PropTypes.func,
    endSpinner: PropTypes.func,
    handleAlreadyHaveAccountClick: PropTypes.func,
    invalidateRecaptcha: PropTypes.func,
    isFree: PropTypes.func,
    loggedIn: PropTypes.bool,
    planSelected: PropTypes.string,
    registrationEmail: PropTypes.string,
    registrationError: PropTypes.string,
    registrationPassword: PropTypes.string,
    registrationPasswordConfirmation: PropTypes.string,
    registrationTermsAndConditions: PropTypes.bool,
    registrationRecaptcha: PropTypes.string,
    registrationSignupRecaptcha: PropTypes.func,
    registrationSignupSubmit: PropTypes.func,
    setRegistrationStage: PropTypes.func,
    siteKey: PropTypes.string,
    startSpinner: PropTypes.func,
    userServiceRegistrationError: PropTypes.string,
  };
  constructor(props) {
    super(props);
    this.recaptchaRef = null;

    this.state = {
      passwordIsValid: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    const signUpError = ['registrationError', 'userServiceRegistrationError']
      .some(key => this.trueKeyAndFalseKey(nextProps, this.props, key));
    if (signUpError) {
      this.resetRecaptcha();
      this.props.invalidateRecaptcha();
      this.props.endSpinner();
      const { error } = ToastActions;
      this.props.dispatch(error('Please try again.', 'Error', false, 3));
      // this.context.toast.error('Please try again.', 'Error', false, 3);
    }
    if (this.trueKeyAndFalseKey(nextProps, this.props, 'registrationRecaptcha')) {
      this.submitForm(null, nextProps.registrationRecaptcha);
    }
  }


  onCaptchaVerify = (value) => {
    // Called when recaptcha checked
    // Value is key from recaptcha
    if (value) this.props.registrationSignupRecaptcha(value);
  };

  trueKeyAndFalseKey = (a, b, key) => (!!a[key] && !b[key]);

  submitForm = (e, registrationRecaptcha) => {
    e && e.preventDefault();

    const {
      registrationEmail,
      registrationPassword,
      registrationPasswordConfirmation,
      registrationTermsAndConditions,
    } = this.props;

    const validation = validateForm({
      registrationEmail,
      registrationPassword,
      registrationPasswordConfirmation,
      registrationTermsAndConditions,
      registrationRecaptcha,
    });

    if (validation.passed) {
      if (registrationRecaptcha) {
        const submitObject = {
          email: this.props.registrationEmail,
          password: this.props.registrationPassword,
          passwordConfirmation: this.props.registrationPasswordConfirmation,
          termsAndConditions: this.props.registrationTermsAndConditions,
          recaptchaKey: registrationRecaptcha,
        };

        if (!this.state.passwordIsValid) {
          // this case gets triggered when the password rules arent passed
          return;
        }

        const {
          billingInformationText,
          registrationSignupSubmit,
          startSpinner,
          setRegistrationStage,
        } = this.props;
        startSpinner();
        setRegistrationStage(billingInformationText);
        registrationSignupSubmit(submitObject);
      } else {
        this.recaptchaRef.execute();
      }
    } else {
      // validation no good
      this.props.dispatch(accountFormError(validation.registrationValidationError));
    }
  };

  resetRecaptcha = () => {
    this.recaptchaRef.reset();
  };

  recaptchaRefSet = (e) => {
    if (e) {
      this.recaptchaRef = e;
    }
  };

  renderAccountBody = () => (
    <CreateAccountBody
      {...this.props}
      submitForm={this.submitForm}
      resetRecaptcha={this.resetRecaptcha}
      accountFormError={accountFormError}
      setPasswordIsValid={(bool) => {
        this.setState({ passwordIsValid: bool });
      }}
      recaptcha={
        <GoogleRecaptcha
          sitekey={this.props.siteKey}
          size='invisible'
          ref={elem => this.recaptchaRefSet(elem)}
          onChange={this.onCaptchaVerify}
          badge='inline'
        />
      }
    />
  );

  render() {
    const { passwordIsValid } = this.state;

    return (
      <div className='create-account'>
        <HighlightedSection
          body={this.renderAccountBody()}
          buttonTitle={`Continue${!this.props.isFree(this.props.planSelected) ? ' To Payment' : ''}`}
          buttonDisabled={!passwordIsValid}
          footerTitle={this.props.planSelected ? 'You are creating a ' : null}
          handleAlreadyHaveAccountClick={this.props.handleAlreadyHaveAccountClick}
          icon={checkClipboardIcon}
          loggedIn={this.props.loggedIn}
          planSelected={this.props.planSelected}
          submit={this.submitForm}
          subTitle={this.props.accountPresent}
          title='Enter Your Account Information'
        />
      </div>
    );
  }
}
