// @flow
import { connect } from 'react-redux';
import React from 'react';
import styled from 'styled-components';

import { CompactMenuItem } from './compact-menu-item';
import type { Dispatch, User } from '../../../../common-types';
import type { SubmenuItem } from './compact-menu';
import { reportButtonPress } from '../../../../../shared/lib/google-global-site-tag/index';

type CompactMenuHeadingProps = {
  dispatch: Dispatch,
  heading: string,
  submenuItems: Array<SubmenuItem>,
  routeName: string,
  user: User,
};

const CompactMenuHeadingWrapper = styled.div`
  background: white;
`;

const Heading = styled.h3`
  background: #81a2ca;
  margin: 0;
  box-sizing: border-box;
  padding: 5px;
  font-size: 1.17em;
  font-weight: bold;
`;

const SubMenuContainer = styled.div``;

const goToExternal = (href) => (document.location.href = href);

const menuItemClickHandler =
  (menuItem, pageName, menuHeading, dispatch) => (evt) => {
    const { clickHandler, link, heading } = menuItem;

    const handlers = [];

    if (typeof clickHandler === 'function') {
      handlers.push(() => clickHandler({ dispatch }));
    }

    if (link.includes('http')) {
      evt.preventDefault();
      handlers.push(() => goToExternal(link));
    }

    handlers.push(() =>
      reportButtonPress(`${pageName}-Menu: ${menuHeading} > ${heading}`)
    );

    handlers.forEach((fn) => fn());
  };

const DisconnectedCompactMenuHeading = ({
  dispatch,
  heading = '',
  routeName,
  submenuItems = [],
  user,
}: CompactMenuHeadingProps) => (
  <CompactMenuHeadingWrapper>
    <Heading>{heading}</Heading>
    <SubMenuContainer>
      {submenuItems.map((d) => (
        <CompactMenuItem
          subscriptionLevel={user && user.subscriptionLevel}
          key={d.id}
          heading={d.heading}
          link={d.link}
          locked={d.locked}
          next={d.next}
          visibility={d.visibility}
          clickHandler={menuItemClickHandler(d, routeName, heading, dispatch)}
        />
      ))}
    </SubMenuContainer>
  </CompactMenuHeadingWrapper>
);

const mapStateToProps = (state) => ({
  routeName: state.App.routeName,
});

export const CompactMenuHeading = connect(mapStateToProps)(
  DisconnectedCompactMenuHeading
);
