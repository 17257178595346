import moment from 'moment';

export const handleDateValidation = (date) => {
  if (!date) {
    return false;
  }
  if ((date.isValid && date.isValid()) && moment.utc(date).format() !== 'Invalid date') {
    return true;
  }
  return false;
};

export default function sortByDate(data, key, direction) {
  return data.sort((objectOne, objectTwo) => {
    const {
      [key]: dateOne,
    } = objectOne;
    const {
      [key]: dateTwo,
    } = objectTwo;
    const momentOne = moment.utc(dateOne);
    const momentTwo = moment.utc(dateTwo);
    const validDates = [momentOne, momentTwo].every(date =>
      handleDateValidation(date));
    if (!validDates) {
      return 0;
    }
    if (momentOne.isBefore(momentTwo)) {
      return direction === 'ASC' ? -1 : 1;
    }
    if (momentOne.isAfter(momentTwo)) {
      return direction === 'ASC' ? 1 : -1;
    }
    return 0;
  });
}
