import * as t from './actionTypes';

const loading = type => ({
  type,
});

export const loadDeparturesOrArrivalsByCodeDateAndDirection = ({
  airportCode,
  year,
  month,
  day,
  departureArrival,
}) => async (dispatch, getStore, client) => {
  const url = `/airport/${departureArrival.substring(0, 3)}/${airportCode}/${year}/${month}/${day}`;
  dispatch(loading(t.LOAD_DEP_ARR_BY_CODE));

  try {
    const result = await client.get(url);
    return dispatch({
      type: t.LOAD_DEP_ARR_SUCCESS,
      result,
    });
  } catch (err) {
    return dispatch({
      type: t.LOAD_FAIL,
      error: err,
    });
  }
};

export const setDepArrAirportOptions =
  ({ searchAirport, isArrival, isDeparture, triggeredByEnter }) => ({
    type: t.SET_DEPARR_AIRPORT,
    result: {
      searchAirport,
      isArrival,
      isDeparture,
      secondaryAction: triggeredByEnter,
    },
  });

export const setSecondaryAction = bool => ({
  type: t.SET_SECONDARY_ACTION,
  bool,
});

export const setDepArrDateFieldIndex = dateFieldIndex => ({
  type: t.SET_DEPARR_DATE_FIELD_INDEX,
  result: {
    dateFieldIndex,
  },
});
