import * as t from './actionTypes';
import { errorText } from '../../../shared/lib/reducer-util';

const initialState = {
  initialLoad: true,
  loading: false,
  loaded: false,
  loadAttempts: 0,
  airport: null,
  center: null,
  spinnerActive: false,
  region: 'northAmerica',
  zoom: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case t.START_SPINNER:
      return {
        ...state,
        spinnerActive: true,
      };
    case t.END_SPINNER:
      return {
        ...state,
        spinnerActive: false,
      };
    case t.LOAD_DETAILS_BY_CODE:
      return {
        ...state,
        error: null,
      };
    case t.LOAD_DETAILS_SUCCESS:
      return {
        ...state,
        error: null,
        loaded: true,
        loading: false,
        loadAttempts: state.loadAttempts + 1,
        data: action.result,
      };

    case t.LOAD_DELAY_INDICES:
      return {
        ...state,
        error: null,
        // data: null,
        list: null,
        basicInfo: null,
        loading: true,
        loaded: false,
        loadAttempts: state.loadAttempts + 1,
      };
    case t.LOAD_DELAY_INDICES_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        loadAttempts: state.loadAttempts + 1,
        delayIndices: action.delayIndices,
        delayObj: action.delayObj,
        defaultAirports: action.defaultAirports,
      };

    case t.LOAD_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        loadAttempts: state.loadAttempts + 1,
        error: errorText(action.error),
      };
    case t.SET_AIRPORT:
      return {
        ...state,
        airport: action.airport,
      };
    case t.UPDATE_MAP:
      return {
        ...state,
        center: action.center,
        zoom: action.zoom,
      };
    case t.UPDATE_AIRPORT_CODE:
      return {
        ...state,
        airport: action.airport,
        center: null,
        zoom: null,
      };
    case t.INITIAL_LOAD_FINISH:
      return {
        ...state,
        initialLoad: false,
      };
    default:
      return state;
  }
};
