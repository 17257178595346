import React from 'react';
import PropTypes from 'prop-types';
import FontIcon from 'material-ui/FontIcon';
import moment from 'moment-timezone';
import componentBase from '../../../../../../src/lib/component-base';
import UpcomingFlightAlertRow from './children/UpcomingFlightAlertRow';
import { dateFormatString } from '../../../../../../src/lib/date-time-format';
import { istanbulAirportCodeCheck } from '../../../../../../shared/lib/airport-code-helpers';

@componentBase('UpcomingFlightAlerts')
export default class UpcomingFlightAlerts extends React.Component {
  static propTypes = {
    sortPropsBySelectedColumn: PropTypes.func,
    sortOrder: PropTypes.bool,
    alerts: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    currentSortColumn: PropTypes.string,
    errorMessages: PropTypes.array,
    errorOnSubmit: PropTypes.bool,
    handleFakeTest: PropTypes.func,
    handleDeactivationReactivation: PropTypes.func,
    handleAlertDeletion: PropTypes.func,
  };

  sortPropsBySelectedColumn = (options) => {
    this.props.sortPropsBySelectedColumn(options);
  }

  flightAlertHeader = () => {
    let caret = '';
    if (this.props.sortOrder) {
      // up arrow
      caret = (<FontIcon
        className='material-icons'
        style={{ fontSize: '13px', top: '3px', display: 'inline' }}
      >
        &#xE316;
      </FontIcon>);
    } else {
      // down arrow
      caret = (<FontIcon
        className='material-icons'
        style={{ fontSize: '13px', top: '3px', display: 'inline' }}
      >
        &#xE313;
      </FontIcon>);
    }
    const caretSizedSpan = (<span
      className='caret-sized-span'
      style={{
 height: '13px', width: '13px', top: '3px', display: 'inline-block',
}}
    />);

    return (
      <div className='column-headers alert-list-row' key={0}>
        <span
          className='date column'
          style={{ paddingLeft: 'calc(3% - 13px)' }}
          onClick={this.sortPropsBySelectedColumn.bind(this, { columnName: 'DATE', bool: !this.props.sortOrder })}
        >
          {this.props.currentSortColumn === 'DATE' ? caret : caretSizedSpan}
          Date
        </span>
        <span
          className='flight-number column hide-xs'
          style={{ marginLeft: '-6.5px' }}
          onClick={this.sortPropsBySelectedColumn.bind(this, { columnName: 'FLIGHT', bool: !this.props.sortOrder })}
        >
          {this.props.currentSortColumn === 'FLIGHT' ? caret : caretSizedSpan}
          Flight
        </span>
        <span
          className='origin column hide-xs hide-mobile'
          onClick={this.sortPropsBySelectedColumn.bind(this, { columnName: 'ORIGIN', bool: !this.props.sortOrder })}
        >
          {this.props.currentSortColumn === 'ORIGIN' ? caret : caretSizedSpan}
          Origin
        </span>
        <span
          className='destination column hide-xs hide-mobile'
          onClick={this.sortPropsBySelectedColumn.bind(this, { columnName: 'DEST', bool: !this.props.sortOrder })}
        >
          {this.props.currentSortColumn === 'DEST' ? caret : caretSizedSpan}
          Destination
        </span>
        <span
          className='departure-arrival-time column hide-xs'
          onClick={this.sortPropsBySelectedColumn.bind(this, { columnName: 'DEPART', bool: !this.props.sortOrder })}
        >
          {this.props.currentSortColumn === 'DEPART' ? caret : caretSizedSpan}
          Departure <span className='hide-number-and-time'>&nbsp;Time</span>
        </span>
        <span
          className='departure-arrival-time column hide-xs'
          onClick={this.sortPropsBySelectedColumn.bind(this, { columnName: 'ARRIVE', bool: !this.props.sortOrder })}
        >
          {this.props.currentSortColumn === 'ARRIVE' ? caret : caretSizedSpan}
          Arrival <span className='hide-number-and-time'>&nbsp;Time</span>
        </span>
        <span
          className='description-upcoming column description-title'
          onClick={this.sortPropsBySelectedColumn.bind(this, { columnName: 'DESCRIPT', bool: !this.props.sortOrder })}
        >
          {this.props.currentSortColumn === 'DESCRIPT' ? caret : caretSizedSpan}
          Description
        </span>
      </div>
    );
  }

  get flightAlertsList() {
    const alertsList = [];
    const { currentUser } = this.context;
    // the first push is for column headers
    alertsList.push(this.flightAlertHeader());
    const usersThatCanSeeTheTestButton = [];
    if (currentUser && currentUser.accountId === 1) {
      usersThatCanSeeTheTestButton.push(currentUser.email);
    }
    const renderTestButton = this.renderTestButton(usersThatCanSeeTheTestButton);

    if (this.props.alerts && this.props.alerts instanceof Array && this.props.alerts.length > 0) {
      this.props.alerts.forEach((alert, i) => {
        const {
          arrivalAirportFsCode,
          departureAirportFsCode,
          scheduledDeparture,
          sendEmail,
          shortText,
        } = alert;

        const arrivalMoment = moment.tz(alert.scheduledArrival, alert.arrivalTimezone);
        const departureMoment = moment.tz(scheduledDeparture, alert.departureTimezone);
        let emailDeliveryPoint = null;
        let phoneDeliveryPoint = null;
        let currentCarrier = null;
        if (sendEmail) {
          emailDeliveryPoint = alert.alternateDeliveryEmail
            || (currentUser && (currentUser.flightMonitorEmailAddress0 || currentUser.email));
        }

        if (shortText) {
          phoneDeliveryPoint = alert.alternateDeliveryPhoneNumber
            || (currentUser && currentUser.flightMonitorWirelessNumber0);
          currentCarrier = alert.alternateDeliveryCarrier
            || (currentUser && currentUser.flightMonitorWirelessServiceCode0);
        }

        alertsList.push(<UpcomingFlightAlertRow
          date={moment(scheduledDeparture).utc().format(dateFormatString(currentUser))}
          flightNumber={`${alert.carrierCode}${alert.flightNumber}`}
          origin={istanbulAirportCodeCheck(departureAirportFsCode)}
          destination={istanbulAirportCodeCheck(arrivalAirportFsCode)}
          arrivalMoment={arrivalMoment}
          departureMoment={departureMoment}
          description={alert.alertDescription}
          messageType={alert.messageType}
          sendEmail={sendEmail}
          shortText={shortText}
          savedOn={moment(alert.createdAt).format(dateFormatString(currentUser))}
          key={alert.flexId}
          index={i}
          user={currentUser}
          handleFakeTest={this.props.handleFakeTest}
          handleDeactivationReactivation={this.props.handleDeactivationReactivation}
          handleAlertDeletion={this.props.handleAlertDeletion}
          flexId={alert.flexId}
          wmaId={alert.wmaId}
          status={alert.status}
          email={emailDeliveryPoint}
          phoneNumber={phoneDeliveryPoint}
          carrier={currentCarrier}
          renderTestButton={renderTestButton}
        />);
      });
    } else {
      return false;
    }
    return alertsList;
  }

  renderErrorMessages = () => this.props.errorMessages.map(message => (
    <div
      className='search-error-text-block'
      style={{ marginTop: '15px' }}
      key={`${message.slice(0, 15)} ${moment().unix()}`}
    >
      <p className='search-error-text'>{message}</p>
    </div>
  ));

  renderTestButton = users => users.reduce((memo, key) => {
    if (key.verifiedUser) {
      return memo;
    }

    if (this.context.currentUser &&
      this.context.currentUser.email.toLowerCase() === key.toLowerCase()) {
      memo.verifiedUser = true;
      return memo;
    }
    return memo;
  }, { verifiedUser: false }).verifiedUser;

  render() {
    // this.logger('props', this.props);

    return (
      <section className='upcoming-flight-alerts'>
        <div className='upcoming-flight-alerts-title'>
          <h1>Upcoming Flight Alerts</h1>
          <h4>Click on a flight to view more detail</h4>
        </div>
        {this.props.errorOnSubmit ? <div> {this.renderErrorMessages()} </div> : null}
        <div className='flight-alerts-list' >
          {this.flightAlertsList && this.flightAlertsList.length ? this.flightAlertsList :
          <div className='alert-data-row alert-list-row' style={{ backgroundColor: 'rgba(255, 255, 255, .4)' }}>
            <div className='no-flights-content-wrapper'>
              <p className='no-monitored-flights'>You currently have no flights set up to be monitored</p>
            </div>
          </div>
          }
        </div>
      </section>
    );
  }
}
