import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '@flightstats/component-lib';
import { Route } from 'react-router-dom';
import CommonSectionWrapper from '../CommonSectionWrapper/CommonSectionWrapper';

const Status = ({ children }) => (
  <Route
    component={() => children}
  />
);

Status.propTypes = {
  children: PropTypes.node,
};

const PrimaryContent = styled.h6`
  font-size: 1.3rem;
  font-weight: 600;
  color: ${colors.fsOrange};
  text-transform: uppercase;
`;

const NoResultWrapper = styled.div`
  margin-top: 20px;
  font-size: 1rem;
`;

const NoResult = (props) => {
  const {
    sectionTitle,
    primaryContent,
    secondaryContent,
    adUnit,
    subscriptionActive,
  } = props;

  return (
    <Status>
      <NoResultWrapper>
        <CommonSectionWrapper
          adUnit={adUnit}
          subscriptionActive={subscriptionActive}
          title={sectionTitle}
        >
          <div>
            <PrimaryContent>{primaryContent}</PrimaryContent>
            {secondaryContent && <section>{secondaryContent}</section>}
          </div>
        </CommonSectionWrapper>
      </NoResultWrapper>
    </Status>
  );
};

NoResult.propTypes = {
  adUnit: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  sectionTitle: PropTypes.string,
  primaryContent: PropTypes.string,
  secondaryContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subscriptionActive: PropTypes.bool,
};

NoResult.defaultProps = {
  useDefaultSecondaryCSS: true,
};

export default NoResult;
