import React from 'react';
import PropTypes from 'prop-types';
import FontIcon from 'material-ui/FontIcon';

const NextNavIcon = props => (
  <FontIcon
    className='material-icons'
    color='rgba(255, 2, 0, 0.5)'
    hoverColor='rgb(255, 2, 0)'
    style={{ display: 'inline', float: 'right' }}
    onClick={props.navigateNext}
  >
    &#xE409;
  </FontIcon>
);

NextNavIcon.propTypes = {
  navigateNext: PropTypes.func,
};

export default NextNavIcon;
