import React from 'react';
import PropTypes from 'prop-types';

const FeatureDot = (props) => {
  const {
    subText,
  } = props;
  return (<p className='subscription-feature-sub-text'>{subText}</p>);
};

FeatureDot.propTypes = {
  subText: PropTypes.string,
};

export default FeatureDot;
