import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import FontIcon from 'material-ui/FontIcon';
import { connect } from 'react-redux';
import { equals, ifElse, pathOr, both, and } from 'ramda';
import Helmet from 'react-helmet';
import { push } from 'connected-react-router';
import componentBase from '../../../../src/lib/component-base';
import { dateFormatString } from '../../../../src/lib/date-time-format';
import { getPaymentHistoryDetail, startSpinner, endSpinner } from '../../redux/actions';
import FSFlatButton from '../../../../src/components/lib/FSFlatButton';
import { subscriptionTitleForLevel, subscriptionLevelForCost } from '../../../../shared/lib/subscription-level-matching';
import { openTab } from '../../../../src/utils/openTab';
import receipt from '../../../../static/images/account-icons/Invoice2-Icon-BLACK.svg';
import logo from '../../../../static/images/account-icons/FS-Logo-BLACK.svg';

@connect(state => ({
  braintreeHistoryDetailLoading: state.Account.braintreeHistoryDetailLoading,
  braintreeHistoryDetail: state.Account.braintreeHistoryDetail,
  braintreeHistoryDetailError: state.Account.braintreeHistoryDetailError,
  loggedIn: state.Account.loggedIn,
}), { push, startSpinner, endSpinner })
@componentBase('ViewPaymentHistoryDetail')
export default class ViewPaymentHistoryDetail extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    startSpinner: PropTypes.func,
    endSpinner: PropTypes.func,
    braintreeHistoryDetailLoading: PropTypes.bool,
    braintreeHistoryDetail: PropTypes.object,
    loggedIn: PropTypes.bool,
    match: PropTypes.object,
    push: PropTypes.func,
    user: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.handlePrint = this.handlePrint.bind(this);
  }

  state = {
    loading: true,
  };

  componentDidMount() {
    const { dispatch, match, loggedIn } = this.props;
    const referenceNum = pathOr(0, ['params', 'referenceNumber']);

    this.props.startSpinner();

    const handlePaymentHistory = ifElse(
      l => l,
      () => dispatch(getPaymentHistoryDetail(referenceNum(match))),
      () => false,
    );

    handlePaymentHistory(loggedIn);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.braintreeHistoryDetailLoading !== this.state.loading) {
      this.setState({ loading: nextProps.braintreeHistoryDetailLoading });
      if (!nextProps.braintreeHistoryDetailLoading) {
        this.props.endSpinner();
      }
    }
  }

  handleMissingKeys = (obj) => {
    const result = {
      ...obj,
    };
    const expectedKeys = {
      referenceNumber: '',
      purchaseDate: '',
      amountCharged: '0.00',
      description: '',
    };

    Object.keys(expectedKeys).forEach((k) => {
      if (!result[k]) {
        result[k] = expectedKeys[k];
      }
    });

    return result;
  }

  formatBillingAddress = customer => (['name', 'streetAddress', 'city', 'state', 'postalCode', 'countryCode'].map(v => customer[v]).filter(v => v).join(', '));

  formatPurchaseDate = (date) => {
    if (!date) {
      return '--';
    } else if (moment(date).format() === 'Invalid date') {
      return '__';
    }
    return moment(date).utc().format(dateFormatString(this.props.user));
  }

  formatKinds = (kind) => {
    let str = kind;
    str = (str || '').replace(/,/, '').replace(/(^| )(\w)/g, x => x.toUpperCase());
    let description = str;
    let itemTag = 'FS_1';
    let nextPayment = '';
    if (str.toLowerCase() === 'subscription_charged_successfully') {
      const level = subscriptionLevelForCost(this.props.braintreeHistoryDetail.amount) ||
        (this.props && this.props.user && this.props.user.subscriptionLevel);
      const subscriptionName = subscriptionTitleForLevel(level) || '';
      description = `${subscriptionName} - Monthly Subscription`;
      itemTag = level === 3 ? 'PRO_1' : 'STND_1';
      let proratedChargeText = '';
      if (level === 3 && this.props.braintreeHistoryDetail.amount !== '$24.99' && this.props.braintreeHistoryDetail.amount !== '$2.99') {
        proratedChargeText = ' OF $24.99';
      }

      nextPayment = `NOTE: THIS WILL BE A RECURRING MONTHLY CHARGE${proratedChargeText}.`;
    } else if (str.toLowerCase().includes('flight alerts')) {
      itemTag = 'FAC_1';
      description = `${str} Credits`;
    } else if (str.toLowerCase().includes('historical flight status by')) {
      // case where exports are bought
      // dont change description
      itemTag = 'Data Export';
    } else if (str.toLowerCase().includes('historical')) {
      itemTag = 'HIST_1';
      description = `${str} Credits`;
    }
    return {
      description,
      itemTag,
      nextPayment,
    };
  }

  handlePrint = (e) => {
    e.preventDefault();
    if (typeof window !== 'undefined') {
      window.print();
    } else {
      this.context.toast.error('Please right click and select "print..." from the menu, your browser does not support this action', 'Error', 3, false);
    }
  }

  paymentHistory = () => {
    if (this.props && this.props.braintreeHistoryDetail) {
      /* braintreeId from the transaction view will be the braintreeId of a Sale object
        or the transactionId of the recurring Subscription object */
      const {
        braintreeId, createdAt, amount, kind,
      } = this.props.braintreeHistoryDetail;
      const descriptions = this.formatKinds(kind);
      const item = {
        referenceNumber: braintreeId || '--',
        purchaseDate: this.formatPurchaseDate(createdAt),
        amountCharged: amount,
        description: descriptions.description,
        tag: descriptions.itemTag,
        nextDate: descriptions.nextPayment,
      };
      const formatted = this.handleMissingKeys(item);
      return formatted;
    }
    return {
      referenceNumber: '--',
      purchaseDate: '--',
      amountCharged: '--',
      description: '--',
      itemTag: 'FA_1',
    };
  }

  icons = () => ({
    receipt,
    logo,
  })

  isSubscription = () => {
    const { kind = '' } = this.props.braintreeHistoryDetail;
    return kind.toLowerCase().includes('subscription');
  }

  customer = () => {
    let additionalInfo;
    const user = this.props && this.props.user;

    if (user) {
      additionalInfo = user.additionalInfo || {};

      // generate billing information
      let {
        city, countryCode, name, postalCode, state, streetAddress,
      } = additionalInfo;
      if (this.props && this.props.braintreeHistoryDetail) {
        const { billingInformation } = this.props && this.props.braintreeHistoryDetail;
        if (billingInformation) {
          // using billingInformation value from transaction view
          city = billingInformation.city;
          countryCode = billingInformation.countryCode;
          name = billingInformation.name;
          postalCode = billingInformation.postalCode;
          state = billingInformation.state;
          streetAddress = billingInformation.streetAddress;
        }
      }

      return {
        city,
        countryCode,
        name,
        postalCode,
        state,
        streetAddress,
      };
    }
    return {};
  }

  renderReceiptHeader = () => {
    const data = this.paymentHistory();
    return (
      <div className='receipt-detail-header'>
        <div className='receipt-heading'>
          <h5>{this.isSubscription() ? 'SUBSCRIPTION' : 'TRANSACTION'} ID:&nbsp;</h5>
          <p>{data.referenceNumber}</p>
        </div>
        <div className='receipt-heading'>
          <h5>DATE:&nbsp;</h5>
          <p>{data.purchaseDate}</p>
        </div>
      </div>
    );
  };

  renderReceiptItem = () => {
    const data = this.paymentHistory();
    return (
      <div className='receipt-detail-table'>
        <div className='main-receipt-container'>
          <div className='row'>
            <div className='receipt-table-header col-xs-2' >
              <h5>ITEM</h5>
            </div>
            <div className='receipt-table-header col-xs-7'>
              <div>
                <h5>DESCRIPTION</h5>
              </div>
            </div>
            <div className='receipt-table-header col-xs-3'>
              <h5>AMOUNT PAID</h5>
            </div>
          </div>
          <div className='row'>
            <div className='receipt-table-column col-xs-2' >
              <p>{data.tag}</p>
            </div>
            <div className='receipt-table-column col-xs-7'>
              <div>
                <p>{data.description}</p>
              </div>
            </div>
            <div className='receipt-table-column col-xs-3'>
              <p>{data.amountCharged}</p>
            </div>
          </div>
          <div className='receipt-next-date col-xs-12'>
            <p>{data.nextDate}</p>
          </div>
        </div>
        <div className='row'>
          <div className='receipt-column col-xs-9'>
            <div>
              <p>Amount Paid</p>
            </div>
          </div>
          <div className='receipt-amount col-xs-3'>
            <p>{data.amountCharged}</p>
          </div>
        </div>
        <div className='row'>
          <div className='receipt-column col-xs-9'>
            <h5 className='balance'>
              CURRENT BALANCE DUE
            </h5>
            <FontIcon
              className='material-icons'
              style={{ fontSize: '40px', display: 'inline', color: 'black' }}
            >
              &#xE037;
            </FontIcon>
          </div>
          <div className='col-xs-3 current-balance-zero'>
            <p>$0.00</p>
          </div>
        </div>
      </div>
    );
  };

  renderBillingAddress = () => (
    <div className='row receipt-customer-address'>
      <div className='col-xs-12 receipt-table-header'>
        <h5>BILLING INFORMATION</h5>
      </div>
      <div className='col-xs-12 receipt-table-column'>
        <p>{this.formatBillingAddress(this.customer())}</p>
      </div>
    </div>
  );

  renderTermsLink = () => {
    // default to subscriptions terms link
    let termsLink = '/company/legal/flightstats-subscription-agreement-terms-and-conditions';
    let termsWording = 'FlightStats Subscription Agreement';
    if (this.props && this.props.braintreeHistoryDetail) {
      const { kind } = this.props.braintreeHistoryDetail;
      const { itemTag } = this.formatKinds(kind);

      // TODO: REMOVE ENTIRELY, NOT NEEDED AFTER DATA EXPORT SHUTDOWN
      if (itemTag === 'Data Export') {
        termsLink = '';
        termsWording = '';
      }
    }
    return (
      <a href={termsLink} onClick={e => openTab(termsLink, e)}>
        {termsWording}
      </a>
    );
  }

  renderNoInvoice = () => (
    this.renderNoInvoiceContent((this.state.loading ? 'Loading' : 'Invoice Not Found'), !this.state.loading)
  );

  renderNoInvoiceContent = (text, showButton) => (
    <div className='row payment-history-detail'>
      <div className='col-xs-12 text' style={{ textAlign: 'center' }}>
        <h2 style={{ margin: '90px 30px 30px', color: '#aaa' }}>{text}</h2>
        {showButton && <FSFlatButton
          label='CONTINUE'
          onClick={() => this.props.push('/my-account/payment')}
          style={{ marginBottom: '12px' }}
        />}
      </div>
    </div>
  );

  render() {
    const userId = pathOr(false, ['user', 'id']);
    const invoiceOwnerId = pathOr(false, ['braintreeHistoryDetail', 'userId']);
    const isValid = both(userId, invoiceOwnerId)(this.props);
    const invoiceOwnedByUser = equals(userId(this.props), invoiceOwnerId(this.props));
    const authorized = and(isValid, invoiceOwnedByUser);

    if (!authorized) {
      return this.renderNoInvoice();
    }

    let isDataExport = false;
    if (this.props && this.props.braintreeHistoryDetail) {
      const { kind } = this.props.braintreeHistoryDetail;
      const { itemTag } = this.formatKinds(kind);

      // TODO: REMOVE ENTIRELY, NOT NEEDED AFTER DATA EXPORT SHUTDOWN
      if (itemTag === 'Data Export') {
        isDataExport = true;
      }
    }

    return (
      <div className='print-section'>
        <Helmet
          title='FlightStats - Receipt'
        />
        <section className='payment-history-detail'>
          <div className='receipt-container'>
            <div className='invoice-header'>
              <div>
                <img src={this.icons().receipt} className='receipt-logo' alt='FlightStats receipt icon' />
              </div>
              <h1>Receipt</h1>
              <div>
                <img src={this.icons().logo} className='receipt-logo' alt='FlightStats logo' />
              </div>
              <div className='receipt-address'>
                <p>522 SW 5th Avenue, Suite 200</p>
                <p>Portland, Oregon 97204</p>
                <p>+1 503 274-0938</p>
              </div>
            </div>
            {this.renderReceiptHeader()}
            {this.renderReceiptItem()}
            {this.renderBillingAddress()}
            <div className='receipt-thanks'>
              <h1>THANK YOU!</h1>
            </div>
            <div className='receipt-terms-agreement'>
              <p>
                Purchases are non-refundable {isDataExport != true ? 'and subject to terms and conditions outlined in the' : ''}
              </p>
              {this.renderTermsLink()}
            </div>
          </div>
        </section>
        <div className='print-button-container'>
          <FSFlatButton
            style={{ width: '100%' }}
            primary
            label='PRINT'
            onClick={this.handlePrint}
          />
        </div>
      </div>
    );
  }
}
