import React from 'react';
import CreateFlightAlert from './children/CreateFlightAlert';
import ViewAlertHistory from './children/ViewAlertHistory';
import componentBase from '../../../../../../src/lib/component-base';

@componentBase('FlightAlertsConsole')
export default class FlightAlertsConsole extends React.Component {
  render() {
    return (
      <section className='flight-alerts-management-console'>
        <div className='alert-history-container'>
          <CreateFlightAlert {...this.props} />
          <ViewAlertHistory {...this.props} />
        </div>
      </section>
    );
  }
}
