import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SelectFilterAsync } from '@flightstats/component-lib';
import Scroll, { Element } from 'react-scroll';

import { SCROLL_OPTIONS } from '../shared/utility';
import { countryOptions } from '../shared/AccountLists';
import componentBase from '../../../../src/lib/component-base';
import LocationIcon from '../../../../static/images/location-icon.svg';
import IndustryIcon from '../../../../static/images/IndustryBriefcase-Icon-WHITE.svg';
import { isValidPostalCode, sanitizePostalCode, MAX_POSTAL_CODE_FIELD_LENGTH } from '../../../../shared/lib/validators';
import IconHeader from '../../../../shared/components/IconHeader/IconHeader';
import industries from '../../../../shared/lib/industryOptions';

const MAX_FIELD_LENGTH = 175;

const idErrorPrepend = 'billing-info-error';

@componentBase('BillingInformation')
export default class BillingInformation extends Component {
  static propTypes = {
    updateProfile: PropTypes.func.isRequired,
    user: PropTypes.object,
  };

  state = {
    additionalInformationCountryCode: this.props.user &&
      this.props.user.additionalInfo &&
      this.props.user.additionalInfo.countryCode,
    additionalInformationIndustry: this.props.user &&
      this.props.user.additionalInfo &&
      this.props.user.additionalInfo.industry,
    missingFields: null,
  };

  scrollToError() {
    const firstErrorField = this.state.missingFields && this.state.missingFields[0];

    if (firstErrorField) {
      Scroll.scroller.scrollTo(`billing-info-error-${firstErrorField}`, SCROLL_OPTIONS);
    }
  }

  validate = (additionalInfo) => {
    const requiredFields = {
      name: true,
      streetAddress: true,
      city: true,
      state: false,
      postalCode: false,
      countryCode: true,
      industry: true,
    };

    const missingFields = Object.keys(additionalInfo).filter(fieldName =>
      requiredFields[fieldName] && !additionalInfo[fieldName]);

    const invalidFields = Object.keys(additionalInfo).filter(fieldName =>
      additionalInfo[fieldName] && additionalInfo[fieldName].length > MAX_FIELD_LENGTH);

    // Postal code may contain no more than 9 letter or number characters.
    // 12345-1234
    if (!isValidPostalCode(additionalInfo.postalCode)) {
      invalidFields.push('postalCode');
    }

    return { missingFields, invalidFields };
  }

  submitForm = () => {
    const additionalInfo = {
      name: this.refs.additionalInfoName.value,
      streetAddress: this.refs.additionalInfoStreetAddress.value,
      city: this.refs.additionalInfoCity.value,
      state: this.refs.additionalInfoState.value,
      postalCode: sanitizePostalCode(this.refs.additionalInfoPostalCode.value),
      countryCode: this.state.additionalInformationCountryCode,
      industry: this.state.additionalInformationIndustry,
    };

    const { missingFields, invalidFields } = this.validate(additionalInfo);

    if ((missingFields && missingFields.length) || (invalidFields && invalidFields.length)) {
      this.setState(
        { missingFields, invalidFields },
        () => {
          this.scrollToError();
        },
      );
    } else {
      this.setState({ missingFields: null, invalidFields: null });
      // Email is copied due to updateProfile call requires email key
      const updatedUserProfile = {
        email: this.props.user && this.props.user.email,
        profile: {
          additionalInfo,
        },
      };

      return this.props.updateProfile(updatedUserProfile);
    }

    return Promise.reject(new Error(`Missing fields: ${missingFields.join(', ')}`));
  };

  handleCountryChange = (option) => {
    this.setState({ additionalInformationCountryCode: option && option.value });
  }

  handleIndustryChange = (option) => {
    this.setState({ additionalInformationIndustry: option && option.value });
  }

  handleRemoveCountrySelection = () => {
    this.handleCountryChange({ value: null });
  }

  handleRemoveIndustrySelection = () => {
    this.handleIndustryChange({ value: null });
  }

  generateTextEntryField = (title, inputRef, inputName, placeholder, defaultValue, autocomplete) =>
    (
      <div className='row spaced' style={{ marginBottom: '14px' }}>
        <div className='col-xs-11'>
          <div className='fieldContainer'>
            <Element name={`${idErrorPrepend}-${inputName}`} />
            <label htmlFor={inputRef} className={`accountFieldLabel ${this.errorClass(inputName)}`}>
              {this.errorMessage(inputName, title)}
            </label>
            <input
              type='text'
              ref={inputRef}
              id={inputName}
              name={inputName}
              defaultValue={defaultValue}
              placeholder={placeholder}
              autoComplete={autocomplete}
              className={`account-input ${this.errorClass(inputName)}`}
            />
          </div>
        </div>
      </div>
    );

  errorClass = (fieldName, className) => (
    (
      (
        (this.state.missingFields && this.state.missingFields.includes(fieldName)) ||
        (this.state.invalidFields && this.state.invalidFields.includes(fieldName))
      ) &&
      (className || 'invalid')
    ) || '');

  errorMessage = (fieldName, prefixText, errorText) => {
    if (this.state.missingFields && this.state.missingFields.includes(fieldName)) {
      // there is an error for this field
      return errorText || `Please enter ${prefixText.toLowerCase()}`;
    } else if (this.state.invalidFields && this.state.invalidFields.includes(fieldName)) {
      // there is an error for this field
      if (fieldName === 'postalCode') {
        return `Enter a postal code less than ${MAX_POSTAL_CODE_FIELD_LENGTH} characters`;
      }

      return `Maximum ${prefixText.toLowerCase()} length is ${MAX_FIELD_LENGTH}`;
    }

    return prefixText;
  }

  render() {
    const hasErrors = this.state.missingFields && this.state.missingFields.length > 0;
    const profileAdditionalInfo = (this.props.user && this.props.user.additionalInfo) || {};

    return (
      <div className={`subscription-form-section billing-info ${hasErrors ? 'error' : null}`}>
        <Element name={`${idErrorPrepend}-text`} />
        <div className='section-head'>
          <IconHeader icon={LocationIcon} title='Billing Address' />
        </div>

        <div ref='additionalInfo'>
          <form method='POST'>
            <div className='alert-preferences'>
              {this.generateTextEntryField('Full Name', 'additionalInfoName', 'name', '', profileAdditionalInfo && profileAdditionalInfo.name, 'name')}
              {this.generateTextEntryField('Street Address', 'additionalInfoStreetAddress', 'streetAddress', '', profileAdditionalInfo.streetAddress, 'street-address')}
              {this.generateTextEntryField('City', 'additionalInfoCity', 'city', '', profileAdditionalInfo.city, 'locality')}
              {this.generateTextEntryField('State/Province', 'additionalInfoState', 'state', '', profileAdditionalInfo.state, 'region')}
              {this.generateTextEntryField('Postal Code', 'additionalInfoPostalCode', 'postalCode', '', profileAdditionalInfo.postalCode, 'postal-code')}

              <div className='row spaced'>
                <div className='col-xs-11'>
                  <div className='fieldContainer'>
                    <Element name={`${idErrorPrepend}-countryCode`} />
                    <label htmlFor='additionalInformationCountryCode' className={`accountFieldLabel ${this.errorClass('countryCode')}`}>
                      {this.errorMessage('countryCode', 'Country')}
                    </label>
                    <SelectFilterAsync
                      clearable
                      searchable
                      ref='additionalInformationCountryCode'
                      error={this.errorClass('countryCode') ? {
                        // for red border to show up, just has to be non-empty object
                        error: 'countryCode',
                      } : {}}
                      handleMenuSelection={this.handleCountryChange}
                      handleRemoveSelection={this.handleRemoveCountrySelection}
                      results={countryOptions}
                      value={this.state.additionalInformationCountryCode}
                      bgColor='rgba(255, 255, 255, 0.4)'
                      borderProp='2px solid'
                      borderColor='#FFF'
                      iconColor='#FFF'
                      inputFieldBg={false}
                      height='30px'
                      placeholderColor='#FFF'
                      textColor='#FFF'
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='row spaced industry'>
              <div className='section-head'>
                <IconHeader icon={IndustryIcon} title='Industry' containerStyle={{ marginTop: '48px' }} />
              </div>
              <div className='col-xs-11'>
                <div className='fieldContainer'>
                  <Element name={`${idErrorPrepend}-industry`} />
                  <label htmlFor='additionalInformationIndustry' className={`accountFieldLabel ${this.errorClass('industry')}`}>
                    {this.errorMessage('industry', 'Tell us a little more about yourself', 'Please choose an industry')}
                  </label>
                  <SelectFilterAsync
                    clearable
                    searchable
                    ref='additionalInformationIndustry'
                    error={this.errorClass('industry') ? {
                      // for red border to show up, just has to be non-empty object
                      error: 'industry',
                    } : {}}
                    handleMenuSelection={this.handleIndustryChange}
                    handleRemoveSelection={this.handleRemoveIndustrySelection}
                    results={industries}
                    value={this.state.additionalInformationIndustry}
                    bgColor='rgba(255, 255, 255, 0.4)'
                    borderProp='2px solid'
                    borderColor='#FFF'
                    iconColor='#FFF'
                    inputFieldBg={false}
                    height='30px'
                    placeholderColor='#FFF'
                    textColor='#FFF'
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
