import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { push } from 'connected-react-router';
import { isEmpty, identity } from 'ramda';

import { setHistoricalSearchValues, setByFlightSecondaryAction } from '../../../redux/Single/actions';
import { updatePageIndex } from '../../../redux/Multi/actions';
import SearchSectionContainer from '../../../../../shared/components/SearchSectionContainer/SearchSectionContainer';
import FSDatePicker from '../../../../../shared/components/FSDatePicker/FSDatePicker';
import flexErrors from '../../../../../src/constants/flex-errors';
import AirlineSelect from '../../../../../src/components/lib/AirlineSelect';
import { dateFormatString } from '../../../../../src/lib/date-time-format';
import componentBase from '../../../../../src/lib/component-base';

@componentBase('HistoricalByFlightSearch')
export default class HistoricalByFlightSearch extends Component {
  static propTypes = {
    byFlightAirline: PropTypes.object,
    flightSearchDepartureDate: PropTypes.object,
    handleDepartureDateChange: PropTypes.func,
    flightNumber: PropTypes.string,
    dispatch: PropTypes.func,
    handleAirlineChange: PropTypes.func,
    handleFlightNumberChange: PropTypes.func,
    byFlightSecondaryAction: PropTypes.bool,
    user: PropTypes.object,
  }

  constructor(props, context) {
    super(props, context);

    this.onFlightNumberChange = this.onFlightNumberChange.bind(this);
    this.onDateOptionChange = this.onDateOptionChange.bind(this);
    this.state = {
      errorText: null,
      flightNumber: this.props.flightNumber || '',
    };
  }

  componentDidMount() {
    this.submitSearchForm = this.submitSearchForm.bind(this);
    this.dispatch = this.props.dispatch;
  }

  componentWillReceiveProps(nextProps) {
    const { dispatch, flightNumber, byFlightSecondaryAction } = nextProps;
    // use component state bc the input is controlled, the source of truth is still the redux store.
    if (flightNumber !== this.state.flightNumber) {
      this.setState({
        flightNumber,
      });
    }

    if (byFlightSecondaryAction) {
      dispatch(setByFlightSecondaryAction(false));
    }
  }

  onFormChange = (newOption, triggeredByEnter) => {
    const {
      byFlightAirline,
      flightSearchDepartureDate,
      flightNumber,
    } = this.props;
    const newState = {
      byFlightAirline,
      flightSearchDepartureDate,
      flightNumber,
      ...newOption,
    };
    this.props.dispatch(setHistoricalSearchValues(newState, triggeredByEnter, true));
  }

  onAirlineOptionSelect = (option) => {
    let airline = null;
    if (option) {
      if (option.value || option.value === 0) airline = option.value;
    }
    this.props.handleAirlineChange(airline);
  };

  onDateOptionChange(date) {
    this.props.handleDepartureDateChange(date, 'flightSearch');
  }

  onFlightNumberChange = (e) => {
    let number = e.target.value;

    if (!number || !(/^\+?(0|[1-9]\d*)$/.test(number))) {
      number = '';
    }
    this.props.handleFlightNumberChange(number);
  }

  submitSearchForm = (e) => {
    e && e.preventDefault();
    const {
      flightSearchDepartureDate,
      flightNumber,
      byFlightAirline,
    } = this.props;

    const searchArgs = {
      byFlightAirline,
      flightSearchDepartureDate,
      flightNumber,
    };

    this.context.reportButtonPress('Search - By Flight');

    const url = this.buildUrl(searchArgs);

    if (this.validateFlightSearch(searchArgs) && url) {
      this.setState({ errorText: null }, () => {
        this.dispatch(updatePageIndex(1, '')); // reset pagination to 1
        this.dispatch(push(url));
      });
    } else if (this.errorText) {
      this.setState({ errorText: this.errorText });
    }
  }

  initialDepatureDate = () => {
    let flightSearchDepartureDate = this.prefillValue('flightSearchDepartureDate');
    if (flightSearchDepartureDate) {
      flightSearchDepartureDate = moment(flightSearchDepartureDate);
    } else {
      flightSearchDepartureDate = moment().utc();
    }

    return flightSearchDepartureDate;
  }

  prefillValue = (name, defaultValue) => {
    const value = this.props[name];
    return value || defaultValue;
  }

  initialAirline = () => {
    const { byFlightAirline } = this.props;
    if (byFlightAirline) {
      return byFlightAirline;
    }
    return null;
  }

  buildUrl = ({ byFlightAirline, flightNumber, flightSearchDepartureDate }) => {
    let path;
    /* eslint-disable no-underscore-dangle */
    const airlineCode = byFlightAirline &&
                        byFlightAirline.value &&
                        byFlightAirline.value._source &&
                        byFlightAirline.value._source.fs;

    const flightSearchDepartureDateFormatted = moment(flightSearchDepartureDate).format('YYYY/MM/DD');
    /* eslint-enable no-underscore-dangle */

    if (flightNumber && airlineCode) {
      path = `/historical-flight/${airlineCode}/${flightNumber}/${flightSearchDepartureDateFormatted}`;
    }

    return path;
  }

  validateFlightSearch = ({ byFlightAirline, flightNumber, flightSearchDepartureDate }) => {
    this.errorText = null;

    if (!flightSearchDepartureDate.isValid()) {
      this.errorText = flexErrors('BAD_MOMENT');
    } else {
      if (byFlightAirline === undefined) {
        this.errorText = flexErrors('BAD_AIRLINE_CODE');
      }
      if (flightNumber && isEmpty(byFlightAirline)) {
        this.errorText = flexErrors('MISSING_AIRLINE_CODE');
      }
      if (!flightNumber && isEmpty(byFlightAirline)) {
        this.errorText = flexErrors('HISTORICAL_FLIGHT_SEARCH_NO_INFORMATION_ENTERED');
      }
      if (byFlightAirline && !flightNumber) {
        this.errorText = flexErrors('MISSING_FLIGHT_NUMBER');
      }
    }

    return this.errorText === null;
  }

  render = () => {
    /* eslint-disable no-underscore-dangle */
    const {
      flightSearchDepartureDate,
      user,
      byFlightAirline,
      byFlightSecondaryAction,
    } = this.props;

    /* eslint-enable no-underscore-dangle */
    let error = '';
    if (this.state.errorText) {
      error = (
        <div className='search-error-text-block'>
          <p className='search-error-text'>{this.state.errorText}</p>
        </div>
      );
    }

    return (
      <form onSubmit={this.submitSearchForm}>
        <SearchSectionContainer
          searchAction={this.submitSearchForm}
          subscriptionActive
          title='Search by Flight'
        >
          <div className='historical-search-flight-search-fields'>
            { error }
            <div className='historical-search-half-fields-container'>
              <div className='historical-search-field'>
                <p>Airline</p>
                <AirlineSelect
                  name='airline'
                  airline={byFlightAirline}
                  onChange={
                    (option, triggeredByEnter) =>
                    this.onFormChange({ byFlightAirline: option }, triggeredByEnter)
                  }
                  shouldFocus
                  secondaryAction={
                    byFlightSecondaryAction ?
                    this.submitSearchForm :
                      identity
                  }
                />
              </div>
              <div className='historical-search-field'>
                <p>Flight Number</p>
                <input
                  className='advanced-search-text-field'
                  onChange={e => this.onFlightNumberChange(e)}
                  style={{ width: '100%' }}
                  type='text'
                  name='flight-number'
                  value={this.props.flightNumber}
                  placeholder='Example: 200'
                  autoComplete='off'
                />
              </div>
            </div>
            <div className='historical-search-half-fields-container'>
              <div className='historical-search-field'>
                <p>Date</p>
                <FSDatePicker
                  className='advanced-search-text-field full-width'
                  style={{ width: '100%' }}
                  key='flightSearchDepartureDate'
                  locale='en'
                  dateFormat={dateFormatString(user)}
                  selected={flightSearchDepartureDate}
                  maxDate={moment()}
                  minDate={moment('20060207', 'YYYYMMDD')}
                  onChange={this.onDateOptionChange}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode='select'
                  readOnly={false}
                />
              </div>
            </div>
          </div>
        </SearchSectionContainer>
      </form>
    );
  }
}
