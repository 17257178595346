import { constants as flickConstants, reducer as flickReducer } from './flick';
import { reducer as toastReducer, constants as toastConstants } from './toast';
import { reducer as sessionReducer, constants as sessionConstants } from './session';
import modules from '../../../modules/redux';

const reducers = {
  ...modules,
  [flickConstants.NAME]: flickReducer,
  [toastConstants.NAME]: toastReducer,
  [sessionConstants.NAME]: sessionReducer,
};

export default reducers;
