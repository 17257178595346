import React from 'react';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';
import componentBase from '../../../src/lib/component-base';
import FSFlatButton from '../../../src/components/lib/FSFlatButton';
import IconHeader from '../IconHeader/IconHeader';

@componentBase('GenericModal')
export default class GenericModal extends React.Component {
  static propTypes = {
    isModal: PropTypes.bool,
    modalOpen: PropTypes.bool,
    closeModal: PropTypes.func,
    handleOptionSelect: PropTypes.func,
    mainActionTitle: PropTypes.string,
    body: PropTypes.object,
    bodyClassName: PropTypes.string,
    headerIcon: PropTypes.string,
    headerString: PropTypes.string,
    disabled: PropTypes.bool,
    isLargeModal: PropTypes.bool,
    largeActions: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.oneOf([PropTypes.object, null]),
  };

  render() {
    const actions = this.props.largeActions || [
      <FSFlatButton
        label={this.props.mainActionTitle}
        onClick={this.props.handleOptionSelect}
        style={FSFlatButton.style({ width: '80%' })}
        backgroundColor='#7DD63B'
        hoverColor='#64bc23'
        disabled={this.props.disabled}
      />,
      <FSFlatButton
        label='CANCEL'
        primary
        onClick={this.props.closeModal}
        style={FSFlatButton.style({ width: '80%', color: 'black', marginTop: '10px' })}
        backgroundColor='transparent'
        hoverColor='#c6c6c6'
        disabled={this.props.disabled}
      />,
    ];

    const contentStyle = {
      maxWidth: this.props.isLargeModal ? '750px' : '347px',
      width: '90%',
    };

    const iconHeaderStyle = {
      color: 'black',
      margin: this.props.isLargeModal ? '0 25px' : '0 auto',
      display: 'table',
    };

    const titleClassName = this.props.isLargeModal ? 'modalTitleLarge' : 'modalTitle';
    const actionsClassName = this.props.isLargeModal ? 'modalActionsLarge' : 'modalActions';

    const bodyStyle = {
      backgroundColor: 'lightgray',
      padding: '10px 24px 0',
      minHeight: '75px',
    };

    const title = this.props.title === undefined ? <div><IconHeader icon={this.props.headerIcon} title={this.props.headerString} headerStyle={iconHeaderStyle} /></div> : this.props.title;

    return (
      <div>
        <Dialog
          contentClassName='genericModal'
          actions={actions}
          modal={this.props.isModal}
          open={this.props.modalOpen}
          onRequestClose={this.props.closeModal}
          autoScrollBodyContent
          contentStyle={contentStyle}
          actionsContainerClassName={actionsClassName}
          bodyStyle={bodyStyle}
          bodyClassName={this.props.bodyClassName}
          title={title}
          titleClassName={titleClassName}
        >
          <div>
            {this.props.body}
          </div>
        </Dialog>
      </div>
    );
  }
}
