import React from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'material-ui/FlatButton';
import componentBase from '../../lib/component-base';

@componentBase('SelectedExableComponent')
export default class SelectedExableComponent extends React.Component {
  static propTypes = {
    item: PropTypes.object,
    onClearClick: PropTypes.func,
    filterType: PropTypes.string,
  };

  static defaultProps = {
    item: {},
  };

  constructor(props, context) {
    super(props, context, 'SelectedExableComponent');

    this.muiDefaultProps = {
      style: {
        border: '2px rgba(129, 162, 202, 0.6) solid',
        margin: '10px 3px',
        height: '20px',
        maxHeight: '20px',
        borderRadius: '2px',
        marginTop: '5px',
        marginBottom: '5px',
        display: 'inline',
        backgroundColor: 'rgba(255, 255, 255, 0.07)',
      },
      pStyle: {
        display: 'inline-block',
        margin: '0',
        color: '#FFF',
        paddingRight: '5px',
        paddingLeft: '5px',
        fontSize: '.9em',
      },
      buttonStyle: {
        backgroundColor: 'transparent',
        height: '18px',
        lineHeight: '18px',
        minWidth: '18px',
        maxWidth: '18px',
        borderLeft: '2px rgba(129, 162, 202, 0.6) solid',
      },
      buttonLabelStyle: {
        lineHeight: '18px',
        textTransform: 'capitalize',
        fontSize: '.9em',
        paddingTop: '5px',
        paddingBottom: '5px',
        paddingLeft: '5px',
        paddingRight: '5px',
      },
      maxHeight: 700,
      autoWidth: true,
    };
  }

  _onClearClick = () => this.props.onClearClick(this.props.item, this.props.filterType);

  render() {
    this.logger('props', this.props);
    // Unique styles currently disabled
    // this.muiDefaultProps.style = Object.assign(this.muiDefaultProps.style, this.props.style);

    return (
      <div style={this.muiDefaultProps.style}>
        <p style={this.muiDefaultProps.pStyle}>
          {this.props.item.label}
        </p>
        <FlatButton
          label='x'
          backgroundColor={this.muiDefaultProps.buttonStyle.backgroundColor}
          style={this.muiDefaultProps.buttonStyle}
          labelStyle={this.muiDefaultProps.buttonLabelStyle}
          onClick={this._onClearClick}
        />
      </div>
    );
  }
}
