import * as t from './actionTypes';

export const startSpinner = () => ({
  type: t.START_SPINNER,
});

export const endSpinner = () => ({
  type: t.END_SPINNER,
});

const loadingDetailsByCode = () => ({
  type: t.LOAD_DETAILS_BY_CODE,
});

const loadDetailsByCodeSuccess = data => ({
  type: t.LOAD_DETAILS_SUCCESS,
  result: data,
});

const loadDetailsByCodeFail = error => ({
  type: t.LOAD_FAIL,
  error,
});

export const loadDetailsByCode = ({ airportCode }) => async (dispatch, getStore, client) => {
  dispatch(startSpinner());
  dispatch(loadingDetailsByCode());

  try {
    const data = await client.get(`/airport/${airportCode}`);

    dispatch(loadDetailsByCodeSuccess(data));
    dispatch(endSpinner());
  } catch (err) {
    dispatch(loadDetailsByCodeFail(err));
    dispatch(endSpinner());
  }
};

export const initialLoadFinish = () => ({
  type: t.INITIAL_LOAD_FINISH,
});

const loadingDelayIndicies = () => ({
  type: t.LOAD_DELAY_INDICES,
});

const loadDelayIndiciesSuccess = (defaultAirports, delayIndices, delayObj) => ({
  type: t.LOAD_DELAY_INDICES_SUCCESS,
  defaultAirports,
  delayIndices,
  delayObj,
});

const loadDelayIndiciesFail = error => ({
  type: t.LOAD_FAIL,
  error,
});

export const loadDelayIndices = () => async (dispatch, getStore, client) => {
  dispatch(loadingDelayIndicies());

  try {
    const {
      defaultAirports,
      delayIndices,
      delayObj,
    } = await client.get('/airports/delays');
    // this will also set loading to false and loaded to true
    dispatch(loadDelayIndiciesSuccess(
      defaultAirports,
      delayIndices,
      delayObj,
    ));
  } catch (err) {
    dispatch(loadDelayIndiciesFail(err));
  }
};

export const setAirport = airport => ({
  type: t.SET_AIRPORT,
  airport,
});

export const updateMap = ({ center, zoom }) => ({
  type: t.UPDATE_MAP,
  center,
  zoom,
});

export const updateAirportCode = ({ airportCode }) => ({
  type: t.UPDATE_AIRPORT_CODE,
  airport: airportCode,
});
