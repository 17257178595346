import React from 'react';
import PropTypes from 'prop-types';
import { SelectField } from '@flightstats/component-lib';

import componentBase from '../../../../src/lib/component-base';
import FSFlatButton from '../../../../src/components/lib/FSFlatButton';
import onResize from '../../../../src/lib/on-resize';
import { regions } from '../regions';

const ZoomButton = props => (
  <FSFlatButton
    label={props.label}
    style={{ width: '14%' }}
    backgroundColor={props.region === props.activeRegion ? '#81A2CA' : '#003259'}
    labelStyle={{
      textTransform: 'none',
      fontSize: '1rem',
      padding: '1px',
    }}
  />
);
ZoomButton.propTypes = {
  label: PropTypes.node,
  region: PropTypes.string,
  activeRegion: PropTypes.string,
};

const createLabel = region => `View ${region}`;

const zoomControlOptions = [
  {
    value: 0,
    label: createLabel('North America'),
    region: 'northAmerica',
  },
  {
    value: 1,
    label: createLabel('Europe'),
    region: 'europe',
  },
  {
    value: 2,
    label: createLabel('Asia'),
    region: 'asia',
  },
  {
    value: 3,
    label: createLabel('Oceania'),
    region: 'oceania',
  },
  {
    value: 4,
    label: createLabel('Africa'),
    region: 'africa',
  },
  {
    value: 5,
    label: createLabel('South America'),
    region: 'southAmerica',
  },
];

@onResize()
@componentBase('AirportDelayMapZoomControl')
export default class AirportDelayMapZoomControl extends React.Component {
  static propTypes = {
    region: PropTypes.string,
  };
  static defaultProps = { region: 'northAmerica' }

  constructor(props) {
    super(props);
    this.state = {
      value: regions.indexOf(props.region),
    };
  }

  componentDidMount() {
    this.initResizeListener();
  }

  componentWillUnmount() {
    this.removeResizeListener();
  }

  handleResize = () => this.forceUpdate();

  render() {
    if (this.mediaBreakpoint) {
      if (this.mediaBreakpoint.name !== 'desktopLg') {
        return (
          <div className='responsive-dropdown-container'>
            <SelectField
              bgColor='#81A2CA'
              borderColor='#81A2CA'
              handleMenuSelection={
                (option) => {
                  window.location.href = `/v2/airport-delays/${option.region}`;
                }
              }
              height='35px'
              iconColor='white'
              name='delay-select'
              results={zoomControlOptions}
              selectWrapperPosition='relative'
              textColor='white'
              value={this.state.value}
            />
          </div>
        );
      }
    }
    return (
      <div className='region-buttons'>
        <a href='/v2/airport-delays/northAmerica'>
          <ZoomButton label='North America' region='northAmerica' activeRegion={this.props.region} />
        </a>
        <a href='/v2/airport-delays/europe'>
          <ZoomButton label='Europe' region='europe' activeRegion={this.props.region} />
        </a>
        <a href='/v2/airport-delays/asia'>
          <ZoomButton label='Asia' region='asia' activeRegion={this.props.region} />
        </a>
        <a href='/v2/airport-delays/oceania'>
          <ZoomButton label='Oceania' region='oceania' activeRegion={this.props.region} />
        </a>
        <a href='/v2/airport-delays/africa'>
          <ZoomButton label='Africa' region='africa' activeRegion={this.props.region} />
        </a>
        <a href='/v2/airport-delays/southAmerica'>
          <ZoomButton label='South America' region='southAmerica' activeRegion={this.props.region} />
        </a>
      </div>
    );
  }
}
