import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import componentBase from '../../../../../../../src/lib/component-base';
import FSFlatButton from '../../../../../../../src/components/lib/FSFlatButton';
import CreditsSummary from '../../../../../../../shared/components/CreditsSummary';

@componentBase('ViewAlertHistory')
export default class ViewAlertHistory extends React.Component {
  static propTypes = {
    alertsSent: PropTypes.number,
    flightsMonitored: PropTypes.number,
    issuesCount: PropTypes.number,
    currentDate: PropTypes.string,
    creditsMonthlyAllowance: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    reserveCredits: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    openBuyCreditsModal: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.formatAlertsSent = this.formatAlertsSent.bind(this);
    this.formatFlightsMonitored = this.formatFlightsMonitored.bind(this);
    this.formatIssuesIdentified = this.formatIssuesIdentified.bind(this);
    this.rangeText = this.rangeText.bind(this);
    this.handleBuyMoreCredits = this.handleBuyMoreCredits.bind(this);
    this.determineBackgroundcolor = this.determineBackgroundcolor.bind(this);
    this.formatValue = this.formatValue.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.flightsMonitored !== nextProps.flightsMonitored) {
      return true;
    }
    if (this.props.creditsMonthlyAllowance !== nextProps.creditsMonthlyAllowance) {
      return true;
    }
    if (this.props.reserveCredits !== nextProps.reserveCredits) {
      return true;
    }
    if ((this.props.alertsSent >= 0) && (nextProps.alertsSent === 0)) {
      // don't go into initial state with stats zeroed out when in this view and alerts is selected from the menu
      return false;
    }
    return true;
  }

  formatValue(val) {
    return val === Infinity ? '--' : val;
  }

  determineBackgroundcolor(allowance, reserve) {
    return !allowance && !reserve ? 'red' : 'green';
  }

  formatAlertsSent(num) {
    if (num) {
      return num === 1 ? `${num} Message` : `${num} Messages`;
    }

    return '--';
  }

  formatFlightsMonitored(num) {
    return num ? `${num} Flights` : '--';
  }

  formatIssuesIdentified(num = 0) {
    return `${num} Issues Identified`;
  }

  rangeText() {
    const { currentDate } = this.props;

    if (!currentDate) {
      return '';
    }

    let text = 'No Activity';

    if (this.props.flightsMonitored === 0) {
      return text;
    }

    const today = moment(currentDate).format('MMM-DD-YYYY');
    const oneMonthAgo = moment(currentDate).subtract(1, 'month').format('MMM-DD-YYYY');

    text = `${oneMonthAgo} to ${today} Activity`;

    return text;
  }

  handleBuyMoreCredits = () => {
    this.props.openBuyCreditsModal();
    this.context.reportButtonPress('Buy More');
  }

  render() {
    // this.logger('props: ', this.props);
    const {
      flightsMonitored,
      issuesCount,
      alertsSent,
      creditsMonthlyAllowance,
      reserveCredits,
    } = this.props;
    const historyLink = '/flight-alerts/history/home';
    const doNotShowBuyMoreButton =
      (reserveCredits === Infinity && creditsMonthlyAllowance === Infinity);

    return (
      <div className='view-history'>
        <div style={{ height: '20px' }}>{this.rangeText()}</div>
        <div className='view-history-summary'>
          <CreditsSummary
            backgroundColor={
              this.determineBackgroundcolor(creditsMonthlyAllowance, reserveCredits)
            }
            creditsMonthlyAllowance={this.formatValue(creditsMonthlyAllowance)}
            reserveCredits={this.formatValue(reserveCredits)}
            doNotShowBuyMoreButton={doNotShowBuyMoreButton}
            buyMoreCredits={this.handleBuyMoreCredits}
            allowanceText='Credits Monthly Allowance'
            reserveText='Reserve Credits'

          />
          <div className='alerts-sent-container alerts-container'>
            <div className='alerts-sent'>
              <span className='title'>Alerts Sent</span>
              <p className='subject'>{this.formatAlertsSent(alertsSent)}</p>
            </div>
          </div>
          <div className='flights-monitored-container alerts-container'>
            <div className='flights-monitored'>
              <span className='title'>Flights Monitored</span>
              <p className='subject'>{this.formatFlightsMonitored(flightsMonitored)}</p>
            </div>
          </div>
          <div className='issues-container alerts-container'>
            <div className='issues'>
              <span className='title'>Identified Issues</span>
              <p className='subject'>{this.formatIssuesIdentified(issuesCount)}</p>
            </div>
          </div>
        </div>
        <div className='view-history-button' >
          <FSFlatButton
            label='VIEW HISTORY'
            onClick={this.context.gotoHandler(historyLink, 'View History')}
            primary
          />
        </div>
      </div>
    );
  }
}
