import React from 'react';
import Helmet from 'react-helmet';
import componentBase from '../../src/lib/component-base';
import { addMetaCanonical } from '../../shared/lib/meta-tags';
import PageTitle from '../../shared/components/PageTitle/PageTitle';

const topTexts = [
  {
    texts: [
      'These terms and conditions are applicable to Standard Account and Professional Account access to FlightStats By Cirium (“Service”) – Customer (meaning an entity or person listed on the sign up form) for the Standard and Professional Account access acknowledges and agrees to be bound by the terms and conditions of this FlightStats by Cirium Agreement (this “Agreement”) between Customer (also called “you” and “your”) and LNRS Data Services Inc (“Cirium”). If for any reason you do not agree to be bound by each and every term of this Agreement, you should exit the license process.',
    ],
  },
];

const bottomTexts = [
  {
    texts: [
      'I, A CUSTOMER USER AUTHORIZED TO LEGALLY BIND CUSTOMER TO THIS FLIGHTSTATS BY CIRIUM AGREEMENT, ACKNOWLEDGE THAT I HAVE READ THE FOREGOING TERMS OF THIS AGREEMENT AND I INDICATE THE INTENTION OF CUSTOMER TO BE LEGALLY BOUND BY THIS AGREEMENT.',
    ],
  },
];

const countItems = [
  {
    title: 'Your Access rights',
    texts: [
      'For the purposes of accessing or Standard and Professional Account on FlightStats By Cirium, Cirium grants you a non-exclusive, non-transferable, limited and terminable license to access and use the Cirium service (the “Services”) and the information provided therein (the “Cirium Data”), upon your acceptance of this Agreement. Individuals working as employees or contractors hired by Customer are called “Customer Users” in this Agreement.',
    ],
  },
  {
    title: 'Payment of Fees',
    texts: [
      'You agree that (i) you will fulfil your obligation to pay for the Services by the date on which payment is due; (ii) any payment information you provide is true and accurate; (iii) you are authorized to use the payment method you provide; (iv) Cirium has permission to retain the payment information and method submitted by you and your issuing bank; and (v) Cirium is authorized to charge you for the Services, including any applicable taxes, using the established payment method and the information you provide that is stored in your account as of the applicable date for payment.',
      <React.Fragment><strong>Changes in Fees.</strong> Cirium may change the price of any of its Services from time to time. Advance notice shall be provided in all cases.</React.Fragment>,
    ],
  },
  {
    title: 'Restrictions on Use',
    texts: [
      'You are not permitted to:',
    ],
    subtexts: [
      'Lend, rent, sell or grant sublicenses, leases or other rights to access the Services.',
      'Resell, sublicense, share, copy or otherwise transfer any part of the Cirium Data received through the Services with anyone else internally or externally within your organization or to any other third parties, unless permitted elsewhere.',
      'Cache and/or store Cirium Data received via the Cirium Services for a period longer than three (3) days.',
      'Use any real-time flight data included within Cirium Data in conjunction with or as a backfill or for authentication of data sourced from any other similar flight data provider, or for any benchmarking purposes, without the written permission of Cirium.',
      'Reverse engineer, disassemble, or decompile the Services or Cirium Data, any password or security device used within the Services, or make any attempt to discover the source code or scripts used to provide the Services.',
      'Modify the Services or the Cirium Data without the written permission of Cirium.',
      'Remove any Cirium or third party names, trademarks, copyright notices or other proprietary rights notices.',
      'Use the Services or the Cirium Data in any way that may violate rights of publicity or privacy of any individual whose personally identifiable data is retrieved.',
      'Abstract, download, store, reproduce, transmit, display, copy or use the Cirium Data other than as expressly permitted in the Agreement. ',
      'Use the Services or the Cirium Data for any passenger rights claims actions, for example actions pursuant to EU Regulation 261/2004.',
    ],
  },
  {
    title: 'Cirium Support',
    texts: [
      'Support for all Cirium Service will be provided by Cirium Help Desk.\nCustomer may submit email to Cirium Help Desk using designated support email address: helpdesk@cirium.com. Cirium Help Desk operates 24 hours a day 5 days a week (Monday - Friday) and shall acknowledge receipt of any support inquiry within one business day. The acknowledgement of receipt may also include suggestions for resolving the issue or an estimate of the time to resolve the issue.\nCirium neither provides any warranty for uninterrupted Service nor accuracy or correctness of the data provided as Cirium does not control both ends of these Services. Data timeliness, accuracy, quality, breadth, and depth vary by source and therefore Cirium disclaims all warranties (including support) thereof.',
    ],
  },
  {
    title: 'Term and Termination',
    texts: [
      <React.Fragment><strong>Term.</strong> The term of this Agreement shall commence on the date on which you complete the account sign-up process and complete a transaction to purchase a Standard or Professional Account. This Agreement shall remain in effect until such time that your Standard or Professional Account is terminated.</React.Fragment>,
      <React.Fragment><strong>Cirium’s Termination Rights.</strong> Cirium reserves the right to immediately suspend or terminate Customer’s use in whole or in part if, in Cirium’s sole judgment, use of the  Services by Customer or Customer Users (i) presents a material security or other risk or is likely to interfere materially with the proper continued operation of the Services or Cirium Data or related services; (ii) jeopardizes Cirium’s’’ relationships with its third-party data providers, or any other third party; (iii) violates applicable laws or governmental regulations, including without limitation consumer protection, securities regulation, child pornography, obscenity, data privacy, data transfer and telecommunications laws; (iv) violates or infringes any intellectual property right of Cirium or a third party; (v) violates export control regulations of the United States or other applicable countries; (vi) is subject to an order from a court or governmental entity stating that such use generally or for certain activities must stop; or (vii) otherwise violates this Agreement or any published Terms of Use Policy of Cirium. In the event that Customer disputes Cirium’s termination or suspension of the Services, each of Cirium and Customer agree to work on a good faith basis to resolve the dispute.</React.Fragment>,
      <React.Fragment><strong>Effect of Termination.</strong> Upon any termination or expiration of this Agreement for any reason the following shall occur: (i) Cirium shall cease to provide and Customer shall cease to access the Services; (ii) each party shall promptly discontinue the use of the other party’s service marks, trademarks, trade names, and logos and cease all marketing efforts relating to its obligations hereunder; and (iii) each party shall return all items containing Confidential Information to the other party, or at the disclosing party’s request destroy them, and certify in writing to the other party within thirty (30) days thereafter that all such information has been duly returned or destroyed.</React.Fragment>,
    ],
  },
  {
    title: 'Ownership and Use of Services and Cirium Data',
    texts: [
      <React.Fragment><strong>Ownership and Use of Services.</strong> Customer acknowledges the Services and documentation are owned by and shall remain the exclusive property of Cirium. No rights in the Services and documentation are granted or conveyed by Cirium other than the limited right to use them as set forth in this Agreement, and nothing in this Agreement will be deemed to grant a party any license, sublicense, copyright interest, proprietary right, or other claim against or interest in the other party’s copyrights, patents, trade secrets, or other intellectual property.</React.Fragment>,
      <React.Fragment><strong>Ownership and Use of Cirium Data.</strong> Customer acknowledges that all Cirium Data provided or available to Customer or Customer Users through the Services is owned either by Cirium or by Cirium’s’ third-party data and that neither Customer nor Customer Users shall acquire any ownership or interest whatsoever in such data.</React.Fragment>,
    ],
  },
  {
    title: 'Customer Support',
    texts: [
      'All costs associated with Customer’s accessing and supporting the Services shall be the sole responsibility of Customer. Customer will establish and enforce reasonable procedures to ensure that Customer can control and account for access to and use of the Services by Customer Users. Cirium may collect, retain, and use data and information regarding Customer and Customer Users who use the Service. Cirium may only use and disclose any personally identifiable information about Customer Users for Cirium’s own internal purposes and for the purpose of providing Services and other relevant information to the Customer.',
    ],
  },
  {
    title: 'Marketing',
    texts: [
      <React.Fragment><strong>Marketing by Cirium.</strong> Cirium shall obtain Customer’s prior consent to use Customer’s name, trademarks, or logos before performing any marketing activities related to this Agreement.</React.Fragment>,
      <React.Fragment><strong>Marketing by Customer.</strong> Customer shall obtain Cirium’s prior written consent to any marketing activities contemplating the use of Cirium’s name, service marks, trademarks, or logos.</React.Fragment>,
    ],
  },
  {
    title: 'Confidentiality',
    texts: [
      <React.Fragment><strong>Confidential Information.</strong> The parties acknowledge that, in the course of their dealings hereunder, each may acquire Confidential Information about the other. For the purposes of this Agreement, “Confidential Information” means any information in tangible form marked as such at the time presented to the other party, excluding information in the public domain or developed independently by the receiving party hereunder.</React.Fragment>,
      <React.Fragment><strong>Nondisclosure and Non-use.</strong> Each party agrees (i) that it will not disclose to any third party or use any Confidential Information disclosed to it by the other except as expressly permitted in this Agreement and (ii) that it will take all reasonable measures to maintain the confidentiality of all Confidential Information of the other party in its possession or control, which will in no event be less than the measures it uses to maintain the confidentiality of its own information of similar importance.</React.Fragment>,
      <React.Fragment><strong>Permitted Disclosures.</strong> Notwithstanding the foregoing, each party may disclose Confidential Information (i) to the extent required by a court of competent jurisdiction or other governmental authority or otherwise as required by law or (ii) on a “need-to-know” basis under an obligation of confidentiality to its legal counsel, accountants, banks, and other financing sources and their advisors.</React.Fragment>,
    ],
  },
  {
    title: 'Warranties, Disclaimer, Limitation of Liability',
    texts: [
      <React.Fragment><strong>Warranties.</strong> Each party warrants that (a) it has the full power and authority to enter into this Agreement and to perform the obligations contained in this Agreement, and (b) its entry into, and performance under this Agreement, will not violate any law, statue or regulation or result in a breach of any material agreement or understanding to which it is bound.</React.Fragment>,
      <React.Fragment><strong>No Performance Warranties.</strong> The Cirium Data available through the Services has been compiled from various sources, and Cirium disclaims all responsibility for checking and verifying that the Cirium Data is 100% accurate or correct or error free. Consequently, Cirium provides no performance warranties to Customer or to Customer Users, AND IN THE ABSENCE OF SUCH WARRANTIES CIRIUM SPECIFICALLY PROVIDES THE SERVICES AND CIRIUM DATA ON AN “AS IS” AND “AS AVAILABLE” BASIS.</React.Fragment>,
      <React.Fragment><strong>General Disclaimer.</strong> CIRIUM DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, QUALITY, AND FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NONINFRINGEMENT, AND ALL IMPLIED WARRANTIES ARISING FROM A COURSE OF DEALING, USAGE, OR TRADE PRACTICE. CIRIUM ASSUMES NO RESPONSIBILITY AND ACCEPTS NO LIABILITY FOR ANY FAILURE IN TELECOMMUNICATION LINES OR ELECTRIC POWER SOURCES CONNECTING CUSTOMER OR CUSTOMER USERS TO THE SERVICES. CIRIUM DOES NOT WARRANT THAT ACCESS TO, OR OPERATION OF, THE SERVICES OR THE CIRIUM DATA WILL BE UNINTERRUPTED OR ERROR-FREE OR THAT ANY ERRORS WILL BE CORRECTED.</React.Fragment>,
      <React.Fragment><strong>Limitation of Liability.</strong> IN NO EVENT SHALL CIRIUM, OR ITS OFFICERS, DIRECTORS, OR EMPLOYEES, BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES (INCLUDING DAMAGES FOR LOSS OF BUSINESS, LOSS OF PROFITS, OR THE LIKE), WHETHER BASED ON BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), BREACH OF WARRANTY, OR OTHERWISE, EVEN IF THE OTHER PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND EVEN IF A REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED ITS ESSENTIAL PURPOSE. THE AGGREGATE LIABILITY OF CIRIUM FOR ALL DAMAGES, INJURIES, AND LIABILITY INCURRED BY CUSTOMER, ANY CUSTOMER USER, AND ALL OTHER PARTIES IN CONNECTION WITH THIS AGREEMENT SHALL BE LIMITED TO AN AMOUNT NOT TO EXCEED US$1,000.</React.Fragment>,
      <React.Fragment><strong>Exclusive Remedy.</strong> In the event the s Services do not operate as represented, Cirium Will make commercially reasonable efforts to correct the problem upon receiving notice of it. If Cirium cannot reasonably remedy the problem, then Customer will be notified and may terminate this Agreement in accordance with the termination provisions below. This remedy is the sole remedy available to Customer.</React.Fragment>,
    ],
  },
  {
    title: 'Indemnity',
    texts: [
      <React.Fragment><strong>Cirium’s Indemnity of Customer.</strong> Cirium shall indemnify and defend Customer against any action, or costs resulting exclusively third party’s claim of patent, copyright or trademark infringement asserted against Customer in relation to the Cirium Service as provided to the Customer by Cirium (a “Claim”), provided that: (i) the Cirium Services has been used as provided by Cirium; (ii) Cirium is given prompt, written notice of any Claim; (iii) Cirium is given the right to control and direct the investigation, defence and settlement of each Claim; and (iv) the Customer reasonably cooperates with Cirium, at Cirium’s expense, in connection with the foregoing and makes no admission or offer of settlement without the prior written consent of Cirium.</React.Fragment>,
      <React.Fragment><strong>Customer Indemnity of Cirium.</strong> Customer shall indemnify, defend, and hold Cirium and its officers, directors, agents, and employees harmless against any action, judgment, liability, loss, cost, or damage (including attorneys’ fees) resulting from third-party claims arising exclusively from any violation by Customer or a Customer User of the restrictions set forth herein on use of the Services or Cirium Data contained therein.</React.Fragment>,
    ],
  },
  {
    title: 'Miscellaneous',
    texts: [
      <React.Fragment><strong>Entire Agreement.</strong> This Agreement is the complete and exclusive agreement between the parties with respect to the subject matter hereof, superseding any prior agreements and communications (both written and oral) regarding such subject matter.</React.Fragment>,
      <React.Fragment><strong>Modifications; Waiver and Amendments.</strong> Cirium may modify the terms of this Agreement from time to time. Cirium’s may alter, modify or substitute Company IDs, passwords, or any other device or method used for controlling access to the Services and the Cirium Data, at any time, without the consent of Customer or Customer Users, by giving reasonable notice to Customer of such change.</React.Fragment>,
      <React.Fragment><strong>Assignment.</strong> Customer may not assign or transfer its interest, rights or obligations in or under this Agreement by written agreement, merger, consolidation, operation of law, or otherwise, without Cirium’s prior written consent, which consent may be withheld for any reason. Any assignment by the Customer without consent shall be null and void. Cirium reserves the right to assess reasonable assignment fee.</React.Fragment>,
      <React.Fragment><strong>Governing Law.</strong> The validity, construction, and performance of this Agreement shall be governed by the laws of the state of Oregon, without reference to conflict of laws principles.</React.Fragment>,
      <React.Fragment><strong>Notices.</strong> Any notice under this Agreement will be in writing and delivered by a message accessible in the Customer’s account, by personal delivery, express courier, confirmed email, or certified or registered mail, return receipt requested. Notice will be deemed given (i) immediately upon delivery of a message accessible in the Customer’s account; (ii) one (1) day after deposit with express courier, upon confirmation of receipt of an email, or; (iii) five (5) days after deposit in the mail. Notices will be sent to a party at its contact address set forth in this Agreement or such other address as that party may specify in writing pursuant to this Section.</React.Fragment>,
      <React.Fragment><strong>Independent Contractors;</strong> No Agency. The parties are independent contractors and will have no power or authority to assume or create any obligation or responsibility on behalf of each other. Notwithstanding any public references to a “partnership”, this Agreement does not, and shall not be construed, to create or imply any partnership, agency, or joint venture.</React.Fragment>,
      <React.Fragment><strong>Force Majeure.</strong> Any delay in or failure of performance by either party under this Agreement will not be considered a breach of this Agreement and will be excused to the extent caused by any occurrence beyond the reasonable control of such party, including, but not limited to, acts of God, power outages, telecommunication system failures, and governmental restrictions.</React.Fragment>,
      <React.Fragment><strong>No Third-Party Beneficiaries.</strong> The provisions of this Agreement are intended solely for the benefit of Customer and shall create no rights or obligations enforceable by any other party unless such beneficiaries are expressly set forth in an amendment hereto.</React.Fragment>,
    ],
  },
];

const StrongText = ({ strong, texts }) => (
<React.Fragment>
  {strong && <strong>{strong}</strong>}
  {texts && texts.map((str, idx) => (
    <p key={mapKey('p', idx)} style={{ marginTop: idx === 0 ? 0 : '16px' }}>{str}</p>
  ))}
</React.Fragment>
);

 const mapKey = (str, idx) => `${str}-${idx}`;

@componentBase('TermsAndConditions')
export default class TermsAndConditions extends React.Component {

  metaTags = () => {
    const title = 'Subscription Agreement - Terms and Conditions – FlightStats';
    const description = 'See the Subscription Agreement - Terms and Conditions – FlightStats';
    const keywords = 'Terms and Conditions';
    const metaWithoutCanonical = {
      title,
      meta: [
        { name: 'description', content: description },
        { name: 'keywords', content: keywords },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
      ],
    };
    return addMetaCanonical(metaWithoutCanonical, this.props.match.path);
  }

  render = () => {
    const sectionStyle = {
    };

    const meta = this.metaTags();

    return (
      <section style={sectionStyle}>
        <Helmet {...meta} />
        <div className="subscribeTnc" style={{   width: '100%' }}>
          <div>
            <PageTitle  titleText="FlightStats Subscription Agreement – Terms and Conditions" />

            <div style={{ color: 'white', fontSize: '12pt', marginTop: '16px', lineHeight:'20px' }}>
              {
                topTexts.map((topProps, idx) => (
                  <StrongText key={mapKey('topStrong', idx)} {...topProps} />
                ))
              }

<ol style={{ marginTop: 0, paddingLeft: '15px' }} type='1'>
          {
            countItems.map(({ title, texts, subtexts }, idx) => (
              <li key={mapKey('li', idx)}>
                <strong>{title}</strong>
                <ol type='a'>
                  {
                    texts.map((text, idx2) => (
                      <li key={mapKey('li-i', idx2)}>
                        <p style={{ marginTop: idx2 === 0 ? 0 : '16px' }}>{text}</p>
                        {
                          subtexts &&
                          <ol type='i'>
                            {subtexts.map((subtext, idx3) => (
                              <li key={mapKey('li-i', idx3)}>
                                <p style={{ marginTop: idx3 === 0 ? 0 : '16px' }}>{subtext}</p>
                              </li>
                            ))
                            }
                          </ol>
                        }
                      </li>
                    ))
                  }
                </ol>
              </li>
            ))
          }
        </ol>
        {
          bottomTexts.map((bottomProps, idx) => (
            <StrongText key={mapKey('bottomStrong', idx)} {...bottomProps} />
          ))
        }
       
          </div>
          </div>
        </div>
      </section>
    );
  }
}
