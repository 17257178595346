import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { SelectField } from '@flightstats/component-lib';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { identity } from 'ramda';

import FsCard from '../../../../src/components/lib/FsSection';
import FSFlatButton from '../../../../src/components/lib/FSFlatButton';
import defaultContextTypes from '../../../../src/components/default-context-types';
import componentBase from '../../../../src/lib/component-base';
import AirportSelect from '../../../../src/components/lib/AirportSelect';
import { getDateMenuItems } from '../../lib/deparr-helpers';
import {
  setDepArrDateFieldIndex,
  setDepArrAirportOptions,
  setSecondaryAction,
} from '../../redux/actions';
import { addMetaCanonical } from '../../../../shared/lib/meta-tags';

@connect(state => ({
  searchAirport: state.AirportDeparturesArrivals.searchAirport,
  dateFieldIndex: state.AirportDeparturesArrivals.dateFieldIndex,
  isArrival: state.AirportDeparturesArrivals.isArrival,
  isDeparture: state.AirportDeparturesArrivals.isDeparture,
  secondaryAction: state.AirportDeparturesArrivals.secondaryAction,
  user: state.Account.user,
}))
@componentBase('DeparturesArrivalsLanding')
export default class DeparturesArrivalsLanding extends Component {
  static propTypes = {
    searchAirport: PropTypes.object,
    dateFieldIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dispatch: PropTypes.func,
    isDeparture: PropTypes.bool,
    isArrival: PropTypes.bool,
    match: PropTypes.object,
    user: PropTypes.object,
    location: PropTypes.object,
    secondaryAction: PropTypes.bool,
  };
  static contextTypes = defaultContextTypes;

  constructor(props, context) {
    super(props, context);
    this.state = {
      errText: '',
    };
  }

  componentWillReceiveProps(nextProps) {
    const { dispatch, secondaryAction } = nextProps;
    if (secondaryAction) {
      // on enter keypress, go to results once any async data has loaded
      dispatch(setSecondaryAction(false));
    }
  }

  getMetaTags = () => {
    const title = 'Airport Departures and Arrivals';
    const description = 'Check flight departures and arrivals for airports worldwide';
    const keywords = 'airport departures, airport arrivals, departures, arrivals';
    const metaWithoutCanonical = {
      title,
      meta: [
        { name: 'description', content: description },
        { name: 'keywords', content: keywords },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
      ],
    };
    const { location } = this.props;
    const pathname = location && location.pathname;
    return addMetaCanonical(metaWithoutCanonical, pathname);
  };

  getDateMenuItems = () => {
    if (!this.dateMenuItems) {
      this.dateMenuItems = getDateMenuItems(this.props.user);
    }
    return this.dateMenuItems;
  };

  submit = (direction) => {
    if (this.props.searchAirport) {
      const {
        searchAirport,
        dateFieldIndex,
      } = this.props;
      /* eslint-disable no-underscore-dangle */
      const airportCode = searchAirport &&
                          searchAirport.value &&
                          searchAirport.value._source &&
                          searchAirport.value._source.fs;
      /* eslint-disable no-underscore-dangle */

      const selectedDate = this.getDateMenuItems()[dateFieldIndex];
      const url = `/airports/departing-arriving/${airportCode}/${direction}/${selectedDate.momentDate.format('YYYY/MM/DD')}`;
      this.props.dispatch(push(url));
    } else {
      // Show alert
      this.setState({ errText: 'Please enter a valid airport name or code' });
    }
  };

  handleDeparturesClick = () => {
    this.context.reportButtonPress('Departures');
    this.submit('departures');
  };

  handleArrivalsClick = () => {
    this.context.reportButtonPress('Arrivals');
    this.submit('arrivals');
  };

  handleDateChange = (option) => {
    const { value } = option;
    if (value) {
      this.props.dispatch(setDepArrDateFieldIndex(value));
    }
  };

  render() {
    const { dateFieldIndex } = this.props;
    const dateMenuItems = this.getDateMenuItems();
    const dateValue = [dateFieldIndex]
      .map(item => item || 1)
      .map(v => v.toString())
      .reduce(o => o);

    const sectionStyle = {
      marginTop: '50px',
      height: 'auto',
    };

    let errorText = '';

    if (this.state.errText) {
      errorText = (
        <div className='search-error-text-block'>
          <p className='search-error-text'>{this.state.errText}</p>
        </div>
      );
    }

    const meta = this.getMetaTags();

    return (
      <FsCard className='depArrLanding'>
        <Helmet {...meta} />
        <section style={sectionStyle}>
          <div style={{ position: 'relative', margin: '0 auto', width: '100%' }}>
            <div className='fs-search'>
              <h1 style={{ fontSize: '28pt', fontWeight: 'strong' }}>Departures and Arrivals</h1>
              <form onSubmit={this.handleSubmit}>
                <p className='landing-text'>Enter your airport and date</p>
                {errorText}
                <div className='transparentDepArrPicker'>
                  <AirportSelect
                    className='depArrAirportSelect'
                    name='airport'
                    inputTitle='Enter your Airport'
                    airport={this.props.searchAirport}
                    shouldFocus
                    onChange={(option, triggeredByEnter) => (
                      this.props.dispatch(setDepArrAirportOptions({
                        searchAirport: option,
                        isArrival: false,
                        isDeparture: false,
                        triggeredByEnter,
                      }))
                    )}
                    secondaryAction={
                      this.props.secondaryAction ?
                        this.handleDeparturesClick
                        /*
                          this.handleArrivalsClick
                          (if enter keydown should trigger an arrivals search instead)
                        */:
                        identity
                    }
                  />
                </div>
                <div className='transparentDatepicker' style={{ marginTop: '10px', position: 'relative' }}>
                  <SelectField
                    value={dateValue}
                    results={dateMenuItems}
                    searchable={false}
                    handleMenuSelection={this.handleDateChange}
                    bgColor='rgba(192, 192, 192, 0.66)'
                    inputFieldBg={false}
                    iconColor='white'
                    placeholderColor='white'
                    textColor='white'
                  />
                </div>
                <div style={{ marginTop: '10px' }}>
                  <div className='col-lg-6 col-md-6 col-sm-6' style={{ padding: '10px 0', textAlign: 'center' }}>
                    <FSFlatButton
                      disableTouchRipple
                      label='Search Departures'
                      onClick={this.handleDeparturesClick}
                      style={FSFlatButton.style({ margin: null, minWidth: null })}
                    />
                  </div>
                  <div className='col-lg-6 col-md-6 col-sm-6' style={{ padding: '10px 0', textAlign: 'center' }}>
                    <FSFlatButton
                      disableTouchRipple
                      className='arrivals'
                      label='Search Arrivals'
                      onClick={this.handleArrivalsClick}
                      style={FSFlatButton.style({ margin: null, minWidth: null, float: null })}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </FsCard>
    );
  }
}
