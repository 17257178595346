import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ifElse, pathOr, path, pathSatisfies } from 'ramda';
import {
  loadByDepartureAirportCarrierAndDate,
  loadByArrivingAirportCarrierAndDate,
  loadByRouteCarrierAndDate,
  setMultiFlightFlights,
  setMultiFlightResultsSearchError,
  setMultiFlightResultsSearchAirlineInput,
  setValidationError,
  updateMultiFlightSearchHasMounted,
  updateMultiFlightSearchCodeShareToggle,
} from './redux/actions';
import FlightTrackerMultiFlightSearch from './children/FlightTrackerMultiFlightSearch/FlightTrackerMultiFlightSearch';
import FlightTrackerMultiFlightResults from './children/FlightTrackerMultiFlightResults/FlightTrackerMultiFlightResults';
import MultiFlightSearchMobileAd from './children/FlightTrackerMultiFlightSearch/children/MultiFlightSearchMobileAd/MultiFlightSearchMobileAd';
import componentBase from '../../src/lib/component-base';
import Meta from '../../shared/components/Meta/container';
import MultiFlightSearchFooter from './children/FlightTrackerMultiFlightSearch/children/MultiFlightSearchFooter/MultiFlightSearchFooter';
import { resultsAd } from './ads';
import { formattedDate } from '../../src/lib/date-time-format';
import flexErrors from '../../src/constants/flex-errors';
import { resolveCanonicalFromPathname } from '../App/redux/selectors';
import OutOfDateRange from '../SingleFlight/children/FlightTracker/children/OutOfDateRange/OutOfDateRange';
import { setFlightTrackerOutOfDateRange } from '../SingleFlight/redux/actions';
import { PageHeader } from '../../shared/components/PageHeader/PageHeader';

@connect(state => ({
  canonical: resolveCanonicalFromPathname(state),
  byAirportAirline: state.FlightTrackerSearch.byAirportAirline,
  flightNumber: state.FlightTrackerSearch.flightNumber,
  departureAirport: state.FlightTrackerSearch.departureAirport,
  arrivalAirport: state.FlightTrackerSearch.arrivalAirport,
  departureDate: state.FlightTrackerSearch.departureDate,
  departureTime: state.FlightTrackerSearch.departureTime,
  error: state.MultiFlightTracker.error,
  flights: state.MultiFlightTracker.data,
  flightTrackerOutOfDateRange: state.SingleFlightTracker.flightTrackerOutOfDateRange,
  isMobile: state.App.userAgent.isMobile,
  loading: state.MultiFlightTracker.loading,
  loaded: state.MultiFlightTracker.loaded,
  multiFlightSearchDate: state.MultiFlightTracker.multiFlightSearchDate,
  multiFlightSearchTime: state.MultiFlightTracker.multiFlightSearchTime,
  multiFlightSearchCodeShareToggle: state.MultiFlightTracker.multiFlightSearchCodeShareToggle,
  multiFlightMobileSearchDepartureTime:
  state.MultiFlightTracker.multiFlightMobileSearchDepartureTime,
  multiFlightMobileSearchLoading: state.MultiFlightTracker.multiFlightMobileSearchLoading,
  multiFlightSearchHasMounted: state.MultiFlightTracker.multiFlightSearchHasMounted,
  multiFlightResultsSearchError: state.MultiFlightTracker.multiFlightResultsSearchError,
  multiFlightResultsAirlineSelectValue:
    state.MultiFlightTracker.multiFlightResultsAirlineSelectValue,
  pageIndex: state.MultiFlightTracker.pageIndex,
  searchUrl: state.MultiFlightTracker.searchUrl,
  secondaryAdvancedSearchActionTime:
  state.FlightTrackerSearch.secondaryAdvancedSearchActionTime,
  user: state.Account.user,
  validationError: state.MultiFlightTracker.validationError,
  variant: state.App.variant,
}))
@componentBase('MultiFlight')
export default class MultiFlight extends React.Component {
  static propTypes = {
    byAirportAirline: PropTypes.object,
    error: PropTypes.object,
    canonical: PropTypes.string,
    dispatch: PropTypes.func,
    flights: PropTypes.object,
    flightTrackerOutOfDateRange: PropTypes.bool,
    isMobile: PropTypes.bool,
    location: PropTypes.object,
    loaded: PropTypes.bool,
    loading: PropTypes.bool,
    match: PropTypes.object,
    multiFlightMobileSearchDepartureTime: PropTypes.number,
    multiFlightMobileSearchLoading: PropTypes.bool,
    multiFlightSearchHasMounted: PropTypes.bool,
    searchUrl: PropTypes.string,
    multiFlightSearchDate: PropTypes.number,
    multiFlightSearchTime: PropTypes.number,
    multiFlightSearchCodeShareToggle: PropTypes.bool,
    multiFlightResultsSearchError: PropTypes.string,
    multiFlightResultsAirlineSelectValue: PropTypes.string,
    user: PropTypes.object,
    validationError: PropTypes.bool,
    variant: PropTypes.string,
  };

  componentWillMount() {
    const { error, flights } = this.props;
    if (!flights && !error) {
      this.load();
    }
  }


  componentWillReceiveProps(nextProps) {
    const thisMatch = this.props.match;
    const nextMatch = nextProps.match;
    if (thisMatch.params.carrier !== nextMatch.params.carrier) {
      this.load(nextProps);
    }
  }

  componentWillUpdate(nextProps) {
    const {
      dispatch,
      error,
    } = nextProps;
    if (!this.props.error && nextProps.error) {
      dispatch(flexErrors(error.code));
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(setMultiFlightResultsSearchError(''));
    dispatch(setMultiFlightResultsSearchAirlineInput(''));
    dispatch(setMultiFlightFlights());
    dispatch(setFlightTrackerOutOfDateRange(false));
    dispatch(setValidationError(false));
  }

  load(nextProps) {
    const props = nextProps || this.props;
    const {
      dispatch,
      match: { params },
      multiFlightSearchHasMounted,
      location: { search: queryString },
    } = props;
    const {
      arrivalAirportCode,
      departureAirportCode,
    } = params;
    if (arrivalAirportCode && departureAirportCode) {
      return dispatch(loadByRouteCarrierAndDate({ ...params, queryString }));
    }
    if (arrivalAirportCode) {
      return dispatch(loadByArrivingAirportCarrierAndDate({ ...params, queryString }));
    }

    dispatch(loadByDepartureAirportCarrierAndDate({ ...params, queryString }));

    const hideCodeshares = pathOr(false, ['user', 'hideCodeshares'], this.props);

    if (!multiFlightSearchHasMounted) {
      dispatch(updateMultiFlightSearchCodeShareToggle(hideCodeshares));
    }
    dispatch(updateMultiFlightSearchHasMounted(true));
  }

  createErrorText = (results, match, validationError) => {
    if (path(['flights', 'length'], results)) {
      return '';
    }

    const tryAgain = 'Please try again.';

    if (validationError) {
      return (
        <Fragment>
          {`Invalid search parameters. ${tryAgain}`}
        </Fragment>
      );
    }

    // only create error when no flights
    const arrivalAirport = path(['header', 'arrivalAirport'], results);
    const departureAirport = path(['header', 'departureAirport'], results);
    const carrier = path(['params', 'carrier'], match);
    const carrierFullName = path(['header', 'carrierFullName'], results);
    const carrierCodeAndFullName = `${carrier ? `(${carrier}) ` : ''}${carrierFullName ? `${carrierFullName} ` : ''}`;

    // route searches
    if (arrivalAirport && departureAirport) {
      return (
        <Fragment>
          {'No '}
          <span className='bold'>
            {carrierCodeAndFullName}
          </span>
          {'flights were found departing from '}
          <span className='bold'>
            {departureAirport.name}
          </span>
          {' and arriving in '}
          <span className='bold'>
            {arrivalAirport.name}
          </span>
          {` at the given time period. ${tryAgain}`}
        </Fragment>
      );
    } else if (departureAirport) { // departures
      return (
        <Fragment>
          {'No '}
          <span className='bold'>
            {carrierCodeAndFullName}
          </span>
          {'flights were found departing from '}
          <span className='bold'>
            {departureAirport.name}
          </span>
          {` at the specified time period. ${tryAgain}`}
        </Fragment>
      );
    } else if (arrivalAirport) { // arrivals
      return (
        <Fragment>
          {'No '}
          <span className='bold'>
            {carrierCodeAndFullName}
          </span>
          {'flights were found arriving in '}
          <span className='bold'>
            {arrivalAirport.name}
          </span>
          {` at the specified time period. ${tryAgain}`}
        </Fragment>
      );
    }
  }

  removeCodeshares = results => results && ({
    ...results,
    flights: results.flights.filter((f) => {
      if (f.operatedBy) {
        const [, behalf] = f.operatedBy.split('on behalf of ');
        return behalf && behalf.toLowerCase() === f.carrier.name.toLowerCase();
      }
      return (f && !f.isCodeshare);
    }),
  });

  reportUserEvent = (action, label) => {
    this.context.reportUserEvent(action, label);
  }

  formatMetaByRoute = ({
    arrivalAirportFs,
    arrivalAirportName,
    departureAirportFs,
    departureAirportName,
  }) => {
    const title = `${departureAirportFs}-${arrivalAirportFs} Flight Status`;
    const nameAndSpace = name => (name ? `${name} ` : '');
    const description = [
      `(${departureAirportFs} to ${arrivalAirportFs}) `,
      `Track the current status of flights departing from (${departureAirportFs}) `, `${nameAndSpace(departureAirportName)}and arriving in `,
      `(${arrivalAirportFs}) ${nameAndSpace(arrivalAirportName)}`,
    ].join('').trim();
    const keywords = `flight status, ${departureAirportFs} to ${arrivalAirportFs}, route status`;
    return {
      description,
      keywords,
      title,
    };
  }

  formatMetaByDepArr = ({
    arrivalAirportCity,
    arrivalAirportFs,
    arrivalAirportName,
    departureAirportCity,
    departureAirportFs,
    departureAirportName,
  }) => {
    const airportFs = departureAirportFs || arrivalAirportFs;
    const airportName = departureAirportName || arrivalAirportName;
    const airportCity = departureAirportCity || arrivalAirportCity;
    const airportCodeAndDepArr = `${airportFs} ${departureAirportFs ? 'Departures' : 'Arrivals'}`;
    const title = [
      `(${airportCodeAndDepArr}) ${airportName} `,
      `${departureAirportFs ? 'Departures' : 'Arrivals'}`,
    ].join('');
    const parensCode = str => (str ? `(${str})` : '');
    const description = [
      `(${airportCodeAndDepArr}) Track the current status of flights `,
      `${departureAirportFs ? 'departing from' : 'arriving at'} `,
      `${parensCode(airportFs)} `,
      `${airportName} using FlightStats flight tracker`.trim(),
    ].join('');
    const keywordsDepArr = departureAirportFs ? 'departures' : 'arrivals';
    const keywords = [
      `${airportFs} ${keywordsDepArr}, `,
      `${airportName} flight ${keywordsDepArr}, `,
      `${airportCity} flight ${keywordsDepArr}, `,
      `${airportCity} airport flight ${keywordsDepArr}`,
    ].join('');
    return {
      description,
      keywords,
      title,
      iOSDeepLink: true,
    };
  }

  formatMeta = () => {
    const [
      arrivalAirportFs,
      departureAirportFs,
    ] = ['arrivalAirportCode', 'departureAirportCode']
      .map(k => pathOr('', ['match', 'params', k], this.props).toUpperCase());

    const [
      [departureAirportName, departureAirportCity],
      [arrivalAirportName, arrivalAirportCity],
    ] = ['departureAirport', 'arrivalAirport']
      .map(o => ['name', 'city'].map(k => pathOr('', ['flights', 'header', o, k], this.props)));

    const params = {
      arrivalAirportCity,
      arrivalAirportFs,
      arrivalAirportName,
      departureAirportCity,
      departureAirportFs,
      departureAirportName,
    };
    const metaParams = ifElse(
      () => departureAirportFs && arrivalAirportFs,
      () => this.formatMetaByRoute(params),
      () => this.formatMetaByDepArr(params),
    );
    return metaParams();
  }

  mobileScreen = () => [path(['props', 'isMobile'], this),
    pathSatisfies(val => val && val < 769, ['context', 'mediaBreakpoint', 'value'], this)]
    .some(val => val)

  render() {
    const {
      canonical,
      flights,
      flightTrackerOutOfDateRange,
      match,
      multiFlightSearchCodeShareToggle,
      user,
      validationError,
      variant,
    } = this.props;
    const hasAtLeastAStandardSubscription = !!pathOr(0, ['user', 'subscriptionLevel'], this.props);
    const isMobileScreen = this.mobileScreen();
    const results = multiFlightSearchCodeShareToggle ? this.removeCodeshares(flights) : flights;
    const header = results && results.header;
    const date = formattedDate(user, header, 'date', 'dateMDY');
    const errorText = this.createErrorText(results, match, validationError);
    const { description, keywords, title, iOSDeepLink } = this.formatMeta();

    if (flightTrackerOutOfDateRange) {
      // out of date range (before minus three days)
      const subscriptionActive = pathOr(false, ['subscriptionActive'], user);
      return (
        <Fragment>
          <Meta
            canonical={canonical}
            description={description}
            keywords={keywords}
            match={match}
            title={title}
          />
          <OutOfDateRange
            airline={{}}
            match={match}
            subscriptionActive={subscriptionActive}
          />
        </Fragment>
      );
    }

    return (
      <div className='container'>
        <Meta
          canonical={canonical}
          description={description}
          keywords={keywords}
          match={match}
          title={title}
          iOSDeepLink={iOSDeepLink}
        />
        {
          header &&
          (
            <PageHeader
              isMobile={isMobileScreen}
              title={header.title}
              altTitle={header.mobileTitle}
              secondaryText={date}
            />
          )
        }
        <FlightTrackerMultiFlightSearch
          {...this.props}
          headerDate={date}
          hasAtLeastAStandardSubscription={hasAtLeastAStandardSubscription}
          resultsTopAd={resultsAd}
          isMobileScreen={isMobileScreen}
          reportUserEvent={this.reportUserEvent}
        />
        <FlightTrackerMultiFlightResults
          {...this.props}
          errorText={errorText}
          header={header}
          date={date}
          results={results}
          variant={variant}
        />
        <MultiFlightSearchFooter
          {...this.props}
          desktop={!isMobileScreen}
          mobile={isMobileScreen}
          hide={!hasAtLeastAStandardSubscription}
        />
        {isMobileScreen && (
          <MultiFlightSearchMobileAd
            {...this.props}
            hasAtLeastAStandardSubscription={hasAtLeastAStandardSubscription}
            resultsBottomAd={resultsAd}
          />
        )}
      </div>
    );
  }
}
