import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ifElse, path, pathOr } from 'ramda';
import { filters } from 'dc';
import {
  alphaNumRegexRegulator,
  createDimensionMenuItems,
  floorTimeToBinSize,
  getQueryParams,
  menuItemsToArray,
  resetAllMenuItems,
  timespanValueLabel,
  unAlphaNumRegexRegulator,
} from './children/_helpers';
import constants from './children/_constants';
import componentBase from '../../../../../src/lib/component-base';
import { prefersAMPMTimeFormat } from '../../../../../src/lib/date-time-format';

import ActiveFilters from './children/ActiveFilters';
import FilterMenus from './children/FilterMenus';
import ListWrapper from './children/ListWrapper';
import OnTimePerformance from './children/OnTimePerformance';
import Chart from './children/Chart';

const getAirlineFilter = pathOr(false, ['filterSelectedValues', 'airline']);
const codeshare = ifElse(
  p => path(['user', 'hideCodeshares'], p),
  () => 'hide',
  () => 'show',
);

@componentBase('Dimensions')
class Dimensions extends React.Component {
  static propTypes = {
    depArr: PropTypes.string,
    clonedList: PropTypes.array,
    crossfilter: PropTypes.object,
    basicInfo: PropTypes.object,
    match: PropTypes.object,
    params: PropTypes.object,
    user: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    const destOrigin = (props.depArr === 'departures' ? 'Destination' : 'Origin');

    const filterSelectedValues = getQueryParams(props);
    filterSelectedValues.codeshare = codeshare(props);

    this.state = {
      currentTimespanKey: null,
      items: {},
      menuItems: {},
      chartWasFiltered: false,
      filterSelectedValues,
      dimensions: null,
      destOrigin,
    };
  }

  componentDidMount() {
    this.state.dimensions = {};

    this.state.items = {
      statuses: {
        Arrived: {
          checked: false,
          label: 'Arrived',
          disabled: true,
        },
        Scheduled: {
          checked: false,
          label: 'Scheduled',
          disabled: true,
        },
        Cancelled: {
          checked: false,
          label: 'Cancelled',
          disabled: true,
        },
        Unknown: {
          checked: false,
          label: 'Unknown',
          disabled: true,
        },
        Diverted: {
          checked: false,
          label: 'Diverted',
          disabled: true,
        },
        Departed: {
          checked: false,
          label: 'Departed',
          disabled: true,
        },
      },
      delays: {
        'Delayed 15-29': {
          checked: false,
          label: 'Delayed 15-29',
          disabled: true,
        },
        'Delayed 30-45': {
          checked: false,
          label: 'Delayed 30-45',
          disabled: true,
        },
        'Delayed 45+': {
          checked: false,
          label: 'Delayed 45+',
          disabled: true,
        },
        'On-time': {
          checked: false,
          label: 'On-time',
          disabled: true,
        },
      },
      flights: {},
      airlineCodes: {},
      airportCodes: {},
      terminals: {},
      gates: {},
      equipments: {},
      baggages: {},
    };

    this.props.clonedList.forEach((f) => {
      // f.inChart = !f.isCodeshare;
      this.state.items.flights[f.flightId] = f;
      // ERROR: f.delay is sometimes undefined...
      if (this.state.items.delays[f.delay]) {
        this.state.items.delays[f.delay].disabled = false;
      }
      this.state.items.statuses[f.status].disabled = false;
      this.state.items.airlineCodes[f.carrierFs] = {
        disabled: false,
        checked: false,
        label: `(${f.carrierFs}) ${f.airline}`,
      };
      this.state.items.airportCodes[f.airportIata] = {
        disabled: false,
        checked: false,
        label: `(${f.airportIata}) ${f.airportName}`,
      };
      this.state.items.terminals[f.terminal] = {
        disabled: false,
        checked: false,
        label: f.terminal,
      };
      this.state.items.gates[f.gate] = {
        disabled: false,
        checked: false,
        label: f.gate,
        sortKey: alphaNumRegexRegulator(f.gate),
      };
      this.state.items.equipments[f.equipmentIata] = {
        disabled: false,
        checked: false,
        label: f.equipmentIata,
        sortKey: alphaNumRegexRegulator(f.equipmentIata),
      };
      this.state.items.baggages[f.baggageClaim] = {
        disabled: false,
        checked: false,
        label: f.baggageClaim,
        sortKey: alphaNumRegexRegulator(f.baggageClaim),
      };
    });

    // Dimensions for filtering
    this.state.dimensions.flightsByAirlineCode = this.props.crossfilter.dimension(d => d.carrierFs);
    this.state.dimensions.flightsByDestOriginAirportCode = this.props.crossfilter.dimension(d => d.airportIata);
    this.state.dimensions.flightsByStatus = this.props.crossfilter.dimension(d => d.status);
    this.state.dimensions.flightsByTerminal = this.props.crossfilter.dimension(d => d.terminal);
    this.state.dimensions.flightsByGate = this.props.crossfilter.dimension(d => alphaNumRegexRegulator(d.gate));
    this.state.dimensions.flightsByEquipment = this.props.crossfilter.dimension(d => alphaNumRegexRegulator(d.equipmentIata));
    this.state.dimensions.flightsByBaggage = this.props.crossfilter.dimension(d => alphaNumRegexRegulator(d.baggageClaim));
    this.state.dimensions.flightsByDelay = this.props.crossfilter.dimension(d => (d.delay ? d.delay : ''));
    this.state.dimensions.flightsByCodeshare = this.props.crossfilter.dimension(d => d.isCodeshare);

    // Lists for sorting
    this.state.dimensions.flightsByFlightCode = this.props.crossfilter.dimension(d => alphaNumRegexRegulator(d.flight));

    this.state.dimensions.flightsByScheduledGate = this.props.crossfilter.dimension((d) => {
      if (this.props.depArr === 'departures') {
        return _unixTimeToSortBy(d.operationalTimes.scheduledGateDeparture);
      }
      return _unixTimeToSortBy(d.operationalTimes.scheduledGateArrival);
    });

    this.state.dimensions.flightsByActualGate = this.props.crossfilter.dimension((d) => {
      if (this.props.depArr === 'departures') {
        return _unixTimeToSortBy(d.operationalTimes.actualGateDeparture || d.operationalTimes.estimatedGateDeparture);
      }
      return _unixTimeToSortBy(d.operationalTimes.actualGateArrival || d.operationalTimes.estimatedGateArrival);
    });

    this.state.dimensions.flightsByScheduledRunway = this.props.crossfilter.dimension((d) => {
      if (this.props.depArr === 'departures') {
        return _unixTimeToSortBy(d.operationalTimes.flightPlanPlannedDeparture);
      }
      return _unixTimeToSortBy(d.operationalTimes.flightPlanPlannedArrival);
    });

    this.state.dimensions.flightsByActualRunway = this.props.crossfilter.dimension((d) => {
      if (this.props.depArr === 'departures') {
        return _unixTimeToSortBy(d.operationalTimes.actualRunwayDeparture || d.operationalTimes.estimatedRunwayDeparture);
      }
      return _unixTimeToSortBy(d.operationalTimes.actualRunwayArrival || d.operationalTimes.estimatedRunwayArrival);
    });

    this.state.dimensions.flightsByTimespan = this.props.crossfilter.dimension((d) => {
      const times = d.operationalTimes;
      /*
        Add a property to each flight of what time we're using to timespan filter by so we can check if that time is within
        range when counting OTP flights.
      */
      let timeBin = null;
      if (this.props.depArr === 'departures') {
        if (times.scheduledGateDeparture) {
          timeBin = floorTimeToBinSize(times.scheduledGateDeparture.dateLocal);
        } else if (times.estimatedGateDeparture) {
          timeBin = floorTimeToBinSize(times.estimatedGateDeparture.dateLocal);
        } else if (times.scheduledRunwayDeparture) {
          timeBin = floorTimeToBinSize(times.scheduledRunwayDeparture.dateLocal);
        } else if (times.estimatedRunwayDeparture) {
          timeBin = floorTimeToBinSize(times.estimatedRunwayDeparture.dateLocal);
        } else if (times.publishedDeparture) {
          timeBin = floorTimeToBinSize(times.publishedDeparture.dateLocal);
        } else if (times.flightPlanPlannedDeparture) {
          timeBin = floorTimeToBinSize(times.flightPlanPlannedDeparture.dateLocal);
        } else if (times.actualRunwayDeparture) {
          timeBin = floorTimeToBinSize(times.actualRunwayDeparture.dateLocal);
        } else {
          console.log('no valid time found', d);
        }
      } else if (times.scheduledGateArrival) {
        timeBin = floorTimeToBinSize(times.scheduledGateArrival.dateLocal);
      } else if (times.estimatedGateArrival) {
        timeBin = floorTimeToBinSize(times.estimatedGateArrival.dateLocal);
      } else if (times.scheduledRunwayArrival) {
        timeBin = floorTimeToBinSize(times.scheduledRunwayArrival.dateLocal);
      } else if (times.estimatedRunwayArrival) {
        timeBin = floorTimeToBinSize(times.estimatedRunwayArrival.dateLocal);
      } else if (times.publishedArrival) {
        timeBin = floorTimeToBinSize(times.publishedArrival.dateLocal);
      } else if (times.flightPlanPlannedArrival) {
        timeBin = floorTimeToBinSize(times.flightPlanPlannedDeparture.dateLocal);
      } else if (times.actualRunwayArrival) {
        timeBin = floorTimeToBinSize(times.actualRunwayArrival.dateLocal);
      } else {
        console.log('no valid time found', d);
      }
      if (this.state.items.flights[d.flightId]) this.state.items.flights[d.flightId].timeBin = timeBin.valueOf();
      return timeBin;
    });

    this.state.dimensions.group = this.state.dimensions.flightsByTimespan.group().reduce(
      (prev, v) => {
        const p = prev;
        // We only want to add flights flagged as NOT codeshares
        // This way we don't show duplicates
        if (this._shouldAddFlightToChart(v)) {
          ++p.total;

          if (v.rowColor === 'red') {
            ++p.cancelledCount;
          } else if (v.rowColor === 'yellow') {
            ++p.delayedCount;
          } else {
            ++p.onTimeCount;
          }
        }
        return p;
      },
      (prevVal, v) => {
        const pr = prevVal;
        // When we remove flights we want to check if we are currently
        // filtering by a codeshare. We do NOT want to remove a flight
        // that matches a current carrier filter value.
        if (this._shouldRemoveFlightFromChart(v)) {
          --pr.total;

          if (v.rowColor === 'red') {
            --pr.cancelledCount;
          } else if (v.rowColor === 'yellow') {
            --pr.delayedCount;
          } else {
            --pr.onTimeCount;
          }
        }

        return pr;
      },
      () => ({
        onTimeCount: 0,
        delayedCount: 0,
        cancelledCount: 0,
        total: 0,
      }),
    );

    this._defaultTimespanLabels = {
      [constants.ALL_DAY_LABEL]: true,
    };

    this.state.items.timespans = {
      Custom: {
        value: 'Custom',
        label: 'Custom',
        key: 'Custom',
        fnc: null,
        disabled: true,
        checked: false,
      },
    };

    const addTimespanItem = (startTime, endTime) => {
      // TODO: this.props.basicInfo.localTime is not a currently real prop, changed to local time of computer
      // const now = moment(this.props.basicInfo.localTime).local();
      const now = moment().local();
      const label = timespanValueLabel(startTime, endTime, prefersAMPMTimeFormat(this.props.user));
      const itemObj = {
        value: label,
        label,
        key: label,
        rangeFilter: filters.RangedFilter(startTime.toDate().getTime(), endTime.toDate().getTime()),
        fnc: () => {
          this.state.chartWasFiltered = true;
          this._filterChart(null);
          return this._filterChart(filters.RangedFilter(startTime.toDate().getTime(), endTime.toDate().getTime()));
        },
        disabled: false,
        checked: false,
      };

      if (now.hours() <= endTime.hours() && now.hours() >= startTime.hours() && this.state.currentTimespanKey == null) {
        this.state.currentTimespanKey = label;
        this._defaultTimespanKey = label;
      }
      this._defaultTimespanLabels[label] = true;
      this.state.items.timespans[label] = itemObj;
    };

    const addTimespanItems = (binSize, binCount, includeMorningBin) => {
      let start = this._beginningOfDay();
      let end = this._beginningOfDay().clone().local();
      if (includeMorningBin) {
        end.add(6 * 60 - 1, 'm');
        addTimespanItem(start, end);
      }

      // Special case for small airports that only have 1 bin and needs to display all day
      if (binCount === 1) {
        start = start.clone().local();
        end = this._endOfDay();
        addTimespanItem(start, end);
        this._defaultTimespanKey = constants.ALL_DAY_LABEL;
      } else {
        for (let i = 0; i < binCount; i++) {
          start = end.clone().local().add(1, 'm');
          end = end.clone().local().add(binSize, 'm');
          addTimespanItem(start, end);
        }
      }
    };

    const numberOfFlights = this.state.dimensions.flightsByTimespan.top(Infinity).length;

    if (numberOfFlights < 30) {
      addTimespanItems(1440, 1, false);
    } else {
      this.state.items.timespans[constants.ALL_DAY_LABEL] = {
        value: constants.ALL_DAY_LABEL,
        label: constants.ALL_DAY_LABEL,
        key: constants.ALL_DAY_LABEL,
        rangeFilter: filters.RangedFilter(this._beginningOfDay().toDate().getTime(), this._endOfDay().toDate().getTime()),
        fnc: () => {
          this.state.chartWasFiltered = true;
          this._filterChart(null);
          return this._filterChart(filters.RangedFilter(this._beginningOfDay().toDate().getTime(), this._endOfDay().toDate().getTime()));
        },
        disabled: false,
        checked: false,
      };

      if (numberOfFlights >= 300) {
        addTimespanItems(60, 18, true);
      } else if (numberOfFlights >= 150) {
        addTimespanItems(180, 6, true);
      } else if (numberOfFlights >= 100) {
        addTimespanItems(360, 4, false);
      } else if (numberOfFlights >= 30) {
        addTimespanItems(720, 2, false);
      }
    }

    this.state.menuItems.airline = createDimensionMenuItems(this.state.items.airlineCodes, 'Airlines');
    this.state.menuItems.destOrigin = createDimensionMenuItems(this.state.items.airportCodes, this.props.depArr);
    this.state.menuItems.status = createDimensionMenuItems(this.state.items.statuses, 'Status');
    this.state.menuItems.terminal = createDimensionMenuItems(this.state.items.terminals, 'Terminal', true);
    this.state.menuItems.gate = createDimensionMenuItems(this.state.items.gates, 'Gate', true);
    this.state.menuItems.equipment = createDimensionMenuItems(this.state.items.equipments, 'Equipment', true);
    this.state.menuItems.baggage = createDimensionMenuItems(this.state.items.baggages, 'Baggage', true);
    this.state.menuItems.delay = createDimensionMenuItems(this.state.items.delays, 'Delay');
    this.state.menuItems.timespan = {};

    this.setState({
      currentTimespanKey: this.state.currentTimespanKey || constants.ALL_DAY_LABEL,
    });

    this._timerA = setTimeout(() => {
      this.state.items.timespans[this.state.currentTimespanKey].fnc();
      if (this.refs.otp) this.refs.otp.forceUpdate();
    }, 100);
  }

  componentWillReceiveProps() {
    this.setState({
      items: this.state.items,
      menuItems: this.state.menuItems,
      filterSelectedValues: this.state.filterSelectedValues,
    });
  }

  componentDidUpdate() {
    this.state.chartWasFiltered = false;
  }

  componentWillUnmount() {
    clearTimeout(this._timerA);
    clearTimeout(this._timerB);
  }

  _beginningOfDay = () => {
    const params = this.props.match && this.props.match.params;
    const month = parseInt(params && params.month, 10);
    const day = parseInt(params && params.day, 10);

    return moment()
      .local()
      .year(params && params.year)
      .month(month - 1)
      .date(day)
      .startOf('day');
  };

  _endOfDay = () => {
    const params = this.props.match && this.props.match.params;
    const month = parseInt(params && params.month, 10);
    const day = parseInt(params && params.day, 10);

    return moment()
      .local()
      .year(params && params.year)
      .month(month - 1)
      .date(day + 1)
      .startOf('day');
  };

  _filterSelections = (d, filter, menuItems) => {
    if (filter && filter.length) {
      if (menuItems[d] && menuItems[d].checked) {
        return true;
      }
      return false;
    }
    return true;
  };

  _setFilters = () => {
    this.state.dimensions.flightsByAirlineCode.filter(d => this._filterSelections(d, this.state.filterSelectedValues.airline, this.state.menuItems.airline));
    this.state.dimensions.flightsByDestOriginAirportCode.filter(d => this._filterSelections(d, this.state.filterSelectedValues.destOrigin, this.state.menuItems.destOrigin));
    this.state.dimensions.flightsByStatus.filter(d => this._filterSelections(d, this.state.filterSelectedValues.status, this.state.menuItems.status));
    this.state.dimensions.flightsByTerminal.filter(d => this._filterSelections(d, this.state.filterSelectedValues.terminal, this.state.menuItems.terminal));
    this.state.dimensions.flightsByGate.filter(d => this._filterSelections(unAlphaNumRegexRegulator(d), this.state.filterSelectedValues.gate, this.state.menuItems.gate));
    this.state.dimensions.flightsByEquipment.filter(d => this._filterSelections(unAlphaNumRegexRegulator(d), this.state.filterSelectedValues.equipment, this.state.menuItems.equipment));
    this.state.dimensions.flightsByDelay.filter(d => this._filterSelections(d, this.state.filterSelectedValues.delay, this.state.menuItems.delay));
    this.state.dimensions.flightsByBaggage.filter(d => this._filterSelections(unAlphaNumRegexRegulator(d), this.state.filterSelectedValues.baggage, this.state.menuItems.baggage));
    this.state.dimensions.flightsByCodeshare.filter((d) => {
      if (this.state.filterSelectedValues.codeshare === 'show') {
        return true;
      }
      return !d;
    });
  };

  _inChart = (flight) => {
    if (flight.mainFlightId) {
      return this.state.items.flights[flight.mainFlightId].inChart;
    }
    return this.state.items.flights[flight.flightId].inChart;
  };

  _shouldAddFlightToChart = (flight) => {
    const addFlight = () => {
      if (flight.mainFlightId) {
        this.state.items.flights[flight.mainFlightId].inChart = true;
      } else {
        this.state.items.flights[flight.flightId].inChart = true;
      }
    };
    const inChart = this._inChart(flight);
    // Don't add it...
    let shouldAdd = false;
    if (!flight.isCodeshare) {
      // ...unless it's a 'primary' flight...
      shouldAdd = true;
      // ...but not if it or one of it's associated flights is already repesented in the chart
      if (inChart) {
        shouldAdd = false;
      }
    } else if (this._filteringByAirline(flight.carrierFs) && !inChart) {
      shouldAdd = true;
    }

    // Flag the flight as added
    if (shouldAdd) {
      addFlight();
    }

    return shouldAdd;
  };

  _shouldRemoveFlightFromChart = (flight) => {
    const removeFlight = () => {
      if (flight.mainFlightId) {
        this.state.items.flights[flight.mainFlightId].inChart = false;
      } else {
        this.state.items.flights[flight.flightId].inChart = false;
      }
    };
    const inChart = this._inChart(flight);
    let shouldRemove = false;

    if (!flight.isCodeshare) {
      shouldRemove = true;

      if (!inChart) {
        shouldRemove = false;
      }

      for (let i = flight.associatedFlightIds.length - 1; i >= 0; i--) {
        const [carrierFs] = flight.associatedFlightIds[i].split('_');
        if (this._filteringByAirline(carrierFs)) {
          shouldRemove = false;
          break;
        }
      }
    } else if (this._filteringByAirline(flight.carrierFs) && inChart) {
      shouldRemove = true;
    }

    // Flag the flight as removed
    if (shouldRemove) {
      removeFlight();
    }

    return shouldRemove;
  };

  _filteringByAirline = (code) => {
    const f = getAirlineFilter(this.state);

    return f && f.indexOf(code) !== -1;
  }

  _filterChart = (arg) => {
    if (this.refs.chart) this.refs.chart._filter(arg);
  };

  _updateMenuObjectAndSelectedArray = (returnedObject, filterType) => {
    const key = returnedObject.key;
    this.setState({
      resetFiltersEnabled: true,
    });
    const getSelectedValues = (menuItems) => {
      const menuItemsArray = menuItemsToArray(menuItems, false, true);
      const selectedValues = [];
      for (const item of menuItemsArray) {
        if (item.checked) {
          selectedValues.push(item.key);
        }
      }
      return selectedValues;
    };

    const newState = {
      pageIndex: 0,
      menuItems: this.state.menuItems,
      filterSelectedValues: this.state.filterSelectedValues,
      chartWasFiltered: true,
    };

    let filterValuesLength = 0;
    switch (filterType) {
      case 'Airlines': {
        newState.menuItems.airline[key] = returnedObject;
        if (key === 'reset') {
          resetAllMenuItems(this.state.menuItems.airline);
          newState.filterSelectedValues.airline = [];
          filterValuesLength = 0;
        } else {
          newState.filterSelectedValues.airline = getSelectedValues(this.state.menuItems.airline);
          filterValuesLength = this.state.filterSelectedValues.airline.length;
        }
        break;
      }
      case 'Destination': {
        newState.menuItems.destOrigin[key] = returnedObject;
        if (key === 'reset') {
          resetAllMenuItems(this.state.menuItems.destOrigin);
          newState.filterSelectedValues.destOrigin = [];
          filterValuesLength = 0;
        } else {
          newState.filterSelectedValues.destOrigin = getSelectedValues(this.state.menuItems.destOrigin);
          filterValuesLength = this.state.filterSelectedValues.destOrigin.length;
        }
        break;
      }
      case 'Origin': {
        newState.menuItems.destOrigin[key] = returnedObject;
        if (key === 'reset') {
          resetAllMenuItems(this.state.menuItems.destOrigin);
          newState.filterSelectedValues.destOrigin = [];
          filterValuesLength = 0;
        } else {
          newState.filterSelectedValues.destOrigin = getSelectedValues(this.state.menuItems.destOrigin);
          filterValuesLength = this.state.filterSelectedValues.destOrigin.length;
        }
        break;
      }
      case 'Terminal': {
        newState.menuItems.terminal[key] = returnedObject;
        if (key === 'reset') {
          resetAllMenuItems(this.state.menuItems.terminal);
          newState.filterSelectedValues.terminal = [];
          filterValuesLength = 0;
        } else {
          newState.filterSelectedValues.terminal = getSelectedValues(this.state.menuItems.terminal);
          filterValuesLength = this.state.filterSelectedValues.terminal.length;
        }
        break;
      }
      case 'Gate': {
        newState.menuItems.gate[key] = returnedObject;
        if (key === 'reset') {
          resetAllMenuItems(this.state.menuItems.gate);
          newState.filterSelectedValues.gate = [];
          filterValuesLength = 0;
        } else {
          newState.filterSelectedValues.gate = getSelectedValues(this.state.menuItems.gate);
          filterValuesLength = this.state.filterSelectedValues.gate.length;
        }
        break;
      }
      case 'Equipment': {
        newState.menuItems.equipment[key] = returnedObject;
        if (key === 'reset') {
          resetAllMenuItems(this.state.menuItems.equipment);
          newState.filterSelectedValues.equipment = [];
          filterValuesLength = 0;
        } else {
          newState.filterSelectedValues.equipment = getSelectedValues(this.state.menuItems.equipment);
          filterValuesLength = this.state.filterSelectedValues.equipment.length;
        }
        break;
      }
      case 'Baggage': {
        newState.menuItems.baggage[key] = returnedObject;
        if (key === 'reset') {
          resetAllMenuItems(this.state.menuItems.baggage);
          newState.filterSelectedValues.baggage = [];
          filterValuesLength = 0;
        } else {
          newState.filterSelectedValues.baggage = getSelectedValues(this.state.menuItems.baggage);
          filterValuesLength = this.state.filterSelectedValues.baggage.length;
        }
        break;
      }
      case 'Status': {
        newState.menuItems.status[key] = returnedObject;
        if (key === 'reset') {
          resetAllMenuItems(this.state.menuItems.status);
          newState.filterSelectedValues.status = [];
          filterValuesLength = 0;
        } else {
          newState.filterSelectedValues.status = getSelectedValues(this.state.menuItems.status);
          filterValuesLength = this.state.filterSelectedValues.status.length;
        }
        break;
      }
      case 'Delay': {
        newState.menuItems.delay[key] = returnedObject;
        if (key === 'reset') {
          resetAllMenuItems(this.state.menuItems.delay);
          newState.filterSelectedValues.delay = [];
          filterValuesLength = 0;
        } else {
          newState.filterSelectedValues.delay = getSelectedValues(this.state.menuItems.delay);
          filterValuesLength = this.state.filterSelectedValues.delay.length;
        }
        break;
      }
      default: {
        this.logger('unexpected default case', filterType);
      }
    }

    if (key != 'reset') {
      this.context.reportUserEvent('FilterApplied', filterType, filterValuesLength);
    } else {
      this.context.reportUserEvent('FilterCleared', filterType);
    }

    this.setState(newState);
  };

  _onChartFiltered = (startTime, endTime) => {
    const newTimespanValueLabel = timespanValueLabel(startTime, endTime, prefersAMPMTimeFormat(this.props.user));
    if (!this._defaultTimespanLabels[newTimespanValueLabel]) {
      this.state.items.timespans.Custom = {
        label: newTimespanValueLabel,
        key: 'Custom',
        rangeFilter: filters.RangedFilter(startTime.toDate().getTime(), endTime.toDate().getTime()),
        fnc: () => {
          this.state.chartWasFiltered = true;
          this._filterChart(null);
          return this._filterChart(filters.RangedFilter(startTime.toDate().getTime(), endTime.toDate().getTime()));
        },
        disabled: false,
        checked: false,
      };
    }

    // TODO: consolidate timespans
    const {
      menuItems,
      items,
    } = this.state;
    let newCurrentTimespanKey = 'Custom';

    menuItems.timespan = this.state.items.timespans;

    if (items.timespans[newTimespanValueLabel]) {
      newCurrentTimespanKey = newTimespanValueLabel;
    }

    this.setState({
      currentTimespanKey: newCurrentTimespanKey,
      resetFiltersEnabled: true,
      items,
      menuItems,
    });
  };

  handleTimespanSelected = (selectedItem) => {
    const { key } = selectedItem;
    const {
      items,
      filterSelectedValues,
    } = this.state;

    items.timespans[key] = selectedItem;
    filterSelectedValues.timespan = key;

    this.setState({
      currentTimespanKey: key,
      items,
      filterSelectedValues,
      resetFiltersEnabled: true,
    });

    // Look up this filter function defined in chart and call it.
    // After the chart applies the filter it sets state updating the
    // dropdown and triggering a rerender.
    items.timespans[key].fnc(key);
    this.context.reportUserEvent('ChangeTimespan', key);
  };

  _handleResetFiltersClick = () => {
    if (this.state.resetFiltersEnabled) {
      this.context.reportButtonPress('Reset Filters');
      this.state.chartWasFiltered = true;
      this.state.items.timespans[this._defaultTimespanKey].fnc();

      const newState = {
        sortAscending: true,
        columnSort: constants.LIST_SORT_KEYS.scheduledGate,
        currentTimespanKey: this._defaultTimespanKey,
        items: this.state.items,
        menuItems: this.state.menuItems,
        resetFiltersEnabled: false,
        filterSelectedValues: Object.assign({}, constants.DEFAULT_FILTER_SELECTED_VALUES),
      };

      newState.menuItems.airline = createDimensionMenuItems(resetAllMenuItems(this.state.menuItems.airline), 'Airlines');
      newState.menuItems.destOrigin = createDimensionMenuItems(resetAllMenuItems(this.state.menuItems.destOrigin), (this.props.depArr === 'departures' ? 'Destination' : 'Origin'));
      newState.menuItems.status = createDimensionMenuItems(resetAllMenuItems(this.state.menuItems.status), 'Status');
      newState.menuItems.equipment = createDimensionMenuItems(resetAllMenuItems(this.state.menuItems.equipment), 'Equipment', true);
      newState.menuItems.terminal = createDimensionMenuItems(resetAllMenuItems(this.state.menuItems.terminal), 'Terminal', true);
      newState.menuItems.delay = createDimensionMenuItems(resetAllMenuItems(this.state.menuItems.delay), 'Delay');
      newState.menuItems.gate = createDimensionMenuItems(resetAllMenuItems(this.state.menuItems.gate), 'Gate', true);
      newState.menuItems.baggage = createDimensionMenuItems(resetAllMenuItems(this.state.menuItems.baggage), 'Baggage', true);

      this.setState(newState);

      this._timerB = setTimeout(() => {
        this.state.items.timespans[this.state.currentTimespanKey].fnc();
      }, 100);
    }
  };

  _handleCodeshareToggle = () => {
    this.resetFiltersEnabled = true;

    const filterSelectedValues = {
      ...this.state.filterSelectedValues,
      codeshare: this.state.filterSelectedValues.codeshare === 'hide' ? 'show' : 'hide',
    };

    this.context.reportUserEvent('Codeshares', this.state.filterSelectedValues.codeshare);
    this.state.dimensions.flightsByCodeshare.filter((d) => {
      if (this.state.filterSelectedValues.codeshare === 'show') {
        return true;
      }
      return !d;
    });
    this.setState({
      chartWasFiltered: true,
      filterSelectedValues,
    });
  };

  render() {
    if (this.state.dimensions && this.state.currentTimespanKey) {
      this._setFilters();
      const defaultSorted = this.state.dimensions.flightsByScheduledGate.bottom(Infinity);
      return (
        <div>
          <ActiveFilters
            updateMenuObjectAndSelectedArray={this._updateMenuObjectAndSelectedArray}
            handleResetFiltersClick={this._handleResetFiltersClick}
            depArr={this.props.depArr}
            airportCode={this.props.match && this.props.match.params.airportCode}
            items={this.state.items}
            menuItems={this.state.menuItems}
            filterSelectedValues={this.state.filterSelectedValues}
            destOrigin={this.state.destOrigin}
            currentTimespanKey={this.state.currentTimespanKey}
            flightsShown={defaultSorted.length}
          />
          <FilterMenus
            {...this.props}
            currentTimespanKey={this.state.currentTimespanKey}
            menuItems={this.state.menuItems}
            destOrigin={this.state.destOrigin}
            items={this.state.items}
            handleTimespanSelected={this.handleTimespanSelected}
            updateMenuObjectAndSelectedArray={this._updateMenuObjectAndSelectedArray}
          />
          <div className='row'>
            <div className='col-md-8 col-lg-9'>
              <Chart
                {...this.props}
                ref='chart'
                dimensions={this.state.dimensions}
                items={this.state.items}
                chartWasFiltered={this.state.chartWasFiltered}
                currentTimespanKey={this.state.currentTimespanKey}
                defaultTimespanKey={this._defaultTimespanKey}
                defaultTimespanLabels={this._defaultTimespanLabels}
                onChartFiltered={this._onChartFiltered}
                beginningOfDay={this._beginningOfDay}
                endOfDay={this._endOfDay}
                filterSelectedValues={this.state.filterSelectedValues}
              />
            </div>
            <div className='col-md-4 col-lg-3'>
              <OnTimePerformance
                ref='otp'
                currentTimespanKey={this.state.currentTimespanKey}
                chartDidMount={this.state.chartDidMount}
                items={this.state.items}
              />
            </div>
          </div>
          <ListWrapper
            ref='list'
            toggleOn={codeshare(this.props) === 'hide'}
            filterSelectedValues={this.state.filterSelectedValues}
            handleCodeshareToggle={this._handleCodeshareToggle}
            chartWasFiltered={this.state.chartWasFiltered}
            depArr={this.props.depArr}
            params={this.props.match && this.props.match.params}
            dimensions={this.state.dimensions}
            defaultSorted={defaultSorted}
            list={this.props.clonedList}
          />
        </div>
      );
    }

    return (<div />);
  }
}

export default Dimensions;

const _unixTimeToSortBy = dateObj => _momentTimeToUnix((dateObj !== '' && dateObj != null) ? new Date(dateObj.dateLocal) : '--');

const _momentTimeToUnix = (dateTime) => {
  if (dateTime != null && dateTime !== '--') {
    return moment.unix(dateTime);
  }
  return 0;
};
