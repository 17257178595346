import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { pathOr } from 'ramda';
import moment from 'moment';
import Helmet from 'react-helmet';
import reportException from '../../../../../shared/lib/report-exception';
import componentBase from '../../../../../src/lib/component-base';
import SubscriptionHeader from '../../shared/SubscriptionHeader';
import { dateFormatString, timeFormatString } from '../../../../../src/lib/date-time-format';
import { subscriptionCostForLevel, subscriptionTitleForLevel } from '../../../../../shared/lib/subscription-level-matching';
import FlightTrackerIcon from '../../../../../static/images/subscription-icons/Tracker-Icon.svg';
import CurrentConditionsIcon from '../../../../../static/images/subscription-icons/CurrentConditions-Icon.svg';
import DelayMapsIcon from '../../../../../static/images/subscription-icons/DelayMaps-Icon.svg';
import DeparturesArrivalsIcon from '../../../../../static/images/subscription-icons/DeparturesArrivals-Icon.svg';
import HistoricalIcon from '../../../../../static/images/subscription-icons/Historical-Icon.svg';
import FlightAlertIcon from '../../../../../static/images/subscription-icons/FlightAlert-Icon.svg';
import NoAdsIcon from '../../../../../static/images/subscription-icons/NoADS-WHITE-Icon.svg';
import SubscriptionService from './children/SubscriptionService';
import StatusBar from '../../shared/StatusBar';
import { getProfile } from '../../../redux/actions';
import { addMetaCanonical } from '../../../../../shared/lib/meta-tags';

@connect(state => ({
  braintreeSubscriptionError: state.Account.braintreeSubscriptionError,
  braintreeSubscription: state.Account.braintreeSubscription,
  upgradedSubscription: state.Account.upgradedSubscription,
  upgradingSubscriptionError: state.Account.upgradingSubscriptionError,
  user: state.Account.user,
}))
@componentBase('SubscriptionSuccess')
export default class SubscriptionSuccess extends Component {
  static propTypes = {
    braintreeSubscription: PropTypes.object,
    braintreeSubscriptionError: PropTypes.string,
    dispatch: PropTypes.func,
    match: PropTypes.object,
    upgradedSubscription: PropTypes.object,
    user: PropTypes.object,
  }

  componentDidMount() {
    const { braintreeSubscriptionError, dispatch } = this.props;

    dispatch(getProfile());

    if (braintreeSubscriptionError) {
      reportException(braintreeSubscriptionError, null, 'create-account-success');
      // go back to payment screen if made it here despite error
    }
    this.validateProps();
  }

  componentWillReceiveProps(nextProps) {
    this.validateProps(nextProps);
  }

  getSubscriptionSuccess = () => {
    const { user } = this.props;
    const { subscriptionCreatedAt, subscriptionUpdatedAt, subscriptionLevel } = user || {};
    const subscription = {};

    const { upgradedSubscription } = this.props;
    if (upgradedSubscription) {
      const { data } = upgradedSubscription;
      const amount = data && data.amount;

      if (subscriptionUpdatedAt) {
        subscription.creationDate = moment(subscriptionUpdatedAt).format(`${dateFormatString(user)} ${timeFormatString(user)}`);
      }

      if (subscriptionLevel) {
        subscription.cost = `${amount || ''} (prorated)`;
        subscription.title = subscriptionTitleForLevel(subscriptionLevel) || '';
      }
    } else if (this.props.braintreeSubscription) {
      if (subscriptionCreatedAt) {
        subscription.creationDate = moment(subscriptionCreatedAt).format(`${dateFormatString(user)} ${timeFormatString(user)}`);
      }

      if (subscriptionLevel) {
        subscription.cost = subscriptionCostForLevel(subscriptionLevel) || '';
        subscription.title = subscriptionTitleForLevel(subscriptionLevel) || '';
      }
    }

    return subscription;
  }

  validateProps = (props = this.props) => {
    const user = pathOr({}, ['user'], props);
    const { id, subscriptionCreatedAt, subscriptionLevel, subscriptionStatus } = user;
    const missingSubscriptionKeys = [subscriptionCreatedAt, subscriptionLevel, subscriptionStatus]
      .some(v => !v);
    const error = missingSubscriptionKeys ? [
      'error in account/success required fields after subscribe missing: ',
      `subscriptionCreatedAt: ${subscriptionCreatedAt}, `,
      `subscriptionLevel: ${subscriptionLevel}, `,
      `subscriptionStatus: ${subscriptionStatus}`,
    ].join('') :
      (!id && 'error in account/success required fields after subscribe missing: user');
    error && reportException(error, null, 'account-success-page');
  }

  renderHistorical = () => {
    const { user } = this.props;
    return user && user.subscriptionLevel >= 3 ? <SubscriptionService
      serviceUnlocked
      serviceImageSrc={HistoricalIcon}
      serviceTitle='Historical Flight Status'
      serviceSubtitle='See status on flights going back to 2006'
      serviceLink='/historical-flight'
    /> : null;
  }

  render() {
    const { creationDate, title, cost } = this.getSubscriptionSuccess();
    return (
      <div className='subscription-success-container'>
        <Helmet {...addMetaCanonical({ title: 'FlightStats Subscription Success' }, this.props.match.path)} />
        <SubscriptionHeader />
        <div className='containerDiv'>
          <StatusBar success statusMessage='Payment Success' />
          <h2 className='successTitle'>THANK YOU</h2>
          <div>
            {creationDate ? <p>Date: <span className='transactionDetails'>{creationDate}</span></p> : null}
            {title ? <p>Service: <span className='transactionDetails'>{title}</span></p> : null}
            {cost ? <p style={{ marginBottom: '0' }}>Charge: <span className='transactionDetails'>${cost}</span></p> : null}
          </div>
          <div className='serviceSection'>
            <h2 className='serviceSectionTitle free'>Free Services</h2>
            <p className='serviceSectionSubtitle'>Free features include</p>
            <SubscriptionService serviceImageSrc={FlightTrackerIcon} serviceTitle='Flight Tracker' serviceSubtitle='Check the status of a flight' serviceLink='/flight-tracker' />
            <SubscriptionService serviceImageSrc={CurrentConditionsIcon} serviceTitle='Airport Conditions' serviceSubtitle='Check weather and delay conditions' serviceLink='/airport-conditions' />
            <SubscriptionService serviceImageSrc={DelayMapsIcon} serviceTitle='Delay Maps' serviceSubtitle='See airport delays for regions of the world' serviceLink='/airport-delays' />
          </div>
          <div className='serviceSection' style={{ marginBottom: '40px' }}>
            <h2 className='serviceSectionTitle unlocked'>Unlocked Services</h2>
            <p className='serviceSectionSubtitle'>You now have access to</p>
            <SubscriptionService serviceUnlocked serviceImageSrc={DeparturesArrivalsIcon} serviceTitle='Airport Departures and Arrivals' serviceSubtitle='See all the flights at your local airport' serviceLink='/airports/departing-arriving' />
            {this.renderHistorical()}
            <SubscriptionService serviceUnlocked serviceImageSrc={FlightAlertIcon} serviceTitle='Flight Alerts' serviceSubtitle='Let FlightStats monitor your flight and update when things change' serviceLink='/flight-alerts' />
            <SubscriptionService serviceUnlocked serviceImageSrc={NoAdsIcon} serviceTitle='No Ads' serviceSubtitle='Enjoy a cleaner experience' />
          </div>
        </div>
      </div>
    );
  }
}
