import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import moment from 'moment';
import componentBase from '../../../../src/lib/component-base';
import { dateFormatString } from '../../../../src/lib/date-time-format';
import { getAirportCodeSimple } from '../../../../shared/lib/airport-code-helpers';

const now = moment();

@componentBase('ResultHeader')
export default class ResultHeader extends React.Component {
  static propTypes = {
    basicInfo: PropTypes.object,
    error: PropTypes.string,
    friendlyError: PropTypes.string,
    lastUpdated: PropTypes.number,
    loading: PropTypes.bool,
    list: PropTypes.array,
    routeParams: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      airportCode: null,
      departureArrival: null,
      lastUpdated: null,
    };

    this.muiDefaultProps = {
      airportNameAddressBlock: {
        padding: '10px 0 10px 20px',
        airportName: {
          fontSize: '20pt',
          fontWeight: 'bold',
          lineHeight: '25px',
        },
        airportAddress: {
          paddingTop: '5px',
          fontSize: '12pt',
        },
      },
      dateRightBlock: {
        padding: '10px 20px 10px 0',
        date: {
          fontSize: '20pt',
          lineHeight: '25px',
          textAlign: 'right',
        },
      },
      dateBlock: {
        padding: '0 20px 10px 20px',
        date: {
          fontSize: '20pt',
          lineHeight: '25px',
        },
      },
      contentBorder: {
        borderTop: '5px solid rgb(250, 167, 24)',
        paddingBottom: '20px',
      },
    };
  }

  componentDidMount() {
    this.lastUpdatedMoment = moment(this.props.lastUpdated);
  }

  componentWillReceiveProps() {
    this.lastUpdatedMoment = moment(this.props.lastUpdated);
    const newState = Object.assign({
      lastUpdated: this.elapsedTime(),
    }, this.props.routeParams);
    this.setState(newState);
    this.timer = this.timer || setInterval(this.tick, 10000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  elapsedTime = () => (this.lastUpdatedMoment ? this.lastUpdatedMoment.fromNow() : null);

  tick = () => {
    this.setState({
      lastUpdated: this.elapsedTime(),
    });
  };

  metaTags = (airportCode, airportName, titleDepartureArrival) => {
    let cityInfo = '';
    if (this.props.basicInfo && this.props.basicInfo.city) {
      cityInfo = `, ${this.props.basicInfo.city} ${titleDepartureArrival}, ${this.props.basicInfo.city} airport ${titleDepartureArrival}`;
    }

    const code = this.props.friendlyError ? 'Departures and Arrivals' : airportCode;
    const title = `(${code}) ${airportName || ''} ${titleDepartureArrival}`;
    const keywords = `${code} ${titleDepartureArrival}, ${airportName || ''} ${titleDepartureArrival}${cityInfo}`;
    const description = `Check the current status of flights ${(titleDepartureArrival === 'Departures' ? 'departing from' : 'arriving at')} (${airportCode}) ${airportName || ''}`;

    return {
      title,
      meta: [
        { name: 'description', content: description },
        { name: 'keywords', content: keywords },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
      ],
    };
  };

  render() {
    // this.logger('props: ', this.props);
    const params = this.props.routeParams;
    let titleDepartureArrival;
    params && params.departureArrival ? titleDepartureArrival = capitalizeFirstLetter(params.departureArrival) : '';

    let year = null;
    let date = null;
    let momDate = null;

    if (this.props.error === 'DATE_OUT_OF_RANGE') {
      date = 'Invalid Date';
    } else if (params.day != undefined) {
      const monthFromParams = parseInt(params.month, 10);
      year = params.year;
      momDate = moment();
      momDate.date(params.day);
      momDate.month(monthFromParams - 1);
      momDate.year(year);
    } else {
      momDate = moment();
      year = now.format('YYYY');
    }

    if (momDate) {
      date = momDate.format(dateFormatString(this.context.currentUser)).replace(/-/g, '-');
    }

    let airportCode = params.airportCode;
    let airportName = null;

    if (this.props.basicInfo) {
      airportCode = getAirportCodeSimple(this.props.basicInfo);
      airportName = this.props.basicInfo.name;
    }

    const meta = this.metaTags(airportCode, airportName, titleDepartureArrival);

    if (this.props.loading) {
      return (
        <div>
          <Helmet {...meta} />
          <div>
            <h4 style={{ paddingTop: '30px', margin: '10px' }}>Loading {titleDepartureArrival} for ({airportCode}) {airportName}</h4>
          </div>
        </div>
      );
    } else if (this.props.routeParams && this.props.list) {
      const airportString = airportName ? `(${airportCode}) ${airportName}` : '';
      return (
        <div>
          <Helmet {...meta} />
          <div className='row'>
            <div className='col-md-12'>
              <div className='row'>
                <div className='col-sm-8 col-md-8 col-lg-8'>
                  <div className='row' style={this.muiDefaultProps.airportNameAddressBlock}>
                    <div className='col-md-12' style={this.muiDefaultProps.airportNameAddressBlock.airportName}>
                      { airportString } { titleDepartureArrival }
                    </div>
                    <div className='col-md-12' style={this.muiDefaultProps.airportNameAddressBlock.airportAddress}>
                      { this.state.lastUpdated ? `Last updated ${this.state.lastUpdated}` : '' }
                    </div>
                  </div>
                </div>
                <div className='col-sm-4 col-md-4 col-lg-4'>
                  <div className='row visible-xs' style={this.muiDefaultProps.dateBlock}>
                    <div className='col-md-12' style={this.muiDefaultProps.dateBlock.date}>
                      { date }
                    </div>
                  </div>
                  <div className='row hidden-xs' style={this.muiDefaultProps.dateRightBlock}>
                    <div className='col-md-12' style={this.muiDefaultProps.dateRightBlock.date}>
                      { date }
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                {this.props.error ? <noscript /> : (<div className='col-md-12' style={this.muiDefaultProps.contentBorder} />)}
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (<div />);
  }
}

const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);
