import sortByDate from '../../../../shared/lib/data-sorting/sort-by-date';

export const flightNumberSort = (data, orderBy) => {
  const flightNumberIndexTracker = generateIndexTrackerFlightNumber(data);
  const sortedCarrierCodes = Object.keys(flightNumberIndexTracker).sort();
  const result = [];
  sortedCarrierCodes.forEach((carrierCode) => {
    const sortedFlightNumbers = Object.keys(flightNumberIndexTracker[carrierCode]).map(flightNumber => +flightNumber).sort((a, b) => a - b);
    sortedFlightNumbers.forEach((flightNumber) => {
      const sortedIndex = flightNumberIndexTracker[carrierCode][flightNumber];
      sortedIndex.forEach((index) => {
        result.push(data[index]);
      });
    });
  });

  return orderBy === 'ASC' ? result : result.reverse();
};

export const generateIndexTrackerFlightNumber = (data) => {
  const indexTracker = {};

  for (let i = 0; i < data.length; i++) {
    const carrierCode = `${(data[i].carrierCode)}`.toUpperCase();
    const flightNumber = +data[i].flightNumber;
    if (!indexTracker[carrierCode]) {
      indexTracker[carrierCode] = {};
    }
    if (!indexTracker[carrierCode][flightNumber]) {
      indexTracker[carrierCode][flightNumber] = [];
    }
    indexTracker[carrierCode][flightNumber].push(i);
  }

  return indexTracker;
};

export const dateSort = (data, orderBy) => sortByDate(data, 'scheduledDeparture', orderBy);

export const stringSort = (data, orderBy = 'DESC', key) => {
  const indexTracker = generateIndexTracker(data, key);
  const sortedKeys = orderBy === 'ASC' ? Object.keys(indexTracker).sort() : Object.keys(indexTracker).sort().reverse();

  const result = sortedKeys.reduce((prev, current) => {
    const allIndices = indexTracker[current];
    return prev.concat(allIndices.reduce((arr, index) => arr.concat(data[index]), []));
  }, []);

  return result;
};

export const generateIndexTracker = (data, key) => {
  // gonna track indexes since JS .sort does not like dealing with duplicates
  const indexTracker = {};
  for (let i = 0; i < data.length; i++) {
    const dataValue = `${(data[i][key])}`.toUpperCase();
    if (!indexTracker[dataValue]) {
      indexTracker[dataValue] = [];
    }
    indexTracker[dataValue].push(i);
  }

  return indexTracker;
};

// borrowed and updated from http://stackoverflow.com/questions/15478954/sort-array-elements-string-with-numbers-natural-sort
export const sortByComplexString = key => (a, b) => {
  const firstArray = [];
  const secondArray = [];

  a[key].replace(/(\d+)|(\D+)/g, (_, $1, $2) => {
    firstArray.push([$1 || Infinity, $2 || '']);
  });
  b[key].replace(/(\d+)|(\D+)/g, (_, $1, $2) => {
    secondArray.push([$1 || Infinity, $2 || '']);
  });

  while (firstArray.length && secondArray.length) {
    const selectorA = firstArray.shift();
    const selectorB = secondArray.shift();
    const combinedSelector = (selectorA[0] - selectorB[0]) || selectorA[1].localeCompare(selectorB[1]);
    if (combinedSelector) return combinedSelector;
  }

  return secondArray.length - firstArray.length;
};
