import { isBusinessUser } from '../../../../../src/utils/isBusinessUser';

const userHasHistoricalCredits = (currentUser) => {
  if (!currentUser) {
    return false;
  }

  const { historicalReservedCredits, historicalMonthlyCredits } = currentUser;
  const businessUser = isBusinessUser(currentUser);

  return businessUser || !!historicalMonthlyCredits || !!historicalReservedCredits;
};

export default userHasHistoricalCredits;
