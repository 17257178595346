export const randomString = () => Math.random().toString(36).substring(2, 13);

const randomStringRegEx = new RegExp(/(^[-a-zA-Z0-9*]{2,13}$)/i);

export const validRandomString = (str = '') => {
  if (str.length > 13) {
    return false;
  } else if (!randomStringRegEx.test(str)) {
    return false;
  }

  return true;
};
