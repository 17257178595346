import * as t from './actionTypes';
import { errorText } from '../../../shared/lib/reducer-util';

const initialState = {
  error: null,
  loadAttempts: 0,
  loading: false,
  loaded: false,
  ratings: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case t.LOAD_OTP_BY_FLIGHT:
      return {
        ...state,
        // error: null,
      };
    case t.LOAD_OTP_SUCCESS:
      return {
        ...state,
        error: null,
        loadAttempts: state.loadAttempts + 1,
        loaded: true,
        ratings: action.result,
      };
    case t.LOAD_FAIL:
      return {
        ...state,
        loadAttempts: state.loadAttempts + 1,
        loaded: false,
        error: errorText(action.error),
      };
    case t.CLEAR_OTP:
      return {
        ...state,
        loadAttempts: 0,
        ratings: null,
      };
    case t.START_SPINNER:
      return {
        ...state,
        loading: true,
      };
    case t.END_SPINNER:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
