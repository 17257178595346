import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { confirmable } from 'react-confirm';
import FSFlatButton from '../../../../src/components/lib/FSFlatButton';
import defaultContextTypes from '../../../../src/components/default-context-types';

@confirmable
export default class ConfirmModalDialog extends React.Component {
  static propTypes = {
    show: PropTypes.bool, // from confirmable. indicates if the dialog is shown or not.
    proceed: PropTypes.func, // from confirmable. call to close the dialog with promise resolved.
    cancel: PropTypes.func, // from confirmable. call to close the dialog with promise rejected.
    dismiss: PropTypes.func, // from confirmable. call to only close the dialog.
    confirmation: PropTypes.string, // arguments of your confirm function
    options: PropTypes.object, // arguments of your confirm function
    okLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
    title: PropTypes.string,
    modal: PropTypes.bool,
  };
  static contextTypes = defaultContextTypes;
  static childContextTypes = defaultContextTypes;

  render() {
    const {
      okLabel = 'Ok',
      cancelLabel = 'Cancel',
      title,
      confirmation,
      show,
      proceed,
      dismiss,
      cancel,
      modal,
      options,
    } = this.props;

    const actions = [
      <FSFlatButton
        label={cancelLabel}
        secondary
        onClick={cancel}
        style={FSFlatButton.style({ backgroundColor: 'rgb(118, 177, 92)', color: '#fff', margin: '2px' })}
      />,
      <FSFlatButton
        id='confirm-modal-ok-button'
        label={okLabel}
        primary
        onClick={proceed}
        style={FSFlatButton.style({ backgroundColor: 'rgb(140, 56, 46)', color: '#fff', margin: '2px' })}
      />,
    ];

    return (
      <div>
        <Dialog
          aria-labelledby='confirmation-dialog-title'
          classes={{
            paper: 'mui-dialog-content',
            paperWidthMd: 'mui-dialog-md',
          }}
          maxWidth='md'
          onClose={dismiss}
          open={show}
        >
          <DialogTitle id='confirmation-dialog-title'>{title}</DialogTitle>
          <DialogContent
            classes={{
              root: options.hideText ? 'confirm-text-hidden' : 'confirm-text-default',
            }}
          >
            {confirmation}
          </DialogContent>
          <DialogActions>
            <Button
              classes={{
                root: 'mui-dialog-button',
                label: 'mui-dialog-button-text',
              }}
              color='primary'
              onClick={cancel}
              size='large'
              style={FSFlatButton.style({ backgroundColor: 'rgb(118, 177, 92)', color: '#fff', margin: '2px' })}
              variant='flat'
            >
              {cancelLabel}
            </Button>
            <Button
              classes={{
                root: 'mui-dialog-button',
                label: 'mui-dialog-button-text',
              }}
              color='primary'
              onClick={proceed}
              size='large'
              style={FSFlatButton.style({ backgroundColor: 'rgb(140, 56, 46)', color: '#fff', margin: '2px' })}
              variant='flat'
            >
              {okLabel}
            </Button>
          </DialogActions>
        </Dialog>
        {/* <Dialog
          className='mui-dialog'
          contentClassName='mui-dialog-content'
          bodyStyle={{ color: 'rgba(7, 28, 53, 0.8)' }}
          bodyClassName={options.hideText ? 'confirm-text-hidden' : 'confirm-text-default'}
          title={title}
          actions={actions}
          modal={modal}
          open={show}
          onClose={dismiss}
          options={options}
        >
          {confirmation}
        </Dialog> */}
      </div>
    );
  }
}
