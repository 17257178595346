import React from 'react';
import PropTypes from 'prop-types';

const ChartIcon = (props) => {
  const {
    customClassName,
    isChartHeader,
    icon,
    title,
    subtitle,
  } = props;

  return (
    <div className='subscription-feature-chart-header'>
      <div className={`icon-title-container ${customClassName || ''}`}>
        <img className={`icon ${isChartHeader ? 'large' : ''}`} src={icon} alt='icon' />
        <h3 className={`title ${isChartHeader ? 'large' : ''}`}>{title}</h3>
      </div>
      <div className='subtitle-container'>
        <p className={`subtitle ${isChartHeader ? 'large' : ''}`}>{subtitle}</p>
      </div>
    </div>
  );
};

ChartIcon.propTypes = {
  customClassName: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isChartHeader: PropTypes.bool,
};

export default ChartIcon;
