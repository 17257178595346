import React, { Component } from 'react';
import PropTypes from 'prop-types';
import componentBase from '../../../../src/lib/component-base';
import ActiveFrameIcon from './children/ActiveFrameIcon';
import PassiveFrameIcon from './children/PassiveFrameIcon';
import BeforeNavIcon from './children/BeforeNavIcon';
import NextNavIcon from './children/NextNavIcon';
import ImageFrame from './children/ImageFrame';

const DEFAULT_ACTIVE_INDEX = 0;
const DEFAULT_INTERVAL = 6000;

@componentBase('ImageCarousel')
export default class ImageCarousel extends Component {
  static propTypes = {
    images: PropTypes.arrayOf(PropTypes.object),
    activeIndex: PropTypes.number,
    interval: PropTypes.number,
    activeSubscription: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    const initialState = {
      activeIndex: props.activeIndex,
      interval: props.interval,
    };
    this.state = {
      activeIndex: initialState.activeIndex || DEFAULT_ACTIVE_INDEX,
      interval: initialState.interval || DEFAULT_INTERVAL,
      animating: true,
    };
  }

  componentDidMount() {
    this.playAnimation();
  }

  componentWillUnmount() {
    this.pauseAnimation();
  }

  get indexIcons() {
    if (this.state.activeIndex <= (this.props.images.length - 1)) {
      let icons = [];
      if (this.props && this.props.images) {
        icons = this.props.images.map((image, index) => {
          const key = `key_${index}`;

          return (
            <div
              className='material-frame-icon-container'
              key={key}
            >
              <PassiveFrameIcon
                handleClick={this.setFrameIndex}
                value={index}
              />
            </div>
          );
        });
      }
      icons[this.state.activeIndex] = (<div
        className='material-frame-icon-container'
        key={this.state.activeIndex}
      >
        <ActiveFrameIcon />
      </div>);
      return icons;
    }
    return null;
  }

  get navigationIcons() {
    return (
      <div className='material-navigation-icon-container'>
        {<BeforeNavIcon navigateBack={this.navigateBack} />}
        {<NextNavIcon navigateNext={this.navigateNext} />}
      </div>
    );
  }

  get activeImageStyle() {
    if (!this.state.animating) {
      return {
        width: '100%',
        height: '100%',
        margin: '0',
      };
    }
    const style = {
      width: '100%',
      height: '100%',
      margin: '0',
      transition: 'margin .4s ease',
      WebkitTransition: 'margin .4s ease',
      MsTransition: 'margin .4s ease',
      MozTransition: 'margin .4s ease',
    };
    return style;
  }

  get nextImageStyle() {
    if (!this.state.animating) {
      return {
        height: 0,
        marginLeft: '150%',
      };
    }
    const style = {
      height: 0,
      marginLeft: '150%',
      transition: 'margin .5s ease-in',
      WebkitTransition: 'margin .5s ease-in',
      MsTransition: 'margin .5s ease-in',
      MozTransition: 'margin .5s ease-in',
    };
    return style;
  }

  get beforeImageStyle() {
    if (!this.state.animating) {
      return {
        height: 0,
        marginRight: '150%',
      };
    }
    const style = {
      height: 0,
      marginRight: '150%',
      transition: 'margin .5s ease-in',
      WebkitTransition: 'margin .5s ease-in',
      MsTransition: 'margin .5s ease-in',
      MozTransition: 'margin .5s ease-in',
    };
    return style;
  }

  get otherImageStyle() {
    return {
      display: 'none',
    };
  }

  get images() {
    if (this.props && this.props.images) {
      return this.props.images.map((image, index) => {
        const equalIndices = this.state.activeIndex === index;
        const style = this.getElementStyle(equalIndices, index);
        const divWidth = this.props.images.length * 20;
        const key = `key_${index}`;

        const Image = (
          <ImageFrame
            style={style}
            key={key}
            text={image.text ? image.text : null}
            index={index}
            source={image.url}
            gaValue={image.gaValue}
            divWidth={divWidth}
            equalIndices={equalIndices}
            navigationIcons={this.navigationIcons}
            indexIcons={this.indexIcons}
            handleRightSwipe={this.navigateBack}
            handleLeftSwipe={this.navigateNext}
            activeSubscription={this.props.activeSubscription}
          />
        );

        return Image;
      });
    }
    return null;
  }


  setFrameIndex = (e) => {
    const newIndex = parseInt(e.target.getAttribute('value'), 10);
    this.pauseAnimation();
    this.setState({
      activeIndex: newIndex,
    });
  }

  getElementStyle = (equalIndices, index) => {
    let style = this.otherImageStyle;
    if (equalIndices) {
      style = this.activeImageStyle;
    }
    const wrapBack = this.state.activeIndex === 0 && index === this.props.images.length - 1;
    if (index === this.state.activeIndex - 1 || wrapBack) {
      style = this.beforeImageStyle;
    }
    const wrapForward = this.state.activeIndex === this.props.images.length - 1 && index === 0;
    if (index === this.state.activeIndex + 1 || wrapForward) {
      style = this.nextImageStyle;
    }
    return style;
  }

  playAnimation = () => {
    this.timer = setInterval(this.rotateImages, this.state.interval);
  }

  pauseAnimation = () => {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
    this.setState({
      animating: false,
    });
  }

  navigateBack = () => {
    const backIndex = this.state.activeIndex === 0 ? this.props.images.length - 1 : this.state.activeIndex - 1;
    this.pauseAnimation();
    this.setState({
      activeIndex: backIndex,
    });
  }

  navigateNext = () => {
    const nextIndex = this.state.activeIndex !== (this.props.images.length - 1) ? this.state.activeIndex + 1 : 0;
    this.pauseAnimation();
    this.setState({
      activeIndex: nextIndex,
    });
  }

  rotateImages = () => {
    const nextIndex = this.state.activeIndex !== (this.props.images.length - 1) ? this.state.activeIndex + 1 : 0;
    this.setState({
      activeIndex: nextIndex,
    });
  }

  render() {
    // this.logger(this.props);
    return (
      <div className='image-carousel-container'>
        {this.images}
      </div>
    );
  }
}
