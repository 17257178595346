import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { isEmpty, identity } from 'ramda';

import componentBase from '../../../../src/lib/component-base';
import FSFlatButton from '../../../../src/components/lib/FSFlatButton';
import AirportSelect from '../../../../src/components/lib/AirportSelect';
import { setAirport } from '../../redux/actions';
import Ad from '../../../../shared/components/Ad/Ad';
import { AirportSearch as airportSearchAds } from '../ads';
import { addMetaCanonical } from '../../../../shared/lib/meta-tags';
import { getAirportCodeSimple } from '../../../../shared/lib/airport-code-helpers';

@connect(state => ({
  airport: state.AirportCurrentConditions.airportSearchItem,
  callSecondaryAction: state.AirportCurrentConditions.callSecondaryAction,
  location: state.router.location,
}))
@componentBase('AirportSearch')
export default class AirportSearch extends React.Component {
  static propTypes = {
    airport: PropTypes.object,
    dispatch: PropTypes.func,
    location: PropTypes.object,
    match: PropTypes.object,
    name: PropTypes.string,
    path: PropTypes.string,
    callSecondaryAction: PropTypes.bool,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      errText: null,
    };
  }

  componentWillUnmount() {
    this.props.dispatch(setAirport({}));
  }

  handleSubmit = (e) => {
    e && e.preventDefault && e.preventDefault();
    if (!isEmpty(this.props.airport)) {
      const { value } = this.props.airport;
      const { _source } = value;
      const code = getAirportCodeSimple(_source);
      const url = `/airport-conditions/${code}`;
      this.context.goto(url, 'Search');
    } else {
      this.setState({
        errText: 'Please enter a valid airport name or code',
      });
    }
  }

  handleDelete = () => {
    // reset to no airport
    this.props.dispatch(setAirport({}));
  }

  metaTags = () => {
    const title = 'Current Airport Conditions';
    const description = 'See the current weather and delay conditions for airport worldwide, including forecast data for US airports';
    const keywords = 'airport current conditions, airport weather, airport delays';
    const metaWithoutCanonical = {
      title,
      meta: [
        { name: 'description', content: description },
        { name: 'keywords', content: keywords },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
      ],
    };
    return addMetaCanonical(metaWithoutCanonical, this.props.match.path);
  }

  render = () => {
    const sectionStyle = {
      position: 'relative',
    };
    const airportSearchStyle = {
      marginBottom: '15px',
      flex: '100% 0 0',
      width: '100%',
    };

    const subscriptionActive = (
      this.context &&
      this.context.currentUser &&
      this.context.currentUser.subscriptionActive
    );

    let errorText = '';

    if (this.state.errText) {
      errorText = (
        <div className='search-error-text-block'>
          <p className='search-error-text'>{this.state.errText}</p>
        </div>
      );
    }

    const boxAd = subscriptionActive ? null : (
      <center style={{ marginTop: '25px', marginBottom: '25px' }}>
        <Ad
          key={airportSearchAds.bottom.id}
          {...airportSearchAds.bottom}
          subscriptionActive={subscriptionActive}
        />
      </center>
    );

    const meta = this.metaTags();

    return (
      <section style={sectionStyle}>
        <Helmet {...meta} />
        <div style={{ position: 'relative', margin: '50px auto', width: '100%' }}>
          <div className='fs-search'>
            <h1 style={{ fontSize: '28pt', fontWeight: 'strong' }}>Airport Conditions</h1>
            <form onSubmit={this.handleSubmit}>
              <p className='landing-text'>Enter your airport</p>
              <section className='fs-search-card'>
                {errorText}
                <div className='airport-search' style={airportSearchStyle}>
                  <AirportSelect
                    inputTitle='Enter your airport'
                    name='airport'
                    airport={this.props.airport}
                    onChange={
                      (option, triggeredByEnter) =>
                      (this.props.dispatch(setAirport(option, triggeredByEnter)))
                    }
                    onDelete={this.handleDelete}
                    shouldFocus
                    secondaryAction={this.props.callSecondaryAction ? this.handleSubmit : identity}
                  />
                </div>
                <div className='center-buttons-mobile'>
                  <FSFlatButton
                    style={{ minWidth: '175px', maxWidth: '300px' }}
                    className='flight-tracker-search-button'
                    disableTouchRipple
                    label='Search'
                    backgroundColor={this.context.muiTheme.rawTheme.palette.orange}
                    primary
                    type='submit'
                  />
                </div>
              </section>
            </form>
          </div>
          {boxAd}
        </div>
      </section>
    );
  }
}
