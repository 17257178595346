import React from 'react';
import PropTypes from 'prop-types';
import FontIcon from 'material-ui/FontIcon';

const BeforeNavIcon = props => (
  // navigate_before
  <FontIcon
    className='material-icons'
    color='rgba(255, 2, 0, 0.5)'
    hoverColor='rgb(255, 2, 0)'
    style={{ display: 'inline', float: 'left' }}
    onClick={props.navigateBack}
  >
    &#xE408;
  </FontIcon>
);

BeforeNavIcon.propTypes = {
  navigateBack: PropTypes.func,
};

export default BeforeNavIcon;
