import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import BillingInformation from './BillingInformation';
import BraintreePaymentForm from './BraintreePaymentForm';
import { actions } from '../../redux';
import { getProfile, getBraintreeClientToken, getBraintreePlans, handlePlanSelection } from '../../redux/actions';
import reportException from '../../../../shared/lib/report-exception/index';
import { catchErrors } from '../../redux/selectors';
import componentBase from '../../../../src/lib/component-base';
import SubscriptionTierSelection from './SubscriptionTierSelection';
import {
  cardErrorMessageForType,
  errorStrings,
} from '../shared/utility';
import {
  findSubscriptionByType,
  subscriptionOptions,
  subscriptionPlanIDForLevel,
  subscriptionGAValueForLevel,
  subscriptionCostForLevel,
} from '../../../../shared/lib/subscription-level-matching';
import { isBusinessUser } from '../../../../src/utils/isBusinessUser';


@connect(
  state => ({
    user: state.Account.user,
    braintreeClientToken: state.Account.braintreeClientToken,
    braintreeClientTokenError: catchErrors(state.Account.braintreeClientTokenError),
    braintreePlans: state.Account.braintreePlans,
    braintreePlansError: catchErrors(state.Account.braintreePlansError),
    braintreeSubscription: state.Account.braintreeSubscription,
    braintreeSubscriptionError: catchErrors(state.Account.braintreeSubscriptionError),
    braintreeSubmitButtonDisabled: state.Account.braintreeSubmitButtonDisabled,
    hostedFields: state.Account.hostedFields,
    hostedFieldsDidCreate: state.Account.hostedFieldsDidCreate,
    planSelected: state.Account.planSelected,
  }),
  actions,
)
@componentBase('Subscribe')
export default class Subscribe extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    updateProfile: PropTypes.func,
    braintreeClientToken: PropTypes.string,
    braintreeClientTokenError: PropTypes.object,
    braintreeSubmitButtonDisabled: PropTypes.bool,
    createBraintreeSubscription: PropTypes.func,
    dispatch: PropTypes.func,
    hostedFields: PropTypes.object,
    hostedFieldsDidCreate: PropTypes.func,
    planSelected: PropTypes.string,
    routerPush: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);

    this.billingInfo = null;
    this.braintreePaymentForm = null;
    this.retryCount = 0;

    this.state = {
      error: null,
    };

    this.errorDisplayWording = {
      SOMETHING_BAD: 'Error: A bad error happened.',
      UNKNOWN_ERROR: 'Error',
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(getBraintreeClientToken());
    dispatch(getProfile());
    getBraintreePlans();
  }

  onPaymentFormSubmitted = () => {
    this.braintreePaymentForm.setSubmitButtonDisabled(true);

    return Promise.all([
      new Promise((resolve, reject) => {
        if (!this.props.planSelected) {
          this.braintreePaymentForm.setGeneralError(errorStrings.noSubscription);
          reject('Plan not selected');
        } else {
          resolve(true);
        }
      }),
      new Promise((resolve, reject) => {
        if (this.braintreePaymentForm.termsCheckbox.checked) {
          resolve(true);
        } else {
          this.braintreePaymentForm.setGeneralError(errorStrings.noTermsAndConditions);
          reject('Terms not accepted');
        }
      }),
    ])
      .then(() => (
        this.billingInfo.submitForm()
      ))
      .then(userProfile => (
        Promise.all([
          userProfile,
          this.braintreePaymentForm.sendPaymentMethod(),
        ])
      ))
      .then((values) => {
        const [userProfile, paymentInfo] = values;
        return this.createSubscription(paymentInfo, userProfile);
      })
      .catch((err) => {
        this.retryCount = 0;
        reportException(err, null, 'billing-subscribe-submit-form');
        this.braintreePaymentForm.setSubmitButtonDisabled(false);
        this.context.toast.error('Please try again.', 'Error', false, 3);
        return false;
      });
  };

  getMetaTags = () => {
    const title = 'FlightStats - Premium Subscription';
    return {
      title,
    };
  };

  getTierSelection = () => {
    const { planSelected, user = {} } = this.props;
    const { subscriptionActive } = user;
    if (!planSelected || planSelected === 'free' || subscriptionActive) {
      // default selection
      const options = this.subscriptionTierOptions();
      return (options[0] && options[0].value);
    }
    return findSubscriptionByType(planSelected).value;
  }

  subscriptionTierOptions = () => {
    const { user = {} } = this.props;
    const { subscriptionActive, subscriptionLevel } = user;
    const isActiveLevel = subscriptionActive ? subscriptionLevel : -1;
    return subscriptionOptions.filter(item => item.value > isActiveLevel && item.displayPurchase);
  };

  retryOnRejection = (err, subscriptionData, successBlock, failureBlock) => {
    if (err.code !== 422 && this.retryCount < 1) {
      this.retryCount++;
      return this.braintreePaymentForm.sendPaymentMethod()
        .then(payload => this.props.createBraintreeSubscription(Object.assign(
          subscriptionData,
          { nonce: payload.nonce },
        )))// new nonce, since nonce already used in first try
        .then(success => successBlock(success))
        .catch(error => failureBlock(error));
    }
    return failureBlock(err);
  }

  createSubscription = (braintreePayload, userProfile) => {
    const { additionalInfo = {} } = userProfile;
    const billingAddress = {
      cardholderName: additionalInfo.name,
      streetAddress: additionalInfo.streetAddress,
      locality: additionalInfo.city,
      region: additionalInfo.state,
      postalCode: additionalInfo.postalCode,
      countryCodeAlpha2: additionalInfo.countryCode,
    };

    if (additionalInfo.companyName) {
      billingAddress.company = additionalInfo.companyName;
    }

    // Now that we have a nonce, send it to the server
    // to create a payment method or a transaction
    const tierSelection = this.getTierSelection();
    const subscriptionData = {
      email: userProfile.email,
      nonce: braintreePayload.nonce,
      planId: subscriptionPlanIDForLevel(tierSelection),
      billingAddress,
    };

    const failureBlock = (err) => {
      if (this.retryCount === 1) { // we have failed twice already (retryCount starts at zero)
        this.retryCount = 0; // if next subscribe account fails, we still want to retry once
      }
      this.braintreePaymentForm.setGeneralError(cardErrorMessageForType(err.type));
      this.braintreePaymentForm.setSubmitButtonDisabled(false);
      this.context.toast.error(catchErrors(err), 'Error', false, 6);
    };

    const successBlock = (result) => {
      const {
        softError,
        type,
      } = result;
      if (softError) {
        // retry on a soft error as well
        if (this.retryCount < 1) {
          return this.retryOnRejection({}, subscriptionData, successBlock, failureBlock);
        }
        // else show the user the soft error
        this.braintreePaymentForm.setGeneralError(cardErrorMessageForType(type));
        this.braintreePaymentForm.setSubmitButtonDisabled(false);
        this.context.toast.error(cardErrorMessageForType(type), 'Error', false, 6);
        return Promise.resolve();
      }

      this.context.reportUserSubscribed(tierSelection);
      const transactionId = result && result.data && result.data.transactionId;
      const purchase = {
        affiliation: 'Subscription',
        name: subscriptionGAValueForLevel(tierSelection),
        revenue: subscriptionCostForLevel(tierSelection),
      };
      this.context.store.dispatch(getProfile())
        .then((userRes) => {
          purchase.id = transactionId || userRes.subscriptionBraintreeId;
          this.context.reportPurchase(purchase);
          this.context.goto('/account/success');
        });
      return result;
    };

    const retryBlock = (err) => {
      if (err.code !== 422 && this.retryCount < 1) {
        this.retryCount++;
        return this.braintreePaymentForm.sendPaymentMethod()
          .then(payload => this.props.createBraintreeSubscription(Object.assign(subscriptionData, { nonce: payload.nonce }))) // Use a new nonce for 2nd try
          .then(success => successBlock(success))
          .catch(error => failureBlock(error));
      }
      return failureBlock(err);
    };

    return this.props.createBraintreeSubscription(subscriptionData)
      .then(successBlock, retryBlock)
      .catch(error => failureBlock(error));
  };

  fullRow = content => (
    <div className='row spaced'>
      <div className='col-xs-12'>
        {content}
      </div>
    </div>
  );

  selectTier = (value) => {
    const plans = {
      0: 'free',
      1: 'standard',
      3: 'premium',
    };
    const plan = plans[value];
    this.props.dispatch(handlePlanSelection(plan));
  }

  render() {
    const meta = this.getMetaTags();
    let hasTokenError = false;

    const {
      braintreeClientToken,
      braintreeClientTokenError,
    } = this.props;

    if (braintreeClientTokenError) {
      hasTokenError = true;
    }

    const errorBlock = hasTokenError && this.fullRow(<div className='error-block'>
      <p className='account-error-text'>
        Sorry, there was a problem loading this page. Please try again.
        <br /><br />
        Details: {braintreeClientTokenError}
      </p>
    </div>);

    const enterpriseMessageBlock = isBusinessUser(this.props.user) && this.fullRow(<div className='error-block'>
      <p className='account-error-text'>You are already a member of a business subscription.</p>
    </div>);

    return (
      <div className='subscription-form'>
        <Helmet {...meta} />

        <div className='row'>
          <div className='col-xs-12' style={{ borderBottom: '4px solid #f8a631' }}>
            <div style={{ padding: '20px' }}>
              <h1 className='purchase-subscription-title'>Purchase Subscription</h1>
            </div>
          </div>
        </div>

        {enterpriseMessageBlock}
        {!enterpriseMessageBlock && errorBlock}

        {!hasTokenError && !enterpriseMessageBlock &&
          <div>
            <div className='row spaced'>
              <div className='section col-xs-offset-1 col-xs-10 col-sm-offset-0 col-sm-6 col-md-offset-1 col-md-5'>
                <SubscriptionTierSelection
                  subscriptionOptions={this.subscriptionTierOptions()}
                  selectedTier={this.getTierSelection()}
                  selectTier={this.selectTier}
                />
              </div>
              <div className='section col-xs-offset-1 col-xs-10 col-sm-offset-0 col-sm-6 col-md-offset-1 col-md-5' />
            </div>
            <div className='row spaced'>
              <div className='section col-xs-offset-1 col-xs-10 col-sm-offset-0 col-sm-6 col-md-offset-1 col-md-5'>
                <BillingInformation
                  user={this.props.user}
                  ref={(ref) => { this.billingInfo = ref; }}
                  updateProfile={this.props.updateProfile}
                />
              </div>
              <div className='section col-xs-offset-1 col-xs-10 col-sm-offset-0 col-sm-6 col-md-5'>
                {braintreeClientToken ?
                  <BraintreePaymentForm
                    braintreeSubmitButtonDisabled={this.props.braintreeSubmitButtonDisabled}
                    hostedFields={this.props.hostedFields}
                    hostedFieldsDidCreate={this.props.hostedFieldsDidCreate}
                    clientToken={braintreeClientToken}
                    ref={(ref) => { this.braintreePaymentForm = ref; }}
                    onPaymentFormSubmitted={this.onPaymentFormSubmitted}
                    user={this.props.user}
                    updateProfile={this.props.updateProfile}
                    subscriptionOptions={this.subscriptionTierOptions()}
                    tierSelection={this.getTierSelection()}
                  /> :
                  <div />
                }
              </div>
            </div>
          </div>}
      </div>
    );
  }
}
