import { NAME } from './constants';

export const LOAD_BY_ARRIVAL_AIRPORT = `${NAME}/LOAD_BY_ARRIVAL_AIRPORT`;
export const LOAD_BY_ARRIVAL_AIRPORT_SUCCESS = `${NAME}/LOAD_BY_ARRIVAL_AIRPORT_SUCCESS`;
export const LOAD_BY_ARRIVAL_AIRPORT_FAIL = `${NAME}/LOAD_BY_ARRIVAL_AIRPORT_FAIL`;
export const LOAD_BY_DEPARTURE_AIRPORT = `${NAME}/LOAD_BY_DEPARTURE_AIRPORT`;
export const LOAD_BY_DEPARTURE_AIRPORT_SUCCESS = `${NAME}/LOAD_BY_DEPARTURE_AIRPORT_SUCCESS`;
export const LOAD_BY_DEPARTURE_AIRPORT_FAIL = `${NAME}/LOAD_BY_DEPARTURE_AIRPORT_FAIL`;
export const LOAD_BY_ROUTE = `${NAME}/LOAD_BY_ROUTE`;
export const LOAD_BY_ROUTE_SUCCESS = `${NAME}/LOAD_BY_ROUTE_SUCCESS`;
export const LOAD_BY_ROUTE_FAIL = `${NAME}/LOAD_BY_ROUTE_FAIL`;
export const LOAD_SUCCESS = `${NAME}/LOAD_SUCCESS`;
export const LOAD_FAIL = `${NAME}/LOAD_FAIL`;
export const START_SPINNER = `${NAME}/START_SPINNER`;
export const END_SPINNER = `${NAME}/END_SPINNER`;
export const UPDATE_PAGE_INDEX = `${NAME}/UPDATE_PAGE_INDEX`;
export const SET_HISTORICAL_MULTI_ERROR_MESSAGE = `${NAME}/SET_HISTORICAL_MULTI_ERROR_MESSAGE`;
