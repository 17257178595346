import { NAME } from './constants';

export const getAirport = state => state[NAME];
export const getDetailsHeader = state => state[NAME];

export const getDetailsHeaderState = (globalState) => {
  const airportDetailsHeaderState = globalState[NAME]
    && globalState[NAME].data
    && globalState[NAME].data.detailsHeader;
  const state = {
    timerOn: false,
    airportCode: null,
    airportName: null,
    addressLine1: null,
    addressLine2: null,
    airportTZRegionName: null,
    latitude: null,
    longitude: null,
  };

  if (airportDetailsHeaderState) {
    state.airportCode = airportDetailsHeaderState.code;
    state.airportName = airportDetailsHeaderState.name;
    state.addressLine1 = airportDetailsHeaderState.addressLine1;
    state.addressLine2 = airportDetailsHeaderState.addressLine2;
    state.airportTZRegionName = airportDetailsHeaderState.timeZoneRegionName;
    state.latitude = airportDetailsHeaderState.latitude;
    state.longitude = airportDetailsHeaderState.longitude;
  }

  return state;
};

export const getTrackerState = (globalState) => {
  const airportDataState = globalState[NAME] && globalState[NAME].data;
  const initialLoad = globalState[NAME] && globalState[NAME].initialLoad;
  if (airportDataState) {
    return {
      airport: getDetailsHeaderState(globalState),
      delayIndex: airportDataState.delayIndex || null,
      currentWeather: airportDataState.currentWeather || null,
      airportCode: (airportDataState.params && airportDataState.params.airportCode) || null,
      initialLoad,
    };
  }
  return {
    initialLoad,
  };
};
