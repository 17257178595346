import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../../../../src/components/lib/Icon';

const MobileSubTitle = (props) => {
  const iconStyle = {
    width: '30px',
    height: '30px',
    marginTop: '-5px',
    fontWeight: 'bold',
  };

  return (
    <div className='mobile-header-subtitle'>
      <Icon src={props.icon} style={iconStyle} />
      <p className='text'>{props.text}</p>
    </div>
  );
};

MobileSubTitle.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
};

export default MobileSubTitle;
