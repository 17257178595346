import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { SelectField } from '@flightstats/component-lib';
import { identity, pathOr } from 'ramda';
import { push } from 'connected-react-router';
import { parse } from 'qs';
import {
  loadByDepartureAirportCarrierAndDate,
  loadByArrivingAirportCarrierAndDate,
  loadByRouteCarrierAndDate,
  multiFlightMobileSearchSearching,
  updateMultiFlightSearchTime,
  updatePageIndex,
} from '../../../../redux/actions';

const backupDateParams = (date, k) => (date[k]() + (k === 'month' ? 1 : 0));

const changeDepartureTime = props => (option) => {
  const { dispatch, match, queryString: propsQueryString, reportUserEvent } = props;
  const {
    arrivalAirportCode,
    departureAirportCode,
    carrier,
  } = match.params;
  const parsedQueryString = parse(propsQueryString, { ignoreQueryPrefix: true });
  const today = moment().hour(12);
  const [year, month, day] = ['year', 'month', 'date'].map(k =>
    pathOr(backupDateParams(today, k), [k], parsedQueryString));

  const {
    value,
  } = option;

  const queryString = `?year=${year}&month=${month}&date=${day}&hour=${value}`;
  const args = {
    ...match.params,
    hour: `${value}`, // string because '0' is truthy while 0 is falsy
    queryString,
  };

  dispatch(multiFlightMobileSearchSearching(true));
  dispatch(updateMultiFlightSearchTime(option));
  dispatch(updatePageIndex(1, '')); // reset pagination to 1

  reportUserEvent('Codeshares', 'FlightTrackerByAirportMobile-TimePeriod');

  if (arrivalAirportCode && departureAirportCode) {
    let arrivalAndDepartureUrl = `/flight-tracker/route/${departureAirportCode}/${arrivalAirportCode}`;
    if (carrier) {
      arrivalAndDepartureUrl += `/${carrier}`;
    }
    arrivalAndDepartureUrl += queryString;

    dispatch(loadByRouteCarrierAndDate(args));
    return dispatch(push(arrivalAndDepartureUrl));
  }

  if (arrivalAirportCode) {
    let arrivalAirportUrl = `/flight-tracker/arrivals/${arrivalAirportCode}`;
    if (carrier) {
      arrivalAirportUrl += `/${carrier}`;
    }
    arrivalAirportUrl += queryString;
    dispatch(loadByArrivingAirportCarrierAndDate(args));
    return dispatch(push(arrivalAirportUrl));
  }

  let departureAirportUrl = `/flight-tracker/departures/${departureAirportCode}`;
  if (carrier) {
    departureAirportUrl += `/${carrier}`;
  }
  departureAirportUrl += queryString;

  dispatch(loadByDepartureAirportCarrierAndDate(args));
  return dispatch(push(departureAirportUrl));
};

const ResultsFilters = props => (
  <div
    className='multi-search-results-filters advanced-search-bar mobile'
  >
    <section className={`${props.multiFlightResultsSearchError ? 'error' : ''}`}>
      <h4>
        { props.multiFlightResultsSearchError }
      </h4>
    </section>
    <p>Time Selector</p>
    <SelectField
      bgColor='rgba(191, 191, 191, 0.66)'
      disabled
      handleMenuSelection={changeDepartureTime(props)}
      iconColor='white'
      inputFieldBg={false}
      placeholderColor='white'
      results={props.timeOfTravelOptions}
      secondaryAction={props.secondaryAdvancedSearchActionTime ? props.secondaryAction : identity}
      selectWrapperPosition='relative'
      textColor='white'
      value={props.multiFlightSearchTime}
    />
  </div>
);

ResultsFilters.propTypes = {
  multiFlightResultsSearchError: PropTypes.string,
  multiFlightSearchTime: PropTypes.number,
  secondaryAction: PropTypes.func,
  secondaryAdvancedSearchActionTime: PropTypes.bool,
  timeOfTravelOptions: PropTypes.array,
};

export default ResultsFilters;
