import moment from 'moment';
import md5 from 'md5';
import alpha from '../../nato-phonetic-alphabet';

export function generateToken(givenServerUnixTime) {
  let hostTime = moment().utc();

  if (givenServerUnixTime) {
    const givenTime = moment(givenServerUnixTime * 1000).utc();
    const diffMillis = Math.abs(hostTime.diff(givenTime));

    if (diffMillis <= 60000) {
      hostTime = givenTime;
    }
  }

  const hash = md5(alpha[`${hostTime.month()}`] +
    alpha[`${hostTime.date()}`] +
    hostTime.format('YYYY-MM-DDTHH:mm'));

  return `${hostTime.utc().unix()}-${hash}`;
}

export function tokenMatches(token) {
  let matches = false;
  const parts = token.split('-');

  if (parts.length === 2) {
    const givenServerUnixTime = parts[0];
    const generatedToken = generateToken(givenServerUnixTime);
    matches = (generatedToken === token);
  }

  return matches;
}

export function verifyBearerToken(ErrorClass = Error) {
  return (req, res, next) => {
    try {
      if (req.headers.authorization) {
        const parts = req.headers.authorization.split(' ');

        if (parts.length == 2) {
          const sentToken = parts[1];

          if (tokenMatches(sentToken)) {
            return next();
          }
        }
      }

      const err = new ErrorClass('invalid_token', { message: 'You shall not pass!' });
      return res.status(403).send(err.message);
    } catch (err) {
      return next(err);
    }
  };
}
