const has = Object.prototype.hasOwnProperty;
const regionMap = {
  Africa: 'africa',
  Antarctica: 'antarctica',
  Asia: 'asia',
  Carribean: 'europe',
  'Central America': 'southAmerica',
  Europe: 'europe',
  'Middle East': 'asia',
  'North America': 'northAmerica',
  Oceania: 'oceania',
  'South America': 'southAmerica',
};

const filterAirports = (airportArray) => {
  let returnAirports = airportArray;
  const airports = {
    1: [],
  };

  // bucket1 for class 1 airports that are excessively delayed
  const bucket1 = [];
  // bucket 2 for class 1 airports that are significantly delayed
  const bucket2 = [];
  // bucket3 for class 2 airports that are excessively delayed
  const bucket3 = [];
  // bucket 4 for class 2 airports that are significantly delayed
  const bucket4 = [];
  // otherwise, return a random class one airport

  airportArray.forEach((airport) => {
    if (airport.classification === 1) {
      airports['1'].push(airport);
      if (airport.score === 5) {
        bucket1.push(airport);
      } else if (airport.score >= 3.5) {
        bucket2.push(airport);
      }
    } else if (airport.classification === 2 && (bucket1.length === 0 || bucket2.length === 0)) {
      if (airport.score === 5) {
        bucket3.push(airport);
      } else if (airport.score >= 3.5) {
        bucket4.push(airport);
      }
    }
  });

  if (bucket1.length > 0) {
    returnAirports = bucket1;
  } else if (bucket2.length > 0) {
    returnAirports = bucket2;
  } else if (bucket3.length > 0) {
    returnAirports = bucket3;
  } else if (bucket4.length > 0) {
    returnAirports = bucket4;
  } else {
    returnAirports = airports['1'];
  }

  return returnAirports;
};

const delayIndices = (details) => {
  if (details && details.airport && details.airport.fs) {
    const airport = {
      name: details.airport.name,
    };
    airport.code = details.airport.fs ? details.airport.fs : null;
    airport.position = (details.airport.latitude && details.airport.longitude) ?
      [details.airport.latitude, details.airport.longitude] : null;
    airport.score = has.call(details, 'normalizedScore') ? details.normalizedScore : null;
    airport.classification = details.airport.classification ? details.airport.classification : null;
    const mappedRegion = regionMap[details.airport.regionName];
    airport.regionName = details.airport.regionName ? mappedRegion : null;
    return airport;
  }
  return null;
};

const defaultAirports = arr => filterAirports(arr);

export { defaultAirports, delayIndices };
