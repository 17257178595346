import React from 'react';
import PropTypes from 'prop-types';
import getClientDimensions from '../../../../../../shared/lib/get-client-dimensions/index';
import componentBase from '../../../../../../src/lib/component-base';
import { prefersMPHSpeedFormat, prefersKPHSpeedFormat } from '../../../../../../src/lib/unit-measurement-format';

const windIcon = require('../../../../../../static/images/white-windsock.png');

let resizeTimeout;
let Raphael;

// TODO : could this be a utility function at some point?
const cardinalDirection = (numericalValue) => {
  if (numericalValue) {
    if (numericalValue >= 348.75 || numericalValue < 11.25) {
      return 'N';
    }
    if (numericalValue >= 11.25 && numericalValue < 33.75) {
      return 'NNE';
    }
    if (numericalValue >= 33.75 && numericalValue < 56.25) {
      return 'NE';
    }
    if (numericalValue >= 56.25 && numericalValue < 78.75) {
      return 'ENE';
    }
    if (numericalValue >= 78.75 && numericalValue < 101.25) {
      return 'E';
    }
    if (numericalValue >= 101.25 && numericalValue < 123.75) {
      return 'ESE';
    }
    if (numericalValue >= 123.75 && numericalValue < 146.25) {
      return 'SE';
    }
    if (numericalValue >= 146.25 && numericalValue < 168.75) {
      return 'SSE';
    }
    if (numericalValue >= 168.75 && numericalValue < 191.25) {
      return 'S';
    }
    if (numericalValue >= 191.25 && numericalValue < 213.75) {
      return 'SSW';
    }
    if (numericalValue >= 213.75 && numericalValue < 236.25) {
      return 'SW';
    }
    if (numericalValue >= 236.25 && numericalValue < 258.75) {
      return 'WSW';
    }
    if (numericalValue >= 258.75 && numericalValue < 281.25) {
      return 'W';
    }
    if (numericalValue >= 281.25 && numericalValue < 303.75) {
      return 'WNW';
    }
    if (numericalValue >= 303.75 && numericalValue < 326.25) {
      return 'NW';
    }
    if (numericalValue >= 326.25 && numericalValue < 348.75) {
      return 'NNW';
    }
  }

  return null;
};

const resizeThrottlerFunctionGenerator = actualResizeHandler => () => {
  if (!resizeTimeout) {
    resizeTimeout = setTimeout(() => {
      resizeTimeout = null;
      actualResizeHandler();
    }, 266);
  }
};

@componentBase('WeatherWindConditions')
export default class WeatherWindConditions extends React.Component {
  static propTypes = {
    speed: PropTypes.object,
    direction: PropTypes.number,
  };

  constructor(props, context) {
    super(props, context);

    this._adjustGraphOnResize = this._adjustGraphOnResize.bind(this);

    this.muiDefaultProps = {
      topStyle: {
        padding: '0',
        height: 'inherit',
        background: 'rgba(255, 255, 255, .1)',
      },
      topInnerStyle: {
        padding: '0',
        height: 'inherit',
        backgroundImage: `url('${windIcon}')`,
        backgroundSize: '55px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      },
    };
  }

  componentDidMount() {
    Raphael = require('raphael');
    this.resizeListener = resizeThrottlerFunctionGenerator(this._adjustGraphOnResize);
    window.addEventListener('resize', this.resizeListener);
    this._adjustGraphOnResize();
  }

  componentWillReceiveProps() {
    this._adjustGraphOnResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeListener);
  }

  _adjustGraphOnResize() {
    this._clearGraph();
    this._drawGraph();
  }

  _clearGraph() {
    if (this.paper) {
      this.paper.clear();
    }
  }

  _drawGraph() {
    const [xWidth, xHeight] = getClientDimensions(document, 'windGraph');
    this.paper = this.paper || Raphael(this.refs.windGraph, '100%', xHeight); // eslint-disable-line
    const dirCircle = this.paper.circle(xWidth / 2, xHeight / 2, xHeight / 2 - 15).attr({
      stroke: '#FFFFFF',
      'stroke-width': 2,
    });

    // uncomment 'degLineTEST' to view direction as a red line...
    //  const degLineTEST = this.paper.path(`M${xWidth / 2}, 15, L${xWidth / 2}, ${xHeight / 2}`)
    //    .attr('stroke', 'rgba(255, 0, 0, 0.6)')
    //    .attr('stroke-width', '2');
    //  degLineTEST.rotate(this.props.direction, xWidth / 2, xHeight / 2);

    const degLine1 = this.paper.path(`M${xWidth / 2}, 15, L${xWidth / 2}, ${30}`)
      .attr('stroke', '#FF9600')
      .attr('stroke-width', '1')
      .attr('visibility', 'hidden');
    const degLine2 = this.paper.path(`M${xWidth / 2}, 3, L${xWidth / 2}, ${27}`)
      .attr('stroke', '#FF9600')
      .attr('stroke-width', '4')
      .attr('visibility', 'hidden');
    const degLine3 = this.paper.path(`M${xWidth / 2}, 3, L${xWidth / 2}, ${27}`)
      .attr('stroke', '#FF9600')
      .attr('stroke-width', '4')
      .attr('visibility', 'hidden');
    const dirPointCircle = this.paper.circle(xWidth / 2, xHeight / 2 - dirCircle.attrs.r, xHeight / 2 - 55)
      .attr('stroke', '#FF9600')
      .attr('stroke-width', '2')
      .attr('fill', '#FF9600')
      .attr('visibility', 'hidden');

    degLine2.rotate(90, xWidth / 2, xHeight / 2);
    degLine3.rotate(270, xWidth / 2, xHeight / 2);

    // make things visible
    degLine1.attr('visibility', 'visible');
    degLine2.attr('visibility', 'visible');
    degLine3.attr('visibility', 'visible');

    if (this.props.direction) {
      dirPointCircle.rotate(this.props.direction, xWidth / 2, xHeight / 2);
      dirPointCircle.attr('visibility', 'visible');
    }
  }

  _getWindSpeed() {
    const windSpeed = { value: '--', units: 'kts' };

    if (prefersMPHSpeedFormat(this.context.currentUser)) {
      windSpeed.units = 'mph';
      if (this.props.speed && this.props.speed.mph) {
        windSpeed.value = this.props.speed.mph;
      }
    } else if (prefersKPHSpeedFormat(this.context.currentUser)) {
      windSpeed.units = 'km/h';
      if (this.props.speed && this.props.speed.kph) {
        windSpeed.value = this.props.speed.kph;
      }
    } else if (this.props.speed && this.props.speed.knots) {
      windSpeed.value = this.props.speed.knots;
    }

    return windSpeed;
  }

  _getWindDirection() {
    if (this.props.direction) {
      return cardinalDirection(this.props.direction);
    }

    return null;
  }

  render() {
    // this.logger('render : props', this.props);

    // wind direction div
    const windDirection = this._getWindDirection();
    let windDirectionDiv = null;
    if (windDirection) {
      windDirectionDiv = (
        <div style={{ marginTop: '-5px' }}><span style={{ fontSize: '8pt', color: '#FF9600' }}>{ windDirection }</span></div>
      );
    }

    const windSpeed = this._getWindSpeed();

    return (
      <div className='row' style={this.muiDefaultProps.topStyle}>
        <div className='col-xs-12' style={this.muiDefaultProps.topInnerStyle}>
          <div id='windGraph' ref='windGraph' className='col-xs-12 col-sm-12 col-md-12' style={{ position: 'absolute', height: 'inherit' }} />
          <div className='col-xs-12 col-sm-12 col-md-12' style={{ padding: '0', height: '45%' }}>
            <div
              className='col-xs-12 col-sm-12 col-md-12'
              style={{
 position: 'absolute', padding: '0', bottom: '0', textAlign: 'center',
}}
            >
              <span style={{ fontSize: '12pt' }}>Wind</span>
            </div>
          </div>
          <div className='col-xs-12 col-sm-12 col-md-12' style={{ padding: '0', height: '55%' }}>
            <div
              className='col-xs-12 col-sm-12 col-md-12'
              style={{
 position: 'absolute', padding: '0', top: '0', textAlign: 'center',
}}
            >
              <span style={{ fontSize: '20pt', fontWeight: 'bold', color: '#FF9600' }}>{ windSpeed.value }</span>&nbsp;
              <span style={{ fontSize: '8pt', color: '#FF9600' }}>{ windSpeed.units }</span>
              { windDirectionDiv }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
