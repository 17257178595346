import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { identity, pathOr, path } from 'ramda';
import { connect } from 'react-redux';
import moment from 'moment';

import { media } from '../../../src/lib/css-media';
import FieldLabel from '../FieldLabel/FieldLabel';
import TravelDateSelectField from '../TravelDateSelectField/TravelDateSelectField';
import AirlineSelect from '../../../src/components/lib/AirlineSelect';
import flexErrors from '../../../src/constants/flex-errors';
import { updateMultiFlightSearchCodeShareToggle } from '../../../modules/MultiFlight/redux/actions';
import { setSingleFlightSecondarySearchAction } from '../../../modules/SingleFlight/redux/actions';
import {
  setAdvancedSearchByFlightError,
  setAirline,
  setFlightNumber,
  setDepartureDate,
  updatingFlightNumberInput,
} from '../../../modules/FlightTracker/redux/Search/actions';

const Container = styled.div`
  margin-bottom: 10px;
`;

const FieldRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`;

const Field = styled.div`
  width: 48%;
  min-width: 260px;
  font-size: 13px;
  margin-bottom: 15px;
  ${media.desktop`
    width: 100%;
  `}
`;

const AirlineField = styled(Field)`
`;

const FlightNumberField = styled(Field)`
`;

const DateField = styled(Field)`
`;

@connect(state => ({
  airline: state.FlightTrackerSearch.airline,
  byFlightError: state.FlightTrackerSearch.byFlightError,
  flightNumber: state.FlightTrackerSearch.flightNumber,
  departureDate: state.FlightTrackerSearch.departureDate,
  secondaryAdvancedSearchActionAirline:
    state.FlightTrackerSearch.secondaryAdvancedSearchActionAirline,
  secondaryAdvancedSearchActionDepDate:
    state.FlightTrackerSearch.secondaryAdvancedSearchActionDepDate,
  userAgent: state.App.userAgent,
}))
export default class SearchByFlight extends Component {
  static propTypes = {
    airline: PropTypes.object,
    byFlightError: PropTypes.string,
    departureDate: PropTypes.string,
    dispatch: PropTypes.func,
    flightNumber: PropTypes.string,
    history: PropTypes.object,
    reportButtonPress: PropTypes.func,
    secondaryAdvancedSearchActionAirline: PropTypes.bool,
    secondaryAdvancedSearchActionDepDate: PropTypes.bool,
    textFieldBGColor: PropTypes.string,
    foregroundColor: PropTypes.string,
    user: PropTypes.object,
    userAgent: PropTypes.object,
  }

  componentDidMount() {
    this.props.dispatch(setDepartureDate(this.initialDepartureDate(), false));
  }

  getCodes = (airLinePort) => {
    if (airLinePort) {
      const { value } = airLinePort;
      const { _source } = value;
      return (_source && (_source.fs || _source.iata || _source.icao)) || null;
    }
    return null;
  }

  buildSearchByFlightUrl = () => {
    const {
      airline,
      flightNumber,
      departureDate,
    } = this.props;

    const [year, month, day] = departureDate.split('/');
    const airlineCode = this.getCodes(airline);

    if (flightNumber && airlineCode) {
      return `/flight-tracker/${airlineCode}/${flightNumber}?year=${year}&month=${month}&date=${day}`;
    }

    return null;
  }

  gotoResults = (destination) => {
    const {
      dispatch,
      history,
    } = this.props;
    // reset search triggers
    dispatch(setSingleFlightSecondarySearchAction(true));
    const topOfStackHistory = path(['location', 'pathname'], history);
    if (destination && topOfStackHistory !== destination) {
      // multiple identical entries were being pushed into history on enter keydown
      window.location.href = `/v2${destination}`;
    }
  }

  searchByFlight = () => {
    const {
      dispatch,
      reportButtonPress,
      user,
    } = this.props;
    const errorCode = this.validateByFlightSearch();
    dispatch(setAdvancedSearchByFlightError(errorCode));
    const hideCodeshares = pathOr(false, ['hideCodeshares'], user);
    dispatch(updateMultiFlightSearchCodeShareToggle(hideCodeshares));

    reportButtonPress && reportButtonPress('Search By Flight');
    if (errorCode == null) {
      this.gotoResults(this.buildSearchByFlightUrl());
    }
  }

  changeDepartureDate = (option, triggeredByEnter) =>
    this.props.dispatch(setDepartureDate(option.value, triggeredByEnter));

  initialDepartureDate = () => {
    const { departureDate } = this.props;
    if (departureDate) {
      return moment(departureDate, 'YYYY/MM/DD').format('YYYY/M/D');
    }
    return moment().format('YYYY/M/D');
  }

  flightNumHandler = (event) => {
    const flightNumber = event.target.value;
    if (/^\+?(0|[1-9]\d*)$/.test(flightNumber) || flightNumber === '') {
      this.props.dispatch(setFlightNumber(flightNumber));
    }
  }

  changeState = (reduxAction, option, triggeredByEnter) => {
    this.props.dispatch(reduxAction(option, triggeredByEnter));
  }

  changeAirline = (option, triggeredByEnter) => {
    this.changeState(setAirline, option, triggeredByEnter);
  }

  handleKeyDownFlightNumber = (e) => {
    if (e.keyCode === 13) {
      // enter has been pressed
      e.preventDefault();
      this.secondaryByFlightAction(true);
    }
  }

  handleBlurFlightNumber = () => {
    this.props.dispatch(updatingFlightNumberInput(false));
  }

  secondaryByFlightAction = (fromKeyDownHandler) => {
    if (fromKeyDownHandler) {
      // enter pressed in the flight number input inputField
      this.searchByFlight();
    }
  }

  validateByFlightSearch = () => {
    const {
      airline,
      flightNumber,
    } = this.props;

    if (!airline) {
      // If there is no airline
      return 'MISSING_AIRLINE_CODE';
    } else if (!flightNumber) {
      // If there is no flight number
      return 'MISSING_FLIGHT_NUMBER';
    }

    return null;
  }

  render() {
    const {
      airline,
      byFlightError,
      flightNumber,
      secondaryAdvancedSearchActionDepDate,
      secondaryAdvancedSearchActionAirline,
      departureDate,
      userAgent,
      textFieldBGColor,
      foregroundColor,
      user,
    } = this.props;

    const { isMobile } = userAgent;
    const secondaryAction = this.secondaryByFlightAction;

    const errorBlock = byFlightError && (
      <div className='search-error-text-block'>
        <p className='search-error-text'>{flexErrors(byFlightError)}</p>
      </div>
    );

    /*
    .advanced-search-flight-search-fields
      .advanced-search-half-fields-container
        .advanced-search-field
          p
          input.advanced-search-text-field
    */

    return (
      <Container>
        {errorBlock}
        <FieldRow>
          <AirlineField>
            <FieldLabel text='Airline' />
            <AirlineSelect
              name='airline'
              airline={airline}
              onChange={(option, triggeredByEnter) =>
                this.changeAirline(option, triggeredByEnter)}
              isMobile={isMobile}
              shouldFocus
              secondaryAction={secondaryAdvancedSearchActionAirline ? secondaryAction : identity}
              selectOnBlur
            />
          </AirlineField>
          <FlightNumberField>
            <FieldLabel text='Flight Number' />
            <input
              className='advanced-search-text-field'
              onChange={this.flightNumHandler}
              type='text'
              name='flight-number'
              value={flightNumber}
              placeholder='Example: 200'
              autoComplete='off'
              onKeyDown={this.handleKeyDownFlightNumber}
              onBlur={this.handleBlurFlightNumber}
            />
          </FlightNumberField>
        </FieldRow>
        <FieldRow>
          <DateField>
            <FieldLabel text='Date' />
            <TravelDateSelectField
              value={departureDate}
              foregroundColor={foregroundColor}
              backgroundColor={textFieldBGColor}
              onChange={this.changeDepartureDate}
              secondaryAction={secondaryAdvancedSearchActionDepDate ? secondaryAction : identity}
              user={user}
            />
          </DateField>
        </FieldRow>
      </Container>
    );
  }
}
