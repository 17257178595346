import React, { Component } from 'react';
import componentBase from '../../../../src/lib/component-base';
import HistoricalByFlightSearch from './children/HistoricalByFlightSearch';
import HistoricalByAirportSearch from './children/HistoricalByAirportSearch';

@componentBase('HistoricalSearch')
export default class HistoricalSearch extends Component {
  render = () => (
    <section className='historical-search'>
      <HistoricalByFlightSearch {...this.props} />
      <HistoricalByAirportSearch {...this.props} />
    </section>
  );
}
