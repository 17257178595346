import React from 'react';
import PropTypes from 'prop-types';
import componentBase from '../../../../../../src/lib/component-base';
import Sky from '../../../../../AirportCurrentConditions/components/children/ConditionsTracker/children/WeatherSkyConditions';
import Wind from '../../../../../AirportCurrentConditions/components/children/ConditionsTracker/children/WeatherWindConditions';
import Visibility from '../../../../../AirportCurrentConditions/components/children/ConditionsTracker/children/WeatherVisibilityConditions';
import onResize from '../../../../../../src/lib/on-resize';

const graphDivHeight = 99;
const trackerDivHeight = 125;

const resizeThrottlerFunctionGenerator = actualResizeHandler => () => {
  if (!resizeTimeout) {
    resizeTimeout = setTimeout(() => {
      resizeTimeout = null;
      actualResizeHandler();
    }, 66);
  }
};

let resizeTimeout;


@onResize()
@componentBase('WeatherConditions')
export default class WeatherConditions extends React.Component {
  static propTypes = {
    currentWeather: PropTypes.object,
  }

  constructor(props) {
    super(props);
    this.muiDefaultProps = {
      divHeights: {
        tracker: trackerDivHeight,
        indexGraph: graphDivHeight,
        statusAndPhase: 25,
      },
    };
  }

  componentDidMount() {
    this.resizeListener = resizeThrottlerFunctionGenerator(this.forceUpdate.bind(this));
    window.addEventListener('resize', this.resizeListener);
    this.forceUpdate();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeListener);
  }


  getSkyProps() {
    const props = {
      available: false,
      icon: null,
      coverage: null,
      tempF: null,
      tempC: null,
    };

    if (this.props.currentWeather) {
      props.icon = this.props.currentWeather.icon;
      props.coverage = this.props.currentWeather.sky;
      props.tempF = this.props.currentWeather.tempF;
      props.tempC = this.props.currentWeather.tempC;

      if (props.icon || props.coverage || props.tempF || props.tempC) {
        props.available = true;
      }
    }

    return props;
  }

  getWindProps() {
    const props = {
      available: false,
      speed: null,
      direction: null,
    };

    if (this.props.currentWeather) {
      props.speed = this.props.currentWeather.wind;
      props.direction = this.props.currentWeather.direction;

      if (props.speed || props.direction) {
        props.available = true;
      }
    }

    return props;
  }

  getVisibilityProps() {
    const props = {
      available: false,
      visibility: null,
      otherWeatherAvailable: (this.getSkyProps().available ||
                              this.getWindProps().available),
    };

    if (this.props.currentWeather) {
      props.visibility = this.props.currentWeather.visibility;

      if (props.visibility) {
        props.available = true;
      }
    }

    return props;
  }

  render() {
    const skyProps = this.getSkyProps();
    const windProps = this.getWindProps();
    const visProps = this.getVisibilityProps();
    let noWeather = null;
    let noWeatherXS = null;
    if (!skyProps.available && !windProps.available && !visProps.available) {
      noWeather = (
        <div className='hidden-xs col-sm-12 no-weather' style={{ padding: 0, height: `${this.muiDefaultProps.divHeights.tracker}px`, position: 'absolute' }}>
          <div
            className='col-sm-12'
            style={{
 padding: 0, height: `${this.muiDefaultProps.divHeights.tracker - 2}px`, border: '1px solid white', background: 'rgba(0, 0, 0, .7)',
}}
          >
            <div
              className='col-sm-12'
              style={{
 padding: '0', height: '40%', top: '50%', position: 'relative', WebkitTransform: 'translateY(-50%)', MsTransform: 'translateY(-50%)', transform: 'translateY(-50%)', MozTransform: 'translateY(-50%)',
}}
            >
              <div
                className='col-sm-12'
                style={{
 position: 'absolute', padding: '0', bottom: '0', textAlign: 'center',
}}
              >
                <span style={{ fontSize: '18pt' }}>Current Weather Conditions<br />Are Not Available</span>
              </div>
            </div>
          </div>
        </div>
      );
      noWeatherXS = (
        <div
          className='visible-xs col-sm-12 no-weather-small'
          style={{
 padding: 0, height: `${this.muiDefaultProps.divHeights.tracker}px`, position: 'absolute', width: '100%',
}}
        >
          <div
            className='col-sm-12'
            style={{
 padding: 0, height: `${this.muiDefaultProps.divHeights.tracker - 2}px`, border: '1px solid white', background: 'rgba(0, 0, 0, .7)', width: '100%',
}}
          >
            <div
              className='col-sm-12'
              style={{
 padding: '0', width: '100%', height: '40%', top: '50%', position: 'relative', WebkitTransform: 'translateY(-50%)', MsTransform: 'translateY(-50%)', transform: 'translateY(-50%)', MozTransform: 'translateY(-50%)',
}}
            >
              <div
                className='col-sm-12'
                style={{
 position: 'absolute', width: 'inherit', padding: '0', bottom: '0', textAlign: 'center',
}}
              >
                <span style={{ fontSize: '18pt' }}>Current Weather Conditions<br />Are Not Available</span>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className='row weather-container'>
        <div className='col-sm-12' style={{ padding: 0 }}>
          <div className='col-sm-4 col-xs-4' style={{ padding: 0, height: `${this.muiDefaultProps.divHeights.tracker}px` }}>
            <div className='col-sm-12' style={{ padding: 0, height: `${this.muiDefaultProps.divHeights.tracker - 2}px`, border: '1px solid white' }}>
              <Sky {...skyProps} />
            </div>
          </div>
          <div className='col-sm-4 col-xs-4' style={{ padding: 0, height: `${this.muiDefaultProps.divHeights.tracker}px` }}>
            <div className='col-sm-12' style={{ padding: 0, height: `${this.muiDefaultProps.divHeights.tracker - 2}px`, border: '1px solid white' }}>
              <Wind {...windProps} />
            </div>
          </div>
          <div className='col-sm-4 col-xs-4' style={{ padding: 0, height: `${this.muiDefaultProps.divHeights.tracker}px` }}>
            <div className='col-md-12' style={{ padding: 0, height: `${this.muiDefaultProps.divHeights.tracker - 2}px`, border: '1px solid white' }}>
              <Visibility {...visProps} />
            </div>
          </div>
          {noWeather}
          {noWeatherXS}
        </div>
      </div>
    );
  }
}
