import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from 'material-ui/MenuItem';
import Icon from '../../../../../../src/components/lib/Icon';
import lockIcon from '../../../../../../static/images/menu-icons/LockedLockBLACK.svg';

const setLockIconRightMarginAndReturnLockStyle = {
  width: '16px',
  height: '16px',
  top: '10px',
  right: '13px',
  margin: 0,
};

const FSMenuItem = (props) => {
  const {
    linkTo,
    onClick,
    onClickTab,
    onClickTerm,
    primaryText,
    showLock,
    openTabHandler,
    menuItemClickHandler,
  } = props;

  const rightLock = showLock ? (
    <Icon
      src={lockIcon}
      style={setLockIconRightMarginAndReturnLockStyle}
    />
  ) : <span />;

  const onClickAction = onClick || (
    onClickTab ?
      openTabHandler(linkTo, onClickTerm) : menuItemClickHandler(linkTo, onClickTerm)
  );

  const href = linkTo.includes('http') ? linkTo : `/v2${linkTo}`;

  return (
    <MenuItem
      className='menu-item'
      style={{ backgroundColor: 'transparent',
        lineHeight: '9px',
        minHeight: 0,
        height: '34px',
      }}
      innerDivStyle={{
        color: 'black',
        backgroundColor: 'transparent',
        padding: 0,
      }}
      primaryText={primaryText}
      containerElement={
        /* eslint-disable jsx-a11y/anchor-has-content */
        <a
          href={href}
          title={primaryText}
          onClick={e => e.preventDefault()}
        />
        /* eslint-enable jsx-a11y/anchor-has-content */
      }
      rightIcon={rightLock}
      onClick={(e) => {
        onClickAction && onClickAction(e);
      }}
    />
  );
};

FSMenuItem.propTypes = {
  linkTo: PropTypes.string,
  menuItemClickHandler: PropTypes.func,
  onClick: PropTypes.func,
  onClickTab: PropTypes.bool,
  onClickTerm: PropTypes.string,
  openTabHandler: PropTypes.func,
  primaryText: PropTypes.string,
  showLock: PropTypes.bool,
};

export default FSMenuItem;
