import React from 'react';
import PropTypes from 'prop-types';
import AccountModalTextarea from './AccountModalTextarea';
import AccountModalUnsubscribeWarning from './AccountModalUnsubscribeWarning';

const baseStyleProps = {
  color: 'black',
  fontSize: '16px',
  lineHeight: '16px',
};

const headerStyleProps = {
  fontSize: '16px',
  fontWeight: 600,
};

const UnsubscribeModalBody = props => (
  <div style={{ margin: '0 10px 15px' }}>
    <div style={{
 ...baseStyleProps, color: 'black', fontWeight: 600, paddingBottom: '11px',
}}
    >
      <h3 style={{
        ...headerStyleProps,
        color: 'black',
        margin: '4px 0 10px',
      }}
      >
        Your feedback is incredibly valuable to our team at FlightStats.
      </h3>
      <h3 style={{ ...headerStyleProps, color: 'black' }}>
        {`
          If you don't mind us asking,
          what made you cancel your subscription?
          What could we do better?`
        }
      </h3>
    </div>
    <AccountModalTextarea
      value={props.reasonForUnsubscribing}
      updateValue={props.updateReasonForUnsubscribing}
    />
    <AccountModalUnsubscribeWarning
      renewalDate={props.renewalDate}
      textStyle={headerStyleProps}
    />
  </div>
);

UnsubscribeModalBody.propTypes = {
  reasonForUnsubscribing: PropTypes.string,
  renewalDate: PropTypes.string,
  updateReasonForUnsubscribing: PropTypes.func,
};

export default UnsubscribeModalBody;
