import React from 'react';
import StackedBarChart from './children/StackedBarChart';

const FlightAlertsHistoryGraph = props => (
  <div className='alerts-graph-wrapper'>
    <StackedBarChart {...props} />
  </div>
);

export default FlightAlertsHistoryGraph;
