import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '@flightstats/component-lib';

const mainText = (params) => {
  const { carrierCode, carrierName, flightNumber } = params;
  const carrierText = carrierCode ? `(${carrierCode})` : '';
  return `${carrierText} ${carrierName || ''} ${flightNumber || ''} On-Time Performance Rating`;
};

const OrangeBar = styled.div`
  height: 5px;
  background-color: ${colors.fsOrange};
  width: 100%;
  margin-top: 5px;
`;

const HeaderText = styled.h1`
  font-size: 25px;
  font-weight: 600;
  padding: 0 10px;
`;

const Header = ({ params }) => {
  const titleText = params ? mainText(params) : 'On-Time Performance Rating';
  return (
    <div className='one-time-title'>
      <HeaderText>
        {titleText}
      </HeaderText>
      <OrangeBar />
    </div>
  );
};

Header.propTypes = {
  params: PropTypes.object,
};

export default Header;
