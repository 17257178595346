import React from 'react';
import PropTypes from 'prop-types';

export default class ToggleSwitch extends React.Component {
  static propTypes = {
    on: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onValue: PropTypes.string.isRequired,
    offValue: PropTypes.string.isRequired,
    callback: PropTypes.func, // optional component call to be made
  };

  static defaultProps = {
    on: false,
    onValue: '1',
    offValue: '0',
  };

  componentDidMount() {
    this.update();
  }

  getChecked() {
    const checkbox = this.refs[this.props.name];
    return checkbox && checkbox.checked;
  }

  getValue() {
    const checkbox = this.refs[this.props.name];
    return checkbox && (checkbox.checked ? this.props.onValue : this.props.offValue);
  }

  update = () => {
    this.value = this.getValue();
    this.checked = this.getChecked();

    if (this.props.callback) {
      this.props.callback();
    }
  }

  render() {
    const id = `toggle-switch-${this.props.name}`;
    return (
      <div className='toggle-switch'>
        <input
          type='checkbox'
          name={this.props.name}
          defaultValue={this.props.onValue}
          className='toggle-switch-checkbox'
          id={id}
          onChange={this.update}
          defaultChecked={this.props.on}
          ref={this.props.name}
        />
        <label className='toggle-switch-label' htmlFor={id} />
      </div>
    );
  }
}
