import moment from 'moment';
import { pathOr, propOr } from 'ramda';
import SearchSource from '../../../../src/sources/search';
import * as t from './actionTypes';

export const setAirline = (airline, triggeredByEnter) => ({
  type: t.SET_AIRLINE,
  airline,
  secondarySearchAction: triggeredByEnter,
  secondaryAdvancedSearchAction: triggeredByEnter,
});

export const setByAirportAirline = (byAirportAirline, triggeredByEnter) => ({
  type: t.SET_BY_AIRPORT_AIRLINE,
  byAirportAirline,
  secondarySearchAction: triggeredByEnter,
  secondaryAdvancedSearchAction: triggeredByEnter,
});

export const setArrivalAirport = (arrivalAirport, triggeredByEnter) => ({
  type: t.SET_ARRIVAL_AIRPORT,
  arrivalAirport,
  secondaryAdvancedSearchAction: triggeredByEnter,
});

export const setDepartureAirport = (departureAirport, triggeredByEnter) => ({
  type: t.SET_DEPARTURE_AIRPORT,
  departureAirport,
  secondaryAdvancedSearchAction: triggeredByEnter,
});

export const setDepartureDate = (departureDate, triggeredByEnter) => ({
  type: t.SET_DEPARTURE_DATE,
  departureDate,
  secondaryAdvancedSearchAction: triggeredByEnter,
});

export const setByAirportDepDate = (byAirportDepDate, triggeredByEnter) => ({
  type: t.SET_BY_AIRPORT_DEP_DATE,
  byAirportDepDate,
  secondaryAdvancedSearchAction: triggeredByEnter,
});

export const setDepartureTime = (departureTime, triggeredByEnter) => ({
  type: t.SET_DEPARTURE_TIME,
  departureTime,
  secondaryAdvancedSearchAction: triggeredByEnter,
});

export const setFlightNumber = flightNumber => ({
  type: t.SET_FLIGHT_NUMBER,
  flightNumber,
});

export const setFlightTrackerClearErrors = () => ({
  type: t.SET_FLIGHT_TRACKER_CLEAR_ERRORS,
});

export const setFlightTrackerSearchError = errorCode => ({
  type: t.SET_FLIGHT_TRACKER_SEARCH_ERROR,
  errorCode,
});

export const setAdvancedSearchByFlightError = errorCode => ({
  type: t.SET_ADVANCED_SEARCH_BY_FLIGHT_ERROR,
  errorCode,
});

export const setAdvancedSearchByAirportError = errorCode => ({
  type: t.SET_ADVANCED_SEARCH_BY_AIRPORT_ERROR,
  errorCode,
});

export const setRandomFlightPath = () => async (dispatch, getStore) => {
  dispatch({ type: t.SET_RANDOM_FLIGHT_PATH });
  try {
    const serverTime = pathOr(moment().unix(), ['Account', 'serverTime'], getStore());
    const { randomFlight } = SearchSource;
    const { data } = await randomFlight(serverTime);

    const source = data.length ? propOr({}, '_source', data[0]) : {};

    if (!data.length) {
      return dispatch({
        type: t.SET_RANDOM_FLIGHT_PATH_SUCCESS,
        randomFlightPath: '/flight-tracker/AA/100',
      });
    }

    const { carrier, departureDateTime, flightId, flightNumber } = source;
    const ddt = moment(departureDateTime);
    const [year, month, day] = ['year', 'month', 'date']
      .map(interval => ddt[interval]());
    const randomFlightPath = `/flight-tracker/${carrier}/${flightNumber}?year=${year}&month=${month + 1}&date=${day}&flightId=${flightId}`;
    dispatch({
      type: t.SET_RANDOM_FLIGHT_PATH_SUCCESS,
      randomFlightPath,
    });
  } catch (err) {
    console.log('Error updating random flight:', err);
    return dispatch({
      type: t.SET_RANDOM_FLIGHT_PATH_FAIL,
      error: err,
    });
  }
};

export const setUniversalSearchFlight = universalSearchFlight => ({
  type: t.SET_UNIVERSAL_SEARCH_FLIGHT,
  universalSearchFlight,
});

export const setFlightTrackerQueryError = o => ({
  type: t.SET_FLIGHT_TRACKER_QUERY_ERROR,
  data: {
    error: o.error,
  },
});

export const setSecondarySearchAction = bool => ({
  type: t.SET_SECONDARY_SEARCH_ACTION,
  bool,
});

export const setSecondaryAdvancedSearchAction = bool => ({
  type: t.SET_SECONDARY_ADVANCED_SEARCH_ACTION,
  bool,
});

export const setSecondaryAdvancedSearchActionAirline = bool => ({
  type: t.SET_SECONDARY_AIRLINE,
  bool,
});

export const setSecondaryAdvancedSearchActionByAirportAirline = bool => ({
  type: t.SET_SECONDARY_BY_AIRPORT_AIRLINE,
  bool,
});

export const setSecondaryAdvancedSearchActionDepAirport = bool => ({
  type: t.SET_SECONDARY_DEP_AIRPORT,
  bool,
});

export const setSecondaryAdvancedSearchActionArrAirport = bool => ({
  type: t.SET_SECONDARY_ARR_AIRPORT,
  bool,
});

export const setSecondaryAdvancedSearchActionDepDate = bool => ({
  type: t.SET_SECONDARY_DEP_DATE,
  bool,
});

export const setSecondaryAdvancedSearchActionTime = bool => ({
  type: t.SET_SECONDARY_TIME,
  bool,
});

export const setAllAdvancedSearchAsyncBools = bool => ({
  type: t.SET_SECONDARY_ALL_BOOL,
  bool,
});

export const updatingFlightNumberInput = bool => ({
  type: t.UPDATING_FLIGHT_NUMBER_INPUT,
  bool,
});
