// @flow
import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { loadReferenceData } from './redux/actions';
import { shouldLoad as shouldLoadFn } from '../../src/redux/utils';
import { NAME } from './redux/constants';
import { addMetaCanonical } from '../../shared/lib/meta-tags';
import FSFlatButton from '../../src/components/lib/FSFlatButton';

type LinkData = {
  name: string,
  airportCode?: string,
  airlineCode?: string,
  classification: number,
};

type Props = {
  shouldLoad: boolean,
  dispatch: Function,
  airports: Array<LinkData>,
  error: string,
  pathname: string,
};

type MetaTag = {
  meta: Array<any>,
  title: string,
}

const metaTags = (pathname: string): MetaTag => {
  const title = 'Airport Departures, Arrivals, Current Conditions';
  const description = 'Explore airport departures, arrivals, and current conditions';
  const keywords = [
    title,
    'airports',
    'departures',
    'arrivals',
    'current conditions',
  ];

  return addMetaCanonical({
    title,
    meta: [
      { name: 'robots', content: 'noindex' },
      { name: 'description', content: description },
      { name: 'keywords', content: keywords },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
    ],
  }, pathname);
};


const mapStateToProps = (state: Object): Object => ({
  shouldLoad: shouldLoadFn(state, NAME),
  airports: state.Sitemap.airports,
  airlines: state.Sitemap.airlines,
  error: state.Sitemap.error,
});

const baseLinks = [
  { departures: '/flight-tracker/departures' },
  { arrivals: '/flight-tracker/arrivals' },
  { 'Current Conditions': '/airport-conditions' },
];

/* eslint-disable */
// @ma - no suitable ID or anything to use except i
const generateLinks = (code: string = '') : Array<React$Element<any>> => baseLinks.map((o: Object, i: number) => {
  const type = Object.keys(o)[0];
  const value = String(Object.values(o)[0]) || '';

  return (
    <Fragment key={i}>
      <Link
        style={{ textTransform: 'capitalize'}}
        to={`${value}/${code}`}
      >
        {type}
      </Link>
      {i < 2 && <span>, </span>
      }
    </Fragment>
  );
});
const generateName = (name: string, code: ?string): string => (code ? `${name} (${code})` : '');

const createLinks = (data: Array<LinkData>) => data.map((d, i) => {
  const links = generateLinks(d.airportCode);

  return (
    <p key={i}>
      {generateName(d.name, d.airportCode)}&nbsp;
      [{links}]
    </p>
)
});
/* eslint-enable */
class SiteMap extends Component<Props> {
  componentWillMount() {
    const { shouldLoad, dispatch } = this.props;

    if (shouldLoad) {
      dispatch(loadReferenceData());
    }
  }

  render() {
    const { airports, pathname, error } = this.props;

    if (error) {
      return (
        <Fragment>
          <h2>{error}</h2>
          <FSFlatButton
            href='/'
            label='home'
          />
        </Fragment>
      );
    }

    return (
      <Fragment>
        <Helmet {...metaTags(pathname)} />
        <h1>Airport Departures, Arrivals, and Current Conditions</h1>
        <div>{createLinks(airports)}</div>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps)(SiteMap);
