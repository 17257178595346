import { validate } from 'email-validator';

const isValidCarrier = (selectedCarrier, carriersThatAreSupported) =>
  (carriersThatAreSupported.reduce((prev, current) => {
    if (current.value === selectedCarrier) return true;
    return prev;
  }, false));

const isValidEmail = e => validate(e);

const isValidPassword = p => (p.length >= 8 && p.length <= 30);

const isValidPhoneNumber = (n) => {
  const regexBase = '[0-9]{6,30}'; // digits only, between 6 and 30 numbers

  return new RegExp(`^${regexBase}$`).test(n);
};


export {
  isValidCarrier,
  isValidEmail,
  isValidPassword,
  isValidPhoneNumber,
};
