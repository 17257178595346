import React from 'react';
import PropTypes from 'prop-types';

const ChartItem = (props) => {
  const {
    children,
    containerClassNames,
  } = props;
  return (
    <div
      className={`${containerClassNames}`}
    >
      {children}
    </div>
  );
};

ChartItem.propTypes = {
  children: PropTypes.array,
  containerClassNames: PropTypes.string,
};

export default ChartItem;
