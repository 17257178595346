import PropTypes from 'prop-types';
import { DomEvent, DomUtil, Control, latLng, latLngBounds, tileLayer } from 'leaflet';
import MapControlBase from './MapControlBase';
import componentBase from '../../lib/component-base';

const activeBtnBkg = `url(${require('../../../static/images/flight-tracker/active-button.png')})`;
const inactiveBtnBkg = `url(${require('../../../static/images/flight-tracker/inactive-button.png')}`;
const weatherIconBkg = `url(${require('../../../static/images/flight-tracker/weather-icon.png')}`;

// create a square on the map covering the area that
// weather radar is available include hawaii and alaska.
const northEast = latLng(78.73350050778467, -49.04296875);
const southWest = latLng(9.96885060854611, -168.75);
const bounds = latLngBounds(southWest, northEast);

@componentBase('RadarTileLayer')
export default class RadarTileLayer extends MapControlBase {
  static propTypes = {
    map: PropTypes.object,
  };

  constructor(props, context) {
    super(props);
    this.control = null;

    this.map = context.map;
  }

  componentDidMount() {
    const MAX_ZOOM = 8;
    const MIN_ZOOM = 3;
    const radarLayer = tileLayer('//weather-tiles-0{s}.flightstats.com/{z}/{x}/{y}.png', {
      subdomains: '1234',
      zIndex: 1000,
      opacity: 0.6,
      minZoom: MIN_ZOOM,
      maxZoom: MAX_ZOOM,
      bounds,
    });

    this.map.addLayer(radarLayer);

    const Ctrl = Control.extend({
      options: {
        position: 'topleft',
      },

      reset: this.reset(),

      onAdd: (map) => {
        let initialLoadZoomend = true;
        let initialLoadMoveend = true;

        const container = DomUtil.create('div', 'leaflet-bar leaflet-control');
        container.style.display = (this.props.hide ? 'none' : null);

        const preloadActive = DomUtil.create('div', container);
        preloadActive.style.backgroundImage = activeBtnBkg;

        const preloadInactive = DomUtil.create('div', container);
        preloadInactive.style.backgroundImage = inactiveBtnBkg;

        const preloadWeatherIcon = DomUtil.create('div', container);
        preloadWeatherIcon.style.backgroundImage = weatherIconBkg;

        const parent = DomUtil.create('div', 'leaflet-bar-part', container);
        parent.style.width = '75px';
        parent.style.height = '75px';
        parent.style.backgroundImage = preloadActive.style.backgroundImage;
        parent.style.backgroundSize = '75px 75px';
        parent.style.backgroundRepeat = 'no-repeat';
        parent.style.cursor = 'pointer';
        parent.style.boxShadow = '2px 2px 10px rgba(0, 0, 0, 0.5)';

        const innerChild = DomUtil.create('div', 'leaflet-bar-part', parent);
        innerChild.style.width = '75px';
        innerChild.style.height = '75px';
        innerChild.style.backgroundImage = preloadWeatherIcon.style.backgroundImage;
        innerChild.style.backgroundSize = '75px 75px';
        innerChild.style.backgroundRepeat = 'no-repeat';

        const innerChild1 = DomUtil.create('div', 'leaflet-bar-part', innerChild);
        innerChild1.style.width = '17px';
        innerChild1.style.height = '75px';
        innerChild1.style.float = 'left';

        const innerChild2 = DomUtil.create('div', 'leaflet-bar-part', innerChild);
        innerChild2.style.width = '58px';
        innerChild2.style.height = '75px';
        innerChild2.style.float = 'left';

        const innerChild2Text = DomUtil.create('div', 'leaflet-bar-part', innerChild2);
        innerChild2Text.style.width = 'inherit';
        innerChild2Text.style.position = 'absolute';
        //  innerChild2Text.style.top = '20%';
        innerChild2Text.style.top = '25%';
        innerChild2Text.style.textAlign = 'center';

        const innerChild2TextLabel = DomUtil.create('div', 'leaflet-bar-part', innerChild2Text);
        innerChild2TextLabel.style.fontSize = '12px';
        innerChild2TextLabel.innerHTML = 'US Weather';

        const innerChild2TextSubLabel = DomUtil.create('div', 'leaflet-bar-part', innerChild2Text);
        innerChild2TextSubLabel.style.padding = '0 2px';
        innerChild2TextSubLabel.style.fontSize = '8px';
        innerChild2TextSubLabel.innerHTML = 'Show US Weather on the map.';
        innerChild2TextSubLabel.style.display = 'none'; // off for now...

        DomEvent
          .on(container, 'dblclick', DomEvent.stopPropagation)
          .on(container, 'dblclick', DomEvent.preventDefault)
          .on(container, 'click', (e) => {
            DomEvent.stopPropagation(e);
            DomEvent.preventDefault(e);

            if (map.hasLayer(radarLayer)) {
              map.removeLayer(radarLayer);
              parent.style.backgroundImage = preloadInactive.style.backgroundImage;
              this.context.reportButtonPress('TrackerWeather', 0);
            } else {
              map.addLayer(radarLayer);
              parent.style.backgroundImage = preloadActive.style.backgroundImage;
              this.context.reportButtonPress('TrackerWeather', 1);
            }
          });

        map.on('zoomend', () => {
          if (map.getZoom() > MAX_ZOOM || map.getZoom() < MIN_ZOOM) {
            container.style.display = 'none';
          } else if (container.style.display === 'none' && initialLoadZoomend && this.props.hide) {
            initialLoadZoomend = false;
          } else if (container.style.display === 'none') {
            if (parent.style.backgroundImage === preloadInactive.style.backgroundImage) {
              map.removeLayer(radarLayer);
            } else {
              parent.style.backgroundImage = preloadActive.style.backgroundImage;
            }

            container.style.display = null;
          }
        });
        map.on('moveend', () => {
          if (map.getZoom() > MAX_ZOOM || map.getZoom() < MIN_ZOOM) {
            container.style.display = 'none';
          } else if (container.style.display === 'none' && initialLoadMoveend && this.props.hide) {
            initialLoadMoveend = false;
          } else if (container.style.display === 'none') {
            if (parent.style.backgroundImage === preloadInactive.style.backgroundImage) {
              map.removeLayer(radarLayer);
            } else {
              parent.style.backgroundImage = preloadActive.style.backgroundImage;
            }

            container.style.display = null;
          }
        });

        return container;
      },
    });

    this.control = new Ctrl();
    this.map.addControl(this.control);
  }
}
