import React from 'react';
import PropTypes from 'prop-types';
import { CalloutGroup } from '@flightstats/component-lib';
import { path, pathOr } from 'ramda';
import { push } from 'connected-react-router';
import SearchByFlight from '../../../../../../shared/components/SearchByFlight/SearchByFlight';
import AdvancedSearchByAirportRoute from '../../../../../../shared/components/AdvancedSearchByAirportRoute/AdvancedSearchByAirportRoute';
import SearchSectionContainer from '../../../../../../shared/components/SearchSectionContainer/SearchSectionContainer';
import componentBase from '../../../../../../src/lib/component-base';
import HFSBar from '../../../../../../static/images/HFS-Banner.jpg';
import FTBar from '../../../../../../static/images/FT-Banner.jpg';
import { setSingleFlightSecondarySearchAction } from '../../../../../SingleFlight/redux/actions';
import { updateMultiFlightSearchCodeShareToggle } from '../../../../../MultiFlight/redux/actions';
import Ad from '../../../../../../shared/components/Ad/Ad';
import { UnifiedSearch as unifiedSearchAds } from '../../../ads';
import {
  setAdvancedSearchByFlightError,
  updatingFlightNumberInput,
  setAllAdvancedSearchAsyncBools,
} from '../../../../redux/Search/actions';

const calloutSectionTitle = 'Other Search Options';

@componentBase('AdvancedSearch')
export default class AdvancedSearch extends React.Component {
  static propTypes = {
    adUnits: PropTypes.object,
    airline: PropTypes.object,
    byAirportAirline: PropTypes.object,
    depArrClick: PropTypes.func,
    departureDate: PropTypes.string,
    dispatch: PropTypes.func,
    flightNumber: PropTypes.string,
    historicalClick: PropTypes.func,
    history: PropTypes.object,
    randomFlightClick: PropTypes.func,
    reportButtonPress: PropTypes.func,
    user: PropTypes.object,
  };

  componentWillReceiveProps() {
    const {
      dispatch,
    } = this.props;
    dispatch(setAllAdvancedSearchAsyncBools(false));
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    // reset the booleans that trigger secondary calls
    dispatch(updatingFlightNumberInput(false));
    dispatch(setAllAdvancedSearchAsyncBools(false));
    // reset the booleans that trigger secondary calls
  }

  getCodes = (airLinePort) => {
    if (airLinePort) {
      const { value } = airLinePort;
      const { _source } = value;
      return (_source && (_source.fs || _source.iata || _source.icao)) || null;
    }
    return null;
  }

  calloutAd = () => {
    const subscriptionActive = pathOr(false, ['user', 'subscriptionActive'], this.props);
    const { third } = unifiedSearchAds;
    if (!subscriptionActive) {
      return [(
        <div
          className='smart-search-ad-box'
          key={third.id}
        >
          <Ad
            width={325}
            key={third.id}
            {...third}
            subscriptionActive={subscriptionActive}
          />
        </div>
      )];
    }
    return null;
  };

  calloutConfigs = [
    {
      backgroundImage: HFSBar,
      title: 'Looking for flights more than 3 days in the past?',
      label: 'Historical Flight Status',
      onClick: this.props.historicalClick,
    },
    {
      backgroundImage: FTBar,
      title: 'Just want to see the flight tracker?',
      label: 'Track a Random Flight',
      onClick: this.props.randomFlightClick,
    },
  ]

  // ///////////////////////////////
  // TODO: DRY this up
  buildSearchByFlightUrl = () => {
    const {
      airline,
      flightNumber,
      departureDate,
    } = this.props;

    const [year, month, day] = departureDate.split('/');
    const airlineCode = this.getCodes(airline);

    if (flightNumber && airlineCode) {
      return `/v2/flight-tracker/${airlineCode}/${flightNumber}?year=${year}&month=${month}&date=${day}`;
    }

    return null;
  }

  gotoResults = (destination) => {
    const {
      dispatch,
      history,
    } = this.props;
    // reset search triggers
    dispatch(setSingleFlightSecondarySearchAction(true));
    const topOfStackHistory = path(['location', 'pathname'], history);
    if (destination && topOfStackHistory !== destination) {
      // multiple identical entries were being pushed into history on enter keydown
      dispatch(push(destination));
    }
  }

  searchByFlight = () => {
    const {
      dispatch,
      reportButtonPress,
      user,
    } = this.props;
    const errorCode = this.validateByFlightSearch();
    dispatch(setAdvancedSearchByFlightError(errorCode));
    const hideCodeshares = pathOr(false, ['hideCodeshares'], user);
    dispatch(updateMultiFlightSearchCodeShareToggle(hideCodeshares));

    reportButtonPress('Advanced Search By Flight');
    if (errorCode == null) {
      const url = this.buildSearchByFlightUrl();
      // initiate a new request to the Next app
      window.location = process.env.NODE_ENV === 'development' ? `http://localhost:4000${url}` : url;
    }
  }

  validateByFlightSearch = () => {
    const {
      airline,
      flightNumber,
    } = this.props;

    if (!airline) {
      // If there is no airline
      return 'MISSING_AIRLINE_CODE';
    } else if (!flightNumber) {
      // If there is no flight number
      return 'MISSING_FLIGHT_NUMBER';
    }

    return null;
  }
  // ///////////////////////////////

  render() {
    const {
      byAirportAirline,
      dispatch,
      reportButtonPress,
      user,
    } = this.props;

    const subscriptionActive = pathOr(false, ['subscriptionActive'], user);

    return (
      <section className='AdvancedSearchContainer'>
        <SearchSectionContainer
          adUnit={unifiedSearchAds.top}
          searchAction={this.searchByFlight}
          subscriptionActive={subscriptionActive}
          title='Search by Flight'
        >
          <SearchByFlight
            dispatch={dispatch}
            title='Search by Flight'
            reportButtonPress={reportButtonPress}
            user={user}
          />
        </SearchSectionContainer>
        <AdvancedSearchByAirportRoute
          byAirportAirline={byAirportAirline}
          adUnit={unifiedSearchAds.second}
          dispatch={dispatch}
          title='Search by Airport or Route'
          user={user}
        />
        <CalloutGroup
          ads={this.calloutAd()}
          configs={this.calloutConfigs}
          sectionTitle={calloutSectionTitle}
        />
      </section>
    );
  }
}
