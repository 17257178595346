import React from 'react';
import PropTypes from 'prop-types';
import componentBase from '../../../../../../src/lib/component-base';

@componentBase('FlightAlertsHeader')
export default class FlightAlertsHeader extends React.Component {
  static propTypes = {
    date: PropTypes.string,
    leftSide: PropTypes.string,
  };

  renderHeader = () => (
    <span>
      <h1>{ this.props.leftSide }</h1>
      <h1>{ this.props.date }</h1>
    </span>
  );

  render() {
    return (
      <div className='flight-alerts-header'>
        { this.renderHeader() }
      </div>
    );
  }
}
