import { SESSION_KEY } from './constants';
import * as t from './actionTypes';

const hasWindowStorage = () => (typeof sessionStorage !== 'undefined' && sessionStorage && [sessionStorage]);

const getSession = () => {
  const defaultItem = {};
  try {
    const getParsedItem = storageBox =>
      storageBox.map(s => s.getItem(SESSION_KEY))
        .map(s => s || '{}')
        .map(s => JSON.parse(s))
        .find(k => k);
    const storage = hasWindowStorage();
    return storage ? getParsedItem(storage) : defaultItem;
  } catch (err) {
    console.log('Error getting session:', err);
    return defaultItem;
  }
};

const setSession = (session) => {
  try {
    const stringified = JSON.stringify(session);
    const storage = hasWindowStorage();
    const setStringifiedSession = storageBox =>
      storageBox.map(s => s.setItem(SESSION_KEY, stringified));
    if (storage) setStringifiedSession(storage);
  } catch (err) {
    console.log('Error setting session:', err);
  }
};

export const setSessionItem = (key, value) => (dispatch) => {
  const session = getSession();
  session[key] = value;
  setSession(session);
  return dispatch({ type: t.SET_SESSION_ITEM });
};

export const setComponentState = (componentName, frozenState) => (dispatch) => {
  const session = getSession();
  session.componentStates = session.componentStates || {};
  session.componentStates[componentName] = frozenState;
  setSession(session);
  return dispatch({ type: t.SET_COMPONENT_STATE });
};
