import React, { Component } from 'react';
import { prop } from 'ramda';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';

import componentBase from '../../../../../../../../src/lib/component-base';
import FSFlatButton from '../../../../../../../../src/components/lib/FSFlatButton';
import { dateFormatString, formattedTime, prefersMDYDateFormat } from '../../../../../../../../src/lib/date-time-format';
import TimeComponent from './children/TimeComponent';
import TimeToDOM from '../../../../../../../../src/components/TimeToDOM/TimeToDOM';
import handleFirstLoad from '../../../../../../../../shared/lib/handle-first-component-load';
import { getAirportCodeSimple } from '../../../../../../../../shared/lib/airport-code-helpers';

const extractFs = prop('fs');

const getAirportDateParams = (date) => {
  const momentDate = date ? moment(date) : moment();
  return {
    year: momentDate.year(),
    month: momentDate.month() + 1,
    day: momentDate.date(),
  };
};

@componentBase('HistoricalFlightInformation')
export default class HistoricalFlightInformation extends Component {
  static propTypes = {
    additionalFlightInfo: PropTypes.object,
    arrivalAirport: PropTypes.object,
    arrivalTimes: PropTypes.object,
    carrier: PropTypes.object,
    codeshares: PropTypes.object,
    departureAirport: PropTypes.object,
    departureTimes: PropTypes.object,
    dispatch: PropTypes.func,
    divertedAirport: PropTypes.object,
    downlines: PropTypes.array,
    eventTimeline: PropTypes.array,
    extendedDetails: PropTypes.bool,
    extendedDetailsAds: PropTypes.array,
    flightState: PropTypes.string,
    hasCredits: PropTypes.bool,
    location: PropTypes.object,
    match: PropTypes.object,
    operatedBy: PropTypes.string,
    sortTime: PropTypes.string,
    status: PropTypes.object,
    uplines: PropTypes.array,
    url: PropTypes.string,
  };

  static defaultProps = {
    additionalFlightInfo: null,
    arrivalAirport: null,
    arrivalTimes: null,
    carrier: null,
    codeshares: null,
    departureAirport: null,
    divertedAirport: null,
    eventTimeline: [],
    flightId: 0,
    flightState: null,
    location: {},
    operatedBy: null,
    schedule: null,
    sortTime: null,
    status: null,
    url: '',
  };

  constructor(props, context) {
    super(props, context);
    this.currentFlightState = null;
    this.state = {
      firstLoad: true,
    };
  }

  componentWillReceiveProps(nextProps) {
    const newState = this.handleFirstLoad(this.state.firstLoad);
    this.setState(newState);
    this.reportFlightState(nextProps);
  }

  getTimesCell = (isDeparture, isGate) => {
    const title = isGate ? 'Flight Gate Times' : 'Flight Runway Times';
    const {
      arrivalAirport,
      departureAirport,
    } = this.props;
    const source = isDeparture ? departureAirport : arrivalAirport;
    const validDate = (source && source.date) || moment();
    const date = moment(validDate).format(dateFormatString(this.context.currentUser));
    const times = {};
    let delayText = '';
    const { delay } = this.props.status;

    if (isDeparture) {
      const { departureTimes } = this.props;
      if (isGate) {
        times.scheduled = departureTimes.scheduledGate;
        times.actual = departureTimes.actualGate;
        times.estimated = departureTimes.estimatedGate;
        const delayString = this.formatDelayString(delay.departure.gateMinutes, isDeparture);
        delayText = `Total Departure Delay: ${delayString}`;
      } else {
        times.scheduled = departureTimes.scheduledRunway;
        times.actual = departureTimes.actualRunway;
        times.estimated = departureTimes.estimatedRunway;
        const delayString = this.formatDelayString(delay.departure.runwayMinutes, isDeparture);
        delayText = `Runway Delay: ${delayString}`;
      }
    } else {
      const { arrivalTimes } = this.props;
      if (isGate) {
        times.scheduled = arrivalTimes.scheduledGate;
        times.actual = arrivalTimes.actualGate;
        times.estimated = arrivalTimes.estimatedGate;
        const delayString = this.formatDelayString(delay.arrival.gateMinutes, isDeparture);
        delayText = `Total Arrival Delay: ${delayString}`;
      } else {
        times.scheduled = arrivalTimes.scheduledRunway;
        times.actual = arrivalTimes.actualRunway;
        times.estimated = arrivalTimes.estimatedRunway;
        const delayString = this.formatDelayString(delay.arrival.runwayMinutes, isDeparture);
        delayText = `Runway Delay: ${delayString}`;
      }
    }

    const isEstimated = !!(!times.actual && times.estimated);

    return (
      <div className='times' style={{ borderBottom: '1px solid white' }}>
        <p className='title'>{title}</p>
        <p className='date'>{date}</p>
        <div className='timeBlock' style={{ width: '50%', display: 'inline-block' }}>
          <p className='title'>Scheduled</p>
          <TimeComponent time={times.scheduled} />
        </div>
        <div className='timeBlock' style={{ width: '50%', display: 'inline-block' }}>
          <p className='title'>{isEstimated ? 'Estimated' : 'Actual'}</p>
          <TimeComponent time={isEstimated ? times.estimated : times.actual} />
        </div>
        <p className='delayText'>{delayText}</p>
      </div>
    );
  }

  getAdditionalInfoDetails = (classes, title, detail) => (
    <div className={`${classes}`}>
      <p className='title'>{title}</p>
      <h4 className='detail'>{detail || '-'}</h4>
    </div>
  );

  getAdditionalInfoDetailsLeft = (classes, title, subtitle, detail) => (
    <div className={`${classes}`}>
      <p className='title'>{title}</p>
      <p className='subtitle'>{subtitle}</p>
      <h4 className='detail'>{detail || '-'}</h4>
    </div>
  );

  getFlightCell = (isDeparture) => {
    const title = isDeparture ? 'Departure' : 'Arrival';
    const airport = isDeparture ? this.props.departureAirport : this.props.arrivalAirport;
    let baggage = '';
    let terminal = '';
    let tail = '';
    let gate = '';
    let craftType = '';
    let flightTime = '';

    let airportNameSubtitle = airport.name;

    if (airport.state) {
      airportNameSubtitle = `${airportNameSubtitle}, ${airport.state}`;
    }
    if (airport.country) {
      airportNameSubtitle = `${airportNameSubtitle}, ${airport.country}`;
    }

    const {
      additionalFlightInfo = {},
      arrivalAirport,
      departureAirport,
      status,
    } = this.props;
    const { equipment = {} } = additionalFlightInfo;
    const { tailNumber = '-' } = equipment;

    if (!isDeparture) {
      const {
        flightTimeLabel,
        flightTimeValue,
      } = this.getFlightTime(additionalFlightInfo.flightDuration);

      flightTime = this.getAdditionalInfoDetailsLeft(
        'col-xs-12 col-sm-6 flightTimeBlock',
        'Flight Time', flightTimeLabel, flightTimeValue,
      );
      terminal = this.getAdditionalInfoDetails(
        'col-xs-3 terminalBlock',
        'Terminal', arrivalAirport.terminal,
      );
      gate = this.getAdditionalInfoDetails(
        'col-xs-3 gateBlock',
        'Gate', arrivalAirport.gate,
      );
      baggage = this.getAdditionalInfoDetails(
        'col-xs-6 baggageBlock',
        'Baggage Claim', arrivalAirport.baggage,
      );
      tail = this.getAdditionalInfoDetailsLeft(
        'col-xs-12 col-sm-6 tailNumberBlock',
        'Tail Number', '', tailNumber,
      );
    } else {
      flightTime = this.getAdditionalInfoDetailsLeft(
        'col-xs-12 col-sm-6 flightTimeBlock',
        'Flight Time', 'Scheduled', additionalFlightInfo.flightDuration.scheduled,
      );
      terminal = this.getAdditionalInfoDetails(
        'col-xs-3 terminalBlock',
        'Terminal', departureAirport.terminal,
      );
      gate = this.getAdditionalInfoDetails(
        'col-xs-3 gateBlock',
        'Gate', departureAirport.gate,
      );
      craftType = this.getAdditionalInfoDetailsLeft(
        'col-xs-12 col-sm-6 craftTypeBlock',
        'Craft Type', equipment.name, equipment.iata,
      );
    }

    return (
      <div className={`col-xs-12 col-sm-6 flightCell flight-ticket ${status.color}`} style={{ textAlign: 'center', marginBottom: '10px' }}>
        <h2 className={`flight-ticket-header ${status.color} departureArrivalTitle`}>{title}</h2>
        <div>
          <div className='airportDiv'>
            <h2 className='airportCodeTitle'>{getAirportCodeSimple(airport)}</h2>
            <p className='airportNameSubtitle'>{airportNameSubtitle}</p>
          </div>
          <div className={`innerStyle ${isDeparture ? 'departure' : ''}`}>
            { this.getTimesCell(isDeparture, true) }
            { this.getTimesCell(isDeparture, false) }
            <div className='row additionalInfo'>
              {terminal}
              {gate}
              {baggage}
            </div>
            <div className='row moreDetails'>
              {tail}
              {craftType}
              {flightTime}
            </div>
          </div>
        </div>
      </div>
    );
  }

  getFlightTime = (flightDuration) => {
    let flightTimeLabel;
    let flightTimeValue;

    if (flightDuration.actual) {
      flightTimeLabel = 'Actual';
      flightTimeValue = flightDuration.actual;
    } else if (flightDuration.estimated) {
      flightTimeLabel = 'Estimated';
      flightTimeValue = flightDuration.estimated;
    }

    return { flightTimeLabel, flightTimeValue };
  }

  getTimeString = (scheduled, actual, estimated) => {
    let scheduledTime = '--';
    let actualTime = '--';
    let estimatedTime = '--';

    if (scheduled) {
      const scheduledAmpm = formattedTime(this.context.currentUser, scheduled, null, 'ampm');

      if (scheduledAmpm) {
        scheduledTime = `${scheduled.time}${scheduledAmpm} ${scheduled.timezone}`;
      } else {
        scheduledTime = `${scheduled.time24} ${scheduled.timezone}`;
      }
    }

    if (actual) {
      const actualAmpm = formattedTime(this.context.currentUser, actual, null, 'ampm');

      if (actualAmpm) {
        actualTime = `${actual.time}${actualAmpm} ${actual.timezone}`;
      } else {
        actualTime = `${actual.time24} ${actual.timezone}`;
      }
    } else if (estimated) {
      const estimatedAmpm = formattedTime(this.context.currentUser, estimated, null, 'ampm');

      if (estimatedAmpm) {
        estimatedTime = `${estimated.time}${estimatedAmpm} ${estimated.timezone}`;
      } else {
        estimatedTime = `${estimated.time24} ${estimated.timezone}`;
      }

      return (<p className='timeString'>{scheduledTime} / <span className='estimated-time'>{estimatedTime}</span></p>);
    }

    return (<p className='timeString'>{scheduledTime} / <span className='actual-time'>{actualTime}</span></p>);
  }

  getMobileStopTopRow = (lineInfo, text1, text2, number, showArrow) => {
    let numberBlock = (<div className={`col-xs-1 flight-ticket-header ${lineInfo.status.color}`} />);
    let arrowBlock = (<div className={`col-xs-1 historical-flight-status-box ${lineInfo.status.color}`} />);

    if (showArrow) {
      numberBlock = (
        <div className={`col-xs-1 flight-ticket-header ${lineInfo.status.color}`} style={{ textAlign: 'center' }}>
          <h2>{number}</h2>
        </div>
      );
      arrowBlock = (
        <div className={`col-xs-1 historical-flight-status-box ${lineInfo.status.color}`} style={{ textAlign: 'center' }}>
          <h2>&gt;</h2>
        </div>
      );
    }

    return (
      <div className='row show-xs hidden-sm hidden-md hidden-lg mobileRow'>
        {numberBlock}
        <div className='col-xs-3' style={{ height: '14px' }}>
          <p className={`flight-ticket-header ${lineInfo.status.color} topRow centerTitles`}>{text1}</p>
        </div>
        <div className='col-xs-7'>
          <p className={`flight-ticket-header ${lineInfo.status.color} topRow centerTitles`}>{text2}</p>
        </div>
        {arrowBlock}
      </div>
    );
  }

  getMobileStopRow = (lineInfo, text1, text2) => (
    <div className='row show-xs hidden-sm hidden-md hidden-lg mobileRow'>
      <div className={`col-xs-1 flight-ticket-header ${lineInfo.status.color}`} />
      <div className='col-xs-3'>
        <p className='timeTitle'>{text1}</p>
      </div>
      <div className='col-xs-7'>
        {text2}
      </div>
      <div className={`col-xs-1 historical-flight-status-box ${lineInfo.status.color}`} />
    </div>
  )

  getOtherStopCell = (lineInfo, index) => {
    const number = index + 1;
    const {
      arrivalAirport,
      arrivalTimes,
      departureAirport,
      departureTimes,
      flightId,
      status,
      url,
    } = lineInfo;

    let statusWording = (status.statusDescription && status.status) ? `${status.statusDescription}` : (status.statusDescription || status.status);
    let statusDescriptionBar = '';

    if (status.diverted) {
      statusWording = status.status;
      statusDescriptionBar = (
        <div className={`col-xs-12 flight-status-box ${status.color}`}>
          <p className='col-sm-offset-1 segmentStatusDesc'>This flight has been diverted to {status.statusDescription}</p>
        </div>
      );
    } else if (status.delayed) {
      statusWording = 'Delayed';
      statusDescriptionBar = (
        <div className={`col-xs-12 flight-status-box ${status.color}`}>
          <p className='col-sm-offset-1 segmentStatusDesc'>This flight has been {status.statusDescription.toLowerCase()}</p>
        </div>
      );
    }

    const departureGate = this.getTimeString(
      departureTimes.scheduledGate,
      departureTimes.actualGate,
      departureTimes.estimatedGate,
    );

    const departureRunway = this.getTimeString(
      departureTimes.scheduledRunway,
      departureTimes.actualRunway,
      departureTimes.estimatedRunway,
    );

    const arrivalGate = this.getTimeString(
      arrivalTimes.scheduledGate,
      arrivalTimes.actualGate,
      arrivalTimes.estimatedGate,
    );

    const arrivalRunway = this.getTimeString(
      arrivalTimes.scheduledRunway,
      arrivalTimes.actualRunway,
      arrivalTimes.estimatedRunway,
    );

    const destination = this.props.hasCredits ? url : '/historical-flight/search';

    return (
      <Link
        key={lineInfo.flightId}
        to={url}
        onClick={(e) => {
          e.preventDefault();
          this.handleOtherStopClick(destination);
        }}
        style={{ textDecoration: 'none' }}
      >
        <div className={`row flight-ticket ${status.color}`} style={{ marginBottom: '10px' }}>
          <div className='row hidden-xs' key={`${flightId}-BOTTOM`}>
            <div className={`col-xs-1 flight-ticket-header ${status.color}`} style={{ height: '120px', textAlign: 'center' }}>
              <h1 className='stopNumber'>{number}</h1>
            </div>
            <div className='col-sm-2'>
              <p className={`flight-ticket-header ${status.color} topRow`}>{statusWording}</p>
              <p className='timeTitle'>Gate Times</p>
              <p className='timeTitle'>Runway Times</p>
            </div>
            <div className='col-sm-4'>
              <p className={`flight-ticket-header ${status.color} topRow centerTitles`}>{`(${getAirportCodeSimple(departureAirport)}) ${departureAirport.city}`}</p>
              {departureGate}
              {departureRunway}
            </div>
            <div className='col-sm-4'>
              <p className={`flight-ticket-header ${status.color} topRow centerTitles`}>{`(${getAirportCodeSimple(arrivalAirport)}) ${arrivalAirport.city}`}</p>
              {arrivalGate}
              {arrivalRunway}
            </div>
            <div className={`col-xs-1 hidden-md hidden-lg historical-flight-status-box ${status.color}`} style={{ height: '120px', textAlign: 'center' }}>
              <h1 className='stopNumber'>&gt;</h1>
            </div>
            <div className={`col-md-1 hidden-xs hidden-sm flight-status-box ${status.color}`} style={{ height: '120px', textAlign: 'center' }}>
              <h1 className='stopNumber'>&gt;</h1>
            </div>
          </div>
          {this.getMobileStopTopRow(lineInfo, statusWording, `(${getAirportCodeSimple(departureAirport)}) ${departureAirport.city}`, number, false)}
          {this.getMobileStopRow(lineInfo, 'Gate Times', departureGate)}
          {this.getMobileStopRow(lineInfo, 'Runway Times', departureRunway)}
          {this.getMobileStopTopRow(lineInfo, '', `(${getAirportCodeSimple(arrivalAirport)}) ${arrivalAirport.city}`, number, true)}
          {this.getMobileStopRow(lineInfo, 'Gate Times', arrivalGate)}
          {this.getMobileStopRow(lineInfo, 'Runway Times', arrivalRunway)}
          {statusDescriptionBar}
        </div>
      </Link>
    );
  }

  getExtendedDetailsButtons = () => {
    if (!this.props.extendedDetails) {
      return null;
    }

    const flightNotFinal = this.activeFlight();
    const { match: { params: { carrier, flightNumber } } } = this.props;
    return (
      <div className='row'>
        {this.buttonWithURLHelper('VIEW FLIGHT STATUS', 'flightTrackerButton', this.flightTrackerUrl(), 'ViewFlightTracker', `View ${carrier} ${flightNumber} Flight Tracker`)}
        {this.buttonWithURLHelper('VIEW AIRPORT DELAYS', 'airportDelaysButton', '/airport-delays', 'ViewAirportDelays')}
        {flightNotFinal ? this.buttonHelper('SET UP FLIGHT ALERTS', 'trackerFlightAlertButton', this.handleFlightAlertsClick) : ''}
        {flightNotFinal ? this.buttonHelper('REFRESH DATA', 'refreshDataButton', this.handleRefreshDataClick) : ''}
      </div>
    );
  }

  getOtherStops = () => {
    const { uplines, downlines, carrier } = this.props;

    if ((!uplines && !downlines) || (!uplines.length && !downlines.length)) {
      return '';
    }

    const lines = [];

    uplines.forEach((line) => {
      lines.push(this.getOtherStopCell(line, lines.length));
    });

    downlines.forEach((line) => {
      lines.push(this.getOtherStopCell(line, lines.length));
    });

    // TODO: Add this in subscriptions
    // <p>Viewing another stop will consume one Look-Up Unit</p>

    return (
      <div className='otherStops col-xs-12'>
        <h3 className='title' style={{ display: 'inline', paddingRight: '10px' }}>Other Stops for {`${carrier.fs}${carrier.flightNumber}`}</h3>
        <p style={{ display: 'inline' }}>Scheduled/<span className='actual-time'>Actual</span>/<span className='estimated-time'>Estimated</span></p>
        {lines}
      </div>
    );
  }

  getExtendedDetailsAds = () => {
    const { extendedDetailsAds } = this.props;
    if (extendedDetailsAds) {
      return (
        <div className='col-xs-12' style={{ marginTop: '25px' }}>
          {extendedDetailsAds}
        </div>
      );
    }
    return null;
  }

  getFlightNotes = () => {
    let notes = '';
    const {
      operatedBy,
      codeshares,
    } = this.props;
    const operatedByString = operatedBy ? (<p className='details'>{operatedBy}</p>) : '';
    const codeshareString = codeshares ? (<p className='details'>Also marketed as {codeshares.displayString}</p>) : '';

    if (operatedBy || codeshares) {
      notes = (
        <div className='flightNotes col-xs-12'>
          <h3 className='title'>Flight Notes</h3>
          {operatedByString}
          {codeshareString}
        </div>
      );
    }
    return notes;
  }

  getEventTimeline = () => {
    let notes = '';
    const {
      arrivalAirport,
      departureAirport,
      eventTimeline,
    } = this.props;

    if (eventTimeline) {
      const rows = [];

      if (eventTimeline.length > 0) {
        rows.push(
          <div className='row tableHeader' key='tableHeader'>
            <div className='row' key='rowTop'>
              <div className='col-xs-2 col-sm-1' />
              <div className='col-xs-2 col-sm-3 time'>Time</div>
              <div className='col-xs-3' />
              <div className='col-xs-5' />
            </div>
            <div className='row' key='rowTop2'>
              <div className='col-xs-2 col-sm-1'><p className='headerJustify'>Date</p></div>
              <div className='col-xs-2 col-sm-1 time'>UTC</div>
              <div className='hidden-xs col-sm-1 time'>{departureAirport.timezoneCode}</div>
              <div className='hidden-xs col-sm-1 time'>{arrivalAirport.timezoneCode}</div>
              <div className='col-xs-2 col-sm-2 col-md-2'><p className='headerJustify'>Event</p></div>
              <div className='col-xs-6 col-sm-6 col-md-6'><p className='headerJustify'>Data Updated</p></div>
            </div>
          </div>);

        eventTimeline.forEach((update, i) => {
          const { events } = update;
          const eventDesc = [];

          const formattedDate = prefersMDYDateFormat(this.context.currentUser) ?
            update.date1 : update.date2;

          if (events) {
            const { currentUser } = this.context;
            const dateChoice = (currentUser && currentUser.dateFormat) || 1;
            const timeChoice = (currentUser && currentUser.timeFormat) || 1;

            events.forEach((event, j) => {
              // assemble the event strign
              let eventString = null;

              if (event.eventText && event.noAdjustments) {
                // no time or date present in the event string
                eventString = event.eventText;
              } else {
                // string builder mode
                const fromLocalDateMonth = event[`fromMonth${dateChoice}`];
                const fromLocalDateTime = event[`fromTime${timeChoice}`];
                const fromLocalDateAMPM = event[`fromAMPM${timeChoice}`];
                const toLocalDateMonth = event[`toMonth${dateChoice}`];
                const toLocalDateTime = event[`toTime${timeChoice}`];
                const toLocalDateAMPM = event[`toAMPM${timeChoice}`];

                const { changed } = event;
                eventString = changed;
                if (event.oldDate && !event.newDate) {
                  // 'from' section
                  eventString = (
                    <span>
                      <span>{event.changed}</span>
                      <span>{fromLocalDateMonth}</span>
                      <span className='historical-flight-time-hours'>{fromLocalDateTime}</span>
                      <span className='historical-flight-time-ampm'>{fromLocalDateAMPM}</span>
                    </span>
                  );
                }
                if (!event.oldDate && event.newDate) {
                  // 'to' section
                  eventString = (
                    <span>
                      <span>{event.changed}</span>
                      <span>{toLocalDateMonth}</span>
                      <span className='historical-flight-time-hours'>{toLocalDateTime}</span>
                      <span className='historical-flight-time-ampm'>{toLocalDateAMPM}</span>
                    </span>
                  );
                }
                if (event.oldDate && event.newDate) {
                  eventString = (
                    <span>
                      <span>{event.changed}</span>
                      <span>{fromLocalDateMonth}</span>
                      <span className='historical-flight-time-hours'>{fromLocalDateTime}</span>
                      <span className='historical-flight-time-ampm'>{fromLocalDateAMPM}</span>
                      <span >{toLocalDateMonth}</span>
                      <span className='historical-flight-time-hours'>{toLocalDateTime}</span>
                      <span className='historical-flight-time-ampm'>{toLocalDateAMPM}</span>
                    </span>
                  );
                }
              }
              // add the assembled string to the eventsDesc array
              const key = `${i}-${j}`;
              eventDesc.push(<p className='rowData' key={key}> {eventString} </p>);
            });
          }

          const formattedTime1 = formattedTime(this.context.currentUser, update, 'departureAirportTime24', 'departureAirportTime');
          const formattedTime2 = formattedTime(this.context.currentUser, update, 'arrivalAirportTime24', 'arrivalAirportTime');
          const key = `k_${i}`;

          rows.push((
            <div className='row eventRow' key={key}>
              <div className='col-xs-2 col-sm-1'><p className='rowData'>{formattedDate}</p></div>
              <div className='col-xs-2 col-sm-1 time'>
                <p className='rowData historical-time-utc'>
                  <TimeToDOM user={this.context.currentUser} time={moment.utc(update.utcTime, 'hh:mm')} isUtc componentName='historical-flight' />
                </p>
              </div>
              <div className='hidden-xs col-sm-1 time'>
                <p className='rowData'>
                  <TimeToDOM user={this.context.currentUser} time={formattedTime1} isUtc={false} componentName='historical-flight' />
                </p>
              </div>
              <div className='hidden-xs col-sm-1 time'>
                <p className='rowData'>
                  <TimeToDOM user={this.context.currentUser} time={formattedTime2} isUtc={false} componentName='historical-flight' />
                </p>
              </div>
              <div className='hidden-xs col-sm-2 col-md-2'><p className='rowData'>{update.title}</p></div>
              <div className='hidden-sm hidden-md hidden-lg col-xs-2'><p className='rowData'>{update.shortTitle}</p></div>
              <div className='col-xs-6 col-sm-6 col-md-6'>{eventDesc}</div>
            </div>));
        });
      }

      notes = (
        <div className='eventTimeline col-xs-12'>
          <h3 className='title'>Event Timeline</h3>
          <div className='eventTable'>
            {rows}
          </div>
        </div>
      );
    }

    return notes;
  }

  handleOtherStopClick = (location) => {
    this.context.reportButtonPress('DifferentSegment');
    this.props.dispatch(push(location));
  }

  flightAlertUser = () => {
    const { currentUser } = this.context;
    if (currentUser) {
      const {
        subscriptionActive,
        subscriptionLevel,
      } = currentUser;
      const validUser = subscriptionActive && (subscriptionLevel || 0) > 0;
      return validUser;
    }
    return false;
  }

  handleRefreshDataClick = () => {
    this.context.reportButtonPress('CalloutRefresh');
    location && location.reload();
  };

  handleFlightAlertsClick = () => {
    this.context.reportButtonPress('CalloutFlightAlerts');
    const {
      arrivalAirport,
      departureAirport,
      dispatch,
      match,
      sortTime,
    } = this.props;
    if (this.flightAlertUser()) {
      const dateProps = getAirportDateParams(sortTime);
      const { params } = match || {};
      const {
        carrier,
        flightNumber,
      } = params || {};
      const {
        year,
        month,
        day,
      } = dateProps || {};
      const url = [
        '/flight-alerts/new',
        carrier,
        flightNumber,
        year,
        month,
        day,
        getAirportCodeSimple(departureAirport),
        getAirportCodeSimple(arrivalAirport),
        'true',
      ].join('/');
      dispatch(push(url));
    } else {
      dispatch(push('/flight-alerts'));
    }
  }

  activeFlight = () => {
    if (['unknown', 'currentDatePostFlight', 'currentDateAfterArrival', 'historical'].indexOf(this.props.flightState) >= 0) {
      return false;
    }
    return true;
  }

  flightTrackerUrl = () => {
    const { location: { pathname, search } } = this.props;
    return `${pathname.replace('details', 'tracker')}${search}`;
  }

  buttonHelper = (label, id, onClick) => {
    const buttonClasses = 'flight-details-buttons col-xs-12 col-sm-6 col-md-3';
    return (
      <div className={buttonClasses}>
        <FSFlatButton
          id={id}
          label={label}
          onClick={onClick}
        />
      </div>
    );
  }

  // Use this for links
  buttonWithURLHelper = (label, id, url, gaLabel, altText) => (
    <div className='flight-details-buttons col-xs-12 col-sm-6 col-md-3'>
      <FSFlatButton
        href={`/v2${url}`}
        alt={altText}
        id={id}
        label={label}
        labelStyle={{ padding: '0' }}
        onClick={(e) => {
          e.preventDefault();
          this.context.reportButtonPress(gaLabel);
          window.location = process.env.NODE_ENV === 'development' ? `http://localhost:4000/v2${url}` : `/v2${url}`;
        }}
      />
    </div>
  );

  formatDelayString = (delayMins, isDeparture) => {
    if (!delayMins || (this.wasDiverted() && !isDeparture)) {
      return '-';
    }

    if (delayMins === 1) {
      return `${delayMins} min`;
    }

    const hours = Math.floor(delayMins / 60);
    let delayMinString = `${hours}`;

    if (hours >= 1) {
      delayMinString = `${delayMinString} h`;
    }

    if (delayMins % 60 === 1) {
      delayMinString = hours >= 1 ? `${delayMinString} ${delayMins % 60} min` : `${delayMins % 60} min`;
    } else if (delayMins % 60 > 1) {
      delayMinString = hours >= 1 ? `${delayMinString} ${delayMins % 60} mins` : `${delayMins % 60} mins`;
    }

    return delayMinString;
  }

  reportFlightState(currentProps = this.props) {
    if (currentProps.flightState !== this.currentFlightState) {
      this.currentFlightState = currentProps.flightState;
      this.context.reportEvent('FlightState', this.currentFlightState, null, null, true);
    }
  }

  reportEvent = () => {
    const { reportEvent } = this.context;
    const {
      departureAirport,
      arrivalAirport,
      carrier,
    } = this.props;
    const departureFs = getAirportCodeSimple(departureAirport);
    const arrivalFs = getAirportCodeSimple(arrivalAirport);
    const carrierFs = extractFs(carrier);
    reportEvent('Airport', departureFs, 'Departing', null, true);
    reportEvent('Airport', arrivalFs, 'Arriving', null, true);
    reportEvent('Airline', carrierFs, null, null, true);
    this.reportFlightState();
  }

  handleFirstLoad = handleFirstLoad(this.reportEvent)

  // when an flight has been diverted, this.props.divertedAirport contains info
  // about the airport diverted to
  wasDiverted = () => (!!this.props.divertedAirport);

  render() {
    const departure = this.getFlightCell(true);
    const arrival = this.getFlightCell(false);
    const extendedDetailsButtons = this.getExtendedDetailsButtons();
    const otherStops = this.getOtherStops();
    const extendedDetailsAds = this.getExtendedDetailsAds();
    const flightNotes = this.getFlightNotes();
    const eventTimeline = this.getEventTimeline();

    const {
      isMobile,
      isTablet,
      isDesktop,
    } = this.context.userAgent;

    return (
      <div className='historicalFlightInformation'>
        <div className='row'>
          {departure}
          {arrival}
        </div>
        {(isTablet || isDesktop) && extendedDetailsButtons}
        {otherStops}
        {extendedDetailsAds}
        {flightNotes}
        {eventTimeline}
        {(isMobile && !isTablet) && extendedDetailsButtons}
      </div>
    );
  }
}
