import React from 'react';
import PropTypes from 'prop-types';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import moment from 'moment';

const Dropdown = props => (
  <DropDownMenu
    autoWidth={false}
    className='dropdown'
    onChange={(e, key, value) => props.handleSelectChange(e, key, value)}
    style={{
      color: 'black',
      height: '33px',
      top: '25px',
    }}
    underlineStyle={{ display: 'none' }}
    value={props.selectedIndex}
  >
    <MenuItem
      key={props.defaultKey}
      primaryText={props.defaultText}
      value={props.defaultKey}
    />
    {
      props.receiptCounts && props.receiptCounts.map((month, i) => {
        const date = moment.utc(month.oneScheduledDepartureThisMonth).format('MMM-YYYY');
        return (
          <MenuItem
            key={date}
            primaryText={date}
            value={i}
          />
        );
      })
    }
  </DropDownMenu>
);

Dropdown.propTypes = {
  defaultKey: PropTypes.number,
  defaultText: PropTypes.string,
  handleSelectChange: PropTypes.func,
  receiptCounts: PropTypes.array,
  selectedIndex: PropTypes.number,
};

export default Dropdown;
