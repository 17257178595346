import React from 'react';
import PropTypes from 'prop-types';
import getClientDimensions from '../../../../../../shared/lib/get-client-dimensions/index';
import componentBase from '../../../../../../src/lib/component-base';
import StatusAndPhase from '../../../../../AirportCurrentConditions/components/children/ConditionsTracker/children/StatusAndPhase';

let resizeTimeout;

const resizeThrottlerFunctionGenerator = actualResizeHandler => () => {
  if (!resizeTimeout) {
    resizeTimeout = setTimeout(() => {
      resizeTimeout = null;
      actualResizeHandler();
    }, 66);
  }
};

const rgbaRed = 'rgba(227, 70, 34, .8)';
const rgbaOrange = 'rgba(250, 168, 23, .8)';
const rgbaYellow = 'rgba(227, 216, 34, .8)';
const rgbaGreen = 'rgba(125, 214, 59, .8)';
const rgbaRedUnknown = 'rgba(227, 70, 34, .2)';
const rgbaOrangeUnknown = 'rgba(250, 168, 23, .2)';
const rgbaYellowUnknown = 'rgba(227, 216, 34, .2)';
const rgbaGreenUnknown = 'rgba(125, 214, 59, .2)';
const graphDivHeight = 99;
const graphBottomDivBorderHeight = 1;
const trackerDivHeight = 125;

let Raphael;

@componentBase('DelayGraph')
export default class DelayGraph extends React.Component {
  static propTypes = {
    delayIndex: PropTypes.object,
    divHeights: PropTypes.object,
    graphAndPhase: PropTypes.object,
  }

  static defaultProps = {
    divHeights: {
      tracker: trackerDivHeight,
      indexGraph: graphDivHeight,
      statusAndPhase: 25,
    },
    graphAndPhase: {
      parentDivStyle: {
        padding: 0,
        height: `${trackerDivHeight - 2}px`,
        border: '1px solid white',
      },
      graph: {
        knownGradient: {
          background: `linear-gradient(to right, ${rgbaGreen}, ${rgbaYellow}, ${rgbaOrange}, ${rgbaRed})`, /* Standard syntax */
        },
        unknownGradient: {
          background: `linear-gradient(to right, ${rgbaGreenUnknown}, ${rgbaYellowUnknown}, ${rgbaOrangeUnknown}, ${rgbaRedUnknown})`, /* Standard syntax */
        },
        parentDivStyle: {
          padding: 0,
          height: `${graphDivHeight}px`,
          borderBottom: `${graphBottomDivBorderHeight}px solid #FFFFFF`,
        },
        subDivStyle: {
          padding: 0,
          height: `${graphDivHeight}px`,
          backgroundImage: 'linear-gradient(0deg, transparent 3%, rgba(255, 255, 255, .2) 4%, rgba(255, 255, 255, .2) 5%, transparent 2%, transparent 3%, rgba(255, 255, 255, .2) 4%, rgba(255, 255, 255, .2) 5%, transparent 15%, transparent),' +
          'linear-gradient(90deg, transparent 3%, rgba(255, 255, 255, .2) 4%, rgba(255, 255, 255, .2) 5%, transparent 2%, transparent 3%, rgba(255, 255, 255, .2) 4%, rgba(255, 255, 255, .2) 5%, transparent 15%, transparent)',
          backgroundSize: '5px 5px',
        },
      },
    },
  }

  componentDidMount() {
    Raphael = require('raphael');
    this.resizeListener = resizeThrottlerFunctionGenerator(this.adjustGraphOnResize.bind(this));
    window.addEventListener('resize', this.resizeListener);
    this.adjustGraphOnResize();
  }

  componentWillReceiveProps() {
    this.adjustGraphOnResize();
    this.forceUpdate();
  }

  componentDidUpdate() {
    this.clearGraph();
    this.drawGraph();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeListener);
  }

  getStatusAndPhaseProps() {
    const props = {
      score: null,
      status: null,
      trend: null,
    };

    if (this.props.delayIndex) {
      props.score = this.props.delayIndex.score;
      props.status = this.props.delayIndex.status;
      props.trend = this.props.delayIndex.trend;
      props.observed = this.props.delayIndex.observed;
    }
    return props;
  }

  adjustGraphOnResize() {
    this.clearGraph();
    this.drawGraph();
  }

  clearGraph() {
    if (this.paper) {
      this.paper.clear();
      this.paper.remove();
    }
  }

  drawGraph() {
    const [xWidth, xHeight] = getClientDimensions(document, 'trackerGraph');

    // current index vertical line
    if (this.props.delayIndex && this.props.delayIndex.score !== null) {
      this.props.delayIndex.observed ? this.paper = Raphael(this.refs.trackerGraph1, '100%', xHeight) : this.paper = Raphael(this.refs.trackerGraph2, '100%', xHeight); //eslint-disable-line
      const rawLoc = xWidth / 5 * this.props.delayIndex.score;
      const vertLineLoc = rawLoc.toFixed(0);
      this.paper.path(`M${vertLineLoc},5 L${vertLineLoc},7`)
        .attr('stroke', 'rgba(255, 255, 255, 0.6)')
        .attr('stroke-width', '2');
      this.paper.path(`M${vertLineLoc},10 L${vertLineLoc},90`)
        .attr('stroke', 'rgba(255, 255, 255, 0.6)')
        .attr('stroke-width', '2');
      this.paper.path(`M${vertLineLoc},93 L${vertLineLoc},95`)
        .attr('stroke', 'rgba(255, 255, 255, 0.6)')
        .attr('stroke-width', '2');

      if (this.props.delayIndex.score < 2.5) {
        this.paper.path(`M${(rawLoc + 5).toFixed(0)},${xHeight / 2}L${(rawLoc + 29).toFixed(0)},${xHeight / 2 - 20}L${(rawLoc + 29).toFixed(0)},${xHeight / 2 + 20}Z`)
          .attr('stroke', 'white')
          .attr('stroke-width', '1')
          .attr('fill', 'white');
        this.paper.rect((rawLoc + 30).toFixed(0), xHeight / 2 - 20, 50, 40)
          .attr('fill', 'rgba(255, 255, 255, .1)')
          .attr('stroke', 'white')
          .attr('stroke-width', '2');
        this.paper.text((rawLoc + 55).toFixed(0), xHeight / 2, `${this.props.delayIndex.score}`)
          .attr('font-size', '18pt')
          .attr('fill', 'rgba(0, 0, 0, 1)');
      } else {
        this.paper.path(`M${(rawLoc - 5).toFixed(0)},${xHeight / 2}L${(rawLoc - 29).toFixed(0)},${xHeight / 2 - 20}L${(rawLoc - 29).toFixed(0)},${xHeight / 2 + 20}Z`)
          .attr('stroke', 'white')
          .attr('stroke-width', '1')
          .attr('fill', 'white');
        this.paper.rect((rawLoc - 80).toFixed(0), xHeight / 2 - 20, 50, 40)
          .attr('fill', 'rgba(255, 255, 255, .1)')
          .attr('stroke', 'white')
          .attr('stroke-width', '2');
        this.paper.text((rawLoc - 55).toFixed(0), xHeight / 2, `${this.props.delayIndex.score}`)
          .attr('font-size', '18pt')
          .attr('fill', 'rgba(0, 0, 0, 1)');
      }
    }
  }

  renderKnownTracker = () => {
    const rowStyle = {
      ...this.props.graphAndPhase.graph.parentDivStyle,
      ...this.props.graphAndPhase.graph.knownGradient,
    };

    return (
      <div key='tracker-column-known' className='col-md-12' style={this.props.divHeights.parentDivStyle}>
        <div key='tracker-row-known' id='trackerGraph' className='row' style={rowStyle}>
          <div key='tracker-inner-row-known' className='row' style={this.props.graphAndPhase.graph.subDivStyle}>
            <div key='tracker-icon-known' className='col-sm-12' style={{ padding: 0, height: 'inherit' }} ref='trackerGraph1' />
          </div>
        </div>
        <StatusAndPhase id='status' {...this.getStatusAndPhaseProps()} />
      </div>
    );
  }

  renderUnknownTracker = () => {
    const rowStyle = {
      ...this.props.graphAndPhase.graph.parentDivStyle,
      ...this.props.graphAndPhase.graph.unknownGradient,
    };

    return (
      <div key='tracker-column-unknown' className='col-md-12' style={this.props.divHeights.parentDivStyle}>
        <div key='tracker-row-unknown' id='trackerGraph' className='row' style={rowStyle}>
          <div key='tracker-inner-row-unknown' className='row' style={this.props.graphAndPhase.graph.subDivStyle}>
            <div key='tracker-icon-unknown' className='col-sm-12' style={{ padding: 0, height: 'inherit' }} ref='trackerGraph2' />
          </div>
        </div>
        <StatusAndPhase id='status' {...this.getStatusAndPhaseProps()} />
      </div>
    );
  }

  render() {
    // this.logger('render: ', this.props);
    return (
      <div className='row delay-index-container' style={{ marginTop: '1em' }} >
        <div className='col-sm-12' style={{ padding: 0 }}>
          <div className='col-sm-12' style={{ padding: 0, border: '1px solid white' }}>
            {this.props.delayIndex && this.props.delayIndex.observed ? this.renderKnownTracker() : this.renderUnknownTracker()}
          </div>
        </div>
      </div>
    );
  }
}
