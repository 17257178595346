import React from 'react';
import PropTypes from 'prop-types';
import StandardFSDropDown from '../../../../../../src/components/lib/StandardFSDropDown';
import * as helpers from './_helpers';
import componentBase from '../../../../../../src/lib/component-base';

@componentBase('FilterMenus')
class FilterMenus extends React.Component {
  static propTypes = {
    currentTimespanKey: PropTypes.string,
    menuItems: PropTypes.object,
    updateMenuObjectAndSelectedArray: PropTypes.func,
    handleTimespanSelected: PropTypes.func,
    items: PropTypes.object,
    destOrigin: PropTypes.string,
    depArr: PropTypes.string,
  };

  constructor(props, context) {
    super(props, context, 'FilterMenus');
  }

  render() {
    const custom = this.props.currentTimespanKey === 'Custom' && this.props.menuItems.timespan[this.props.currentTimespanKey];
    const timespanTitleString = custom ?
      this.props.menuItems.timespan[this.props.currentTimespanKey].label :
      this.props.currentTimespanKey;
    const timespanClassName = custom ? 'custom-timespan-label' : '';

    let baggageFilterMenu = null;

    if (this.props.depArr === 'arrivals') {
      baggageFilterMenu = (
        <StandardFSDropDown
          title='Baggage'
          className='select-background'
          onChange={this.props.updateMenuObjectAndSelectedArray}
          list={helpers.menuItemsToArray(this.props.menuItems.baggage, true)}
        />
      );
    }

    return (
      <div className='flight-filters'>
        <div className='timespan-filter'>
          <StandardFSDropDown
            title={timespanTitleString}
            className={`select-background ${timespanClassName}`}
            onChange={this.props.handleTimespanSelected}
            list={helpers.menuItemsToArray(this.props.items.timespans, false, true)}
          />
        </div>
        <div>
          <StandardFSDropDown
            title='Airlines'
            className='select-background'
            onChange={this.props.updateMenuObjectAndSelectedArray}
            list={helpers.menuItemsToArray(this.props.menuItems.airline)}
          />
        </div>
        <div>
          <StandardFSDropDown
            title={this.props.destOrigin}
            className='select-background'
            onChange={this.props.updateMenuObjectAndSelectedArray}
            list={helpers.menuItemsToArray(this.props.menuItems.destOrigin)}
          />
        </div>
        <div>
          <StandardFSDropDown
            title='Status'
            className='select-background'
            onChange={this.props.updateMenuObjectAndSelectedArray}
            list={helpers.menuItemsToArray(this.props.menuItems.status)}
          />
        </div>
        <div>
          <StandardFSDropDown
            title='Delay'
            className='select-background'
            onChange={this.props.updateMenuObjectAndSelectedArray}
            list={helpers.menuItemsToArray(this.props.menuItems.delay)}
          />
        </div>
        <div>
          <StandardFSDropDown
            title='Equipment'
            className='select-background'
            onChange={this.props.updateMenuObjectAndSelectedArray}
            list={helpers.menuItemsToArray(this.props.menuItems.equipment, true)}
          />
        </div>
        <div>
          <StandardFSDropDown
            title='Terminal'
            className='select-background'
            onChange={this.props.updateMenuObjectAndSelectedArray}
            list={helpers.menuItemsToArray(this.props.menuItems.terminal, true)}
          />
        </div>
        <div>
          <StandardFSDropDown
            title='Gate'
            className='select-background'
            onChange={this.props.updateMenuObjectAndSelectedArray}
            list={helpers.menuItemsToArray(this.props.menuItems.gate, true)}
          />
        </div>
        <div>
          {baggageFilterMenu}
        </div>
      </div>
    );
  }
}

export default FilterMenus;
