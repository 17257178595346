import moment from 'moment';
import { dateFormatSelect } from '../../../src/lib/date-time-format';

export const getDateMenuItems = (user) => {
  const now = moment();
  const yesterday = now.clone().subtract(1, 'day');
  const tomorrow = now.clone().add(1, 'day');
  const month = { yest: yesterday.format('MMM'), today: now.format('MMM'), tom: tomorrow.format('MMM') };

  const yesterdayLabel = dateFormatSelect(
    user,
    `${yesterday.date()}-${month.yest}-${yesterday.year()}`, // DMY
    `${month.yest}-${yesterday.date()}-${yesterday.year()}`,
  ); // MDY

  const todayLabel = dateFormatSelect(
    user,
    `${now.date()}-${month.today}-${now.year()}`, // DMY
    `${month.today}-${now.date()}-${now.year()}`,
  ); // MDY

  const tomorrowLabel = dateFormatSelect(
    user,
    `${tomorrow.date()}-${month.tom}-${tomorrow.year()}`, // DMY
    `${month.tom}-${tomorrow.date()}-${tomorrow.year()}`,
  ); // MDY

  return [
    { value: '0', label: yesterdayLabel, momentDate: yesterday },
    { value: '1', label: todayLabel, momentDate: now },
    { value: '2', label: tomorrowLabel, momentDate: tomorrow },
  ];
};
