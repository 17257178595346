import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PaginatedTable from '../../../../../../shared/components/PaginatedTable/PaginatedTable';
import componentBase from '../../../../../../src/lib/component-base';
import { formattedTime } from '../../../../../../src/lib/date-time-format';
import { updatePageIndex } from '../../../../redux/actions';

const centeredTextStyle = {
  textAlign: 'center',
};

const departureTimeStyle = {
  marginBottom: '5px',
  textTransform: 'lowercase',
  marginLeft: '5px',
};

const arrivalTimeStyle = {
  color: '#FAA718',
  marginBottom: '5px',
  textTransform: 'lowercase',
  marginLeft: '5px',
};

@componentBase('MultiFlightsList')
export default class MultiFlightsList extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    errorText: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.node,
    ]),
    flights: PropTypes.array,
    destOriginTitle: PropTypes.string,
    loading: PropTypes.bool,
    match: PropTypes.object,
    pageIndex: PropTypes.number,
    searchUrl: PropTypes.string,
    variant: PropTypes.string,
  };

  static defaultProps = {
    flights: null,
    destOriginTitle: null,
  };

  get header() {
    const headers = [
      {
        title: 'Flight',
      },
      {
        title: 'Departure Time',
        titleAlt: 'Departure',
      },
      {
        title: 'Arrival Time',
        titleAlt: 'Arrival',
      },
    ];

    if (this.props.destOriginTitle) {
      headers.push({
        title: this.props.destOriginTitle,
        style: centeredTextStyle,
      });
    }

    return headers;
  }

  get rows() {
    const hasAirport = this.props.flights &&
      this.props.flights.length > 0 &&
      !!this.props.flights[0].airport;
    return this.props.flights && this.props.flights.map((flight) => {
      // this is necessary in order to initiate a request to the Next app
      const url = process.env.NODE_ENV === 'development' ? `http://localhost:4000/v2${flight.url}` : `/v2${flight.url}`;
      const operatedBy = flight.operatedBy ? ` / ${flight.operatedBy}` : '';
      const row = [
        {
          href: url,
          onClick: () => (this.context.reportUserEvent('Click', 'TrackFlight')),
          primary: {
            element: <h2 className='flights-list-bold-text flights-list-margined leftText'>{`${flight.carrier.fs} ${flight.carrier.flightNumber}`}</h2>,
            className: hasAirport ? 'col-xs-3 col-sm-4' : 'col-xs-4 col-sm-4',
          },
          secondary: {
            element: <p className='hidden-xs flights-list-subtitle-text leftText'>{flight.carrier.name}{operatedBy}</p>,
            elementAlt: <p className='visible-xs flights-list-subtitle-text leftText'>{flight.carrier.name}{operatedBy.replace(/ on behalf.*/, '')}</p>,
            className: hasAirport ? 'col-xs-9 col-sm-10' : 'col-xs-12 col-sm-12',
          },
        },
        {
          primary: {
            element: <h2 className='flights-list-bold-text flights-list-margined departureTimePadding' style={departureTimeStyle}>{formattedTime(this.context.currentUser, flight.departureTime, 'time24', 'timeAMPM')}</h2>,
            className: hasAirport ? 'col-xs-3 col-sm-3' : 'col-xs-4 col-sm-4',
          },
        },
        {
          primary: {
            element: <h2 className='flights-list-light-text flights-list-margined' style={arrivalTimeStyle}>{formattedTime(this.context.currentUser, flight.arrivalTime, 'time24', 'timeAMPM')}</h2>,
            className: hasAirport ? 'col-xs-3 col-sm-3' : 'col-xs-4 col-sm-4',
          },
        },
      ];

      if (hasAirport) {
        row.push({
          primary: {
            element: <h2 className='flights-list-bold-text flights-list-margined' style={centeredTextStyle}>{flight.airport.fs}</h2>,
            className: 'col-xs-3 col-sm-2',
          },
          secondary: {
            element: <p className='flights-list-subtitle-text' style={centeredTextStyle}>{flight.airport.city}</p>,
            className: 'col-xs-3 col-sm-2',
          },
        });
      }

      return row;
    });
  }

  handlePageClick = (pageIndex, searchUrl) => {
    this.props.dispatch(updatePageIndex(pageIndex, searchUrl));
  }

  render() {
    if (this.props.loading) {
      return (<div style={{ height: '200px' }} />);
    }
    if (this.props.errorText) {
      return (
        <div className='multi-search-list-error'>
          <h5>
            { this.props.errorText }
          </h5>
        </div>
      );
    }
    return (
      <div>
        <PaginatedTable
          dispatch={this.props.dispatch}
          handlePageClick={this.handlePageClick}
          header={this.header}
          pageIndex={this.props.pageIndex}
          rows={this.rows}
          match={this.props.match}
          searchUrl={this.props.searchUrl}
          variant={this.props.variant}
        />
      </div>
    );
  }
}
