import {
  LOAD_FLIGHT,
  LOAD_SUCCESS,
  LOAD_FAIL,
} from './actionTypes';
import { LOAD_ERROR_MESSAGE } from './constants';

const initialState = {
  loading: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case LOAD_FLIGHT:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.result,
        error: null,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        error: LOAD_ERROR_MESSAGE,
      };
    default:
      return state;
  }
};
