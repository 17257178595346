import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { parse } from 'qs';
import { pathOr, propOr, compose, ifElse, equals, curry, map, is } from 'ramda';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';

import { featureForRoute } from '../shared/lib/feature-for-path';
import { parseVariantsFromQueryString } from '../src/lib/variant-util';
import { setFlightTrackerOutOfDateRange } from './SingleFlight/redux/actions';
import { setHistoricalSingleErrorMessage } from './HistoricalFlightStatus/redux/Single/actions';
import { setRouteName, addVariants, clearVariants } from './App/redux/actions';
import { watchVariants } from '../src/lib/ga-optimize-utils';

const LOGIN_PATH = '/account/login';
const BLOCKED_PATH = '/blocked';

@connect(state => ({
  user: state.Account.user,
  deletedAccount: state.Account.deletedAccount,
  routeName: state.App.routeName,
}))
export default class RouteWrapper extends Component {
  static propTypes = {
    component: PropTypes.any.isRequired,
    deletedAccount: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.object,
    match: PropTypes.object,
    computedMatch: PropTypes.object,
    name: PropTypes.string,
    onEnter: PropTypes.array,
    routeName: PropTypes.string,
    user: PropTypes.object,
  };

  componentWillMount() {
    this.updateRouteName(this.props);
    this.updateVariants(this.props);
  }

  componentDidMount() {
    this.didNavigate(this.props);
    watchVariants((variantName, variantValue) => {
      if (variantName) {
        this.props.dispatch(addVariants({ [variantName]: variantValue }));
      } else {
        this.props.dispatch(clearVariants());
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location && this.props.location.pathname !== prevProps.location.pathname) {
      this.didNavigate(this.props);
    }
  }

  updateRouteName(props) {
    const oldName = props.routeName;
    const currentName = props.name;
    currentName && currentName !== oldName && this.props.dispatch(setRouteName(currentName));
  }

  didNavigate(props) {
    window.scrollTo(0, 0);
    this.updateRouteName(props);
    this.updateVariants(props);
  }

  updateVariants(props) {
    const qsVariants = parseVariantsFromQueryString(props.location);
    if (qsVariants) {
      this.props.dispatch(addVariants(qsVariants));
    }
  }

  userHas = (props, field) => (props.user && props.user[field]);

  requireActiveSubscription = (props) => {
    const { user, match } = props;
    const { path } = match;
    const protectedFeature = featureForRoute(path);
    const subscriptionLevel = propOr(0, 'subscriptionLevel');
    const hasActiveSubscription = propOr(false, 'subscriptionActive');
    const isUserInvalid = () => !hasActiveSubscription(user);
    const isUserLevelLess = () => subscriptionLevel(user) < protectedFeature.level;

    const isUserLevelInvalid = ifElse(
      equals(true),
      () => true,
      isUserLevelLess,
    );

    const shouldRedirect = compose(
      isUserLevelInvalid,
      isUserInvalid,
    );

    const protectedPaths = {
      'flight-alerts': '/flight-alerts/subscribe',
      'departing-arriving': '/airports/departing-arriving/subscribe',
      'historical-flight': '/historical-flight/subscribe',
    };

    const foundPathKey = Object.keys(protectedPaths).filter(name => path.includes(name));
    return shouldRedirect(user) ? { route: protectedPaths[foundPathKey], status: 302 } || '/subscription' : '';
  }

  requireLogin = props => (props.user || props.deletedAccount ? '' : LOGIN_PATH);

  requireActiveAccount = (props) => {
    if (!props.user) {
      return '';
    }

    const active = pathOr(false, ['user', 'active'], props);
    return active ? '' : BLOCKED_PATH;
  }

  requireSignUp = props => (this.userHas(props, 'email') ? '' : '/register/plan');

  requireVerifiedEmail = props => (this.userHas(props, 'verified') ? '' : '/account/verify');

  validateHistoricalDate = (props) => {
    const { params } = props.match;

    if (params) {
      const { year, month, day } = params;
      if (year && month && day) {
        const dayString = Number(day) < 10 ? `0${Number(day)}` : day;
        const monthString = Number(month) < 10 ? `0${Number(month)}` : month;
        const now = moment();
        const dateSearched = `${year}-${monthString}-${dayString}`;
        const validHistorical = () => moment(dateSearched).isSameOrBefore(now, 'date');
        const valid = validHistorical();

        if (!valid) {
          this.props.dispatch(setHistoricalSingleErrorMessage({ error: 'FLIGHT_LOAD_ERROR2' }));
          return '/historical-flight/search';
        }

        return '';
      }
    }
  }

  validateDate = (year, month, day) => {
    const dayString = Number(day) < 10 ? `0${Number(day)}` : day;
    const monthString = Number(month) < 10 ? `0${Number(month)}` : month;
    const now = moment();
    const dateSearched = `${year}-${monthString}-${dayString}`;
    // in the last three days for extended details
    const validTrackerDetails = () => {
      const fourDaysAgo = moment(now).subtract(4, 'days');
      return moment(dateSearched).isAfter(fourDaysAgo, 'date');
    };
    const valid = validTrackerDetails();

    if (!valid) {
      this.props.dispatch(setFlightTrackerOutOfDateRange(true));
    }

    return '';
  }

  // checks params
  validateTrackerDate = (props) => {
    const { params } = props.match;
    if (params) {
      const { year, month, day } = params;
      if (year && month && day) { return this.validateDate(year, month, day); }
    }
  }

  // checks querystring params
  validateQueryStringTrackerDate = (props) => {
    const queryString = pathOr(null, ['location', 'search'], props);
    if (queryString) {
      const { year, month, date } = parse(queryString, { ignoreQueryPrefix: true });
      if (year && month && date) { return this.validateDate(year, month, date); }
    }
  }

  mapConstToFunc = {
    requireActiveAccount: this.requireActiveAccount,
    requireActiveSubscription: this.requireActiveSubscription,
    requireLogin: this.requireLogin,
    requireSignUp: this.requireSignUp,
    requireVerifiedEmail: this.requireVerifiedEmail,
    validateHistoricalDate: this.validateHistoricalDate,
    validateQueryStringTrackerDate: this.validateQueryStringTrackerDate,
    validateTrackerDate: this.validateTrackerDate,
  }

  applyFuncs = (validators, props) => curry(fns => map((fn) => {
    if (typeof fn !== 'function') {
      throw new Error(`onEnter handler missing: ${fn}`);
    }
    return fn(props);
  }, fns))(validators);

  render() {
    const { onEnter = [], component: TargetComponent, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={(props) => {
          const {
            deletedAccount,
            user,
          } = this.props;
          const fns = onEnter.map(fnName => this.mapConstToFunc[fnName] || fnName);
          const validatorProps = {
            user,
            deletedAccount,
            ...props,
          };
          const appliedFns = onEnter.length ? this.applyFuncs(fns, validatorProps) : [];
          const redirTo = appliedFns.filter(f => f).find(g => g);

          if (redirTo) {
            if (is(Object, redirTo)) {
              // redirTo is object containing route and status
              const { route, status = 301 } = redirTo;
              const { staticContext } = props;
              if (staticContext) {
                staticContext.status = status; // eslint-disable-line no-param-reassign
              }
              return (
                <Redirect to={{
                  pathname: route,
                  state: { from: props.location },
                }}
                />
              );
            }

            // redirTo is string
            return (
              <Redirect to={{
                pathname: redirTo,
                state: { from: props.location },
              }}
              />
            );
          }

          return <TargetComponent {...props} {...rest} />;
        }}
      />
    );
  }
}
