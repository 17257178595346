import React from 'react';
import PropTypes from 'prop-types';
import ToastActions from '../../redux/modules/toast/actions';
import componentBase from '../../lib/component-base';

@componentBase('Toast')
export default class Toast extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    message: PropTypes.string,
    title: PropTypes.string,
    iconClass: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose(evt) {
    this.context.store.dispatch(ToastActions.close(this.props.id));
    evt.stopPropagation();
  }

  handleClick() {
    this.context.store.dispatch(ToastActions.close(this.props.id));
  }

  render() {
    let elements = <noscript />;

    if (this.props.message || this.props.title) {
      elements = (
        <div
          className={`toast ${this.props.iconClass || ''}`}
          onClick={this.handleClick}
        >
          <button className='toast-close-button' type='button' onClick={this.handleClose}>&times;</button>
          <div className='toast-title'>
            {this.props.title}
          </div>
          <div className='toast-message'>
            {this.props.message}
          </div>
        </div>
      );
    } else {
      elements = <div style={{ display: 'none' }} />;
    }

    return elements;
  }
}
