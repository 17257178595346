import FlatButton from 'material-ui/FlatButton';
import { Colors } from '../../constants/style-constants';
import defaultContextTypes from '../default-context-types';

export default class FSFlatButton extends FlatButton {
  static contextTypes = defaultContextTypes;
}

const baseProps = Object.assign({}, FlatButton.defaultProps);
FSFlatButton.defaultProps = Object.assign(baseProps, {
  primary: true,
  backgroundColor: Colors.orange1,
  rippleColor: '#98ADC7',
  hoverColor: '#81A2CA',
  style: {
    textTransform: 'capitalize',
    lineHeight: '34px',
    height: '100%',
  },
});

FSFlatButton.style = overrides => Object.assign({}, FSFlatButton.defaultProps.style, overrides);
