import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import moment from 'moment';
import { Events } from 'react-scroll';
import { Link, Switch, Redirect } from 'react-router-dom';
import { actions } from '../../redux';
import RouteWrapper from '../../../RouteWrapper';
import Profile from './children/Profile';
import PaymentInformation from './children/PaymentInformation';
import { catchErrors } from '../../redux/selectors';
import componentBase from '../../../../src/lib/component-base';
import Icon from '../../../../src/components/lib/Icon';
import { dateFormatString } from '../../../../src/lib/date-time-format';
import { isBusinessUser } from '../../../../src/utils/isBusinessUser';

import freeIcon from '../../../../static/images/account-icons/FREETag-Icon-SOLID.svg';
import premiumIcon from '../../../../static/images/account-icons/PREMIUMTag-Icon-SOLID.svg';

@connect(
  state => ({
    updateErrors: state.Account.updateProfileErrors || state.Account.changePasswordErrors || {},
    updateSuccess: state.Account.updatedProfile || state.Account.changedPassword,
    deleteAccountError: state.Account.deleteAccountError,
    deletedAccount: state.Account.deletedAccount,
    updatingBillingAddressError: catchErrors(state.Account.updatingBillingAddressError),
    updatingPaymentInformationError: catchErrors(state.Account.updatingPaymentInformationError),
    user: state.Account.user,
  }),
  actions,
)
@componentBase('MyAccount')
export default class MyAccount extends Component {
  static propTypes = {
    children: PropTypes.object,
    user: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);

    this.errorDisplayWording = {
      MISSING_EMAIL: 'An email address is required',
      INVALID_EMAIL: 'The email address provided is invalid',
      EMAIL_IN_USE: 'The email address provided is already in use',
      UNKNOWN_ERROR: 'Error',
    };

    this.redirect = false;
  }

  state = {};

  componentDidMount() {
    // Events.scrollEvent.register('begin', (to, element) => {
    // });
    //
    // Events.scrollEvent.register('end', (to, element) => {
    // });
  }

  componentWillUnmount() {
    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');
  }

  _getMetaTags = () => {
    const title = 'FlightStats - My Account';
    return {
      title,
      meta: [{ name: 'robots', content: 'noindex' }],
    };
  };

  _renderMembershipIcon = (user) => {
    const iconStyle = {
      width: '25px',
      height: '25px',
    };

    let subscriptionLevel = '';
    let subscriptionCreatedAtDateOrProfileCreationDate = '';
    let subscriptionFirstCreatedAt = user.creationDate;
    let iconSrc;
    if (user.subscriptionActive) {
      if (isBusinessUser(user)) {
        subscriptionLevel = 'Business';
      } else if (user.subscriptionLevel === 1) {
        subscriptionLevel = 'Standard';
        subscriptionFirstCreatedAt = user.subscriptionFirstStandardDate;
      } else if (user.subscriptionLevel === 3) {
        subscriptionLevel = 'Professional';
        subscriptionFirstCreatedAt = user.subscriptionFirstPremiumDate;
      }

      iconSrc = premiumIcon;
      if (!isBusinessUser(user) && user.subscriptionCreatedAt) {
        subscriptionCreatedAtDateOrProfileCreationDate = ` since ${moment(user.subscriptionCreatedAt).format(dateFormatString(user))}`;
      }
    } else {
      subscriptionLevel = 'Free';
      iconSrc = freeIcon;
    }
    subscriptionCreatedAtDateOrProfileCreationDate = ` since ${moment(subscriptionFirstCreatedAt).format(dateFormatString(user))}`;

    return (
      <div className='myAccountProfileDate'>
        <Icon className='myAccountProfileIcon' src={iconSrc} style={iconStyle} />
        <h3 className={`myAccountProfileTitle ${subscriptionLevel.toLowerCase()}`}>{`${subscriptionLevel} Member${subscriptionCreatedAtDateOrProfileCreationDate}`}</h3>
      </div>
    );
  }

  render() {
    if (!this.props.user) {
      return <noscript />;
    }
    const currentPath = this.context && this.context.location && this.context.location.pathname === '/my-account/profile' ? 'profile' : 'payment';
    const headerText = currentPath === 'profile' ? 'My FlightStats Account Profile' : 'My FlightStats Payment Information';
    const meta = this._getMetaTags();
    const u = this.props.user || {}; // les Z
    const registeredDate = this._renderMembershipIcon(u);
    const currentTabToHighlight = this.context && this.context.location && this.context.location.pathname === '/my-account/profile' ? 'profile' : 'billing';

    return (
      <div className='account-profile'>
        <Helmet {...meta} />
        <section className='white-bg row'>
          <div className='col-xs-12'>
            <h1>{headerText}</h1>
          </div>
          <div className='col-xs-12 col-sm-6'>
            {registeredDate}
          </div>
          <div className='col-xs-12 col-sm-6'>
            <div className='tab-holder'>
              <Link
                to='/my-account/profile'
                className={`tab profile col-xs-5 col-sm-6 ${currentTabToHighlight === 'profile' ? 'selected' : ''}`}
                onClick={() => { this.context.reportButtonPress('Preferences'); }}
              >
                Preferences
              </Link>
              <Link
                to='/my-account/payment'
                className={`tab payment-information col-xs-7 col-sm-6 ${currentTabToHighlight === 'billing' ? 'selected' : ''}`}
                onClick={() => { this.context.reportButtonPress('Payment Information'); }}
              >
                Payment Information
              </Link>
            </div>
          </div>
        </section>
        <Switch>
          <RouteWrapper
            path='/my-account/profile'
            name='AccountProfile'
            component={Profile}
          />
          <RouteWrapper
            path='/my-account/payment'
            name='PaymentInformation'
            component={PaymentInformation}
          />
          <Redirect
            from='/my-account'
            to='/my-account/profile'
            exact
          />
        </Switch>
      </div>
    );
  }
}
