import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Helmet from 'react-helmet';
import componentBase from '../../../../../../src/lib/component-base';
import MultiSegmentFlightsList from './children/MultiSegmentFlightsList';
import { dateFormatString } from '../../../../../../src/lib/date-time-format';

@componentBase('HistoricalFlightTrackerSegments')
export default class HistoricalFlightTrackerSegments extends React.Component {
  static propTypes = {
    segments: PropTypes.array,
    params: PropTypes.object,
    style: PropTypes.object,
    refreshCount: PropTypes.number,
    error: PropTypes.object,
  };

  static defaultProps = {
    segments: null,
  };

  constructor(props, context) {
    super(props, context);

    this.muiDefaultProps = {
      pageStyle: {
        marginBottom: '99px',
      },
      airportNameAddressBlock: {
        padding: '10px 0 10px 10px',
        airportName: {
          fontSize: '20pt',
          fontWeight: 'bold',
          lineHeight: '25px',
        },
        airportSubtitle: {
          fontSize: '12pt',
          marginTop: '5px',
        },
      },
      dateRightBlock: {
        padding: '10px 10px 10px 0',
        date: {
          fontSize: '20pt',
          lineHeight: '25px',
          textAlign: 'right',
        },
      },
      dateBlock: {
        padding: '0 10px 10px 10px',
        date: {
          fontSize: '20pt',
          lineHeight: '25px',
        },
      },
      contentBorder: {
        borderTop: '5px solid rgb(250, 167, 24)',
        marginBottom: '20px',
      },
    };
  }

  _getMetaTags = () => {
    const title = 'Historical Flight Tracker';
    return {
      title,
      meta: [{ name: 'robots', content: 'noindex' }],
    };
  };

  _getHeader = (flight) => {
    // console.log('flightstachts' ,flight)
    const airport = `Search results for ${flight.carrier.fs} ${flight.carrier.flightNumber}`;
    const airportMobile = `Search results for ${flight.carrier.fs} ${flight.carrier.flightNumber}`;
    const subtitle = 'This flight has multiple stops';
    const date = moment(flight.sortTime).format(dateFormatString(this.context.currentUser));

    return (
      <div className='row'>
        <div className='col-md-12'>
          <div className='row'>
            <div className='col-sm-8 col-md-8 col-lg-8'>
              <div className='row hidden-xs' style={this.muiDefaultProps.airportNameAddressBlock}>
                <div className='col-md-12' style={this.muiDefaultProps.airportNameAddressBlock.airportName}>
                  {airport}
                </div>
                <div className='col-md-12' style={this.muiDefaultProps.airportNameAddressBlock.airportSubtitle}>
                  {subtitle}
                </div>
              </div>
              <div className='row visible-xs' style={this.muiDefaultProps.airportNameAddressBlock}>
                <div className='col-md-12' style={this.muiDefaultProps.airportNameAddressBlock.airportName}>
                  {airportMobile}
                </div>
              </div>
            </div>
            <div className='col-sm-4 col-md-4 col-lg-4'>
              <div className='row visible-xs' style={this.muiDefaultProps.dateBlock}>
                <div className='col-md-12' style={this.muiDefaultProps.dateBlock.date}>
                  { date }
                </div>
                <div className='col-md-12' style={this.muiDefaultProps.airportNameAddressBlock.airportSubtitle}>
                  {subtitle}
                </div>
              </div>
              <div className='row hidden-xs' style={this.muiDefaultProps.dateRightBlock}>
                <div className='col-md-12' style={this.muiDefaultProps.dateRightBlock.date}>
                  { date }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12' style={this.muiDefaultProps.contentBorder} />
        </div>
      </div>
    );
  };

  render() {
    let results = null;
    const meta = this._getMetaTags();

    if (this.props.segments && this.props.segments.length > 0) {
      const header = this._getHeader(this.props.segments[0]);
      results = (
        <div>
          {header}
          <MultiSegmentFlightsList segments={this.props.segments} />
        </div>
      );
    }

    return (
      <div style={this.muiDefaultProps.pageStyle}>
        <Helmet {...meta} />
        {results}
      </div>
    );
  }
}
