import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import Crossfilter from '../../../src/components/lib/Crossfilter';
import { loadDeparturesOrArrivalsByCodeDateAndDirection } from '../redux/actions';
import { parseRouteParams } from '../redux/selectors';
import FsCard from '../../../src/components/lib/FsSection';
import DeparturesArrivalsSearch from './children/Search/DeparturesArrivalsSearch';
import ResultHeader from './children/ResultHeader';
import Dimensions from './children/Dimensions/Dimensions';
import componentBase from '../../../src/lib/component-base';
import { addMetaCanonical } from '../../../shared/lib/meta-tags';

const loadAirport = loadDeparturesOrArrivalsByCodeDateAndDirection;

const getErrorMessage = (error) => {
  const errors = {
    DATE_OUT_OF_RANGE: {
      header: 'Date Not Supported',
      description: "We're sorry, but the date you selected is outside the allowed date range.",
    },
    NO_RESULTS: {
      header: 'No Results For This Airport',
      description: "We're sorry, but FlightStats does not have any flights in our system for this airport.",
    },
    TOO_MANY_REQUESTS: {
      header: 'Your usage of this website is unusually high',
      description: 'We are temporarily disabling access for your user. You can try again after a while. If you are sharing your account with another user, please create separate accounts.',
    },
    UNKNOWN: {
      header: 'Unknown Exception Occurred',
      description: "We're sorry, but an unknown exception has occurred with your request. Please try again.",
    },
  };
  return errors[error] || errors.UNKNOWN;
};

@connect(state => ({
  airport: state.AirportDeparturesArrivals.airport,
  searchAirport: state.AirportDeparturesArrivals.searchAirport,
  basicInfo: state.AirportDeparturesArrivals.basicInfo,
  dateFieldIndex: state.AirportDeparturesArrivals.dateFieldIndex,
  friendlyError: state.AirportDeparturesArrivals.friendlyError,
  isArrival: state.AirportDeparturesArrivals.isArrival,
  isDeparture: state.AirportDeparturesArrivals.isDeparture,
  lastUpdated: state.AirportDeparturesArrivals.lastUpdated,
  list: state.AirportDeparturesArrivals.list || [],
  depArr: state.AirportDeparturesArrivals.depArr,
  error: state.AirportDeparturesArrivals.error,
  secondaryAction: state.AirportDeparturesArrivals.secondaryAction,
  loading: state.AirportDeparturesArrivals.loading,
  loaded: state.AirportDeparturesArrivals.loaded,
  routing: state.router,
  routeParams: parseRouteParams(state.router),
  user: state.Account.user,
}))

@componentBase('DeparturesArrivals')
export default class DeparturesArrivals extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    error: PropTypes.string,
    friendlyError: PropTypes.string,
    list: PropTypes.array,
    loading: PropTypes.bool,
    location: PropTypes.object,
    match: PropTypes.object,
    secondaryAction: PropTypes.bool,
  };

  componentDidMount() {
    const { dispatch, match } = this.props;
    const params = match && match.params;
    dispatch(loadAirport(params));
  }

  componentWillReceiveProps(nextProps) {
    const { dispatch, location, match } = nextProps;
    const pathname = location && location.pathname;
    const oldPathname = this.props.location && this.props.location.pathname;
    const newPath = pathname !== oldPathname;
    if (newPath) {
      const params = match && match.params;
      dispatch(loadAirport(params));
    }
  }

  meta = () => (addMetaCanonical({ title: 'Airport Departures/Arrivals' }, this.props.location && this.props.location.pathname));

  renderApp = () => {
    let appKey = '';
    const { match } = this.props;
    const params = match && match.params;
    for (const param in params) {
      if (params[param]) appKey += params[param];
    }
    if (this.props.loading) {
      return (
        <div>
          <div className='row' />
        </div>
      );
    }
    return (
      <Crossfilter list={this.props.list} key={appKey}>
        <Dimensions {...this.props} />
      </Crossfilter>
    );
  }

  renderError = (e) => {
    const errorBreakStyle = {
      height: '5px',
      backgroundColor: '#E34622',
    };

    const { header, description } = getErrorMessage(e);

    return (
      <FsCard>
        <section style={{ height: 'auto' }}>
          <div>
            <div className='row' style={errorBreakStyle} />
            <h4 style={{ textAlign: 'center', margin: '50px 0 5px 0' }}>{header}</h4>
            <p style={{ textAlign: 'center', margin: '0 15px 30px 15px' }}>{description}</p>
          </div>
        </section>
      </FsCard>
    );
  }

  render() {
    const { error, friendlyError } = this.props;
    const isError = error || friendlyError;

    return (
      <FsCard>
        <section style={{ height: 'auto' }}>
          <div className='container'>
            <Helmet {...this.meta()} />
            <DeparturesArrivalsSearch {...this.props} />
            <ResultHeader {...this.props} />
            {isError ? this.renderError(isError) : this.renderApp()}
          </div>
        </section>
      </FsCard>
    );
  }
}
