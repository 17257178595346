import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import WhiteCalendar from '../../../static/images/White-Calendar.png';

export default class FSDatePicker extends Component {
  static propTypes = {
    calendarIcon: PropTypes.string,
    onChange: PropTypes.func,
  }

  constructor(props) {
    super(props);

    this.state = {
      calendarOpen: false,
    };

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleCalendarOpen = this.handleCalendarOpen.bind(this);
    this.handleCalendarClose = this.handleCalendarClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleCalendarOpen = () => {
    if (!this.state.calendarOpen) {
      this.setState({
        calendarOpen: true,
      });
    }
  }

  handleCalendarClose = () => {
    if (this.state.calendarOpen) {
      this.setState({
        calendarOpen: false,
      });
    }
  }

  handleChange = (moment) => {
    this.props.onChange(moment);
    this.handleCalendarClose();
  }

  handleKeyDown = (e) => {
    if (e.keyCode === 27) { // escape key
      this.handleCalendarClose();
    }
  }

  render() {
    return (
      <div
        className='fs-datepicker'
        onKeyDown={this.handleKeyDown}
        onClick={this.handleCalendarOpen}
      >
        <DatePicker
          {...this.props}
          onChange={this.handleChange}
          onFocus={this.handleCalendarOpen}
          onBlur={this.handleCalendarClose}
          onClickOutside={this.handleCalendarClose}
          onSelect={this.handleCalendarClose}
        />
        <img
          className='calendar-icon'
          alt='Calendar'
          src={this.props.calendarIcon || WhiteCalendar}
          style={this.state.calendarOpen ? { opacity: '0.5' } : {}}
        />
      </div>
    );
  }
}
