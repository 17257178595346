import React from 'react';
import PropTypes from 'prop-types';
import { XAxis, YAxis, BrushedBarChart, ResponsiveContainer, ReferenceLine, Bar } from 'recharts';
import moment from 'moment';
import { BLUEPARTOFGRAPH, REDPARTOFGRAPH } from '../../../../../redux/Historical/constants';

export default class StackedBarChart extends React.Component {
  static propTypes = {
    setInitialCostAndTime: PropTypes.func,
    filterAlertsByTimeframe: PropTypes.func,
    filterAlertsByMonth: PropTypes.func,
    filteredData: PropTypes.array,
    data: PropTypes.array,
    dimensions: PropTypes.object,
    receiptCounts: PropTypes.array,
    currentBrushInxStart: PropTypes.number,
    currentBrushInxEnd: PropTypes.number,
    freezeState: PropTypes.func,
    currentData: PropTypes.array,
    rehydrated: PropTypes.bool,
    stackedBarChartUpdatedSwitch: PropTypes.func,
    hasStackedBarChartUpdated: PropTypes.bool,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      currentBrushInxStart: null,
      currentBrushInxEnd: null,
    };
  }

  componentDidMount() {
    this.props.stackedBarChartUpdatedSwitch();
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.hasStackedBarChartUpdated;
  }

  componentDidUpdate() {
    const currentIndexes = {
      currentBrushInxStart: this.state.currentBrushInxStart || this.props.currentBrushInxStart,
      currentBrushInxEnd: this.state.currentBrushInxEnd || this.props.currentBrushInxEnd,
    };

    const nonFunctionProps = {};
    for (const key in this.props) {
      if (key !== 'rehydrated' || typeof this.props[key] !== 'function') {
        nonFunctionProps[key] = this.props[key];
      }
    }
    const frozenState = Object.assign({}, nonFunctionProps, currentIndexes);
    this.props.freezeState(frozenState);
  }

  getYAxisLabel = () => { // just to keep the axis from rendering before data shows up
    let text = '';
    if (this.props.receiptCounts && this.props.receiptCounts.length) {
      text = 'Number Of Alerts Sent';
    }
    return text;
  }

  handleClick = (e) => {
    const monthClicked = moment.utc(e.oneScheduledDepartureThisMonth);

    this.props.filterAlertsByMonth(monthClicked);
  }

  handleBrushChange = (dateObj, receiptCounts) => {
    const { startIndex, endIndex } = dateObj;
    const currentBrushInxStart = startIndex;
    const currentBrushInxEnd = endIndex;
    const update = {
      currentBrushInxStart,
      currentBrushInxEnd,
    };
    const newState = Object.assign({}, this.state, update);
    this.setState(newState, () => {
      this.props.filterAlertsByTimeframe(dateObj, receiptCounts);
    });
  }

  render() {
    const height = (this.props.dimensions && this.props.dimensions.height) || 300;
    const yAxisLabel = this.getYAxisLabel();
    const receiptCounts = this.props.receiptCounts ? this.props.receiptCounts : [];
    const startIndex = this.props.currentBrushInxStart || +this.props.currentBrushInxStart === 0 ?
      this.props.currentBrushInxStart : 0;
    const endIndex = this.props.currentBrushInxEnd || +this.props.currentBrushInxEnd === 0 ?
      this.props.currentBrushInxEnd + 1 : receiptCounts.length - 1;
    const barWidth = this.props.dimensions && this.props.dimensions.barWidth ?
      this.props.dimensions.barWidth : 0;

    return (
      <div className='graph-container' style={{ height }}>
        <ResponsiveContainer width='100%'>
          <BrushedBarChart
            height={height}
            data={receiptCounts}
            barSize={barWidth}
            margin={{
              top: 5, right: 20, left: -10, bottom: 5,
            }}
            onClick={this.handleClick}
            brushProps={{
              onChange: (dateObj) => {
                this.handleBrushChange(dateObj, this.props.receiptCounts);
              },
              travellerWidth: 15,
              startIndex,
              endIndex,
              stroke: 'rgb(255, 255, 255)',
            }
            }
          >
            <XAxis dataKey='name' />
            <YAxis
              allowDecimals={false}
            />
            <ReferenceLine y={0} stroke='#000' />
            <Bar dataKey={`${BLUEPARTOFGRAPH}`} stackId='a' fill='#0e7206' />
            <Bar dataKey={`${REDPARTOFGRAPH}`} stackId='a' fill='#ff2e23' />
          </BrushedBarChart>
        </ResponsiveContainer>
        <span className='y-axis-graph-label'>
          {yAxisLabel}
        </span>
      </div>
    );
  }
}
