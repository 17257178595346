import React from 'react';
import PropTypes from 'prop-types';

const CardArticle = (props) => {
  const { children } = props;
  return (
    <article
      className={`flexible-card-article ${props.subscribed ? 'subscribed' : ''}`}
    >
      {children}
    </article>
  );
};

CardArticle.propTypes = {
  children: PropTypes.node,
  subscribed: PropTypes.bool,
};

export default CardArticle;
