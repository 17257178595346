import React, { Component } from 'react';
import FSFlatButton from './FSFlatButton';
import componentBase from '../../lib/component-base';

@componentBase('BlockedUser')
export default class BlockedUser extends Component {
  render() {
    const containerStyle = {
      textAlign: 'center',
    };

    const contentStyle = {
      paddingTop: '200px',
    };

    return (
      <div style={containerStyle}>
        <div className='fourOhFourCallout'>
          <div className='row'>
            <div className='col-xs-12'>
              <div style={contentStyle}>
                <img src={this.context.images.dangerZoneThumb} alt='Blocked' />
                <h1 className='dark-text'>Account blocked</h1>
                <h4>
                  <a href='https://helpdesk.cirium.com/s/topic/0TOJ5000000TNJ1OAO/flightstats-by-cirium'>
                    <FSFlatButton
                      label='Contact Helpdesk'
                      style={FSFlatButton.style({ margin: null, minWidth: null })}
                      labelStyle={{ verticalAlign: 'top' }}
                    />
                  </a>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
