// @flow
import React from 'react';
import styled from 'styled-components';
import { props as getProps } from 'ramda';
import { Link } from 'react-router-dom';

import { LinkCarat } from '../../LinkCarat/LinkCarat';
import { ClickHandler } from '../../../../modules/common-types';

type VariantLinkProps = {
  item: {
    href: string,
    onClick: ClickHandler,
  },
  innerRows: React.Node,
};

const getItemProps = getProps(['href', 'onClick']);

const StyledLink = styled(props => <Link {...props} />)`
  text-decoration: none;

  :hover {
    background-color: rgba(255, 255, 255, 0.3);
    cursor: pointer;
  }
`;

const StyledNextLink = styled.a`
  text-decoration: none;

  :hover {
    background-color: rgba(255, 255, 255, 0.3);
    cursor: pointer;
  }
`;

const RowContainer = styled.div`
  border-bottom: 2px solid #9b9b9b;
  display: flex;

  :hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
`;

const InnerRowsContainer = styled.div`
  flex: 1 0 calc(100% - 33px);
  width: calc(100% - 33px);
`;

const LinkCaratCell = styled.div`
  flex: 0 1 33px;
  width: 33px;
`;

const nextPaths = [
  // array of routes that have been migrated to next
  '/flight-tracker',
];

// will return true if a string from nextPaths array is a substring of bigString
const containsSubstring = (bigString = '') =>
  nextPaths.some((substring) => bigString.includes(substring));

export const VariantLink = ({
  item,
  innerRows,
}: VariantLinkProps): React.Node => {
  const [href, clickHandler] = getItemProps(item);
  const isNextLink = containsSubstring(href && href.toLowerCase());
  const LinkComponent = isNextLink ? StyledNextLink : StyledLink;
  return (
    <LinkComponent href={href} key={href} onClick={clickHandler} to={href}>
      <RowContainer>
        <InnerRowsContainer>{innerRows}</InnerRowsContainer>
        <LinkCaratCell>
          <LinkCarat />
        </LinkCaratCell>
      </RowContainer>
    </LinkComponent>
  );
};
