import React from 'react';
import PropTypes from 'prop-types';
import componentBase from '../../../../../../../../src/lib/component-base';
import StatusAdvisory from '../../../../../../../SingleFlight/children/FlightTracker/children/FlightHeader/StatusAdvisory';

@componentBase('HistoricalFlightHeader')
export default class HistoricalFlightHeader extends React.Component {
  static propTypes = {
    carrier: PropTypes.object,
    departureAirport: PropTypes.object,
    status: PropTypes.object,
    divertedAirport: PropTypes.object,
    extendedDetails: PropTypes.bool,
  };

  static defaultProps = {
    carrier: null,
    flightNumber: '',
    departureAirport: null,
    arrivalAirport: null,
    status: null,
  };

  render() {
    let result = (
      <div />
    );
    if (this.props.carrier) {
      const carrier = this.props.carrier;
      const status = this.props.status;
      let statusWording = (status.statusDescription && status.status) ? `${status.status} - ${status.statusDescription}` : (status.statusDescription || status.status);

      if (statusWording.indexOf('-') >= 0) {
        // format phrase the same way as Flight Tracker, ex: 'Delayed by 1hr | Arrived'
        statusWording = statusWording.split('-').reverse().map(word => word.trim());
        statusWording = statusWording[0].concat(' | ').concat(statusWording[1]);
      }

      if (status.diverted) {
        statusWording = status.status;
      }

      let statusAdvisory = '';

      if (this.props.status.advisoryDisplayStatus) {
        const advisoryProps = {
          status: this.props.status.status,
          redirected: this.props.status.redirected,
          diverted: this.props.status.diverted,
          departureAirportFS: this.props.departureAirport.fs,
          advisoryDisplayStatus: this.props.status.advisoryDisplayStatus,
          divertedAirport: this.props.divertedAirport,
        };
        statusAdvisory = (<StatusAdvisory {...advisoryProps} />);
      }

      result = (
        <div className='historical-flight-header'>
          <div className='status-header flight-status-box'>
            <div className='content-wrapper'>
              <h1 className='carrier-text-style'>
                {`(${carrier.fs}) ${carrier.name} ${carrier.flightNumber} ${this.props.extendedDetails ? 'Flight Details' : 'Historical Flight Status'}`}
              </h1>
              <p className={`status-text-style ${status.color}`}>{statusWording}</p>
            </div>
          </div>
          {statusAdvisory}
        </div>
      );
    }

    return result;
  }
}
