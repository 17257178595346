import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { SelectField } from '@flightstats/component-lib';

import { dateFormatString } from '../../../src/lib/date-time-format';

const buildDateOption = (momentDate, formatString) => ({
  value: momentDate.format('YYYY/M/D'),
  label: momentDate.format(formatString),
});

export const buildDateOfTravelOptions = (formatString) => {
  // forcing midday to avoid server/client date issues
  const now = moment.utc();
  return [
    buildDateOption(now.clone().subtract(3, 'days'), formatString),
    buildDateOption(now.clone().subtract(2, 'days'), formatString),
    buildDateOption(now.clone().subtract(1, 'days'), formatString),
    buildDateOption(now.clone(), formatString),
    buildDateOption(now.clone().add(1, 'days'), formatString),
    buildDateOption(now.clone().add(2, 'days'), formatString),
    buildDateOption(now.clone().add(3, 'days'), formatString),
  ];
};

const TravelDateSelectField = (props) => {
  const {
    foregroundColor,
    backgroundColor,
    onChange,
    secondaryAction,
    user,
    value,
  } = props;

  return (
    <SelectField
      name='travel-date-select'
      value={value}
      results={buildDateOfTravelOptions(dateFormatString(user))}
      handleMenuSelection={onChange}
      iconColor={foregroundColor}
      textColor={foregroundColor}
      placeholderColor={foregroundColor}
      bgColor={backgroundColor}
      borderColor={foregroundColor}
      selectWrapperPosition='relative'
      inputFieldBg={false}
      secondaryAction={secondaryAction}
    />
  );
};

TravelDateSelectField.propTypes = {
  backgroundColor: PropTypes.string,
  foregroundColor: PropTypes.string,
  onChange: PropTypes.func,
  secondaryAction: PropTypes.func,
  user: PropTypes.object,
  value: PropTypes.string,
};

TravelDateSelectField.defaultProps = {
  backgroundColor: 'rgba(191, 191, 191, 0.66)',
  foregroundColor: 'white',
};

export default TravelDateSelectField;
