import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { pathOr } from 'ramda';
import componentBase from '../../../../src/lib/component-base';
import onResize from '../../../../src/lib/on-resize';
import AdvancedSearch from './children/AdvancedSearch/AdvancedSearch';
import { errorMessageForCode } from '../../../../src/lib/errors';
import flexErrors from '../../../../src/constants/flex-errors';
import Meta from '../../../../shared/components/Meta/container';
import { resolveCanonicalFromPathname } from '../../../App/redux/selectors';
import {
  setRandomFlightPath,
  setFlightTrackerClearErrors,
  setFlightTrackerSearchError,
} from '../../redux/Search/actions';

const advancedTitleStyle = {
  padding: '0',
  margin: '20px 0',
  fontSize: '3em',
  lineHeight: '1',
};

@connect(state => ({
  airline: state.FlightTrackerSearch.airline,
  byAirportAirline: state.FlightTrackerSearch.byAirportAirline,
  byFlightError: state.FlightTrackerSearch.byFlightError,
  canonical: resolveCanonicalFromPathname(state),
  departureDate: state.FlightTrackerSearch.departureDate,
  error: state.FlightTrackerSearch.error,
  flightNumber: state.FlightTrackerSearch.flightNumber,
  queryError: state.FlightTrackerSearch.queryError,
  randomFlightPath: state.FlightTrackerSearch.randomFlightPath,
  secondaryAdvancedSearchActionAirline:
    state.FlightTrackerSearch.secondaryAdvancedSearchActionAirline,
  secondaryAdvancedSearchActionDepDate:
    state.FlightTrackerSearch.secondaryAdvancedSearchActionDepDate,
}))
@onResize()
@componentBase('FlightTrackerAdvancedSearch')
export default class FlightTrackerAdvancedSearch extends Component {
  static propTypes = {
    canonical: PropTypes.string,
    dispatch: PropTypes.func,
    error: PropTypes.string,
    match: PropTypes.object,
    queryError: PropTypes.string,
    randomFlightPath: PropTypes.string,
  };

  constructor(props, context) {
    super(props, context);
    this.reportButtonPress = this.reportButtonPress.bind(this);
  }

  componentDidMount() {
    this.forceUpdate();
    this.initResizeListener();
    this.props.dispatch(setRandomFlightPath());
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    // clear error message
    dispatch(setFlightTrackerClearErrors());
    dispatch(setFlightTrackerSearchError());
    this.removeResizeListener();
  }

  handleResize = () => this.forceUpdate();

  gotoPath = (newPath) => {
    const { dispatch } = this.props;
    dispatch(push(newPath));
  }

  randomFlightClick = () => {
    const { randomFlightPath } = this.props;
    this.reportButtonPress('Callout - Random Flight');
    // initiate a new request to the Next app
    window.location = process.env.NODE_ENV === 'development' ? `http://localhost:4000/v2${randomFlightPath}` : `/v2${randomFlightPath}`;
  }

  depArrClick = () => {
    this.reportButtonPress('Callout - DepArr');
    this.gotoPath('/airports/departing-arriving/search');
  }

  historicalClick = () => {
    this.reportButtonPress('Callout - Historical Search');
    this.gotoPath('/historical-flight/search');
  }

  metaTags = () => {
    const title = 'Flight Tracker - Track the current status of your flight';
    const description = 'FlightStats Global Flight Tracker - Track the real-time flight status of your flight. See if your flight has been delayed or cancelled and track the live position on a map. FlightStats is the recognized leader in commercial airline flight status tracking.';
    const { canonical, match = {} } = this.props;
    return {
      canonical,
      description,
      keywords: 'flight tracker,flight status,flight search,find flight,live flight,random flight',
      match,
      title,
    };
  }

  reportButtonPress = (label) => {
    this.context.reportButtonPress(label);
  }

  formatErrorText = () => {
    const { error, queryError } = this.props;
    const isError = (error && flexErrors(error)) ||
    (queryError && errorMessageForCode(queryError));
    return isError && (
      <div className='search-error-text-block' style={{ marginTop: '20px' }}>
        <p className='search-error-text'>{isError}</p>
      </div>
    );
  }

  subscriptionActive = () => pathOr(false, ['user', 'subscriptionActive'], this.props);

  render = () => (
    <section>
      <Meta {...this.metaTags()} />
      <div className={`
        flight-tracker-search-container
          ${this.subscriptionActive() && 'subscribed-advanced-search-container'}
        `}
      >
        <div className='fs-search advanced-search' >
          <div>
            <div className='row'>
              <h1 style={advancedTitleStyle}>Flight Tracker</h1>
              {this.formatErrorText()}
            </div>
          </div>
          <AdvancedSearch
            {
              ...{
                ...this.props,
                depArrClick: this.depArrClick,
                historicalClick: this.historicalClick,
                randomFlightClick: this.randomFlightClick,
                reportButtonPress: this.reportButtonPress,
              }
            }
          />
        </div>
      </div>
    </section>
  );
}
