import React from 'react';
import PropTypes from 'prop-types';
import { SelectField } from '@flightstats/component-lib';

import { timeFormatSelect } from '../../../src/lib/date-time-format';

export const buildTimeOfTravelOptions = user => ([
  { value: 0, label: timeFormatSelect(user, '00:00 - 06:00', '12:00 am - 6:00 am') },
  { value: 6, label: timeFormatSelect(user, '06:00 - 12:00', '6:00 am - 12:00 pm') },
  { value: 12, label: timeFormatSelect(user, '12:00 - 18:00', '12:00 pm - 6:00 pm') },
  { value: 18, label: timeFormatSelect(user, '18:00 - 00:00', '6:00 pm - 12:00 am') },
]);

const TravelTimeSelectField = (props) => {
  const {
    foregroundColor,
    backgroundColor,
    onChange,
    secondaryAction,
    user,
    value,
  } = props;
  return (
    <SelectField
      name='travel-time-select'
      value={value}
      results={buildTimeOfTravelOptions(user)}
      handleMenuSelection={onChange}
      iconColor={foregroundColor}
      textColor={foregroundColor}
      placeholderColor={foregroundColor}
      bgColor={backgroundColor}
      borderColor={foregroundColor}
      selectWrapperPosition='relative'
      inputFieldBg={false}
      secondaryAction={secondaryAction}
    />
  );
};

TravelTimeSelectField.propTypes = {
  backgroundColor: PropTypes.string,
  foregroundColor: PropTypes.string,
  onChange: PropTypes.func,
  secondaryAction: PropTypes.func,
  user: PropTypes.object,
  value: PropTypes.number,
};

TravelTimeSelectField.defaultProps = {
  backgroundColor: 'rgba(191, 191, 191, 0.66)',
  foregroundColor: 'white',
};

export default TravelTimeSelectField;
