import React from 'react';
import PropTypes from 'prop-types';
import IconHeader from '../../../../../../shared/components/IconHeader/IconHeader';
import FSFlatButton from '../../../../../../src/components/lib/FSFlatButton';

const customButtonStyle = {
  minWidth: '300px',
  height: '35px',
  paddingTop: '5px',
};
const HighlightedSection = props => (
  <div className='HighlightedSection'>
    <div className='sectionHeader'>
      <IconHeader
        icon={props.icon}
        onClickSubtitle={props.handleAlreadyHaveAccountClick}
        subtitle={props.subTitle}
        title={props.title}
      />
    </div>
    <div className='row sectionBody'>
      {props.body}
    </div>
    <div className={`account-type-footer ${props.planSelected}-footer`}>
      <div className='row'>
        <div className='col-xs-12 col-sm-8'>
          <h3>
            {props.footerTitle}
            {
              (props.planSelected && !props.hideAccountText) ?
                <span className='formatted-text'>
                  {props.planSelected === 'premium' ? 'professional' : props.planSelected} Account
                </span> :
              null
            }
          </h3>
        </div>
        {props.buttonTitle && <div className='col-xs-12 col-sm-4'>
          <FSFlatButton
            label={props.buttonTitle}
            onClick={props.submit}
            style={FSFlatButton.style(customButtonStyle)}
            disabled={props.buttonDisabled}
          />
        </div>}
      </div>
    </div>
  </div>
);

HighlightedSection.propTypes = {
  buttonTitle: PropTypes.string,
  body: PropTypes.object,
  buttonDisabled: PropTypes.bool,
  footerTitle: PropTypes.string,
  handleAlreadyHaveAccountClick: PropTypes.func,
  hideAccountText: PropTypes.bool,
  icon: PropTypes.string,
  planSelected: PropTypes.string,
  submit: PropTypes.func,
  subTitle: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

HighlightedSection.defaultProps = {
  buttonDisabled: false,
};

export default HighlightedSection;
