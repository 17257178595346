import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
// redux
import { actions } from '../../../redux';
import { catchErrors, formFieldsValid } from '../../../redux/selectors';
import {
  loadUserAlertHistory,
  // deactivateAllCurrentAlerts,
  // ^^^^ not being used, but may be related to (https://www.pivotaltracker.com/story/show/146728443)
  // so leaving as placeholder
} from '../../../../FlightAlerts/redux/Current/actions';
import componentBase from '../../../../../src/lib/component-base';
import SubscribedUserPaymentInfo from './children/SubscribedUserPaymentInfo';
import FSFlatButton from '../../../../../src/components/lib/FSFlatButton';
import ViewPaymentHistory from './children/ViewPaymentHistory/ViewPaymentHistory';

// helpers/utils
import { isBusinessUser } from '../../../../../src/utils/isBusinessUser';
import { dateFormatString } from '../../../../../src/lib/date-time-format';

// assets
import { icons } from '../../../lib/constants';

const { paymentHistoryIcon } = icons;

@connect(
  state => ({
    braintreeClientToken: state.Account.braintreeClientToken,
    braintreeClientTokenError: catchErrors(state.Account.braintreeClientTokenError),
    braintreePaymentHistory: state.Account.paymentHistory,
    braintreePlans: state.Account.braintreePlans,
    braintreePlansError: catchErrors(state.Account.braintreePlansError),
    braintreeSubscription: state.Account.braintreeSubscription,
    braintreeSubscriptionError: catchErrors(state.Account.braintreeSubscriptionError),
    cancelBraintreeSubscriptionSuccess: state.Account.cancelBraintreeSubscriptionSuccess,
    cancelBraintreeSubscriptionError: catchErrors(state.Account.cancelBraintreeSubscriptionError),
    cityError: state.Account.cityError,
    countryLabel: state.Account.countryLabel,
    countryCodeError: state.Account.countryCodeError,
    flightsMonitored: state.FlightAlerts.flightsMonitored,
    gettingBraintreePaymentHistory: state.Account.gettingPaymentHistory,
    gettingBraintreePaymentHistoryError: catchErrors(state.Account.paymentHistoryError),
    loggedIn: state.Account.loggedIn,
    newAdditionalInfo: state.Account.newAdditionalInfo,
    nameError: state.Account.nameError,
    postalCodeError: state.Account.postalCodeError,
    reasonForUnsubscribing: state.Account.reasonForUnsubscribing,
    streetAddressError: state.Account.streetAddressError,
    updatingBillingAddressError: catchErrors(state.Account.updatingBillingAddressError),
    updatingPaymentInformation: state.Account.updatingPaymentInformation,
    updatedPaymentInformation: state.Account.updatedPaymentInformation,
    updatingPaymentInformationError: catchErrors(state.Account.updatingPaymentInformationError),
    updateReasonForUnsubscribing: state.Account.updateReasonForUnsubscribing,
    user: state.Account.user,
  }),
  actions,
)

@componentBase('PaymentInformation')
export default class PaymentInformation extends Component {
  static propTypes = {
    braintreeClientToken: PropTypes.string,
    braintreeClientTokenError: PropTypes.string,
    braintreePaymentHistory: PropTypes.array,
    cancelBraintreeSubscription: PropTypes.func,
    createBraintreeSubscription: PropTypes.func,
    dispatch: PropTypes.func,
    endSpinner: PropTypes.func,
    flightAlertCreditUse: PropTypes.func,
    getBraintreeClientToken: PropTypes.func,
    getBraintreePlans: PropTypes.func,
    getProfile: PropTypes.func,
    getPaymentHistory: PropTypes.func,
    loggedIn: PropTypes.bool,
    newAdditionalInfo: PropTypes.object,
    reasonForUnsubscribing: PropTypes.string,
    resetAuthState: PropTypes.func,
    routerPush: PropTypes.func,
    startSpinner: PropTypes.func,
    updateAdditionalInfoInput: PropTypes.func,
    updateBillingAddress: PropTypes.func,
    updateFormErrorState: PropTypes.func,
    updatePaymentInformation: PropTypes.func,
    updatedPaymentInformation: PropTypes.object,
    updatingPaymentInformationError: PropTypes.string,
    updatingPaymentInformation: PropTypes.bool,
    updateReasonForUnsubscribing: PropTypes.func,
    user: PropTypes.object.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      changePaymentInformationDialogOpen: false,
      updateBillingButtonLabel: 'SAVE CHANGES',
      upgradeSubscriptionModalOpen: false,
      buyFlightAlertCreditsModalOpen: false,
      buyHistoricalCreditsModalOpen: false,
      unsubscribeModalOpen: false,
      updateCardButtonsDisabled: true,
      unsubscribeButtonsDisabled: false,
    };

    this.handleModalToggle = this.handleModalToggle.bind(this);
    this.isValid = this.isValid.bind(this);
    this.renderErrorMessage = this.renderErrorMessage.bind(this);
    this.togglePaymentInformationDialog = this.togglePaymentInformationDialog.bind(this);
    this.goToSubscribe = this.goToSubscribe.bind(this);
    // component ref placeholders
    this.updateCreditCardForm = null;
  }

  componentWillMount() {
    // This was causing an endless loop when getProfile request failed
    // this.props.getProfile();
  }

  componentDidMount() {
    const {
      dispatch,
      getBraintreeClientToken,
      getBraintreePlans,
      getPaymentHistory,
      loggedIn,
    } = this.props;

    getBraintreeClientToken();
    getBraintreePlans();
    dispatch(loadUserAlertHistory());

    loggedIn && getPaymentHistory();
  }


  componentWillReceiveProps(nextProps) {
    let updateCardButtonsDisabled = false;
    const {
      braintreeClientToken,
      braintreeClientTokenError,
      updatingPaymentInformation,
      updatedPaymentInformation,
      updatingPaymentInformationError,
    } = nextProps;
    if (!braintreeClientToken ||
      braintreeClientTokenError ||
      updatingPaymentInformation) {
      updateCardButtonsDisabled = true;
    }
    this.setState({ updateCardButtonsDisabled });

    if (updatingPaymentInformationError) {
      this.props.resetAuthState();
      this.failedCreditCardUpdate();
    }

    if (updatedPaymentInformation) {
      this.props.resetAuthState();
      this.successfulCreditCardUpdate();
    }
  }

  onPaymentFormSubmitted = () => this.setState(
    { updateCardButtonsDisabled: true },
    () => Promise.all([
      Promise.resolve(this.props.user),
      this.updateCreditCardForm.sendPaymentMethod(),
    ])
      .then((values) => {
        const [userProfile, braintreePayload] = values;
        return this.updateCreditCard(braintreePayload, userProfile);
      })
      .catch((err) => {
        if (err.type !== 'CUSTOMER') {
        // not an input error
          this.context.toast.error(catchErrors(err), 'Error', false, 4);
        }
        // errors handled by input fields on the tokenized braintree form
        this.setState({ updateCardButtonsDisabled: false });
        return false;
      }),
  );

  setComponentRef = (refName, ref) => {
    this[refName] = ref;
  }

  getCredits = () => {
    const { user } = this.props;
    const {
      flightAlertsMonthlyCredits,
      flightAlertsReservedCredits,
      historicalMonthlyCredits,
      historicalReservedCredits,
      historicalMonthlyCreditsUsed,
      historicalReservedCreditsUsed,
    } = user;
    const unlimit = isBusinessUser(user);
    const creditsArray = [
      flightAlertsMonthlyCredits,
      flightAlertsReservedCredits,
      historicalMonthlyCredits,
      historicalReservedCredits,
    ].map((creditItem) => {
      if (unlimit) {
        return Infinity;
      }
      if (!creditItem) {
        return 0;
      }
      return creditItem;
    });
    const totalHistoricalCreditsUsed = (historicalMonthlyCreditsUsed +
      historicalReservedCreditsUsed) || 0;
    return {
      flightAlertsMonthlyCredits: creditsArray[0],
      flightAlertsReservedCredits: creditsArray[1],
      historicalMonthlyCredits: creditsArray[2],
      historicalReservedCredits: creditsArray[3],
      totalHistoricalCreditsUsed,
    };
  }

  getPaymentDetailsLastFour = () => {
    const { user } = this.props;
    if (user) {
      const { paymentDetails } = user;
      if (paymentDetails) {
        const { last4 } = paymentDetails;
        let lastFour = '';
        if (last4) {
          lastFour = `•••• •••• •••• ${last4}`;
        }
        return lastFour;
      }
    }
    return '';
  }

  getRenewalDate = () => {
    const { user } = this.props;
    if (user) {
      const { subscriptionRenewedAt } = user;
      if (this.isSubscriptionActive() && subscriptionRenewedAt) {
        return moment.utc(subscriptionRenewedAt).add(1, 'months').format(dateFormatString(user));
      }
    }
    return '';
  }

  getSubscriptionLevelText = () => {
    const { user } = this.props;

    if (!user || !this.isSubscriptionActive()) {
      return 'Free Member';
    }

    const { subscriptionLevel } = user;

    switch (subscriptionLevel) {
      case 1:
        return 'Standard Subscription';
      case 3:
        return 'Professional Subscription';
      case 11:
        return 'Business Subscription';
      default:
        return 'Free Member';
    }
  }

  getAdditionalInfo = () => {
    const { user, newAdditionalInfo } = this.props;
    const { additionalInfo } = user;
    return {
      ...additionalInfo,
      ...newAdditionalInfo,
    };
  }

  isSubscriptionActive = () => this.props.user && this.props.user.subscriptionActive;

  togglePaymentInformationDialog = (bool) => {
    this.setState({ changePaymentInformationDialogOpen: bool });
  }

  // Actually unsubscribes account
  handleUnsubscribeAccount = () => {
    this.setState({ unsubscribeButtonsDisabled: true }, () => this.props.startSpinner());

    return this.props.cancelBraintreeSubscription(this.props.reasonForUnsubscribing)
      .then(() => {
        if (this.isSubscriptionActive()) {
          this.context.reportUserUnsubscribed(this.props.user.subscriptionLevel);
        }
      })
      .then(() => this.props.getProfile())
      .then(() => {
        this.setState({
          unsubscribeButtonsDisabled: false,
          unsubscribeModalOpen: false,
        }, () => {
          this.props.updateReasonForUnsubscribing('');
          this.props.endSpinner();
        });
      })
      .catch((err) => {
        this.setState({
          unsubscribeButtonsDisabled: false,
          unsubscribeModalOpen: false,
        });
        this.context.toast.error(catchErrors(err), 'Error', false, 4);
        this.props.updateReasonForUnsubscribing('');
        this.props.endSpinner();
      });
  }

  handleModalToggle = (modal, open, fetchProfile = false) => {
    if (fetchProfile) {
      return this.props.getProfile()
        .then(() => this.setState(
          {
            [modal]: open,
          },
          () => this.props.getPaymentHistory()
            .then(() => this.props.endSpinner())
            .catch(() => this.props.endSpinner()),
        ))
        .catch(err => this.setState(
          {
            [modal]: open,
          },
          () => {
            this.props.endSpinner();
            this.context.toast.error(catchErrors(err), 'Error', false, 4);
          },
        ));
    }

    this.setState({ [modal]: open });
    return false;
  }

  handleCountryChange = (option) => {
    const { label, value } = option;
    const billingInfo = this.getAdditionalInfo();
    const combinedInfo = {
      ...billingInfo,
      countryCode: value,
    };
    const newState = {
      newAdditionalInfo: combinedInfo,
      countryLabel: label,
    };

    this.props.updateAdditionalInfoInput(newState);
  }

  handleInput = (stateKey, value) => {
    const { user, newAdditionalInfo } = this.props;
    const { additionalInfo } = user;
    const newState = {
      ...additionalInfo,
      ...newAdditionalInfo,
      [stateKey]: value,
    };
    this.props.updateAdditionalInfoInput({ newAdditionalInfo: newState });
  }

  handleUpdateBillingAddress = () => {
    const newBillingInfo = this.getAdditionalInfo();
    const propClone = {
      ...this.props,
      additionalInfo: newBillingInfo,
    };

    if (!this.isValid(propClone)) return false;

    this.setState({ updateBillingButtonLabel: 'SAVING CHANGES ' });

    const updatedBillingAddress = this.createBillingAddress();

    return this.props.updateBillingAddress(updatedBillingAddress)
      .then(() => this.props.getProfile())
      .then(() => {
        this.setState({ updateBillingButtonLabel: 'SAVE CHANGES' }, () => {
          this.context.toast.success('Your billing address has been updated', 'Success');
        });
      })
      .catch((ex) => {
        this.setState({ updateBillingButtonLabel: 'SAVE CHANGES' });
        this.context.toast.error(catchErrors(ex), 'Error', false, 4);
      });
  }

  successfulCreditCardUpdate = () => {
    this.props.getProfile();
    this.togglePaymentInformationDialog(false);
    this.updateCreditCardForm.clearErrors();
    this.context.toast.success('Your credit card has been updated', 'Success');
  }

  updateCreditCard = (braintreePayload, userProfile) => {
    // Now that we have a nonce, send it to the server
    const subscriptionData = {
      email: userProfile.email,
      nonce: braintreePayload.nonce,
      clientToken: this.props.braintreeClientToken,
    };

    return this.updatePaymentInfoOrCreateSubscription(subscriptionData);
  };

  createBillingAddress = () => {
    const userBillingInfo = this.getAdditionalInfo();
    if (userBillingInfo) {
      return {
        ...userBillingInfo,
        cardholderName: userBillingInfo.name,
      };
    }
    return {};
  }

  goToSubscribe = () => this.props.routerPush('/subscription');

  isValid = (currentState) => {
    let errorValue = false;
    const newState = formFieldsValid(currentState);

    if (newState.errorsPresent) {
      errorValue = true;
    }
    delete newState.errorsPresent;
    this.props.updateFormErrorState(newState);

    return !errorValue;
  }

  updatePaymentInfoOrCreateSubscription = (subscriptionData) => {
    if (this.isSubscriptionActive()) {
      return this.props.updatePaymentInformation(subscriptionData);
    }
    // when subscription becomes inactive, the subscription will be handled as if it were cancelled,
    // so we handle 'reactivation' as new subscription creation
    const billingAddress = this.createBillingAddress();
    const subscriptionDataForBraintreeCreation = { ...subscriptionData, ...billingAddress };
    return this.props.createBraintreeSubscription(subscriptionDataForBraintreeCreation)
      .catch((err) => {
        this.context.toast.error(catchErrors(err), 'Error', false, 4);
      });
  }

  renderErrorMessage = (errorType, errorMessage) => (
    <div className={`invalid-${errorType}`}>
      <span className='text'>
        {errorMessage}
      </span>
    </div>
  );

  renderSubscribedUserDiv = () => {
    const additionalInfo = this.getAdditionalInfo();
    const credits = this.getCredits();
    const paymentDetailsLastFour = this.getPaymentDetailsLastFour();
    const subscriptionLevelText = this.getSubscriptionLevelText();
    const renewalDate = this.getRenewalDate();
    const isSubscriptionActive = this.isSubscriptionActive();
    const updateCardButtonsDisabled = (!this.props.braintreeClientToken ||
      this.props.braintreeClientTokenError ||
      this.props.updatingPaymentInformation);
    const props = {
      ...this.state,
      ...this.props,
      additionalInfo,
      credits,
      successfullyUpdatedCCinfo: this.props.updatedPaymentInformation,
      updateCardButtonsDisabled,
      handleCountryChange: this.handleCountryChange,
      handleInput: this.handleInput,
      handleUnsubscribeAccount: this.handleUnsubscribeAccount,
      handleModalToggle: this.handleModalToggle,
      handleUpdateBillingAddress: this.handleUpdateBillingAddress,
      isSubscriptionActive,
      isValid: this.isValid,
      onPaymentFormSubmitted: this.onPaymentFormSubmitted,
      paymentDetailsLastFour,
      renewalDate,
      renderErrorMessage: this.renderErrorMessage,
      setComponentRef: this.setComponentRef,
      subscriptionLevelText,
      togglePaymentInformationDialog: this.togglePaymentInformationDialog,
    };
    return (<SubscribedUserPaymentInfo {...props} />);
  }

  renderNotSubscribedUserDiv = () => (
    <div className='row payment-information-not-premium'>
      <div className='col-xs-12 text'>
        <p>You currently do not have a subscription</p>
      </div>
      <div className='col-xs-12 button'>
        <FSFlatButton
          label='SUBSCRIBE'
          onClick={this.goToSubscribe}
        />
      </div>
    </div>
  );

  render() {
    return (
      <div className='payment-information-container'>
        {this.isSubscriptionActive() ?
          this.renderSubscribedUserDiv() : this.renderNotSubscribedUserDiv()}
        <ViewPaymentHistory
          invoiceItems={this.props.braintreePaymentHistory}
          icon={paymentHistoryIcon}
        />
      </div>
    );
  }
}
