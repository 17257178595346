import React, { Component } from 'react';
import PropTypes from 'prop-types';
import componentBase from '../../../../../../src/lib/component-base';
import AccountModalTextarea from './AccountModalTextarea';
import AccountModalUnsubscribeWarning from './AccountModalUnsubscribeWarning';

const textStyleProps = {
  fontSize: '16px',
  fontWeight: 600,
};

@componentBase('DeleteAccount')
export default class DeleteAccount extends Component {
  static propTypes = {
    deleteAccountCheckboxChecked: PropTypes.bool,
    deleteAccountSetCheckbox: PropTypes.func,
    reasonForUnsubscribing: PropTypes.string,
    renewalDate: PropTypes.string,
    showTextarea: PropTypes.bool,
    updateReasonForUnsubscribing: PropTypes.func,
  };

  state = {
    open: false,
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleCheck = () => {
    this.props.deleteAccountSetCheckbox(!this.props.deleteAccountCheckboxChecked);
  };

  render() {
    return (
      <div className='row  account-large-modal'>
        <section className='change-password'>
          <div className='row'>
            {
              this.props.showTextarea ?
                (
                  <div className='col-xs-12'>
                    <div style={{ margin: '5px 0 10px' }}>
                      <h3 style={{ ...textStyleProps, margin: '7px 0' }}>
                        Your feedback is incredibly valuable to our team at FlightStats.
                      </h3>
                      <h3 style={textStyleProps}>
                        {
                          `If you don't mind us asking, what made you delete your account?
                          What could we do better?`
                        }
                      </h3>
                    </div>
                    <AccountModalTextarea
                      value={this.props.reasonForUnsubscribing}
                      updateValue={this.props.updateReasonForUnsubscribing}
                    />
                    <AccountModalUnsubscribeWarning
                      renewalDate={this.props.renewalDate}
                      textStyle={textStyleProps}
                    />
                  </div>
                ) : null
            }
            <div
              className='col-xs-12'
              style={
                this.props.showTextarea ?
                { marginTop: '-20px' } :
                { marginTop: '20px' }
              }
            >
              <h3 style={textStyleProps}>
                {
                  `By deleting your account,
                  you understand that your data will be permanently removed.`
                }
              </h3>
              <input
                type='checkbox'
                name='deleteCheckbox'
                checked={this.props.deleteAccountCheckboxChecked}
                onChange={this.handleCheck}
                style={{ marginRight: '10px' }}
              />
              <span style={textStyleProps}>
                I understand
              </span>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
