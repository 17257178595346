import React from 'react';
import { PropTypes } from 'prop-types';
import Helmet from 'react-helmet';
import { pathOr } from 'ramda';
import { formatCanonicalParams } from '../../lib/format-canonical-params';

const resolveOneValueFromVariousKeys = (keys, props) => keys
  .map(p => pathOr('', ['match', 'params', p], props))
  .find(v => v);

const Meta = (props) => {
  const airportCode = resolveOneValueFromVariousKeys(
    ['airportCode', 'departureAirport', 'departureAirportCode'],
    props,
  );
  const airlineCode = resolveOneValueFromVariousKeys(
    ['carrier', 'carrierCode'],
    props,
  );
  const arrivalAirportCode = pathOr('', ['match', 'params', 'arrivalAirportCode'], props);
  const { canonical, description, keywords, title, iOSDeepLink, noIndex } = props;
  const formattedCanonical = formatCanonicalParams(
    canonical,
    { airportCode, airlineCode, arrivalAirportCode },
  );
  const meta = {
    title,
    link: [{ rel: 'canonical', href: formattedCanonical }],
    meta: [
      { name: 'description', content: description },
      { name: 'keywords', content: keywords },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      { property: 'og:url', content: formattedCanonical },
    ],
  };

  if (iOSDeepLink) {
    meta.meta.push({ name: 'FlightStats', content: `app-id=572700574, app-argument=${formattedCanonical}` });
  }

  if (noIndex) {
    meta.meta.push({ name: 'robots', content: 'noindex' });
  }

  return (
    <Helmet {...meta} />
  );
};

Meta.defaultProps = {
  canonical: '',
};

Meta.propTypes = {
  canonical: PropTypes.string,
  description: PropTypes.string,
  iOSDeepLink: PropTypes.bool,
  noIndex: PropTypes.bool,
  keywords: PropTypes.string,
  match: PropTypes.object,
  title: PropTypes.string,
};

export default Meta;
