export const MAX_POSTAL_CODE_FIELD_LENGTH = 9;

export const sanitizePostalCode = value =>
  (value ? value.replace(/[^\w0-9-]/g, '') : '');

export const isValidPostalCode = (value) => {
  const sanitized = sanitizePostalCode(value);

  const minusHyphens = sanitized.replace(/-/g, '');

  // postal code is optional
  return minusHyphens.length <= MAX_POSTAL_CODE_FIELD_LENGTH;
};

export const isValuePresent = value => !!value;
