import React from 'react';
import PropTypes from 'prop-types';
import { CommonSection } from '@flightstats/component-lib';
import Ad from '../Ad/Ad';

const CommonSectionWrapper = props => (
  <div className='CommonSectionWrapper'>
    <CommonSection title={props.title}>
      <div className='common-section-inner'>
        <div className='left-block'>
          {props.children}
        </div>
        {!props.subscriptionActive && !props.hideAd && props.adUnit ? (
          <div className='right-ad-block'>
            <Ad
              key={props.adUnit.id}
              {...props.adUnit}
              {...props.subscriptionActive}
            />
          </div>
        ) : ''}
      </div>
    </CommonSection>
  </div>
);

CommonSectionWrapper.propTypes = {
  adUnit: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  children: PropTypes.object,
  hideAd: PropTypes.bool,
  subscriptionActive: PropTypes.bool,
  title: PropTypes.string,
};

export default CommonSectionWrapper;
