import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'react-leaflet';
import componentBase from '../../../../../src/lib/component-base';
import MarkerLegend from './children/MarkerLegend';
import AirportDelays from './children/AirportDelays';
import SatTileLayer from '../../../../../src/components/MapControlsAndLayers/SatTileLayer';
import MapLabelsLayer from '../../../../../src/components/MapControlsAndLayers/LabelsTileLayer';
import MapRadarLayer from '../../../../../src/components/MapControlsAndLayers/RadarTileLayer';
import MapZoomControl from '../../../../../src/components/MapControlsAndLayers/ZoomControl';
import onResize from '../../../../../src/lib/on-resize';

const regionExtents = {
  northAmerica: { center: [39, -97], zoom: 4 },
  europe: { center: [49, 10], zoom: 4 },
  asia: { center: [32, 107], zoom: 3 },
  oceania: { center: [-29, 148], zoom: 3 },
  africa: { center: [5, 21], zoom: 3 },
  southAmerica: { center: [-25, -65], zoom: 3 },
};

const smallRegionExtents = {
  northAmerica: { center: [39, -97], zoom: 3 },
  europe: { center: [49, 10], zoom: 4 },
  asia: { center: [12, 107], zoom: 3 },
  oceania: { center: [-29, 148], zoom: 3 },
  africa: { center: [0, 21], zoom: 3 },
  southAmerica: { center: [-25, -65], zoom: 3 },
};

const mobileRegionExtents = {
  northAmerica: { center: [39, -97], zoom: 3 },
  europe: { center: [49, 10], zoom: 3 },
  asia: { center: [32, 107], zoom: 2 },
  oceania: { center: [-29, 145], zoom: 3 },
  africa: { center: [5, 21], zoom: 2 },
  southAmerica: { center: [-25, -65], zoom: 2 },
};

const mapDefaultStyles = {
  padding: 0,
  border: '1px solid #fff',
};

const ResponsiveMap = props => (
  <Map
    style={mapDefaultStyles}
    id='actual-map'
    zoom={props.zoom}
    center={props.center}
    key={props.childKey}
    zoomControl={false}
    scrollWheelZoom={false}
    maxBounds={[[-75, -180], [85, 180]]}
    minZoom={2}
    maxZoom={7}
  >
    {props.children}
  </Map>
);
ResponsiveMap.propTypes = {
  zoom: PropTypes.number,
  center: PropTypes.array,
  childKey: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.node),
};

@onResize()
@componentBase('AirportDelayMap')
export default class AirportDelayMap extends React.Component {
  static propTypes = {
    center: PropTypes.array,
    zoom: PropTypes.number,
    region: PropTypes.string,
    delayIndices: PropTypes.array,
    updateAirport: PropTypes.func,
  };

  componentDidMount() {
    this.initResizeListener();
  }

  componentWillUnmount() {
    this.removeResizeListener();
  }

  handleResize = () => {
    this.forceUpdate();
  }

  renderMaps = (props, breakPoint) => {
    let extents = regionExtents;
    if (breakPoint.name === 'mobile') {
      extents = mobileRegionExtents;
    }
    if (breakPoint.name === 'desktopSm') {
      extents = smallRegionExtents;
    }
    return (
      <div>
        <div className='responsive-map-container'>
          <div className='responsive-map-holder'>
            <ResponsiveMap
              center={props.center || extents[props.region].center}
              zoom={props.zoom || extents[props.region].zoom}
              childKey={`${breakPoint.name}-airport-map`}
            >
              <SatTileLayer />
              <MapLabelsLayer />
              {breakPoint.isMobile ? null : <MapZoomControl />}
              <MapRadarLayer />
              <AirportDelays
                handleAirportChange={props.updateAirport}
                delayIndices={props.delayIndices}
              />
            </ResponsiveMap>
            <MarkerLegend />
          </div>
        </div>
      </div>
    );
  }

  render() {
    // this.logger('render', this.props, this.mediaBreakpoint);
    if (this.mediaBreakpoint) {
      return this.renderMaps(this.props, this.mediaBreakpoint);
    }
    return (
      <div className='responsive-map-container'>
        <div className='responsive-map-holder'>
          <ResponsiveMap
            center={this.props.center || regionExtents[this.props.region].center}
            zoom={this.props.zoom || regionExtents[this.props.region].zoom}
            childKey='desktop-airport-map'
          >
            <SatTileLayer />
            <MapLabelsLayer />
            <MapZoomControl />
            <MapRadarLayer />
            <AirportDelays
              handleAirportChange={this.props.updateAirport}
              delayIndices={this.props.delayIndices}
            />
          </ResponsiveMap>
          <MarkerLegend />
        </div>
      </div>
    );
  }
}
