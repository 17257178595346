import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ifElse, isNil, path, pathOr, prop } from 'ramda';
// add ,Events as GPTEvents and uncomment logging below for gpt render events
import { Bling as GPT } from 'react-gpt';
import moment from 'moment';
import debounce from 'debounce';
import {
  set as gtagSet,
  event as gtagEvent,
  pagePerSessionEvent,
  purchaseEvent,
} from '../../../shared/lib/google-global-site-tag/index';

import { getPathname, shouldReportNavigation } from './shared/utils';
import handleFirstLoad from '../../../shared/lib/handle-first-component-load';
import Theme from '../../../src/styles/ThemeManager';
import HeaderComponent from './children/Header/HeaderComponent';
import Footer from './children/Footer/FooterComponent';
import * as StyleConstants from '../../../src/constants/style-constants';
import ToastContainer from '../../../src/containers/Toast/ToastContainer';
import ToastActions from '../../../src/redux/modules/toast/actions';
import defaultContextTypes from '../../../src/components/default-context-types';
import PageBackground from './children/PageBackground';
import componentBase from '../../../src/lib/component-base';
import * as appActions from '../redux/actions';
import * as authActions from '../../Account/redux/actions';
import { subscriptionSuspended, userSubscriptionSuspended } from '../../Account/redux/selectors';
import * as sessionActions from '../../../src/redux/modules/session/actions';
import * as images from '../../../src/constants/images';
import { featureForRoute } from '../../../shared/lib/feature-for-path/index';
import { subscriptionSimpleValueForLevel, subscriptionGAValueForLevel } from '../../../shared/lib/subscription-level-matching/index';
import AccountAttentionDialog from './children/Modals/AccountAttentionDialog';
import RootModal from '../../Modal/components/RootModal';
import { showModal } from '../../Modal/redux/actions';
import { routerPush } from '../redux/actions';

import ChildRoutes from './routes';
import Grafana from '../../../shared/lib/Grafana/frontend';
const grafanaURL = __GRAFANA_URL__;
const { NODE_ENV } = process.env;
const appName = 'dotcom';
const appEnv = NODE_ENV;

// const { SLOT_RENDER_ENDED } = GPTEvents;
// add these custom keys to the default refresh triggers on the react gpt ad module
const customFilterProps = (propKeys, props, nextProps) => {
  const customKeys = ['noAdOnLoad'];
  const sameTopLevelRoute = [props, nextProps]
    .map(p => pathOr('', 'routeName', p))
    .reduce((a, b) => a === b);
  if (sameTopLevelRoute) {
    customKeys.push('location');
  }
  return [...customKeys, ...propKeys]
    .reduce(
      (filtered, key) => ({
        props: {
          ...filtered.props,
          [key]: props[key],
        },
        nextProps: {
          ...filtered.nextProps,
          [key]: nextProps[key],
        },
      }),
      {
        props: {},
        nextProps: {},
      },
    );
};

const extractLoggedIn = prop('loggedIn');

const notNull = val => !isNil(val);

const OUTAGE_PAGE_URL = 'https://static.flightstats.com/v2/outage.html';

const getCurrentBreakPoint = (agent = {}) => {
  const { breakpoints } = StyleConstants;
  try {
    const getBreaks = ifElse(
      () => typeof window !== 'undefined',
      () => pathOr(0, ['innerWidth'], window),
      () => null,
    );

    const windowWidth = getBreaks();
    const bp = {
      value: windowWidth || 0,
      isMobile: agent.isMobile,
    };

    if (windowWidth >= breakpoints.desktopSm) {
      bp.name = 'desktopLg';
    } else if (windowWidth >= breakpoints.tablet) {
      bp.name = 'desktopSm';
    } else if (windowWidth >= breakpoints.mobile) {
      bp.name = 'tablet';
    }

    return bp;
  } catch (ex) {
    console.log('ex', ex);
  }
};

// Needed for onTouchTap
// http://stackoverflow.com/a/34015469/988941
// *** 04/2017 ***
// Still need this for material-ui ... until we upgrade that
// TODO: upgrade material ui and rip this out
// injectTapEventPlugin();

const isLoading = s =>
  s.Account.creatingBraintreeSubscription ||
  s.Account.cancellingBraintreeSubscription ||
  s.Account.loading ||
  s.Account.addingFlightAlertsCredits ||
  s.Account.deletingAccount ||
  s.Account.gettingBraintreeClientToken ||
  s.Account.loggingIn ||
  s.Account.sendingEmailVerification ||
  s.Account.signingUp ||
  s.Account.updatingBillingAddress ||
  s.Account.updatingPaymentInformation ||
  s.AirportCurrentConditions.loading ||
  s.AirportDelayMap.loading ||
  s.AirportDeparturesArrivals.loading ||
  s.FlightAlerts.checkFlightsLoading ||
  s.FlightAlerts.currentDateLoading ||
  s.FlightAlerts.deactivateLoading ||
  s.FlightAlerts.deleteFlightAlertLoading ||
  s.FlightAlerts.flightSchedulesLoading ||
  s.FlightAlerts.loading ||
  s.FlightAlerts.newAlertLoading ||
  s.FlightAlerts.reactivateLoading ||
  s.MultiFlightTracker.loading ||
  s.MultiHistoricalFlightStatus.loading ||
  (s.SingleFlightTracker.loading && !s.SingleFlightTracker.isPolling) ||
  s.SingleHistoricalFlightStatus.loading ||
  s.OnTimePerformance.loading ||
  false;

class App extends Component {
  static propTypes = {
    deletedAccount: PropTypes.bool,
    deletingAccount: PropTypes.bool,
    finishedCountryFetch: PropTypes.bool,
    finishedProfileFetch: PropTypes.bool,
    location: PropTypes.object,
    history: PropTypes.object,
    params: PropTypes.object,
    user: PropTypes.object,
    loggedIn: PropTypes.bool,
    loggingOut: PropTypes.bool,
    logout: PropTypes.func,
    mediaBreakpoint: PropTypes.object,
    isLoading: PropTypes.bool,
    setSessionItem: PropTypes.func,
    userAgent: PropTypes.object,
    dismissAccountAttentionDialog: PropTypes.func,
    accountAttentionRequired: PropTypes.bool,
    shouldRedirectToOutagePage: PropTypes.bool,
    mightNeedRefresh: PropTypes.bool,
    getProfile: PropTypes.func,
    displayAccountAttentionDialog: PropTypes.func,
    release: PropTypes.string,
    resetAuthState: PropTypes.func,
    routeName: PropTypes.string,
    routerPush: PropTypes.func,
    setBreakpoint: PropTypes.func,
    // slotsRefreshed: PropTypes.array,
    setPollingIntervalId: PropTypes.func,
    setSlotRefreshed: PropTypes.func,
    dispatch: PropTypes.func.isRequired,
  };

  static childContextTypes = defaultContextTypes;

  constructor(props) {
    super(props);

    this.breakpoint = getCurrentBreakPoint(props.userAgent);
    this.polling = null;

    this.redirectedToOutagePage = false;

    this.state = {
      firstLoad: true,
    };

    // global config for ad module
    GPT.configure({
      filterProps: customFilterProps,
    });
    // Disabeld auto refresh, uncomment to enable.
    // Auto refresh is there in case an ad comes back empty, will retry once
    // GPT.on(SLOT_RENDER_ENDED, async ({ slot }) => {
    // console.log('hey i rendered, check out the slot: ');
    //  console.log('slot::::::::', slot.getAdUnitPath());
    // const { slotsRefreshed = [], setSlotRefreshed } = this.props;
    // const adUnit = slot.getAdUnitPath();
    // const slotRefreshedOnce = slotsRefreshed.find(s => s === adUnit);
    // if (isEmpty && !slotRefreshedOnce) {
    //   setSlotRefreshed(adUnit);
    //   GPT.refresh([slot]);
    // }
    // });
  }

  getChildContext = () => ({
    history: this.props.history,
    params: this.props.params,
    location: this.props.location,
    mediaBreakpoint: getCurrentBreakPoint(this.props.userAgent),
    userAgent: this.props.userAgent,
    muiTheme: Theme,
    images,
    reportButtonPress: this.reportButtonPress,
    reportEvent: this.reportEvent,
    reportPurchase: this.reportPurchase,
    reportUserEvent: this.reportUserEvent,
    reportQueryTimeFrame: this.reportQueryTimeFrame,
    reportSendInteraction: this.reportSendInteraction,
    reportUserSubscribed: this.reportUserSubscribed,
    reportUserUnsubscribed: this.reportUserUnsubscribed,
    reportUserDeleteAccount: this.reportUserDeleteAccount,
    reportUserUpgraded: this.reportUserUpgraded,
    gotoHandler: this.gotoHandler,
    goto: this.goto,
    store: {
      dispatch: this.props.dispatch,
    },
    currentUser: this.props.user,
    setSessionItem: this.props.setSessionItem,
    toast: {
      info: (...args) => this.props.dispatch(ToastActions.info(...args)),
      warning: (...args) => this.props.dispatch(ToastActions.warning(...args)),
      success: (...args) => this.props.dispatch(ToastActions.success(...args)),
      error: (...args) => this.props.dispatch(ToastActions.error(...args)),
    },
  });

  componentDidMount = () => {
    const { deletedAccount, deletingAccount, user } = this.props;

    const shouldPoll = [user,
      path(['email'], user),
      !deletingAccount,
      !deletedAccount,
      !this.polling].every(v => !!v);

    if (shouldPoll) {
      // begin polling the backend for dynamic user info
      this.pollForUserProfile();
      this.polling = true;
    }

    const { setBreakpoint, userAgent } = this.props;
    setBreakpoint(getCurrentBreakPoint(userAgent));
    typeof window !== 'undefined' &&
      window.addEventListener('resize', debounce(this.onResize, 400));
    typeof window !== 'undefined' &&
      window.addEventListener('beforeunload', this.onBeforeUnload());
  }

  componentWillReceiveProps = (nextProps) => {
    // run addUserInfo on first load only once, then only on login or logout
    const newState = this.handleFirstLoad(this.state.firstLoad, this.loggedInMatches(nextProps));
    this.setState(newState);

    if (nextProps.shouldRedirectToOutagePage) {
      if (this.redirectedToOutagePage) {
        return;
      }

      // console.log('redirecting to outage page');
      this.redirectedToOutagePage = true;
      window.location = OUTAGE_PAGE_URL;
      return;
    }
    if (nextProps.deletedAccount) {
      this.handleDeleteAccountSuccess();
    }

    if (nextProps.loggedIn && !this.props.loggedIn) {
      const nextSubscriptionActive = pathOr(false, ['user', 'subscriptionActive'], nextProps);
      if (nextSubscriptionActive) {
        this.removeGPTListeners();
      }
      this.pollForUserProfile();
    }

    // Check if this is a protected route
    // and if subscription is suspended
    const pathName = path(['history', 'location', 'pathname'], nextProps);
    const protectedFeature = featureForRoute(pathName);
    const isProtected = protectedFeature && protectedFeature.level > 0;
    if (isProtected && userSubscriptionSuspended(this.props.user)) {
      if (!nextProps.accountAttentionRequired) {
        this.props.displayAccountAttentionDialog();
      }
    }
    if (!isProtected && nextProps.accountAttentionRequired) {
      this.props.dismissAccountAttentionDialog();
    }
    const nextPathname = getPathname(nextProps);
    const newLocation = getPathname(this.props) !== nextPathname;
    if (newLocation) {
      if (shouldReportNavigation(this.props, nextProps)) {
        pagePerSessionEvent(nextPathname);
      }
      // clear the refreshed ad slot array
      this.props.setSlotRefreshed(null, true);
    }

    if (nextProps.mightNeedRefresh && nextProps.loggedIn) {
      /*
        user has received a 440 from an API request,
        and is currently logged in

        where did the session go?
        open modal to refresh and re-login to regenerate it
      */

      this.props.dispatch(showModal('NO_SESSION'));
      // turn the shouldRefresh boolean off
      this.props.dispatch(authActions.shouldRefresh(false));
    }
  }

  componentWillUnmount = () => {
    // HALT POLLING
    this.haltPollingForUserProfile();
    this.removeGPTListeners();
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('beforeunload', this.onBeforeUnload);
    // clear the refreshed ad slot array
    this.props.setSlotRefreshed(null, true);
  }

  onResize = () => {
    this.props.setBreakpoint(getCurrentBreakPoint(this.props.userAgent));
  }

  onBeforeUnload = () => {
    window.removeEventListener('resize', this.onResize);
    GPT.clear();
    window.removeEventListener('beforeunload', this.onBeforeUnload);
  };

  removeGPTListeners = () => {
    GPT.removeAllListeners();
  }


  pollForUserProfile = () => {
    const { setPollingIntervalId } = this.props;
    const intervalId = window.setInterval(() => this.props.getProfile(), 60000);
    this.intervalId = intervalId;
    setPollingIntervalId(this.intervalId);
  }

  handleDeleteAccountSuccess = () => {
    const {
      resetAuthState,
      // routerPush,
      user,
    } = this.props;
    const {
      toast,
    } = this.getChildContext();
    const subscriptionLevel = user && user.subscriptionLevel;
    const reporterLevel = subscriptionLevel || 0;
    resetAuthState();
    toast.success('Your account has been deleted.', 'Success');
    this.reportUserDeleteAccount(reporterLevel);
    this.reportButtonPress('Delete Account');
    this.props.routerPush('/');
  }

  haltPollingForUserProfile = () => this.intervalId && window.clearInterval(this.intervalId);

  gotoHandler = (url, button) => () => this.goto(url, button);

  goto = (url, button) => {
    this.props.routerPush(url);  // Changed from this.contxt.store.dispatch(push(url))

    if (button) {
      this.reportButtonPress(button);
    }
  }

  addUserInfo = () => {
    const { user } = this.props;
    if (notNull(user)) {
      const {
        user: {
          id: userId,
          email,
          subscriptionActive,
          subscriptionLevel: userSubscriptionLevel,
        },
      } = this.props;
      const domain = email.replace(/.*@/, '');
      const subscription = subscriptionActive ? 'true' : 'false';

      let subscriptionLevel = 'Free';
      switch (userSubscriptionLevel) {
        case 1:
          subscriptionLevel = 'Standard';
          break;
        case 3:
          subscriptionLevel = 'Professional';
          break;
        case 11:
          subscriptionLevel = 'Enterprise';
          break;
        default:
          subscriptionLevel = 'Free';
          break;
      }

      const gaUser = {
        user_id: userId,
        userId: `${userId}`,
        domain,
        subscription,
        loggedIn: 'true',
        subscriptionLevel,
      };
      gtagSet({ ...gaUser });
    } else {
      gtagSet({
        loggedIn: 'false',
      });
    }
  }

  handleFirstLoad = handleFirstLoad(this.addUserInfo);

  loggedInMatches = nextProps => [this.props, nextProps]
    .map(p => extractLoggedIn(p))
    .reduce((a, b) => a === b);

  namespacedLabel = label => (this.props.routeName ? `${this.props.routeName}-${label}` : label);

  reportButtonPress = (label, value) => {
    const eventDict = {
      label: this.namespacedLabel(label),
      category: 'User',
      nonInteraction: false,
    };

    if (notNull(value)) {
      eventDict.value = value;
    }

    gtagEvent('buttonPress', eventDict);
  }

  reportUserEvent = (action, label, value) => {
    const eventDict = { category: 'User', nonInteraction: false };

    if (notNull(label)) {
      eventDict.label = this.namespacedLabel(label);
    }
    if (notNull(value)) {
      eventDict.value = value;
    }

    gtagEvent(action, eventDict);
  }

  reportEvent = (category, action, label, value, nonInteraction) => {
    const eventDict = { category, nonInteraction };

    if (notNull(label)) {
      eventDict.label = label;
    }
    if (notNull(value)) {
      eventDict.value = value;
    }

    gtagEvent(action, eventDict);
  }

  reportPurchase = ({
    id, affiliation, name, revenue,
  }) => {
    const transactionDict = {
      id: '', affiliation: '', value: '', shipping: '', tax: '',
    };
    const itemDict = {
      quantity: 1, sku: '', id: '', category: '', name: '', price: '',
    };

    if (id) {
      transactionDict.transaction_id = id;
      itemDict.id = id;
    }
    if (affiliation) {
      transactionDict.affiliation = affiliation;
      itemDict.category = affiliation;
    }
    if (name) {
      itemDict.name = name;
    }
    if (revenue) {
      const rev = revenue.toString().replace('$', '');
      transactionDict.value = rev;
      itemDict.price = revenue;
    }
    purchaseEvent(transactionDict, itemDict);
  }

  reportQueryTimeFrame = (day, month, year) => {
    // On page load, report QueryTimeFrame and Airport
    if (notNull(day)) {
      const diff = -moment().diff(moment().year(year).month(month - 1).date(day), 'days');
      let reportAction = '';

      if (`${diff}` === '0') {
        reportAction = 'Current Day';
      } else if (diff < -7) {
        reportAction = 'Historical - Past 7';
      } else if (diff < -1) {
        reportAction = 'Historical - Recent';
      } else if (`${diff}` === '-1') {
        reportAction = 'Historical - Past Day';
      } else {
        reportAction = 'Future';
      }

      const eventDict = { category: 'QueryTimeFrame', nonInteraction: true };
      gtagEvent(reportAction, eventDict);
    }
  }

  reportSendInteraction = (action, dict) => {
    gtagEvent(action, dict);
  }

  reportUserSubscribed = (level) => {
    gtagSet({ subscription: 'true', subscriptionLevel: subscriptionGAValueForLevel(level) });
    gtagEvent('Subscription', {
      category: 'User', action: 'Subscription', label: `${subscriptionSimpleValueForLevel(level)}Subscribe`, value: level, nonInteraction: true,
    });
  }

  reportUserUpgraded = (level) => {
    gtagSet({ subscription: 'true', subscriptionLevel: subscriptionGAValueForLevel(level) });
    gtagEvent('Subscription', {
      category: 'User', label: `${subscriptionSimpleValueForLevel(level)}Upgrade`, value: level, nonInteraction: true,
    });
  }

  reportUserUnsubscribed = (level) => {
    gtagSet({ subscription: 'false', subscriptionLevel: 'Free' });
    gtagEvent('Subscription', {
      category: 'User', label: `${subscriptionSimpleValueForLevel(level)}Unsubscribe`, value: level, nonInteraction: true,
    });
  }

  reportUserDeleteAccount = (level) => {
    const subValueForLevel = subscriptionSimpleValueForLevel(level);
    gtagSet({ subscription: 'false' });
    gtagEvent('Subscription', {
      category: 'User', label: `${subValueForLevel}Delete`, value: level || 0, nonInteraction: true,
    });
    if (level > 0) {
      gtagEvent('Subscription', {
        category: 'User', label: `${subValueForLevel}Unsubscribe`, value: level, nonInteraction: true,
      });
    }
  }

  render = () => {
    const { loggedIn, loggingOut, mediaBreakpoint, routeName, user } = this.props;
    const breakpoint = mediaBreakpoint || this.breakpoint;
    const subscribed = pathOr(false, ['subscriptionActive'], user);
    let mainContent = null;
    let isAccountPage = false;
    if (routeName === 'VerifyEmail') {
      mainContent = (
        <main className={`container ${routeName}`} style={{ width: '100%' }}>
          <ChildRoutes />
        </main>
      );
      isAccountPage = true;
    } else {
      mainContent = (
        <main className={`container ${subscribed ? 'subscribed' : ''}`}>
          <ChildRoutes />
        </main>
      );
    }

    const curtainStyle = {
      zIndex: 1501,
      position: 'fixed',
      overflow: 'show',
      margin: 'auto',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    };

    const spinner = (
      <div style={curtainStyle} className='spinner'>
        <div className='rect1' />
        <div className='rect2' />
        <div className='rect3' />
        <div className='rect4' />
        <div className='rect5' />
      </div>
    );

    const delayContent = routeName === 'AirportDelayMap' ? 'delay-content' : '';
    return (
      <div>
        {(appEnv === 'staging' || appEnv === 'production') && 
        <Grafana
          grafanaURL={grafanaURL}
          appName={appName}
          appVersion={this.props.release || '1.1'}
          appEnv={appEnv}
        />}
        {this.props.isLoading ? spinner : null}
        <div className={`content-holder ${delayContent}`}>
          <PageBackground
            componentName={routeName}
          />
          <HeaderComponent
            mediaBreakpoint={breakpoint}
            dispatch={this.props.dispatch}
            isDesktop={this.props.userAgent.isDesktop}
            location={this.props.history.location}
            loggedIn={loggedIn}
            logout={this.props.logout}
            parsedComponentName={routeName}
            loggingOut={loggingOut}
            reportButtonPress={this.reportButtonPress}
            user={this.props.user}
          />
          {mainContent}
          <ToastContainer />
          <RootModal />
          <AccountAttentionDialog
            location={this.props.history.location}
            open={this.props.accountAttentionRequired || false}
          />
          <Footer
            mediaBreakpoint={breakpoint}
            release={this.props.release}
            location={this.props.history.location}
            loggedIn={loggedIn}
            parsedComponentName={routeName}
            loggingOut={loggingOut}
            isAccountPage={isAccountPage}
            user={this.props.user}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  release: state.App.release,
  routeName: state.App.routeName,
  userAgent: state.App.userAgent,
  deletingAccount: state.Account.deletingAccount,
  deletedAccount: state.Account.deletedAccount,
  finishedCountryFetch: state.App.finishedCountryFetch,
  finishedProfileFetch: state.Account.finishedProfileFetch,
  mediaBreakpoint: state.App.mediaBreakpoint,
  user: state.Account.user,
  loggedIn: state.Account.loggedIn,
  loggingOut: state.Account.loggingOut,
  mightNeedRefresh: state.Account.mightNeedRefresh,
  isLoading: isLoading(state),
  accountAttentionRequired: state.Account.accountAttentionRequired,
  slotsRefreshed: state.App.slotsRefreshed,
  location: state.router.location,
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(authActions, dispatch),
  ...bindActionCreators(sessionActions, dispatch),
  ...bindActionCreators(appActions, dispatch),
  dispatch,
});

const EnhancedApp = componentBase('App')(App);

export default connect(mapStateToProps, mapDispatchToProps)(EnhancedApp);
