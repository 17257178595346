export const FlightTracker = {
  bottom: {
    id: 'div-gpt-ad-1470845574196-1',
    sizes: [300, 250],
    adSlot: '/1010197/BS_FlightTracker_Second_300x250',
  },
  mapAd: {
    id: 'div-gpt-ad-1499900382761-0',
    sizes: [300, 250],
    adSlot: '/1010197/BS_FlightTracker_Mobile_Map_300x250',
  },
  mapAdBottom: {
    id: 'div-gpt-ad-1528306345249-2',
    sizes: [300, 250],
    adSlot: '/1010197/BS_FlightTracker_Mobile_Map_Second_300x250',
  },
  top: {
    id: 'div-gpt-ad-1470845574196-0',
    sizes: [300, 250],
    adSlot: '/1010197/BS_FlightTracker_top_300x250',
  },
  tall: {
    id: 'div-gpt-ad-1522279396356-0',
    sizes: [300, 600],
    adSlot: '/1010197/BS_FlightTracker_top_300x600',
  },
};
