import { pathOr } from 'ramda';

import * as t from './actionTypes';

const errorCode = (error) => {
  // console.log('flight load error:', error);
  if (error && error.status === 429) {
    return 'TOO_MANY_REQUESTS';
  }
  return 'FLIGHTS_LOAD_ERROR1';
};

const initialState = {
  loading: false,
  loaded: false,
  pageIndex: 1,
  searchUrl: '',
  multiFlightMobileSearchLoading: false,
  multiFlightSearchHasMounted: false,
  validationError: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case t.LOAD_BY_DEPARTURE_AIRPORT_CARRIER_AND_DATE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case t.LOAD_BY_ARRIVAL_AIRPORT_CARRIER_AND_DATE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case t.LOAD_BY_ROUTE_CARRIER_AND_DATE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case t.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result,
        error: null,
      };
    case t.LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: {
          ...action.error,
          code: errorCode(action.error),
        },
      };
    case t.UPDATE_PAGE_INDEX:
      return {
        ...state,
        pageIndex: action.data.pageIndex,
        searchUrl: action.data.searchUrl,
      };
    case t.UPDATE_LOADED:
      return {
        ...state,
        loaded: false,
      };
    case t.UPDATE_MULTI_FLIGHT_SEARCH_DATE:
      return {
        ...state,
        multiFlightSearchDate: pathOr(3, ['data', 'date', 'value'], action),
      };
    case t.UPDATE_MULTI_FLIGHT_SEARCH_TIME:
      return {
        ...state,
        multiFlightSearchTime: pathOr(6, ['data', 'time', 'value'], action),
      };
    case t.UPDATE_MULTI_FLIGHT_SEARCH_CODESHARE_TOGGLE:
      return {
        ...state,
        multiFlightSearchCodeShareToggle: action.toggle,
      };
    case t.MULTI_FLIGHT_MOBILE_SEARCH_SEARCHING:
      return {
        ...state,
        multiFlightMobileSearchLoading: action.bool,
      };
    case t.MULTI_FLIGHT_SEARCH_HAS_MOUNTED:
      return {
        ...state,
        multiFlightSearchHasMounted: action.bool,
      };
    case t.MULTI_FLIGHT_SEARCH_RESULT_ERROR:
      return {
        ...state,
        multiFlightResultsSearchError: action.error,
      };
    case t.SET_MULTI_FLIGHT_SEARCH_AIRLINE_VALUE:
      return {
        ...state,
        multiFlightResultsAirlineSelectValue: action.value,
      };
    case t.SET_MULTI_FLIGHT_FLIGHTS:
      return {
        ...state,
        data: action.flights,
      };
    case t.SET_VALIDATION_ERROR:
      return {
        ...state,
        validationError: action.bool,
      };
    default:
      return state;
  }
};
