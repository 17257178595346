import React from 'react';
import PropTypes from 'prop-types';
import FSFlatButton from '../../../../../../../src/components/lib/FSFlatButton';

const ACTIVE = 'SELECTED';
const LATENT = 'SELECT';

const PlanTitleButton = (props) => {
  const {
    disabled,
    handlePlanHover,
    handlePlanSelection,
    planTitle,
    selected,
  } = props;

  const buttonColors = {
    free: '123, 161, 203',
    standard: '255, 168, 0',
    premium: '255, 255, 255',
  };

  const buttonBorders = {
    free: '168, 194, 221',
    standard: '230, 218, 11',
    premium: '211, 211, 211',
  };

  const baseButtonStyle = {
    width: '100%',
    minWidth: '75px',
    borderRadius: 0,
  };

  const baseLabelStyle = {
    display: 'block',
    border: selected ? 0 : `1px solid rgb(${buttonBorders[planTitle]})`,
    lineHeight: '30px',
    paddingLeft: 0,
    paddingRight: 0,
    fontWeight: 600,
    height: '94%',
    width: '97%',
    margin: '1px auto',
  };

  const planTitleLabelStyle = {
    free: {
      ...baseLabelStyle,
    },
    standard: {
      ...baseLabelStyle,
    },
    premium: {
      ...baseLabelStyle,
      color: 'black',
    },
  };

  const planTitleButtonStyle = () => ({
    ...baseButtonStyle,
    backgroundColor: `rgb(${buttonColors[planTitle]})`,
  });

  const handleClick = () => {
    handlePlanSelection(planTitle);
  };

  const onHover = (isDisabled, position, hoverPlanTitle) => {
    const val = position === 'enter' ? hoverPlanTitle : null;
    if (!isDisabled) {
      handlePlanHover(val);
    }
  };

  return (
    <FSFlatButton
      disabled={disabled}
      primary
      labelStyle={{ ...planTitleLabelStyle[planTitle] }}
      hoverColor={`rgb(${buttonColors[planTitle]})`}
      style={{ ...FSFlatButton.style, ...planTitleButtonStyle(planTitle) }}
      className={`${planTitle}-plan-title-button selected-plan-button`}
      label={selected ? ACTIVE : LATENT}
      onClick={handleClick}
      onMouseEnter={() => onHover(disabled, 'enter', planTitle)}
      onMouseLeave={() => onHover(disabled, 'leave', planTitle)}
    />
  );
};

PlanTitleButton.propTypes = {
  disabled: PropTypes.bool,
  handlePlanHover: PropTypes.func,
  handlePlanSelection: PropTypes.func,
  planTitle: PropTypes.string,
  selected: PropTypes.bool,
};

export default PlanTitleButton;
