export const countryOptions = [
  { value: 'AF', label: 'Afghanistan' },
  { value: 'AX', label: 'Åland' },
  { value: 'AL', label: 'Albania' },
  { value: 'DZ', label: 'Algeria' },
  { value: 'AS', label: 'American Samoa' },
  { value: 'AD', label: 'Andorra' },
  { value: 'AO', label: 'Angola' },
  { value: 'AI', label: 'Anguilla' },
  { value: 'AQ', label: 'Antarctica' },
  { value: 'AG', label: 'Antigua and Barbuda' },
  { value: 'AR', label: 'Argentina' },
  { value: 'AM', label: 'Armenia' },
  { value: 'AW', label: 'Aruba' },
  { value: 'AU', label: 'Australia' },
  { value: 'AT', label: 'Austria' },
  { value: 'AZ', label: 'Azerbaijan' },
  { value: 'BS', label: 'Bahamas' },
  { value: 'BH', label: 'Bahrain' },
  { value: 'BD', label: 'Bangladesh' },
  { value: 'BB', label: 'Barbados' },
  { value: 'BY', label: 'Belarus' },
  { value: 'BE', label: 'Belgium' },
  { value: 'BZ', label: 'Belize' },
  { value: 'BJ', label: 'Benin' },
  { value: 'BM', label: 'Bermuda' },
  { value: 'BT', label: 'Bhutan' },
  { value: 'BO', label: 'Bolivia' },
  { value: 'BQ', label: 'Bonaire, Sint Eustatius and Saba' },
  { value: 'BA', label: 'Bosnia and Herzegovina' },
  { value: 'BW', label: 'Botswana' },
  { value: 'BV', label: 'Bouvet Island' },
  { value: 'BR', label: 'Brazil' },
  { value: 'IO', label: 'British Indian Ocean Territory' },
  { value: 'BN', label: 'Brunei Darussalam' },
  { value: 'BG', label: 'Bulgaria' },
  { value: 'BF', label: 'Burkina Faso' },
  { value: 'BI', label: 'Burundi' },
  { value: 'KH', label: 'Cambodia' },
  { value: 'CM', label: 'Cameroon' },
  { value: 'CA', label: 'Canada' },
  { value: 'CV', label: 'Cape Verde' },
  { value: 'KY', label: 'Cayman Islands' },
  { value: 'CF', label: 'Central African Republic' },
  { value: 'TD', label: 'Chad' },
  { value: 'CL', label: 'Chile' },
  { value: 'CN', label: 'China' },
  { value: 'CX', label: 'Christmas Island' },
  { value: 'CC', label: 'Cocos (Keeling) Islands' },
  { value: 'CO', label: 'Colombia' },
  { value: 'KM', label: 'Comoros' },
  { value: 'CG', label: 'Congo (Brazzaville)' },
  { value: 'CD', label: 'Congo (Kinshasa)' },
  { value: 'CK', label: 'Cook Islands' },
  { value: 'CR', label: 'Costa Rica' },
  { value: 'CI', label: 'Côte d\'Ivoire' },
  { value: 'HR', label: 'Croatia' },
  { value: 'CU', label: 'Cuba' },
  { value: 'CW', label: 'Curaçao' },
  { value: 'CY', label: 'Cyprus' },
  { value: 'CZ', label: 'Czech Republic' },
  { value: 'DK', label: 'Denmark' },
  { value: 'DJ', label: 'Djibouti' },
  { value: 'DM', label: 'Dominica' },
  { value: 'DO', label: 'Dominican Republic' },
  { value: 'EC', label: 'Ecuador' },
  { value: 'EG', label: 'Egypt' },
  { value: 'SV', label: 'El Salvador' },
  { value: 'GQ', label: 'Equatorial Guinea' },
  { value: 'ER', label: 'Eritrea' },
  { value: 'EE', label: 'Estonia' },
  { value: 'ET', label: 'Ethiopia' },
  { value: 'FK', label: 'Falkland Islands' },
  { value: 'FO', label: 'Faroe Islands' },
  { value: 'FJ', label: 'Fiji' },
  { value: 'FI', label: 'Finland' },
  { value: 'FR', label: 'France' },
  { value: 'GF', label: 'French Guiana' },
  { value: 'PF', label: 'French Polynesia' },
  { value: 'TF', label: 'French Southern Lands' },
  { value: 'GA', label: 'Gabon' },
  { value: 'GM', label: 'Gambia' },
  { value: 'GE', label: 'Georgia' },
  { value: 'DE', label: 'Germany' },
  { value: 'GH', label: 'Ghana' },
  { value: 'GI', label: 'Gibraltar' },
  { value: 'GR', label: 'Greece' },
  { value: 'GL', label: 'Greenland' },
  { value: 'GD', label: 'Grenada' },
  { value: 'GP', label: 'Guadeloupe' },
  { value: 'GU', label: 'Guam' },
  { value: 'GT', label: 'Guatemala' },
  { value: 'GG', label: 'Guernsey' },
  { value: 'GN', label: 'Guinea' },
  { value: 'GW', label: 'Guinea-Bissau' },
  { value: 'GY', label: 'Guyana' },
  { value: 'HT', label: 'Haiti' },
  { value: 'HM', label: 'Heard and McDonald Islands' },
  { value: 'HN', label: 'Honduras' },
  { value: 'HK', label: 'Hong Kong' },
  { value: 'HU', label: 'Hungary' },
  { value: 'IS', label: 'Iceland' },
  { value: 'IN', label: 'India' },
  { value: 'ID', label: 'Indonesia' },
  { value: 'IR', label: 'Iran' },
  { value: 'IQ', label: 'Iraq' },
  { value: 'IE', label: 'Ireland' },
  { value: 'IM', label: 'Isle of Man' },
  { value: 'IL', label: 'Israel' },
  { value: 'IT', label: 'Italy' },
  { value: 'JM', label: 'Jamaica' },
  { value: 'JP', label: 'Japan' },
  { value: 'JE', label: 'Jersey' },
  { value: 'JO', label: 'Jordan' },
  { value: 'KZ', label: 'Kazakhstan' },
  { value: 'KE', label: 'Kenya' },
  { value: 'KI', label: 'Kiribati' },
  { value: 'KP', label: 'Korea, North' },
  { value: 'KR', label: 'Korea, South' },
  { value: 'KW', label: 'Kuwait' },
  { value: 'KG', label: 'Kyrgyzstan' },
  { value: 'LA', label: 'Laos' },
  { value: 'LV', label: 'Latvia' },
  { value: 'LB', label: 'Lebanon' },
  { value: 'LS', label: 'Lesotho' },
  { value: 'LR', label: 'Liberia' },
  { value: 'LY', label: 'Libya' },
  { value: 'LI', label: 'Liechtenstein' },
  { value: 'LT', label: 'Lithuania' },
  { value: 'LU', label: 'Luxembourg' },
  { value: 'MO', label: 'Macau' },
  { value: 'MK', label: 'Macedonia' },
  { value: 'MG', label: 'Madagascar' },
  { value: 'MW', label: 'Malawi' },
  { value: 'MY', label: 'Malaysia' },
  { value: 'MV', label: 'Maldives' },
  { value: 'ML', label: 'Mali' },
  { value: 'MT', label: 'Malta' },
  { value: 'MH', label: 'Marshall Islands' },
  { value: 'MQ', label: 'Martinique' },
  { value: 'MR', label: 'Mauritania' },
  { value: 'MU', label: 'Mauritius' },
  { value: 'YT', label: 'Mayotte' },
  { value: 'MX', label: 'Mexico' },
  { value: 'FM', label: 'Micronesia' },
  { value: 'MD', label: 'Moldova' },
  { value: 'MC', label: 'Monaco' },
  { value: 'MN', label: 'Mongolia' },
  { value: 'ME', label: 'Montenegro' },
  { value: 'MS', label: 'Montserrat' },
  { value: 'MA', label: 'Morocco' },
  { value: 'MZ', label: 'Mozambique' },
  { value: 'MM', label: 'Myanmar' },
  { value: 'NA', label: 'Namibia' },
  { value: 'NR', label: 'Nauru' },
  { value: 'NP', label: 'Nepal' },
  { value: 'NL', label: 'Netherlands' },
  { value: 'AN', label: 'Netherlands Antilles' },
  { value: 'NC', label: 'New Caledonia' },
  { value: 'NZ', label: 'New Zealand' },
  { value: 'NI', label: 'Nicaragua' },
  { value: 'NE', label: 'Niger' },
  { value: 'NG', label: 'Nigeria' },
  { value: 'NU', label: 'Niue' },
  { value: 'NF', label: 'Norfolk Island' },
  { value: 'MP', label: 'Northern Mariana Islands' },
  { value: 'NO', label: 'Norway' },
  { value: 'OM', label: 'Oman' },
  { value: 'PK', label: 'Pakistan' },
  { value: 'PW', label: 'Palau' },
  { value: 'PS', label: 'Palestine' },
  { value: 'PA', label: 'Panama' },
  { value: 'PG', label: 'Papua New Guinea' },
  { value: 'PY', label: 'Paraguay' },
  { value: 'PE', label: 'Peru' },
  { value: 'PH', label: 'Philippines' },
  { value: 'PN', label: 'Pitcairn' },
  { value: 'PL', label: 'Poland' },
  { value: 'PT', label: 'Portugal' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'QA', label: 'Qatar' },
  { value: 'RE', label: 'Reunion' },
  { value: 'RO', label: 'Romania' },
  { value: 'RU', label: 'Russian Federation' },
  { value: 'RW', label: 'Rwanda' },
  { value: 'BL', label: 'Saint Barthélemy' },
  { value: 'SH', label: 'Saint Helena' },
  { value: 'KN', label: 'Saint Kitts and Nevis' },
  { value: 'LC', label: 'Saint Lucia' },
  { value: 'MF', label: 'Saint Martin (French part)' },
  { value: 'PM', label: 'Saint Pierre and Miquelon' },
  { value: 'VC', label: 'Saint Vincent and the Grenadines' },
  { value: 'WS', label: 'Samoa' },
  { value: 'SM', label: 'San Marino' },
  { value: 'ST', label: 'Sao Tome and Principe' },
  { value: 'SA', label: 'Saudi Arabia' },
  { value: 'SN', label: 'Senegal' },
  { value: 'RS', label: 'Serbia' },
  { value: 'SC', label: 'Seychelles' },
  { value: 'SL', label: 'Sierra Leone' },
  { value: 'SG', label: 'Singapore' },
  { value: 'SX', label: 'Sint Maarten (Dutch part)' },
  { value: 'SK', label: 'Slovakia' },
  { value: 'SI', label: 'Slovenia' },
  { value: 'SB', label: 'Solomon Islands' },
  { value: 'SO', label: 'Somalia' },
  { value: 'ZA', label: 'South Africa' },
  { value: 'GS', label: 'South Georgia and South Sandwich Islands' },
  { value: 'SS', label: 'South Sudan' },
  { value: 'ES', label: 'Spain' },
  { value: 'LK', label: 'Sri Lanka' },
  { value: 'SD', label: 'Sudan' },
  { value: 'SR', label: 'Suriname' },
  { value: 'SJ', label: 'Svalbard and Jan Mayen Islands' },
  { value: 'SZ', label: 'Swaziland' },
  { value: 'SE', label: 'Sweden' },
  { value: 'CH', label: 'Switzerland' },
  { value: 'SY', label: 'Syria' },
  { value: 'TW', label: 'Taiwan' },
  { value: 'TJ', label: 'Tajikistan' },
  { value: 'TZ', label: 'Tanzania' },
  { value: 'TH', label: 'Thailand' },
  { value: 'TL', label: 'Timor-Leste' },
  { value: 'TG', label: 'Togo' },
  { value: 'TK', label: 'Tokelau' },
  { value: 'TO', label: 'Tonga' },
  { value: 'TT', label: 'Trinidad and Tobago' },
  { value: 'TN', label: 'Tunisia' },
  { value: 'TR', label: 'Turkey' },
  { value: 'TM', label: 'Turkmenistan' },
  { value: 'TC', label: 'Turks and Caicos Islands' },
  { value: 'TV', label: 'Tuvalu' },
  { value: 'UG', label: 'Uganda' },
  { value: 'UA', label: 'Ukraine' },
  { value: 'AE', label: 'United Arab Emirates' },
  { value: 'GB', label: 'United Kingdom' },
  { value: 'UM', label: 'United States Minor Outlying Islands' },
  { value: 'US', label: 'United States of America' },
  { value: 'UY', label: 'Uruguay' },
  { value: 'UZ', label: 'Uzbekistan' },
  { value: 'VU', label: 'Vanuatu' },
  { value: 'VA', label: 'Vatican City' },
  { value: 'VE', label: 'Venezuela' },
  { value: 'VN', label: 'Vietnam' },
  { value: 'VG', label: 'Virgin Islands, British' },
  { value: 'VI', label: 'Virgin Islands, U.S.' },
  { value: 'WF', label: 'Wallis and Futuna Islands' },
  { value: 'EH', label: 'Western Sahara' },
  { value: 'YE', label: 'Yemen' },
  { value: 'ZM', label: 'Zambia' },
  { value: 'ZW', label: 'Zimbabwe' },
];

export const providerOptions = [
  { value: '3RIVER', label: '3 River Wireless' },
  { value: '711SPEAKOUT', label: '7-11 Speakout' },
  { value: 'ACS', label: 'American Messaging' },
  { value: 'ALASKACOMM', label: 'Alaska Communications' },
  { value: 'ALIANT', label: 'Aliant' },
  { value: 'ALLPAGE', label: 'ALL Page Wireless' },
  { value: 'ALLTEL', label: 'Alltel Wireless' },
  { value: 'AMERITECH', label: 'Ameritech' },
  { value: 'ATT', label: 'AT&T Wireless' },
  { value: 'ATTMMS', label: 'AT&T MMS' },
  { value: 'BELL', label: 'Bell Mobility (Canada)' },
  { value: 'BIGREDGIANT', label: 'BigRedGiant Mobile Solutions' },
  { value: 'BLUESKY', label: 'Bluesky Communications' },
  { value: 'BOOST', label: 'Boost Mobile' },
  { value: 'BOUYGUES', label: 'Bouygues Télécom' },
  { value: 'BOXINTERNET', label: 'Box Internet Services SMS Gateway' },
  { value: 'CELLCOM', label: 'Cellcom' },
  { value: 'CELLONE', label: 'Cellular One' },
  { value: 'CELLSOUTH', label: 'Cellular South' },
  { value: 'CHINAMOBILE', label: 'China Mobile' },
  { value: 'CINCINNATIBELL', label: 'Cincinnati Bell' },
  { value: 'CLARO', label: 'Claro (Nicaragua)' },
  { value: 'CLAROAR', label: 'Claro (Argentina)' },
  { value: 'CLAROBR', label: 'CLARO (Brazil)' },
  { value: 'CLARONI', label: 'CLARO (Nicaragua)' },
  { value: 'CLEARTALK', label: 'Cleartalk Wireless' },
  { value: 'COMCEL', label: 'Comcel (Colombia)' },
  { value: 'COMCELCO', label: 'COMCEL (Colombia)' },
  { value: 'COMCELGT', label: 'COMCEL (Guatemala)' },
  { value: 'COPPERVALLEY', label: 'Copper Valley Telecom' },
  { value: 'CREDO', label: 'CREDO Mobile' },
  { value: 'CRICKET', label: 'Cricket' },
  { value: 'CSL', label: 'CSL' },
  { value: 'CSPIRE', label: 'C Spire Wireless' },
  { value: 'DIGICELAW', label: 'Digicel (Aruba)' },
  { value: 'DIGICELBB', label: 'Digicel (Barbados)' },
  { value: 'DIGICELBO', label: 'Digicel (Bonaire)' },
  { value: 'DIGICELCU', label: 'Digicel (Curacao)' },
  { value: 'DIGICELGD', label: 'Digicel (Grenada)' },
  { value: 'DIGICELJM', label: 'Digicel (Jamaica)' },
  { value: 'DIGICELKY', label: 'Digicel (Cayman)' },
  { value: 'DIGICELLC', label: 'Digicel (St. Lucia)' },
  { value: 'DIGICELVC', label: 'Digicel (St. Vincent)' },
  { value: 'EDGEWIRELESS', label: 'Edge Wireless' },
  { value: 'EMTEL', label: 'Emtel (Mauritius)' },
  { value: 'EPLUS', label: 'E-Plus' },
  { value: 'ESENDEX', label: 'Esendex' },
  { value: 'FIDO', label: 'Fido (Canada)' },
  { value: 'GCI', label: 'GCI' },
  { value: 'GLOBALSTAR', label: 'Globalstar' },
  { value: 'HAWAIIAN', label: 'Hawaiian Telcom Wireless' },
  { value: 'HELIO', label: 'Helio' },
  { value: 'ILLINOISVC', label: 'Illinois Valley Cellular' },
  { value: 'IRIDIUM', label: 'Iridium' },
  { value: 'ITCOMPANY', label: 'IT Company (Australia)' },
  { value: 'JAMES', label: 'James Valley Wireless' },
  { value: 'KOODOO', label: 'Koodo Mobile' },
  { value: 'LIME', label: 'LIME (Cable & Wireless)' },
  { value: 'MATANUSKA', label: 'Matanuska' },
  { value: 'MEO', label: 'Meo' },
  { value: 'METEOR', label: 'Meteor (Ireland)' },
  { value: 'METROPCS', label: 'MetroPCS' },
  { value: 'MOBILIX', label: 'Mobilix' },
  { value: 'MOBISTAR', label: 'Mobistar' },
  { value: 'MOVICOM', label: 'Movicom (Argentina)' },
  { value: 'MOVISTAR', label: 'Movistar (Colombia)' },
  { value: 'MOVISTARES', label: 'Movistar (Spain)' },
  { value: 'MTS', label: 'MTS (Canada)' },
  { value: 'NETCOM', label: 'Netcom (Norway)' },
  { value: 'NTELOS', label: 'nTelos' },
  { value: 'O2', label: 'O2 (UK)' },
  { value: 'O2DE', label: 'O2 (DE)' },
  { value: 'OGVODAFONE', label: 'OgVodafone' },
  { value: 'ORANGE', label: 'Orange' },
  { value: 'PANACEA', label: 'Panacea Mobile' },
  { value: 'PCTELECOM', label: 'PC Telecom' },
  { value: 'PELEPHONE', label: 'pelephone (Israel)' },
  { value: 'POCKET', label: 'Pocket Wireless' },
  { value: 'PRESIDENTS', label: 'President\'s Choice (Canada)' },
  { value: 'PTLUXEMBOURG', label: 'P&T Luxembourg' },
  { value: 'QWEST', label: 'Qwest' },
  { value: 'REDCOAL', label: 'Redcoal (Australia)' },
  { value: 'ROGERS', label: 'Rogers' },
  { value: 'SASKTEL', label: 'Sasktel (Canada)' },
  { value: 'SASKTEL2', label: 'SaskTel' },
  { value: 'SIMINN', label: 'Siminn' },
  { value: 'SIMPLEMOBILE', label: 'Simple Mobile' },
  { value: 'SKYTEL', label: 'Skytel' },
  { value: 'SLINTERACTIVE', label: 'SL Interactive' },
  { value: 'SMSBROADCAST', label: 'SMS Broadcast' },
  { value: 'SMSCENTRAL', label: 'SMS Central' },
  { value: 'SOLO', label: 'Solo Mobile (Canada)' },
  { value: 'SONOFON', label: 'SONOFON' },
  { value: 'SPIKKO', label: 'Spikko (Israel)' },
  { value: 'SPRINT', label: 'Sprint PCS' },
  { value: 'SUNCOM', label: 'Suncom' },
  { value: 'SUNRISES', label: 'Sunrise Switzerland' },
  { value: 'SWISSCOM', label: 'swisscom' },
  { value: 'TDC', label: 'TDC (Tele Danmark Communication)' },
  { value: 'TELCEL', label: 'Telcel (Mexico)' },
  { value: 'TELENOR', label: 'Telenor (Norway)' },
  { value: 'TELIA', label: 'Telia' },
  { value: 'TELIUSTALK', label: 'TellusTalk' },
  { value: 'TELUS', label: 'Telus Mobility (Canada)' },
  { value: 'THUMB', label: 'Thumb Cellular' },
  { value: 'TIGOLUXEMBOURG', label: 'tigo (Luxembourg)' },
  { value: 'TIM', label: 'TIM' },
  { value: 'TMOBILE', label: 'T-Mobile' },
  { value: 'TMOBILEAT', label: 'T-Mobile (Austria)' },
  { value: 'TRACFONE', label: 'TracFone (prepaid)' },
  { value: 'TRACFONEMMS', label: 'Tracfone MMS' },
  { value: 'USCELLULAR', label: 'US Cellular' },
  { value: 'VERIZON', label: 'Verizon' },
  { value: 'VERIZONMMS', label: 'Verizon MMS' },
  { value: 'VIAERO', label: 'Viaero' },
  { value: 'VIRGIN', label: 'Virgin Mobile' },
  { value: 'VIRGINCA', label: 'Virgin Mobile (Canada)' },
  { value: 'VIVO', label: 'Vivo (Brazil)' },
  { value: 'VODACOM', label: 'Vodacom' },
  { value: 'VODAFONEDE', label: 'Vodafone (Germany)' },
  { value: 'VODAFONEHJP1', label: 'Vodafone Hokuriku/Central North' },
  { value: 'VODAFONEIT', label: 'Vodafone (Italy)' },
  { value: 'VODAFONEJP', label: 'Vodafone (Japan)' },
  { value: 'VODAFONEJP0', label: 'Vodafone Hokkaido' },
  { value: 'VODAFONEJP2', label: 'Vodafone Kansai/West, including Osaka' },
  { value: 'VODAFONEJP3', label: 'Vodafone Kanto/Koushin/East, including Tokyo' },
  { value: 'VODAFONEJP4', label: 'Vodafone Kyuushu/Okinawa' },
  { value: 'VODAFONEJP5', label: 'Vodafone Shikoku' },
  { value: 'VODAFONEJP6', label: 'Vodafone Touhoku/Niigata/North' },
  { value: 'VODAFONEJP7', label: 'Vodafone Chuugoku/Western' },
  { value: 'VODAFONENZ', label: 'Vodafone (NZ)' },
  { value: 'VODAPHONEES', label: 'Vodaphone (Spain)' },
  { value: 'WESTCENTRAL', label: 'West Central Wireless' },
  { value: 'WILLCOM', label: 'Willcom' },
  { value: 'WINDMOBILE', label: 'Wind Mobile' },
  { value: 'YCC', label: 'YCC (Russia)' },
];
