import React from 'react';
import PropTypes from 'prop-types';
import { ChartIcon } from '../ChartCell';

const MobileRow = props => (
  <div className={`feature-row-container row ${props.optionalClassname}`}>
    <div className='col-xs-12'>
      <ChartIcon icon={props.icon} title={props.title} />
    </div>
    <div className={`${props.subtext ? 'col-xs-12' : 'hidden-xs'}`}>
      <p className='mobile-feature-subtext'>{props.subtext}</p>
    </div>
  </div>
);

MobileRow.propTypes = {
  icon: PropTypes.string,
  optionalClassname: PropTypes.string,
  title: PropTypes.string,
  subtext: PropTypes.string,
};

export default MobileRow;
