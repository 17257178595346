import { applyMiddleware } from 'redux';
import * as Bluebird from 'bluebird';

const isPromise = promise => promise && typeof promise.then === 'function';

export const serverHelper = createStore => (reducer, initialState) => {
  const promises = [];

  const observePromise = (state, promise) => {
    if (!isPromise(promise)) {
      return promise;
    }

    // wrap promises in bluebird.resolve so we can access #isResolved etc.
    promises.push(Bluebird.resolve(promise));

    return promise;
  };

  const middleware = ({ getState }) => next => (action) => {
    const state = getState();

    return observePromise(state, next(observePromise(state, action)));
  };

  const store = applyMiddleware(middleware)(createStore)(reducer, initialState);

  store.promises = promises;

  return store;
};

const render = (store, renderFn, promises) => {
  // render at each loop so we detect promises
  const rendered = renderFn(store);
  // find unresolved promises
  const unresolved = promises.filter(promise => !promise.isResolved());

  if (unresolved.length === 0) {
    return Bluebird.resolve(rendered);
  }

  return Bluebird.all(unresolved).then(() =>
    // recursive in case new promises were introduced.
    render(store, renderFn, promises),
  ).catch(e => console.log(e));
};


export const renderAsync = (store, renderFn) => {
  const { promises } = store;

  if (!promises) {
    throw new Error('You need to create the store with serverHelper before calling renderAsync');
  }

  return render(store, renderFn, promises);
};
