import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import { actions } from '../redux';
import FSFlatButton from '../../../src/components/lib/FSFlatButton';
import componentBase from '../../../src/lib/component-base';

@connect(
  state => ({
    user: state.Account.user,
    sendingEmailVerification: state.Account.sendingEmailVerification,
    sentEmailVerification: state.Account.sentEmailVerification,
    sendEmailVerificationError: state.Account.sendEmailVerificationError,
    gettingProfile: state.Account.gettingProfile,
  }),
  actions,
)
@componentBase('VerifyEmail')
export default class VerifyEmail extends Component {
  static propTypes = {
    location: PropTypes.object,
    resetAuthState: PropTypes.func,
    getProfile: PropTypes.func,
    gettingProfile: PropTypes.bool,
    startSpinner: PropTypes.func,
    endSpinner: PropTypes.func,
    sendEmailVerification: PropTypes.func,
    sendingEmailVerification: PropTypes.bool,
    sentEmailVerification: PropTypes.bool,
    sendEmailVerificationError: PropTypes.array,
    user: PropTypes.object.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      error: null,
    };

    this.errorDisplayWording = {
      MISSING_EMAIL: 'Error: An email address is required.',
      UNKNOWN_ERROR: 'Error',
    };
  }

  componentDidMount() {
    this.props.resetAuthState();
    this.props.getProfile();
  }

  componentWillReceiveProps(nextProps) {
    const {
      user,
      location,
      gettingProfile,
      startSpinner,
      endSpinner,
    } = nextProps;

    gettingProfile ? startSpinner() : endSpinner();

    if (user && location) {
      if (user.verified && location.query && location.query.redirectedFrom) {
        this.handleContinue();
      }
    }
  }

  getMetaTags = () => {
    const title = 'FlightStats - Email Verification';
    return {
      title,
    };
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.props.user && this.props.user.email) {
      this.props.sendEmailVerification(this.props.user.email);
      this.setState({
        error: null,
      });
    }
  };

  handleContinue = (event) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    this.props.resetAuthState();
  };

  listItem = (linkUrl, text) => (
    <li key={text}>
      &raquo; <Link to={linkUrl} className='account-link'>
        <p>{text}</p>
      </Link>
    </li>
  );

  accessItems = [
    this.listItem('/flight-tracker/search', 'Flight Tracker'),
    this.listItem('/my-account/profile', 'My Account Preferences'),
  ];

  standardSubscriptionItems = [
    this.listItem('/airports/departing-arriving/search', 'Departures and Arrivals'),
    this.listItem('/flight-alerts/home', 'Flight Alerts'),
  ];

  proSubscriptionItems = [
    ...this.standardSubscriptionItems,
    this.listItem('/historical-flight/search', 'Historical Flight Status'),
  ];

  render() {
    const meta = this.getMetaTags();
    const {
      user,
      sendEmailVerificationError,
      sentEmailVerification,
      sendingEmailVerification,
    } = this.props;
    const {
      email,
      subscriptionActive,
      subscriptionLevel,
      verified,
    } = user;

    let error = null;
    let panelStyle = null;
    const sectionStyle = { margin: '10px 0' };
    const userVerified = verified;
    const hasSubscription = subscriptionActive;
    let heading = 'Verify Your Email';

    if (userVerified) {
      heading = 'Email Verified';
    } else if (sentEmailVerification) {
      heading = 'Verification Email Sent';
    }

    if (this.state.error) {
      error = (
        <p className='account-error-text'>{this.state.error}</p>
      );
      panelStyle = 'error';
    } else if (sendEmailVerificationError) {
      error = (
        <p className='account-error-text'>{this.errorDisplayWording[sendEmailVerificationError[0]]}</p>
      );
      panelStyle = 'error';
    }

    const subscribeTodayItems = (
      <span>
        <p style={{ textDecoration: 'none', fontWeight: '600' }}>Subscribe today to gain access to:</p>
        {this.proSubscriptionItems}
      </span>
    );

    const subscriptionItems = (
      <span>
        <p style={{ textDecoration: 'none', fontWeight: '600' }}>Your subscription features:</p>
        {hasSubscription && subscriptionLevel === 1 && this.standardSubscriptionItems}
        {hasSubscription && subscriptionLevel >= 3 && this.proSubscriptionItems}
      </span>
    );

    const upgradeText = (
      <span>
        <p style={{ textDecoration: 'none', fontWeight: '600' }}>Upgrade your subscription to gain access to:</p>
        {this.listItem('/historical-flight/search', 'Historical Flight Status')}
      </span>
    );

    const strongEmail = (<strong>{email}</strong>);

    if (email) {
      return (
        <div className={`account-panel-split-content ${panelStyle}`}>
          <Helmet {...meta} />
          <div className='account-content'>
            <h4 className='account-title'>{heading}</h4>
            <div className='account-panel-non-form'>
              {!userVerified && <div style={sectionStyle}>
                {!sentEmailVerification && <div>
                  <p className='login-text'>
                    Your email address must be verified in order to continue.
                  </p>
                  <p className='login-text'>
                    A verification email was sent to {strongEmail} when the account was created.
                    Clicking the button below will resend another email.
                  </p>
                  {error}
                </div>
                }
                {sentEmailVerification && <div>
                  <p className='login-text'>
                    An email has been sent to {strongEmail}.
                    Follow the link included in that email to verify your email address.
                  </p>
                </div>
                }
                <div className='account-padding-div'>
                  <FSFlatButton
                    label='Resend Verification Email'
                    onClick={this.handleSubmit}
                    disabled={sendingEmailVerification}
                    style={FSFlatButton.style({
 marginTop: '10px', width: '100%', maxWidth: '250px', minWidth: null,
})}
                  />
                </div>
              </div>
              }

              {userVerified && <div style={sectionStyle}>
                <strong>{email}</strong> has been verified.
                <div className='account-padding-div'>
                  <ul style={{ textAlign: 'left', marginTop: '20px' }}>
                    <p style={{ fontWeight: '600' }}>You now have access to:</p>
                    {this.accessItems}
                  </ul>
                  {hasSubscription && subscriptionLevel > 0 && <ul style={{ textAlign: 'left', marginTop: '25px' }}>{subscriptionItems}</ul>}
                  {hasSubscription && subscriptionLevel < 3 && <ul style={{ textAlign: 'left', marginTop: '25px' }}>{upgradeText}</ul>}
                  {!hasSubscription && <ul style={{ textAlign: 'left', marginTop: '25px' }}>{subscribeTodayItems}</ul>}
                </div>
              </div>
              }
            </div>
          </div>
        </div>
      );
    }
    return (<div />);
  }
}
