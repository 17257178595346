import React, { Component } from 'react';
import PropTypes from 'prop-types';
import componentBase from '../../../../../../../src/lib/component-base';
import FSFlatButton from '../../../../../../../src/components/lib/FSFlatButton';

@componentBase('CreditCounter')
export default class CreditCounter extends Component {
  static propTypes = {
    title: PropTypes.string,
    credits: PropTypes.object,
    handleClick: PropTypes.func,
  };

  get title() {
    return this.props && this.props.title ? this.props.title : '';
  }

  get creditCounts() {
    const credits = {
      used: 0,
      remaining: 0,
      reserved: 0,
    };
    if (this.props && this.props.credits) {
      const { used } = this.props.credits;
      let { remaining, reserved } = this.props.credits;
      if (remaining === Infinity) {
        remaining = '--';
      }
      if (reserved === Infinity) {
        reserved = '--';
      }
      return {
        used,
        remaining,
        reserved,
      };
    }
    return credits;
  }

  render() {
    const {
      used,
      remaining,
      reserved,
    } = this.creditCounts;
    const businessAccount = (this.props.credits &&
      (this.props.credits.reserved === Infinity && this.props.credits.remaining === Infinity));

    return (
      <div className='credit-buckets-container' style={{ textAlign: 'center' }} >
        <p style={{ textAlign: 'left', position: 'relative', top: '13px' }} >{this.title}</p>
        <div className='row'>
          <div className={businessAccount ? 'col-xs-12' : 'col-xs-8 col-sm-8 col-md-6 col-lg-7 table-row'}>
            <div className='row'>
              <div className={businessAccount ? 'col-xs-2' : 'hidden'} />
              <div className={businessAccount ? 'col-xs-2' : 'col-xs-4'}>
                <p>Used</p>
              </div>
              <div className='col-xs-4'>
                <p>Remaining</p>
              </div>
              <div className={businessAccount ? 'col-xs-2' : 'col-xs-4'}>
                <p>Reserved</p>
              </div>
              <div className={businessAccount ? 'col-xs-2' : 'hidden'} />
            </div>
          </div>
          <div className='row'>
            <div className={businessAccount ? 'col-xs-12' : 'col-xs-8 col-sm-8 col-md-6 col-lg-7 table-row'} style={{ border: '3px solid rgb(105, 143, 191)', backgroundColor: 'rgba(105, 143, 191, 0.3)' }}>
              <div className='row'>
                <div className={businessAccount ? 'col-xs-2' : 'hidden'} />
                <div className={businessAccount ? 'col-xs-2' : 'col-xs-4'}>
                  <p>{used}</p>
                </div>
                <div className='col-xs-4'>
                  <p>{remaining}</p>
                </div>
                <div className={businessAccount ? 'col-xs-2' : 'col-xs-4'}>
                  <p>{reserved}</p>
                </div>
                <div className={businessAccount ? 'col-xs-2' : 'hidden'} />
              </div>
            </div>
            <div className='credit-button-container'>
              {
                businessAccount ? null :
                <FSFlatButton
                  label='BUY MORE'
                  primary
                  backgroundColor='#7dd63b'
                  hoverColor='#64bc23'
                  className='button credit-button'
                  onClick={this.props.handleClick}
                />
              }
            </div>
          </div>
        </div>
      </div>);
  }
}
