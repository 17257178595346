import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Colors } from '../../../../../src/constants/style-constants';
import componentBase from '../../../../../src/lib/component-base';
import FSFlatButton from '../../../../../src/components/lib/FSFlatButton';
import { showModal, hideModal } from '../../../../Modal/redux/actions';

const marketingPaths = [
  '/subscription',
  '/flight-alerts/subscribe',
  '/historical-flight/subscribe',
  '/airports/departing-arriving/subscribe',
];
/**
 * A flat button that appears with a SUBSCRIBE or UPGRADE label.
 * Displays the subscription upgrade modal or navigates to subscription page.
 */
@connect(
  state => ({
    modal: state.Modal,
  }),
  {
    showModal,
    hideModal,
  },
)
@componentBase('SubscriptionButton')
class SubscriptionButton extends Component {
  static propTypes = {
    backgroundColor: PropTypes.string,
    className: PropTypes.string,
    dispatch: PropTypes.func,
    location: PropTypes.object,
    hoverColor: PropTypes.string,
    reportButtonPressValue: PropTypes.func,
    style: PropTypes.object,
    user: PropTypes.object,
    showModal: PropTypes.func,
    hideModal: PropTypes.func,
  };

  state = {
    modalOpen: false,
  };

  setModalOpen = (modalOpen) => {
    if (modalOpen) {
      this.props.showModal('UPGRADE_SUBSCRIPTION');
    } else {
      this.props.hideModal();
    }
  }

  reportButtonPress() {
    if (this.props.reportButtonPressValue) {
      const { pro } = this.subscriptionStatus();
      this.props.reportButtonPressValue(pro ? 0 : 1);
    }
  }

  upgrade = () => {
    this.reportButtonPress();
    this.setModalOpen(true);
  }

  destination = () => {
    const { location } = this.props;
    let destination = '/subscription';
    if (location) {
      const { pathname } = location;
      destination = marketingPaths.some(path => pathname === path) ? '/account/subscribe' : '/subscription';
    }
    return destination;
  }

  subscribe = () => {
    this.reportButtonPress();
    const destination = this.destination();
    this.props.dispatch(push(destination));
  }

  subscriptionStatus() {
    const {
      user,
    } = this.props;

    let pro = false;
    let active = false;

    if (user) {
      active = user.subscriptionActive;
      pro = active && user.subscriptionLevel >= 3;
    }

    return { active, pro };
  }

  render() {
    const { active, pro } = this.subscriptionStatus();

    if (pro) {
      return <div style={{ display: 'none' }} />;
    }

    let label;
    let onClick;

    if (active) {
      label = 'UPGRADE';
      onClick = this.upgrade;
    } else {
      label = 'SUBSCRIBE';
      onClick = this.subscribe;
    }

    return (
      <FSFlatButton
        backgroundColor={this.props.backgroundColor || Colors.blue6}
        className={this.props.className || 'subscription-button'}
        hoverColor={this.props.hoverColor || Colors.blue8}
        label={label}
        onClick={onClick}
        secondary
        style={FSFlatButton.style(this.props.style)}
      />
    );
  }
}

export default SubscriptionButton;
