// @flow
import React from 'react';
import { openTab } from '../../../src/utils/openTab';

type LinkProps = {
  children: Array<React.Node | string>,
  linkTo: string,
  nofollow: boolean,
};

export const ExternalLink = ({
  children,
  linkTo,
  nofollow,
}: LinkProps): React.Node => (
  <a
    href={linkTo}
    onClick={(e) => openTab(linkTo, e)}
    rel={nofollow ? 'nofollow' : null}
  >
    {children}
  </a>
);
