import reportException from '../report-exception';
import {
  proConversionEvent,
  standardConversionEvent,
} from '../google-global-site-tag';

// Value: return string
// Label: text to display for option
// Cost: for braintree payment component
const subscriptionOptions = [
  {
    value: 0, type: 'free', label: 'Free', cost: '$0.00', displayPurchase: false,
  },
  {
    value: 1, type: 'standard', label: 'Standard for $2.99/month', cost: '$2.99', displayPurchase: true,
  },
  {
    value: 3, type: 'premium/professional', label: 'Professional for $24.99/month', cost: '$24.99', displayPurchase: true,
  },
  {
    value: 11, type: 'business/enterprise', label: 'Enterprise', cost: 'Contact us', displayPurchase: false,
  },
];

const subscriptionLevelByPlanID = {
  WEB_MOBILE_BASIC_MONTHLY_SUBSCRIPTION: 1,
  WEB_MOBILE_PRO_MONTHLY_SUBSCRIPTION: 3,
  WEB_MOBILE_ENTERPRISE_UNLIMITED_SUBSCRIPTION: 11,
};

const subscriptionLevelByCost = {
  '$2.99': 1,
  '$24.99': 3,
  'Contact us': 11,
};

const subscriptionPlanIDByLevel = {
  1: 'WEB_MOBILE_BASIC_MONTHLY_SUBSCRIPTION',
  3: 'WEB_MOBILE_PRO_MONTHLY_SUBSCRIPTION',
  11: 'WEB_MOBILE_ENTERPRISE_UNLIMITED_SUBSCRIPTION',
};

const subscriptionCostByLevel = {
  1: '2.99',
  3: '24.99',
  11: 'Contact us',
};

const subscriptionTitleByLevel = {
  1: 'FlightStats Standard Subscription',
  3: 'FlightStats Professional Subscription',
  11: 'FlightStats Enterprise Subscription',
};

const subscriptionShortNameByLevel = {
  0: 'Free',
  1: 'Standard',
  3: 'Professional',
  11: 'Enterprise',
};

const subscriptionGAValueByLevel = {
  1: 'Standard',
  3: 'Professional',
  11: 'Enterprise',
};

const subscriptionSimpleValueByLevel = {
  0: 'Free-',
  1: 'Basic-',
  3: 'Pro-',
  11: 'Ent-',
};

const subscriptionReportingByLevel = {
  1: standardConversionEvent,
  3: proConversionEvent,
};

const subscriptionForKey = (key, subscriptionSet) => {
  if (isNaN(key) && !key) { // level can be 0, if free
    reportException(new Error('invalid key for subscription'), {
      key,
    });
    return null;
  }

  return subscriptionSet[key];
};

const findSubscriptionByType =
  type => subscriptionOptions.find(e => e.type.includes(type.toLowerCase()));
const subscriptionLevelForPlanID = planID => subscriptionForKey(planID, subscriptionLevelByPlanID);
const subscriptionPlanIDForLevel = level => subscriptionForKey(level, subscriptionPlanIDByLevel);
const subscriptionCostForLevel = level => subscriptionForKey(level, subscriptionCostByLevel);
const subscriptionLevelForCost = level => subscriptionForKey(level, subscriptionLevelByCost);
const subscriptionShortNameForLevel = (level = 0) =>
  subscriptionForKey(level, subscriptionShortNameByLevel);
const subscriptionTitleForLevel = level => subscriptionForKey(level, subscriptionTitleByLevel);
const subscriptionGAValueForLevel = level => subscriptionForKey(level, subscriptionGAValueByLevel);
const subscriptionSimpleValueForLevel = level =>
  subscriptionForKey(level, subscriptionSimpleValueByLevel);
const subscriptionReporterForLevel = (level = 0) =>
  subscriptionForKey(level, subscriptionReportingByLevel);

export {
  findSubscriptionByType,
  subscriptionLevelForPlanID,
  subscriptionPlanIDForLevel,
  subscriptionCostForLevel,
  subscriptionLevelForCost,
  subscriptionShortNameForLevel,
  subscriptionTitleForLevel,
  subscriptionOptions,
  subscriptionGAValueForLevel,
  subscriptionReporterForLevel,
  subscriptionSimpleValueForLevel,
};
