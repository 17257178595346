import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import moment from 'moment';

import {
  setHistoricalSearchValues,
  userWithSingleHistoricalCredit,
  setHistoricalSingleErrorMessage,
} from '../redux/Single/actions';
import {
  setHistoricalMultiErrorMessage,
} from '../redux/Multi/actions';
import componentBase from '../../../src/lib/component-base';
import { errorMessageForCode } from '../../../src/lib/errors';
import userHasHistoricalCredits from './lib/helpers/userHasHistoricalCredits';
import { isUsersLastHistoricalCredit } from './lib/helpers/isUsersLastHistoricalCredit';
import { isBusinessUser } from '../../../src/utils/isBusinessUser';
import CreditsSummary from '../../../shared/components/CreditsSummary';
import BuyCreditsGenericModal from '../../../shared/components/BuyCreditsGenericModal/BuyCreditsGenericModal';
import { endSpinner, getProfile } from '../../Account/redux/actions';
import reportException from '../../../shared/lib/report-exception';
import { addMetaCanonical } from '../../../shared/lib/meta-tags';
import HistoricalSearch from './HistoricalSearch/HistoricalSearch';

@connect(state => ({
  airportSearchDepartureDate:
      moment.utc(state.SingleHistoricalFlightStatus.airportSearchDepartureDate),
  user: state.Account.user,
  byFlightAirline: state.SingleHistoricalFlightStatus.byFlightAirline,
  byAirportAirline: state.SingleHistoricalFlightStatus.byAirportAirline,
  arrivalAirport: state.SingleHistoricalFlightStatus.arrivalAirport,
  departureAirport: state.SingleHistoricalFlightStatus.departureAirport,
  flightSearchDepartureDate:
      moment.utc(state.SingleHistoricalFlightStatus.flightSearchDepartureDate),
  flightNumber: state.SingleHistoricalFlightStatus.flightNumber,
  historicalByAirportSearch: state.SingleHistoricalFlightStatus.historicalByAirportSearch,
  historicalByFlightSearch: state.SingleHistoricalFlightStatus.historicalByFlightSearch,
  byFlightSecondaryAction: state.SingleHistoricalFlightStatus.byFlightSecondaryAction,
  byAirlineSecondaryAction: state.SingleHistoricalFlightStatus.byAirlineSecondaryAction,
  singleErrorMessage: state.SingleHistoricalFlightStatus.singleErrorMessage,
  historicalMultiErrorMessage: state.MultiHistoricalFlightStatus.historicalMultiErrorMessage,
}))
@componentBase('HistoricalFlightTrackerSearch')
export default class HistoricalFlightTrackerSearch extends Component {
  static propTypes = {
    airportSearchDepartureDate: PropTypes.object,
    arrivalAirport: PropTypes.object,
    byFlightAirline: PropTypes.object,
    byAirportAirline: PropTypes.object,
    location: PropTypes.object,
    children: PropTypes.any,
    departureAirport: PropTypes.object,
    flightSearchDepartureDate: PropTypes.object,
    dispatch: PropTypes.func,
    flightNumber: PropTypes.string,
    history: PropTypes.object,
    historicalByAirportSearch: PropTypes.string,
    historicalByFlightSearch: PropTypes.string,
    byFlightSecondaryAction: PropTypes.bool,
    byAirlineSecondaryAction: PropTypes.bool,
    singleErrorMessage: PropTypes.string,
    historicalMultiErrorMessage: PropTypes.string,
    user: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    const { singleErrorMessage, historicalMultiErrorMessage } = props;
    // if somehow both single and multi errors, single takes precedence
    const errorCode = singleErrorMessage || historicalMultiErrorMessage;

    this.state = {
      errorText: (errorCode && errorMessageForCode(errorCode)),
      creditsModalOpen: false,
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  componentDidMount() {
    const {
      dispatch,
      user,
    } = this.props;

    dispatch(getProfile());

    if (this.lastCredit(user)) {
      dispatch(userWithSingleHistoricalCredit());
    }
  }

  componentWillUnmount() {
    const {
      dispatch,
      historicalMultiErrorMessage,
      singleErrorMessage,
    } = this.props;
    if (singleErrorMessage) {
      dispatch(setHistoricalSingleErrorMessage(''));
    }
    if (historicalMultiErrorMessage) {
      dispatch(setHistoricalMultiErrorMessage(''));
    }
  }

  getMetaTags = () => {
    const title = 'Historical Flight Status';
    const description = 'Search for global flights back to 2006 using the FlightStats Historical Flight Status feature. Information includes Final Status, Delay Calculations, Gate and Runway Times, Equipment Type, Terminal, Gate, and Baggage, and Upline and Downline Segments.';
    const metaWithoutCanonical = {
      title,
      meta: [
        { name: 'description', content: description },
        { name: 'keywords', content: 'historical flights, historical flight status, historical flight search' },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
      ],
    };
    const { location } = this.props;
    const pathname = location && location.pathname;
    return addMetaCanonical(metaWithoutCanonical, pathname);
  }

  hasCredits = () => userHasHistoricalCredits(this.props.user);

  lastCredit = user => isUsersLastHistoricalCredit(user);

  credits = () => {
    const { user } = this.props;

    if (!user) {
      return {};
    }

    const {
      historicalMonthlyCredits,
      historicalReservedCredits,
    } = user;

    const credits = {
      monthly: `${historicalMonthlyCredits || 0}`,
      reserved: `${historicalReservedCredits || 0}`,
    };

    if (isBusinessUser(user)) {
      credits.monthly = '--';
      credits.reserved = '--';
    }

    return credits;
  }

  handleCloseModal = () => {
    const { dispatch } = this.props;
    this.toggleCreditsModal(false);
    dispatch(getProfile())
      .then((action) => {
        dispatch(endSpinner());
        return action;
      })
      .catch((err) => {
        const standardError = 'failed to get user profile after historical credit purchase';
        reportException(err, standardError, 'HistoricalFlightTrackerSearch');
        dispatch(endSpinner());
      });
  }

  handleOpenModal = () => {
    this.toggleCreditsModal(true);
  }

  handleFlightNumberChange = flightNumber =>
    this.props.dispatch(setHistoricalSearchValues({ flightNumber }))

  handleAirlineChange = airlineByFlight =>
    this.props.dispatch(setHistoricalSearchValues({ airlineByFlight }))

  handleDepartureDateChange = (flightSearchDepartureDate, airportOrFlightSearch) =>
    this.props.dispatch(setHistoricalSearchValues({ [`${airportOrFlightSearch}DepartureDate`]: flightSearchDepartureDate }))

  handleAirportArrivalChange = arrivalAirport =>
    this.props.dispatch(setHistoricalSearchValues({ arrivalAirport }))

  handleAirportDepartureChange = departureAirport =>
    this.props.dispatch(setHistoricalSearchValues({ departureAirport }))

  toggleCreditsModal = (bool) => {
    this.setState({
      creditsModalOpen: bool,
    });
  }

  renderCreditCounter = () => {
    const { monthly, reserved } = this.credits();
    const doNotShowBuyMoreButton = isBusinessUser(this.props.user);
    const zeroCreditCount = !this.hasCredits();
    return (
      <div className='credits-summary-wrapper'>
        <CreditsSummary
          doNotShowBuyMoreButton={doNotShowBuyMoreButton}
          buyMoreCredits={this.handleOpenModal}
          backgroundColor={zeroCreditCount ? 'red' : 'green'}
          creditsMonthlyAllowance={monthly}
          reserveCredits={reserved}
          allowanceText='Monthly Credits'
          reserveText='Reserve Credits'
        />
      </div>
    );
  }

  renderNoCredits = () => (
    <div>
      <div className='no-historical-credits-container' >
        <div className='no-historical-credits-message' >
          <p>
            This service requires the use of credits.
            Currently, you have no reserve credits.
          </p>
        </div>
      </div>
    </div>
  );

  renderError = () => (this.state.errorText ? (
    <div className='search-error-text-block'>
      <p className='search-error-text'>{this.state.errorText}</p>
    </div>
  ) : '');

  renderChildren = () => React.Children.map(this.props.children, child => React.cloneElement(
    child,
    {
      ...this.props,
      ref: 'child',
    },
  ));

  renderWithCredits = () => (
    <div>
      <div>
        {this.renderError()}
      </div>
      <div> {this.renderChildren()} </div>
    </div>
  );

  render() {
    const metaTags = this.getMetaTags();
    const headerStyle = {
      fontSize: '3em',
      lineHeight: '1',
      display: 'inline-block',
      marginBottom: '20px',
    };
    return (
      <section>
        <Helmet {...metaTags} />
        <div style={{ top: 0, margin: '25px auto', width: '100%' }}>
          <div className='fs-search'>
            <h1 style={headerStyle}>
              Historical Flight Status
            </h1>
            {this.renderCreditCounter()}
            {!this.hasCredits() ? this.renderNoCredits() : this.renderWithCredits()}
            <BuyCreditsGenericModal
              modalOpen={this.state.creditsModalOpen}
              closeModal={this.handleCloseModal}
              isHistoricalCredits
            />
            <HistoricalSearch
              airportSearchDepartureDate={this.props.airportSearchDepartureDate}
              arrivalAirport={this.props.arrivalAirport}
              byFlightAirline={this.props.byFlightAirline}
              byAirportAirline={this.props.byAirportAirline}
              departureAirport={this.props.departureAirport}
              flightSearchDepartureDate={this.props.flightSearchDepartureDate}
              dispatch={this.props.dispatch}
              flightNumber={this.props.flightNumber}
              handleAirlineChange={this.handleAirlineChange}
              handleAirportArrivalChange={this.handleAirportArrivalChange}
              handleAirportDepartureChange={this.handleAirportDepartureChange}
              handleDepartureDateChange={this.handleDepartureDateChange}
              handleFlightNumberChange={this.handleFlightNumberChange}
              historicalByAirportSearch={this.props.historicalByAirportSearch}
              historicalByFlightSearch={this.props.historicalByFlightSearch}
              byFlightSecondaryAction={this.props.byFlightSecondaryAction}
              byAirlineSecondaryAction={this.props.byAirlineSecondaryAction}
              history={this.props.history}
            />
          </div>
        </div>
      </section>
    );
  }
}
