import { matchPath } from 'react-router';

export const extractCanonical = (pattern, url) => {
  const urlParamsToUppercase = [
    ':carrier',
    ':departureAirportCode',
    ':arrivalAirportCode',
    ':airportCode',
    ':departureAirport',
    ':arrivalAirport',
    ':carrierCode',
  ];
  const urlComponents = url.split('?').shift().split('/');
  const patternComponents = pattern.split('/');
  return urlComponents.length === 1 ? '' : patternComponents
    .map((pc, i) => (pc.includes(':')
      ? (urlParamsToUppercase.includes(pc)
        ? urlComponents[i].toUpperCase()
        : urlComponents[i])
      : pc))
    .join('/');
};

export const resolveCanonical = (routeConfig, path, withHost = true) => {
  const strippedUrl = path.replace('/v2', '').replace(/((\?|\#|\;|\%).*)$/, '');
  const matchedRoute = routeConfig.find(i => matchPath(strippedUrl, i));
  if (matchedRoute && (matchedRoute.path || matchedRoute.canonical)) {
    const canonical = extractCanonical(
      matchedRoute.canonical || matchedRoute.path,
      strippedUrl,
    );
    const c = canonical === '/' ? '' : canonical;
    return withHost ? `https://www.flightstats.com/v2${c}` : `/v2${c}`;
  }
  return '';
};
