import React from 'react';
import PropTypes from 'prop-types';

const ProgressIcon = (props) => {
  const {
    className,
    handleClick,
    highlighted,
    imgAlt,
    imgSrc,
    registrationStage,
    text,
  } = props;

  const handleOnClick = (event, stage) => {
    if (handleClick) {
      handleClick(event, stage);
    }
    return null;
  };

  return (
    <div className={`progress-icon ${className}`}>
      <img
        src={imgSrc}
        alt={imgAlt}
        className={highlighted ? 'highlight' : ''}
        onClick={e => handleOnClick(e, registrationStage)}
      />
      <div className={`text${highlighted ? ' highlight' : ''}`}>
        {text}
      </div>
    </div>
  );
};

ProgressIcon.propTypes = {
  className: PropTypes.string,
  handleClick: PropTypes.func,
  highlighted: PropTypes.bool,
  imgAlt: PropTypes.string,
  imgSrc: PropTypes.string,
  registrationStage: PropTypes.string,
  text: PropTypes.string,
};

export default ProgressIcon;
