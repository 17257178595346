import { DomUtil, DomEvent, Control } from 'leaflet';
import MapControlBase from './MapControlBase';
import componentBase from '../../lib/component-base';

const zoomBtnBkg = `url(${require('../../../static/images/flight-tracker/nuzoom-button.png')})`;

@componentBase('ZoomControl')
export default class ZoomControl extends MapControlBase {
  constructor(props, context) {
    super(props);

    this.map = context.map;
  }

  componentDidMount() {
    const Ctrl = Control.extend({
      options: {
        position: 'topright',
      },

      reset: this.reset(),

      onAdd: (map) => {
        const container = DomUtil.create('div', 'leaflet-bar leaflet-control');
        container.style.display = (this.props.hide ? 'none' : null);

        const preloadZoom = DomUtil.create('div', container);
        preloadZoom.style.backgroundImage = zoomBtnBkg;

        const parent = DomUtil.create('div', 'leaflet-bar-part', container);
        parent.style.width = '50px';
        parent.style.height = '80px';
        parent.style.backgroundImage = preloadZoom.style.backgroundImage;
        parent.style.backgroundSize = '50px 80px';
        parent.style.backgroundRepeat = 'no-repeat';
        parent.style.boxShadow = '-2px 2px 10px rgba(0, 0, 0, 0.5)';

        const zoomIn = DomUtil.create('div', 'leaflet-bar-part', parent);
        zoomIn.style.width = '50px';
        zoomIn.style.height = '31px';
        zoomIn.style.cursor = 'pointer';

        const spacer = DomUtil.create('div', 'leaflet-bar-part', parent);
        spacer.style.width = '50px';
        spacer.style.height = '19px';

        const zoomOut = DomUtil.create('div', 'leaflet-bar-part', parent);
        zoomOut.style.width = '50px';
        zoomOut.style.height = '30px';
        zoomOut.style.cursor = 'pointer';

        DomEvent
          .on(zoomIn, 'dblclick', DomEvent.stopPropagation)
          .on(zoomIn, 'dblclick', DomEvent.preventDefault)
          .on(zoomIn, 'click', (e) => {
            DomEvent.stopPropagation(e);
            DomEvent.preventDefault(e);

            map.zoomIn();
            this.context.reportButtonPress('TrackerZoomLevelChange', Math.min(map.getMaxZoom(), map.getZoom() + 1));
          });

        DomEvent
          .on(zoomOut, 'dblclick', DomEvent.stopPropagation)
          .on(zoomOut, 'dblclick', DomEvent.preventDefault)
          .on(zoomOut, 'click', (e) => {
            DomEvent.stopPropagation(e);
            DomEvent.preventDefault(e);

            map.zoomOut();
            this.context.reportButtonPress('TrackerZoomLevelChange', Math.max(map.getMinZoom(), map.getZoom() - 1));
          });

        return container;
      },
    });

    this.control = new Ctrl();
    this.map.addControl(this.control);
  }
}
