import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import componentBase from '../../../../../../../src/lib/component-base';
import { formattedTime } from '../../../../../../../src/lib/date-time-format';

@componentBase('MultiFlightsList')
export default class MultiFlightsList extends React.Component {
  static propTypes = {
    segments: PropTypes.array,
    destOriginTitle: PropTypes.string,
  };

  static defaultProps = {
    segments: null,
    destOriginTitle: null,
  };

  constructor(props, context) {
    super(props, context);

    this.muiDefaultProps = {
      centeredText: {
        textAlign: 'center',
      },
      departureTimeStyle: {
        marginBottom: '5px',
        textTransform: 'lowercase',
        marginLeft: '5px',
        textAlign: 'center',
      },
      arrivalTimeStyle: {
        color: '#FAA718',
        marginBottom: '5px',
        textTransform: 'lowercase',
        marginLeft: '5px',
        textAlign: 'center',
      },
      airlineStyle: {
      },
    };
  }

  _getTableHeader = () =>
    // origin, destination, departure time, arrival time, operated by
    (
      <div className='multi-flights-list-header row'>
        <div className='col-xs-3 col-sm-2'>
          <p className='flights-list-header-text' style={this.muiDefaultProps.centeredText}>Origin</p>
        </div>
        <div className='col-xs-3 col-sm-2'>
          <p className='flights-list-header-text' style={this.muiDefaultProps.centeredText}>Destination</p>
        </div>
        <div className='col-xs-3 col-sm-2'>
          <p className='flights-list-header-text visible-xs' style={{ marginLeft: '5px', textAlign: 'center' }}>Departure</p>
          <p className='flights-list-header-text hidden-xs' style={{ marginLeft: '5px', textAlign: 'center' }}>Departure Time</p>
        </div>
        <div className='col-xs-3 col-sm-2'>
          <p className='flights-list-header-text visible-xs' style={{ marginLeft: '5px', textAlign: 'center' }}>Arrival</p>
          <p className='flights-list-header-text hidden-xs' style={{ marginLeft: '5px', textAlign: 'center' }}>Arrival Time</p>
        </div>
        <div className='hidden-xs col-sm-4'>
          <p className='flights-list-header-text'>Operated By</p>
        </div>
      </div>
    );

  _getAirportCell = (airport) => {
    if (airport) {
      return this._getCellTopRow(airport.fs, 'flights-list-bold-text flights-list-margined', this.muiDefaultProps.centeredText, '3');
    }

    return null;
  }

  _getCellTopRow = (value, classes, customStyle, xs, carrier = false) => {
    let divClasses = `table-cell col-xs-${xs}`;
    divClasses += carrier ? ' hidden-xs col-sm-4' : ' col-sm-2';
    return (
      <div className={divClasses}>
        <h2 className={classes} style={customStyle}>{value}</h2>
      </div>
    );
  }

  _getTableRows = (flights) => {
    const tableRows = [];

    flights.forEach((flight, i) => {
      const departureAirport = this._getAirportCell(flight.departureAirport);
      const arrivalAirport = this._getAirportCell(flight.arrivalAirport);
      const operatedBy = flight.operatedBy ? flight.operatedBy : '';
      let departureTime = '';
      let arrivalTime = '';
      let departureRunway = false;
      let arrivalRunway = false;

      if (flight.departureTimes.scheduledGate) {
        departureTime = `${formattedTime(this.context.currentUser, flight.departureTimes.scheduledGate, 'time24', 'time')}${formattedTime(this.context.currentUser, flight.departureTimes.scheduledGate, null, 'ampm') || ''}`;
      } else if (flight.departureTimes.scheduledRunway) {
        departureTime = `${formattedTime(this.context.currentUser, flight.departureTimes.scheduledRunway, 'time24', 'time')}${formattedTime(this.context.currentUser, flight.departureTimes.scheduledRunway, null, 'ampm') || ''}`;
        departureRunway = true;
      }

      if (flight.arrivalTimes.scheduledGate) {
        arrivalTime = `${formattedTime(this.context.currentUser, flight.arrivalTimes.scheduledGate, 'time24', 'time')}${formattedTime(this.context.currentUser, flight.arrivalTimes.scheduledGate, null, 'ampm') || ''}`;
      } else if (flight.arrivalTimes.scheduledRunway) {
        arrivalTime = `${formattedTime(this.context.currentUser, flight.arrivalTimes.scheduledRunway, 'time24', 'time')}${formattedTime(this.context.currentUser, flight.arrivalTimes.scheduledRunway, null, 'ampm') || ''}`;
        arrivalRunway = true;
      }

      const secondLine1 = (
        <div className='table-cell col-xs-3 col-sm-2'>
          <p className='flights-list-subtitle-text' style={this.muiDefaultProps.centeredText}>{flight.departureAirport.city}</p>
        </div>
      );
      const secondLine2 = (
        <div className='table-cell col-xs-3 col-sm-2'>
          <p className='flights-list-subtitle-text' style={this.muiDefaultProps.centeredText}>{flight.arrivalAirport.city}</p>
        </div>
      );
      const secondLine3 = (
        <div className='table-cell col-xs-3 col-sm-2'>
          <p className='flights-list-subtitle-text' style={this.muiDefaultProps.centeredText}>{departureRunway ? 'Runway' : ''}</p>
        </div>
      );
      const secondLine4 = (
        <div className='table-cell col-xs-3 col-sm-2'>
          <p className='flights-list-subtitle-text' style={this.muiDefaultProps.centeredText}>{arrivalRunway ? 'Runway' : ''}</p>
        </div>
      );
      const secondLine5 = (
        <div className='table-cell hidden-xs'>
          <p className='flights-list-subtitle-text'>{operatedBy}</p>
        </div>
      );

      const key = `key_${i}`;

      const row = (
        <Link
          className='list-card'
          to={flight.url}
          key={key}
          onClick={this.context.reportUserEvent.bind(this, 'Click', 'TrackFlight')}
        >
          <div className='multi-flights-list-row row'>
            <div className='row'>
              {departureAirport}
              {arrivalAirport}
              { this._getCellTopRow(departureTime, 'flights-list-bold-text flights-list-margined departureTimePadding', this.muiDefaultProps.departureTimeStyle, '3') }
              { this._getCellTopRow(arrivalTime, 'flights-list-light-text flights-list-margined', this.muiDefaultProps.arrivalTimeStyle, '3') }
              { this._getCellTopRow(flight.carrier.name, 'flights-list-light-text flights-list-margined', this.muiDefaultProps.airlineStyle, '', true) }
            </div>
            <div className='row'>
              {secondLine1}
              {secondLine2}
              {secondLine3}
              {secondLine4}
              {secondLine5}
            </div>
          </div>
        </Link>
      );
      tableRows.push(row);
    });

    return tableRows;
  };

  render() {
    if (this.props.segments) {
      const flights = this.props.segments;
      const tableHeader = this._getTableHeader();
      const rows = this._getTableRows(flights);

      return (
        <div className='multi-segment-flights-list container'>
          {tableHeader}
          {rows}
        </div>
      );
    }

    return (<div />);
  }
}
