import React, { Component } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { path } from 'ramda';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

// relative paths
import componentBase from '../../../../../../src/lib/component-base';
import { catchErrors } from '../../../../redux/selectors';
import reportException from '../../../../../../shared/lib/report-exception/index';
import {
  subscriptionPlanIDForLevel,
  subscriptionGAValueForLevel,
  subscriptionCostForLevel,
  findSubscriptionByType,
} from '../../../../../../shared/lib/subscription-level-matching';
import clipboardIcon from '../../../../../../static/images/CheckClipboard-WHITE.svg';
import { cardErrorMessageForType } from '../../../shared/utility';
// components
import HighlightedSection from '../shared/HighlightedSection';
import BillingInformation from '../../../Subscription/BillingInformation';
import BraintreePaymentForm from '../../../Subscription/BraintreePaymentForm';

@componentBase('RegistrationPaymentInformationContainer')
export default class RegistrationPaymentInformationContainer extends Component {
  static propTypes = {
    braintreeClientToken: PropTypes.string,
    braintreeSubmitButtonDisabled: PropTypes.bool,
    createBraintreeSubscription: PropTypes.func,
    disableRegistrationBtn: PropTypes.func,
    dispatch: PropTypes.func,
    endSpinner: PropTypes.func,
    getProfile: PropTypes.func,
    getBraintreePlans: PropTypes.func,
    getBraintreeClientToken: PropTypes.func,
    handleAlreadyHaveAccountClick: PropTypes.func,
    hasCheckedTermsAndConditions: PropTypes.bool,
    hostedFields: PropTypes.object,
    hostedFieldsDidCreate: PropTypes.func,
    loggedIn: PropTypes.bool,
    planSelected: PropTypes.string,
    registrationBillingInformationBtnDisabled: PropTypes.bool,
    routerPush: PropTypes.func,
    startSpinner: PropTypes.func,
    termsAndConditionsChecked: PropTypes.func,
    updateProfile: PropTypes.func,
    user: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);

    this.billingInfo = null;
    this.braintreePaymentForm = null;
    this.retryCount = 0;

    this.cleanup = this.cleanup.bind(this);
    this.createSubscription = this.createSubscription.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onPaymentFormSubmitted = this.onPaymentFormSubmitted.bind(this);
  }

  componentDidMount() {
    this.props.getBraintreePlans();
    this.props.getBraintreeClientToken();
    this.props.termsAndConditionsChecked(true);
    this.props.endSpinner();
    scroll.scrollToTop();
  }

  componentWillUnmount() {
    this.cleanup();
  }

  onPaymentFormSubmitted = () => {
    this.braintreePaymentForm.setSubmitButtonDisabled(true);
    this.props.startSpinner();

    return this.billingInfo.submitForm()
      .then(action => (
        Promise.all([
          action,
          this.braintreePaymentForm.sendPaymentMethod(),
        ])
      ))
      .then((values) => {
        const [action, paymentInfo] = values;
        const { result: userProfile } = action;
        const { subscriptionStatus } = userProfile;
        if (subscriptionStatus === 'active') {
          this.braintreePaymentForm.setGeneralError(cardErrorMessageForType('subscription_active'));
          return this.cleanup();
        }
        return this.createSubscription(paymentInfo, userProfile);
      })
      .catch((err) => {
        this.retryCount = 0;
        reportException(err, null, 'registration-payment-info');
        this.context.toast.error('Please try again.', 'Error', false, 3);
        this.cleanup();
        return false;
      });
  }

  cleanup = () => {
    this.props.endSpinner();
    this.props.disableRegistrationBtn(false);
  }

  retryOnRejection = (err, subscriptionData, successBlock, failureBlock) => {
    if (err.code !== 422 && this.retryCount < 1) {
      this.retryCount++;
      return this.braintreePaymentForm.sendPaymentMethod()
        .then(payload => this.props.createBraintreeSubscription(Object.assign(
          subscriptionData,
          { nonce: payload.nonce },
        )))// new nonce, since nonce already used in first try
        .then(success => successBlock(success))
        .catch(error => failureBlock(error));
    }
    return failureBlock(err);
  }

  createSubscription = (braintreePayload, userProfile) => {
    const billingAddress = {
      cardholderName: userProfile.additionalInfo.name,
      streetAddress: userProfile.additionalInfo.streetAddress,
      locality: userProfile.additionalInfo.city,
      region: userProfile.additionalInfo.state,
      postalCode: userProfile.additionalInfo.postalCode,
      countryCodeAlpha2: userProfile.additionalInfo.countryCode,
    };

    if (userProfile.additionalInfo.companyName) {
      billingAddress.company = userProfile.additionalInfo.companyName;
    }

    // Now that we have a nonce, send it to the server
    // to create a payment method or a transaction
    const tierSelection = findSubscriptionByType(this.props.planSelected).value;
    const subscriptionData = {
      email: userProfile.email,
      nonce: braintreePayload.nonce,
      planId: subscriptionPlanIDForLevel(tierSelection),
      billingAddress,
    };

    const failureBlock = (err) => {
      if (this.retryCount === 1) { // we have failed twice already (retryCount starts at zero)
        this.retryCount = 0; // if next subscribe account fails, we still want to retry once
      }
      this.braintreePaymentForm.setGeneralError(cardErrorMessageForType(err.type));
      this.braintreePaymentForm.setSubmitButtonDisabled(false);
      this.context.toast.error(catchErrors(err), 'Error', false, 6);
      this.cleanup();
    };

    const successBlock = (action) => {
      // const result = action && action.result && action.result.data;
      const getDeepKey = key => path(['result', key], action);
      const softError = getDeepKey('softError');
      const result = getDeepKey('data');

      if (softError) {
        const type = getDeepKey('type');
        // retry on a soft error as well
        if (this.retryCount < 1) {
          return this.retryOnRejection({}, subscriptionData, successBlock, failureBlock);
        }
        // else show the user the soft error
        this.braintreePaymentForm.setGeneralError(cardErrorMessageForType(type));
        this.context.toast.error(cardErrorMessageForType(type), 'Error', false, 6);
        return this.cleanup();
      }
      this.context.reportUserSubscribed(tierSelection);
      const transactionId = path(['transactionId'], result);
      const purchase = {
        affiliation: 'Subscription',
        name: subscriptionGAValueForLevel(tierSelection),
        revenue: subscriptionCostForLevel(tierSelection),
      };
      this.props.getProfile()
        .then((userAction) => {
          const userRes = userAction && userAction.result;
          purchase.id = transactionId || userRes.subscriptionBraintreeId;
          this.context.reportPurchase(purchase);
          this.braintreePaymentForm && this.braintreePaymentForm.clearErrors();
          this.cleanup();
          const search = this.props.planSelected === 'premium' ? 'professional' : this.props.planSelected;
          this.props.routerPush({
            pathname: '/register/completion',
            search: `?type=${search}`,
          });
        });
      return result;
    };

    const retryBlock = err =>
      this.retryOnRejection(err, subscriptionData, successBlock, failureBlock);

    return this.props.createBraintreeSubscription(subscriptionData)
      .then(success => successBlock(success), err => retryBlock(err))
      .catch(error => failureBlock(error));
  };

  handleSubmit = () => {
    if (this.props.hasCheckedTermsAndConditions) {
      this.props.disableRegistrationBtn(true);

      this.onPaymentFormSubmitted();
    } else {
      this.braintreePaymentForm.setGeneralError('You must accept the Terms and Conditions');
    }
  }

  renderBody = () => {
    const {
      braintreeClientToken,
      braintreeSubmitButtonDisabled,
      hostedFields,
      hostedFieldsDidCreate,
      planSelected,
      updateProfile,
      user,
    } = this.props;
    const tierSelection = findSubscriptionByType(planSelected).value;
    return braintreeClientToken ? (
      <div className='content'>
        <div className='section info col-xs-offset-0 col-xs-12 col-sm-6 col-md-5'>
          <BillingInformation
            ref={ref => (this.billingInfo = ref)}
            updateProfile={updateProfile}
            user={user}
          />
        </div>
        <div className='section payment col-xs-offset-0 col-xs-12 col-sm-6 col-md-offset-1 col-md-5'>
          <BraintreePaymentForm
            clientToken={braintreeClientToken}
            dispatch={this.props.dispatch}
            handleCheckBoxChange={() => {
              /* required, but checkbox and is hidden and checked by default */
            }}
            hostedFields={hostedFields}
            hostedFieldsDidCreate={hostedFieldsDidCreate}
            braintreeSubmitButtonDisabled={braintreeSubmitButtonDisabled}
            ref={ref => (this.braintreePaymentForm = ref)}
            onPaymentFormSubmitted={() => { /* nothing here since button is hidden */ }}
            tierSelection={tierSelection}
            user={user}
          />
        </div>
      </div>
    ) : (
      <div />
    );
  }

  render() {
    return (
      <div className='account-registration' key='registrationBillingContainer'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>FlightStats - Subscription</title>
        </Helmet>
        <HighlightedSection
          accountType={this.props.planSelected}
          body={this.renderBody()}
          buttonDisabled={this.props.registrationBillingInformationBtnDisabled}
          buttonTitle='Submit Payment'
          footerTitle='You are creating a '
          handleAlreadyHaveAccountClick={this.props.handleAlreadyHaveAccountClick}
          icon={clipboardIcon}
          loggedIn={this.props.loggedIn}
          planSelected={this.props.planSelected}
          submit={this.handleSubmit}
          title='Enter Your Billing Information'
        />
      </div>
    );
  }
}
