import { NAME } from './constants';

export const LOAD_BY_CARRIER_FLIGHT_NUMBER_AND_DATE = `${NAME}/LOAD_BY_CARRIER_FLIGHT_NUMBER_AND_DATE`;
export const LOAD_EXTENDED_DETAILS_BY_CARRIER_FLIGHT_NUMBER_AND_DATE = `${NAME}/LOAD_EXTENDED_DETAILS_BY_CARRIER_FLIGHT_NUMBER_AND_DATE`;
export const LOAD_EXTENDED_SUCCESS = `${NAME}/LOAD_EXTENDED_SUCCESS`;
export const LOAD_SUCCESS = `${NAME}/LOAD_SUCCESS`;
export const LOAD_FAIL = `${NAME}/LOAD_FAIL`;
export const LOAD_BY_CARRIER_FLIGHT_NUMBER_AND_DATE_SUCCESS = `${NAME}/LOAD_BY_CARRIER_FLIGHT_NUMBER_AND_DATE_SUCCESS`;
export const LOAD_BY_CARRIER_FLIGHT_NUMBER_AND_DATE_FAIL = `${NAME}/LOAD_BY_CARRIER_FLIGHT_NUMBER_AND_DATE_FAIL`;
export const LOAD_OTHER_DAYS_FLIGHTS = `${NAME}/LOAD_OTHER_DAYS_FLIGHTS`;
export const LOAD_OTHER_DAYS_FLIGHTS_SUCCESS = `${NAME}/LOAD_OTHER_DAYS_FLIGHTS_SUCCESS`;
export const LOAD_OTHER_DAYS_FLIGHTS_FAIL = `${NAME}/LOAD_OTHER_DAYS_FLIGHTS_FAIL`;
export const UPDATE_SEGMENTS = `${NAME}/UPDATE_SEGMENTS`;
export const SET_SECONDARY_SEARCH_ACTION = `${NAME}/SET_SECONDARY_SEARCH_ACTION`;
export const SET_SINGLE_FLIGHT_TRACKER_ERROR = `${NAME}/SET_SINGLE_FLIGHT_TRACKER_ERROR`;
export const SET_FLIGHT_TRACKER_OUT_OFF_DATE_RANGE = `${NAME}/SET_FLIGHT_TRACKER_OUT_OFF_DATE_RANGE`;
export const GET_AIRLINE_CARRIER_SUCCESS = `${NAME}/GET_AIRLINE_CARRIER_SUCCESS`;
export const GET_AIRLINE_CARRIER_FAIL = `${NAME}/GET_AIRLINE_CARRIER_FAIL`;
export const RESET_TRACKER_STATE = `${NAME}/RESET_TRACKER_STATE`;
