import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom/server';
import { divIcon, marker, point } from 'leaflet';
import { complement, prop, equals } from 'ramda';

import componentBase from '../../../../../../src/lib/component-base';

@componentBase('AirportMarker')
export default class AirportMarker extends React.Component {
  static propTypes = {
    map: PropTypes.object,
    airportCode: PropTypes.string,
    airportName: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  };

  constructor(props, context) {
    super(props, context);

    this.marker = null;
    this.map = context.map;
  }

  componentDidMount() {
    this.createMarker(this.props);
  }

  componentWillReceiveProps(nextProps) {
    const notEquals = complement(equals);
    if (notEquals(prop('airportCode', this.props), prop('airportCode', nextProps))) {
      // only creating a new marker when the airport code changes
      this.createMarker(nextProps);
    }
  }

  popUpContent = (props) => {
    // shortened version if needed...
    let airportName = props.airportName.replace('International', 'Intl.');
    if (airportName.length > 30) {
      airportName = `${airportName.substring(0, 30)}...`;
    }

    const html = (
      <div className='parent' key={props.airportCode}>
        <div className='details airport-conditions-map-airport-icon-standard-color'>
          <div>{ props.airportCode }</div>
          <div className='sub-text'>{ airportName }</div>
          <div className='sub-text'>{ props.addressLine1 }</div>
          <div className='sub-text'>{ props.addressLine2 }</div>
        </div>
      </div>
    );

    return ReactDOM.renderToString(html);
  }

  createMarker(props) {
    if (this.marker) {
      this.map.removeLayer(this.marker);
    }

    const icon = divIcon({
      iconAnchor: [33, 69],
      iconSize: [44, 54],
      className: 'flight-tracker-map-airport-icon',
      html: `
        <div class="airport-conditions-map-airport-icon-top airport-conditions-map-airport-icon-standard-color">
          <div class="inner">
            <img src="${this.context.images.airportInfoIcon}" alt='icon' />
          </div>
        </div>
        <div class="airport-conditions-map-airport-icon-bottom" />
        `,
    });

    this.marker = marker([props.latitude, props.longitude], {
      icon,
    });
    this.marker.bindPopup(this.popUpContent(props), {
      offset: point(112, -11),
      className: 'airport-conditions-map-airport-popup',
      closeOnClick: false,
    });

    this.marker.addTo(this.map);
    this.marker.openPopup();
  }


  render() {
    return (<div />);
  }
}
