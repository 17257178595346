import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { scroller, animateScroll } from 'react-scroll';
import { path, pathOr } from 'ramda';
import moment from 'moment';
import { actions as reduxActions } from '../../../redux';
import FSFlatButton from '../../../../../src/components/lib/FSFlatButton';
import componentBase from '../../../../../src/lib/component-base';
import ChoiceAirlineAirport from '../../shared/ChoiceAirlineAirport';
import ToggleSwitches from '../../shared/ToggleSwitches';
import AccountAlertPreferences from '../../shared/AccountAlertPreferences';
import UnitsOfMeasurementPreferences from '../../shared/UnitsOfMeasurementPreferences';
import AccountError from './children/AccountError';
import ChangePassword from './children/ChangePassword';
import DeleteAccount from './children/DeleteAccount';
import PreferenceHeader from './children/PreferenceHeader';
import LargeGenericModal from '../../../../../shared/components/LargeGenericModal/LargeGenericModal';
import { Colors } from '../../../../../src/constants/style-constants';
import { dateFormatString } from '../../../../../src/lib/date-time-format';

import emailIcon from '../../../../../static/images/account-icons/Email-Black.svg';
import emailListIcon from '../../../../../static/images/account-icons/EmailList-BLACK.svg';
import favoritesIcon from '../../../../../static/images/account-icons/Favorites-BLACK.svg';
import unitsIcon from '../../../../../static/images/account-icons/Units-BLACK.svg';
import togglesIcon from '../../../../../static/images/account-icons/Toggles-BLACK.svg';
import alertsIcon from '../../../../../static/images/menu-icons/FlightAlert-Icons-BLACK.svg';
import privacyIcon from '../../../../../static/images/account-icons/Privacy-BLACK.svg';
import deleteAccountIcon from '../../../../../static/images/account-icons/DeleteAccount-BLACK.svg';
import passwordIcon from '../../../../../static/images/account-icons/Password-BLACK.svg';
import { updateMultiFlightSearchCodeShareToggle, updatePageIndex } from '../../../../MultiFlight/redux/actions';

const SCROLL_OPTIONS = {
  duration: 500,
  delay: 0,
  smooth: true,
};

@connect(
  state => ({
    changePasswordDialogOpen: state.Account.changePasswordDialogOpen,
    deletedAccount: state.Account.deletedAccount,
    deleteAccountCheckboxChecked: state.Account.deleteAccountCheckboxChecked,
    deleteAccountDialogOpen: state.Account.deleteAccountDialogOpen,
    deleteAccountError: state.Account.deleteAccountError,
    deletingAccount: state.Account.deletingAccount,
    deleteAccountSetCheckbox: state.Account.deleteAccountSetCheckbox,
    reasonForUnsubscribing: state.Account.reasonForUnsubscribing,
    updateErrors: state.Account.updateProfileErrors || state.Account.changePasswordErrors,
    updateReasonForUnsubscribing: state.Account.updateReasonForUnsubscribing,
    user: state.Account.user,
  }),
  reduxActions,
)
@componentBase('Profile')
export default class Profile extends Component {
  static propTypes = {
    changePassword: PropTypes.func,
    changePasswordDialogOpen: PropTypes.bool,
    deleteAccount: PropTypes.func,
    deleteAccountCheckboxChecked: PropTypes.bool,
    deleteAccountDialogOpen: PropTypes.bool,
    deleteAccountError: PropTypes.string,
    deleteAccountSetCheckbox: PropTypes.func,
    deletedAccount: PropTypes.bool,
    deletingAccount: PropTypes.bool,
    dispatch: PropTypes.func,
    getProfile: PropTypes.func,
    reasonForUnsubscribing: PropTypes.string,
    resetAuthState: PropTypes.func,
    toggleAccountProfileDialogs: PropTypes.func,
    updateProfile: PropTypes.func,
    updateErrors: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
    ]),
    updateReasonForUnsubscribing: PropTypes.func,
    user: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);

    this.errorDisplayWording = {
      MISSING_EMAIL: 'An email address is required',
      INVALID_EMAIL: 'The email address provided is invalid',
      EMAIL_IN_USE: 'The email address provided is already in use',
      UNKNOWN_ERROR: 'Error',
    };

    this.handleDeleteAccount = this.handleDeleteAccount.bind(this);
    this.handleSavePassword = this.handleSavePassword.bind(this);
    this.handleGeneralError = this.handleGeneralError.bind(this);
    this.handleUpdatedSuccess = this.handleUpdatedSuccess.bind(this);
    this.handleDeleteAccountError = this.handleDeleteAccountError.bind(this);
    this.handleUpdateReasonForUnsub = this.handleUpdateReasonForUnsub.bind(this);

    // refs
    this.alertPreferences = null;
    this.changePassword = null;
    this.choiceAirlineAirport = null;
    this.deleteAccount = null;
    this.newEmail = null;
    this.optInB2BInfo = null;
    this.optInCustomerInfo = null;
    this.toggleSwitches = null;
    this.unitsOfMeasurementPreferences = null;

    this.state = {
      changePasswordButtonDisabled: true,
    };
  }

  componentDidMount() {
    this.props.getProfile();
  }

  componentWillReceiveProps(nextProps) {
    const {
      deleteAccountError,
    } = nextProps;
    const nextPropCallbacks = {
      deleteAccountError: this.handleDeleteAccountError,
    };
    // find the first prop that returns true that needs a related callback action
    const actions = {
      deleteAccountError,
    };
    const action = Object.keys(actions)
      .find(key => actions[key]);
    if (action) {
      nextPropCallbacks[action]();
    }
  }

  getChildRefs = () => [
    'alertPreferences',
    'unitsOfMeasurementPreferences',
    'toggleSwitches',
    'choiceAirlineAirport',
    'optInB2BInfo',
    'optInCustomerInfo',
  ].map(k => this.getDeepKeys(k, this))

  getAlertPrefs = prefs => [
    'mobilePhoneNumber',
    'flightMonitorEmail',
    'flightMonitorWirelessEnabled',
    'flightMonitorEmailEnabled']
    .map(k => this.getDeepKeys(k, prefs))

  getToggles = toggles => [
    'dateFormat',
    'timeFormat',
    'hideCodeshares']
    .map(k => this.getDeepKeys(k, toggles));

  getDeepKeys = (key, obj, alt = {}) => pathOr(alt, [key], obj)

  getFormData = () => {
    const { user } = this.props;
    const email = pathOr('', ['user', 'email'], this.props);
    const [
      alertPreferences,
      unitsOfMeasurementPreferences,
      toggleSwitches,
      choiceAirlineAirport,
      optInB2BInfo,
      optInCustomerInfo,
    ] = this.getChildRefs();
    const [
      mobilePhoneNumber,
      flightMonitorEmail,
      flightMonitorWirelessEnabled,
      flightMonitorEmailEnabled] = this.getAlertPrefs(alertPreferences);
    const [
      dateFormat,
      timeFormat,
      hideCodeshares,
    ] = this.getToggles(toggleSwitches);
    const measurementPrefs = pathOr({}, ['state'], unitsOfMeasurementPreferences);
    const airlineAirportState = pathOr({}, ['state'], choiceAirlineAirport);
    return {
      email, // unique user identifier
      newEmail: pathOr(email, ['value'], this.newEmail),
      currentFlightMonitorWirelessEnabled: user && user.flightMonitorWirelessEnabled,
      profile: {
        flightMonitorWirelessNumber0: mobilePhoneNumber.value,
        flightMonitorWirelessEnabled: flightMonitorWirelessEnabled.checked,
        flightMonitorEmailAddress0: flightMonitorEmail.value,
        flightMonitorEmailEnabled: flightMonitorEmailEnabled.checked,
        flightMonitorWirelessServiceCode0: path(['flightMonitorWirelessService'], alertPreferences),
        unitsOfMeasurementDistance: measurementPrefs.unitsOfMeasurementDistance,
        unitsOfMeasurementTemperature: measurementPrefs.unitsOfMeasurementTemperature,
        unitsOfMeasurementSpeed: measurementPrefs.unitsOfMeasurementSpeed,
        dateFormat: dateFormat.value,
        timeFormat: timeFormat.value,
        hideCodeshares: hideCodeshares.checked,
        departureAirportCode: airlineAirportState.departureAirportCode,
        airlineCode: airlineAirportState.airlineCode,
        optInCustomerInfo: optInCustomerInfo.checked,
        optInB2BInfo: optInB2BInfo.checked,
      },
    };
  }

  getRenewalDate = () => {
    const { user } = this.props;
    if (user) {
      const { subscriptionRenewedAt } = user;
      if (this.isSubscriptionActive() && subscriptionRenewedAt) {
        return moment.utc(subscriptionRenewedAt).add(1, 'months').format(dateFormatString(user));
      }
    }
    return '';
  }

  getMetaTags = () => {
    const title = 'FlightStats - My Account';
    return {
      title,
      meta: [{ name: 'robots', content: 'noindex' }],
    };
  };

  setRef = (ref, refName) => {
    this[refName] = ref;
  }

  scrollToError = (fieldName) => {
    try {
      scroller.scrollTo(`account-error-${fieldName}`, {
        duration: 500,
        delay: 100,
        smooth: true,
      });
    } catch (e) {
      console.log('error in scrollToError');
    }
  };

  errorClass = field => ((this.props.updateErrors && this.props.updateErrors[field]) ? 'red' : '');
  error = field => (this.props.updateErrors && this.props.updateErrors[field]);

  scrollToTop = () => {
    animateScroll.scrollToTop(SCROLL_OPTIONS);
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const userData = this.getFormData();
    if (userData) {
      const { toast } = this.context;
      const { updateProfile, dispatch } = this.props;
      try {
        const updateResult = await updateProfile(userData);
        const type = pathOr('', ['type'], updateResult);
        /**
         * this checks the action type so if
         * Account/UPDATE_PROFILE_SUCCESS or Account/UPDATE_PROFILE_FAIL change types,
         * please update the .includes
         */
        const success = type.toLowerCase().includes('success');
        const failure = type.toLowerCase().includes('fail');
        if (success) {
          this.handleUpdatedSuccess();
        } else if (failure) {
          this.handleGeneralError();
        }
        const { hideCodeshares } = userData.profile;
        dispatch(updateMultiFlightSearchCodeShareToggle(hideCodeshares));
        dispatch(updatePageIndex(1));
        this.context.reportButtonPress('Save Changes');
      } catch (e) {
        toast.error('Please try again.', 'Update not updated');
      }
    }
  };

  handleChangePasswordOpen = () => {
    this.props.toggleAccountProfileDialogs({ changePasswordDialogOpen: true });
    this.context.reportButtonPress('Change Password');
  }

  handleChangePasswordClose = () => {
    this.props.resetAuthState();
    this.props.toggleAccountProfileDialogs({ changePasswordDialogOpen: false });
  }

  handleSavePassword = () => {
    const r = this.changePassword;
    const data = {
      oldPassword: r.oldPassword.value,
      newPassword: r.newPassword.value,
      passwordConfirm: r.passwordConfirm.value,
    };

    this.props.changePassword(data);
    this.context.reportButtonPress('Save New Password');
  }

  handleGeneralError = () => {
    const { resetAuthState, updateErrors } = this.props;
    const { toast } = this.context;
    if (updateErrors) {
      const errorKeys = Object.keys(updateErrors);
      toast.error('Your changes were not saved', 'Error', false, 3);
      this.scrollToError(errorKeys[0]);
      resetAuthState();
    }
  }

  handleUpdatedSuccess = () => {
    const { toast } = this.context;
    const { resetAuthState } = this.props;
    resetAuthState();
    this.handleChangePasswordClose();
    toast.success('Your profile was updated', 'Success');
    this.scrollToTop();
  }

  handleDeleteAccountOpen = (e) => {
    e && e.preventDefault();
    this.props.toggleAccountProfileDialogs({ deleteAccountDialogOpen: true });
    this.context.reportButtonPress('Open Delete Account');
  }

  handleDeleteAccountError = () => {
    const { resetAuthState } = this.props;
    const { toast } = this.context;
    resetAuthState();
    toast.error('Please try again.', 'Account not deleted');
  }

  handleDeleteAccountClose = () => {
    this.props.deleteAccountSetCheckbox(false);
    this.props.toggleAccountProfileDialogs({ deleteAccountDialogOpen: false });
  }

  handleDeleteAccount = () => {
    if (this.props.deleteAccountCheckboxChecked) {
      const {
        deleteAccount,
        reasonForUnsubscribing,
      } = this.props;

      deleteAccount(null, reasonForUnsubscribing);
    }
  }

  handleUpdateReasonForUnsub = (reason) => {
    this.props.updateReasonForUnsubscribing(reason);
  }

  isSubscriptionActive = () => this.props.user && this.props.user.subscriptionActive;

  render() {
    const renewalDate = this.getRenewalDate();
    const {
      deleteAccountDialogOpen,
      deletedAccount,
      deletingAccount,
      resetAuthState,
      updateErrors,
      user,
    } = this.props;
    if (!user && !deletingAccount && !deletedAccount) {
      // returning noscript ruins the rerender
      // the template element is the html5 semantic preferred replacement
      return <template />;
    }

    const u = user || {}; // les Z
    let goPremium;

    if (u && !u.subscriptionActive) {
      goPremium = (
        <section className='premium-banner row orange-bg' style={{ textAlign: 'center' }}>
          <div className='banner-left'>
            <p className='premium-banner-text'>UPGRADE TODAY!</p>
          </div>
          <div className='banner-right row'>
            <div className='banner-arrows col-xs-5 col-sm-8'>
              <div className='arrow-body'>
                <div className='arrow-right' />
              </div>
              <div className='arrow-body'>
                <div className='arrow-right' />
              </div>
              <div className='arrow-body'>
                <div className='arrow-right' />
              </div>
              <div className='arrow-body'>
                <div className='arrow-right' />
              </div>
            </div>
            <div className='xs-hidden col-sm-1' />
            <div className='banner-button col-xs-7 col-sm-3'>
              <FSFlatButton
                className='premium-btn'
                label='SUBSCRIBE!'
                backgroundColor={Colors.blue6}
                hoverColor={Colors.blue8}
                style={FSFlatButton.style({ height: '40px', fontSize: '25px', fontWeight: '600' })}
                onClick={(e) => {
                  e.preventDefault();
                  this.context.goto('/subscription', 'GoPremium');
                }}
              />
            </div>
          </div>
        </section>
      );
    }

    return (
      <div>
        <form method='POST'>
          {goPremium}
          <section className='grey-bg'>
            <div className='row'>
              <div className='col-xs-12 col-md-6 account-preference'>
                <PreferenceHeader title='Email Address' icon={emailIcon} />
                <h3>Email</h3>
                <div className='col-xs-12 col-sm-11'>
                  <AccountError name='email' errorCode={this.error('email')} />
                  <input
                    type='text'
                    ref={ref => this.setRef(ref, 'newEmail')}
                    name='newEmail'
                    placeholder='name@domain.com'
                    defaultValue={u.email}
                    className={`account-input padded ${this.errorClass('email')}`}
                  />
                </div>
                <FSFlatButton
                  label='Change Password'
                  onClick={this.handleChangePasswordOpen}
                  style={FSFlatButton.style({ margin: '10px 0', padding: '0 50px', minWidth: null })}
                />
                <LargeGenericModal
                  modalOpen={this.props.changePasswordDialogOpen}
                  closeModal={this.handleChangePasswordClose}
                  disablePrimaryButton={!this.state.changePasswordButtonDisabled}
                  title='Change Password'
                  image={passwordIcon}
                  actionTitle='Save New Password'
                  selectAction={this.handleSavePassword}
                  secondaryActionBackgroundColor='transparent'
                  secondaryActionHoverColor='#c6c6c6'
                  body={
                    <ChangePassword
                      ref={ref => this.setRef(ref, 'changePassword')}
                      errors={this.props.updateErrors || {}}
                      setChangePasswordButtonDisabled={(bool) => {
                        this.setState({ changePasswordButtonDisabled: bool });
                      }}
                    />
                  }
                />
              </div>
              <div className='col-xs-12 col-md-6 account-preference email-list-preference'>
                <PreferenceHeader title='FlightStats Email Lists' icon={emailListIcon} />
                <p>
                  <input
                    type='checkbox'
                    ref={ref => this.setRef(ref, 'optInCustomerInfo')}
                    name='optInCustomerInfo'
                    className='padded right-spaced checkbox'
                    defaultChecked={u.optInCustomerInfo}
                  />
                  <label
                    htmlFor='optInCustomerInfo'
                    className='email-me enhancements'
                  >
                    Email me about enhancements &amp; new features to FlightStats.com
                  </label>
                </p>
                <p>
                  <input
                    type='checkbox'
                    ref={ref => this.setRef(ref, 'optInB2BInfo')}
                    name='optInB2BInfo'
                    className='padded right-spaced checkbox'
                    defaultChecked={u.optInB2BInfo}
                  />
                  <label
                    htmlFor='optInB2BInfo'
                    className='email-me products-and-services'
                  >
                    Email me about new B2B products and services
                  </label>
                </p>
              </div>
            </div>
          </section>
          <section className='grey-bg'>
            <div className='row'>
              <div className='col-xs-12 col-md-6 account-preference'>
                <PreferenceHeader title='Your Favorites' icon={favoritesIcon} />
                <div className='col-xs-12 col-sm-11'>
                  <ChoiceAirlineAirport
                    ref={ref => this.setRef(ref, 'choiceAirlineAirport')}
                    user={u}
                  />
                </div>
              </div>
              <div className='col-xs-12 col-md-6 account-preference'>
                <PreferenceHeader title='Units of Measurement' icon={unitsIcon} />
                <UnitsOfMeasurementPreferences
                  user={u}
                  errors={updateErrors}
                  resetAuthState={resetAuthState}
                  isMyAccountPage
                  ref={ref => this.setRef(ref, 'unitsOfMeasurementPreferences')}
                />
              </div>
            </div>
          </section>
          <section className='grey-bg'>
            <div className='row'>
              <div className='col-xs-12 col-md-6 account-preference'>
                <PreferenceHeader title='Alert Preferences' icon={alertsIcon} />
                <AccountAlertPreferences
                  user={u}
                  errors={updateErrors}
                  resetAuthState={resetAuthState}
                  isMyAccountPage
                  ref={ref => this.setRef(ref, 'alertPreferences')}
                />
              </div>
              <div className='col-xs-12 col-md-6 account-preference'>
                <PreferenceHeader title='Function Preferences' icon={togglesIcon} />
                <ToggleSwitches
                  ref={ref => this.setRef(ref, 'toggleSwitches')}
                  user={u}
                />
              </div>
            </div>
            <div className='row'>
              <div className='center-align'>
                <FSFlatButton
                  label='Save Changes'
                  onClick={this.handleSubmit}
                  style={FSFlatButton.style({ margin: '30px 0', padding: '0 50px', minWidth: null })}
                />
              </div>
            </div>
          </section>
          <section className='darkgrey-bg'>
            <PreferenceHeader title='Your Privacy' icon={privacyIcon} />
            <div className='row'>
              <div className='col-xs-12'>
                <p style={{ margin: '0 0 16px' }}>We respect your privacy and adhere to the following principles:</p>
                <ul>
                  <li>
                    <p>
                      {`We do not sell, share, or rent your personal information to any
                       3rd party for sales or marketing purposes unless you opt-in to receive email.`}
                    </p>
                  </li>
                  <li>
                    <p>
                      {`We only display personal information in your profile
                       to other users if you indicate you want it shared.`}
                    </p>
                  </li>
                  <li>
                    <p>
                      {`Email and password information are secured
                        using industry standard encryption techniques.`}
                    </p>
                  </li>
                </ul>
                <p>
                  For additional information, refer to our
                  <a
                    href='https://risk.lexisnexis.com/group/privacy-policy'
                    className='account-link'
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{ margin: '5px 3px', textDecoration: 'underline' }}
                  >
                    Privacy Policy
                  </a>.
                </p>
                <div>
                  <p>To delete your account,&nbsp;
                    <a
                      href=''
                      role='button'
                      target='_self'
                      className='delete-account-link'
                      style={{
                        textDecoration: 'underline',
                        color: '#444',
                        cursor: 'pointer',
                      }}

                      onClick={e => this.handleDeleteAccountOpen(e)}
                    >click here
                    </a>. Please note that this action cannot be undone.
                  </p>
                  <LargeGenericModal
                    modalOpen={deleteAccountDialogOpen}
                    closeModal={() => this.handleDeleteAccountClose()}
                    disableButtons={false}
                    disablePrimaryButton={!this.props.deleteAccountCheckboxChecked}
                    title='Delete Your Account'
                    image={deleteAccountIcon}
                    actionTitle='Delete Account'
                    selectAction={this.handleDeleteAccount}
                    secondaryActionBackgroundColor='transparent'
                    secondaryActionHoverColor='#c6c6c6'
                    body={
                      <DeleteAccount
                        ref={ref => this.setRef(ref, 'deleteAccount')}
                        reason={this.props.reasonForUnsubscribing}
                        renewalDate={renewalDate}
                        showTextarea={this.isSubscriptionActive()}
                        updateReasonForUnsubscribing={this.handleUpdateReasonForUnsub}
                        deleteAccountCheckboxChecked={this.props.deleteAccountCheckboxChecked}
                        deleteAccountSetCheckbox={this.props.deleteAccountSetCheckbox}
                      />
                    }
                  />
                </div>
              </div>
              <div className='col-xs-2' />
            </div>
          </section>
          <section className='grey-bg section-footer' />
        </form>
      </div>
    );
  }
}
