import moment from 'moment';
import * as t from './actionTypes';

const startSpinner = () => ({
  type: t.START_SPINNER,
});

const endSpinner = () => ({
  type: t.END_SPINNER,
});

const loadingByDepartureAirport = () => ({
  type: t.LOAD_BY_DEPARTURE_AIRPORT,
});

const loadByDepartureAirportSuccess = (result, parameters) => ({
  type: t.LOAD_BY_DEPARTURE_AIRPORT_SUCCESS,
  data: {
    result,
    parameters,
  },
});

const loadByDepartureAirportFail = () => ({
  type: t.LOAD_BY_DEPARTURE_AIRPORT_FAIL,
});

const checkIstanbul = (code) => {
  if (code === 'ISL') {
    return 'IST';
  } else if (code === 'IST') {
    return 'ISL';
  }
  return code;
};

export const loadByDepartingAirportDateAndOptionalCarrier =
  ({
    departureAirportCode, year, month, day, carrier,
  }) => {
    let y = year;
    let m = month;
    let d = day;

    if (!year || !month || !day) {
      const now = moment();
      y = now.year();
      m = now.month() + 1;
      d = now.date();
    }

    let url = `/historical-flights/dep/${checkIstanbul(departureAirportCode)}/${y}/${m}/${d}`;
    if (carrier) {
      url = `${url}?carrier=${carrier.toUpperCase()}`;
    }

    const params = {
      departureAirportCode,
      year: y,
      month: m,
      day: d,
      carrier,
    };

    return async (dispatch, getState, client) => {
      dispatch(startSpinner());
      dispatch(loadingByDepartureAirport());
      try {
        const result = await client.get(url);
        dispatch(loadByDepartureAirportSuccess(result, params));
        dispatch(endSpinner());
      } catch (err) {
        dispatch(loadByDepartureAirportFail(err));
        dispatch(endSpinner());
      }
    };
  };

const loadingByArrivalAirport = () => ({
  type: t.LOAD_BY_ARRIVAL_AIRPORT,
});

const loadByArrivalAirportSuccess = (result, parameters) => ({
  type: t.LOAD_BY_ARRIVAL_AIRPORT_SUCCESS,
  data: {
    result,
    parameters,
  },
});

const loadByArrivalAirportFail = error => ({
  type: t.LOAD_BY_ARRIVAL_AIRPORT_FAIL,
  error,
});

export const loadByArrivingAirportDateAndOptionalCarrier =
  ({
    arrivalAirportCode, year, month, day, carrier,
  }) => {
    let y = year;
    let m = month;
    let d = day;

    if (!year || !month || !day) {
      const now = moment();
      y = now.year();
      m = now.month() + 1;
      d = now.date();
    }

    let url = `/historical-flights/arr/${checkIstanbul(arrivalAirportCode)}/${y}/${m}/${d}`;
    if (carrier) {
      url = `${url}?carrier=${carrier.toUpperCase()}`;
    }

    const params = {
      arrivalAirportCode,
      year: y,
      month: m,
      day: d,
      carrier,
    };

    return async (dispatch, getState, client) => {
      dispatch(startSpinner());
      dispatch(loadingByArrivalAirport());
      try {
        const result = await client.get(url);
        dispatch(loadByArrivalAirportSuccess(result, params));
        dispatch(endSpinner());
      } catch (err) {
        dispatch(loadByArrivalAirportFail(err));
        dispatch(endSpinner());
      }
    };
  };

const loadingByRoute = () => ({
  type: t.LOAD_BY_ROUTE,
});

const loadByRouteSuccess = (result, parameters) => ({
  type: t.LOAD_BY_ROUTE_SUCCESS,
  data: {
    result,
    parameters,
  },
});

const loadByRouteFail = error => ({
  type: t.LOAD_BY_ROUTE_FAIL,
  error,
});

export const loadByRouteDateAndOptionalCarrier =
  ({
    departureAirportCode, arrivalAirportCode, year, month, day, carrier,
  }) => {
    let y = year;
    let m = month;
    let d = day;

    if (!year || !month || !day) {
      const now = moment();
      y = now.year();
      m = now.month() + 1;
      d = now.date();
    }

    let url = `/historical-flights/route/${checkIstanbul(departureAirportCode)}/${checkIstanbul(arrivalAirportCode)}/${y}/${m}/${d}`;
    if (carrier) {
      url = `${url}?carrier=${carrier.toUpperCase()}`;
    }

    const params = {
      departureAirportCode,
      arrivalAirportCode,
      year: y,
      month: m,
      day: d,
      carrier,
    };

    return async (dispatch, getState, client) => {
      dispatch(startSpinner());
      dispatch(loadingByRoute());
      try {
        const result = await client.get(url);
        dispatch(loadByRouteSuccess(result, params));
        dispatch(endSpinner());
      } catch (err) {
        dispatch(loadByRouteFail(err));
        dispatch(endSpinner());
      }
    };
  };

export const updatePageIndex = (pageIndex, searchUrl) => ({
  type: t.UPDATE_PAGE_INDEX,
  data: {
    pageIndex,
    searchUrl,
  },
});

export const setHistoricalMultiErrorMessage = message => ({
  type: t.SET_HISTORICAL_MULTI_ERROR_MESSAGE,
  message,
});
