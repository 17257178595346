import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Switch } from 'react-router-dom';
import Helmet from 'react-helmet';
import RouteWrapper from '../../RouteWrapper';
import VerifyEmail from './VerifyEmail';
import componentBase from '../../../src/lib/component-base';
import { actions } from '../redux';

@connect(
  state => ({
    user: state.Account.user,
  }),
  actions,
)
@componentBase('AccountPanel')
export default class AccountPanel extends Component {
  static propTypes = {
    children: PropTypes.object,
    location: PropTypes.object,
  };

  getMetaTags = () => ({
    meta: [{ name: 'robots', content: 'noindex' }],
  })

  noAccountPanel = () => {
    const pathPrefixesWithoutPanel = [
      '/my-account/profile',
      '/my-account/payment',
      '/account/invoice',
    ];

    const { location = {} } = this.props;
    const { pathname = '' } = location;
    return !pathPrefixesWithoutPanel.find(prefix => pathname.startsWith(prefix));
  }

  render() {
    const meta = this.getMetaTags();
    const accountPanel = this.noAccountPanel() ? 'account-panel' : null;
    const accountStyle = this.noAccountPanel() ? { display: 'table', height: '100vh' } : {};
    const accountPanelWindow = this.noAccountPanel() ? <div className='account-panel-window' style={{ display: 'none' }} /> : null;

    return (
      <div className='account-panel-holder'>
        {accountPanelWindow}
        <div className={accountPanel} style={accountStyle}>
          <Helmet {...meta} />
          <Switch>
            <RouteWrapper
              path='/account/verify'
              name='VerifyEmail'
              component={VerifyEmail}
              onEnter={['requireLogin']}
            />
            <Redirect
              to='/account/login'
            />
          </Switch>
        </div>
        <div className='account-panel-clearfix' />
      </div>
    );
  }
}
