import { displayAccountAttentionDialog, shouldRefresh, redirectToOutagePage } from '../../../modules/Account/redux/actions';
import reportException from '../../../shared/lib/report-exception';

export default function clientResponseMiddleware() {
  return ({ dispatch }) =>
    next =>
      (action) => {
        if (action && action.error) {
          const { error } = action;
          const { status } = error;
          if (status === 440) {
            dispatch(shouldRefresh(true));
          } else if (status === 402) {
            dispatch(displayAccountAttentionDialog());
          } else if (status === 307 || status === 302) {
            console.log('\n\ngot redir status', error, '\n\n');
            dispatch(redirectToOutagePage());
          } else if (status >= 500) {
            reportException(error, {
              error,
              status,
            }, 'client-response-middleware-api');
          }
        }
        return next(action);
      };
}
