import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'react-leaflet';
import AirportMarker from './AirportMarker';
import MapLabelsLayer from '../../../../../../src/components/MapControlsAndLayers/LabelsTileLayer';
import MapSatLayer from '../../../../../../src/components/MapControlsAndLayers/SatTileLayer';
import MapZoomControl from '../../../../../../src/components/MapControlsAndLayers/ZoomControl';
import componentBase from '../../../../../../src/lib/component-base';

@componentBase('AirportMap')
export default class AirportMap extends Component {
  static propTypes = {
    airportCode: PropTypes.string,
    airportName: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    subscriptionActive: PropTypes.bool,
  };

  render() {
    const position = [this.props.latitude, this.props.longitude];

    return (
      <Map
        zoomControl={false}
        center={position}
        zoom={7}
        worldCopyJump={false}
        minZoom={3}
        maxZoom={7}
        scrollWheelZoom={false}
        className='AirportMap'
        style={this.props.subscriptionActive ? { width: '100%' } : {}}
      >

        <MapLabelsLayer hide />
        <MapSatLayer />
        <MapZoomControl />
        <AirportMarker {...this.props} />
      </Map>
    );
  }
}
