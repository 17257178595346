import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SelectField } from '@flightstats/component-lib';
import componentBase from '../../../../src/lib/component-base';
import { distanceOptions, speedOptions, temperatureOptions } from '../../../../src/lib/unit-measurement-format';

@componentBase('UnitsOfMeasurementPreferences')
export default class UnitsOfMeasurementPreferences extends Component {
  static propTypes = {
    user: PropTypes.object,
  };

  static defaultProps = {
    isMyAccountPage: false,
    errors: {},
    user: {
      unitsOfMeasurementDistance: 0,
      unitsOfMeasurementTemperature: 0,
      unitsOfMeasurementSpeed: 0,
    },
  };

  state = {
    unitsOfMeasurementDistance: this.props.user.unitsOfMeasurementDistance || 0,
    unitsOfMeasurementTemperature: this.props.user.unitsOfMeasurementTemperature || 0,
    unitsOfMeasurementSpeed: this.props.user.unitsOfMeasurementSpeed || 0,
  };

  errorClass = shouldShow => (shouldShow ? 'error' : '');

  handleDistanceChange = (option) => {
    this.setState({ unitsOfMeasurementDistance: option.value });
  }

  handleTemperatureChange = (option) => {
    this.setState({ unitsOfMeasurementTemperature: option && option.value });
  }

  handleSpeedChange = (option) => {
    this.setState({ unitsOfMeasurementSpeed: option && option.value });
  }

  render() {
    return (
      <div>

        <div className='row'>
          <div className='col-xs-12 col-sm-11'>
            <h3 className='account-input-title'>Distance</h3>
            <SelectField
              handleMenuSelection={this.handleDistanceChange}
              results={distanceOptions}
              clearable={false}
              searchable={false}
              value={this.state.unitsOfMeasurementDistance}
            />
          </div>
        </div>

        <div className='row spaced'>
          <div className='col-xs-12 col-sm-11'>
            <h3 className='account-input-title'>Temperature</h3>
            <SelectField
              handleMenuSelection={this.handleTemperatureChange}
              results={temperatureOptions}
              clearable={false}
              searchable={false}
              value={this.state.unitsOfMeasurementTemperature}
            />
          </div>
        </div>

        <div className='row spaced'>
          <div className='col-xs-12 col-sm-11'>
            <h3 className='account-input-title'>Speed</h3>
            <SelectField
              handleMenuSelection={this.handleSpeedChange}
              results={speedOptions}
              clearable={false}
              searchable={false}
              value={this.state.unitsOfMeasurementSpeed}
            />
          </div>
        </div>
      </div>
    );
  }
}
