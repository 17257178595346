import React from 'react';
import PropTypes from 'prop-types';

const errorRed = '#E34622';

const defaultLabelBaseStyle = {
  color: 'black',
  padding: 0,
  margin: 0,
  display: 'block',
};

const defaultLabelErrorStyle = {
  display: 'inline-block',
  backgroundColor: errorRed,
  boxSizing: 'border-box',
  padding: '5px',
  position: 'relative',
  top: '12px',
  borderRadius: '4px',
  color: 'white',
};

const defaultSpanBaseStyle = {
  display: 'inline-block',
  position: 'relative',
  color: 'black',
};

const defaultSpanErrorStyle = {
  minHeight: '20px',
  color: 'white',
  top: 0,
};

const FormLabel = (props) => {
  let labelStyle = {
    ...defaultLabelBaseStyle,
    ...props.labelStyle,
  };

  let spanStyle = {
    ...defaultSpanBaseStyle,
    ...props.spanStyle,
  };

  if (props.errorMessage) {
    labelStyle = {
      ...labelStyle,
      ...defaultLabelErrorStyle,
      ...props.labelErrorStyle,
    };

    spanStyle = {
      ...spanStyle,
      ...defaultSpanErrorStyle,
      ...props.spanErrorStyle,
    };
  }

  return (
    <div>
      <label
        htmlFor={props.name}
        style={labelStyle}
        className={props.errorMessage ? 'error' : ''}
      >
        <span style={spanStyle}>{props.errorMessage || props.text}</span>
      </label>
    </div>
  );
};

FormLabel.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  name: PropTypes.string,
  labelStyle: PropTypes.object,
  spanStyle: PropTypes.object,
  spanErrorStyle: PropTypes.object,
  labelErrorStyle: PropTypes.object,
  errorMessage: PropTypes.string,
};

export default FormLabel;
export {
  errorRed,
};
