import React, { Fragment, forwardRef, useState } from 'react';
import ReCAPTCHA0 from 'react-google-recaptcha';
import LoadScript from '../LoadScript/LoadScript';

// inspired by https://github.com/dozoisch/react-google-recaptcha/issues/76#issuecomment-386977752
const GoogleRecaptcha = forwardRef((props, ref) => {
  const [grecaptcha, setGrecaptcha] = useState(null);

  return (
    <Fragment>
      <LoadScript
        url='https://www.google.com/recaptcha/api.js'
        onCreate={() => {
            window.grecaptcha.ready(() => {
                setGrecaptcha(window.grecaptcha);
            });
        }}
      />
      {grecaptcha ? (
        <ReCAPTCHA0 {...props} ref={ref} grecaptcha={grecaptcha} />
      ) : null}
    </Fragment>
  );
});

export default GoogleRecaptcha;
