import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { Colors, Spacing } from '../constants/style-constants';

const FlightStatsTheme = {
  spacing: Spacing,
  fontFamily: 'proxima-nova, sans-serif',
  palette: {
    primaryColor: Colors.blue1,
    primary1Color: Colors.blue1,
    primary2Color: Colors.blue4,
    primary3Color: Colors.blue7,
    secondaryColor: Colors.white,
    accent1Color: Colors.gray1,
    accent2Color: Colors.gray3,
    accent3Color: Colors.gray7,
    primaryTextColor: Colors.black,
    alternateTextColor: Colors.white,
    textColor: Colors.white,
    canvasColor: Colors.white,
    borderColor: Colors.white,
    disabledColor: Colors.gray5,
    orange: Colors.orange1,
    primaryRaisedButtonNormal: Colors.blue4,
    primaryRaisedButtonHover: Colors.blue6,
    primaryRaisedButtonSelected: Colors.blue7,
    secondaryRaisedButtonNormal: Colors.white,
    secondaryRaisedButtonHover: Colors.blue7,
    secondaryRaisedButtonSelected: Colors.blue6,
    vipRaisedButtonNormal: Colors.orange1,
  },
  menu: {
    containerBackgroundColor: 'transparent',
  },
};

const Theme = getMuiTheme(FlightStatsTheme, { userAgent: 'all' });

Theme.tabs.backgroundColor = Colors.blue4;
Theme.raisedButton.secondaryColor = Colors.blue4;
Theme.textField.focusColor = Colors.gray7;
Theme.flatButton.primaryTextColor = Colors.white;

export default Theme;
