import { NAME } from './constants';

export const LOAD_BY_ID = `${NAME}/LOAD_BY_ID`;
export const LOAD_BY_ID_SUCCESS = `${NAME}/LOAD_BY_ID_SUCCESS`;
export const LOAD_BY_ID_FAIL = `${NAME}/LOAD_BY_ID_FAIL`;
export const LOAD_SEARCH = `${NAME}/LOAD_SEARCH`;
export const LOAD_SEARCH_SUCCESS = `${NAME}/LOAD_SEARCH_SUCCESS`;
export const LOAD_SEARCH_FAIL = `${NAME}/LOAD_SEARCH_FAIL`;
export const USING_SINGLE_CREDIT = `${NAME}/USING_SINGLE_CREDIT`;
export const USED_SINGLE_CREDIT = `${NAME}/USED_SINGLE_CREDIT`;
export const SET_HISTORICAL_SEARCH_INPUTS = `${NAME}/SET_HISTORICAL_SEARCH_INPUTS`;
export const HISTORICAL_BY_AIRPORT_SEARCH = `${NAME}/HISTORICAL_BY_AIRPORT_SEARCH`;
export const HISTORICAL_BY_FLIGHT_SEARCH = `${NAME}/HISTORICAL_BY_FLIGHT_SEARCH`;
export const HISTORICAL_BY_FLIGHT_NUMBER = `${NAME}/HISTORICAL_BY_FLIGHT_NUMBER`;
export const START_SPINNER = `${NAME}/START_SPINNER`;
export const END_SPINNER = `${NAME}/END_SPINNER`;
export const SET_BY_FLIGHT_SECONDARY_ACTION = `${NAME}/SET_FLIGHT_SECONDARY_ACTION`;
export const SET_BY_AIRLINE_SECONDARY_ACTION = `${NAME}/SET_AIRLINE_SECONDARY_ACTION`;
export const SET_HISTORICAL_SINGLE_ERROR_MESSAGE = `${NAME}/SET_HISTORICAL_SINGLE_ERROR_MESSAGE`;
