import React from 'react';
import FontIcon from 'material-ui/FontIcon';

const ActiveFrameIcon = () => (
  <FontIcon
    className='material-icons'
    color='rgba(255, 2, 0, 0.5)'
    hoverColor='rgb(255, 2, 0)'
    style={{ textAlign: 'center', display: 'inline', transition: 'all .4s linear' }}
  >
    &#xE3FA;
  </FontIcon>
);

export default ActiveFrameIcon;
