import React from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';
import { StarRating } from '@flightstats/component-lib';
import CommonSectionWrapper from '../../../../../shared/components/CommonSectionWrapper/CommonSectionWrapper';

const OtherStops = (props) => {
  const {
    adUnit,
    carrierCode,
    dispatch,
    flightNumber,
    stops,
    subscriptionActive,
  } = props;

  const titleCol = (title, mobileClass) => (
    <div className='stops-col'>
      <h3 className={`title ${mobileClass}`}>{title}</h3>
    </div>
  );

  const tableHeader = (
    <div className='stops-header stops-row stops-underline'>
      {titleCol('Origin', 'origin-title')}
      {titleCol('Destination', 'destination-title')}
      {titleCol('Rating', 'rating-title')}
    </div>
  );

  const airportCol = (airport, destination) => {
    const {
      fs,
      name,
      city,
      state,
      country,
    } = airport;

    return (
      <div className='stops-col'>
        <h4 className={`title ${destination ? 'destination-airport' : ''}`}>{`(${fs}) ${name}`}</h4>
        <h5 className={`subTitle ${destination ? 'destination-airport' : ''}`}>{`${city}${state ? `, ${state}` : ''}${country ? `, ${country}` : ''}`}</h5>
      </div>
    );
  };

  const tableRows = stops.map((stop, index) => {
    const {
      departureAirport,
      arrivalAirport,
      rating,
      appraisal,
      roundedStars,
    } = stop;
    const url = `/flight-ontime-performance-rating/${carrierCode}/${flightNumber}/${departureAirport.fs}/${arrivalAirport.fs}`;
    const underlineClass = index !== (stops.length - 1) ? 'stops-underline' : '';
    return (
      <Link
        to={url}
        key={url}
        onClick={(e) => {
          e.preventDefault();
          dispatch(push(url));
        }}
      >
        <div className={`stops-row content-row ${underlineClass}`}>
          {airportCol(departureAirport)}
          {airportCol(arrivalAirport, true)}
          <div className='stops-col star-col'>
            <div style={{ minWidth: '120px' }}>
              <StarRating
                height={35}
                rating={roundedStars}
              />
            </div>
            <span className='star-appraisal'>
              {`${rating}  ${appraisal}`}
            </span>
          </div>
        </div>
      </Link>
    );
  });

  return (
    <div className='OtherStopsContainer'>
      <CommonSectionWrapper
        adUnit={adUnit}
        subscriptionActive={subscriptionActive}
        title={`Other Stops for ${carrierCode} ${flightNumber}`}
      >
        <div className='stops-wrapper'>
          <div className='other-stops-table'>
            {tableHeader}
            {tableRows}
          </div>
        </div>
      </CommonSectionWrapper>
    </div>
  );
};

OtherStops.propTypes = {
  adUnit: PropTypes.object,
  carrierCode: PropTypes.string,
  dispatch: PropTypes.func,
  flightNumber: PropTypes.string,
  stops: PropTypes.array,
  subscriptionActive: PropTypes.bool,
};

export default OtherStops;
