import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { pathOr } from 'ramda';
import componentBase from '../../../src/lib/component-base';
import AirportDetailsHeader from './children/Header/AirportDetailsHeader';
import ConditionsTracker from './children/ConditionsTracker/AirportConditionsTracker';
import WeatherForecast from './children/WeatherForcast/AirportWeatherForecast';
import AirportCurrentConditionsSearch from './children/Search/AirportCurrentConditionsSearch';
import { NAME } from '../redux/constants';
import { shouldLoad as shouldLoadFn } from '../../../src/redux/utils';
import { clearError, setAirport, loadDetailsByCode, setSecondaryAction } from '../redux/actions';
import Meta from '../../../shared/components/Meta/container';
import NoResult from '../../../shared/components/NoResult/NoResult';
import { resolveCanonicalFromPathname } from '../../App/redux/selectors';
import { AirportCurrentConditions as adUnits } from './ads';


@connect(state => ({
  airport: state.AirportCurrentConditions.airport,
  canonical: resolveCanonicalFromPathname(state),
  detailsHeader: state.AirportCurrentConditions.detailsHeader,
  forecastWeather: state.AirportCurrentConditions.forecastWeather,
  delayIndex: state.AirportCurrentConditions.delayIndex,
  currentWeather: state.AirportCurrentConditions.currentWeather,
  airportCode: state.AirportCurrentConditions.airportCode,
  error: state.AirportCurrentConditions.error,
  loading: state.AirportCurrentConditions.loading,
  loaded: state.AirportCurrentConditions.loaded,
  shouldLoad: shouldLoadFn(state, NAME),
  airportSearchItem: state.AirportCurrentConditions.airportSearchItem,
  callSecondaryAction: state.AirportCurrentConditions.callSecondaryAction,
  user: state.Account.user,
}))
@componentBase('CurrentConditions')
class CurrentConditions extends Component {
  static propTypes = {
    airportSearchItem: PropTypes.object,
    canonical: PropTypes.string,
    detailsHeader: PropTypes.object,
    dispatch: PropTypes.func,
    error: PropTypes.string,
    airport: PropTypes.object,
    airportCode: PropTypes.string,
    location: PropTypes.object,
    loaded: PropTypes.bool,
    loading: PropTypes.bool,
    match: PropTypes.object,
    name: PropTypes.string,
    params: PropTypes.object,
    callSecondaryAction: PropTypes.bool,
    shouldLoad: PropTypes.bool,
    user: PropTypes.object,
  };

  componentWillMount() {
    const { shouldLoad, dispatch, match = {} } = this.props;
    const { params } = match;

    if (shouldLoad && params) {
      dispatch(loadDetailsByCode(params));
    }
  }

  componentDidMount() {
    const { dispatch, match, error } = this.props;

    if (!error) {
      dispatch(loadDetailsByCode(match.params));
    }
  }

  componentWillReceiveProps(nextProps) {
    const { pathname } = this.props.location;

    if (pathname !== nextProps.location.pathname) {
      this.props.dispatch(loadDetailsByCode(nextProps.match.params));
    }

    const { callSecondaryAction, dispatch } = nextProps;
    if (callSecondaryAction) {
      dispatch(setSecondaryAction(false));
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(clearError());
  }

  metaNameAndCity = (airportName, airportCity) => {
    if (airportName && airportCity) {
      if (airportName.includes(airportCity)) {
        return airportName;
      }
      return `${airportCity}, ${airportName}`;
    }
    return airportName;
  }

  formatMeta = () => {
    /* should have airportCode reliably, that is expected for these strings even
      when the airportCode does align with a real airport
    */
    const airportCode = pathOr('', ['match', 'params', 'airportCode'], this.props).toUpperCase();
    const [airportName, airportCity, state, country] = ['name', 'city', 'stateCode', 'countryCode']
      .map(s => pathOr('', ['detailsHeader', s], this.props));
    const metaCodeAndCond = (code, other) => `${code} ${other}`;
    const comma = str => (str ? ',' : '');
    const parenCode = c => (c ? `(${c})` : '');
    const keywords = `${metaCodeAndCond(airportCode, airportName)},${metaCodeAndCond(airportCode, airportCity)}${comma(airportCity)}${airportCode}`;
    const location = airportCity ? `located in ${airportCity}${state ? ` ${state}` : ''}${country ? `, ${country}` : ''}` : '';
    const airportNameAndCity = `${airportName} ${location}`;
    const description = `Current weather and airport delay conditions for ${parenCode(airportCode)} ${airportNameAndCity}`.trim();
    const formattedNameAndCity = this.metaNameAndCity(airportName, airportCity);
    const titleDesc = 'Current Weather and Airport Delay Conditions';
    const pageTitleDesc = 'Current Conditions';
    const title = (
      formattedNameAndCity ?
        `${airportCode} - ${formattedNameAndCity} ${titleDesc}` :
        `${airportCode} Airport ${titleDesc}`
    );
    const pageTitle = (
      formattedNameAndCity ?
        `${airportCode} - ${formattedNameAndCity} ${pageTitleDesc}` :
        `${airportCode} Airport ${pageTitleDesc}`
    );
    return {
      description,
      keywords,
      title,
      pageTitle,
    };
  }

  render() {
    const {
      airport,
      canonical,
      error,
      airportSearchItem,
      dispatch,
      callSecondaryAction,
      loading,
      loaded,
      user,
      match,
    } = this.props;
    const {
      description,
      keywords,
      title,
      pageTitle,
    } = this.formatMeta();
    const { isMobile } = pathOr(false, ['context', 'mediaBreakpoint'], this);

    const isInvalid = (!!error);
    const airportCodeParam = pathOr('N/A', ['params', 'airportCode'], match);
    const errorText = isInvalid ?
      `No match was found for airport code ${airportCodeParam}.` : null;

    return (
      <div style={{ width: '100%', padding: 0, margin: 0 }}>
        <Meta
          canonical={canonical}
          description={description}
          keywords={keywords}
          match={match}
          title={title}
          iOSDeepLink
        />
        <AirportCurrentConditionsSearch
          airport={airportSearchItem}
          dispatch={dispatch}
          isMobile={isMobile}
          onChange={(option, triggeredByEnter) => dispatch(setAirport(option, triggeredByEnter))}
          callSecondaryAction={callSecondaryAction}
        />
        {!loading && isInvalid && (
          <NoResult
            sectionTitle='Airport Conditions Not Available'
            primaryContent='SORRY, THIS AIRPORT COULD NOT BE LOCATED IN OUR SYSTEM'
            secondaryContent={errorText}
            adUnit={adUnits.mapTop}
            subscriptionActive={pathOr(false, ['subscriptionActive'], user)}
          />
        )}
        {loaded && !isInvalid && (
          <div>
            <AirportDetailsHeader {...airport} title={pageTitle} />
            <ConditionsTracker {...this.props} />
            <WeatherForecast {...this.props} />
          </div>
        )}
      </div>
    );
  }
}

export default CurrentConditions;
