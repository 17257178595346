import { parse } from 'qs';

export const combineParamsWithQuerystring = (props) => {
  const {
    location: { search: queryString },
    match,
  } = props;
  const { params } = match;
  const { year, month, date, flightId } = parse(queryString, { ignoreQueryPrefix: true });
  return {
    ...params,
    year,
    month,
    day: date,
    flightId,
  };
};
