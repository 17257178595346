import React from 'react';
import PropTypes from 'prop-types';

import ProgressIcon from './children/ProgressIcon';

import CheckClipboard from '../../../../../../static/images/CheckClipboard-WHITE.svg';
import TicketIconWHITE from '../../../../../../static/images/Ticket-Icon-WHITE.svg';
import ListIcon from '../../../../../../static/images/subscription-icons/List-Icon.svg';

const Progress = (props) => {
  const renderHighlightedLines = ({
    linePosition,
    registrationStage,
    billingInformationText,
    selectText,
    createText,
    completeText,
  }) => {
    if (linePosition === 'left') {
      return registrationStage === selectText;
    } else if (linePosition === 'right') {
      return registrationStage === completeText;
    }
    // else mid
    return (registrationStage === createText || registrationStage === billingInformationText);
  };
  const {
    billingInformationText,
    createText,
    completeText,
    registrationStage,
    selectText,
  } = props;
  const highlightLeft = renderHighlightedLines({
    linePosition: 'left',
    registrationStage,
    billingInformationText,
    selectText,
    createText,
    completeText,
  });
  const highlightMid = renderHighlightedLines({
    linePosition: 'mid',
    registrationStage,
    billingInformationText,
    selectText,
    createText,
    completeText,
  });
  const highlightRight = renderHighlightedLines({
    linePosition: 'right',
    registrationStage,
    billingInformationText,
    selectText,
    createText,
    completeText,
  });
  return (
    <div className='row'>
      <div className='col-xs-0 col-sm-2' />
      <div className='col-xs-12 col-sm-8 registration-progress'>
        <div className='col-xs-4'>
          <ProgressIcon
            className='left'
            imgAlt={selectText}
            imgSrc={ListIcon}
            registrationStage={selectText}
            text={selectText}
            highlighted={highlightLeft}
          />
        </div>
        <span
          className={`line mid-left${(highlightLeft || highlightMid) ? ' highlight' : ''}`}
        />
        <div className='col-xs-4'>
          <ProgressIcon
            className='middle'
            imgAlt={createText}
            imgSrc={CheckClipboard}
            registrationStage={createText}
            text={createText}
            highlighted={highlightMid}
          />
        </div>
        <span
          className={`line mid-right${(highlightRight || highlightMid) ? ' highlight' : ''}`}
        />
        <div className='col-xs-4'>
          <ProgressIcon
            className='right'
            imgAlt={completeText}
            imgSrc={TicketIconWHITE}
            registrationStage={completeText}
            text={completeText}
            highlighted={highlightRight}
          />
        </div>
      </div>
      <div className='col-xs-0 col-sm-2' />
    </div>
  );
};

Progress.propTypes = {
  billingInformationText: PropTypes.string,
  createText: PropTypes.string,
  completeText: PropTypes.string,
  registrationStage: PropTypes.string,
  selectText: PropTypes.string,
};

export default Progress;
