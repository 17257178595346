/* eslint-disable */

console.warn('WARNING: all component methods EXCEPT getters, setters, and those defined as fat-arrow functions are automatically wrapped in try/catches');

// const React = require('react');
import React from 'react';
import reportException from '../../shared/lib/report-exception';

/**
 * Configuration that allows overriding default behavior.
 * @type {Object}
 */
export const config = {
  /**
   * If enabled=false then this essentially disables the try/catch monkey-patch
   * @type {Boolean}
   */
  enabled: true,

  /**
   * Currently thif is configured to console.error a useful message if one of you
   * Component lifecycle methos throws an error. You can override the implementation
   * to integrate with you'r eown error logging.
   * @param  {[Object]} errorReport The report metadata including component, method, error thrown.
   * @return {[void]}
   */
  errorHandler: function errorHandler(errorReport) {
    console.error(`Error in ${errorReport.component}.${errorReport.method}(${errorReport.arguments ? '...' : ''}): ${errorReport.error}`, errorReport);
  },
};

/**
 * Implementation of the try/catch wrapper
 * @param
 * {[React.Component]} component The ES6 React.Component.prototype that contains the React lifecycle
 * method.
 * @param  {[string]} method The name of the method to wrap ex: "render"
 * @return {[React.Component]} Returns the same React.Component.prototype method monkey-patched with
 * the specified method wrapped with a try/catch
 */
const wrapWithTryCatch = function wrapWithTryCatch(component, method) {
  const descriptor = Object.getOwnPropertyDescriptor(component, method);

  if (descriptor.get || descriptor.set) return;

  const unsafe = component[method];

  component[method] = function (...args) {
    if (!config.enabled) {
      return;
    }
    try {
      return unsafe.apply(this, args);
    } catch (err) {
      const errorReport = {
        component: component.constructor.name,
        method,
        props: this.props,
        error: err,
      };
      if (args.length > 0) {
        errorReport.arguments = args;
      }
      reportException(err, errorReport, 'react-component-errors');
      const returnValue = config.errorHandler(errorReport);
      if (method === 'render') {
        return returnValue || React.createElement('h1', {}, 'error');
      }
    }
  };

  if (method === 'constructor') {
    console.log(component[method]);
  }
};

/**
 * Wraps each React.Component lifecycle method with a try/catch that enables easier development
 * diagnostics of errors throwin within each method
 * Methods wrapped include: `render, componentWillMount, componentDidMount,
 * componentWillReceiveProps, shouldComponentUpdate, componentWillUpdate, componentDidUpdate,
 * componentWillUnmount`
 * @param  {[React.Component]} ComponentConstructor The React.Component you want to wrap lifecycle
 * methods with a try/catch and error handler.
 * @return {[void]} [description]
 */
export const wrapReactLifecycleMethodsWithTryCatch =
  function wrapReactLifecycleMethodsWithTryCatch(ComponentConstructor) {
    Object.getOwnPropertyNames(ComponentConstructor.prototype)
    .filter(i => i !== 'constructor')
    .forEach((method) => {
      wrapWithTryCatch(ComponentConstructor.prototype, method);
    });
  };

export default wrapReactLifecycleMethodsWithTryCatch;
/* eslint-enable */
