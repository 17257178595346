import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getProfile,
  startSpinner,
  endSpinner,
  routerPush,
  upgradeSubscription,
} from '../../Account/redux/actions';

import UpgradeSubscriptionModal from '../../App/components/children/Modals/UpgradeSubscriptionModal';
import { hideModal } from '../redux/actions';

const UpgradeSubscriptionModalContainer = props => (
  <UpgradeSubscriptionModal
    closeModal={props.hideModal}
    endSpinner={props.endSpinner}
    getProfileReduxAction={props.getProfile}
    modalOpen
    routerPush={props.routerPush}
    startSpinner={props.startSpinner}
    upgradeReduxAction={props.upgradeSubscription}
  />
);

UpgradeSubscriptionModalContainer.propTypes = {
  hideModal: PropTypes.func,
  endSpinner: PropTypes.func,
  routerPush: PropTypes.func,
  startSpinner: PropTypes.func,
  upgradeSubscription: PropTypes.func,
  getProfile: PropTypes.func,
};

export default connect(() => ({}), {
  hideModal,
  getProfile,
  routerPush,
  startSpinner,
  endSpinner,
  upgradeSubscription,
})(UpgradeSubscriptionModalContainer);
