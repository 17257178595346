export default function (code) {
  const unknownErrorMessage = 'Sorry, something went wrong';
  const messages = {
    BAD_FLIGHT_NUMBER: 'Please enter a valid flight number',
    BAD_AIRLINE_CODE: 'Please enter a valid airline',
    BAD_AIRPORT_CODE: 'Please enter a valid airport',
    MISSING_FLIGHT_NUMBER: 'Please enter a flight number',
    MISSING_AIRPORT_CODE: 'Please enter an airport',
    MISSING_AIRLINE_CODE: 'Please enter an airline',
    MISSING_ARRIVAL_AIRPORT_OR_FLIGHT_NUMBER: 'Please enter arrival airport or flight number',
    MISSING_DEPARTURE_AIRPORT_OR_FLIGHT_NUMBER: 'Please enter departure airport or flight number',
    MISSING_FLIGHT_NUMBER_OR_ROUTE: 'Please enter flight number, departure airport, or arrival airport',
    MISSING_AIRLINE_AND_ARRIVAL_AIRPORT: 'Please enter airline and arrival airport',
    MISSING_AIRLINE_AND_DEPARTURE_AIRPORT: 'Please enter airline and departure airport',
    NO_FLIGHTS_FOUND: 'No flights found',
    FLIGHT_NOT_FOUND: 'Flight not found',
    NO_INFORMATION_ENTERED: 'Please enter a departure airport and/or arrival airport',
    HISTORICAL_FLIGHT_SEARCH_NO_INFORMATION_ENTERED: 'Please enter an airline and a flight number',
    HISTORICAL_AIRPORT_SEARCH_NO_INFORMATION_ENTERED: 'Please enter a departure airport and/or an arrival airport',
    BAD_DATE: 'No flights found',
    BAD_MOMENT: 'Please enter a valid date',
    UNIVERSAL_SEARCH_EMPTY: 'Please enter a flight number or an airline and departure airport',
    UNKNOWN_ERROR: unknownErrorMessage,
  };

  const message = messages[code];

  return message || unknownErrorMessage;
}
