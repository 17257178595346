import React from 'react';
import PropTypes from 'prop-types';

const UnsubscriptionTextarea = ({ value, updateValue }) => {
  const handleChange = (e) => {
    updateValue(e.target.value);
  };

  return (
    <textarea
      className='account text-area'
      onChange={handleChange}
      style={{
        boxSizing: 'border-box',
        height: '150px',
        width: '100%',
      }}
      placeholder='Optional'
      value={value}
    />
  );
};

UnsubscriptionTextarea.propTypes = {
  value: PropTypes.string,
  updateValue: PropTypes.func,
};

export default UnsubscriptionTextarea;
