import moment from 'moment';
import { path } from 'ramda';
import { NAME } from './constants';

export const isLoaded = globalState => (globalState[NAME] && globalState[NAME].loaded);

export const parseRouteParams = (router) => {
  const pathParts = router &&
  router.location &&
  router.location.pathname &&
  router.location.pathname.split('/');
  return {
    airportCode: pathParts[3],
    day: pathParts[7],
    departureArrival: pathParts[4],
    month: pathParts[6],
    year: pathParts[5],
  };
};

export const sameDate = (m1, m2) =>
  (m1.year() === m2.year() && m1.month() === m2.month() && m1.date() === m2.date());

export const getDefaultDateIndex = (router) => {
  const now = moment();
  const today = moment(new Date(now.year(), now.month(), now.date()).getTime());
  const yesterday = today.clone().subtract(1, 'day');
  const tomorrow = today.clone().add(1, 'day');

  const routeParams = parseRouteParams(router);
  const y = parseInt(routeParams.year || moment().year(), 10);
  const m = parseInt(routeParams.month || moment().month() + 1, 10) - 1;
  const d = parseInt(routeParams.day || moment().date(), 10);
  const preselectedDate = moment(new Date(y, m, d).getTime());
  let defaultDateIndex = 1;

  if (sameDate(preselectedDate, yesterday)) {
    defaultDateIndex = 0;
  } else if (sameDate(preselectedDate, tomorrow)) {
    defaultDateIndex = 2;
  }
  return defaultDateIndex;
};

export const isNoResults = (result) => {
  const errorCodes = {
    error: 'DATE_OUT_OF_RANGE',
    noResults: 'NO_RESULTS',
  };
  return ['error', 'noResults']
    .filter(val => path([val], result))
    .map(val => path([val], errorCodes))
    .find(val => val);
};

export const errorCode = (error) => {
  if (error && error.status === 429) {
    return 'TOO_MANY_REQUESTS';
  }
  return error.response.text;
};

export const getAirportNameAndCode = (airport) => {
  if (airport) {
    return {
      _id: airport.fs,
      _source: {
        name: airport.name,
      },
    };
  }
  return null;
};
