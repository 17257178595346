// Used for comparing user's subscriptionLevel
// when accessing a feature at a given path.

const featureByRoute = {
  'historical-flight': {
    level: 3, // change to 3 when multiple plans supported
  },
  'flight-alerts': {
    level: 1,
  },
  airports: {
    level: 1,
  },
};

const featureByEndpoint = {
  'historical-flight': {
    level: 3, // change to 3 when multiple plans supported
  },
  'historical-flights': {
    level: 3, // change to 3 when multiple plans supported
  },
  'flight-alerts': {
    level: 1,
  },
  'flight-alerts-historical': {
    level: 1,
  },
};

const featureForPath = (path, featureSet) => {
  if (!path || path[0] !== '/') {
    console.log('featureForPath - invalid path:', path);
    return null;
  }

  const base = path.split('/')[1];
  return featureSet[base] || { level: -1 };
};

const strippedPath = path => (path || '').replace(/^\/v2|\/api/g, '');
const featureForRoute = path => featureForPath(path, featureByRoute);
const featureForEndpoint = path => featureForPath(strippedPath(path), featureByEndpoint);

export {
  featureForRoute,
  featureForEndpoint,
};
