// @flow
import React from 'react';
import styled from 'styled-components';
import { media } from '../../../src/lib/css-media';

type ButtonTypes = {
  margin?: string,
  bgColor?: string,
  hoverColor?: string,
};

export const FSButton = (styled.a`
  margin: ${(props) => (props.margin ? props.margin : '10px')};
  height: 40px;
  max-width: 254px;
  line-height: inherit;
  font-size: 16px;
  flex: 1 1 254px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  text-decoration: none !important;
  text-align: center;
  cursor: pointer;
  border-radius: 2px;
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : 'rgb(255, 168, 39)'};

  ${media.mobile`
    margin: 5px 10px;
  `}

  &:hover {
    background: ${(props) => (props.hoverColor ? props.hoverColor : '#81A2CA')};
  }
`: React.ComponentType<ButtonTypes>);
