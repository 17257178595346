// TODO: initialize this as reference data on startup
import { sortByComplexString } from '../../modules/FlightAlerts/redux/Shared/selectors';

const industryOptions = [
  'Advertising',
  'Airfreight Logistics',
  'Airline',
  'Airport',
  'Airport Consultant',
  'Aviation Consultant',
  'Business Traveler',
  'Corporate Travel Department',
  'Cruise Line',
  'Digital Signage',
  'Education',
  'GDS',
  'Government',
  'Ground Transportation',
  'Group/Event Travel',
  'Ground Handling Services',
  'Hotel',
  'Industry Association',
  'Leisure Traveler',
  'Leisure Travel Agent',
  'Media - Syndicated Content',
  'Mobile Carrier',
  'Mobile Solutions Provider',
  'Online Travel Agent',
  'Other',
  'Personal Use',
  'Publisher',
  'Search Engine',
  'Security',
  'Travel Consultant',
  'Travel Management Company',
  'Travel Solutions Provider',
];

const industries = industryOptions.map(value => ({ label: value, value })).sort(sortByComplexString('value'));

export default industries;
