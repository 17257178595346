import { SESSION_KEY } from './constants';

export default () => {
  let newState = null;

  try {
    if (typeof (sessionStorage) !== 'undefined') {
      newState = JSON.parse(sessionStorage.getItem(SESSION_KEY));
    }
  } catch (e) {
    console.log('session error', e);
  }

  return newState || {};
};
