import * as t from './actionTypes';

const loading = type => ({
  type,
});

export const getHistoricalFlights = () => async (dispatch, getStore, client) => {
  dispatch(loading(t.LOAD));

  try {
    const result = await client.get('/flight-alerts-historical');
    dispatch({
      type: t.LOAD_HISTORICAL_SUCCESS,
      result,
    });
  } catch (error) {
    dispatch({
      type: t.LOAD_FAIL,
      error,
    });
  }
};

export const checkHistoricalFlights = () => async (dispatch, getStore, client) => {
  dispatch(loading(t.CHECK_HISTORICAL));

  try {
    const result = await client.get('/flight-alerts-historical');
    dispatch({
      type: t.LOAD_HISTORICAL_SUCCESS,
      result,
    });
  } catch (error) {
    dispatch({
      type: t.CHECK_FAIL,
      error,
    });
  }
};

export const getHistoricalFlightAlertDetails = wmaId => async (dispatch, getStore, client) => {
  dispatch(loading(t.LOAD_HISTORICAL));

  try {
    const result = await client.get(`/flight-alerts-historical/details/${wmaId}`);
    dispatch({
      type: t.LOAD_HISTORICAL_BY_ID_SUCCESS,
      result,
    });
  } catch (error) {
    dispatch({
      type: t.LOAD_FAIL,
      error,
    });
  }
};

export const setFilter = (startFilterDate, endFilterDate, alertsCost) => ({
  type: t.SET_FILTER,
  startFilterDate,
  endFilterDate,
  costForTimePeriod: alertsCost,
});

export const setFilterFromMonthSelect = (startFilterDate, endFilterDate, alertsCost, currentBrushInxStart, currentBrushInxEnd) => ({ // eslint-disable-line max-len
  type: t.SET_FILTER_FROM_MONTH_SELECT,
  startFilterDate,
  endFilterDate,
  costForTimePeriod: alertsCost,
  currentBrushInxStart,
  currentBrushInxEnd,
});

export const generateMonthlySummaries = (data, mediaBreakpoint) => ({
  type: t.GENERATE_MONTHLY_SUMMARIES,
  data,
  mediaBreakpoint,
});

export const updateCurrentFilterTimeframe = (currentFilterStart, currentFilterEnd) => ({
  type: t.UPDATE_CURRENT_FILTER,
  currentFilterStart,
  currentFilterEnd,
});

export const updateCost = cost => ({ type: t.UPDATE_COST, cost });

export const setColumnFilter = (searchColumnName, orderBy, currentData) => ({
  type: t.SET_COLNAME_FILTER,
  searchColumnName: [searchColumnName, orderBy, currentData],
});

export const setKeywordFilter = (searchKeywords, currentData) => ({
  type: t.SET_KEYWORD_FILTER,
  searchKeywords: [searchKeywords, currentData],
});

export const rehydrate = props => ({
  type: t.REHYDRATE_PROPS,
  props,
});
