import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push, replace } from 'connected-react-router';
import Helmet from 'react-helmet';
import { Single } from '../../redux';
import HistoricalFlightTrackerComponent from './children/FlightTracker/HistoricalFlightTracker';
import HistoricalFlightTrackerSegments from './children/FlightTrackerSegments/HistoricalFlightTrackerSegments';
import componentBase from '../../../../src/lib/component-base';
import userHasHistoricalCredits from '../lib/helpers/userHasHistoricalCredits';
import { isUsersLastHistoricalCredit } from '../lib/helpers/isUsersLastHistoricalCredit';
import { getProfile } from '../../../Account/redux/actions';
import { setHistoricalSingleErrorMessage } from '../../redux/Single/actions';
import { meta } from './meta';

const {
  loadByParamsWithId,
  loadByCarrierFlightNumberAndDate,
  usedFinalCredit,
  userWithSingleHistoricalCredit,
} = Single.actions;

@connect(state => ({
  segments: state.SingleHistoricalFlightStatus.segments,
  details: state.SingleHistoricalFlightStatus.details,
  error: state.SingleHistoricalFlightStatus.error,
  loading: state.SingleHistoricalFlightStatus.loading,
  loaded: state.SingleHistoricalFlightStatus.loaded,
  searched: state.SingleHistoricalFlightStatus.searched,
  searching: state.SingleHistoricalFlightStatus.searching,
  singleErrorMessage: state.SingleHistoricalFlightStatus.singleErrorMessage,
  user: state.Account.user,
  usingFinalCredit: state.SingleHistoricalFlightStatus.usingFinalCredit,
}))
@componentBase('HistoricalFlightTracker')
export default class HistoricalFlightTracker extends React.Component {
  static propTypes = {
    error: PropTypes.object,
    dispatch: PropTypes.func,
    loaded: PropTypes.bool,
    params: PropTypes.object,
    details: PropTypes.object,
    match: PropTypes.object,
    segments: PropTypes.array,
    searched: PropTypes.bool,
    searching: PropTypes.bool,
    singleErrorMessage: PropTypes.string,
    user: PropTypes.object,
    usingFinalCredit: PropTypes.bool,
  };

  componentDidMount() {
    const { dispatch, match, searched } = this.props;
    dispatch(getProfile());

    if (match.params && match.params.flightId) {
      // If we have a flightId we definitely need to load details
      dispatch(loadByParamsWithId(match.params));
    } else if (!searched) {
      // check if this flight has multiple segments
      dispatch(loadByCarrierFlightNumberAndDate(match.params));
    }

    this.forceUpdate();
  }

  componentWillReceiveProps(nextProps) {
    const {
      dispatch,
      loaded,
      usingFinalCredit,
      match: prevMatch,
    } = this.props;

    if (!loaded && nextProps.loaded) {
      let errorCode = null;

      const {
        details,
        error,
        segments,
        searching,
      } = nextProps;
      if (error) {
        errorCode = error.code;
      } else if (((!segments || !segments.length) && !details) && !searching) {
        errorCode = 'FLIGHT_LOAD_ERROR2';
      }

      if (errorCode) {
        dispatch(setHistoricalSingleErrorMessage(errorCode));
        return dispatch(push({
          pathname: '/historical-flight/search',
        }));
      }

      if (!this.hasCredits() && !usingFinalCredit) {
        return this.handleErrorRedirect();
      }
    }

    const noCredits = !userHasHistoricalCredits(nextProps.user) || !this.hasCredits();

    if (noCredits && !usingFinalCredit) {
      return this.handleErrorRedirect();
    }
    const lastCredit = isUsersLastHistoricalCredit(nextProps.user);
    if (lastCredit) {
      this.props.dispatch(userWithSingleHistoricalCredit());
    }

    if (nextProps.match.params.flightId !== prevMatch.params.flightId) {
      dispatch(loadByParamsWithId(nextProps.match.params));
    }
  }

  componentDidUpdate(prevProps) {
    const {
      dispatch,
      match,
      segments,
    } = this.props;

    if (segments && segments.length === 1 && !match.params.flightId) {
      // url came without flight Id, but there is enough information to assume flight Id
      dispatch(replace({
        pathname: segments[0].url,
      }));
    }

    if (!prevProps.match.params.flightId && match.params.flightId) {
      dispatch(loadByParamsWithId(match.params));
    }
  }

  componentWillUnmount() {
    const {
      dispatch,
      usingFinalCredit,
    } = this.props;

    if (usingFinalCredit) {
      dispatch(usedFinalCredit());
    }

    const element = this.getWindowRef();
    if (element && element.onpopstate) {
      element.onpopstate = null;
    }
  }

  getWindowRef = () => typeof window !== 'undefined' && window;

  hasCredits = () => {
    const { user } = this.props;
    return userHasHistoricalCredits(user);
  }

  handlePopStateEvent = () => {
    this.props.dispatch(usedFinalCredit());
  }

  handleErrorRedirect = () => {
    this.props.dispatch(push('/historical-flight/search'));
  }

  render() {
    const hasCredits = this.hasCredits();
    const props = {
      ...this.props,
      hasCredits,
    };

    let flightOrFlights = null;
    const {
      match, segments, usingFinalCredit, details,
    } = this.props;
    const shouldRenderFlights = (segments || details) && (hasCredits || usingFinalCredit);

    if (shouldRenderFlights) {
      if (match.params.flightId && details) {
        flightOrFlights = (<HistoricalFlightTrackerComponent {...props} />);
      } else if (segments && segments.length > 1) {
        flightOrFlights = (<HistoricalFlightTrackerSegments {...this.props} />);
      }
    }

    return (
      <div className='container'>
        <Helmet {...meta(this.props)} />
        {flightOrFlights}
      </div>
    );
  }
}
