import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { SelectFilterAsync } from '@flightstats/component-lib';
import { identity, path, pathOr, pathSatisfies } from 'ramda';
import { debounce } from 'debounce';
import SearchSource from '../../sources/search';
import onResize from '../../lib/on-resize';
import componentBase from '../../lib/component-base';

@onResize()
@componentBase('AsyncElasticsearchSelect')
class AsyncElasticsearchSelect extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    isHistory: PropTypes.bool,
    input: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    inputTitle: PropTypes.string,
    isMobile: PropTypes.bool,
    selectedItem: PropTypes.object,
    noResultsTexts: PropTypes.object,
    placeholder: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDelete: PropTypes.func,
    secondaryAction: PropTypes.func,
    itemFormatter: PropTypes.func.isRequired,
    searchSource: PropTypes.string.isRequired,
    selectOnBlur: PropTypes.bool,
    serverTime: PropTypes.string,
    shouldFocus: PropTypes.bool,
    styleProps: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    const { selectedItem } = props;
    const isLoading = false;
    const noResults = false;
    if (selectedItem) {
      this.state = {
        value: selectedItem.value || '',
        results: [selectedItem],
        isLoading,
        noResults,
        searchHistory: [],
      };
    } else {
      this.state = {
        value: '',
        results: [],
        isLoading,
        noResults,
        searchHistory: [],
      };
    }
  }

  componentDidMount() {
    this.initResizeListener();
    this.changeInput(this.props);
    this.mounted = true;
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.input !== nextProps.input) {
      this.changeInput(nextProps);
    }
  }

  componentWillUnmount() {
    this.removeResizeListener();
    this.mounted = false;
  }

  onBlur = (event, searchTerm, option) => {
    if (this.props.onBlur) {
      this.props.onBlur(event, searchTerm, option);
    }
  }

  onChange = (option, triggeredByEnter) => {
    const optionValue = (option && option.value);

    if (optionValue) {
      this.setState({ value: optionValue });

      if (this.props.onChange) {
        return this.props.onChange(option, triggeredByEnter);
      }
    }
  };

  onDelete = (option) => {
    const optionValue = (option && option.value);
    this.setState({ value: optionValue, results: [] });
    if (this.props.onDelete) {
      // originally, delete was handled by onChange, might not be updated everywhere
      return this.props.onDelete(option);
    } else if (this.props.onChange) {
      // fallback to onChange (original delete handler)
      return this.props.onChange(option);
    }
  }

  getOptions = (value) => {
    const isLoading = !this.state.isLoading;
    const searchHistory = [...this.state.searchHistory, value];
    this.setState({
      isLoading,
      noResults: false,
      searchHistory,
    }, () => {
      let body = value;
      if (this.props.searchSource === 'airport') {
        body = {
          value,
          isHistory: this.props.isHistory
        }
      }
      SearchSource[this.props.searchSource](body, this.props.serverTime)
        .then((res) => {
          try {
            const searchTerm = JSON.parse(res.config.data).value;
            const mostRecentSearchTermIndex = this.state.searchHistory.length - 1;
            if (searchTerm === this.state.searchHistory[mostRecentSearchTermIndex]) {
              this.handleAsyncResults(this.buildOptions(res));
              this.setState({
                searchHistory: [],
              });
            }
          } catch (e) {
            console.log('Parse error ', e);
          }
        })
        .catch((ex) => {
          this.exception(ex);
          this.handleAsyncResults(this.buildOptions({}));
        });
    });
  }

  setValueState = (newValue) => {
    if (this.mounted) {
      this.setState({ value: newValue });
    } else {
      this.state.value = newValue;
    }
  }

  handleAsyncResults = results => this.setState({
    noResults: (!results || !results.length),
    isLoading: false,
    results,
  });

  secondaryAction = () => {
    const { secondaryAction } = this.props;
    secondaryAction && secondaryAction();
  }

  mobileScreen = () => [path(['mediaBreakpoint', 'isMobile'], this),
    pathSatisfies(val => val < 769, ['mediaBreakpoint', 'value'], this)]
    .some(val => val)

  handleResize = () => {
    this.forceUpdate();
  }

  changeInput(props) {
    if (props && props.input) {
      if (props.initializerSource) {
        SearchSource[props.initializerSource](props.input, this.props.serverTime)
          .then((res) => {
            this.setValueState(res.data);
          })
          .catch(() => {
          });
      } else {
        this.setValueState(props.input);
      }
    } else {
      // empty
      this.setValueState(null);
    }
  }

  buildOptions = (res) => {
    const { data = [] } = res;
    return data.map(item => ({
      value: item,
      label: this.props.itemFormatter(item),
    }));
  }

  render() {
    const isMobileScreen = this.mobileScreen();
    const {
      inputTitle,
      isMobile,
      name,
      noResultsTexts,
      placeholder,
      selectOnBlur,
      shouldFocus,
      styleProps = {},
    } = this.props;
    const {
      isLoading,
      noResults,
      results,
      value,
    } = this.state;
    const {
      bgColor,
      placeholderColor,
      textColor,
      iconColor,
      height,
      borderColor,
    } = styleProps;

    return (
      <div>
        <SelectFilterAsync
          isLoading={isLoading}
          shouldFocus={shouldFocus}
          clearable
          searchable
          noResults={noResults}
          noResultsTexts={noResultsTexts}
          ref={`async-elasticsearch-select-${name}`}
          hideCaret
          value={value}
          results={results}
          handleChange={debounce(this.getOptions, 300)}
          handleMenuSelection={this.onChange}
          handleRemoveSelection={this.onDelete}
          inputTitle={inputTitle}
          placeholder={placeholder}
          height={height}
          menuMaxHeight='350px'
          menuWidth='100%'
          borderColor={borderColor || 'white'}
          bgColor={bgColor || 'hsla(0,0%,75%,.66)'}
          placeholderColor={placeholderColor || 'rgba(255,255,255,0.7)'}
          iconColor={iconColor || 'white'}
          isMobile={isMobileScreen || isMobile}
          textColor={textColor || 'white'}
          selectWrapperPosition='relative'
          secondaryAction={this.secondaryAction}
          selectOnBlur={selectOnBlur}
          onBlur={this.onBlur}
        />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  serverTime: state.Account.serverTime,
});
export default connect(mapStateToProps)(AsyncElasticsearchSelect);
