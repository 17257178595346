import reportException from '../../../../shared/lib/report-exception';

export function _errorMessage(fieldName, prefixText, state) {
  if (state.invalidFieldKeys && state.invalidFieldKeys.includes(fieldName)) {
    // there is an error for this field
    return `Invalid ${prefixText === 'CVV' ? prefixText : prefixText.toLowerCase()}`;
  }
  return prefixText;
}

export function _errorClass(fieldName, state) {
  // undefined if no error
  const errorClass = state.invalidFieldKeys && state.invalidFieldKeys.includes(fieldName) && 'invalid';
  return errorClass || '';
}

export function setGeneralError(message, component) {
  component.setState({ generalError: message });
}

export function clearErrors(success, component) {
  const successfulUpdate = !!success;
  component.setState({
    invalidFieldKeys: null, generalError: null, submitDisabled: false, successfulUpdate,
  });
}

export function sendPaymentMethod(state, component) {
  return new Promise((resolve, reject) => {
    if (state.hostedFields || component.props.hostedFields) {
      const hostedFields = state.hostedFields || component.props.hostedFields;
      hostedFields.tokenize((err, payload) => {
        if (err) {
          const newState = {};
          if (err.code === 'HOSTED_FIELDS_FIELDS_INVALID') {
            newState.invalidFieldKeys = err.details && err.details.invalidFieldKeys;
          } else if (err.code === 'HOSTED_FIELDS_FIELDS_EMPTY') {
            newState.invalidFieldKeys = ['number', 'cvv', 'expirationMonth', 'expirationYear'];
          } else {
            reportException(err, null, 'braintree-hosted-fields');
            newState.generalError = err.message;
          }

          newState.submitDisabled = false;
          component.setState(newState);
          component._scrollToError();
          reject(err);
        } else {
          component.setState({ invalidFieldKeys: null });
          resolve(payload);
        }
      });
    } else {
      reject(new Error('Something went wrong. Please retry'));// this message going to be displayed on a toast if user tried to submit before tokenization
    }
  });
}

export function setSubmitButtonDisabled(disabled, component) {
  component.setState({ submitDisabled: disabled });
}
const placeholderColor = { color: '#7f7f7f' };
const placeholderStyle = {
  '::-webkit-input-placeholder': placeholderColor,
  ':placeholder': placeholderColor,
  '::placeholder': placeholderColor,
  '::-moz-placeholder': placeholderColor,
  ':-ms-input-placeholder': placeholderColor,
  ':-moz-placeholder': placeholderColor,
};

export const clientDidCreateStyles = {
  input: {
    'font-size': '10pt',
  },

  select: {
    '-webkit-appearance': 'none',
  },

  ...placeholderStyle,

  '.number': { 'font-family': 'monospace' },

  '.valid': { },

  '.invalid': { },
};

export const clientDidCreateFields = {
  number: {
    selector: '#card-number',
  },
  cvv: {
    selector: '#cvv',
  },
  expirationMonth: {
    selector: '#expirationMonth',
    placeholder: 'MM',
    /*
    select: {
      options: [
      '01 - January',
      '02 - February',
      '03 - March',
      '04 - April',
      '05 - May',
      '06 - June',
      '07 - July',
      '08 - August',
      '09 - September',
      '10 - October',
      '11 - November',
      '12 - December'
      ]
    }
    */
  },
  expirationYear: {
    selector: '#expirationYear',
    placeholder: 'YYYY',
    select: false,
  },
};

export const SCROLL_OPTIONS = {
  duration: 500,
  delay: 0,
  smooth: true,
};

export const SUBSCRIPTION_ERROR_TYPE_MESSAGES = {
  declined: 'This credit card was declined. Please call your bank or try a different credit card.',
  backend: 'There was a connection problem reaching the payment processor. Please try again.',
  other: 'There was a problem reaching the payment processor. Please try again.',
  subscription_active: 'You have an active subscription. Your subscription may be managed in the Account Panel.',
  unknown: 'An error occurred while communicating with the payment processor. Please try again.',
};

export const cardErrorMessageForType = type => (
  SUBSCRIPTION_ERROR_TYPE_MESSAGES[type] || SUBSCRIPTION_ERROR_TYPE_MESSAGES.unknown
);

export const scrollToId = (elementId) => {
  // used for error messages on payment form
  try {
    const element = document.getElementById(elementId);
    element && element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  } catch (e) {
    reportException(e, null, 'getElementById');
  }
};

export const errorStrings = {
  noSubscription: 'Please select a subscription plan',
  noTermsAndConditions: 'You must accept the Terms and Conditions',
};
