import React from 'react';
import PropTypes from 'prop-types';
import componentBase from '../../../../../../src/lib/component-base';
import { prefersMetricDistanceFormat } from '../../../../../../src/lib/unit-measurement-format';

const visIcon = require('../../../../../../static/images/white-binoculars.png');

@componentBase('WeatherVisibilityConditions')
export default class WeatherVisibilityConditions extends React.Component {
  static propTypes = {
    visibility: PropTypes.object,
    otherWeatherAvailable: PropTypes.bool,
  };

  constructor(props, context) {
    super(props, context);

    this.muiDefaultProps = {
      topStyle: {
        padding: '0',
        height: 'inherit',
        background: 'rgba(255, 255, 255, .1)',
      },
      topInnerStyle: {
        padding: '0',
        height: 'inherit',
        backgroundImage: `url('${visIcon}')`,
        backgroundSize: '65px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      },
    };
  }

  _getVisibility() {
    const vis = { value: '--', units: 'miles' };

    if (prefersMetricDistanceFormat(this.context.currentUser)) {
      vis.units = 'km';
      if (this.props.visibility && this.props.visibility.km) {
        vis.value = this.props.visibility.km;
      }
    } else if (this.props.visibility && this.props.visibility.miles) {
      vis.value = this.props.visibility.miles;
    }

    return vis;
  }

  _getOtherWeatherAvailable() {
    if (this.props.otherWeatherAvailable) {
      return this.props.otherWeatherAvailable;
    }

    return false;
  }

  render() {
    const visibility = this._getVisibility();

    let additionalMsg = null;
    if (visibility.value === '--' && this._getOtherWeatherAvailable()) {
      additionalMsg = (
        <div style={{ marginTop: '-5px' }}>
          <span style={{ fontSize: '8pt', color: '#FF9600' }}>
            Data Not Available
          </span>
        </div>
      );
    }

    return (
      <div className='row' style={this.muiDefaultProps.topStyle}>
        <div className='col-xs-12' style={this.muiDefaultProps.topInnerStyle}>
          <div className='col-xs-12 col-sm-12 col-md-12' style={{ padding: '0', height: '50%' }}>
            <div
              className='col-xs-12 col-sm-12 col-md-12'
              style={{
 position: 'absolute', padding: '0', bottom: '0', textAlign: 'center',
}}
            >
              <span style={{ fontSize: '12pt' }}>Visibility</span>
            </div>
          </div>
          <div className='col-xs-12 col-sm-12 col-md-12' style={{ padding: '0', height: '50%' }}>
            <div
              className='col-xs-12 col-sm-12 col-md-12'
              style={{
 position: 'absolute', padding: '0', top: '0', textAlign: 'center',
}}
            >
              <span style={{ fontSize: '20pt', fontWeight: 'bold', color: '#FF9600' }}>{ visibility.value }</span>&nbsp;
              <span style={{ fontSize: '8pt', color: '#FF9600' }}>{ visibility.units }</span>
              { additionalMsg }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
