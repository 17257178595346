import React from 'react';
import FlightSearch from '../../FlightTrackerSharedSearch/components/container';

const styles = {
  heading: {
    textAlign: 'center',
    fontSize: '2em',
    borderBottom: '3px solid #FFA800',
  },
};

const HomepageSearch = props => (
  <div>
    <h1 style={styles.heading}>FlightStats Serves the Needs of On-the-go Travelers</h1>
    <div className='unified-flight-tracker-search-feature ' >
      <FlightSearch
        {...props}
        title='Track a Flight'
        gaTitlePrefix='Home'
      />
    </div>
  </div>
);

export default HomepageSearch;
