import * as t from './actionTypes';
import {
  catchErrors,
  combinedErrors,
  handleSoftError,
  registrationValidationError,
  responseError,
} from './selectors';

const initialState = {
  changePasswordDialogOpen: false,
  cityError: null,
  countryCodeError: null,
  deleteAccountDialogOpen: false,
  deleteAccountCheckboxChecked: false,
  deletedAccount: false,
  finishedProfileFetch: false,
  loaded: false,
  loginError: null,
  loginRecaptcha: null,
  loggedIn: false,
  mightNeedRefresh: false,
  nameError: null,
  newAdditionalInfo: {},
  planSelected: 'standard',
  postalCodeError: null,
  registrationEmail: '',
  registrationError: null,
  registrationRecaptcha: null,
  registrationPassword: '',
  registrationPasswordConfirmation: '',
  shouldRedirectToOutagePage: false,
  shouldRefresh: false,
  streetAddressError: null,
  accountAttentionRequired: false,
  braintreeClientToken: '',
  reasonForUnsubscribing: '',
  registrationBillingInformationBtnDisabled: false,
  registrationFlowToken: null,
  updateSuccess: false,
  user: null,
  userServiceRegistrationError: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case t.SET_SERVER_TIME:
      return {
        ...state,
        serverTime: action.serverTime,
      };
    case t.RESET_AUTH_STATE:
      return {
        ...state,
        getProfileErrors: null,
        gettingProfile: null,
        gotProfile: null,

        updateProfileErrors: null,
        updatingProfile: null,
        updatedProfile: null,

        updatingBillingAddress: null,
        updatingBillingAddressError: null,
        updatedBillingAddress: null,

        updatingPaymentInformation: null,
        updatedPaymentInformation: null,
        updatingPaymentInformationError: null,

        updateSuccess: null,
        updatedUserError: null,

        deleteAccountError: null,
        deletingAccount: null,
        deletedAccount: null,

        changePasswordErrors: null,
        changedPassword: null,
        changingPassword: null,

        forgotPasswordError: null,
        forgotPasswordSubmit: null,
        forgotPasswordReset: null,

        sendingEmailVerification: null,
        sentEmailVerification: null,
        sendEmailVerificationError: null,

        loaded: false,
        signUpError: null,
        loginError: null,
        logoutError: null,
        signingUp: null,
        loggingIn: null,
        loggingOut: null,
      };
    case t.LOAD_SESSION:
      if (action.user) {
        return {
          ...state,
          loading: false,
          loaded: true,
          loggedIn: true,
          user: action.user,
        };
      }
      return {
        ...state,
      };
    case t.LOAD:
      return {
        ...state,
        loading: true,
      };
    case t.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        user: action.result.email ? action.result : null,
      };
    case t.LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    case t.LOAD_END:
      return {
        ...state,
        loading: false,
      };
    case t.REGISTRATION_DATA_CLEAR:
      return {
        ...state,
        registrationError: null,
        registrationEmail: '',
        registrationPassword: null,
        registrationPasswordConfirmation: null,
        registrationRecaptcha: null,
        registrationTermsAndConditions: false,
      };
    case t.INVALIDATE_RECAPTCHA: {
      return {
        ...state,
        registrationRecaptcha: null,
      };
    }
    case t.REGISTRATION_EMAIL:
      return {
        ...state,
        registrationEmail: action.result,
        registrationError: null,
        userServiceRegistrationError: null,
      };
    case t.REGISTRATION_PASSWORD:
      return {
        ...state,
        registrationPassword: action.result,
        registrationError: null,
        userServiceRegistrationError: null,
      };
    case t.REGISTRATION_PASSWORD_CONFIRMATION:
      return {
        ...state,
        registrationPasswordConfirmation: action.result,
        registrationError: null,
        userServiceRegistrationError: null,
      };
    case t.REGISTRATION_TERMS_AND_CONDITIONS:
      return {
        ...state,
        registrationTermsAndConditions: action.result,
        registrationError: null,
        userServiceRegistrationError: null,
      };
    case t.REGISTRATION_RECAPTCHA:
      return {
        ...state,
        registrationRecaptcha: action.result,
        registrationError: null,
        userServiceRegistrationError: null,
      };
    case t.REGISTRATION_SIGNUP:
      return {
        ...state,
        registering: true,
        registrationError: null, // validation errors
        userServiceRegistrationError: null, // user service errors
      };
    case t.REGISTRATION_FAIL:
      return {
        ...state,
        registering: false,
        loggedIn: false,
        user: null,
        registrationError: registrationValidationError(action), // validation errors
        userServiceRegistrationError: null, // user service errors
      };
    case t.REGISTRATION_SUCCESS:
      return {
        ...state,
        registering: false,
        loggedIn: true,
        user: action.result,
        registrationError: null, // validation errors
        userServiceRegistrationError: null, // user service errors
      };
    case t.USER_SERVICE_REGISTRATION_FAIL:
      return {
        ...state,
        registering: false,
        loggedIn: false,
        user: null,
        userServiceRegistrationError: registrationValidationError(action),
      };
    case t.SIGNUP:
      return {
        ...state,
        signingUp: true,
      };
    case t.SIGNUP_SUCCESS:
      return {
        ...state,
        signingUp: false,
        loggedIn: true,
        user: action.result,
        signUpError: null,
      };
    case t.SIGNUP_FAIL:
      return {
        ...state,
        signingUp: false,
        loggedIn: false,
        user: null,
        signUpError: action.validationError ? [action.validationError] :
          action.error.response.body.error,
      };
    case t.LOGIN:
      return {
        ...state,
        loggingIn: true,
      };
    case t.LOGIN_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        loggedIn: true,
        user: action.result,
        loginError: null,
        loginRecaptchaValue: null,
      };
    case t.LOGIN_FAIL:
      return {
        ...state,
        loggingIn: false,
        loggedIn: false,
        user: null,
        loginRecaptchaValue: null,
        loginError: action.validationError ? [action.validationError] :
          [responseError(action)],
      };
    case t.LOGIN_RECAPTCHA:
      return {
        ...state,
        loginRecaptchaValue: action.result,
      };
    case t.LOGOUT:
      return {
        ...state,
        loggingOut: true,
      };
    case t.LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        loggingOut: false,
        loggedIn: false,
        user: null,
        loginRecaptchaValue: null,
      };
    case t.LOGOUT_FAIL:
      return {
        ...state,
        loading: false,
        user: null,
        loginRecaptchaValue: null,
        loggingOut: false,
        logoutError: action.error,
      };
    case t.FORGOT_PASSWORD:
      return {
        ...state,
        forgotPasswordSubmit: true,
        forgotPasswordReset: false,
      };
    case t.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPasswordSubmit: false,
        forgotPasswordError: null,
        forgotPasswordReset: true,
        forgotPasswordRecaptchaValue: null,
      };
    case t.FORGOT_PASSWORD_FAIL:
      return {
        ...state,
        forgotPasswordReset: false,
        forgotPasswordSubmit: false,
        forgotPasswordError: responseError(action),
        forgotPasswordRecaptchaValue: null,
      };
    case t.FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        forgotPasswordError: action.error,
        forgotPasswordRecaptchaValue: null,
      };
    case t.FORGOT_PASSWORD_RECAPTCHA:
      return {
        ...state,
        forgotPasswordRecaptchaValue: action.result,
      };
    case t.SEND_EMAIL_VERIFICATION:
      return {
        ...state,
        sendingEmailVerification: true,
        sentEmailVerification: false,
      };
    case t.SEND_EMAIL_VERIFICATION_SUCCESS:
      return {
        ...state,
        sendingEmailVerification: false,
        sentEmailVerification: true,
        sendEmailVerificationError: null,
      };
    case t.SEND_EMAIL_VERIFICATION_FAIL:
      return {
        ...state,
        sendingEmailVerification: false,
        sentEmailVerification: false,
        sendEmailVerificationError: responseError(action),
      };
    case t.CHANGE_PASSWORD:
      return {
        ...state,
        changingPassword: true,
        changedPassword: null,
        changePasswordErrors: null,
      };
    case t.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changingPassword: false,
        changedPassword: true,
        changePasswordErrors: null,
      };
    case t.CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        changingPassword: false,
        changedPassword: false,
        changePasswordErrors: combinedErrors(action),
      };
    case t.GET_PROFILE:
      return {
        ...state,
        gettingProfile: true,
        finishedProfileFetch: false,
        gotProfile: null,
        getProfileErrors: null,
      };
    case t.GET_PROFILE_SUCCESS:
      return {
        ...state,
        gettingProfile: false,
        finishedProfileFetch: true,
        gotProfile: true,
        user: action.result,
        additionalInfo: action.result && action.result.additionalInfo,
        subscriptionActive: action.result && action.result.subscriptionActive,
        getProfileErrors: null,
      };
    case t.GET_PROFILE_FAIL:
      return {
        ...state,
        gettingProfile: false,
        finishedProfileFetch: true,
        gotProfile: false,
        getProfileErrors: combinedErrors(action),
      };
    case t.UPDATE_PROFILE:
      return {
        ...state,
        updatingProfile: true,
        updatedProfile: null,
        updateProfileErrors: null,
      };
    case t.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        updatingProfile: false,
        updatedProfile: true,
        user: action.result,
        updateProfileErrors: null,
      };
    case t.UPDATE_PROFILE_FAIL:
      return {
        ...state,
        updatingProfile: false,
        updatedProfile: false,
        updateProfileErrors: combinedErrors(action),
      };
    case t.DELETE_ACCOUNT:
      return {
        ...state,
        deletingAccount: true,
        updateErrors: null,
      };
    case t.DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        deletingAccount: false,
        deletedAccount: true,
        user: null,
        loggedIn: false,
        deleteAccountError: null,
        updateErrors: null,
        deleteAccountDialogOpen: false,
      };
    case t.DELETE_ACCOUNT_FAIL:
      return {
        ...state,
        deletingAccount: false,
        deletedAccount: false,
        deleteAccountError: responseError(action),
        deleteAccountDialogOpen: false,
      };
    case t.DELETE_ACCOUNT_SET_CHECKBOX:
      return {
        ...state,
        deleteAccountCheckboxChecked: action.bool,
      };
    case t.GET_BRAINTREE_CLIENT_TOKEN:
      return {
        ...state,
        gettingBraintreeClientToken: true,
      };
    case t.GET_BRAINTREE_CLIENT_TOKEN_SUCCESS:
      return {
        ...state,
        gettingBraintreeClientToken: false,
        braintreeClientToken: action.result.clientToken,
        braintreeClientTokenError: null,
      };
    case t.GET_BRAINTREE_CLIENT_TOKEN_FAIL:
      return {
        ...state,
        gettingBraintreeClientToken: false,
        braintreeClientToken: null,
        braintreeClientTokenError: catchErrors(action.error),
      };
    case t.GET_BRAINTREE_PLANS:
      return {
        ...state,
        gettingBraintreePlans: true,
      };
    case t.GET_BRAINTREE_PLANS_SUCCESS:
      return {
        ...state,
        gettingBraintreePlans: false,
        braintreePlans: action.result.plans,
        braintreePlansError: null,
      };
    case t.GET_BRAINTREE_PLANS_FAIL:
      return {
        ...state,
        gettingBraintreePlans: false,
        braintreePlans: null,
        braintreePlansError: action.error,
      };
    case t.GET_PAYMENT_HISTORY:
      return {
        ...state,
        gettingPaymentHistory: true,
        paymentHistory: null,
        paymentHistoryError: null,
      };
    case t.GET_PAYMENT_HISTORY_SUCCESS:
      return {
        ...state,
        gettingPaymentHistory: false,
        paymentHistory: action.result && action.result.data,
        paymentHistoryError: null,
      };
    case t.GET_PAYMENT_HISTORY_FAIL:
      return {
        ...state,
        gettingPaymentHistory: false,
        paymentHistory: null,
        paymentHistoryError: action.error,
      };
    case t.CANCEL_BRAINTREE_SUBSCRIPTION:
      return {
        ...state,
        cancellingBraintreeSubscription: true,
      };
    case t.CANCEL_BRAINTREE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        cancellingBraintreeSubscription: false,
        cancelBraintreeSubscriptionSuccess: action.result,
        upgradedSubscription: null,
        cancelBraintreeSubscriptionError: null,
      };
    case t.CANCEL_BRAINTREE_SUBSCRIPTION_FAIL:
      return {
        ...state,
        cancellingBraintreeSubscription: false,
        cancelBraintreeSubscriptionSuccess: null,
        cancelBraintreeSubscriptionError: action.error,
      };
    case t.CREATE_BRAINTREE_SUBSCRIPTION:
      return {
        ...state,
        creatingBraintreeSubscription: true,
      };
    case t.CREATE_BRAINTREE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        creatingBraintreeSubscription: false,
        braintreeSubscription: handleSoftError(action.result),
        braintreeSubscriptionError: null,
      };
    case t.CREATE_BRAINTREE_SUBSCRIPTION_FAIL:
      return {
        ...state,
        creatingBraintreeSubscription: false,
        braintreeSubscription: null,
        braintreeSubscriptionError: catchErrors(action.error),
      };
    case t.UPDATE_BILLING_ADDRESS:
      return {
        ...state,
        updatingBillingAddress: true,
        updatingBillingAddressError: null,
        updatedBillingAddress: null,
      };
    case t.UPDATE_BILLING_ADDRESS_SUCCESS:
      return {
        ...state,
        updatingBillingAddress: false,
        updatingBillingAddressError: null,
        updatedBillingAddress: action.result,
      };
    case t.UPDATE_BILLING_ADDRESS_FAIL:
      return {
        ...state,
        updatingBillingAddress: false,
        updatingBillingAddressError: responseError(action),
        updatedBillingAddress: null,
      };
    case t.UPDATE_PAYMENT_INFORMATION:
      return {
        ...state,
        updatingPaymentInformation: true,
        updatedPaymentInformation: null,
        updatingPaymentInformationError: null,
      };
    case t.UPDATE_PAYMENT_INFORMATION_SUCCESS:
      return {
        ...state,
        updatingPaymentInformation: false,
        updatedPaymentInformation: action.result,
        updatingPaymentInformationError: null,
      };
    case t.UPDATE_PAYMENT_INFORMATION_FAILURE:
      return {
        ...state,
        updatingPaymentInformation: false,
        updatedPaymentInformation: null,
        updatingPaymentInformationError: responseError(action),
      };
    case t.UPGRADE_SUBSCRIPTION:
      return {
        ...state,
        upgradingSubscription: true,
        upgradedSubscription: null,
        upgradingSubscriptionError: null,
      };
    case t.UPGRADE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        upgradingSubscription: false,
        upgradedSubscription: action.result,
        upgradingSubscriptionError: null,
      };
    case t.UPGRADE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        upgradingSubscription: false,
        upgradedSubscription: null,
        upgradingSubscriptionError: responseError(action),
      };
    case t.UPDATE_REASON_FOR_UNSUBSCRIBING:
      return {
        ...state,
        reasonForUnsubscribing: action.reason,
      };
    case t.ATTENTION_DIALOG:
      return {
        ...state,
        accountAttentionRequired: true,
      };
    case t.DISMISS_ATTENTION_DIALOG:
      return {
        ...state,
        accountAttentionRequired: false,
      };
    case t.OUTAGE_PAGE_REDIRECT:
      return {
        ...state,
        shouldRedirectToOutagePage: true,
      };
    case t.SHOULD_REFRESH:
      return {
        ...state,
        mightNeedRefresh: action.bool,
      };
    case t.CREATE_BRAINTREE_CREDITS_TRANSACTION:
      return {
        ...state,
        addingFlightAlertsCredits: true,
      };
    case t.CREATE_BRAINTREE_CREDITS_TRANSACTION_SUCCESS:
      return {
        ...state,
        addingFlightAlertsCredits: null,
        addMoreFlightAlertCreditsSuccess: action.result,
        addMoreFlightAlertCreditsError: null,
      };
    case t.CREATE_BRAINTREE_CREDITS_TRANSACTION_FAIL:
      return {
        ...state,
        addingFlightAlertsCredits: null,
        addMoreFlightAlertCreditsError: action.error,
        addMoreFlightAlertCreditsSuccess: null,
      };
    case t.GET_PAYMENT_HISTORY_DETAIL:
      return {
        ...state,
        braintreeHistoryDetail: null,
        braintreeHistoryDetailError: null,
        braintreeHistoryDetailLoading: true,
      };
    case t.GET_PAYMENT_HISTORY_DETAIL_SUCCESS:
      return {
        ...state,
        braintreeHistoryDetail: action.result.data,
        braintreeHistoryDetailError: null,
        braintreeHistoryDetailLoading: false,
      };
    case t.GET_PAYMENT_HISTORY_DETAIL_ERROR:
      return {
        ...state,
        braintreeHistoryDetail: null,
        braintreeHistoryDetailLoading: false,
        braintreeHistoryDetailError: action.error,
      };
    case t.SELECT_NEW_ACCOUNT_PLAN:
      return {
        ...state,
        ...action.result,
      };
    case t.HAS_CHECKED_TERMS_AND_CONDITIONS:
      return {
        ...state,
        hasCheckedTermsAndConditions: action.bool,
      };
    case t.REGISTRATION_BILLING_INFO_BTN_DISABLED:
      return {
        ...state,
        registrationBillingInformationBtnDisabled: action.bool,
      };
    case t.GET_FIRST_TRANSACTIONID:
      return {
        ...state,
        gettingFirstTransactionId: true,
      };
    case t.GET_FIRST_TRANSACTIONID_SUCCESS:
      return {
        ...state,
        gettingFirstTransactionId: false,
        gettingFirstTransactionIdSuccess: (action.result &&
          !action.result.receiptPending) &&
          action.result.transactionId,
        gettingFirstTransactionIdFail: false,
        receiptPending: !!action.result.receiptPending,
      };
    case t.GET_FIRST_TRANSACTIONID_FAIL:
      return {
        ...state,
        receiptPending: null,
        gettingFirstTransactionId: false,
        gettingFirstTransactionIdSuccess: false,
        gettingFirstTransactionIdFail: action.error,
      };
    case t.SET_REGISTRATION_STAGE:
      return {
        ...state,
        registrationStage: action.registrationStage,
      };
    case t.UPDATE_ADDITIONAL_INFO_INPUT:
      return {
        ...state,
        ...action.result,
      };
    case t.SET_PLAN_HOVERED:
      return {
        ...state,
        planHovered: action.plan,
      };
    case t.UPDATE_FORM_ERROR_STATE:
      return {
        ...state,
        ...action.result,
      };
    case t.HOSTED_FIELDS_DID_CREATE:
      return {
        ...state,
        ...action.result,
      };
    case t.TOGGLE_ACCOUNT_PROFILE_DIALOGS:
      return {
        ...state,
        ...action.result,
      };
    case t.REGISTRATION_FLOW_TOKEN:
      return {
        ...state,
        ...action.result,
      };
    case t.DELETE_REGISTRATION_FLOW_TOKEN:
      return {
        ...state,
        ...action.result,
      };
    default:
      return state;
  }
}
