import { NAME } from './constants';

export const getAirport = state => state[NAME];
export const getDetailsHeader = state => state[NAME];

export const getDetailsHeaderState = (detailsHeader) => {
  const state = {
    timerOn: false,
    airportCode: null,
    airportName: null,
    addressLine1: null,
    addressLine2: null,
    airportTZRegionName: null,
    latitude: null,
    longitude: null,
  };

  if (detailsHeader) {
    state.airportCode = detailsHeader.code;
    state.airportName = detailsHeader.name;
    state.addressLine1 = detailsHeader.addressLine1;
    state.addressLine2 = detailsHeader.addressLine2;
    state.airportTZRegionName = detailsHeader.timeZoneRegionName;
    state.latitude = detailsHeader.latitude;
    state.longitude = detailsHeader.longitude;
  }

  return state;
};

export const getTrackerState = (data) => {
  if (data) {
    const { delayIndex = {}, currentWeather = {}, detailsHeader = {} } = data;

    return {
      airport: getDetailsHeaderState(detailsHeader),
      delayIndex,
      currentWeather,
      airportCode: detailsHeader.code || '',
    };
  }
  return null;
};
