import {
  INFO,
  WARNING,
  SUCCESS,
  ERROR,
  CLOSE,
  STICK,
} from './actionTypes';

const initialState = {
  toasts: {},
};

const toastTypeForActionType = actionType => actionType.split('/')[1];
const iconClassForToastType = toastType => toastType.toLowerCase();

export default (state = initialState, action = {}) => {
  const tmpToasts = Object.assign({}, state.toasts);

  switch (action.type) {
    case INFO:
    case SUCCESS:
    case WARNING:
    case ERROR:
      const toastType = toastTypeForActionType(action.type);
      const newToast = {
        type: toastType,
        id: action.id,
        title: action.title,
        message: action.message,
        sticky: action.sticky,
        iconClass: iconClassForToastType(toastType),
      };
      tmpToasts[newToast.id] = newToast;
      break;

    case CLOSE:
      delete (tmpToasts[action.id]);
      break;

    case STICK:
      tmpToasts[action.id].sticky = true;
      break;

    default:
      break;
  }

  return {
    ...state,
    toasts: tmpToasts,
  };
};
