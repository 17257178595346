import React from 'react';
import styled from 'styled-components';

const Carat = styled.div`
  width: 0;
  height: 0;
  padding: 0;

  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 11px solid #fff;
`;

const LinkCaratWrapper = styled.div`
  display: flex;
  width: 33px;
  background: rgba(123, 161, 203, 0.5);
  min-height: 100%;
  justify-content: center;
  align-items: center;
  margin: 0;

  :hover {
    background: rgba(250, 167, 24, 0.5);
  }

  > div :hover {
    background: rgba(250, 167, 24, 0.5);
  }
`;

export const LinkCarat = (): React.Node => (
  <LinkCaratWrapper>
    <Carat />
  </LinkCaratWrapper>
);
