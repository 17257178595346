import React, { Component } from 'react';
import PropTypes from 'prop-types';
import componentBase from '../../../../src/lib/component-base';

@componentBase('StatusBar')
export default class StatusBar extends Component {
  static propTypes = {
    success: PropTypes.bool,
    failure: PropTypes.bool,
    statusMessage: PropTypes.string,
    color: PropTypes.string,
  };

  get statusProps() {
    const props = {
      color: '',
      statusMessage: '',
    };

    if (this.props) {
      const {
        success,
        failure,
        statusMessage,
        color,
      } = this.props;

      if (success || failure) {
        props.color = success ? 'green' : 'red';
      } else if (color) {
        props.color = color;
      }

      if (statusMessage) {
        props.statusMessage = statusMessage;
      }
    }

    return props;
  }

  render() {
    const props = this.statusProps;
    return (
      <div className={`subscription-status-bar subscription-status-bar-${props.color}`}>
        <h4 className={`subscription-status-message subscription-status-message-${props.color}`}>{props.statusMessage}</h4>
      </div>
    );
  }
}
