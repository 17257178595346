import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import componentBase from '../../../../../../../src/lib/component-base';
import FSFlatButton from '../../../../../../../src/components/lib/FSFlatButton';
import TimeToDOM from '../../../../../../../src/components/TimeToDOM/TimeToDOM';

@componentBase('UpcomingFlightAlertRow')
export default class UpcomingFlightAlertRow extends React.Component {
  static propTypes = {
    origin: PropTypes.string,
    destination: PropTypes.string,
    flightNumber: PropTypes.string,
    description: PropTypes.string,
    handleFakeTest: PropTypes.func,
    handleAlertDeletion: PropTypes.func,
    handleDeactivationReactivation: PropTypes.func,
    messageType: PropTypes.string,
    index: PropTypes.number,
    flexId: PropTypes.number,
    status: PropTypes.number,
    date: PropTypes.string,
    savedOn: PropTypes.string,
    wmaId: PropTypes.number,
    user: PropTypes.object,
    departureMoment: PropTypes.object,
    arrivalMoment: PropTypes.object,
    email: PropTypes.string,
    renderTestButton: PropTypes.bool,
    sendEmail: PropTypes.bool,
    shortText: PropTypes.bool,
    phoneNumber: PropTypes.string,
    carrier: PropTypes.string,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      active: false,
      deactivated: false,
      description: this.props.description,
      tested: false,
    };
  }

  componentWillMount() {
    if (this.props.index === 0) {
      this.setState({ active: true });
    }
  }

  componentDidUpdate() {
    ReactDOM.findDOMNode(this.refs.editInput).focus();
  }

  get description() {
    let originToDestination;
    let flightNumber;
    let text;

    if (this.props.origin && this.props.destination) {
      originToDestination = `${this.props.origin} to ${this.props.destination}`;
    }
    if (this.props.flightNumber) {
      flightNumber = this.props.flightNumber;
    }
    text = flightNumber && originToDestination ? `${flightNumber} ${originToDestination}` : originToDestination;

    if (!text) {
      text = 'Enter Alert Description';
    }

    return (this.props.description ? this.props.description : <span className='default-description'>{ text }</span>);
  }

  get deliveryPoint() {
    const deliveryPoint = {
      title: 'Delivery Points',
      email: `${this.props.email}`,
      phone: `${this.props.phoneNumber} (${this.props.carrier})`,
    };
    if (!this.props.sendEmail) {
      deliveryPoint.title = 'Phone Number (Carrier)';
      deliveryPoint.email = '';
    }
    if (!this.props.shortText) {
      deliveryPoint.title = 'Email Address';
      deliveryPoint.phone = '';
    }
    return deliveryPoint;
  }

  get messageTypeIcon() {
    switch (this.props.messageType) {
      case 'travelerMessages':
        return require('../../../../../../../static/images/flight-alerts/Default State/FAIcon-TravelerMessages.svg');
      case 'departureMessages':
        return require('../../../../../../../static/images/flight-alerts/Default State/FAIcon-DepartureMessages.svg');
      case 'arrivalMessages':
        return require('../../../../../../../static/images/flight-alerts/Default State/FAIcon-ArrivalMessages.svg');
      default:
        return require('../../../../../../../static/images/flight-alerts/Default State/FAIcon-AllMessages-ALT3.svg');
    }
  }

  get messageTypeText() {
    switch (this.props.messageType) {
      case 'travelerMessages':
        return 'Traveler Messages';
      case 'departureMessages':
        return 'Departure Messages';
      case 'arrivalMessages':
        return 'Arrival Messages';
      default:
        return 'All Messages';
    }
  }

  handleFakeTest = (e) => {
    e.stopPropagation();
    this.toggleTestState();
    this.props.handleFakeTest(this.props.index, this.props.flexId);
  }

  handleDeleteClick = () => {
    const { wmaId, flexId } = this.props;
    this.props.handleAlertDeletion({ wmaId, flexId }, this.props.index);
  }

  toggleActive = () => {
    this.setState({ active: !this.state.active });
  }

  toggleActivation = (e) => {
    e.stopPropagation();
    this.props.handleDeactivationReactivation(this.props.wmaId, this.props.index);
  }

  toggleTestState = () => {
    // will be able to test once per alert
    if (!this.state.tested) {
      this.setState({ tested: true });
    }
  }

  render() {
    // this.logger('props: ', this.props)
    const deactivated = ((this.props.status === 2 || this.props.status === 5) ? 'REACTIVATE ALERT' : 'DEACTIVATE ALERT');
    const deactivatedClass = (this.props.status === 2 || this.props.status === 5) ? ' deactivated' : '';
    const active = this.state.active ? 'active' : '';
    const dateSmallScreen = this.props && this.props.date ? this.props.date.replace(/((-\d{4})|(\d{4}-))/, '') : '';
    const description = this.description;
    const travelerIcon = this.messageTypeIcon;
    const editingInput = this.state.editing ? 'show editing' : 'hide';
    const descriptionSpan = !this.state.editing ? 'show' : 'hide';
    const sendTest = this.state.tested ? 'RETEST' : 'TEST';
    const messageText = this.messageTypeText;
    const testButton = this.props.renderTestButton ? '' : 'action-button';
    const downArrowVisibleClass = active ? 'hidden-arrow' : '';

    return (
      <div className={`alert-data-row alert-list-row${deactivatedClass}`}>
        <div className={`alert-visible-initial ${active}`} onClick={this.toggleActive}>
          <span className='date column visible-tablet'>
            {dateSmallScreen}
          </span>
          <span className='date column visible-xs-desktop'>
            { this.props && this.props.date ? this.props.date : '' }
          </span>
          <span className='flight-number column hide-xs'>{this.props.flightNumber}</span>
          <span className='origin column hide-xs hide-mobile'>{this.props.origin}</span>
          <span className='destination column hide-xs hide-mobile'>{this.props.destination}</span>
          <span className='departure-arrival-time column hide-xs'>
            <TimeToDOM time={this.props.departureMoment} user={this.props.user} isUtc componentName='upcoming-flight-alerts' />
            &nbsp;<span className='upcoming-flight-alerts-timezone'>{`${this.props.departureMoment.format('z')}`}</span>
          </span>
          <span className='departure-arrival-time column hide-xs'>
            <TimeToDOM time={this.props.arrivalMoment} user={this.props.user} isUtc componentName='upcoming-flight-alerts' />
            &nbsp;<span className='upcoming-flight-alerts-timezone'>{`${this.props.arrivalMoment.format('z')}`}</span>
          </span>
          <span className={`description-upcoming column ${descriptionSpan}`} >
            { description }
          </span>
          <span className={`description-upcoming column ${editingInput}`}>
            <input
              type='text'
              value={this.state.newDescription}
              ref='editInput'
            />
          </span>
          <div className={`arrow-down ${downArrowVisibleClass}`} onClick={this.toggleActive} />
        </div>
        <div className={`alert-hidden-initial ${active}`}>
          <div className='expanded-description'>
            <span className='title'>Description</span>
            <span className='text'>{ description}</span>
          </div>
          <div>
            <span className='message-type text'>Alert Type</span>
            <span className='saved-on text'>Alert Created</span>
            <span className='email not-xs text'>{this.deliveryPoint.title}</span>
          </div>
          <div>
            <span className='message-type'>
              <div className='message-type-icon hide-xxs' style={{ backgroundImage: `url("${travelerIcon}")`, backgroundRepeat: 'no-repeat' }} />
              <span className='hide-xxs'>&nbsp;</span>
              {messageText}
            </span>
            <span className='saved-on'>{this.props.savedOn}</span>
            <span className='email not-xs user-email'>
              <span className='upcoming-delivery-point'>{this.deliveryPoint.email}</span>
              <span className='upcoming-delivery-point'>{this.deliveryPoint.phone}</span>
            </span>
            <div className='email xs'>
              <span className='title'>{this.deliveryPoint.title}</span>
              <span className='upcoming-delivery-point'>{this.deliveryPoint.email} </span>
              <span className='upcoming-delivery-point'> {this.deliveryPoint.phone}</span>
            </div>
            <span className='button-wrapper'>
              {this.props.status == 0 ? <FSFlatButton
                className={`${testButton}`}
                label='delete'
                primary
                onClick={this.handleDeleteClick}
              /> :
              <FSFlatButton
                className={`${testButton}`}
                label={deactivated}
                type='submit'
                primary
                onClick={this.toggleActivation}
              />}
              {this.props.renderTestButton ? <FSFlatButton
                label={sendTest}
                type='submit'
                className='testing-button visible-desktop'
                style={FSFlatButton.style({ width: '75px', left: '40px' })}
                primary
                onClick={this.handleFakeTest}
              /> : null}
              <div className={`arrow-up ${testButton}`} onClick={this.toggleActive} />
            </span>
            {this.props.renderTestButton ? <div className='testing-button-wrapper hide-until-desktop'>
              <FSFlatButton
                label={sendTest}
                type='submit'
                className='testing-button-mobile'
                style={FSFlatButton.style({ width: '75px', left: '40px' })}
                primary
                onClick={this.handleFakeTest}
              />
            </div> : null}
          </div>
        </div>
      </div>
    );
  }
}
