import React from 'react';
import PropTypes from 'prop-types';
import { Marker, FeatureGroup } from 'react-leaflet';
import componentBase from '../../../../../../src/lib/component-base';
import { istanbulAirportCodeCheck } from '../../../../../../shared/lib/airport-code-helpers';

const unknownIcon = require('../../../../../../static/images/delay-map/White-Unknown.png');

const colors = ['Green-VeryLow.png', 'YellowGreen-Low.png', 'Yellow-Moderate.png', 'RedYellow-Significant.png', 'Red-Excessive.png']
  .map(color => require(`../../../../../../static/images/delay-map/${color}`));
const africaGeoCenter = [2.182778, 22.481667];
const SAGeoCenter = [-12.716667, -60.116667];
const oceaniaGeoCenter = [-23.801389, 133.903384];

const tooltipMarker = ({ text }) => {
  const options = {
    className: 'leaflet-marker-tooltip',
    iconSize: [30, 14],
    iconAnchor: [10, 23],
    html: text,
  };
  const iconTab = new L.divIcon(options); //eslint-disable-line
  return iconTab;
};
const iconMarkers = colors.map((color) => new L.icon({ //eslint-disable-line
  iconUrl: color,
  iconSize: [10, 10],
  iconAnchor: [0, 3],
}));
const naMarker = new L.icon({ //eslint-disable-line
  iconUrl: unknownIcon,
  iconSize: [10, 10],
  iconAnchor: [0, 3],
});

@componentBase('AirportDelays')
export default class AirportDelays extends React.Component {
  static propTypes = {
    map: PropTypes.object,
    delayIndices: PropTypes.array,
    handleAirportChange: PropTypes.func,
    layerContainer: PropTypes.node,
  }

  constructor(props, context) {
    super(props);

    this.map = context.map;
  }

  componentDidMount() {
    this.map.invalidateSize(true);
    this.map.on('zoomend', () => this.forceUpdate());
    this.map.on('moveend', () => this.forceUpdate());
  }

  componentWillUnmount() {
    this.map.clearAllEventListeners();
  }

  handleClick(airport, map) {
    this.props.handleAirportChange(airport, map);
  }

  renderMarker = di => (
    <Marker
      alt={di.name}
      key={istanbulAirportCodeCheck(di.code)}
      position={di.position}
      icon={di.score === 5 ? iconMarkers[4] : iconMarkers[Math.floor(di.score)] || naMarker}
      map={this.map}
      layerContainer={this.props.layerContainer}
      airport={di}
      onClick={() => this.handleClick(di, this.map)}
      onMouseOver={() => this.handleClick(di, this.map)}
    />
  );

  renderToolTip = (di) => {
    const code = istanbulAirportCodeCheck(di.code);
    const icon = tooltipMarker({ text: code });
    return (
      <Marker
        alt={di.name}
        key={`tooltip${code}`}
        position={di.position}
        icon={icon}
        map={this.map}
        layerContainer={this.props.layerContainer}
      />
    );
  }

  renderSouthernHemisphere = (di) => {
    const zoomLevel = this.map.getZoom();
    if (zoomLevel < 5 && di.classification <= 3) {
      const marker = this.renderMarker(di);
      const tool = this.renderToolTip(di);
      return [marker, tool];
    } else if (zoomLevel > 4) {
      const marker = this.renderMarker(di);
      const tool = this.renderToolTip(di);
      return [marker, tool];
    }

    return null;
  }

  renderNorthernHemisphere = (di) => {
    const zoomLevel = this.map.getZoom();
    if (zoomLevel < 4 && di.classification === 1) {
      const marker = this.renderMarker(di);
      const tool = this.renderToolTip(di);
      return [marker, tool];
    } else if ((zoomLevel > 3 && zoomLevel < 6) && di.classification <= 2) {
      const marker = this.renderMarker(di);
      const tool = this.renderToolTip(di);
      return [marker, tool];
    } else if (zoomLevel > 5) {
      const marker = this.renderMarker(di);
      const tool = this.renderToolTip(di);
      return [marker, tool];
    }

    return null;
  }

  renderAirports = () => {
    const bounds = this.map.getBounds();
    if (this.props.delayIndices && this.props.delayIndices.length > 0) {
      const markers = [];
      this.props.delayIndices.forEach((di) => {
        if (di.classification && bounds.contains(di.position)) {
          if (
            bounds.contains(africaGeoCenter) ||
            bounds.contains(SAGeoCenter) ||
            bounds.contains(oceaniaGeoCenter)
          ) {
            const markerSet = this.renderSouthernHemisphere(di);
            if (markerSet && !!markerSet.length) {
              markers.push(...markerSet);
            }
          } else {
            const markerSet = this.renderNorthernHemisphere(di);
            if (markerSet && !!markerSet.length) {
              markers.push(...markerSet);
            }
          }
        }
      });
      return markers;
    }
    return null;
  }

  render() {
    // this.logger('render: ', this.props);
    return (
      <FeatureGroup map={this.map} layerContainer={this.props.layerContainer}>
        {this.renderAirports()}
      </FeatureGroup>
    );
  }
}
