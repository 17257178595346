import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import HistoricalFlightHeader from './children/HistoricalFlightHeader/HistoricalFlightHeader';
import HistoricalFlightInformation from './children/HistoricalFlightInformation/HistoricalFlightInformation';
import componentBase from '../../../../../../src/lib/component-base';
import { meta as historicalMeta } from '../../meta';

@componentBase('HistoricalFlightTracker')
export default class HistoricalFlightTracker extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    error: PropTypes.object,
    hasCredits: PropTypes.bool,
    location: PropTypes.object,
    match: PropTypes.object,
    details: PropTypes.object,
    style: PropTypes.object,
    extendedDetails: PropTypes.bool,
    extendedDetailsAds: PropTypes.array,
    segments: PropTypes.array,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.error) {
      this.logger('Refresh error:', nextProps.error);
    }
  }

  render() {
    const { props } = this;

    let results = '';

    const {
      details,
      dispatch,
      extendedDetailsAds,
      extendedDetails,
      hasCredits,
      location,
      match,
      segments,
    } = props;

    if (details) {
      this.selectedRouteIndex = 0;
      const params = match && match.params;
      const flight = details.details || details;
      if (segments) {
        this.selectedRouteIndex = segments.findIndex((segment) => {
          if (`${segment.flightId}` === `${params && params.flightId}` ||
              `${segment.flightId}` === `${flight.flightId}`) {
            return true;
          }
          return false;
        });
      }

      flight.uplines = [];
      flight.downlines = [];

      segments && segments.forEach((segment, i) => {
        if (i < this.selectedRouteIndex) {
          flight.uplines.push(segment.lineInfo);
        } else if (i > this.selectedRouteIndex) {
          flight.downlines.push(segment.lineInfo);
        }
      });

      if (flight) {
        const meta = !extendedDetails && historicalMeta(props, flight);

        const hfiProps = {
          ...flight,
          ...{ hasCredits },
          extendedDetails,
          extendedDetailsAds,
          dispatch,
          location,
          match,
        };

        results = (
          <section>
            <Helmet {...meta} />
            <HistoricalFlightHeader {...flight} extendedDetails={extendedDetails} />
            <HistoricalFlightInformation {...hfiProps} />
          </section>
        );
      }
    }

    return (
      <div style={props.style}>
        {results}
      </div>
    );
  }
}
