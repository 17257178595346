import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AirportSelect from '../../../../src/components/lib/AirportSelect';
import AirlineSelect from '../../../../src/components/lib/AirlineSelect';

import componentBase from '../../../../src/lib/component-base';

@componentBase('ChoiceAirlineAirport')
export default class ChoiceAirlineAirport extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
  };

  static defaultProps = {
    isMyAccountPage: false,
    user: {
      departureAirportCode: null,
    },
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      departureAirportCode: props.user.departureAirportCode,
      airlineCode: props.user.airlineCode,
    };
  }

  /* eslint-disable no-underscore-dangle */
  handleAirPortLineChange = (option, stateToChange) => {
    const currentState = this.state;
    currentState[stateToChange] = (option &&
                                  option.value &&
                                  option.value._source &&
                                  option.value._source.fs) || null;
    this.setState(currentState);
  }
  /* eslint-enable no-underscore-dangle */

  render() {
    return (
      <div>
        <h3 className='account-input-title'>Home Airport</h3>
        <AirportSelect
          name='departureAirportCode'
          style={{ padding: '3px 3px 3px 5px' }}
          className='account-input'
          inputTitle='Home Airport'
          airport={this.props.user ? this.props.user.homeAirport : null}
          onChange={option => (this.handleAirPortLineChange(option, 'departureAirportCode'))}
          styleProps={{
            placeholderColor: 'black',
            textColor: 'black',
            iconColor: 'black',
            bgColor: 'white',
            borderColor: 'rgb(204, 204, 204)',
          }}
        />
        <h3 className='account-input-title spaced'>Favorite Airline</h3>
        <AirlineSelect
          name='airlineCode'
          style={{ padding: '3px 3px 3px 5px' }}
          className='account-input'
          inputTitle='Favorite Airline'
          airline={this.props.user ? this.props.user.favoriteAirline : null}
          onChange={option => (this.handleAirPortLineChange(option, 'airlineCode'))}
          styleProps={{
            placeholderColor: 'black',
            textColor: 'black',
            iconColor: 'black',
            bgColor: 'white',
            borderColor: 'rgb(204, 204, 204)',
          }}
        />
      </div>
    );
  }
}
