// @flow
import React from 'react';
import { CompactAccountMenu } from './compact-account-menu';
import { CompactMenuHeading } from './compact-menu-heading';
import type { User } from '../../../../common-types';

export type SubmenuItem = {
  id: string,
  heading: string,
  link: string,
  locked: boolean,
  visibility?: Array<string>,
};

type CompactMenuData = {
  id: string,
  heading: string,
  headingURL: string,
  submenuItems: Array<SubmenuItem>,
};

export type AccountMenuDataSubmenuItem = {
  id: string,
  heading: string,
  link: string,
  locked: boolean,
};

type AccountMenuData = {
  id: string,
  heading: string,
  submenuWidth: string,
  submenuItems: AccountMenuDataSubmenuItem,
};

type CompactMenuProps = {
  menuData: Array<CompactMenuData>,
  accountMenuData: AccountMenuData,
  user: User,
};

export const CompactMenu = ({
  menuData = [],
  accountMenuData,
  user,
}: CompactMenuProps) => (
  <React.Fragment>
    {menuData.map((d) => (
      <CompactMenuHeading
        key={d.id}
        heading={d.heading}
        submenuItems={d.submenuItems}
        user={user}
      />
    ))}
    <CompactAccountMenu
      heading={accountMenuData.heading}
      submenuItems={accountMenuData.submenuItems}
      user={user}
    />
  </React.Fragment>
);
