import React, { Component } from 'react';
import PropTypes from 'prop-types';
import braintreeClient from 'braintree-web/client';
import braintreeHostedFields from 'braintree-web/hosted-fields';
import Scroll, { Element } from 'react-scroll';
import reportException from '../../../../../../shared/lib/report-exception/index';
import componentBase from '../../../../../../src/lib/component-base';
import {
  _errorMessage,
  _errorClass,
  clientDidCreateStyles,
  clientDidCreateFields,
  sendPaymentMethod,
  SCROLL_OPTIONS,
} from '../../../shared/utility';

@componentBase('ChangePaymentInformation')
export default class ChangePassword extends Component {
  static propTypes = {
    braintreeClientToken: PropTypes.string,
    braintreeClientTokenError: PropTypes.string,
    onPaymentFormSubmitted: PropTypes.func.isRequired,
    updatingPaymentInformationError: PropTypes.string,
  };

  constructor(props, context) {
    super(props, context);

    this.errorDisplayWording = {
      number: 'Credit Card Number',
      cvv: 'CVV',
      expirationMonth: 'Expiration Month',
      expirationYear: 'Expiration Year',
    };
  }

  state = {
    hostedFields: null,
    submitDisabled: true,
  };

  componentDidMount() {
    const {
      braintreeClientTokenError,
    } = this.props;
    if (braintreeClientTokenError) {
      this.context.toast.error(braintreeClientTokenError, 'Error', false, 3);
    }
    if (!this.props.braintreeClientToken) {
      reportException('Missing Braintree client token', null, 'change-payment-info');
      // this.logger('Missing Braintree client token');
    } else {
      braintreeClient.create({
        authorization: this.props.braintreeClientToken,
      }, this.clientDidCreate);
    }
  }

  clientDidCreate = (err, client) => {
    if (err) {
      reportException(err, null, 'change-payment-info');
      this.context.toast.error('Please try again later', 'Error', false, 4);
    } else {
      const styles = { ...clientDidCreateStyles, input: { 'font-size': '12pt', color: 'black' } };
      const fields = { ...clientDidCreateFields };
      braintreeHostedFields.create({
        client,
        styles,
        fields,
      }, this.hostedFieldsDidCreate);
    }
  };

  hostedFieldsDidCreate = (err, hostedFields) => {
    this.setState({
      submitDisabled: false,
      hostedFields,
    });
  }

  sendPaymentMethod = () => sendPaymentMethod(this.state, this);

  clearErrors = () => {
    this.setState({ invalidFieldKeys: null, generalError: null, submitDisabled: true });
  }

  _scrollToError = () => {
    Scroll.scroller.scrollTo('payment-info-error-text', SCROLL_OPTIONS);
  }

  submitHandler = (event) => {
    event.preventDefault();
    this.clearErrors();
    this.props.onPaymentFormSubmitted();
    return false;
  }
  /* eslint-disable no-script-url */
  render() {
    let generalError = null;
    let fatalError = false;

    if (this.props.updatingPaymentInformationError) {
      generalError = (
        <p
          className='account-error-text'
          style={{ margin: '20px 0 0 0' }}
        >
          {this.props.updatingPaymentInformationError}
        </p>
      );
    } else if (!this.props.braintreeClientToken) {
      generalError = (
        <p
          className='account-error-text'
          style={{ margin: '20px 0 0 0' }}
        >
          Sorry, payment processing is temporarily offline.
          Please try again.
        </p>
      );
      fatalError = true;
    }

    return (
      <div
        className='subscription-form-section braintree-payment-form row payment-information'
      >
        <section className='change-payment-information'>
          <form
            action='javascript:null'
            className='payment-form'
            method='POST'
            ref='payment-form'
            onSubmit={this.submitHandler}
          >
            <input type='hidden' name='payment_method_nonce' />

            {!fatalError &&
              <div className='row'>
                <div className='col-xs-12'>
                  <div className='row'>
                    <div className='col-xs-11'>
                      <div className='fieldContainer'>
                        <Element name='billing-info-error-number' />
                        <label
                          htmlFor='card-number'
                          className={`accountFieldLabel ${_errorClass('number', this.state) || ''}`}
                        >
                          {_errorMessage('number', 'Credit Card Number', this.state)}
                        </label>
                        <div
                          id='card-number'
                          className={`account-input ${_errorClass('number', this.state) || ''}`}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-xs-12 col-sm-2 cvv'>
                      <Element name='billing-info-error-cvv' />
                      <label
                        htmlFor='cvv'
                        className={`accountFieldLabel ${_errorClass('cvv', this.state) || ''}`}
                      >
                        {_errorMessage('cvv', 'CVV', this.state)}
                      </label>
                      <div
                        id='cvv'
                        className={`account-input ${_errorClass('cvv', this.state) || ''}`}
                      />
                    </div>

                    <div className='col-xs-12 col-sm-offset-1 col-sm-4'>
                      <Element name='billing-info-error-number' />
                      <label htmlFor='expiration-month-selector' className={`accountFieldLabel ${_errorClass('expirationMonth', this.state) || ''}`}>
                        <span
                          className='visible-xs visible-sm visible-md'
                        >
                          {_errorMessage('expirationMonth', 'Exp. Month', this.state)}
                        </span>
                        <span className='visible-lg'>
                          {_errorMessage('expirationMonth', 'Expiration Month', this.state)}
                        </span>
                      </label>
                      <div
                        id='expirationMonth'
                        className={`account-input ${_errorClass('expirationMonth', this.state) || ''}`}
                      />
                    </div>

                    <div className='col-xs-12 col-sm-offset-1 col-sm-3'>
                      <Element name='billing-info-error-number' />
                      <label
                        htmlFor='expiration-year-selector'
                        className={`accountFieldLabel ${_errorClass('expirationYear', this.state) || ''}`}
                      >
                        <span className='visible-xs visible-sm visible-md'>
                          {_errorMessage('expirationYear', 'Exp. Year', this.state)}
                        </span>
                        <span className='visible-lg'>
                          {_errorMessage('expirationYear', 'Expiration Year', this.state)}
                        </span>
                      </label>
                      <div
                        id='expirationYear'
                        className={`account-input ${_errorClass('expirationYear', this.state) || ''}`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            }
            {generalError}
          </form>
        </section>
      </div>
    );
  }
  /* eslint-enable no-script-url */
}
