import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Label = styled.label`
  display: block;
  font-size: 14px;
  font-weight: 400;
  margin: 0px 0 4px;
`;

const FieldLabel = ({ text }) => <Label>{text}</Label>;

FieldLabel.propTypes = {
  text: PropTypes.string,
};

export default FieldLabel;
