export const OnTimePerformance = {
  bottom: {
    id: 'div-gpt-ad-1502743031520-2',
    sizes: [300, 250],
    adSlot: '/1010197/BS_FlightOntimePerformance_second_300x250',
  },
  top: {
    id: 'div-gpt-ad-1502743031520-3',
    sizes: [300, 250],
    adSlot: '/1010197/BS_FlightOntimePerformance_top_300x250',
  },
};
