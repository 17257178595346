import * as t from './actionTypes';

const errorCode = (error) => {
  if (error && error.status === 429) {
    return 'TOO_MANY_REQUESTS';
  }
  return 'FLIGHT_LOAD_ERROR1';
};

const initialState = {
  airline: {},
  loadAttempts: 0,
  error: null,
  flightTrackerOutOfDateRange: false,
  loading: false,
  loaded: false,
  refreshCount: -1,
  segments: [],
  attemptedToLoadSegment: false,
  singleFlightTrackerError: false,
  flight: {},
  secondarySearchAction: false,
  otherDays: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case t.LOAD_BY_CARRIER_FLIGHT_NUMBER_AND_DATE:
      return {
        ...state,
        singleFlightTrackerError: false,
        segmentsLoading: true,
        segmentsLoaded: false,
        refreshCount: -1,
      };
    case t.LOAD_EXTENDED_DETAILS_BY_CARRIER_FLIGHT_NUMBER_AND_DATE:
      return {
        ...state,
        // error: null,
        loadAttempts: state.loadAttempts + 1,
        loading: true,
        loaded: false,
        otherDaysLoading: false,
        refreshCount: -1,
      };
    case t.LOAD_EXTENDED_SUCCESS:
      return {
        ...state,
        loadAttempts: state.loadAttempts + 1,
        loading: false,
        loaded: true,
        extendedData: action.result,
        error: null,
        refreshCount: state.refreshCount + 1,
      };
    case t.LOAD_BY_CARRIER_FLIGHT_NUMBER_AND_DATE_SUCCESS:
      return {
        ...state,
        loadAttempts: state.loadAttempts + 1,
        segmentsLoading: false,
        segmentsLoaded: true,
        segments: action.result,
        attemptedToLoadSegment: true,
        error: null,
        refreshCount: state.refreshCount + 1,
      };
    case t.LOAD_BY_CARRIER_FLIGHT_NUMBER_AND_DATE_FAIL:
      return {
        ...state,
        loadAttempts: state.loadAttempts + 1,
        segments: [],
        segmentsLoading: false,
        attemptedToLoadSegment: true,
        refreshCount: state.refreshCount + 1,
        singleFlightTrackerError: true,
      };
    case t.LOAD_FAIL:
      return {
        ...state,
        loadAttempts: state.loadAttempts + 1,
        segmentsLoaded: false,
        segmentsLoading: false,
        loading: false,
        attemptedToLoadSegment: true,
        // data: null, // Do not nil out .data here so that downstream handler can be responsible.
        error: Object.assign({}, action.error, { code: errorCode(action.error) }),
        refreshCount: state.refreshCount + 1,
      };
    case t.LOAD_OTHER_DAYS_FLIGHTS:
      return {
        ...state,
        otherDaysLoading: true,
      };
    case t.LOAD_OTHER_DAYS_FLIGHTS_SUCCESS:
      return {
        ...state,
        otherDaysLoading: false,
        otherDays: action.result,
      };
    case t.LOAD_OTHER_DAYS_FLIGHTS_FAIL:
      return {
        ...state,
        otherDaysLoading: false,
        otherDays: null,
      };
    case t.UPDATE_SEGMENTS:
      return {
        ...state,
        segments: action.segments,
      };
    case t.SET_SECONDARY_SEARCH_ACTION:
      return {
        ...state,
        secondarySearchAction: action.bool,
      };
    case t.SET_SINGLE_FLIGHT_TRACKER_ERROR:
      return {
        ...state,
        singleFlightTrackerError: action.error,
      };
    case t.SET_FLIGHT_TRACKER_OUT_OFF_DATE_RANGE:
      return {
        ...state,
        flightTrackerOutOfDateRange: action.bool,
      };
    case t.GET_AIRLINE_CARRIER_SUCCESS:
      return {
        ...state,
        loadAttempts: state.loadAttempts + 1,
        loading: false,
        loaded: true,
        airline: action.result,
        error: null,
        refreshCount: state.refreshCount + 1,
      };
    case t.GET_AIRLINE_CARRIER_FAIL:
      return {
        ...state,
        loadAttempts: state.loadAttempts + 1,
        loading: false,
        refreshCount: state.refreshCount + 1,
        singleFlightTrackerError: true,
      };
    case t.RESET_TRACKER_STATE:
      return initialState;
    default:
      return state;
  }
};
