import moment from 'moment';
import { DAY } from '../redux/Historical/constants';

export const _getAlertWithSmallestScheduledDeparture = (arr) => {
  if (!arr.length > 0) {
    return null;
  }

  const minAlert = arr.reduce((memo, key) => {
    if (key.scheduledDeparture &&
      moment(key.scheduledDeparture).isBefore(moment(memo.scheduledDeparture))) {
      key.updated = true;
      return key;
    }
    return memo;
  }, {
    scheduledDeparture: moment(new Date()),
  });

  if (!minAlert.updated) {
    return null;
  }

  return minAlert;
};

export const _getAlertWithLargestScheduledDeparture = (arr) => {
  if (!arr.length > 0) {
    return null;
  }

  const maxAlert = arr.reduce((memo, key) => {
    if (key.scheduledDeparture && moment(key.scheduledDeparture).isAfter(moment(memo.scheduledDeparture))) {
      key.updated = true;
      return key;
    }
    return memo;
  }, {
    scheduledDeparture: moment(new Date('01-01-1900')),
    // alerts should all come after 1-1-1900
  });

  if (!maxAlert.updated) {
    return null;
  }

  return maxAlert;
};

export const _calculateAlertCost = (arr = []) => {
  // pass _calc alert cost the receiptCounts array

  // calcuating cost assumes that the user is subscribed over the entire length of time that arr represents
  let cost = arr.reduce((prev, current) => {
    // if (user.isNotSubscribedFotTheCurrentMonth) {
    // ///// gonna need some way to tell if subscription was active in the month we are looking at

    //  return prev;
    // }

    // when subscribed, first 10 alerts are 2.99 and 0.10 for each additional one
    const lessThanOrEqTen = 299;
    // alerts is the number of alerts that were sent in the month
    const alerts = current.alerts;
    if (alerts >= 0 && alerts <= 10) {
      return prev + lessThanOrEqTen;
    }
    if (alerts >= 0 && alerts > 10) {
      const overTen = alerts - 10;
      const overage = overTen * 10;
      // const calculatedCost = +parseFloat(lessThanOrEqTen + overage).toPrecision(3);
      const calculatedCost = lessThanOrEqTen + overage;

      return prev + calculatedCost;
    }
    // negative number of alerts or something lol ???....
    return prev;
  }, 0);

  if (cost === 0) {
    return '0.00';
  }

  cost = `${cost}`;
  const length = cost.length;
  cost = `${cost.slice(0, length - 2)}.${cost.slice(length - 2, length)}`;
  return cost;
};

export const filterBySearchKeywords = (searchKeywords, currentAlerts) => {
  // if search results are also empty, the data that shows up should be the result of the current filters that are in place on the bar chart
  // short circuit :^) in case no terms submitted
  if (!searchKeywords.length || !searchKeywords[0].length) {
    return currentAlerts;
  }

  const maxScore = searchKeywords.length;
  const result = [];
  // setting up buckets to throw alerts into
  for (let i = 0; i < maxScore; i++) {
    result.push([]);
  }
  /* eslint-disable no-param-reassign */
  currentAlerts.forEach((alert) => {
    const score = searchKeywords.reduce((prev, current) => {
      const searchTerm = current.toLowerCase();

      if (alert.wordMap && alert.wordMap[searchTerm]) {
        return ++prev;
      }

      return prev;
    }, 0);
    /* eslint-enable no-param-reassign */
    if (score === 0) return; // not gonna render results where the search terms don't show up and there are other results
    const bucket = maxScore - score;
    result[bucket].push(alert);
  });

  let IWantNoResultsFlag = false;
  const finalResult = result.reduce((prev, current, i) => {
    if (i === 0 && !current.length) {
      IWantNoResultsFlag = true;
      return [];
    }
    if (prev.length) return prev;
    return prev.concat(current);
  }, []);

  return IWantNoResultsFlag ? [] : finalResult;
};

export const filterBySearchDate = (date, currentAlerts) => {
  const result = currentAlerts.filter((datum) => {
    const momentizedScheduledDeparture = moment(datum.scheduledDeparture);
    const momentizedFilterDay = moment(date);

    return momentizedScheduledDeparture.isSame(momentizedFilterDay, DAY);
  });

  return result;
};
/* eslint-disable no-param-reassign */
export const _getNumberAlertsSent = (arr = []) => arr.reduce((prev, current) => {
  current.flightAlertEvents && current.flightAlertEvents.forEach((event) => {
    if (event.description && event.description.sendStatus && event.description.sendStatus.toLowerCase() === 'processed' && event.alertStatusText && event.alertStatusText.toLowerCase() === 'active') {
      prev++;
    }
  });
  return prev;
}, 0);

export const _getNumberOfIssues = (arr = []) => arr.reduce((prev, current) => {
  current.flightAlertEvents && current.flightAlertEvents.forEach((event) => {
    if (event.description && event.description.sendStatus &&
      (event.description.sendStatus.toLowerCase() === 'dropped' ||
      event.description.sendStatus.toLowerCase() === 'deferred' ||
      event.description.sendStatus.toLowerCase() === 'bounced' ||
      event.description.sendStatus.toLowerCase() === 'spam')) {
      prev++;
    }
  });
  return prev;
}, 0);
/* eslint-enable no-param-reassign */
