import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SelectFilterAsync } from '@flightstats/component-lib';
import componentBase from '../../../../../../../src/lib/component-base';
import FSFlatButton from '../../../../../../../src/components/lib/FSFlatButton';
import TextInput from '../../../../../../../shared/components/TextInput/TextInput';
import FormLabel from '../../../../../../../shared/components/FormLabel/FormLabel';
import { countryOptions } from '../../../../shared/AccountLists';

@componentBase('UpdateBillingAddress')
export default class UpdateBillingAddress extends Component {
  static propTypes = {
    cityError: PropTypes.string,
    countryCodeError: PropTypes.string,
    handleCountryChange: PropTypes.func,
    handleInput: PropTypes.func,
    handleUpdateBillingAddress: PropTypes.func,
    nameError: PropTypes.string,
    postalCodeError: PropTypes.string,
    stateError: PropTypes.string,
    streetAddressError: PropTypes.string,
  };

  onInputKeyUp = (value, fieldName) => {
    this.props.handleInput(fieldName, value);
  }

  onUpdateBillingAddress = () => {
    this.props.handleUpdateBillingAddress();
  }

  getAddress = () => {
    if (!this.props) {
      return {};
    }

    const keys = [
      'billingAddressButtonLabel',
      'city',
      'countryCode',
      'name',
      'postalCode',
      'state',
      'streetAddress',
    ];

    return keys.reduce((memo, key) => {
      memo[key] = this.props[key] || '';

      return memo;
    }, {});
  }

  render() {
    const errorSpanAdjustment = { top: '-4px' };
    const errorLabelAdjustment = { top: 0 };
    const divLabelAdjustment = { top: '-10px' };
    const {
      name,
      streetAddress,
      state,
      city,
      postalCode,
      countryCode,
      billingAddressButtonLabel,
    } = this.getAddress();

    return (<div className='row'>
      <div className='col-sm-12 col-md-6'>
        <div className='row'>
          <div className='col-xs-12 billing-address'>
            <TextInput
              labelStyle={{ fontWeight: 600 }}
              onInputKeyUp={val => this.onInputKeyUp(val, 'name')}
              defaultValue={name}
              errorMessage={this.props.nameError || null}
              label='Your Name'
              spanErrorStyle={errorSpanAdjustment}
              labelErrorStyle={errorLabelAdjustment}
              divErrorStyle={divLabelAdjustment}
              inputDivClassName='billing-address-input'
            />
          </div>
          <div className='col-xs-12 billing-address'>
            <TextInput
              labelStyle={{ fontWeight: 600 }}
              onInputKeyUp={val => this.onInputKeyUp(val, 'streetAddress')}
              defaultValue={streetAddress}
              errorMessage={this.props.streetAddressError || null}
              label='Street Name and Number'
              spanErrorStyle={errorSpanAdjustment}
              labelErrorStyle={errorLabelAdjustment}
              divErrorStyle={divLabelAdjustment}
              inputDivClassName='billing-address-input'
            />
          </div>
          <div className='col-xs-12 billing-address'>
            <TextInput
              onInputKeyUp={val => this.onInputKeyUp(val, 'state')}
              defaultValue={state}
              errorMessage={this.props.stateError || null}
              label={<span><p style={{ margin: 0, fontWeight: 600 }}>State / Province</p></span>}
              spanErrorStyle={errorSpanAdjustment}
              labelErrorStyle={{ top: '10px' }}
              divErrorStyle={{ top: '0' }}
              inputDivClassName='billing-address-input'
            />
          </div>
        </div>
      </div>
      <div className='col-sm-12 col-md-6'>
        <div className='row' >
          <div className='col-xs-12 billing-address'>
            <TextInput
              labelStyle={{ fontWeight: 600 }}
              onInputKeyUp={val => this.onInputKeyUp(val, 'city')}
              defaultValue={city}
              errorMessage={this.props.cityError || null}
              label='City'
              spanErrorStyle={errorSpanAdjustment}
              labelErrorStyle={errorLabelAdjustment}
              divErrorStyle={divLabelAdjustment}
              inputDivClassName='billing-address-input'
            />
          </div>
          <div className='col-xs-12 billing-address'>
            <TextInput
              labelStyle={{ fontWeight: 600 }}
              onInputKeyUp={val => this.onInputKeyUp(val, 'postalCode')}
              defaultValue={postalCode}
              errorMessage={this.props.postalCodeError || null}
              label='Postal Code'
              spanErrorStyle={errorSpanAdjustment}
              labelErrorStyle={errorLabelAdjustment}
              divErrorStyle={divLabelAdjustment}
              inputDivClassName='billing-address-input'
            />
          </div>
          <div className={`col-xs-12 billing-address${this.props.countryCodeError ? ' error' : ''}`}>
            <FormLabel
              text='Country'
              name='countryCode'
              labelStyle={{ fontWeight: 600 }}
              spanErrorStyle={errorSpanAdjustment}
              labelErrorStyle={errorLabelAdjustment}
              errorMessage={this.props.countryCodeError}
            />

            <SelectFilterAsync
              clearable={false}
              searchable
              handleMenuSelection={this.props.handleCountryChange}
              value={countryCode}
              results={countryOptions}
              bgColor='white'
              height='34px'
              borderProp='2px solid #ccc'
            />
          </div>
        </div>
      </div>
      <div className='col-xs-12 col-sm-12 col-md-4 save-changes'>
        <FSFlatButton
          style={FSFlatButton.style({ width: '100%' })}
          disabled={billingAddressButtonLabel === 'SAVING CHANGES'}
          label={billingAddressButtonLabel}
          onClick={this.onUpdateBillingAddress}
          className='button save-button'
        />
      </div>
    </div>);
  }
}
