import React from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';
import { isEmpty } from 'ramda';

import MessageTypeSelect from './children/MessageTypeSelect/MessageTypeSelect';
import AlertPreferences from './children/AlertPreferences/AlertPreferences';
import componentBase from '../../../../../../../src/lib/component-base';
import { isValidEmail, isValidPhoneNumber, isValidCarrier } from '../../../../../../../src/helpers/Validators';
import { flightNumberInt } from '../../../../../../../src/utils/validation';

const invalidEmailString = 'Invalid email address';
const invalidPhoneNumberString = 'Invalid phone number';

@componentBase('NewAlertForm')
export default class NewAlertForm extends React.Component {
  static propTypes = {
    alertPhonePreference: PropTypes.bool,
    carriers: PropTypes.array,
    currentEmail: PropTypes.string,
    profileEmailAddress: PropTypes.string,
    profilePhoneNumber: PropTypes.string,
    profileCarrier: PropTypes.string,
    errorOnSubmit: PropTypes.bool,
    formValidated: PropTypes.bool,
    handleMessageSelect: PropTypes.func,
    handleSubmit: PropTypes.func,
    travelerMessages: PropTypes.bool,
    departureMessages: PropTypes.bool,
    arrivalMessages: PropTypes.bool,
    allMessages: PropTypes.bool,
    flightInfo: PropTypes.object,
    submitButtonDisabled: PropTypes.bool,
    descriptionError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    params: PropTypes.object,
    setAlertPhonePreference: PropTypes.func,
  };

  state = {
    userAlertEmail: this.props.profileEmailAddress !== this.props.currentEmail ?
      this.props.profileEmailAddress : this.props.currentEmail,
    userAlertPhoneNumber: this.props.profilePhoneNumber,
    userAlertCarrier: this.props.profileCarrier,
    alertEmailPreference: !this.context.currentUser.flightMonitorWirelessEnabled ?
      true : this.context.currentUser.flightMonitorEmailEnabled,
    alertPhonePreference: this.props.alertPhonePreference,
    alertSelectionError: false,
    emailError: null,
    phoneNumberError: null,
    carrierError: null,
  }

  get submitDisabled() {
    return this.props.submitButtonDisabled ||
      this.props.flightInfo.isLandedFlight ||
      this.props.flightInfo.isCancelledFlight ||
      !!this.state.emailError ||
      !!this.state.carrierError ||
      !!this.state.alertSelectionError ||
      !!this.state.phoneNumberError;
  }

  handleAlertPreference = (value) => {
    const newState = {
      alertSelectionError: false,
      [value]: !this.state[value],
    };
    // if the state is currently true, we are about to make it false,
    // don't show an error for turned off preference
    if (value === 'alertEmailPreference') {
      if (this.state[value]) {
        this.resetValidation('emailError');
      } else if (!isValidEmail(this.state.userAlertEmail)) {
        newState.emailError = invalidEmailString;
      }
    }
    if (value === 'alertPhonePreference') {
      if (this.state[value]) {
        this.resetValidation('phoneNumberError');
        this.resetValidation('carrierError');
      } else if (
        !isEmpty(this.state.userAlertPhoneNumber) &&
        !isValidPhoneNumber(this.state.userAlertPhoneNumber)) {
        newState.phoneNumberError = invalidPhoneNumberString;
      }
    }

    this.setState(newState);
  }

  handleMessageSelect = (value) => {
    this.props.handleMessageSelect(value);
  }

  handleEmailSelect = (value) => {
    this.setState({
      emailError: null,
      userAlertEmail: value,
    });
  }

  handlePhoneNumberSelect = (value) => {
    this.setState({
      phoneNumberError: null,
      userAlertPhoneNumber: value,
    });
  }

  handleCarrierSelect = (value) => {
    this.setState({
      carrierError: null,
      userAlertCarrier: value,
    });
  }

  handleSubmit = () => {
    const alertDescription = this.refs.alertDescription.value;
    // const shortText = this.props.alertPhonePreference;
    // const sendEmail = this.state.alertEmailPreference;

    // SMS no longer supported
    const shortText = false; // no SMS option, value is always false
    const sendEmail = true; // no SMS option, value is always true

    let error = null;
    if (!sendEmail && !shortText) {
      // do not submit send down an error
      this.setState({
        alertSelectionError: 'At least one alert delivery point is required',
      });
      error = true;
    } else {
      // submit for email or text or both
      if (shortText) {
        if (!isValidPhoneNumber(this.state.userAlertPhoneNumber)) {
          this.phoneNumberError();
          error = true;
        }
        if (!this.state.userAlertPhoneNumber) {
          this.phoneNumberError('A phone number is required');
          error = true;
        }

        if (!isValidCarrier(this.state.userAlertCarrier, this.props.carriers)) {
          // the carrier that is selected is not supported
          this.carrierError('This carrier is not supported');
          error = true;
        }

        if (!this.state.userAlertCarrier) {
          this.carrierError('A carrier is required');
          error = true;
        }
      }

      if (sendEmail) {
        if (!isValidEmail(this.state.userAlertEmail)) {
          this.emailError();
          error = true;
        }
        if (!this.state.userAlertEmail) {
          this.emailError('An email address is required');
        }
      }
    }

    return this.props.handleSubmit(
      error,
      this.state.userAlertEmail,
      this.state.userAlertPhoneNumber,
      this.state.userAlertCarrier,
      alertDescription,
      shortText,
      sendEmail,
    );
  }

  phoneNumberError = (error = invalidPhoneNumberString) => {
    if (this.props.alertPhonePreference) {
      this.setState({
        phoneNumberError: error,
      });
    } else {
      this.setState({
        phoneNumberError: null,
      });
    }
  }

  emailError = (error = invalidEmailString) => {
    if (this.state.alertEmailPreference) {
      this.setState({
        emailError: error,
      });
    } else {
      this.setState({
        emailError: null,
      });
    }
  }

  carrierError = (errorText) => {
    if (this.props.alertPhonePreference) {
      this.setState({
        carrierError: errorText,
      });
    } else {
      this.setState({
        carrierError: null,
      });
    }
  }

  resetValidation = (option) => {
    const newState = {};
    newState[option] = null;
    if (this.state[option]) {
      this.setState(newState);
    }
  }

  renderErrorMessage = () => (
    <div className='search-error-text-block' style={{ marginTop: '20px' }} >
      <p className='search-error-text'>There was an error submitting the form</p>
    </div>
  );

  render() {
    // this.logger('props: ', this.props);
    // this.logger('state: ', this.state);
    // this.logger('user: ', this.context.currentUser);

    const alertDescriptionError = this.props && this.props.descriptionError;
    const {
      carrier,
      flightNumber,
    } = this.props.params;
    const {
      departureAirport,
      arrivalAirport,
    } = this.props.flightInfo;

    return (
      <div>
        <form id='new-flight-alert-signup' method='POST'>
          <fieldset className='new-alert-fieldset description-set'>
            <Element name='new-flight-alert-description' />
            <p>Alert Description</p>

            <label htmlFor='flight-alert-description'>Enter a memorable name for this flight</label>
            <div className='col-xs-12' style={alertDescriptionError ? {} : { display: 'none' }}>
              <div className='flight-alert-error-text-container'>
                <p className='error-text'>
                  {alertDescriptionError}
                </p>
              </div>
            </div>
            <textarea
              className='flight-alert-description'
              name='flight-alert-description'
              ref='alertDescription'
              defaultValue={`${carrier} ${flightNumberInt(flightNumber)} ${departureAirport.code}->${arrivalAirport.code}`}
              rows='2'
              minLength='1'
              maxLength='140'
            />
          </fieldset>
          <MessageTypeSelect
            handleMessageSelect={this.handleMessageSelect}
            travelerMessages={this.props.travelerMessages}
            departureMessages={this.props.departureMessages}
            arrivalMessages={this.props.arrivalMessages}
            allMessages={this.props.allMessages}
            hasDeparted={this.props.flightInfo.isDepartedFlight}
            segmentHasLanded={this.props.flightInfo.isLandedFlight}
            segmentHasBeenCancelled={this.props.flightInfo.isCancelledFlight}
          />

          <Element name='new-flight-alert-message-type' />
          <AlertPreferences
            ref='AlertPreferences'
            alertEmailPreference={this.state.alertEmailPreference}
            alertPhonePreference={this.props.alertPhonePreference}
            carriers={this.props.carriers}
            currentEmail={this.props.currentEmail}
            currentPhoneNumber={this.props.profilePhoneNumber}
            profileEmailAddress={this.props.profileEmailAddress}
            profilePhoneNumber={this.props.profilePhoneNumber}
            handleEmailSelect={this.handleEmailSelect}
            handlePhoneNumberSelect={this.handlePhoneNumberSelect}
            handleCarrierSelect={this.handleCarrierSelect}
            handleAlertPreference={this.handleAlertPreference}
            alertSelectionError={this.state.alertSelectionError}
            phoneNumberError={this.state.phoneNumberError}
            submitButtonDisabled={this.submitDisabled}
            handleSubmit={this.handleSubmit}
            setPhoneNumberError={this.phoneNumberError}
            setEmailError={this.emailError}
            resetValidation={this.resetValidation}
            carrierError={this.state.carrierError}
            emailError={this.state.emailError}
            setAlertPhonePreference={this.props.setAlertPhonePreference}
            setCarrierError={this.carrierError}
          />

        </form>

        {this.props.errorOnSubmit ? <div>{this.renderErrorMessage()}</div> : null}
        {this.props.formValidated ? <div><p className='alert-form-success'> Alert Successfully Saved.</p></div> : null}
      </div>
    );
  }
}
