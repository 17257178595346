import React from 'react';
import PropTypes from 'prop-types';
import componentBase from '../../lib/component-base';
import downCaretPng from '../../../static/images/down-caret.png';

@componentBase('StandardFSDropDown')
export default class StandardFSDropDown extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    value: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.string,
    multiple: PropTypes.bool,
    list: PropTypes.array,
    onChange: PropTypes.func,
    labelValue: PropTypes.string,
    resetValue: PropTypes.string,
  };

  static defaultProps = {
    title: '',
    value: null,
    style: null,
    className: null,
    multiple: false,
    list: null,
  };

  constructor(props, context) {
    super(props, context);

    this.muiDefaultProps = {
      style: {
        // background position styled in sass for responsitivity
        // image loaded inline for webpackness
        backgroundColor: '#7ba1cb',
        backgroundImage: `url(${downCaretPng})`,
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'scroll',
        backgroundSize: '10px',
        color: '#FFF',
        height: '28px',
        maxHeight: '28px',
        borderRadius: '4px',
        overflow: 'hidden',
      },
      maxHeight: 700,
      autoWidth: true,
    };

    this.selectControl = null;
  }

  state = {};

  handleChangeEvent = (e) => {
    if (this.props.multiple) {
      const values = [];
      const options = e.target.options;
      let optionCount = e.target.options.length;
      let shouldReset = false;
      while (optionCount) {
        optionCount--;
        const item = options.item(optionCount);
        if (item.selected && item.value !== this.props.labelValue) {
          if (item.value === this.props.resetValue) {
            shouldReset = true;
          } else {
            values.push(item.value);
          }
        }
      }
      if (shouldReset) {
        this.selectControl && this.selectControl.blur();
        this.props.onChange(null);
      } else {
        this.props.onChange(values);
      }
    } else {
      for (let i = this.props.list.length - 1; i >= 0; i--) {
        if (e.target.value === this.props.list[i].key) {
          if (this.props.list[i].reset) {
            /** TODO @pmark: we shouldn't be setting props' values like this
             * ideally, this should be tracked in Redux and updated accordingly
            */
            this.props.list.forEach((item) => {
              item.checked = false; // eslint-disable-line no-param-reassign
            });
          } else {
            this.props.list[i].checked = !this.props.list[i].checked;
          }
          this.props.onChange(this.props.list[i], this.props.title);
          break;
        }
      }
    }
  };

  selectOptions = () => {
    const options = [
      <option value={this.props.title} key={this.props.title}>{this.props.title}</option>,
    ];

    this.props.list.forEach((item) => {
      const o = (
        <option value={item.value || ''} key={`${this.props.title}: ${item.key}`} disabled={item.disabled}>{item.label}</option>
      );
      options.push(o);
    });
    return options;
  };

  render() {
    const customStyle = { ...this.muiDefaultProps.style, ...this.props.style };

    const value = this.props.value || this.props.title;

    return (
      <div style={this.muiDefaultProps.style} className={`select-div-background ${this.props.className}`}>
        <select
          ref={(ref) => { this.selectControl = ref; }}
          style={{ ...customStyle, fontStyle: 'inherit' }}
          multiple={this.props.multiple}
          value={value || ''}
          onChange={this.handleChangeEvent}
        >
          {this.selectOptions()}
        </select>
        <input type='hidden' name='unfocus' />
      </div>
    );
  }
}
