import {
  curry,
  fromPairs,
  pipe,
} from 'ramda';

const arr2obj = curry((fn, arr) =>
  pipe(
    list => list.map(k => [k.toString(), fn(k)]),
    fromPairs,
  )(arr));

const regionsDict = {
  northAmerica: 'North America',
  europe: 'Europe',
  asia: 'Asia',
  oceania: 'Oceania',
  africa: 'Africa',
  southAmerica: 'South America',
};

export const regions = Object.keys(regionsDict);
export const regionText = Object.values(regionsDict);

export const getRegion = (name) => {
  const sanitizedName = name.replace('delay_index_', '').replace('_', '');
  const map = arr2obj(k => k, regions);
  return map[sanitizedName];
};

export const redirectToRegion = (name) => {
  const sanitizedName = name.toLowerCase().replace('delay_index_', '').replace('_', '');
  const map = {
    unitedkingdom: 'europe',
    japan: 'asia',
    usfaa: 'northAmerica',
    faa: 'northAmerica',
    northamerica: 'northAmerica',
    middleeast: 'africa',
    centralamerica: 'southAmerica',
  };
  return map[sanitizedName];
};

export const getRegionText = regionCode => regionsDict[regionCode];
