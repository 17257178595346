import React from 'react';
import PropTypes from 'prop-types';
import { ifElse } from 'ramda';
import componentBase from '../../../../../../../../../src/lib/component-base';

const allMessagesSelectedIcon = require('../../../../../../../../../static/images/flight-alerts/Selected State/FAIcon-AllMessages-ALT3-WHITE.svg');
const allMessagesDefaultIcon = require('../../../../../../../../../static/images/flight-alerts/Default State/FAIcon-AllMessages-ALT3.svg');
const departureMessagesSelectedIcon = require('../../../../../../../../../static/images/flight-alerts/Selected State/FAIcon-DepartureMessages-WHITE.svg');
const departureMessagesDefaultIcon = require('../../../../../../../../../static/images/flight-alerts/Default State/FAIcon-DepartureMessages.svg');
const arrivalMessagesSelectedIcon = require('../../../../../../../../../static/images/flight-alerts/Selected State/FAIcon-ArrivalMessages-WHITE.svg');
const arrivalMessagesDefaultIcon = require('../../../../../../../../../static/images/flight-alerts/Default State/FAIcon-ArrivalMessages.svg');
const travelerMessagesSelectedIcon = require('../../../../../../../../../static/images/flight-alerts/Selected State/FAIcon-TravelerMessages-WHITE.svg');
const travelerMessagesDefaultIcon = require('../../../../../../../../../static/images/flight-alerts/Default State/FAIcon-TravelerMessages.svg');

@componentBase('MessageTypeSelect')
export default class MessageTypeSelect extends React.Component {
  static propTypes = {
    handleMessageSelect: PropTypes.func,
    travelerMessages: PropTypes.bool,
    departureMessages: PropTypes.bool,
    arrivalMessages: PropTypes.bool,
    allMessages: PropTypes.bool,
    hasDeparted: PropTypes.bool,
    segmentHasLanded: PropTypes.bool,
    segmentHasBeenCancelled: PropTypes.bool,
  }

  handleMessageSelect = (e, onChangeOrClick = 'onChange', selectedMessageType) => {
    e && e.preventDefault();
    return onChangeOrClick === 'onChange' ? this.props.handleMessageSelect(e.target.value) : this.props.handleMessageSelect(selectedMessageType);
  }

  labelCannotBeClicked = ifElse(
    props => props.type === 'DEPARTURE',
    props => props.hasDeparted,
    props => props.cancelledOrLanded,
  )

  handleLabelClick = (e, type) => {
    e && e.preventDefault();
    const {
      hasDeparted,
      segmentHasLanded,
      segmentHasBeenCancelled,
    } = this.props;
    const cancelledOrLanded = segmentHasLanded || segmentHasBeenCancelled;
    const disabled = this.labelCannotBeClicked({
      type,
      hasDeparted,
      cancelledOrLanded,
    });
    if (!disabled) {
      this.handleMessageSelect(null, 'click', type);
    }
  }

  determineSelection = (val, condition) => {
    let result;

    if (val) {
      result = 'UNAVAILABLE';
    } else if (condition) {
      result = 'SELECTED';
    } else {
      result = 'SELECT';
    }

    return result;
  }

  render() {
    const {
      allMessages,
      arrivalMessages,
      departureMessages,
      hasDeparted,
      segmentHasBeenCancelled,
      segmentHasLanded,
      travelerMessages,
    } = this.props;
    const segmentCannotBeSelected = segmentHasLanded || segmentHasBeenCancelled;
    const segmentCannotBeSelectedClass = segmentCannotBeSelected ? 'label-disabled' : '';
    return (
      <div>
        <fieldset className='new-alert-fieldset alert-type' >
          <h5>Alert Type</h5>
          <p>Select which type of messages to receive</p>
          <div className='new-alert-fieldset-row'>
            <label className={`${allMessages ? 'label-selected-highlight' : 'label-unselected-drab'} ${segmentCannotBeSelectedClass}`} htmlFor='ALL'>
              <input
                type='radio'
                value='ALL'
                name='ALL'
                checked={allMessages}
                onChange={this.handleMessageSelect}
                disabled={segmentCannotBeSelected}
              />
              <div className={`alert-label-group ${segmentCannotBeSelectedClass}`}>
                <div className='alert-message-icon'>
                  <img alt='all messages selection icon' src={allMessages ? allMessagesSelectedIcon : allMessagesDefaultIcon} />
                </div>
                <div className='alert-label-title'>
                  <p className={segmentCannotBeSelected ? 'unavailable-message' : ''}>All Messages</p>
                  <p className={segmentCannotBeSelected ? 'unavailable-text' : ''}>{segmentCannotBeSelected ? 'This flight has already landed. All Messages unavailable' : 'Send me both flight arrival and departure updates'}</p>
                </div>
              </div>
              <div className={`alert-label-input-select ${segmentCannotBeSelectedClass}`} onClick={e => this.handleLabelClick(e, 'ALL')}>
                <p>{this.determineSelection(segmentCannotBeSelected, allMessages)}</p>
              </div>
            </label>
            <label className={`${travelerMessages ? 'label-selected-highlight' : 'label-unselected-drab'} ${segmentCannotBeSelectedClass}`} htmlFor='TRAVELER'>
              <input
                type='radio'
                value='TRAVELER'
                name='TRAVELER'
                checked={travelerMessages}
                onChange={this.handleMessageSelect}
                disabled={segmentCannotBeSelected}
              />
              <div className={`alert-label-group ${segmentCannotBeSelectedClass}`}>
                <div className='alert-message-icon'>
                  <img alt='traveler messages selection icon' src={travelerMessages ? travelerMessagesSelectedIcon : travelerMessagesDefaultIcon} />
                </div>
                <div className='alert-label-title'>
                  <p className={segmentCannotBeSelected ? 'unavailable-message' : ''}>Traveler Messages</p>
                  <p className={segmentCannotBeSelected ? 'unavailable-text' : ''}>{segmentCannotBeSelected ? 'This flight has already landed. Traveler Messages unavailable' : 'Send me just the updates that make sense for a traveler'}</p>
                </div>
              </div>
              <div className={`alert-label-input-select ${segmentCannotBeSelectedClass}`} onClick={e => this.handleLabelClick(e, 'TRAVELER')}>
                <p>{this.determineSelection(segmentCannotBeSelected, travelerMessages)}</p>
              </div>
            </label>
            <label className={`${departureMessages ? 'label-selected-highlight' : 'label-unselected-drab'} ${hasDeparted ? 'label-disabled' : ''}`} htmlFor='DEPARTURE'>
              <input
                type='radio'
                value='DEPARTURE'
                name='DEPARTURE'
                checked={departureMessages}
                disabled={hasDeparted ? 'disabled' : null}
                onChange={this.handleMessageSelect}
              />
              <div className={`alert-label-group ${segmentCannotBeSelectedClass}`}>
                <div className='alert-message-icon'>
                  <img alt='departure messages selection icon' src={departureMessages ? departureMessagesSelectedIcon : departureMessagesDefaultIcon} />
                </div>
                <div className='alert-label-title'>
                  <p className={hasDeparted ? 'unavailable-message' : ''}>Departure Messages</p>
                  <p className={hasDeparted ? 'unavailable-text' : ''}>{hasDeparted ? 'This flight has already departed. Departure Messages unavailable' : 'Send me flight departure updates'}</p>
                </div>
              </div>
              <div className={`alert-label-input-select ${segmentCannotBeSelectedClass} ${hasDeparted ? 'departed' : 'null'}`} onClick={e => this.handleLabelClick(e, 'DEPARTURE')}>
                <p>{this.determineSelection(hasDeparted, departureMessages)}</p>
              </div>
            </label>
            <label className={`${arrivalMessages ? 'label-selected-highlight' : 'label-unselected-drab'} ${segmentCannotBeSelectedClass}`} htmlFor='ARRIVAL'>
              <input
                type='radio'
                value='ARRIVAL'
                name='ARRIVAL'
                checked={arrivalMessages}
                onChange={this.handleMessageSelect}
                disabled={segmentCannotBeSelected}
              />
              <div className={`alert-label-group ${segmentCannotBeSelectedClass}`}>
                <div className='alert-message-icon'>
                  <img alt='arrival messages selection icon' src={arrivalMessages ? arrivalMessagesSelectedIcon : arrivalMessagesDefaultIcon} />
                </div>
                <div className='alert-label-title'>
                  <p className={segmentCannotBeSelected ? 'unavailable-message' : ''}>Arrival Messages</p>
                  <p className={segmentCannotBeSelected ? 'unavailable-text' : ''}>{segmentCannotBeSelected ? 'This flight has already landed. Arrival Messages unavailable' : 'Send me flight arrival updates'}</p>
                </div>
              </div>
              <div className={`alert-label-input-select ${segmentCannotBeSelectedClass}`} onClick={e => this.handleLabelClick(e, 'ARRIVAL')}>
                <p>{this.determineSelection(segmentCannotBeSelected, arrivalMessages)}</p>
              </div>
            </label>
          </div>
        </fieldset>
      </div>
    );
  }
}
