import * as t from './actionTypes';
import { TOAST_LIFESPAN_SEC } from './constants';

const timeouts = {};

function guid() {
  function r() {
    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
  }
  return `${r() + r() + r()}-${r()}_${r()}-${r()}_${r()}${r()}${r()}`;
}

function killTimeout(toastId) {
  clearTimeout(timeouts[toastId]);
  delete (timeouts[toastId]);
}

const ToastActions = {
  addToastWithTimeout: (toast) => {
    const newToast = {
      ...toast,
      id: guid(),
    };

    return (dispatch) => {
      // dispatch add action, then a delayed remove
      dispatch(newToast);

      if (!newToast.sticky) {
        const displayTimeSec = (parseInt(newToast.timeout, 10) || TOAST_LIFESPAN_SEC) * 1000;
        const timeout = setTimeout(() => {
          dispatch(ToastActions.close(newToast.id));
        }, displayTimeSec);
        timeouts[newToast.id] = timeout;
      }
    };
  },

  info: (message, title, sticky, timeout) => (
    ToastActions.addToastWithTimeout({
      message, title, sticky, timeout, type: t.INFO,
    })
  ),

  success: (message, title, sticky, timeout) => (
    ToastActions.addToastWithTimeout({
      message, title, sticky, timeout, type: t.SUCCESS,
    })
  ),

  warning: (message, title, sticky, timeout) => (
    ToastActions.addToastWithTimeout({
      message, title, sticky, timeout, type: t.WARNING,
    })
  ),

  error: (message, title, sticky, timeout) => (
    ToastActions.addToastWithTimeout({
      message, title, sticky, timeout, type: t.ERROR,
    })
  ),

  close: (toastId) => {
    killTimeout(toastId);
    return {
      type: t.CLOSE,
      id: toastId,
    };
  },

  stick: (toastId) => {
    killTimeout(toastId);
    return {
      type: t.STICK,
      id: toastId,
    };
  },
};

export default ToastActions;
