// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { setLockedFeatures } from './menu-utils';
import { getFSHost } from '../../../../../src/lib/hostname';

// import { SVGIcon } from '../../components/svg-icon';
// import { svgBackground } from '../../lib/asset-utils';

type CompactMenuItemProps = {
  clickHandler?: () => {},
  heading: string,
  link: string,
  locked: boolean,
  next: boolean,
  subscriptionLevel: string,
  visibility: mixed,
};

const MenuItem = styled.div`
  box-sizing: border-box;
  padding: 5px;
  color: black;
  border-top: 1px solid #f5f5f5;
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-image: url('https://assets.flightstats.com/v2/menu-icons/caret-right.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - 5px) center;
  background-size: 22px 22px;
`;

const Anchor = styled.div`
  text-decoration: none;
  color: black;
  padding; 4px 18px;
  width:100%;
  font-size: 1.25em;
  box-sizing: border-box;
  display: block;

  ${(props) =>
    props.locked &&
    `
    background-image: url('https://assets.flightstats.com/v2/menu-icons/lock-black.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 30px) center;
    background-size: 18px 18px;
  `}
`;

export const CompactMenuItem = ({
  clickHandler = () => {},
  visibility,
  subscriptionLevel,
  heading,
  link,
  locked,
  next,
}: CompactMenuItemProps) => {
  const lock = setLockedFeatures({ locked, visibility, subscriptionLevel });
  if (next) {
    // in link to the next.js app
    return (
      <a href={`${getFSHost()}${link}`}>
        <Anchor locked={lock}>
          <MenuItem>{heading}</MenuItem>
        </Anchor>
      </a>
    );
  }
  return (
    <Link to={link} onClick={clickHandler}>
      <Anchor locked={lock}>
        <MenuItem>{heading}</MenuItem>
      </Anchor>
    </Link>
  );
};
