import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { identity } from 'ramda';
import { push } from 'connected-react-router';

import { setSecondaryAction, setAirport } from '../../../redux/actions';
import AirportSelect from '../../../../../src/components/lib/AirportSelect';
import FSFlatButton from '../../../../../src/components/lib/FSFlatButton';
import componentBase from '../../../../../src/lib/component-base';
import { getAirportCodeSimple } from '../../../../../shared/lib/airport-code-helpers';

@componentBase('AirportCurrentConditionsSearch')
export default class AirportCurrentConditionsSearch extends Component {
  static propTypes = {
    airport: PropTypes.object,
    dispatch: PropTypes.func,
    isMobile: PropTypes.bool,
    onChange: PropTypes.func,
    callSecondaryAction: PropTypes.bool,
  };

  constructor(props, context) {
    super(props, context);
    this.state = { errorText: null };
  }

  componentWillReceiveProps() {
    this.setState({ errorText: null });
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(setAirport({}));
    dispatch(setSecondaryAction(false));
  }

  getChangeHandler(stateKey) {
    return (option, triggeredByEnter) => {
      const newState = {};

      /* eslint-disable no-underscore-dangle */
      if (option) {
        if (option.value) {
          newState[stateKey] = option.value._source;
        }

        if (option.diff) {
          newState.departureTimespan = option.diff;
        }
      } else {
        newState[stateKey] = null;
      }
      /* eslint-enable no-underscore-dangle */

      this.setState(newState);
      this.props.onChange && this.props.onChange(option, triggeredByEnter);
    };
  }

  handleSubmit = (e) => {
    e && e.preventDefault();
    if (this.props.airport && this.props.airport.value) {
      const { value } = this.props.airport;
      const { _source } = value;
      const code = getAirportCodeSimple(_source);
      const url = `/airport-conditions/${code}`;
      if (url) {
        this.props.dispatch(push(url));
        this.props.dispatch(setSecondaryAction(false));
      }
    } else {
      this.setState({
        errorText: 'Please enter a valid airport name or code',
      });
    }
  };

  render() {
    let error = '';

    if (this.state.errorText) {
      error = (
        <div className='row'>
          <div className='col-lg-6 col-md-6 col-sm-6' style={{ padding: '0 20px' }}>
            <p className='search-error-text'>{this.state.errorText}</p>
          </div>
        </div>
      );
    }

    return (
      <section className='airport-current-conditions-search'>
        <h4>Search for an Airport</h4>
        {error}
        <div className='row'>
          <form onSubmit={this.handleSubmit}>
            <div className='search-bar col-lg-6 col-md-6 col-sm-6' style={{ paddingLeft: '20px', paddingRight: '20px' }}>
              <AirportSelect
                name='airport'
                inputTitle='Search for an Airport'
                isMobile={this.props.isMobile}
                airportCode={this.state.airport && getAirportCodeSimple(this.state.airport)}
                onChange={this.getChangeHandler('airport')}
                airport={this.props.airport}
                shouldFocus
                secondaryAction={this.props.callSecondaryAction ? this.handleSubmit : identity}
              />
            </div>
            <div className='col-lg-4 col-md-5 col-sm-5' style={{ padding: '0 10px' }}>
              <FSFlatButton
                label='Search'
                onClick={this.handleSubmit}
                style={FSFlatButton.style({ margin: null, minWidth: null })}
              />
            </div>
          </form>
        </div>
      </section>
    );
  }
}
