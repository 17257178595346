// @flow
import React from 'react';
import styled from 'styled-components';
import { media } from '../../../src/lib/css-media';

type HeaderProps = {
  title: string,
  altTitle?: string,
  secondaryText?: string,
  altSecondaryText?: string,
  isMobile: boolean,
};

const HeaderWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 3px solid #faa718;
  margin: 10px 0 0;
  padding: 10px;
  ${media.tablet`
    flex-direction: column;
  `};
`;

const TitleWrapper = styled.h1`
  font-size: 1.61rem;
  font-weight: bold;
`;

const SecondaryTextWrapper = styled.h2`
  font-size: 1.61rem;
`;

export const PageHeader = ({
  title,
  altTitle,
  isMobile,
  secondaryText,
  altSecondaryText,
}: HeaderProps): React.Node => (
  <HeaderWrapper>
    <TitleWrapper>{isMobile && altTitle ? altTitle : title}</TitleWrapper>
    {secondaryText ? (
      <SecondaryTextWrapper>
        {isMobile && altSecondaryText ? altSecondaryText : secondaryText}
      </SecondaryTextWrapper>
    ) : (
      ''
    )}
  </HeaderWrapper>
);
