import { NAME } from './constants';

export const LOAD = `${NAME}/LOAD`;
export const LOAD_HISTORICAL = `${NAME}/LOAD_HISTORICAL`;
export const LOAD_HISTORICAL_SUCCESS = `${NAME}/LOAD_HISTORICAL_SUCCESS`;
export const LOAD_HISTORICAL_BY_ID_SUCCESS = `${NAME}/LOAD_HISTORICAL_BY_ID_SUCCESS`;
export const CHECK_HISTORICAL = `${NAME}/CHECK_HISTORICAL`;
export const CHECK_HISTORICAL_SUCCESS = `${NAME}/CHECK_HISTORICAL_SUCCESS`;
export const CHECK_FAIL = `${NAME}/CHECK_FAIL`;
export const LOAD_FAIL = `${NAME}/LOAD_FAIL`;
export const FILTER_BY_DAY = `${NAME}/FILTER_BY_DAY`;
export const FILTER_BY_MONTH = `${NAME}/FILTER_BY_MONTH`;
export const SET_FILTER = `${NAME}/SET_FILTER`;
export const GENERATE_MONTHLY_SUMMARIES = `${NAME}/GENERATE_MONTHLY_SUMMARIES`;
export const UPDATE_CURRENT_FILTER = `${NAME}/UPDATE_CURRENT_FILTER`;
export const UPDATE_COST = `${NAME}/UPDATE_COST`;
export const SET_COLNAME_FILTER = `${NAME}/SET_COLNAME_FILTER`;
export const SET_KEYWORD_FILTER = `${NAME}/SET_KEYWORD_FILTER`;
export const SET_FILTER_FROM_MONTH_SELECT = `${NAME}/SET_FILTER_FROM_MONTH_SELECT`;
export const REHYDRATE_PROPS = `${NAME}/REHYDRATE_PROPS`;
// //// for staging || development
export const LOAD_NEW_ALERT = `${NAME}/LOAD_NEW_ALERT`;
export const LOAD_NEW_ALERT_SUCCESS = `${NAME}/LOAD_NEW_ALERT_SUCCESS`;
export const LOAD_NEW_ALERT_FAIL = `${NAME}/LOAD_NEW_ALERT_FAIL`;
export const LOAD_TEST_FLIGHT_SCHEDULE = `${NAME}/LOAD_TEST_FLIGHT_SCHEDULE`;
export const LOAD_TEST_FLIGHT_SCHEDULE_SUCCESS = `${NAME}/LOAD_TEST_FLIGHT_SCHEDULE_SUCCESS`;
export const LOAD_TEST_FLIGHT_SCHEDULE_FAIL = `${NAME}/LOAD_TEST_FLIGHT_SCHEDULE_FAIL`;
// //// end for staging || development
