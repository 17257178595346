import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import componentBase from '../../../../../../src/lib/component-base';
import { dateFormatString } from '../../../../../../src/lib/date-time-format';
import { loadFlightSchedulesByCarrierFlightNumberAndDate } from '../../../../redux/Current/actions';
import SegmentSelect from './SegmentSelect';

@connect(state => ({
  flightSchedulesError: state.FlightAlerts.flightSchedulesError,
  flightSchedulesLoaded: state.FlightAlerts.flightSchedulesLoaded,
  flightSchedules: state.FlightAlerts.flightSchedulesResult,
}))
@componentBase('SegmentSelectContainer')
export default class SegmentSelectContainer extends React.Component {
  static propTypes = {
    // error: PropTypes.object,
    match: PropTypes.object,
    dispatch: PropTypes.func,
    // flightSchedulesError: PropTypes.object,
    // flightSchedulesLoaded: PropTypes.bool,
    flightSchedules: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  }

  componentDidMount() {
    const { match } = this.props;
    const { params } = match;
    this.props.dispatch(loadFlightSchedulesByCarrierFlightNumberAndDate(params));
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.flightSchedules !== null;
  }

  selectSegment = (segment) => {
    const path = this.newFlightAlertPath(segment);
    this.props.dispatch(push(path));
  }

  newFlightAlertPath = segment => (
    [
      '/flight-alerts',
      'new',
      segment.carrierFsCode,
      segment.flightNumber,
      this.props.match.params.year,
      this.props.match.params.month,
      this.props.match.params.day,
      segment.departureAirportFsCode,
      segment.arrivalAirportFsCode,
    ].join('/')
  )

  render() {
    let searchDate = null;
    const { match } = this.props;
    const { params } = match;
    const { day, month, year } = params;

    if (day && month && year) {
      searchDate =
        moment().set({ month: month - 1, date: day, year })
          .format(dateFormatString(this.context.currentUser));
    } else {
      searchDate = moment().format(dateFormatString(this.context.currentUser));
    }

    if (this.props && this.props.flightSchedules && this.props.flightSchedules.length > 0) {
      return (
        <div>
          <SegmentSelect
            segments={this.props.flightSchedules}
            searchDate={searchDate}
            selectSegment={this.selectSegment}
          />
        </div>
      );
    } else if (this.props.flightSchedules && this.props.flightSchedules.length === 0) {
      return (
        <div>
          <p>Sorry, we could not find any flights based on your query.</p>
        </div>
      );
    }

    return (
      <div />
    );
  }
}
