import React from 'react';
import PropTypes from 'prop-types';
import componentBase from '../../../../../src/lib/component-base';
import exclamationIcon from '../../../../../static/images/exclamation-icon.svg';
import { dismissAccountAttentionDialog } from '../../../../Account/redux/actions';
import LargeGenericModal from '../../../../../shared/components/LargeGenericModal/LargeGenericModal';

@componentBase('AccountAttentionDialog')
class AccountAttentionDialog extends React.Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    location: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    this.state = { open: props.open };
  }

  componentWillReceiveProps(nextProps) {
    const transitionalPaths = ['flight-tracker', 'account'];
    const pathname = nextProps.location.pathname;
    const destination = pathname && pathname.split('/')[1];
    const closed = transitionalPaths.includes(destination);
    this.setState({ open: (!closed && nextProps.open) });
  }

  onOK() {
    this.context.goto('/subscription', 'Resubscribe');
    this.context.store.dispatch(dismissAccountAttentionDialog());
  }

  onCancel() {
    this.context.goto('/flight-tracker/search', 'No Resubscription');
    this.context.store.dispatch(dismissAccountAttentionDialog());
  }

  render() {
    let dialog = null;

    const dialogBody = (
      <div style={{ margin: '0 10px 15px', color: 'black' }}>
        <h5 style={{
 color: '#E34623', textAlign: 'center', fontWeight: '600', textTransform: 'uppercase',
}}
        >Your subscription has ended.
        </h5>
        <p style={{ textAlign: 'center' }}>
          Our system attempted to automatically renew your monthly subscription,
          but there was a problem processing your credit card.
        </p>
        <p style={{ textAlign: 'center' }}>
          Please re-subscribe with a valid credit card and billing address.
        </p>
      </div>
    );

    if (this.state.open) {
      dialog = (
        <LargeGenericModal
          isModal
          modalOpen={this.state.open}
          closeModal={this.onCancel.bind(this)}
          title='Subscription Renewal'
          image={exclamationIcon}
          actionTitle='Subscribe'
          secondaryActionTitle='No, Thanks!'
          selectAction={this.onOK.bind(this)}
          secondaryActionBackgroundColor='transparent'
          secondaryActionHoverColor='#c6c6c6'
          body={dialogBody}
        />
      );
    }

    return dialog;
  }
}

export default AccountAttentionDialog;
