import debounce from 'debounce';
import MobileDetect from 'mobile-detect';
import mixin from './mixin';
import { breakpoints } from '../constants/style-constants';

function getCurrentBreakPoint() {
  if (typeof (window) === 'undefined') {
    return null;
  }

  try {
    let name = 'mobile';
    let value = breakpoints[name];
    const windowWidth = window.innerWidth || value;
    const mobileDetect = new MobileDetect(window.navigator.userAgent);
    const mobile = mobileDetect.mobile() != null;

    if (windowWidth >= breakpoints.desktopSm) {
      name = 'desktopLg';
      value = breakpoints.desktopLg;
    } else if (windowWidth >= breakpoints.tablet) {
      name = 'desktopSm';
      value = breakpoints.desktopSm;
    } else if (windowWidth >= breakpoints.mobile) {
      name = 'tablet';
      value = breakpoints.tablet;
    }

    return {
      name,
      value,
      isMobile: mobile,
    };
  } catch (ex) {
    return {
      name: 'mobile',
      value: breakpoints.mobile,
      isMobile: true,
    };
  }
}
// Add instance properties to the decorated component here.
const instanceMembersMixin = () => mixin({
  initResizeListener: function initResizeListener() {
    this.mediaBreakpoint = getCurrentBreakPoint();
    if (__CLIENT__ && !this.hasResizeListener) {
      this.handleWindowResize = debounce(() => {
        const currentBreakPoint = getCurrentBreakPoint();

        if (this.currentWidth !== currentBreakPoint.value) {
          this.currentWidth = currentBreakPoint.value;
          this.mediaBreakpoint = currentBreakPoint;
          this.handleResize();
        }
      }, 200);

      window.addEventListener('resize', this.handleWindowResize);
      this.hasResizeListener = true;
    }
  },
  removeResizeListener: function removeResizeListener() {
    window.removeEventListener('resize', this.handleWindowResize);
  },
});

export default () => Component => instanceMembersMixin()(Component);
