import React from 'react';
import PropTypes from 'prop-types';
import componentBase from '../../../../../../src/lib/component-base';
import { formattedTime } from '../../../../../../src/lib/date-time-format';
import constants from './_constants';
import TimeToDOM from '../../../../../../src/components/TimeToDOM/TimeToDOM';

@componentBase('List')
class List extends React.Component {
  static propTypes = {
    list: PropTypes.array,
    depArr: PropTypes.string,
    timesLabel: PropTypes.string,
    header: PropTypes.object,
  }

  constructor(props, context) {
    super(props, context, 'List');

    this.state = {
      sortAscending: true,
      columnSort: constants.LIST_SORT_KEYS.scheduledGate,
    };
  }

  render() {
    if (this.props.list && this.props.list.length > 0) {
      const tableRows = [];
      for (const flight of this.props.list) {
        let baggageClaim;
        let baggageClaimCellMobile;
        let baggageClaimCellPlaceHolder;
        let depViewBuffer = 'hide-mobile times-info-cell';

        if (this.props.depArr === 'arrivals') {
          baggageClaim = (<div className='hide-mobile baggage-info-cell'><p className='flight-list-bold-text'>{flight.baggageClaim}</p></div>);
          baggageClaimCellMobile = (
            <div className='baggage-info-cell display-mobile'>
              <div className='mobile-info-title'>
                <p style={{ textAlign: 'center' }}>Baggage Claim</p>
              </div>
              <div>
                <p className='flight-list-bold-text'>{flight.baggageClaim}</p>
              </div>
            </div>
          );
          baggageClaimCellPlaceHolder = (<div />);
        } else {
          depViewBuffer = 'hide-mobile times-info-cell cell-before-status';
        }

        let statusBold = (<p className='flight-list-status-bold-text'>{flight.status}</p>);
        let delayText = null;
        if (flight.isDelayed) {
          const hours = parseInt(flight.delayInMinutes / 60, 10);
          const justMinutes = parseInt(flight.delayInMinutes % 60, 10);
          delayText = (<p className='flight-list-delay-info-text'>Delayed by {hours > 0 ? `${hours}h ${justMinutes}m` : `${justMinutes} min`}</p>);
          statusBold = (<p className='flight-list-delay-bold-text'>{flight.status}</p>);
        }
        let operatedByRowColor = '';
        if (flight.operatedBy) operatedByRowColor = flight.rowColor;

        const flightUrl = process.env.NODE_ENV === 'development' ? `http://localhost:4000/v2${flight.url}` : `/v2${flight.url}`;

        tableRows.push(
          <a
            className={`list-card ${flight.rowColor}`}
            key={flightUrl}
            href={flightUrl}
            onClick={this.context.reportUserEvent.bind(this, 'Click', 'TrackFlight')}
            style={{ textDecoration: 'none' }}
          >
            <div className='flight-list-row'>
              <div className='airport-info-cell'>
                <p className='flight-list-bold-text'>{flight.airportIata}</p>
                <p className='display-mobile flight-list-info-text fs-mobile-airport-info'>{flight.airportName}</p>
              </div>
              <div className='flight-info-cell'>
                <p className='flight-list-bold-text flight-name-number-text'>{flight.flight}</p>
                <p className='display-mobile flight-list-info-text'>{flight.displayAirline}</p>
              </div>
              <div className={`status-info-cell ${this.props.depArr} display-mobile ${flight.rowColor}`}>
                <p className='flight-list-bold-text'>{flight.status}</p>{delayText}
              </div>
              <div className='display-mobile times-info-cell opaque-column'>
                <div className='mobile-info-title flight-time-block'>
                  <p>Gate {this.props.timesLabel} Times</p>
                  <div><p>Scheduled</p></div>
                  <div><p className='flight-list-right-text'>Actual</p></div>
                </div>
                <div className='time'>
                  <p className='flight-list-bold-text fs-mobile-text-align-left' style={{ minHeight: '15px' }}>
                    <TimeToDOM user={this.context.currentUser} time={formattedTime(this.context.currentUser, flight, 'scheduledGate24', 'scheduledGate')} componentName='list' isUtc={false} />
                  </p>
                  <div style={{ minHeight: '15px' }} />
                </div>
                <div className='time'>
                  <p className='flight-list-bold-text flight-list-right-text' style={{ minHeight: '15px' }}>
                    <TimeToDOM user={this.context.currentUser} time={formattedTime(this.context.currentUser, flight, 'actualGate24', 'actualGate')} componentName='list' isUtc={false} />
                  </p>
                  <p className='flight-list-info-text flight-list-right-text' style={{ minHeight: '15px' }}>{flight.actualGateDescription}</p>
                </div>
              </div>
              <div className='display-mobile times-info-cell'>
                <div className='mobile-info-title flight-time-block'>
                  <p>Runway {this.props.timesLabel} Times</p>
                  <div><p>Scheduled</p></div>
                  <div><p className='flight-list-right-text'>Actual</p></div>
                </div>
                <div className='time'>
                  <p className='flight-list-bold-text fs-mobile-text-align-left' style={{ minHeight: '15px' }}>
                    <TimeToDOM user={this.context.currentUser} time={formattedTime(this.context.currentUser, flight, 'scheduledRunway24', 'scheduledRunway')} componentName='list' isUtc={false} />
                  </p>
                  <div style={{ minHeight: '15px' }} />
                </div>
                <div className='time'>
                  <p className='flight-list-bold-text flight-list-right-text' style={{ minHeight: '15px' }}>
                    <TimeToDOM user={this.context.currentUser} time={formattedTime(this.context.currentUser, flight, 'actualRunway24', 'actualRunway')} componentName='list' isUtc={false} />
                  </p>
                  <p className='flight-list-info-text flight-list-right-text' style={{ minHeight: '15px' }}>{flight.actualRunwayDescription}</p>
                </div>
              </div>
              <div className='craft-info-cell hide-mobile'>
                <div className='mobile-info-title'><p>Craft Type</p></div>
                <p className='flight-list-bold-text'>{flight.equipmentIata}</p>
              </div>
              <div className={`terminal-info-cell ${this.props.depArr}`}>
                <div className='mobile-info-title'><p style={{ textAlign: 'center' }}>Terminal</p></div>
                <p className='flight-list-bold-text'>{flight.terminal}</p>
              </div>
              <div className={`gate-info-cell ${this.props.depArr}`}>
                <div className='mobile-info-title'><p style={{ textAlign: 'center' }}>Gate</p></div>
                <p className='flight-list-bold-text'>{flight.gate}</p>
              </div>
              {baggageClaimCellMobile}
              <div className='craft-info-cell display-mobile'>
                <div className='mobile-info-title'><p style={{ textAlign: 'center' }}>Craft Type</p></div>
                <p className='flight-list-bold-text'>{flight.equipmentIata}</p>
              </div>
              <div className='hide-mobile times-info-cell opaque-column'>
                <div className='mobile-info-title flight-time-block'>
                  <p>Gate {this.props.timesLabel} Times</p>
                  <div><p>Scheduled</p></div>
                  <div><p>Actual</p></div>
                </div>
                <div className='time'>
                  <p className='bold-text'>
                    <TimeToDOM user={this.context.currentUser} time={formattedTime(this.context.currentUser, flight, 'scheduledGate24', 'scheduledGate')} componentName='list' isUtc={false} />
                  </p>
                </div>
                <div className='time'>
                  <p className='bold-text'>
                    <TimeToDOM user={this.context.currentUser} time={formattedTime(this.context.currentUser, flight, 'actualGate24', 'actualGate')} componentName='list' isUtc={false} />
                  </p>
                </div>
              </div>
              <div className={depViewBuffer}>
                <div className='mobile-info-title flight-time-block'>
                  <p>Runway {this.props.timesLabel} Times</p>
                  <div><p>Scheduled</p></div>
                  <div><p>Actual</p></div>
                </div>
                <div className='time'>
                  <p className='bold-text'>
                    <TimeToDOM user={this.context.currentUser} time={formattedTime(this.context.currentUser, flight, 'scheduledRunway24', 'scheduledRunway')} componentName='list' isUtc={false} />
                  </p>
                </div>
                <div className='time'>
                  <p className='bold-text'>
                    <TimeToDOM user={this.context.currentUser} time={formattedTime(this.context.currentUser, flight, 'actualRunway24', 'actualRunway')} componentName='list' isUtc={false} />
                  </p>
                </div>
              </div>
              {baggageClaim}
              <div className={`status-info-cell ${this.props.depArr} hide-mobile ${flight.rowColor}`} />
            </div>

            <div className='hide-mobile flight-list-row'>
              <div className='airport-info-cell'>
                <p className='flight-list-info-text'>{flight.airportName}</p>
              </div>
              <div className='flight-info-cell'>
                <p className='flight-list-info-text'>{flight.displayAirline}</p>
              </div>
              <div className='craft-info-cell' />
              <div className={`terminal-info-cell ${this.props.depArr}`} />
              <div className={`gate-info-cell ${this.props.depArr}`} />
              <div className='craft-info-cell display-mobile'>
                <p className='flight-list-bold-text'>{flight.equipmentIata}</p>
              </div>
              <div className='times-info-cell opaque-column'>
                <div className='time' />
                <div className='time'>
                  <p className='flight-list-info-text'>{flight.actualGateDescription}</p>
                </div>
              </div>
              <div className={depViewBuffer}>
                <div className='time' />
                <div className='time'>
                  <p className='flight-list-info-text'>{flight.actualRunwayDescription}</p>
                </div>
              </div>
              {baggageClaimCellPlaceHolder}
              <div className={`status-info-cell ${this.props.depArr} hide-mobile ${flight.rowColor}`}>{statusBold}</div>
            </div>

            <div className={`hide-mobile flight-list-row bottom ${operatedByRowColor}`}>
              <div className='airport-info-cell'>
                <p className='operated-by-text'>{flight.operatedBy}</p>
              </div>
              <div className='flight-info-cell' />
              <div className='craft-info-cell' />
              <div className={`terminal-info-cell ${this.props.depArr}`} />
              <div className={`gate-info-cell ${this.props.depArr}`} />
              <div className='craft-info-cell display-mobile' />
              <div className='times-info-cell opaque-column' />
              <div className={depViewBuffer} />
              {baggageClaimCellPlaceHolder}
              <div className={`status-info-cell hide-mobile ${flight.rowColor}`}>{delayText}</div>
            </div>
          </a>);
      }
      return (<div className='flight-list'>
        {this.props.header}
        {tableRows}
      </div>
      );
    }
    return <div />;
  }
}


export default List;
