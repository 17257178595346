import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import moment from 'moment';
import { prop, ifElse } from 'ramda';
import { parse } from 'qs';

// relative paths
import FSFlatButton from '../../../../../../src/components/lib/FSFlatButton';
import handleFirstLoad from '../../../../../../shared/lib/handle-first-component-load';
import componentBase from '../../../../../../src/lib/component-base';
import {
  findSubscriptionByType,
  subscriptionReporterForLevel,
  subscriptionShortNameForLevel,
} from '../../../../../../shared/lib/subscription-level-matching';
import { dateFormatString } from '../../../../../../src/lib/date-time-format';
import TicketIconWHITE from '../../../../../../static/images/Ticket-Icon-WHITE.svg';
import MailSentIconWHITE from '../../../../../../static/images/MailSent-Icon-WHITE.svg';
import { openTab } from '../../../../../../src/utils/openTab';

// components
import HighlightedSection from '../shared/HighlightedSection';

const defaultString = '--';
const footerTitles = {
  account: 'Thank you for subscribing to FlightStats!',
  noAccount: 'Create an account!',
  notSubscribedYet: 'Subscribe today!',
};
const buttonTitles = {
  account: 'TRACK A FLIGHT',
  noAccount: 'CREATE',
  notSubscribedYet: 'SUBSCRIBE',
};
const URSignUp = 'You are signing up for a';
const highlightedSectionTitles = {
  account: 'You have signed up for a',
  noPlan: 'You are signing up for an',
  noAccount: URSignUp,
  notSubscribedYet: URSignUp,
};

@componentBase('RegistrationCompletion')
export default class RegistrationCompletion extends Component {
  static propTypes = {
    createText: PropTypes.string,
    getProfile: PropTypes.func,
    endSpinner: PropTypes.func,
    firstTransactionId: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
    ]),
    firstTransactionIdPending: PropTypes.bool,
    getFirstTransactionIdForSubscription: PropTypes.func,
    handleAlreadyHaveAccountClick: PropTypes.func,
    isFree: PropTypes.func,
    location: PropTypes.object,
    loggedIn: PropTypes.bool,
    planSelected: PropTypes.string,
    routerPush: PropTypes.func,
    setRegistrationStage: PropTypes.func,
    deleteRegistrationFlowToken: PropTypes.func,
    user: PropTypes.object,
    startSpinner: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);

    this.handlePrintInvoiceBtnClick = this.handlePrintInvoiceBtnClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.firstTransactionIdTimer = null;
    this.transactionCalls = 0;
    this.state = {
      firstLoad: true,
    };
  }

  componentDidMount() {
    const {
      endSpinner,
      isFree,
      planSelected,
      startSpinner,
      user,
    } = this.props;

    this.props.deleteRegistrationFlowToken();

    user && this.props.getProfile();

    if (isFree(planSelected)) {
      endSpinner();
    }
    if (user &&
        user.subscriptionBraintreeId &&
        !isFree(this.props.planSelected)) {
      /*
        gotta wait for the webhook to receive the receipt event
        from braintree before we know the transaction id
        and rendering receipt button
      */
      this.firstTransactionIdTimer = setInterval(() => {
        if (this.props.firstTransactionId || this.transactionCalls > 10) {
          endSpinner();
          clearInterval(this.firstTransactionIdTimer);
        } else {
          this.getFirstTransactionIdForUserSubscription(user.subscriptionBraintreeId);
          startSpinner();
          this.transactionCalls += 1;
        }
      }, 2000);
    }
  }

  componentWillReceiveProps(nextProps) {
    const newState = this.handleFirstLoad(this.state.firstLoad);
    this.setState(newState);
    const { endSpinner, firstTransactionIdPending } = this.props;
    const { firstTransactionId } = nextProps;
    if (firstTransactionId && firstTransactionIdPending) {
      endSpinner();
    }
  }

  componentWillUnmount() {
    this.props.endSpinner();
    clearInterval(this.firstTransactionIdTimer);
  }

  getFirstTransactionIdForUserSubscription = (subscriptionBraintreeId) => {
    this.props.getFirstTransactionIdForSubscription(subscriptionBraintreeId);
  }

  getAmountCharged = () => {
    const { location = {} } = this.props;
    const { search } = location;
    if (search) {
      const { type } = parse(search, { ignoreQueryPrefix: true });
      if (type) {
        const plan = findSubscriptionByType(type);
        if (plan) {
          return plan.cost;
        }
      }
    }
    return '--';
  }

  getSubscriptionName = () =>
    subscriptionShortNameForLevel(this.props.user && this.props.user.subscriptionLevel);

  reportConversion = () => {
    const { user } = this.props;
    const subscriptionLevel = prop('subscriptionLevel', user);
    const reporter = subscriptionReporterForLevel(subscriptionLevel);
    if (reporter) {
      reporter();
    }
  }

  handleFirstLoad = handleFirstLoad(this.reportConversion)

  handlePrintInvoiceBtnClick = (e, transactionId) => {
    if (transactionId) {
      openTab(`/v2/account/invoice/${transactionId}`, e);
    } else {
      this.context.toast.error('Please try again.', 'Error', false, 3);
    }
  }

  handleSubmit = (user = {}, createText, planSelected) => {
    if (user.subscriptionBraintreeId || this.props.isFree(planSelected)) {
      this.props.routerPush('/flight-tracker/search');
    } else {
      this.props.setRegistrationStage(createText);
      this.props.routerPush('/register/create');
    }
  }

  dateIsValid = date => date && moment(date).isValid && moment(date).isValid()
  dateString = (date, user) => moment(date).format(dateFormatString(user))
  formatDate = ifElse(
    ({ date }) => this.dateIsValid(date),
    ({ date, user }) => this.dateString(date, user),
    () => defaultString,
  )

  renderBody = ({
    date,
    planSelected = defaultString,
    amountCharged = defaultString,
    transactionId,
    email = defaultString,
    user,
  }) => {
    if (!user) {
      return (
        <div className='content completion'>
          <h3 className='no-account'>
            You have not yet created an account
          </h3>
        </div>
      );
    } else if (user && !user.subscriptionBraintreeId && !this.props.isFree(planSelected)) {
      // has not created a subscription yet
      return (
        <div className='content completion'>
          <h3 className='not-yet-sub'>
            You are not yet subscribed
          </h3>
        </div>
      );
    }
    return (
      <div className='content completion'>
        <div className='thank-you'>
          <h2 className='bold'>Thank you!</h2>
        </div>
        {
          this.renderTopHalf(planSelected) ? null :
          <div className='registration-info'>
            <div className='date'>
              Date:&nbsp;
              <span className='bold'>
                {this.formatDate({ date, user })}
              </span>
            </div>
            <div className='services'>
              Services:&nbsp;
              <span className='bold'>
                FlightStats&nbsp;
                <span className='fs-orange bold plan-selected'>
                  {this.getSubscriptionName()}
                </span>
                &nbsp;Account Subscription
              </span>
            </div>
            <div className='mo-charge-amount'>
              Monthly Charged Amount:&nbsp;
              <span className='bold'>
                {amountCharged}
              </span>
            </div>
            <div className='invoice-btn'>
              {
                transactionId ?
                  <FSFlatButton
                    label='VIEW RECEIPT'
                    onClick={e => this.handlePrintInvoiceBtnClick(e, transactionId)}
                    labelStyle={{ fontSize: '18px', fontWeight: '600' }}
                    className={`transaction-id-button${transactionId ? ' vis' : ''}`}
                  />
                : null
              }
            </div>
          </div>
        }
        <div className='verification'>
          <div className='title'>
            <img src={MailSentIconWHITE} alt='email-icon' />
            <h3>Email Verification</h3>
          </div>
          <div className='text'>
            <p>
              We sent a verification email
              to <span className='registration-email underline'>{email}</span> so
              that you can complete the signup process. Please open it and follow the link.
            </p>

            <p>
              REMINDER: Your email address is used for login.
            </p>
          </div>
        </div>
      </div>
    );
  }

  renderHighlightedSectionBtnFooterTitle = (planSelected, user, objMap) => {
    if (!planSelected || !user) {
      return objMap.noPlan;
    } else if (user && !user.subscriptionBraintreeId && !this.props.isFree(planSelected)) {
      return objMap.notSubscribedYet;
    }
    return objMap.account;
  }

  renderHighlightedSectionTitle = (planSelected = '', user) => {
    const text = this.renderHighlightedSectionBtnFooterTitle(
      planSelected,
      user,
      highlightedSectionTitles,
    );
    const planText = this.getSubscriptionName();
    return (
      <span>
        { text }
        <span className='planSelected fs-orange bold'>
          {` ${planText ? planText.toUpperCase() : ''} `}
        </span>
        Account!
      </span>
    );
  }

  renderTopHalf = planSelected => (this.props.isFree(planSelected))

  render() {
    const {
      user, firstTransactionId, planSelected, createText,
    } = this.props;

    return (
      <div className='account-registration'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>FlightStats - Completion</title>
        </Helmet>
        <HighlightedSection
          body={this.renderBody({
            date: user && user.createdAt,
            planSelected,
            amountCharged: this.getAmountCharged(),
            transactionId: firstTransactionId,
            email: user && user.email,
            user,
          })}
          buttonDisabled={false}
          buttonTitle={
            this.renderHighlightedSectionBtnFooterTitle(planSelected, user, buttonTitles)
          }
          footerTitle={
            this.renderHighlightedSectionBtnFooterTitle(planSelected, user, footerTitles)
          }
          handleAlreadyHaveAccountClick={this.props.handleAlreadyHaveAccountClick}
          hideAccountText
          icon={TicketIconWHITE}
          loggedIn={this.props.loggedIn}
          planSelected={planSelected}
          submit={() => this.handleSubmit(user, createText, planSelected)}
          title={this.renderHighlightedSectionTitle(planSelected, user)}
        />
      </div>
    );
  }
}
