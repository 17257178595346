import React, { useState } from 'react';
import GenericModal from '../../../shared/components/GenericModal/GenericModal';

export const NoSessionModal = () => {
  const [modalOpen, setModalOpen] = useState(true);
  const body = (
    <div style={{ color: 'black', display: 'flex', flexDirection: 'column' }}>
      <span>
        Your session has expired, and you were logged out.
      </span>
      <span style={{ marginTop: '8px', marginBottom: '8px' }}>
        Please refresh to continue.
      </span>
    </div>
  );

  return (
    <GenericModal
      modalOpen={modalOpen}
      closeModal={() => {
        setModalOpen(false);
      }}
      mainActionTitle='Refresh'
      body={body}
      handleOptionSelect={() => {
        window.location.reload();
      }}
      disabled={false}
      title={null}
    />
  );
};
