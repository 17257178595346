import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { identity, path, pathOr } from 'ramda';
import { connect } from 'react-redux';

import componentBase from '../../../src/lib/component-base';
import SearchSectionContainer from '../SearchSectionContainer/SearchSectionContainer';
import TravelDateSelectField from '../TravelDateSelectField/TravelDateSelectField';
import TravelTimeSelectField from '../TravelTimeSelectField/TravelTimeSelectField';
import FieldLabel from '../FieldLabel/FieldLabel';

// Advanced search
import AirportSelect from '../../../src/components/lib/AirportSelect';
import AirlineSelect from '../../../src/components/lib/AirlineSelect';
import flexErrors from '../../../src/constants/flex-errors';

import {
  setAdvancedSearchByAirportError,
  setByAirportAirline,
  setByAirportDepDate,
  setDepartureAirport,
  setArrivalAirport,
  setDepartureTime,
} from '../../../modules/FlightTracker/redux/Search/actions';

import {
  updateMultiFlightSearchCodeShareToggle,
  updateMultiFlightSearchDate,
} from '../../../modules/MultiFlight/redux/actions';

@connect(state => ({
  byAirportDepartureDate: state.FlightTrackerSearch.byAirportDepartureDate,
  byAirportError: state.FlightTrackerSearch.byAirportError,
  departureAirport: state.FlightTrackerSearch.departureAirport,
  arrivalAirport: state.FlightTrackerSearch.arrivalAirport,
  departureDate: state.FlightTrackerSearch.departureDate,
  departureTime: state.FlightTrackerSearch.departureTime,
  secondaryAdvancedSearchAction: state.FlightTrackerSearch.secondaryAdvancedSearchAction,
  secondaryAdvancedSearchActionByAirportAirline:
    state.FlightTrackerSearch.secondaryAdvancedSearchActionByAirportAirline,
  secondaryAdvancedSearchActionByAirportDepDate:
    state.FlightTrackerSearch.secondaryAdvancedSearchActionByAirportDepDate,
  secondaryAdvancedSearchActionDepAirport:
    state.FlightTrackerSearch.secondaryAdvancedSearchActionDepAirport,
  secondaryAdvancedSearchActionArrAirport:
    state.FlightTrackerSearch.secondaryAdvancedSearchActionArrAirport,
  secondaryAdvancedSearchActionTime:
    state.FlightTrackerSearch.secondaryAdvancedSearchActionTime,
}))
@componentBase('AdvancedSearchByAirportRoute')
export default class AdvancedSearchByAirportRoute extends Component {
  static propTypes = {
    // component props
    adUnit: PropTypes.object,
    dispatch: PropTypes.func,
    title: PropTypes.string,
    user: PropTypes.object,
    // search props
    arrivalAirport: PropTypes.object,
    byAirportAirline: PropTypes.object,
    byAirportDepartureDate: PropTypes.string,
    byAirportError: PropTypes.string,
    departureAirport: PropTypes.object,
    departureTime: PropTypes.number,
    history: PropTypes.object,
    secondaryAdvancedSearchActionByAirportAirline: PropTypes.bool,
    secondaryAdvancedSearchActionByAirportDepDate: PropTypes.bool,
    secondaryAdvancedSearchActionDepAirport: PropTypes.bool,
    secondaryAdvancedSearchActionArrAirport: PropTypes.bool,
    secondaryAdvancedSearchActionTime: PropTypes.bool,
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(setByAirportDepDate(this.initialByAirportDepartureDate(), false));
  }

  getCodes = (airLinePort) => {
    if (airLinePort) {
      const { value } = airLinePort;
      const { _source } = value;
      return (_source && (_source.fs || _source.iata || _source.icao)) || null;
    }
    return null;
  }

  changeState = (reduxAction, option, triggeredByEnter) => {
    this.props.dispatch(reduxAction(option, triggeredByEnter));
  }

  initialByAirportDepartureDate = () => {
    const { byAirportDepartureDate } = this.props;
    if (byAirportDepartureDate) {
      return moment(byAirportDepartureDate, 'YYYY/MM/DD').format('YYYY/M/D');
    }
    return moment().format('YYYY/M/D');
  }

  valueOrFalse = o => pathOr(false, ['value'], o)

  findIndexBySelectFieldOption = (currentOptions, option) =>
    currentOptions && currentOptions.findIndex(
      element => (
        this.valueOrFalse(element) === this.valueOrFalse(option)),
    )

  changeByAirportAirline = (option, triggeredByEnter) => {
    this.changeState(setByAirportAirline, option, triggeredByEnter);
  }

  changeByAirportDepartureDate = (option, triggeredByEnter) => {
    const { dispatch } = this.props;
    const indexValue = this.findIndexBySelectFieldOption(this.dateOfTravelOptions, option);
    dispatch(updateMultiFlightSearchDate({
      value: indexValue || 0,
    }));
    dispatch(setByAirportDepDate(option.value, triggeredByEnter));
  }

  changeDepartureAirport =
    (option, triggeredByEnter) => this.changeState(setDepartureAirport, option, triggeredByEnter);

  changeArrivalAirport =
    (option, triggeredByEnter) => this.changeState(setArrivalAirport, option, triggeredByEnter);

  changeDepartureTime =
    (option, triggeredByEnter) =>
      this.props.dispatch(setDepartureTime(option.value, triggeredByEnter));

  secondaryByAirportAction = (fromKeyDownHandler) => {
    if (fromKeyDownHandler) {
      // enter pressed in the flight number input inputField
      this.searchByAirportRoute();
    }
  }

  validateByAirportSearch = () => {
    const {
      departureAirport,
      arrivalAirport,
    } = this.props;

    // If there are no airports entered
    if (!departureAirport && !arrivalAirport) {
      return 'NO_INFORMATION_ENTERED';
    }

    return null;
  }

  searchByAirportRoute = () => {
    const {
      dispatch,
      history,
      user,
    } = this.props;
    this.context.reportButtonPress('Advanced Search By Airport or Route');
    const errorCode = this.validateByAirportSearch();
    dispatch(setAdvancedSearchByAirportError(errorCode));
    const hideCodeshares = pathOr(false, ['hideCodeshares'], user);
    dispatch(updateMultiFlightSearchCodeShareToggle(hideCodeshares));

    if (errorCode == null) {
      const destination = this.buildSearchByAirportUrl();
      const topOfStackHistory = path(['location', 'pathname'], history);
      if (destination && topOfStackHistory !== destination) {
        // multiple identical entries were being pushed into history on enter keydown
        window.location.href = `/v2${destination}`;
      }
    }
  }

  buildSearchByAirportUrl = () => {
    const {
      arrivalAirport,
      byAirportAirline,
      departureAirport,
      byAirportDepartureDate,
      departureTime,
    } = this.props;

    const airlineCode = this.getCodes(byAirportAirline);
    const departureAirportCode = this.getCodes(departureAirport);
    const arrivalAirportCode = this.getCodes(arrivalAirport);
    const [year, month, day] = byAirportDepartureDate.split('/');

    if (departureAirportCode && !arrivalAirportCode) {
      return `/flight-tracker/departures/${departureAirportCode}${airlineCode ? `/${airlineCode}` : ''}?year=${year}&month=${month}&date=${day}&hour=${departureTime}`;
    } else if (arrivalAirportCode && !departureAirportCode) {
      return `/flight-tracker/arrivals/${arrivalAirportCode}${airlineCode ? `/${airlineCode}` : ''}?year=${year}&month=${month}&date=${day}&hour=${departureTime}`;
    } else if (departureAirportCode && arrivalAirportCode) {
      return `/flight-tracker/route/${departureAirportCode}/${arrivalAirportCode}${airlineCode ? `/${airlineCode}` : ''}?year=${year}&month=${month}&date=${day}&hour=${departureTime}`;
    }

    return null;
  }

  render() {
    const {
      adUnit,
      byAirportAirline,
      byAirportError,
      title,
      user,
    } = this.props;

    const subscriptionActive = pathOr(false, ['subscriptionActive'], user);
    const byAirportErrorBlock = byAirportError ? (
      <div className='search-error-text-block'>
        <p className='search-error-text'>{flexErrors(byAirportError)}</p>
      </div>
    ) : '';

    return (
      <SearchSectionContainer
        adUnit={adUnit}
        searchAction={this.searchByAirportRoute}
        subscriptionActive={subscriptionActive}
        title={title}
      >
        <div className='AdvancedSearchByAirportRoute'>
          {byAirportErrorBlock}
          <div className='half-fields-container'>
            <div className='search-field'>
              <FieldLabel text='Departure Airport' />
              <AirportSelect
                inputTitle='Departure Airport'
                name='departureAirport'
                onChange={this.changeDepartureAirport}
                airport={this.props.departureAirport}
                secondaryAction={
                  this.props.secondaryAdvancedSearchActionDepAirport ?
                    this.secondaryByAirportAction : identity
                }
                selectOnBlur
              />
            </div>
            <div className='search-field'>
              <FieldLabel text='Arrival Airport' />
              <AirportSelect
                name='arrivalAirport'
                inputTitle='Arrival Airport'
                onChange={this.changeArrivalAirport}
                airport={this.props.arrivalAirport}
                secondaryAction={
                  this.props.secondaryAdvancedSearchActionArrAirport ?
                    this.secondaryByAirportAction : identity
                }
                selectOnBlur
              />
            </div>
          </div>
          <div className='half-fields-container'>
            <div className='search-field'>
              <FieldLabel text='Airline' />
              <AirlineSelect
                name='airline'
                airline={byAirportAirline}
                onChange={this.changeByAirportAirline}
                secondaryAction={
                  this.props.secondaryAdvancedSearchActionByAirportAirline ?
                  this.secondaryByAirportAction : identity
                }
                selectOnBlur
              />
            </div>
            <div className='date-time-fields'>
              <div className='search-field quarter'>
                <FieldLabel text='Date' />
                <TravelDateSelectField
                  value={this.props.byAirportDepartureDate}
                  onChange={this.changeByAirportDepartureDate}
                  secondaryAction={this.props.secondaryAdvancedSearchActionByAirportDepDate ?
                    this.secondaryByAirportAction : identity}
                  user={user}
                />
              </div>
              <div className='search-field quarter'>
                <FieldLabel text='Time' />
                <TravelTimeSelectField
                  value={this.props.departureTime}
                  onChange={this.changeDepartureTime}
                  secondaryAction={this.props.secondaryAdvancedSearchActionTime ?
                    this.secondaryByAirportAction : identity}
                  user={user}
                />
              </div>
            </div>
          </div>
        </div>
      </SearchSectionContainer>
    );
  }
}
