import React from 'react';
import PropTypes from 'prop-types';
import { path } from 'ramda';

import Ad from '../Ad/Ad';
import componentBase from '../../../src/lib/component-base';

@componentBase('BannerAd')
export default class BannerAd extends React.Component {
  static propTypes = {
    desktopAd: PropTypes.object,
    mobileAd: PropTypes.object,
    needSpace: PropTypes.bool,
    noAdOnLoad: PropTypes.bool,
    style: PropTypes.object,
    shouldUpdateAdsException: PropTypes.bool,
    subscriptionActive: PropTypes.bool,
    topAd: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      renderBecauseWindow: false,
    };

    this.timer = null;
  }

  componentDidMount() {
    this.timer = setTimeout(() => {
      this.setState({
        renderBecauseWindow: true,
      });
    }, 200);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  formatAdSize = () => {
    const { desktopAd, mobileAd } = this.props;
    let isSmall;
    if (window && document) {
      const w = window;
      const d = document;
      const e = d.documentElement;
      const g = d.getElementsByTagName('body')[0];
      const width = w.innerWidth || e.clientWidth || g.clientWidth;
      isSmall = (width < 768);
    }

    const { isMobile } = path(['context', 'mediaBreakpoint'], this);
    return (isMobile || isSmall) ? mobileAd : desktopAd;
  }

  render() {
    const {
      renderBecauseWindow,
    } = this.state;
    const {
      needSpace,
      noAdOnLoad,
      style,
      shouldUpdateAdsException,
      subscriptionActive,
      topAd,
    } = this.props;
    let defaultRender = null;

    if (topAd && needSpace) {
      defaultRender = <div className='DefaultBannerAd' />;
    }

    if (subscriptionActive || !renderBecauseWindow) {
      return defaultRender;
    }
    const adObj = this.formatAdSize();

    if (!adObj || !adObj.id) {
      return defaultRender;
    }
    return (
      <div className='BannerAd' style={style || {}}>
        <center className={this.props.topAd ? 'topAd' : ''}>
          <Ad
            noAdOnLoad={noAdOnLoad}
            {...adObj}
            key={adObj.id}
            shouldUpdateAdsException={shouldUpdateAdsException}
            subscriptionActive={subscriptionActive}
          />
        </center>
      </div>
    );
  }
}
