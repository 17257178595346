import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import CommonSectionWrapper from '../../../../../../shared/components/CommonSectionWrapper/CommonSectionWrapper';
import { FlightTracker } from '../../../../../App/ads'; // same ads as flight tracker is ok


export default class OutOfDateRange extends React.Component {
  static propTypes = {
    airline: PropTypes.object,
    match: PropTypes.object,
    subscriptionActive: PropTypes.bool,
  };

  createHeader = (airline, match) => {
    const {
      name,
    } = airline;
    const {
      carrier,
      flightNumber,
    } = match.params;
    return (
      `${carrier ? `(${carrier}) ` : ''}${name ? `${name} ` : ''}${flightNumber ? `${flightNumber} ` : ''}Flight Tracker`
    );
  }

  render() {
    const {
      airline,
      match,
      subscriptionActive,
    } = this.props;
    return (
      <div className='out-of-range'>
        <h1>
          { this.createHeader(airline, match) }
        </h1>
        <CommonSectionWrapper
          adUnit={FlightTracker.top}
          title='Flight Status Not Available'
          subscriptionActive={subscriptionActive}
        >
          <p>
            <Fragment>
              The flight you are searching for appears to be a flight more than 3 days in the past.
              The Flight Tracker only allows for searches +- 3 days from the current date.
              For flights older than 3 days in the past, please use our&nbsp;
            </Fragment>
            <Link
              to='/historical-flight'
              className='historical-link'
            >
              Historical Flight Status
            </Link>
            <Fragment>
               &nbsp;feature.
            </Fragment>
          </p>
        </CommonSectionWrapper>
      </div>
    );
  }
}
