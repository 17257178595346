/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import componentBase from '../../lib/component-base';
import AsyncElasticsearchSelect from './AsyncElasticsearchSelect';
import { getAirportCodeSimple } from '../../../shared/lib/airport-code-helpers';

const placeholderBase = 'Example: PDX or Portland';

@connect(state => ({
  isMobile: state.App.userAgent.isMobile,
}))
@componentBase('AirportSelect')
export default class AirportSelect extends Component {
  static propTypes = {
    name: PropTypes.string,
    airport: PropTypes.object,
    inputTitle: PropTypes.string,
    isMobile: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDelete: PropTypes.func,
    onEnterKeyPress: PropTypes.func,
    selectOnBlur: PropTypes.bool,
    shouldFocus: PropTypes.bool,
    styleProps: PropTypes.object,
    secondaryAction: PropTypes.func,
    isHistory: PropTypes.bool
  };

  componentDidMount() {
    if (this.props.onEnterKeyPress) {
      if (this.refs && this.refs.select && this.refs.select.refs && this.refs.select.refs.select &&
        this.refs.select.refs.select.refs) {
        const underlyingControl = this.refs.select.refs.select.refs.control;
        if (underlyingControl) {
          underlyingControl.onkeydown = (e) => {
            if (e.keyCode === 13) { // enter
              this.props.onEnterKeyPress();
            }
          };
        }
      }
    }
  }

  onBlur = (event, searchTerm, option) => {
    if (this.props.onBlur) {
      this.props.onBlur(event, searchTerm, option);
    }
  }

  onChange = (option, triggeredByEnter) => {
    if (this.props.onChange) {
      this.props.onChange(option, triggeredByEnter);
    }
  }

  onDelete = (option) => {
    if (this.props.onDelete) {
      this.props.onDelete();
    } else if (this.props.onChange) {
      /*
        fallback, not all instances of airport select hand a onDelete,
        original callback was onChange
      */
      this.props.onChange(option);
    }
  }

  render() {
    const {
      styleProps,
      inputTitle,
      name,
      airport,
      isMobile,
      secondaryAction,
      selectOnBlur,
    } = this.props;
    const formatQuery = input => ({ query: input });
    const formatItem = item => `(${getAirportCodeSimple(item._source)}) ${item._source.name}`;
    const airportCode = airport &&
                        airport.value &&
                        airport.value._source &&
                        getAirportCodeSimple(airport.value._source);

    return (
      <div>
        <AsyncElasticsearchSelect
          name={name || 'airport'}
          isHistory={this.props.isHistory}
          input={airportCode}
          inputTitle={inputTitle}
          selectedItem={airport}
          initializerSource='airportLookup'
          isMobile={isMobile}
          onBlur={this.onBlur}
          placeholder={isMobile ? placeholderBase : `${placeholderBase} International Airport`}
          onChange={this.onChange}
          onDelete={this.onDelete}
          queryFormatter={formatQuery}
          itemFormatter={formatItem}
          searchSource='airport'
          shouldFocus={this.props.shouldFocus}
          styleProps={styleProps}
          selectOnBlur={selectOnBlur}
          secondaryAction={secondaryAction}
        />
      </div>
    );
  }
}
/* eslint-enable no-underscore-dangle */
