import React from 'react';
import PropTypes from 'prop-types';

const RegistrationTermsAndConditions = (props) => {
  const {
    planSelected,
  } = props;

  const isFree = planSelected === 'free';

  const link = () => (isFree ? '/company/legal/terms-of-use' : '/v2/company/legal/flightstats-subscription-agreement-terms-and-conditions');

  const subscriptionTitle = 'Flightstats Subscription Agreement ';

  return (<div style={{ display: 'inline' }}>
    <div
      className='account-create-input-title'
      style={{
 display: 'inline-block', paddingLeft: '0', marginLeft: '2px', lineHeight: '23px', verticalAlign: 'bottom', marginTop: '0',
}}
    >
      I have read and agree to the
    </div>
    <a
      href={link()}
      className='account-create-link'
      target='_blank'
      rel='noopener noreferrer'
    >
      {isFree ? '' : subscriptionTitle}Terms and Conditions
    </a>
  </div>);
};

RegistrationTermsAndConditions.propTypes = {
  planSelected: PropTypes.string,
};

export default RegistrationTermsAndConditions;
