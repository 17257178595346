/**
 * If password rules change, there is a related validator
 * used in the users service
 * please keep them in parity
 */

// if more special chars are needed, add them to the string below
// dont forget to check the users service, similar validation is done there, too!
const allowedSpecialChars = " !\"#$%&'()*+,-./:;<=>?@[\]^_`{|}~";
const specialCharsAsArray = allowedSpecialChars.split('');
const specialCharsAsObjectKeys = {};
specialCharsAsArray.forEach((char) => {
  specialCharsAsObjectKeys[`${char}`] = true;
});

/*
  now specialCharsAsObjectKeys looks like:
  {
    '!': true,
    '#': true,
    ...etc
  }
  lookups now constant time
  (versus iterating through allowedSpecialChars string)
*/

const lowercaseLetters = {
  a: true,
  b: true,
  c: true,
  d: true,
  e: true,
  f: true,
  g: true,
  h: true,
  i: true,
  j: true,
  k: true,
  l: true,
  m: true,
  n: true,
  o: true,
  p: true,
  q: true,
  r: true,
  s: true,
  t: true,
  u: true,
  v: true,
  w: true,
  x: true,
  y: true,
  z: true,
};

const uppercaseLetters = {
  A: true,
  B: true,
  C: true,
  D: true,
  E: true,
  F: true,
  G: true,
  H: true,
  I: true,
  J: true,
  K: true,
  L: true,
  M: true,
  N: true,
  O: true,
  P: true,
  Q: true,
  R: true,
  S: true,
  T: true,
  U: true,
  V: true,
  W: true,
  X: true,
  Y: true,
  Z: true,
};

const digits = {
  1: true,
  2: true,
  3: true,
  4: true,
  5: true,
  6: true,
  7: true,
  8: true,
  9: true,
  0: true,
};

const boolRepresentedAsNumber = bool => (bool ? 1 : 0);

// return values should match up with errorDictionary in auth routes
export const validatePassword = (passwordString) => {
  const errors = [];

  let atLeast8CharsLong = false;
  let atMost30CharsLong = false;
  // three of the four for the ones below
  let hasLowerCase = false;
  let hasUpperCase = false;
  let hasNumbers = false;
  let includesSpecialCharacters = false;
  let threeOfFourRulesHaveBeenMet = false;

  const countAtLeastThreeOfFour = () => {
    /*
      3 of 4 of
      booleans hasLowerCase, hasUpperCase, includesSpecialCharacters, includesSpecialCharacters
      needs to be set to true.

      when that is the case, threeOfFourRulesHaveBeenMet boolean
      will be set to true by this function
    */
    const hasLowerCaseNumber = boolRepresentedAsNumber(hasLowerCase);
    const hasUpperCaseNumber = boolRepresentedAsNumber(hasUpperCase);
    const hasNumbersNumber = boolRepresentedAsNumber(hasNumbers);
    const includesSpecialCharactersNumber = boolRepresentedAsNumber(includesSpecialCharacters);

    const currentCount = (
      hasLowerCaseNumber
      + hasUpperCaseNumber
      + hasNumbersNumber
      + includesSpecialCharactersNumber
    );

    if (currentCount >= 3) {
      threeOfFourRulesHaveBeenMet = true;
    }
  };

  // lets look for errors
  const numberOfChars = passwordString.length;
  if (numberOfChars >= 8) {
    atLeast8CharsLong = true;
  }
  if (numberOfChars <= 30) {
    atMost30CharsLong = true;
  }
  for (let i = 0; i < numberOfChars; i++) {
    const currentChar = passwordString[i];

    if (currentChar in lowercaseLetters) {
      hasLowerCase = true;
      countAtLeastThreeOfFour();
    }
    if (currentChar in uppercaseLetters) {
      hasUpperCase = true;
      countAtLeastThreeOfFour();
    }
    if (currentChar in digits) {
      hasNumbers = true;
      countAtLeastThreeOfFour();
    }
    if (currentChar in specialCharsAsObjectKeys) {
      includesSpecialCharacters = true;
      countAtLeastThreeOfFour();
    }
  }

  // booleans are set, lets put some errors into the array we've set aside for that
  const passwordLengthOkay = atLeast8CharsLong && atMost30CharsLong;
  if (!atLeast8CharsLong) {
    errors.push('PASSWORD_SHORT');
  }
  if (!atMost30CharsLong) {
    errors.push('PASSWORD_LONG');
  }

  if (!hasLowerCase) {
    errors.push('PASSWORD_NO_LOWERCASE');
  }
  if (!hasUpperCase) {
    errors.push('PASSWORD_NO_UPPERCASE');
  }
  if (!hasNumbers) {
    errors.push('PASSWORD_NO_NUMBER');
  }
  if (!includesSpecialCharacters) {
    errors.push('PASSWORD_NO_SPECIAL_CHAR');
  }

  return {
    valid: passwordLengthOkay && threeOfFourRulesHaveBeenMet,
    errors,
  };
};
