import { NAME } from './constants';

export const LOAD_NEW_ALERT = `${NAME}/LOAD_NEW_ALERT`;
export const LOAD_NEW_ALERT_SUCCESS = `${NAME}/LOAD_NEW_ALERT_SUCCESS`;
export const LOAD_NEW_ALERT_FAIL = `${NAME}/LOAD_NEW_ALERT_FAIL`;

export const LOAD_CURRENT = `${NAME}/LOAD_CURRENT`;
export const LOAD_CURRENT_FAIL = `${NAME}/LOAD_CURRENT_FAIL`;
export const LOAD_CURRENT_SUCCESS = `${NAME}/LOAD_CURRENT_SUCCESS`;

export const SORT = `${NAME}/SORT`;

export const LOAD_TEST_ALERT = `${NAME}/LOAD_TEST_ALERT`;
export const LOAD_TEST_ALERT_SUCCESS = `${NAME}/LOAD_TEST_ALERT_SUCCESS`;
export const LOAD_TEST_ALERT_FAIL = `${NAME}/LOAD_TEST_ALERT_FAIL`;

export const LOAD_DELETE_FLIGHT_ALERT = `${NAME}/LOAD_DELETE_FLIGHT_ALERT`;
export const LOAD_DELETE_FLIGHT_ALERT_SUCCESS = `${NAME}/LOAD_DELETE_FLIGHT_ALERT_SUCCESS`;
export const LOAD_DELETE_FLIGHT_ALERT_FAIL = `${NAME}/LOAD_DELETE_FLIGHT_ALERT_FAIL`;

export const LOAD_DEACTIVATE_ALERT = `${NAME}/LOAD_DEACTIVATE_ALERT`;
export const LOAD_DEACTIVATE_SUCCESS = `${NAME}/LOAD_DEACTIVATE_SUCCESS`;
export const LOAD_DEACTIVATE_FAIL = `${NAME}/LOAD_DEACTIVATE_FAIL`;

export const LOAD_REACTIVATE_ALERT = `${NAME}/LOAD_REACTIVATE_ALERT`;
export const LOAD_REACTIVATE_SUCCESS = `${NAME}/LOAD_REACTIVATE_SUCCESS`;
export const LOAD_REACTIVATE_FAIL = `${NAME}/LOAD_REACTIVATE_FAIL`;

export const LOAD_FLIGHT_SCHEDULES = `${NAME}/LOAD_FLIGHT_SCHEDULES`;
export const LOAD_FLIGHT_SCHEDULES_SUCCESS = `${NAME}/LOAD_FLIGHT_SCHEDULES_SUCCESS`;
export const LOAD_FLIGHT_SCHEDULES_FAIL = `${NAME}/LOAD_FLIGHT_SCHEDULES_FAIL`;

export const LOAD_USER_HISTORY = `${NAME}/LOAD_USER_HISTORY`;
export const LOAD_USER_HISTORY_SUCCESS = `${NAME}/LOAD_USER_HISTORY_SUCCESS`;
export const LOAD_USER_HISTORY_FAIL = `${NAME}/LOAD_USER_HISTORY_FAIL`;

export const LOAD_DELIVERY_POINTS = `${NAME}/LOAD_DELIVERY_POINTS`;
export const LOAD_DELIVERY_POINTS_SUCCESS = `${NAME}/LOAD_DELIVERY_POINTS_SUCCESS`;
export const LOAD_DELIVERY_POINTS_FAIL = `${NAME}/LOAD_DELIVERY_POINTS_FAIL`;

export const CHECK_FLIGHT_SCHEDULES = `${NAME}/CHECK_FLIGHT_SCHEDULES`;
export const CHECK_FLIGHT_SCHEDULES_SUCCESS = `${NAME}/CHECK_FLIGHT_SCHEDULES_SUCCESS`;
export const CHECK_FLIGHT_SCHEDULES_FAIL = `${NAME}/CHECK_FLIGHT_SCHEDULES_FAIL`;
export const CHECKING_SCHEDULES_RESULTS_ERROR = `${NAME}/CHECKING_SCHEDULES_RESULTS_ERROR`;

export const LOAD_CURRENT_DATE = `${NAME}/LOAD_CURRENT_DATE`;
export const LOAD_CURRENT_DATE_SUCCESS = `${NAME}/LOAD_CURRENT_DATE_SUCCESS`;
export const LOAD_CURRENT_DATE_FAIL = `${NAME}/LOAD_CURRENT_DATE_FAIL`;

export const SET_ALERT_PHONE_PREFERENCE = `${NAME}/SET_ALERT_EMAIL_PREFERENCE`;

export const SET_SECONDARY_ACTION = `${NAME}/SET_SECONDARY_ACTION`;

export const SET_QUERY_ERROR = `${NAME}/SET_QUERY_ERROR`;
