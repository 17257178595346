import React from 'react';
import PropTypes from 'prop-types';
import { scroller } from 'react-scroll';
import NewAlertForm from './NewAlertForm/NewAlertForm';
import componentBase from '../../../../../../src/lib/component-base';
import carrierOptions from '../../../../../../shared/lib/carrierOptions';
import FlightAlertsDetailHeader from '../../../../../../shared/components/FlightAlertsDetailHeader/FlightAlertsDetailHeader';
import FlightAlertsHeader from '../../../FlightAlertsIndex/children/FlightAlertsHeader/FlightAlertsHeader';
import { isBusinessUser } from '../../../../../../src/utils/isBusinessUser';
import { flightNumberInt } from '../../../../../../src/utils/validation';

for (let i = 0; i < carrierOptions.length; i++) {
  carrierOptions[i].selected = false;
}

@componentBase('NewFlightAlert')
export default class NewFlightAlert extends React.Component {
  static propTypes = {
    alertPhonePreference: PropTypes.bool,
    flightInfo: PropTypes.object,
    params: PropTypes.object,
    handleSubmit: PropTypes.func,
    errorOnSubmit: PropTypes.bool,
    submitButtonDisabled: PropTypes.bool,
    creationSuccess: PropTypes.bool,
    date: PropTypes.string,
    unableToCreateAlert: PropTypes.string,
    setAlertPhonePreference: PropTypes.func,
  };

  state = {
    errorOnSubmit: this.props.errorOnSubmit,
    formValidated: false,
    travelerMessages: false,
    departureMessages: false,
    arrivalMessages: false,
    allMessages: true,
    descriptionError: false,
  }

  getMessageTypeString = () => {
    const messages = {
      travelerMessages: this.state.travelerMessages,
      arrivalMessages: this.state.arrivalMessages,
      departureMessages: this.state.departureMessages,
      allMessages: this.state.allMessages,
    };
    let messageString = '';
    for (const key in messages) {
      if (messages[key]) {
        messageString = key;
      }
    }
    return messageString;
  }

  handleMessageSelect = (value) => {
    switch (value) {
      case 'ALL':
        this.setState({
          allMessages: true,
          travelerMessages: false,
          departureMessages: false,
          arrivalMessages: false,
        });
        break;
      case 'TRAVELER':
        this.setState({
          travelerMessages: true,
          allMessages: false,
          departureMessages: false,
          arrivalMessages: false,
        });
        break;
      case 'DEPARTURE':
        this.setState({
          departureMessages: true,
          allMessages: false,
          travelerMessages: false,
          arrivalMessages: false,
        });
        break;
      case 'ARRIVAL':
        this.setState({
          arrivalMessages: true,
          allMessages: false,
          travelerMessages: false,
          departureMessages: false,
        });
        break;
      default:
    }
  }

  scrollToError = (fieldName) => {
    scroller.scrollTo(`new-flight-alert-${fieldName}`, {
      duration: 500,
      delay: 100,
      smooth: true,
    });
  };

  handleSubmit = (
    hasValidationError,
    alertEmail,
    alertPhoneNumber,
    alertCarrier,
    description,
    shortText,
    sendEmail,
  ) => {
    const descriptionError = this.validateDescription(description);
    this.setState({ descriptionError });

    if (descriptionError) {
      this.scrollToError('description');
    } else if (hasValidationError) {
      this.scrollToError('message-type');
    }

    const valid = !hasValidationError && !descriptionError;

    if (valid) {
      this.setState({
        errorOnSubmit: false,
        formValidated: true,
      });
      // handle setting the alert, the state is valid.
      const events = this.getMessageTypeString();
      const {
        params: {
          flightNumber,
          carrier,
          year,
          month,
          day,
        },
        flightInfo,
      } = this.props;
      const options = {
        userId: this.context.currentUser.id,
        carrierFsCode: carrier,
        flightNumber: flightNumberInt(flightNumber),
        departureAirportFsCode: flightInfo.departureAirportFsCode,
        arrivalAirportFsCode: flightInfo.arrivalAirportFsCode,
        year,
        month,
        day,
        name: description,
        events,
        shortText,
        sendEmail,
        enterpriseAccount: isBusinessUser(this.context.currentUser),
      };

      if (sendEmail) {
        if (alertEmail !== this.context.currentUser.flightMonitorEmailAddress0) {
          // any option that isnt the email that is set as the alert preference email in account
          // settings is an alternate delivery email
          options.alternateDeliveryEmail = alertEmail;
        }
      }

      if (shortText) {
        if (alertPhoneNumber !== this.context.currentUser.flightMonitorWirelessNumber0) {
          options.alternateDeliveryPhoneNumber = alertPhoneNumber;
          options.alternateDeliveryCarrier = alertCarrier;
        }
      }


      if (this.props.flightInfo.departureTimeUTC) {
        options.departureTimeUTC = this.props.flightInfo.departureTimeUTC;
      }

      if (this.props.flightInfo.arrivalTimeUTC) {
        options.arrivalTimeUTC = this.props.flightInfo.arrivalTimeUTC;
      }

      this.props.handleSubmit(options);
    } else {
      this.context.toast.error('Please try again.', 'Alert not saved');
    }
    return false;
  }

  validateDescription = (description) => {
    const descriptionMessage = 'A description is required';
    if (!description.length) {
      return descriptionMessage;
    }
    return false;
  }

  renderErrorMessage = () => {
    let errorMessage = null;

    if (this.props.flightInfo.isCancelledFlight || this.props.flightInfo.isLandedFlight) {
      const { arrivalAirport, departureAirport } = this.props.flightInfo;
      const arrivalAirportCityCode = arrivalAirport.airportCityCode;
      const departureAirportCityCode = departureAirport.airportCityCode;
      errorMessage = `Segment ${departureAirportCityCode} to ${arrivalAirportCityCode}'s status does not allow it to be monitored`;
    } else if (this.props.unableToCreateAlert) {
      errorMessage = this.props.unableToCreateAlert;
    }

    return errorMessage && (
      <div className='col-xs-12' style={errorMessage ? {} : { display: 'none' }}>
        <div className='flight-alert-error-text-container'>
          <p className='error-text'>
            {errorMessage}
          </p>
        </div>
      </div>
    );
  }

  render() {
    const {
      params,
      flightInfo,
    } = this.props;

    const flightAlertText = `(${params.carrier}) ${flightInfo.carrierName} ${flightNumberInt(params.flightNumber)} Flight Alert Setup`;
    const errorMessage = this.renderErrorMessage();

    return (
      <div className='new-wizard-container'>
        <div>
          <FlightAlertsHeader date={this.props.date} leftSide={flightAlertText} />
        </div>
        <div className='flight-alerts-form-container'>
          { errorMessage }
          <FlightAlertsDetailHeader
            flightInfo={this.props.flightInfo}
            containerClassName='alert-header-wizard'
          />
          <NewAlertForm
            alertPhonePreference={this.props.alertPhonePreference}
            carriers={carrierOptions}
            currentEmail={this.context.currentUser.email}
            profileEmailAddress={this.context.currentUser.flightMonitorEmailAddress0}
            profilePhoneNumber={this.context.currentUser.flightMonitorWirelessNumber0}
            profileCarrier={this.context.currentUser.flightMonitorWirelessServiceCode0}
            handleMessageSelect={this.handleMessageSelect}
            handleEmailSelect={this.handleEmailSelect}
            handleSubmit={this.handleSubmit}
            errorOnSubmit={this.state.errorOnSubmit}
            formValidated={this.state.formValidated && this.props.creationSuccess}
            travelerMessages={this.state.travelerMessages}
            departureMessages={this.state.departureMessages}
            arrivalMessages={this.state.arrivalMessages}
            allMessages={this.state.allMessages}
            flightInfo={flightInfo}
            submitButtonDisabled={this.props.submitButtonDisabled}
            mobileError={this.state.mobileError}
            descriptionError={this.state.descriptionError}
            params={params}
            setAlertPhonePreference={this.props.setAlertPhonePreference}
          />
        </div>
      </div>);
  }
}
