export default {
  0: 'alpha',
  1: 'bravo',
  2: 'charlie',
  3: 'delta',
  4: 'echo',
  5: 'foxtrot',
  6: 'golf',
  7: 'hotel',
  8: 'india',
  9: 'juliett',
  10: 'kilo',
  11: 'lima',
  12: 'mike',
  13: 'november',
  14: 'oscar',
  15: 'papa',
  16: 'quebec',
  17: 'romeo',
  18: 'sierra',
  19: 'tango',
  20: 'uniform',
  21: 'victor',
  22: 'whiskey',
  23: 'xray',
  24: 'yankee',
  25: 'zulu',
  26: 'one',
  27: 'two',
  28: 'three',
  29: 'four',
  30: 'five',
  31: 'six',
};
