import React from 'react';
import PropTypes from 'prop-types';
import { SelectFilterAsync } from '@flightstats/component-lib';
import { not, path } from 'ramda';

import TextInput from '../../../../../../../../../shared/components/TextInput/TextInput';
import FSFlatButton from '../../../../../../../../../src/components/lib/FSFlatButton';
import componentBase from '../../../../../../../../../src/lib/component-base';
import { isValidEmail, isValidPhoneNumber } from '../../../../../../../../../src/helpers/Validators';

const ACCOUNT_DEFAULT = 'ACCOUNT_DEFAULT';

@componentBase('AlertPreferences')
export default class AlertPreferences extends React.Component {
  static propTypes = {
    alertPhonePreference: PropTypes.bool,
    carriers: PropTypes.array,
    currentEmail: PropTypes.string,
    profileEmailAddress: PropTypes.string,
    handleEmailSelect: PropTypes.func,
    handleSubmit: PropTypes.func,
    profilePhoneNumber: PropTypes.string,
    handlePhoneNumberSelect: PropTypes.func,
    handleCarrierSelect: PropTypes.func,
    handleAlertPreference: PropTypes.func,
    alertEmailPreference: PropTypes.bool,
    alertPhonePreference: PropTypes.bool,
    alertSelectionError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    phoneNumberError: PropTypes.string,
    emailError: PropTypes.string,
    submitButtonDisabled: PropTypes.bool,
    carrierError: PropTypes.string,
    resetValidation: PropTypes.func,
    setEmailError: PropTypes.func,
    setPhoneNumberError: PropTypes.func,
    setAlertPhonePreference: PropTypes.func,
    setCarrierError: PropTypes.func,
  }

  state = {
    lastPhoneValue: null,
    selectedEmail: this.emailDeliveryPoint,
    selectedPhoneNumber: ACCOUNT_DEFAULT || null,
    selectedCarrier: this.context.currentUser.flightMonitorWirelessServiceCode0,
  }

  get emailDeliveryPoint() {
    return this.props.profileEmailAddress ?
      this.props.profileEmailAddress :
      this.props.currentEmail;
  }

  smsDeliveryPoint() {
    return path(['props', 'profilePhoneNumber'], this);
  }

  handleEmailChange = (value) => {
    this.props.resetValidation('emailError');

    this.props.handleEmailSelect(value);

    if (!value && this.props.alertEmailPreference) {
      this.props.setEmailError('An email address is required');
    }
    if (value && value !== this.emailDeliveryPoint && !isValidEmail(value)) {
      this.props.setEmailError();
    }
  }

  handlePhoneNumberChange = (value) => {
    const {
      alertPhonePreference,
      handlePhoneNumberSelect,
      resetValidation,
      setPhoneNumberError,
    } = this.props;

    if (this.state.lastPhoneValue !== value) {
      resetValidation('phoneNumberError');
      this.setState({
        lastPhoneValue: value,
      });

      handlePhoneNumberSelect(value);

      if (!value && alertPhonePreference) {
        setPhoneNumberError('A phone number is required');
      }
      if (value && value !== this.smsDeliveryPoint() && !isValidPhoneNumber(value)) {
        setPhoneNumberError();
      }
    }
  }

  handleCarrierSelect = (e) => {
    const value = path(['value'], e);
    this.setState({
      selectedCarrier: value,
    });
    this.props.handleCarrierSelect(value);
  }

  handleCarrierRemove = () => {
    this.setState({
      selectedCarrier: '',
    });

    this.props.handleCarrierSelect('');
  }

  handleAlertPreference = (e) => {
    const value = path(['target', 'value'], e);
    this.props.handleAlertPreference(value);
  }

  handlePhonePreferenceCheckbox = () => {
    const toggle = not(this.props.alertPhonePreference);
    if (!toggle) {
      this.props.setPhoneNumberError('');
      this.props.setCarrierError('');
    }
    this.props.setAlertPhonePreference(toggle);
  }

  handleSubmit = () => {
    this.props.handleSubmit();
  }

  filterCarriers = options => options.filter(option => this.props.carriers.includes(option) > -1);

  render() {
    const pTagAndButtonErrorStyle = {}; // this adjusts the style when there is an error
    const alertSelectionError = path(['props', 'alertSelectionError'], this);
    return (
      <div>
        <fieldset className='new-alert-fieldset preferences'>
          <h5 className='delivery-points-title'>Alert Delivery Points</h5>
          <p className='delivery-points-text'>Select where to send alerts</p>
          <div className='col-xs-12' style={alertSelectionError ? {} : { display: 'none' }}>
            <div className='flight-alert-error-text-container'>
              <p className='error-text'>
                {alertSelectionError}
              </p>
            </div>
          </div>
          <div>
            <div className='col-xs-12 col-sm-12 col-md-6 alert-select'>
              <div className='user-alert-email-group'>
                <TextInput
                  label='Email Address'
                  name='user-alert-email'
                  id='user-alert-email'
                  ref='user-alert-email'
                  type='email'
                  labelStyle={{ height: '40px' }}
                  spanStyle={{ top: '20px' }}
                  onInputKeyUp={this.handleEmailChange}
                  onInputBlur={this.handleEmailChange}
                  errorMessage={this.props.emailError}
                  disabled={!this.props.alertEmailPreference}
                  defaultValue={this.emailDeliveryPoint}
                />
                {/* <input
                  type='checkbox'
                  checked={this.props.alertEmailPreference}
                  name='email-alert-elect'
                  id='email-alert-elect'
                  value='alertEmailPreference'
                  onChange={e => this.handleAlertPreference(e)}
                />
                <label htmlFor='email-alert-elect'>Send Alerts To My Email</label> */}
              </div>
            </div>
            <div className='col-xs-12 col-sm-12 col-md-3 alert-select phone-number'>
              <div className='user-alert-phone-group'>
                <TextInput
                  label='Phone Number'
                  name='user-alert-phone'
                  id='user-alert-phone'
                  ref='user-alert-phone'
                  placeholder='015555555555'
                  type='tel'
                  labelStyle={{ height: '40px' }}
                  spanStyle={{ top: '20px' }}
                  onInputKeyUp={this.handlePhoneNumberChange}
                  onInputBlur={this.handlePhoneNumberChange}
                  errorMessage={this.props.phoneNumberError}
                  // disabled={!this.props.alertPhonePreference}
                  disabled // until SMS works again
                  defaultValue={this.smsDeliveryPoint()}
                />
                <input
                  type='checkbox'
                  checked={this.props.alertPhonePreference}
                  name='phone-alert-elect'
                  id='phone-alert-elect'
                  value='alertPhonePreference'
                  onChange={e => this.handlePhonePreferenceCheckbox(e)}
                  disabled // until SMS works again
                />
                <label htmlFor='phone-alert-elect'>Send Alerts To My Phone</label>
              </div>
            </div>

            <div className='col-xs-12 col-sm-12 col-md-3 alert-select carrier-select'>
              <div className='user-alert-carrier-group'>
                <label
                  htmlFor='user-carrier-select'
                  className={!this.props.carrierError ? 'alert-label' : 'alert-label error'}
                  style={this.props.carrierError ? {} : {}}
                >
                  <span>{this.props.carrierError || 'Carrier'}</span>
                </label>
                <SelectFilterAsync
                  clearable
                  searchable
                  ref='user-carrier-select'
                  handleMenuSelection={this.handleCarrierSelect}
                  handleRemoveSelection={this.handleCarrierRemove}
                  results={this.props.carriers}
                  value={this.state.selectedCarrier}
                  height='34px'
                  borderProp='2px solid'
                  bgColor='white'
                />
              </div>
            </div>
            <div className='alerts-form-submit' style={(this.props.emailError || this.props.phoneNumberError || this.props.carrierError) ? pTagAndButtonErrorStyle : {}}>
              <FSFlatButton
                style={FSFlatButton.style({
                  width: '250px',
                  display: 'block',
                  margin: '0 auto',
                  padding: 0,
                })}
                onClick={this.handleSubmit}
                disabled={this.props.submitButtonDisabled}
              >
                SAVE ALERT
              </FSFlatButton>
            </div>
          </div>
        </fieldset>
      </div>
    );
  }
}
