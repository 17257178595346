import React from 'react';
import PropTypes from 'prop-types';
import componentBase from '../../../../../../src/lib/component-base';
import { prefersAMPMTimeFormat } from '../../../../../../src/lib/date-time-format';

const planeOrangeIcon = require('../../../../../../static/images/plane-orange.svg');

@componentBase('SegmentSelect')
export default class SegmentSelect extends React.Component {
  static propTypes = {
    segments: PropTypes.array,
    searchDate: PropTypes.string,
    selectSegment: PropTypes.func,
  }

  state = {
    ineligibleFlightSegment: null,
  }

  onSegmentSelect = (segment) => {
    if (segment.isLandedFlight || segment.isCancelledFlight) {
      this.setState({
        ineligibleFlightSegment: segment,
      });
    } else {
      this.props.selectSegment(segment);
    }
  }

  flightNote = (segment) => {
    let flightNote = null;
    // order matters here, it can be more than one!``
    if (segment.isDepartedFlight) {
      flightNote = 'DEPARTED';
    }
    if (segment.isLandedFlight) {
      flightNote = 'LANDED';
    }
    if (segment.isCancelledFlight) {
      flightNote = 'CANCELLED';
    }
    return flightNote;
  }

  renderErrorMessage = () => {
    const segment = this.state.ineligibleFlightSegment;
    return (
      <div className='flight-alert-errors' id='segment-select-error'>
        <h2 style={{ fontSize: '1.2em' }}>Segment {segment.departureAirportFsCode} to {segment.arrivalAirportFsCode}&#39;s status does not allow it to be monitored.</h2>
      </div>
    );
  }

  renderSegmentRows = segments => segments.map((segment) => {
    const depTime = prefersAMPMTimeFormat(this.context.currentUser) ? segment.departureTime : segment.departureTime24;
    const depTimeAMPM = prefersAMPMTimeFormat(this.context.currentUser) ? segment.departureTimeMeridian : null;

    const arrTime = prefersAMPMTimeFormat(this.context.currentUser) ? segment.arrivalTime : segment.arrivalTime24;
    const arrTimeAMPM = prefersAMPMTimeFormat(this.context.currentUser) ? segment.arrivalTimeMeridian : null;
    const flightNote = this.flightNote(segment);
    return (
      <div
        className={segment.isLandedFlight || segment.isCancelledFlight ? 'alert-segment-disabled row' : 'alert-segment-row row'}
        key={`${segment.arrivalAirportFsCode}${segment.departureAirportFsCode}`}
        onClick={this.onSegmentSelect.bind(this, segment)}
      >
        <div className='col-xs-3 alert-segment-title-packs'>
          <div className='row'>
            <div className='col-xs-4 origin-segment'>
              <p>{segment.departureAirportFsCode}</p>
            </div>
            <div className='plane-icon-container'>
              <img src={planeOrangeIcon} alt='Airplane' />
            </div>
            <div className='col-xs-4 dest-segment'>
              <p>{segment.arrivalAirportFsCode}</p>
            </div>
          </div>
        </div>
        <div className='col-xs-8 alert-segment-title-packs'>
          <p>
            <span className='segment-time-span'>{depTime}</span>
            <span>{depTimeAMPM}</span>
            <span className='segment-timezone-span'> {segment.departureTimeZone}</span>
          </p>
          <p>
            <span className='segment-time-span'>{arrTime}</span>
            <span>{arrTimeAMPM}</span>
            <span className='segment-timezone-span'> {segment.arrivalTimeZone}</span>
          </p>
          <p
            className='flight-alert-notes hidden-xs'
            style={{ color: '#FFF' }}
          >
            <span className='flight-status-note'>
              {flightNote}
            </span>
            <span className='flight-status-monitorable'>
              {segment.isLandedFlight || segment.isCancelledFlight ? 'No Longer Monitorable' : null}
            </span>
          </p>
        </div>
      </div>
    );
  });

  render() {
    // this.logger('props: ', this.props);
    const flightNameHeader = `${this.props.segments[0].carrierFsCode}${this.props.segments[0].flightNumber}`;
    return (
      <div className='segment-selection-container'>
        <div className='segment-detail-header'>
          <h4>Search results for {flightNameHeader} Flight Alerts</h4>
          <h2>{this.props.searchDate}</h2>
          <p>{flightNameHeader} has multiple segments, select the segment you would like an alert for</p>
          {this.state.ineligibleFlightSegment ? this.renderErrorMessage() : null }
        </div>
        <div className='segment-select-chart'>
          <div className='segment-select-chart-header row'>
            <div className='col-xs-3 segment-chart-header-packs'>
              <div className='row'>
                <div className='col-xs-4'>
                  <p>Origin</p>
                </div>
                <div className='col-xs-4'>
                  <p>Destination</p>
                </div>
              </div>
            </div>
            <div className='col-xs-8 segment-chart-header-packs'>
              <p className='hidden-xs'>Departure Time</p>
              <p className='visible-xs'>Departure</p>
              <p className='hidden-xs'>Arrival Time</p>
              <p className='visible-xs'>Arrival</p>
              <p className='hidden-xs'>Notes</p>
            </div>
          </div>
          <div className='segment-select-row-container'>
            {this.renderSegmentRows(this.props.segments)}
          </div>
        </div>
      </div>
    );
  }
}
