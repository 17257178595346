import * as t from './actionTypes';
import { errorText } from '../../../../shared/lib/reducer-util';

const initialState = {
  loading: false,
  loaded: false,
  sortColumn: 'DATE',
  sortOrder: true,
  currentResult: [],
  couldNotDestroyError: null,
  checkFlightsResult: [],
  alertPhonePreference: false, // unchecked
  secondaryAction: false,
  queryError: '',
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    // Deactivate alert
    case t.LOAD_DEACTIVATE_ALERT:
      return {
        ...state,
        deactivateError: null,
        deactivateLoading: true,
        deactivateLoaded: false,
      };
    case t.LOAD_DEACTIVATE_SUCCESS:
      return {
        ...state,
        deactivateError: null,
        deactivateLoading: false,
        deactivateLoaded: true,
        currentResult: action.result,
      };
    case t.LOAD_DEACTIVATE_FAIL:
      return {
        ...state,
        deactivateError: action.response.error.text,
        deactivateLoading: false,
        deactivateLoaded: false,
      };

    // Reactivate alert
    case t.LOAD_REACTIVATE_ALERT:
      return {
        ...state,
        reactivateError: null,
        reactivateLoading: true,
        reactivateLoaded: false,
      };
    case t.LOAD_REACTIVATE_SUCCESS:
      return {
        ...state,
        reactivateError: null,
        reactivateLoading: false,
        reactivateLoaded: true,
        currentResult: action.result,
      };
    case t.LOAD_REACTIVATE_FAIL:
      return {
        ...state,
        reactivateError: action.response.error.text,
        reactivateLoading: false,
        reactivateLoaded: false,
      };

    // Test alert
    case t.LOAD_TEST_ALERT:
      return {
        ...state,
        testAlertError: null,
        loading: true,
      };
    case t.LOAD_TEST_ALERT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case t.LOAD_TEST_ALERT_FAIL:
      return {
        ...state,
        testAlertError: action.result,
        loading: false,
      };

    // New alert
    case t.LOAD_NEW_ALERT:
      return {
        ...state,
        newAlertError: null,
        newAlertLoading: true,
        newAlertLoaded: false,
      };
    case t.LOAD_NEW_ALERT_SUCCESS:
      return {
        ...state,
        newAlertError: null,
        newAlertLoading: false,
        newAlertLoaded: true,
        newAlertSuccess: action.result,
      };
    case t.LOAD_NEW_ALERT_FAIL:
      return {
        ...state,
        newAlertLoading: false,
        newAlertLoaded: false,
        newAlertError: errorText(action.error),
      };

    // Current alerts
    case t.LOAD_CURRENT:
      return {
        ...state,
        flightAlertsError: null,
        loading: true,
        loaded: false,
        couldNotDestroyError: null,
      };
    case t.LOAD_CURRENT_SUCCESS:
      return {
        ...state,
        flightAlertsError: null,
        loading: false,
        loaded: true,
        currentResult: action.result,
      };
    case t.LOAD_CURRENT_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        flightAlertsError: errorText(action.error),
      };

    // Sort
    case t.SORT:
      const { sortColumn, sortOrder } = action;
      return {
        ...state,
        sortColumn,
        sortOrder,
      };

    // Delete flight
    case t.LOAD_DELETE_FLIGHT_ALERT:
      return {
        ...state,
        deleteFlightAlertError: null,
        deleteFlightAlertLoading: true,
        deleteFlightAlertLoaded: false,
        deleteFlightAlertResult: null,
      };
    case t.LOAD_DELETE_FLIGHT_ALERT_SUCCESS:
      return {
        ...state,
        deleteFlightAlertError: null,
        deleteFlightAlertLoading: false,
        deleteFlightAlertLoaded: true,
        currentResult: action.result.currentAlerts,
        couldNotDestroyError: action.result.couldNotDestroyError,
      };
    case t.LOAD_DELETE_FLIGHT_ALERT_FAIL:
      return {
        ...state,
        deleteFlightAlertError: errorText(action.error),
        deleteFlightAlertLoading: false,
        deleteFlightAlertLoaded: false,
        deleteFlightAlertResult: null,
      };

    // Flight schedules
    case t.LOAD_FLIGHT_SCHEDULES:
      return {
        ...state,
        flightSchedulesError: null,
        flightSchedulesLoading: true,
        flightSchedulesLoaded: false,
        flightSchedulesResult: null,
      };
    case t.LOAD_FLIGHT_SCHEDULES_SUCCESS:
      return {
        ...state,
        flightSchedulesError: null,
        flightSchedulesLoading: false,
        flightSchedulesLoaded: true,
        flightSchedulesResult: action.result,
      };
    case t.LOAD_FLIGHT_SCHEDULES_FAIL:
      return {
        ...state,
        flightSchedulesError: errorText(action.error),
        flightSchedulesLoading: false,
        flightSchedulesLoaded: false,
        flightSchedulesResult: null,
      };

    // User history
    case t.LOAD_USER_HISTORY:
      return {
        ...state,
        userHistoryError: null,
        userHistoryLoading: true,
        userHistoryLoaded: false,
        userHistoryResult: null,
      };
    case t.LOAD_USER_HISTORY_SUCCESS:
      return {
        ...state,
        userHistoryError: null,
        userHistoryLoading: false,
        userHistoryLoaded: true,
        userHistoryResult: action.result,
        flightsMonitored: action.result && action.result.flightsMonitored,
      };
    case t.LOAD_USER_HISTORY_FAIL:
      return {
        ...state,
        userHistoryError: errorText(action.error),
        userHistoryLoading: false,
        userHistoryLoaded: false,
        userHistoryResult: null,
      };

    // check flight schedules
    case t.CHECK_FLIGHT_SCHEDULES:
      return {
        ...state,
        checkFlightsError: null,
        checkFlightsLoading: true,
        checkFlightsLoaded: false,
        checkFlightsResult: [],
      };
    case t.CHECK_FLIGHT_SCHEDULES_SUCCESS:
      return {
        ...state,
        checkFlightsError: null,
        checkFlightsLoading: false,
        checkFlightsLoaded: true,
        checkFlightsResult: action.result,
      };
    case t.CHECK_FLIGHT_SCHEDULES_FAIL:
      return {
        ...state,
        checkFlightsLoading: false,
        checkFlightsLoaded: false,
        checkFlightsError: errorText(action.error),
        checkFlightsResult: [],
      };
    // get the current server date
    case t.LOAD_CURRENT_DATE:
      return {
        ...state,
        currentDateError: null,
        currentDateLoading: true,
        currentDateLoaded: false,
        currentDateResult: null,
      };
    case t.LOAD_CURRENT_DATE_SUCCESS:
      return {
        ...state,
        currentDateError: null,
        currentDateLoading: false,
        currentDateLoaded: true,
        currentDateResult: action.result,
      };
    case t.LOAD_CURRENT_DATE_FAIL:
      return {
        ...state,
        currentDateLoading: false,
        currentDateLoaded: false,
        currentDateError: errorText(action.error),
        currentDateResult: null,
      };
    case t.CHECKING_SCHEDULES_RESULTS_ERROR:
      return {
        ...state,
        checkingSchedulesResultError: action.bool,
      };
    case t.SET_ALERT_PHONE_PREFERENCE:
      return {
        ...state,
        alertPhonePreference: action.alertPhonePreference,
      };
    case t.SET_SECONDARY_ACTION:
      return {
        ...state,
        secondaryAction: action.bool,
      };
    case t.SET_QUERY_ERROR:
      return {
        ...state,
        queryError: action.queryError,
      };
    default:
      return state;
  }
};
