import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';
import { pathOr, propOr } from 'ramda';
import Helmet from 'react-helmet';
import findkey from 'lodash.findkey';
import componentBase from '../../../../src/lib/component-base';
import RouteWrapper from '../../../RouteWrapper';
import { actions } from '../../redux';
import Progress from './children/Progress/Progress';
import PlanSelection from './children/PlanSelection/PlanSelection';
import CreateAccountContainer from './children/CreateAccount/CreateAccountContainer';
import RegistrationPaymentInformationContainer from './children/RegistrationPaymentInformation/RegistrationPaymentInformationContainer';
import RegistrationCompletion from './children/Completion/Completion';

const selectText = 'Select a Plan';
const createText = 'Create Account';
const completeText = 'Completion';
const billingInformationText = 'Billing Information';

const parentRoute = '/register';
const selectLink = `${parentRoute}/plan`;
const createLink = `${parentRoute}/create`;
const billingInformationLink = `${parentRoute}/billing-information`;
const completeLink = `${parentRoute}/completion`;

const linkMap = {
  [selectText]: [selectLink],
  [createText]: [createLink, billingInformationLink],
  [completeText]: [completeLink],
};

const accountPresent = 'I already have an Account';

const redirectToastMessages = {
  ACTIVE_SUB: 'You Already Have an Active Subscription',
  ACCOUNT_PRESENT: 'You Already Have an Account',
};

@connect(
  state => ({
    braintreeClientToken: state.Account.braintreeClientToken,
    braintreeSubmitButtonDisabled: state.Account.braintreeSubmitButtonDisabled,
    firstTransactionId: state.Account.gettingFirstTransactionIdSuccess,
    firstTransactionIdPending: state.Account.gettingFirstTransactionId ||
      state.Account.receiptPending,
    hasCheckedTermsAndConditions: state.Account.hasCheckedTermsAndConditions,
    hostedFields: state.Account.hostedFields,
    hostedFieldsDidCreate: state.Account.hostedFieldsDidCreate,
    loading: state.Account.loading,
    loggedIn: state.Account.loggedIn,
    planHovered: state.Account.planHovered,
    planSelected: state.Account.planSelected,
    registrationBillingInformationBtnDisabled:
      state.Account.registrationBillingInformationBtnDisabled,
    registrationError: state.Account.registrationError,
    registrationFlowToken: state.Account.registrationFlowToken,
    registrationStage: state.Account.registrationStage,
    routing: state.router,
    sentEmailVerification: state.Account.sentEmailVerification,
    signUpError: state.Account.signUpError,
    siteKey: state.App.siteKey,
    user: state.Account.user,
    userServiceRegistrationError: state.Account.userServiceRegistrationError,
  }),
  actions,
)
@componentBase('AccountRegistrationContainer')
export default class AccountRegistrationContainer extends Component {
  static propTypes = {
    children: PropTypes.object,
    endSpinner: PropTypes.func,
    getBraintreeClientToken: PropTypes.func,
    getBraintreePlans: PropTypes.func,
    goToBillingInformation: PropTypes.func,
    handlePlanSelection: PropTypes.func,
    loading: PropTypes.bool,
    location: PropTypes.object,
    loggedIn: PropTypes.bool,
    planHovered: PropTypes.string,
    planSelected: PropTypes.string,
    registrationBillingInformationBtnDisabled: PropTypes.bool,
    registrationClearData: PropTypes.func,
    registrationError: PropTypes.string,
    registrationFlowToken: PropTypes.bool,
    registrationStage: PropTypes.string,
    routerPush: PropTypes.func,
    setRegistrationStage: PropTypes.func,
    setPlanHovered: PropTypes.func,
    setRegistrationFlowToken: PropTypes.func,
    user: PropTypes.object,
  };

  componentWillMount() {
    this.popStateCallback = null;
    this.redirected = false;
    this.determineDefaultPlanSelected = this.determineDefaultPlanSelected.bind(this);
  }

  componentDidMount() {
    const {
      handlePlanSelection,
      location,
      planSelected,
      setRegistrationStage,
      user,
    } = this.props;

    this.setRegistrationFlowToken();

    const pathname = location && location.pathname;
    if (pathname) {
      setRegistrationStage(this.findLinkMapKey(pathname));
    }

    const defaultPlanSelected = this.determineDefaultPlanSelected(user, planSelected);

    handlePlanSelection(defaultPlanSelected);

    if (typeof window !== 'undefined') {
      window.onpopstate = this.handlePopState;
    }
  }

  componentWillReceiveProps = (nextProps) => {
    const {
      loading,
      location,
      loggedIn,
      registrationError,
      planSelected,
      user,
    } = nextProps;

    this.redirectOnInvalidToken(nextProps);

    if (!this.redirected && loggedIn) {
      this.redirected = true; // this is important else you may get stuck in an infinite loop
      this.props.registrationClearData();
      if (this.isFree(planSelected)) {
        if (!user && nextProps.user) {
          // skip billing stuff
          this.handleRegistrationNavigation(null, completeText);
        }
      }
    }
    if (loading && registrationError) {
      this.props.endSpinner();
    }
    const { pathname } = this.props.location;
    if (location.pathname !== pathname) {
      this.props.setRegistrationStage(this.findLinkMapKey(location.pathname));
    }
  }

  componentWillUnmount() {
    if (window) {
      window.onpopstate = null;
    }
    if (this.props.loading) {
      this.props.endSpinner();
    }
  }

  getMetaTags = () => ({
    meta: [{
      content: 'noindex',
      name: 'robots',
    }],
  })

  setRegistrationFlowToken = () => {
    const user = pathOr({}, ['user'], this.props);
    const pathname = this.getCurrentPathname();
    const isSelectionRoute = pathname === selectLink;
    const isNotCancelled = !this.subPreviouslyCancelled(user);
    const noSubscriptionLevel = isNotCancelled && !this.positiveSubscriptionValue(user);
    /* is a not a user, or has free/cancelled subscription,
    location is beginning of flow */
    isSelectionRoute &&
    noSubscriptionLevel &&
    this.props.setRegistrationFlowToken();
  }

  getCurrentPathname = () => pathOr('', ['location', 'pathname'], this.props);

  hasSubscriptionLevel = propOr(0, 'subscriptionLevel');

  hasSubscriptionStatus = propOr('', 'subscriptionStatus');

  subPreviouslyCancelled = user => this.hasSubscriptionStatus(user) === 'cancelled by user';

  // using the comparitive expression as otherwise string zero would return positive
  positiveSubscriptionValue = user => this.hasSubscriptionLevel(user) > 0;

  redirectOnInvalidToken = (nextProps) => {
    const pathname = this.getCurrentPathname();
    const currentToken = pathOr(false, ['registrationFlowToken'], this.props);
    const tokenized = pathOr(currentToken, ['registrationFlowToken'], nextProps);

    const complete = pathname === completeLink;
    !complete &&
    !tokenized &&
    this.props.routerPush('/my-account/profile');
  }

  determineDefaultPlanSelected = (user = {}, planSelected) => {
    let defaultPlanSelected = 'standard';
    if (planSelected) {
      defaultPlanSelected = planSelected;
    } else if (!user) {
      defaultPlanSelected = 'standard';
    } else if (user && !user.subscriptionActive) {
      defaultPlanSelected = 'free';
      this.handlePlanSelection(defaultPlanSelected);
    } else if (user.subscriptionLevel === 1) {
      defaultPlanSelected = 'standard';
    } else if (user.subscriptionLevel === 3) {
      defaultPlanSelected = 'premium';
    } else if (user.subscriptionLevel === 11) {
      defaultPlanSelected = 'business';
    }

    return defaultPlanSelected;
  }

  goToAccount = (user) => {
    if (user) {
      const toastMessage = redirectToastMessages.ACCOUNT_PRESENT;
      this.redirected = true;

      setTimeout(() => this.context.toast.info(toastMessage, 'Redirecting to Your Account Profile', false, 1), 100);
      this.props.routerPush('/my-account/profile');
    }
  }

  findLinkMapKey = pathname => findkey(linkMap, o => o.includes(pathname))

  handleAlreadyHaveAccountClick = (e) => {
    e.preventDefault();
    this.props.routerPush('/account/login');
  }

  handlePopState = () => {
    const { location } = this.props;
    const pathname = location && location.pathname;
    this.props.setRegistrationStage(this.findLinkMapKey(pathname));
  }

  handlePlanHoverState = (plan) => {
    this.props.setPlanHovered(plan);
  }

  handlePlanSelection = (plan) => {
    this.props.handlePlanSelection(plan);
  }

  handleRegistrationNavigation = (e, planClicked) => {
    const { planSelected } = this.props;
    this.props.setRegistrationStage(planClicked);
    const accountType = planSelected === 'premium' ? 'professional' : planSelected;
    this.props.routerPush({
      pathname: `${linkMap[planClicked][0]}`,
      search: `?type=${accountType}`,
    });
  }

  isFree = planSelected => planSelected === 'free';

  render() {
    const meta = this.getMetaTags();
    const { handlePlanSelection, planHovered, setRegistrationStage } = this.props;
    const { reportButtonPress } = this.context;
    const {
      handleAlreadyHaveAccountClick,
      handlePlanHoverState,
      handleRegistrationNavigation,
      isFree,
    } = this;
    const props = {
      ...this.props,
      accountPresent,
      billingInformationText,
      createText,
      completeText,
      handleAlreadyHaveAccountClick,
      handlePlanHoverState,
      handlePlanSelection,
      handleRegistrationNavigation,
      isFree,
      planHovered,
      reportButtonPress,
      selectText,
      setRegistrationStage,
    };
    return (
      <div className='account-registration-container' key='registrationContainer' >
        <div>
          <Helmet
            {...meta}
            title='Create a New Account'
          />
          <div className='top'>
            <h1>
              Account Registration
            </h1>
            <Progress
              billingInformationText={billingInformationText}
              createText={createText}
              completeText={completeText}
              planSelected={this.props.planSelected}
              registrationStage={this.props.registrationStage}
              selectText={selectText}
            />
          </div>
          <Switch>
            <RouteWrapper
              path='/register/plan'
              exact
              component={PlanSelection}
              name='PlanSelection'
              {...props}
            />
            <RouteWrapper
              path='/register/create'
              exact
              component={CreateAccountContainer}
              name='CreateAccount'
              {...props}
            />
            <RouteWrapper
              path='/register/billing-information'
              name='RegistrationBillingInformation'
              exact
              component={RegistrationPaymentInformationContainer}
              {...props}
            />
            <RouteWrapper
              path='/register/completion'
              exact
              name='RegistrationCompletion'
              component={RegistrationCompletion}
              {...props}
            />
          </Switch>
        </div>
      </div>
    );
  }
}
