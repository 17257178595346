import React, { Component } from 'react';
import PropTypes from 'prop-types';
import componentBase from '../../../../src/lib/component-base';
import ToggleSwitch from '../../../../src/components/lib/ToggleSwitch';

@componentBase('ToggleSwitches')
export default class ToggleSwitches extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
  };
  static defaultProps = {
    user: {
      dateFormat: null,
    },
  };
  constructor(props) {
    super(props);
    this.dateFormat = null;
    this.timeFormat = null;
    this.hideCodeshares = null;
  }

  setRef = (ref, key) => (this[key] = ref)

  booleanFromEnumerationString = s => (s && s === '1')

  render() {
    return (
      <div>
        <div className='toggle-switch-container'>
          <p>Date Format</p>
          <div className='row'>
            <div className='col-xs-5 right-align'>
              <em>DMY</em>
              <small>(e.g. 01-Jan-2012)</small>
            </div>
            <div className='col-xs-2 center-align'>
              <ToggleSwitch
                name='dateFormat'
                ref={ref => this.setRef(ref, 'dateFormat')}
                value='MDY'
                onValue='1'
                offValue='0'
                on={this.booleanFromEnumerationString(this.props.user.dateFormat)}
              />
            </div>
            <div className='col-xs-5 mobile-justification'>
              <em>MDY</em>
              <small>(e.g. Jan-01-2012)</small>
            </div>
          </div>
        </div>
        <div className='toggle-switch-container spaced'>
          <p>Time Format</p>
          <div className='row'>
            <div className='col-xs-5 right-align'>
              <em>24 Hour</em>
              <small>(e.g. 18:30)</small>
            </div>
            <div className='col-xs-2 center-align'>
              <ToggleSwitch
                name='timeFormat'
                ref={ref => this.setRef(ref, 'timeFormat')}
                onValue='1'
                offValue='0'
                on={this.booleanFromEnumerationString(this.props.user.timeFormat)}
              />
            </div>
            <div className='col-xs-5 mobile-justification'>
              <em>AM/PM</em>
              <small>(e.g. 6:30 PM)</small>
            </div>
          </div>
        </div>
        <div className='toggle-switch-container spaced'>
          <p>Codeshares</p>
          <div className='row'>
            <div className='col-xs-5 right-align'>
              <em>Show</em>
            </div>
            <div className='col-xs-2 center-align'>
              <ToggleSwitch
                name='hideCodeshares'
                ref={ref => this.setRef(ref, 'hideCodeshares')}
                onValue='true'
                offValue='false'
                on={!!this.props.user.hideCodeshares}
              />
            </div>
            <div className='col-xs-5 mobile-justification'>
              <em>Hide</em>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
