import moment from 'moment';
import * as t from './actionTypes';
import { errorCode, getAirportNameAndCode, isNoResults } from './selectors';

const initialState = {
  dateFieldIndex: 1,
  loading: false,
  loaded: false,
  airport: null,
  isArrival: false,
  isDeparture: false,
  secondaryAction: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case t.LOAD_DEP_ARR_BY_CODE:
      return {
        ...state,
        error: null,
        data: null,
        list: null,
        basicInfo: null,
        loading: true,
      };
    case t.LOAD_DEP_ARR_SUCCESS:
      return {
        ...state,
        airport: getAirportNameAndCode(action.result.airport),
        error: null,
        friendlyError: isNoResults(action.result),
        loading: false,
        loaded: true,
        basicInfo: { ...action.result.airport },
        isArrival: !!action.result.arrivals,
        isDeparture: !!action.result.departures,
        lastUpdated: moment().valueOf(),
        list: action.result.departures ? action.result.departures : action.result.arrivals,
        depArr: action.result.departures ? 'departures' : 'arrivals',
        destOrigin: action.result.departures ? 'destination' : 'origin',
      };
    case t.SET_DEPARR_AIRPORT:
      return {
        ...state,
        ...action.result,
      };
    case t.SET_DEPARR_DATE_FIELD_INDEX:
      return {
        ...state,
        ...action.result,
      };
    case t.LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: errorCode(action.error),
      };
    case t.SET_SECONDARY_ACTION:
      return {
        ...state,
        secondaryAction: action.bool,
      };
    default:
      return state;
  }
};
