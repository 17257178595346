import URL from 'url';

const DOTCOM_PORT = 3000;
const FLIGHTSTATS_NEXT_PORT = 4000;
const URL_PREFIX = '/v2';

let appHost = '';

const env = () => (process.env.NODE_ENV || 'development');

const devHost = port => `http://localhost:${port}${URL_PREFIX}`;

const isDevelopment = () => (env() === 'development');

export const appHostForRequestURL = (requestURL) => {
  const u = URL.parse(requestURL);
  return `${u.protocol}//${u.host}${URL_PREFIX}`;
};

export const setAppHost = (host) => {
  appHost = host;
  return appHost;
};

export const setAppHostFromRequestURL = requestURL =>
  setAppHost(appHostForRequestURL(requestURL));

export const dotcomAppHost = () => (
  isDevelopment() ? devHost(DOTCOM_PORT) : appHost
);

export const flightstatsNextAppHost = () => (
  isDevelopment() ? devHost(FLIGHTSTATS_NEXT_PORT) : appHost
);
