import React from 'react';
import PropTypes from 'prop-types';
import { path, pathOr } from 'ramda';
import { openTab } from '../../../src/utils/openTab';
import AppStore from '../../../static/images/appstore-badge.svg';
import GooglePlay from '../../../static/images/google-play-badge.svg';
import phones from '../../../static/images/combined-phones.png';

const iOSAppLink = 'https://itunes.apple.com/app/apple-store/id572700574?pt=829440&ct=flightstats.com&mt=8';
const androidAppLink = 'https://play.google.com/store/apps/details?id=com.conducivetech.android.traveler&referrer=utm_source%3Dflightstats.com';

const mobileLink = (reportButtonPress, link, imgSrc, appType) => (
  <a
    href={link}
    style={{ margin: '0 auto', width: '50%', minWidth: '150px' }}
    onClick={(e) => {
      reportButtonPress(`${appType}AppDownload`);
      openTab(link, e);
    }}
  >
    <img
      src={imgSrc}
      style={{ height: '50px', width: '100%' }}
      alt={`Download FlightStats for ${appType}`}
    />
  </a>
);

const MobileAppCallout = (props) => {
  const {
    mediaBreakpoint,
    reportButtonPress,
  } = props;

  const userAgent = pathOr({}, ['userAgent'], mediaBreakpoint);
  const isAndroid = path(['isAndroid'], userAgent) || path(['isAndroidTablet'], userAgent);
  const isiOS = path(['isiPhone'], userAgent) || path(['isiPad'], userAgent) || path(['isiPod'], userAgent);

  return (
    <div className='mobile-app-callout-section'>
      <div className='mobile-app-callout-section-left-col'>
        <div
          className='mobile-app-callout-section-phones'
          style={{ backgroundImage: `url(${phones})` }}
          alt='Mobile Applications for the Active Traveler'
        />
      </div>
      <div className='mobile-app-callout-section-right-col'>
        <div>
          <h3>Over 10 million people have downloaded our FlightStats mobile apps!</h3>
          <h4>try it out today!</h4>
        </div>
        <div className='mobile-app-callout-app-logos'>
          {!isAndroid ? mobileLink(reportButtonPress, iOSAppLink, AppStore, 'iOS') : ''}
          {!isiOS ? mobileLink(reportButtonPress, androidAppLink, GooglePlay, 'Android') : ''}
        </div>
      </div>
    </div>
  );
};

MobileAppCallout.propTypes = {
  mediaBreakpoint: PropTypes.object,
  reportButtonPress: PropTypes.func,
};

export default MobileAppCallout;
