import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import FontIcon from 'material-ui/FontIcon';
import debounce from 'debounce';
import { prefersMDYDateFormat } from '../../../../../../src/lib/date-time-format';
import componentBase from '../../../../../../src/lib/component-base';
import ArchivedAlert from './children/ArchivedAlert';
import Dropdown from './children/Dropdown';
import { istanbulAirportCodeCheck } from '../../../../../../shared/lib/airport-code-helpers';

@componentBase('ArchivedFlights')
export default class ArchivedFlights extends React.Component {
  static propTypes = {
    filteredData: PropTypes.array,
    filterByColumn: PropTypes.func,
    filterAlertsByKeyword: PropTypes.func,
    renderedData: PropTypes.array,
    receiptCounts: PropTypes.array,
    filterAlertsByTimeframeFromMonthSelect: PropTypes.func,
    noHistoricalResults: PropTypes.bool,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      keywordSearch: '',
      date: 'DESC',
      'flight-number': 'ASC',
      'flight-number-selected': false,
      origin: 'ASC',
      'origin-selected': false,
      destination: 'ASC',
      'destination-selected': false,
      'departure-time': 'ASC',
      'departure-time-selected': false,
      description: 'ASC',
      'description-selected': false,
      'currently-selected': 'date',
      'currently-sorted-by': 'DESC',
      // currentData is the all alerts returned by the stacked bar chart brush
      currentData: null,
      // renderedData is the result of narrowing down the currentData set with keyword search
      renderedData: null,
      selectedIndex: -1,
    };

    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const update = {
      currentData: nextProps.filteredData,
      renderedData: nextProps.filteredData,
    };
    const newState = Object.assign({}, this.state, update);
    this.setState(newState);
  }

  columnHeaderClickHandler(columnName) {
    const oldColumn = this.state[columnName];
    const newColumn = (oldColumn === null || oldColumn === 'ASC') ? 'DESC' : 'ASC';
    const update = {};
    update[columnName] = newColumn;
    const update2 = {
      'flight-number-selected': false,
      'origin-selected': false,
      'destination-selected': false,
      'departure-time-selected': false,
      'description-selected': false,
    };
    update2[`${columnName}-selected`] = !this.state[`${columnName}-selected`];
    update2['currently-selected'] = columnName;
    update2['currently-sorted-by'] = newColumn;
    const newState = Object.assign({}, this.state, update, update2);
    this.setState(newState, () => {
      const dataSource = this.props.renderedData || this.props.filteredData;
      this.props.filterByColumn(columnName, oldColumn, dataSource);
    });
  }

  filterAlertsByKeyword = (keywords, currentData) => {
    const searchKeywords = keywords.trim().split(' ');

    this.props.filterAlertsByKeyword(searchKeywords, currentData, this.props.receiptCounts);
  }

  debouncedFilterAlertsByKeyword = (() => debounce(this.filterAlertsByKeyword, 400))()

  formatDate(date) {
    const dateToFormat = moment.utc(date);
    const formattedDate = [dateToFormat.format('-YYYY')];

    if (dateToFormat.isValid()) {
      if (prefersMDYDateFormat(this.context.currentUser)) {
        formattedDate.push(dateToFormat.format('MMM-DD'));
      } else {
        formattedDate.push(dateToFormat.format('DD-MMM'));
      }
    } else {
      // date came in wrong format or was not a date
      formattedDate[0] = '--';
    }
    formattedDate.push(dateToFormat);

    return formattedDate;
  }

  handleSearch(e) {
    const keywordSearch = e.target && e.target.value;
    const update = { keywordSearch };
    const newState = {
      ...this.state,
      ...update,
    };

    this.setState(newState, () => {
      this.debouncedFilterAlertsByKeyword(this.state.keywordSearch, this.state.currentData);
    });
  }

  handleSelectChange(e, key, value) {
    this.setState({ selectedIndex: value }, () => {
      if (value !== -1) {
        const dateObj = {
          startIndex: value,
          endIndex: value,
        };
        this.props.filterAlertsByTimeframeFromMonthSelect(dateObj, this.props.receiptCounts);
      }
    });
  }

  generateArrows() {
    if (this.state['currently-sorted-by'] === 'ASC') {
      // down arrow
      return (
        <div className='arrow-wrapper'>
          <FontIcon className={`material-icons arrow-up ${this.state['currently-selected']}-arrow`}>&#xE313;</FontIcon>
        </div>
      );
    }
    // up arrow
    return (
      <div className='arrow-wrapper'>
        <FontIcon className={`material-icons arrow-down ${this.state['currently-selected']}-arrow`}>&#xE316;</FontIcon>
      </div>
    );
  }

  handleClick(e) {
    e.stopPropagation();
    this.context.reportUserEvent.bind(this, 'Click', 'AlertHistoryDetail');
  }

  renderHistoricalAlerts(alerts = []) {
    const alertList = [];
    const arrows = this.generateArrows();

    const tableHeader = (
      <div key='alert-list-0' className='archived-flights-header archived-flight-list'>
        <div
          className='date'
          onClick={() => this.columnHeaderClickHandler('date')}
        >
          {arrows}
          <span
            className='text'
          >Date
          </span>
        </div>
        <div
          className='flight-number hide-mobile'
          onClick={() => this.columnHeaderClickHandler('flight-number')}
        >
          {arrows}
          <span
            className='text'
          >Flight Number
          </span>
        </div>
        <div
          className='origin hide-mobile'
          onClick={() => this.columnHeaderClickHandler('origin')}
        >
          {arrows}
          <span
            className='text'
          >Origin
          </span>
        </div>
        <div
          className='destination hide-mobile'
          onClick={() => this.columnHeaderClickHandler('destination')}
        >
          {arrows}
          <span
            className='text'
          >Destination
          </span>
        </div>
        <div
          className='departure-time hide-mobile'
          onClick={() => this.columnHeaderClickHandler('departure-time')}
        >
          {arrows}
          <span
            className='text'
          >Departure Time
          </span>
        </div>
        <div
          className='description'
          onClick={() => this.columnHeaderClickHandler('description')}
        >
          {arrows}
          <span
            className='text'
          >Description
          </span>
        </div>
      </div>
    );

    if (this.props.noHistoricalResults) {
      return (
        <div className='no-archived-alerts'>
          <p className='text'>No archived alerts to display</p>
        </div>
      );
    } else if (alerts && alerts[0] && alerts[0].wait) {
      // do nothing
    } else if (alerts && !!alerts.length) {
      alerts.forEach((alert, i) => {
        const alertProps = alerts[i];
        const timezone = moment.tz(alertProps.scheduledDeparture, alertProps.departureTimezone).format('z');
        const {
          arrivalAirportFsCode,
          departureAirportFsCode,
        } = alert;

        alertList.push(<div className='archived-flight-list-row' key={`${alert.wmaId}`}>
          <Link
            className=''
            to={`/flight-alerts/history/details/${alert.wmaId}`}
            onClick={() => this.handleClick}
          >
            <ArchivedAlert
              index={i}
              user={this.context.currentUser}
              scheduledArrival={alert.scheduledArrival}
              flightNumber={`${alert.carrierCode}${alert.flightNumber}`}
              arrivalAirportFsCode={istanbulAirportCodeCheck(arrivalAirportFsCode)}
              departureAirportFsCode={istanbulAirportCodeCheck(departureAirportFsCode)}
              scheduledDeparture={this.formatDate(alert.scheduledDeparture)}
              alertDescription={alert.alertDescription}
              timeZone={timezone}
            />
          </Link>
        </div>);
      });
    } else if (alerts && !alerts.length) {
      return (
        <div className='no-archived-alerts'>
          <p className='text'>No archived alerts to display for date selected</p>
        </div>
      );
    }

    if (!alertList.length) {
      return null;
    }

    alertList.unshift(tableHeader);
    return alertList;
  }

  renderYourArchivedFlights() {
    return (
      <div className='archived-flights'>
        <h1>Your Archived Flights</h1>
        <h3>Click on a flight to view more details</h3>
        <div className='search-inputs'>
          <input type='text' className='keyword-search' placeholder='Search by Keyword' value={this.state.keywordSearch} onChange={e => this.handleSearch(e)} />
          <span>
            <Dropdown
              receiptCounts={this.props.receiptCounts}
              defaultKey={-1}
              defaultText='Filter By Month'
              handleSelectChange={this.handleSelectChange}
              selectedIndex={this.state.selectedIndex}
            />
          </span>
        </div>
      </div>
    );
  }

  render() {
    const archivedFlights = this.renderYourArchivedFlights();
    const dataSource = this.props.renderedData || this.props.filteredData;
    const alerts = this.renderHistoricalAlerts(dataSource);

    return (
      <div className='archived-flights-section'>
        { archivedFlights }
        { alerts }
      </div>
    );
  }
}
