import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { pageview } from '../shared/lib/google-global-site-tag';
import { resolveCanonical } from '../shared/lib/resolve-pathname-to-canonical';

const trackPage = (props, routeConfig) => {
  const { location } = props;
  const { pathname } = location;
  const canonical = resolveCanonical(routeConfig, pathname, false);

  if (canonical) {
    pageview(canonical);
  } else {
    console.warn('Unable to resolve canonical page path for', pathname);
  }
};

const withTracker = (WrappedComponent, { routeConfig }) => {
  const HOC = class extends Component {
    componentDidMount() {
      trackPage(this.props, routeConfig);
    }

    componentWillReceiveProps(nextProps) {
      const {
        pathname: currentPage,
        search: currentSearch,
      } = this.props.location;

      const {
        pathname: nextPage,
        search: nextSearch,
      } = nextProps.location;

      const changed = (
        nextPage !== currentPage ||
        nextSearch !== currentSearch
      );

      if (nextPage && changed) {
        trackPage(nextProps, routeConfig);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  HOC.propTypes = {
    location: PropTypes.object,
  };

  return HOC;
};

export default withTracker;
