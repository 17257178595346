import { createBrowserHistory, createMemoryHistory } from 'history';

let historyInstance;

const getHistory = (url) => {
  if (!historyInstance) {
    const isServer = typeof window === 'undefined';
    historyInstance = isServer
      ? createMemoryHistory({
        initialEntries: [url],
      })
      : createBrowserHistory({ basename: '/v2' });
  }
  return historyInstance;
};

export default getHistory;
