import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import componentBase from '../../../../../../src/lib/component-base';
import Icon from '../../../../../../src/components/lib/Icon';

@componentBase('SubscriptionService')
export default class SubscriptionService extends Component {
  static propTypes = {
    serviceImageSrc: PropTypes.string,
    serviceUnlocked: PropTypes.bool,
    serviceTitle: PropTypes.string,
    serviceLink: PropTypes.string,
    serviceSubtitle: PropTypes.string,
  };

  render() {
    const {
      serviceUnlocked,
      serviceLink,
      serviceImageSrc,
      serviceTitle,
      serviceSubtitle,
    } = this.props;
    const backgroundClass = serviceUnlocked ? 'unlocked' : 'standard';

    let anchorStyle = 'service-section-head';
    if (!serviceLink) {
      // Used only for the case of 'Better Ads', removes highlighting on hover
      anchorStyle = `${anchorStyle} betterAdsLinkStyle`;
    }

    const innerContent = (
      <Fragment>
        <div className={`col-xs-2 col-sm-1 serviceSectionIcon-${backgroundClass}`}>
          <Icon className='serviceIcon' src={serviceImageSrc} />
        </div>
        <div className={`col-xs-10 col-sm-11 serviceSectionDescription-${backgroundClass}`}>
          <p className='title'>{serviceTitle}</p>
          <p className='subtitle'>{serviceSubtitle}</p>
        </div>
      </Fragment>
    );

    if (!serviceLink) {
      return (
        <div className={`${anchorStyle} row-eq-height`} style={{ padding: '10px 0', minHeight: '60px' }}>
          {innerContent}
        </div>
      );
    }

    return (
      <Link className={`${anchorStyle} row-eq-height`} to={serviceLink} style={{ padding: '10px 0', minHeight: '60px' }}>
        {innerContent}
      </Link>
    );
  }
}
