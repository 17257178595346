import reportException from '../../../shared/lib/report-exception';

export default function clientMiddleware(client) {
  return ({ dispatch, getState }) =>
    next =>
      (action) => {
        if (!action) {
          throw new Error('Attempt to dispatch undefined action');
        }

        if (typeof action === 'function') {
          return action(dispatch, getState);
        }

        const { promise, types, ...rest } = action; // eslint-disable-line no-redeclare
        if (!promise) {
          return next(action);
        }

        console.log('DEPRECATED USE OF clientMiddleware PROMISE ACTION:', action);

        const [REQUEST, SUCCESS, FAILURE] = types;
        next({ ...rest, type: REQUEST });

        const actionPromise = promise(client);
        actionPromise.then(
          result => next({ ...rest, result, type: SUCCESS }),
          error => next({ ...rest, error, type: FAILURE }),
        )
          .catch((error) => {
            reportException(error, {
              types,
            }, 'client-middleware');
            next({ ...rest, error, type: FAILURE });
          });

        return actionPromise;
      };
}
