// export const AccountProfile = {
//   footer: {
//     id: 'div-gpt-ad-1489619015062-0',
//     sizes: [728, 90],
//     adSlot: '/1010197/BS_Account_Bottom_728x90',
//   },
//   footerMobile: {
//     id: 'div-gpt-ad-1489619261605-0',
//     sizes: [320, 50],
//     adSlot: '/1010197/BS_Account_Bottom_320x100',
//   },
//   top: {
//     id: 'div-gpt-ad-1517446561501-0',
//     sizes: [728, 90],
//     adSlot: '/1010197/BS_Account_Top_728x90',
//   },
//   topMobile: {
//     id: 'div-gpt-ad-1517446468333-0',
//     sizes: [320, 50],
//     adSlot: '/1010197/BS_Account_Top_320x100',
//   },
// };
export const AdvancedSearch = {
  footer: {
    id: 'div-gpt-ad-1500408352760-0',
    sizes: [728, 90],
    adSlot: '/1010197/BS_FlightTrackerAdvancedSearch_bottom_728x90',
  },
  footerMobile: {
    id: 'div-gpt-ad-1500408413552-0',
    sizes: [320, 50],
    adSlot: '/1010197/BS_FlightTrackerAdvancedSearch_bottom_320x100',
  },
  top: {
    id: 'div-gpt-ad-1500408303616-0',
    sizes: [728, 90],
    adSlot: '/1010197/BS_FlightTrackerAdvancedSearch_top_728x90',
  },
  topMobile: {
    id: 'div-gpt-ad-1500408231516-0',
    sizes: [320, 50],
    adSlot: '/1010197/BS_FlightTrackerAdvancedSearch_top_320x100',
  },
};
export const AirportCurrentConditions = {
  footer: {
    id: 'div-gpt-ad-1470845383089-2',
    sizes: [728, 90],
    adSlot: '/1010197/BS_AirportConditions_bottom_728x90',
  },
  footerMobile: {
    id: 'div-gpt-ad-1470934182836-0',
    sizes: [320, 50],
    adSlot: '/1010197/BS_AirportConditions_bottom_320x100',
  },
  top: {
    id: 'div-gpt-ad-1500402642937-0',
    sizes: [728, 90],
    adSlot: '/1010197/BS_AirportConditions_top_728x90',
  },
  topMobile: {
    id: 'div-gpt-ad-1500402570367-0',
    sizes: [320, 50],
    adSlot: '/1010197/BS_AirportConditions_top_320x100',
  },
};
export const AirportDelayMap = {
  footer: {
    id: 'div-gpt-ad-1470845645868-0',
    sizes: [728, 90],
    adSlot: '/1010197/BS_AirportDelays_bottom_728x90',
  },
  footerMobile: {
    id: 'div-gpt-ad-1470934182836-1',
    sizes: [320, 50],
    adSlot: '/1010197/BS_AirportDelay_bottom_320x100',
  },
  top: {
    id: 'div-gpt-ad-1500406358802-0',
    sizes: [728, 90],
    adSlot: '/1010197/BS_AirportDelay_top_728x90',
  },
  topMobile: {
    id: 'div-gpt-ad-1500406274331-0',
    sizes: [320, 50],
    adSlot: '/1010197/BS_AirportDelay_top_320x100',
  },
};
export const AirportSearch = {
  footer: {
    id: 'div-gpt-ad-1500402313575-0',
    sizes: [728, 90],
    adSlot: '/1010197/BS_AirportConditionsSearch_bottom_728x90',
  },
  footerMobile: {
    id: 'div-gpt-ad-1500402396077-0',
    sizes: [320, 50],
    adSlot: '/1010197/BS_AirportConditionsSearch_bottom_320x100',
  },
  top: {
    id: 'div-gpt-ad-1500402020933-0',
    sizes: [728, 90],
    adSlot: '/1010197/BS_AirportConditionsSearch_top_728x90',
  },
  topMobile: {
    id: 'div-gpt-ad-1500402128658-0',
    sizes: [320, 50],
    adSlot: '/1010197/BS_AirportConditionsSearch_top_320x100',
  },
};
// export const CreateAccount = {
//   footer: {
//     id: 'div-gpt-ad-1489619015062-0',
//     sizes: [728, 90],
//     adSlot: '/1010197/BS_Account_Bottom_728x90',
//   },
//   footerMobile: {
//     id: 'div-gpt-ad-1489619261605-0',
//     sizes: [320, 50],
//     adSlot: '/1010197/BS_Account_Bottom_320x100',
//   },
//   top: {
//     id: 'div-gpt-ad-1517446561501-0',
//     sizes: [728, 90],
//     adSlot: '/1010197/BS_Account_Top_728x90',
//   },
//   topMobile: {
//     id: 'div-gpt-ad-1517446468333-0',
//     sizes: [320, 50],
//     adSlot: '/1010197/BS_Account_Top_320x100',
//   },
// };
export const DepArrSubscriptionMarketing = {
  footer: {
    id: 'div-gpt-ad-1489619015062-0',
    sizes: [728, 90],
    adSlot: '/1010197/BS_Account_Bottom_728x90',
  },
  footerMobile: {
    id: 'div-gpt-ad-1489619261605-0',
    sizes: [320, 50],
    adSlot: '/1010197/BS_Account_Bottom_320x100',
  },
  top: {
    id: 'div-gpt-ad-1517446561501-0',
    sizes: [728, 90],
    adSlot: '/1010197/BS_Account_Top_728x90',
  },
  topMobile: {
    id: 'div-gpt-ad-1517446468333-0',
    sizes: [320, 50],
    adSlot: '/1010197/BS_Account_Top_320x100',
  },
};
export const ExtendedDetails = {
  footer: {
    id: 'div-gpt-ad-1501016286376-2',
    sizes: [728, 90],
    adSlot: '/1010197/BS_FlightTrackerExtendedDetails_bottom_728x90',
  },
  footerMobile: {
    id: 'div-gpt-ad-1501016286376-3',
    sizes: [320, 50],
    adSlot: '/1010197/BS_FlightTrackerExtendedDetails_bottom_320x100',
  },
  top: {
    id: 'div-gpt-ad-1501016286376-0',
    sizes: [728, 90],
    adSlot: '/1010197/BS_FlightTrackerExtendedDetails_top_728x90',
  },
  topMobile: {
    id: 'div-gpt-ad-1501016286376-1',
    sizes: [320, 50],
    adSlot: '/1010197/BS_FlightTrackerExtendedDetails_top_320x100',
  },
};
export const FlightAlertsSubscriptionMarketing = {
  footer: {
    id: 'div-gpt-ad-1489619015062-0',
    sizes: [728, 90],
    adSlot: '/1010197/BS_Account_Bottom_728x90',
  },
  footerMobile: {
    id: 'div-gpt-ad-1489619261605-0',
    sizes: [320, 50],
    adSlot: '/1010197/BS_Account_Bottom_320x100',
  },
  top: {
    id: 'div-gpt-ad-1517446561501-0',
    sizes: [728, 90],
    adSlot: '/1010197/BS_Account_Top_728x90',
  },
  topMobile: {
    id: 'div-gpt-ad-1517446468333-0',
    sizes: [320, 50],
    adSlot: '/1010197/BS_Account_Top_320x100',
  },
};
export const FlightTracker = {
  footer: {
    id: 'div-gpt-ad-1470845574196-2',
    sizes: [728, 90],
    adSlot: '/1010197/BS_FlightTracker_bottom_728x90',
  },
  footerMobile: {
    id: 'div-gpt-ad-1470934182836-3',
    sizes: [320, 50],
    adSlot: '/1010197/BS_FlightTracker_bottom_320x100',
  },
  top: {
    id: 'div-gpt-ad-1502745996964-1',
    sizes: [728, 90],
    adSlot: '/1010197/BS_FlightTracker_top_728x90',
  },
  topMobile: {
    id: 'div-gpt-ad-1502745996964-0',
    sizes: [320, 50],
    adSlot: '/1010197/BS_FlightTracker_top_320x100',
  },
};

export const FlightTrackerMap = {
  footerMobile: {
    id: 'div-gpt-ad-1528306345249-1',
    sizes: [320, 50],
    adSlot: '/1010197/BS_FlightTracker_Mobile_Map_bottom_320x100',
  },
  topMobile: {
    id: 'div-gpt-ad-1528306345249-3',
    sizes: [320, 50],
    adSlot: '/1010197/BS_FlightTracker_Mobile_Map_top_320x100',
  },
};

// export const ForgotPassword = {
//   footer: {
//     id: 'div-gpt-ad-1489619015062-0',
//     sizes: [728, 90],
//     adSlot: '/1010197/BS_Account_Bottom_728x90',
//   },
//   footerMobile: {
//     id: 'div-gpt-ad-1489619261605-0',
//     sizes: [320, 50],
//     adSlot: '/1010197/BS_Account_Bottom_320x100',
//   },
//   top: {
//     id: 'div-gpt-ad-1517446561501-0',
//     sizes: [728, 90],
//     adSlot: '/1010197/BS_Account_Top_728x90',
//   },
//   topMobile: {
//     id: 'div-gpt-ad-1517446468333-0',
//     sizes: [320, 50],
//     adSlot: '/1010197/BS_Account_Top_320x100',
//   },
// };
export const GlobalCancellationsDelays = {
  footer: {
    id: 'div-gpt-ad-1503353720593-2',
    sizes: [728, 90],
    adSlot: '/1010197/BS_GlobalCancellationsDelays_bottom_728x90',
  },
  footerMobile: {
    id: 'div-gpt-ad-1503353720593-3',
    sizes: [320, 50],
    adSlot: '/1010197/BS_GlobalCancellationsDelays_bottom_320x100',
  },
  top: {
    id: 'div-gpt-ad-1503353720593-0',
    sizes: [728, 90],
    adSlot: '/1010197/BS_GlobalCancellationsDelays_top_728x90',
  },
  topMobile: {
    id: 'div-gpt-ad-1503353720593-1',
    sizes: [320, 50],
    adSlot: '/1010197/BS_GlobalCancellationsDelays_top_320x100',
  },
};
export const HistoricalSubscriptionMarketing = {
  footer: {
    id: 'div-gpt-ad-1489619015062-0',
    sizes: [728, 90],
    adSlot: '/1010197/BS_Account_Bottom_728x90',
  },
  footerMobile: {
    id: 'div-gpt-ad-1489619261605-0',
    sizes: [320, 50],
    adSlot: '/1010197/BS_Account_Bottom_320x100',
  },
  top: {
    id: 'div-gpt-ad-1517446561501-0',
    sizes: [728, 90],
    adSlot: '/1010197/BS_Account_Top_728x90',
  },
  topMobile: {
    id: 'div-gpt-ad-1517446468333-0',
    sizes: [320, 50],
    adSlot: '/1010197/BS_Account_Top_320x100',
  },
};
export const Homepage = {
  footer: {
    id: 'div-gpt-ad-1503432146362-0',
    sizes: [728, 90],
    adSlot: '/1010197/BS_Home_Bottom_728x90',
  },
  footerMobile: {
    id: 'div-gpt-ad-1503432146362-1',
    sizes: [320, 50],
    adSlot: '/1010197/BS_Home_Bottom_320x100',
  },
  top: {
    id: 'div-gpt-ad-1517446660383-0',
    sizes: [728, 90],
    adSlot: '/1010197/BS_Home_Top_728x90',
  },
  topMobile: {
    id: 'div-gpt-ad-1517446740659-0',
    sizes: [320, 50],
    adSlot: '/1010197/BS_Home_Top_320x100',
  },
};
// export const Login = {
//   footer: {
//     id: 'div-gpt-ad-1489619015062-0',
//     sizes: [728, 90],
//     adSlot: '/1010197/BS_Account_Bottom_728x90',
//   },
//   footerMobile: {
//     id: 'div-gpt-ad-1489619261605-0',
//     sizes: [320, 50],
//     adSlot: '/1010197/BS_Account_Bottom_320x100',
//   },
//   top: {
//     id: 'div-gpt-ad-1517446561501-0',
//     sizes: [728, 90],
//     adSlot: '/1010197/BS_Account_Top_728x90',
//   },
//   topMobile: {
//     id: 'div-gpt-ad-1517446468333-0',
//     sizes: [320, 50],
//     adSlot: '/1010197/BS_Account_Top_320x100',
//   },
// };
export const MultiFlight = {
  footer: {
    id: 'div-gpt-ad-1500411822710-0',
    sizes: [728, 90],
    adSlot: '/1010197/BS_FlightTrackerFlightPicker_bottom_728x90',
  },
  footerMobile: {
    id: 'div-gpt-ad-1500411994336-0',
    sizes: [320, 50],
    adSlot: '/1010197/BS_FlightTrackerFlightPicker_bottom_320x100',
  },
  top: {
    id: 'div-gpt-ad-1500412152622-0',
    sizes: [728, 90],
    adSlot: '/1010197/BS_FlightTrackerFlightPicker_top_728x90',
  },
  topMobile: {
    id: 'div-gpt-ad-1500412067268-0',
    sizes: [320, 50],
    adSlot: '/1010197/BS_FlightTrackerFlightPicker_top_320x100',
  },
};
// export const MyAccount = {
//   footer: {
//     id: 'div-gpt-ad-1489619015062-0',
//     sizes: [728, 90],
//     adSlot: '/1010197/BS_Account_Bottom_728x90',
//   },
//   footerMobile: {
//     id: 'div-gpt-ad-1489619261605-0',
//     sizes: [320, 50],
//     adSlot: '/1010197/BS_Account_Bottom_320x100',
//   },
//   top: {
//     id: 'div-gpt-ad-1517446561501-0',
//     sizes: [728, 90],
//     adSlot: '/1010197/BS_Account_Top_728x90',
//   },
//   topMobile: {
//     id: 'div-gpt-ad-1517446468333-0',
//     sizes: [320, 50],
//     adSlot: '/1010197/BS_Account_Top_320x100',
//   },
// };
export const OnTimePerformance = {
  footer: {
    id: 'div-gpt-ad-1502743031520-1',
    sizes: [728, 90],
    adSlot: '/1010197/BS_FlightOntimePerformance_bottom_728x90',
  },
  footerMobile: {
    id: 'div-gpt-ad-1502743031520-0',
    sizes: [320, 50],
    adSlot: '/1010197/BS_FlightOntimePerformance_bottom_320x100',
  },
  top: {
    id: 'div-gpt-ad-1502743031520-5',
    sizes: [728, 90],
    adSlot: '/1010197/BS_FlightOntimePerformance_top_728x90',
  },
  topMobile: {
    id: 'div-gpt-ad-1502743031520-4',
    sizes: [320, 50],
    adSlot: '/1010197/BS_FlightOntimePerformance_top_320x100',
  },
};
// export const PaymentInformation = {
//   footer: {
//     id: 'div-gpt-ad-1489619015062-0',
//     sizes: [728, 90],
//     adSlot: '/1010197/BS_Account_Bottom_728x90',
//   },
//   footerMobile: {
//     id: 'div-gpt-ad-1489619261605-0',
//     sizes: [320, 50],
//     adSlot: '/1010197/BS_Account_Bottom_320x100',
//   },
//   top: {
//     id: 'div-gpt-ad-1517446561501-0',
//     sizes: [728, 90],
//     adSlot: '/1010197/BS_Account_Top_728x90',
//   },
//   topMobile: {
//     id: 'div-gpt-ad-1517446468333-0',
//     sizes: [320, 50],
//     adSlot: '/1010197/BS_Account_Top_320x100',
//   },
// };
export const PlanSelection = {
  footer: {
    id: 'div-gpt-ad-1489619015062-0',
    sizes: [728, 90],
    adSlot: '/1010197/BS_Account_Bottom_728x90',
  },
  footerMobile: {
    id: 'div-gpt-ad-1489619261605-0',
    sizes: [320, 50],
    adSlot: '/1010197/BS_Account_Bottom_320x100',
  },
  top: {
    id: 'div-gpt-ad-1517446561501-0',
    sizes: [728, 90],
    adSlot: '/1010197/BS_Account_Top_728x90',
  },
  topMobile: {
    id: 'div-gpt-ad-1517446468333-0',
    sizes: [320, 50],
    adSlot: '/1010197/BS_Account_Top_320x100',
  },
};
// export const RegistrationBillingInformation = {
//   footer: {
//     id: 'div-gpt-ad-1489619015062-0',
//     sizes: [728, 90],
//     adSlot: '/1010197/BS_Account_Bottom_728x90',
//   },
//   footerMobile: {
//     id: 'div-gpt-ad-1489619261605-0',
//     sizes: [320, 50],
//     adSlot: '/1010197/BS_Account_Bottom_320x100',
//   },
//   top: {
//     id: 'div-gpt-ad-1517446561501-0',
//     sizes: [728, 90],
//     adSlot: '/1010197/BS_Account_Top_728x90',
//   },
//   topMobile: {
//     id: 'div-gpt-ad-1517446468333-0',
//     sizes: [320, 50],
//     adSlot: '/1010197/BS_Account_Top_320x100',
//   },
// };
// export const RegistrationCompletion = {
//   footer: {
//     id: 'div-gpt-ad-1489619015062-0',
//     sizes: [728, 90],
//     adSlot: '/1010197/BS_Account_Bottom_728x90',
//   },
//   footerMobile: {
//     id: 'div-gpt-ad-1489619261605-0',
//     sizes: [320, 50],
//     adSlot: '/1010197/BS_Account_Bottom_320x100',
//   },
//   top: {
//     id: 'div-gpt-ad-1517446561501-0',
//     sizes: [728, 90],
//     adSlot: '/1010197/BS_Account_Top_728x90',
//   },
//   topMobile: {
//     id: 'div-gpt-ad-1517446468333-0',
//     sizes: [320, 50],
//     adSlot: '/1010197/BS_Account_Top_320x100',
//   },
// };
export const Subscribe = {
  footer: {
    id: 'div-gpt-ad-1489619015062-0',
    sizes: [728, 90],
    adSlot: '/1010197/BS_Account_Bottom_728x90',
  },
  footerMobile: {
    id: 'div-gpt-ad-1489619261605-0',
    sizes: [320, 50],
    adSlot: '/1010197/BS_Account_Bottom_320x100',
  },
  top: {
    id: 'div-gpt-ad-1517446561501-0',
    sizes: [728, 90],
    adSlot: '/1010197/BS_Account_Top_728x90',
  },
  topMobile: {
    id: 'div-gpt-ad-1517446468333-0',
    sizes: [320, 50],
    adSlot: '/1010197/BS_Account_Top_320x100',
  },
};
export const SubscriptionMarketing = {
  footer: {
    id: 'div-gpt-ad-1489619015062-0',
    sizes: [728, 90],
    adSlot: '/1010197/BS_Account_Bottom_728x90',
  },
  footerMobile: {
    id: 'div-gpt-ad-1489619261605-0',
    sizes: [320, 50],
    adSlot: '/1010197/BS_Account_Bottom_320x100',
  },
  top: {
    id: 'div-gpt-ad-1517446561501-0',
    sizes: [728, 90],
    adSlot: '/1010197/BS_Account_Top_728x90',
  },
  topMobile: {
    id: 'div-gpt-ad-1517446468333-0',
    sizes: [320, 50],
    adSlot: '/1010197/BS_Account_Top_320x100',
  },
};
export const VerifyEmail = {
  footer: {
    id: 'div-gpt-ad-1489619015062-0',
    sizes: [728, 90],
    adSlot: '/1010197/BS_Account_Bottom_728x90',
  },
  footerMobile: {
    id: 'div-gpt-ad-1489619261605-0',
    sizes: [320, 50],
    adSlot: '/1010197/BS_Account_Bottom_320x100',
  },
  top: {
    id: 'div-gpt-ad-1517446561501-0',
    sizes: [728, 90],
    adSlot: '/1010197/BS_Account_Top_728x90',
  },
  topMobile: {
    id: 'div-gpt-ad-1517446468333-0',
    sizes: [320, 50],
    adSlot: '/1010197/BS_Account_Top_320x100',
  },
};
// export const ViewPaymentHistoryDetail = {
//   footer: {
//     id: 'div-gpt-ad-1489619015062-0',
//     sizes: [728, 90],
//     adSlot: '/1010197/BS_Account_Bottom_728x90',
//   },
//   footerMobile: {
//     id: 'div-gpt-ad-1489619261605-0',
//     sizes: [320, 50],
//     adSlot: '/1010197/BS_Account_Bottom_320x100',
//   },
// };
export const UnifiedSearch = {
  footer: {
    id: 'div-gpt-ad-1500407896904-0',
    sizes: [728, 90],
    adSlot: '/1010197/BS_FlightTrackerSearch_bottom_728x90',
  },
  footerMobile: {
    id: 'div-gpt-ad-1500407738125-0',
    sizes: [320, 50],
    adSlot: '/1010197/BS_FlightTrackerSearch_bottom_320x100',
  },
  top: {
    id: 'div-gpt-ad-1500407967872-0',
    sizes: [728, 90],
    adSlot: '/1010197/BS_FlightTrackerSearch_top_728x90',
  },
  topMobile: {
    id: 'div-gpt-ad-1500408024011-0',
    sizes: [320, 50],
    adSlot: '/1010197/BS_FlightTrackerSearch_top_320x100',
  },
};
