import * as t from './actionTypes';

const buildUrl = (carrier, flightNumber, year, month, day, flightId) => {
  const base = `/historical-flight/${carrier}/${flightNumber}/${year}/${month}/${day}`;
  return flightId ? `${base}/${flightId}` : base;
};

export const setHistoricalSearchValues = (args, triggeredByEnter, byFlight /* vsByAirport */) => ({
  type: t.SET_HISTORICAL_SEARCH_INPUTS,
  result: {
    ...args,
    secondary: triggeredByEnter,
    byFlight,
  },
});

export const updateHistoricalByAirportSearch = historicalByAirportSearch => ({
  type: t.HISTORICAL_BY_AIRPORT_SEARCH,
  historicalByAirportSearch,
});

export const updateHistoricalByFlightNumber = historicalByFlightNumber => ({
  type: t.HISTORICAL_BY_FLIGHT_NUMBER,
  historicalByFlightNumber,
});

const startSpinner = () => ({
  type: t.START_SPINNER,
});

const endSpinner = () => ({
  type: t.END_SPINNER,
});

export const loadingByParamsWithId = () => ({
  type: t.LOAD_BY_ID,
});

export const loadByParamsWithIdSuccess = result => ({
  type: t.LOAD_BY_ID_SUCCESS,
  result,
});

export const loadByParamsWithIdFail = error => ({
  type: t.LOAD_BY_ID_FAIL,
  error,
});

export const loadByParamsWithId = ({
  carrier, flightNumber, year, month, day, flightId,
}) => (
  async (dispatch, getState, client) => {
    if (flightId) { // since this is load by params with id
      dispatch(startSpinner());
      dispatch(loadingByParamsWithId());
      try {
        const url = buildUrl(carrier, flightNumber, year, month, day, flightId);
        const result = await client.get(url);
        dispatch(loadByParamsWithIdSuccess(result));
        dispatch(endSpinner());
      } catch (err) {
        dispatch(loadByParamsWithIdFail(err));
        dispatch(endSpinner());
      }
    }
  }
);

const loadingByCarrierFlightNumberAndDate = () => ({
  type: t.LOAD_SEARCH,
});

const loadByCarrierFlightNumberAndDateSuccess = result => ({
  type: t.LOAD_SEARCH_SUCCESS,
  result,
});

const loadByCarrierFlightNumberAndDateFail = error => ({
  type: t.LOAD_SEARCH_FAIL,
  error,
});

export const loadByCarrierFlightNumberAndDate = ({
  carrier, flightNumber, year, month, day,
}) => (
  async (dispatch, getState, client) => {
    dispatch(startSpinner());
    dispatch(loadingByCarrierFlightNumberAndDate());

    try {
      const result = await client.get(buildUrl(carrier, flightNumber, year, month, day));
      dispatch(loadByCarrierFlightNumberAndDateSuccess(result));
      dispatch(endSpinner());
    } catch (err) {
      dispatch(loadByCarrierFlightNumberAndDateFail(err));
      dispatch(endSpinner());
    }
  }
);

export const userWithSingleHistoricalCredit = () => ({
  type: t.USING_SINGLE_CREDIT,
  result: { usingFinalCredit: true },
});

export const usedFinalCredit = () => ({
  type: t.USED_SINGLE_CREDIT,
  result: { usingFinalCredit: null },
});

export const setByFlightSecondaryAction = bool => ({
  type: t.SET_BY_FLIGHT_SECONDARY_ACTION,
  bool,
});

export const setByAirlineSecondaryAction = bool => ({
  type: t.SET_BY_AIRLINE_SECONDARY_ACTION,
  bool,
});

export const setHistoricalSingleErrorMessage = message => ({
  type: t.SET_HISTORICAL_SINGLE_ERROR_MESSAGE,
  message,
});
