import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { SelectField } from '@flightstats/component-lib';
import IconHeader from '../../../../shared/components/IconHeader/IconHeader';
import componentBase from '../../../../src/lib/component-base';
import ListIcon from '../../../../static/images/subscription-icons/List-Icon.svg';

@componentBase('SubscriptionTierSelection')
export default class SubscriptionTierSelection extends Component {
  static propTypes = {
    selectTier: PropTypes.func,
    selectedTier: PropTypes.number,
    subscriptionOptions: PropTypes.arrayOf(PropTypes.object),
  };

  handleTierChange = (option) => {
    const tierSelection = option && option.value;
    this.props.selectTier(tierSelection);
  }

  render() {
    return (
      <div id='subscription-select' className='subscription-form-section subscription-select-info'>
        <div className='section-head'>
          <IconHeader title='Select Subscription' icon={ListIcon} />
        </div>
        <div>
          <div className='row spaced'>
            <div className='col-xs-11'>
              <div className='fieldContainer'>
                <label
                  className='accountFieldLabel'
                  htmlFor='subscriptionTierSelect'
                >
                  Subscription Plans
                </label>
                <SelectField
                  bgColor='rgba(255, 255, 255, .4)'
                  borderColor='#FFF'
                  handleMenuSelection={this.handleTierChange}
                  height='30px'
                  iconColor='#FFF'
                  menuBorderWidth='2px'
                  results={this.props.subscriptionOptions}
                  textColor='#FFF'
                  value={this.props.selectedTier}
                />
                <Link
                  key='remindMe'
                  to='/subscription#compare-subscriptions'
                  className='subscriptionTierRemindMe'
                >
                  <p>Remind me, what are the differences in subscription plans?</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
