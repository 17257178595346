import React, { Component } from 'react';
import componentBase from '../../lib/component-base';

@componentBase('MapControlBase')
export default class MapControlBase extends Component {
  static createLeafletElement() {
    return (
      <div />
    );
  }

  constructor(props) {
    super(props);
    this.control = null;
  }

  reset() {
    const component = this;
    return function () { // eslint-disable-line func-names
      const container = this.getContainer();

      if (container) {
        container.style.display = (component.props.hide ? 'none' : null);
      }
    };
  }

  render() {
    if (this.control) {
      this.control.reset(this);
    }

    return (<div />);
  }
}
