import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dialog from 'material-ui/Dialog';
import { confirmModal, cancelModal, hideModal } from '../redux/actions';
import FSFlatButton from '../../../src/components/lib/FSFlatButton';

const ConfirmModal = ({
  body, title, affirmativeActionLabel, negativeActionLabel, dispatch,
}) => {
  const actions = [
    <FSFlatButton
      label={affirmativeActionLabel || 'Yes'}
      onClick={() => dispatch(confirmModal())}
      style={FSFlatButton.style({ width: '80%' })}
      backgroundColor='#7DD63B'
      hoverColor='#64bc23'
      disabled={false}
    />,
    <FSFlatButton
      label={negativeActionLabel || 'No'}
      primary
      onClick={() => dispatch(cancelModal())}
      style={FSFlatButton.style({ width: '80%', color: 'black', marginTop: '10px' })}
      backgroundColor='transparent'
      hoverColor='#c6c6c6'
      disabled={false}
    />,
  ];

  const contentStyle = {
    maxWidth: '347px',
    width: '90%',
  };

  const headerStyle = {
    color: 'black',
    margin: '0 auto',
    display: 'table',
    textAlign: 'center',
  };

  const contentClassName = 'genericModal';
  const titleClassName = 'modalTitle';
  const actionsClassName = 'modalActions';
  const bodyClassName = 'modalBody';

  const bodyStyle = {
    backgroundColor: 'lightgray',
    color: '#333',
    textAlign: 'center',
    padding: '24px 24px 0',
    minHeight: '75px',
  };

  const titleElement = (
    <h1 style={headerStyle}>
      {title || 'Please confirm'}
    </h1>
  );

  return (
    <Dialog
      actions={actions}
      actionsContainerClassName={actionsClassName}
      autoScrollBodyContent
      bodyClassName={bodyClassName}
      bodyStyle={bodyStyle}
      contentClassName={contentClassName}
      contentStyle={contentStyle}
      modal
      onRequestClose={() => dispatch(hideModal())}
      open
      title={titleElement}
      titleClassName={titleClassName}
    >
      <div>
        {body || 'Are you sure?'}
      </div>
    </Dialog>
  );
};

ConfirmModal.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  affirmativeActionLabel: PropTypes.string,
  negativeActionLabel: PropTypes.string,
  dispatch: PropTypes.func,
};

export default connect(state => ({
  confirmed: state.Modal.modalConfirmed,
  canceled: state.Modal.modalCanceled,
}))(ConfirmModal);
