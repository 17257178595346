import { NAME } from './constants';

export const SET_APP_HOST = `${NAME}/SET_APP_HOST`;
export const SET_USER_AGENT = `${NAME}/SET_USER_AGENT`;
export const SET_RECAPTCHA_SITE_KEY = `${NAME}/SET_RECAPTCHA_SITE_KEY`;
export const SET_RELEASE = `${NAME}/SET_RELEASE`;
export const SET_ROUTE_NAME = `${NAME}/SET_ROUTE_NAME`;
export const SET_ROUTE_CONFIG = `${NAME}/SET_ROUTE_CONFIG`;
export const SET_INTERVAL_ID = `${NAME}/SET_INTERVAL_ID`;
export const SET_SLOT_REFRESHED = `${NAME}/SET_SLOT_REFRESHED`;
export const SET_CURRENT_BREAKPOINT = `${NAME}/SET_CURRENT_BREAKPOINT`;
export const ADD_VARIANTS = `${NAME}/ADD_VARIANTS`;
export const CLEAR_VARIANTS = `${NAME}/CLEAR_VARIANTS`;
