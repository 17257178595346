import React from 'react';
import PropTypes from 'prop-types';
import CommonSectionWrapper from '../CommonSectionWrapper/CommonSectionWrapper';
import FSFlatButton from '../../../src/components/lib/FSFlatButton';

const SearchSectionContainer = (props) => {
  const {
    adUnit,
    children,
    hideAd,
    hideButton,
    searchAction,
    subscriptionActive,
    title,
  } = props;

  return (
    <CommonSectionWrapper
      adUnit={adUnit}
      hideAd={hideAd}
      subscriptionActive={subscriptionActive}
      title={title}
    >
      <div className='SearchSectionContainer'>
        {children}
        {!hideButton &&
          <div className='search-section-button-container'>
            <FSFlatButton
              label='Search'
              type='submit'
              style={FSFlatButton.style({ width: '200px' })}
              onClick={e => (searchAction ?
                searchAction(e) :
                console.warn('SearchSectionContainer: no searchAction'))}
              primary
            />
          </div>}
      </div>
    </CommonSectionWrapper>
  );
};

SearchSectionContainer.propTypes = {
  adUnit: PropTypes.object,
  children: PropTypes.object,
  hideAd: PropTypes.bool,
  hideButton: PropTypes.bool,
  searchAction: PropTypes.func,
  subscriptionActive: PropTypes.bool,
  title: PropTypes.string,
};

export default SearchSectionContainer;
