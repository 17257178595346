import { NAME } from './constants';

export const LOAD_BY_DEPARTURE_AIRPORT_CARRIER_AND_DATE = `${NAME}/LOAD_BY_DEPARTURE_AIRPORT_CARRIER_AND_DATE`;
export const LOAD_BY_ARRIVAL_AIRPORT_CARRIER_AND_DATE = `${NAME}/LOAD_BY_ARRIVAL_AIRPORT_CARRIER_AND_DATE`;
export const LOAD_BY_ROUTE_CARRIER_AND_DATE = `${NAME}/LOAD_BY_ROUTE_CARRIER_AND_DATE`;
export const LOAD_SUCCESS = `${NAME}/LOAD_SUCCESS`;
export const LOAD_FAIL = `${NAME}/LOAD_FAIL`;
export const UPDATE_PAGE_INDEX = `${NAME}/UPDATE_PAGE_INDEX`;
export const UPDATE_LOADED = `${NAME}/UPDATE_LOADED`;
export const UPDATE_MULTI_FLIGHT_SEARCH_DATE = `${NAME}/UPDATE_MULTI_FLIGHT_SEARCH_DATE`;
export const UPDATE_MULTI_FLIGHT_SEARCH_TIME = `${NAME}/UPDATE_MULTI_FLIGHT_SEARCH_TIME`;
export const UPDATE_MULTI_FLIGHT_SEARCH_CODESHARE_TOGGLE = `${NAME}/UPDATE_MULTI_FLIGHT_SEARCH_CODESHARE_TOGGLE`;
export const MULTI_FLIGHT_MOBILE_SEARCH_SEARCHING = `${NAME}/MULTI_FLIGHT_MOBILE_SEARCH_SEARCHING`;
export const MULTI_FLIGHT_SEARCH_HAS_MOUNTED = `${NAME}/MULTI_FLIGHT_SEARCH_HAS_MOUNTED`;
export const MULTI_FLIGHT_SEARCH_RESULT_ERROR = `${NAME}/MULTI_FLIGHT_SEARCH_RESULT_ERROR`;
export const SET_MULTI_FLIGHT_SEARCH_AIRLINE_VALUE = `${NAME}/SET_MULTI_FLIGHT_SEARCH_AIRLINE_VALUE`;
export const SET_MULTI_FLIGHT_FLIGHTS = `${NAME}/SET_MULTI_FLIGHT_FLIGHTS`;
export const SET_VALIDATION_ERROR = `${NAME}/SET_VALIDATION_ERROR`;
