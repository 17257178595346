import moment from 'moment';

/*
  hard wired pf-calculations:

  public static void getDateRangeRoutingsGoodFor(Date dateRangeStart, Date dateRangeEnd) {
    Calendar calendar = Calendar.getInstance();
    calendar.setTime(new Date());
    if (5 >= calendar.get(Calendar.DAY_OF_MONTH)) {
      calendar.add(Calendar.MONTH, -1);
      calendar.set(Calendar.DAY_OF_MONTH, 28);
    }
    if (20 <= calendar.get(Calendar.DAY_OF_MONTH)) {
      calendar.set(Calendar.DAY_OF_MONTH, 15);
      dateRangeEnd.setTime(calendar.getTime().getTime());
      calendar.add(Calendar.MONTH, -2);
      dateRangeStart.setTime(calendar.getTime().getTime());
    } else {
      calendar.set(Calendar.DAY_OF_MONTH, 1);
      calendar.add(Calendar.DAY_OF_MONTH, -1);
      dateRangeEnd.setTime(calendar.getTime().getTime());
      calendar.add(Calendar.DAY_OF_MONTH, 1);
      calendar.add(Calendar.MONTH, -2);
      dateRangeStart.setTime(calendar.getTime().getTime());
    }
  }
*/


const getDateRangeRoutingsGoodFor = (dateRangeStart, dateRangeEnd) => {
  const now = moment.utc();

  if (moment.isMoment(dateRangeStart) && moment.isMoment(dateRangeEnd)) {
    let startRange = dateRangeStart.clone();
    let endRange = dateRangeEnd.clone();

    if (now.date() <= 5) {
      now.subtract(1, 'months');
      now.endOf('month');
    }
    if (now.date() >= 20) {
      now.set('date', 15);
      endRange = now.clone();

      now.subtract(2, 'months');
      startRange = now.clone();
    } else {
      now.subtract(1, 'months');
      now.endOf('month');
      endRange = now.clone();

      now.add(1, 'days');
      now.subtract(2, 'months');
      startRange = now.clone();
    }

    return {
      endRange,
      startRange,
    };
  }

  throw new Error('both getDateRangeRoutingsGoodFor arguments should be moment.js objects');
};

export default getDateRangeRoutingsGoodFor;
