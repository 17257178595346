import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import { pathOr, path } from 'ramda';
import Meta from '../../../../shared/components/Meta/container';
import { loadExtendedDetailsByCarrierFlightNumberAndDate } from '../../../SingleFlight/redux/actions';
import componentBase from '../../../../src/lib/component-base';
import HistoricalFlightTrackerComponent from '../../../HistoricalFlightStatus/components/HistoricalFlightTracker/children/FlightTracker/HistoricalFlightTracker';
import NoFlight from '../../../SingleFlight/children/FlightTracker/children/NoFlight/NoFlight';
import Ad from '../../../../shared/components/Ad/Ad';
import { ExtendedDetails as extendedDetailsAds } from '../ads';
import { shouldLoad } from '../../../../src/redux/utils';
import onResize from '../../../../src/lib/on-resize';
import { flightURL } from '../../../../src/lib/single-flight-util';
import { combineParamsWithQuerystring } from '../../../../src/lib/combine-params-util';
import { isNilOrEmpty } from '../../../../src/lib/ramda-extension';
import { resolveCanonicalFromPathname } from '../../../App/redux/selectors';

@connect(state => ({
  canonical: resolveCanonicalFromPathname(state),
  error: state.SingleFlightTracker.error,
  loaded: state.SingleFlightTracker.loaded,
  loading: state.SingleFlightTracker.loading,
  shouldLoad: shouldLoad(state, 'SingleFlightTracker'),
  extendedData: state.SingleFlightTracker.extendedData,
  flight: state.SingleFlightTracker.flight,
}))
@onResize()
@componentBase('ExtendedDetails')
export default class ExtendedDetails extends React.Component {
  static propTypes = {
    canonical: PropTypes.string,
    dispatch: PropTypes.func,
    error: PropTypes.object,
    extendedData: PropTypes.object,
    flight: PropTypes.object,
    loading: PropTypes.bool,
    location: PropTypes.object,
    shouldLoad: PropTypes.bool,
    match: PropTypes.object,
    name: PropTypes.string,
    params: PropTypes.object,
    user: PropTypes.object,
  };

  componentWillMount() {
    if (this.props.shouldLoad) {
      this.loadFlight();
    }
  }

  componentDidMount() {
    const { error } = this.props;
    if (!error) {
      this.loadFlight(this.props);
    }
    this.initResizeListener();
  }

  componentWillReceiveProps(nextProps) {
    const { dispatch, location: prevLocation } = this.props;
    const { pathname: prevPathname } = prevLocation;
    const {
      flight, extendedData, location: nextLocation,
    } = nextProps;
    const { pathname: nextPathname } = nextLocation;

    // update on the click of another segment stop
    if (prevPathname !== nextPathname) {
      this.loadFlight(nextProps);
    } else if (!isNilOrEmpty(flight) && isNilOrEmpty(extendedData)) {
      // The flight was loaded by flightId without using the date
      const redirPathname = `/flight-details/${flightURL(flight)}`;
      (nextPathname !== redirPathname) && dispatch(replace(redirPathname));
    }
  }

  componentWillUnmount() {
    this.removeResizeListener();
  }

  loadFlight = (props) => {
    const { dispatch } = props || this.props;
    const params = combineParamsWithQuerystring(props || this.props);
    const {
      year, month, day, flightId,
    } = params;

    if (year && month && day) {
      dispatch(loadExtendedDetailsByCarrierFlightNumberAndDate(params));
    } else {
      const now = moment();
      dispatch(loadExtendedDetailsByCarrierFlightNumberAndDate({
        year: now.year(),
        month: now.month() + 1,
        day: now.date(),
        carrier: params.carrier,
        flightNumber: params.flightNumber,
        flightId,
      }));
    }
  };

  handleResize = () => this.forceUpdate();

  formatMetaFullname = (carrierName, carrierFsCode, flightNumber) =>
    `${carrierName} ${carrierFsCode} ${flightNumber}`.trim();


  formatMetaRoute = (dep, arr) => (dep && arr ? `(${dep} to ${arr}) ` : '');

  formatMetaDescription = ({
    arrivalAirportFs,
    carrierFsCode,
    carrierName,
    departureAirportFs,
    flightNumber,
  }) =>
    ['See all the details FlightStats has collected about flight ', `${this.formatMetaFullname(carrierName, carrierFsCode, flightNumber)} `, `${this.formatMetaRoute(departureAirportFs, arrivalAirportFs)}including tail number, equipment`, ' information, and runway times',
    ].join('')

  formatMetaTitle = ({ carrierName, carrierFsCode, flightNumber }) =>
    `${this.formatMetaFullname(carrierName, carrierFsCode, flightNumber)} Flight Details, Notes, History - FlightStats`;
  // Meta Title wording is a result from April 2018 SEO Audit suggestion

  formatMetaKeywords = ({ carrierFsCode, carrierName, flightNumber }) => {
    const flightDetails = text => `${text} flight details`.trim();
    const shortname = `${carrierName} ${flightNumber}`;
    const codename = `${carrierFsCode} ${flightNumber}`.trim();
    const codenameNoSpace = `${carrierFsCode}${flightNumber}`;
    const comma = (textA, textB) => (textA || textB ? ',' : '');
    return [
      `${flightDetails(shortname)},`,
      `${flightDetails(codename)},`,
      `${codename}`,
      `${comma(carrierFsCode, flightNumber)}`,
      `${codenameNoSpace}`,
      `${comma(carrierFsCode, flightNumber)}`,
      `${shortname}`,
    ].join('');
  }

  resolveMetaParams = () => {
    const [arrFs, depFs, carrierName] = [
      ['arrivalAirport', 'fs'],
      ['departureAirport', 'fs'],
      ['carrier', 'name'],
    ]
      .map(p => pathOr('', ['extendedData', ...p], this.props));

    const params = combineParamsWithQuerystring(this.props);
    const [carrierFs, flightNumber] = ['carrier', 'flightNumber']
      .map(k => pathOr('', [k], params));

    return {
      arrivalAirportFs: arrFs.toUpperCase(),
      carrierFsCode: carrierFs.toUpperCase(),
      carrierName,
      departureAirportFs: depFs.toUpperCase(),
      flightNumber,
    };
  }

  render() {
    const {
      dispatch,
      extendedData,
      location,
      match,
      error,
      user,
    } = this.props;
    const { reportUserEvent } = this.context;
    const params = combineParamsWithQuerystring(this.props);
    let {
      canonical,
    } = this.props;
    const props = {
      details: extendedData,
      dispatch,
      hasCredits: true,
      location,
      match,
    };

    const subscriptionActive = pathOr(false, ['subscriptionActive'], user);

    const {
      left,
      right,
    } = extendedDetailsAds;

    let ads = null;
    if (!subscriptionActive) {
      ads = [
        (
          <center className='col-xs-12 col-sm-6' key={`${left.id}-div-center`}>
            <Ad key={left.id} {...left} />
          </center>
        ),
      ];
      if (path(['mediaBreakpoint', 'value'], this) > 769) {
        ads.push((
          <center className='hidden-xs col-sm-6' key={`${right.id}-div-center`}>
            <Ad key={right.id} {...right} />
          </center>
        ));
      }
    }
    const metaParams = this.resolveMetaParams();
    const segments = path(['extendedData', 'segments'], this.props);
    if (segments) {
      // find correct index
      const selectedFlight = segments.find(element => (`${element.flightId}` === params.flightId));
      if (selectedFlight) {
        const { carrier } = selectedFlight;
        canonical = `https://www.flightstats.com/v2/flight-details/${carrier.fs}/${carrier.flightNumber}`;
      }
    }

    return (
      <div className='container'>
        <Meta
          canonical={canonical}
          description={this.formatMetaDescription(metaParams)}
          keywords={this.formatMetaKeywords(metaParams)}
          match={match}
          title={this.formatMetaTitle(metaParams)}
        />
        {error ?
          <NoFlight
            {...params}
            dispatch={dispatch}
            reportUserEvent={reportUserEvent}
            user={user}
          /> :
          <HistoricalFlightTrackerComponent
            {...props}
            extendedDetails
            extendedDetailsAds={ads}
          />
        }
      </div>
    );
  }
}
