import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { path, pathOr } from 'ramda';
import { clearOTP, loadOTPByFlight } from '../redux/actions';
import componentBase from '../../../src/lib/component-base';
import OnTimePerformanceDetail from './children/SingleFlight/container';
import Header from './shared/Header/Header';
import getDateRangeRoutingsGoodFor from '../../../shared/lib/get-date-range-routings-good-for';
import NoResult from '../../../shared/components/NoResult/NoResult';
import { dateFormatString } from '../../../src/lib/date-time-format';
import { NAME } from '../redux/constants';
import { shouldLoad as shouldLoadFn } from '../../../src/redux/utils';
import Meta from '../../../shared/components/Meta/container';
import { OnTimePerformance as adUnits } from './ads';
import { resolveCanonicalFromPathname } from '../../App/redux/selectors';
import OtherStops from './children/OtherStops/OtherStops';
import handleFirstLoad from '../../../shared/lib/handle-first-component-load';

@connect(state => ({
  canonical: resolveCanonicalFromPathname(state),
  error: state.OnTimePerformance.error,
  loaded: state.OnTimePerformance.loaded,
  ratings: state.OnTimePerformance.ratings,
  shouldLoad: shouldLoadFn(state, NAME),
  user: state.Account.user,
}))
@componentBase('OnTimePerformance')
export default class OnTimePerformance extends Component {
  static propTypes = {
    canonical: PropTypes.string,
    dispatch: PropTypes.func,
    // error: PropTypes.string,
    loaded: PropTypes.bool,
    match: PropTypes.object,
    name: PropTypes.string,
    ratings: PropTypes.array,
    routeName: PropTypes.string,
    shouldLoad: PropTypes.bool,
    user: PropTypes.object,
  };

  state = {
    firstLoad: true,
  };

  componentWillMount() {
    const { dispatch, match: { params }, ratings, shouldLoad } = this.props;
    if (shouldLoad && !ratings) {
      dispatch(loadOTPByFlight(params));
    }
  }

  componentDidMount() {
    const { dispatch, match: { params } } = this.props;
    if (!this.isInvalid()) {
      dispatch(loadOTPByFlight(params));
    }
  }

  componentWillReceiveProps(nextProps) {
    const newState = this.handleFirstLoad(this.state.firstLoad);
    this.setState(newState);
    const { dispatch, match, name } = this.props;
    const { url } = match;
    const {
      match: nextMatch,
      routeName: nextName,
    } = nextProps;
    const { params, url: nextPathname } = nextMatch;
    url !== nextPathname &&
    nextName === name &&
    dispatch(loadOTPByFlight(params));
  }

  shouldComponentUpdate(nextProps) {
    const { match: { url, params } } = this.props;
    const { match: { url: nextUrl, params: nextParams } } = nextProps;
    const diffy = [this.props, nextProps]
      .map(p => ({ ...p, computedMatch: null, match: null }))
      .map((p, i, arr) => Object.keys(arr[0]).some(key => (arr[0][key] !== arr[1][key])))
      .reduce((a, b) => a && b, true);
    const diffParam = Object.keys(params)
      .filter(key => (!!params[key] || !!nextParams[key]))
      .filter(key => params[key] !== nextParams[key])
      .find(v => v);
    const diffPath = url !== nextUrl;
    return [diffy, diffParam, diffPath].some(v => v);
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(clearOTP());
  }

  getDateRange = (plain) => {
    const now = moment.utc(); // really does not matter what now or nowAgain are
    const nowAgain = now.clone(); // just need them to be moment objects
    const {
      startRange,
      endRange,
    } = getDateRangeRoutingsGoodFor(now, nowAgain);

    const userDateFormat = dateFormatString(this.props.user);
    const func = plain ? this.dateRangeString : this.dateRangeJSX;
    return func(startRange, endRange, userDateFormat);
  };

  reportEvent = () => {
    const [departureAirportFs,
      arrivalAirportFs,
      airlineFs] = ['departureAirport',
      'arrivalAirport',
      'airline']
      .map(obj => pathOr('', ['ratings', 0, obj, 'fs'], this.props));
    this.context.reportEvent('Airport', departureAirportFs, 'Departing', null, true);
    this.context.reportEvent('Airport', arrivalAirportFs, 'Arriving', null, true);
    this.context.reportEvent('Airline', airlineFs, null, null, true);
  }

  handleFirstLoad = handleFirstLoad(this.reportEvent)

  isInvalid = () => [['error'], ['ratings', 0, 'errorCode'], ['ratings', 0]]
    .map((v, i) => (i < 2 ? pathOr(false, v, this.props) : !path(v, this.props)))
    .some(v => v);

  dateRangeString = (startRange, endRange, userPreference) => (
    `${startRange.format(userPreference)} to ${endRange.format(userPreference)}`
  );

  dateRangeJSX = (startRange, endRange, userPreference) => (
    <div className='date-range'>
      <span className='date-title'>Date Range:</span>
      <p className='date-subtitle'>
        {this.dateRangeString(startRange, endRange, userPreference)}
      </p>
    </div>
  );

  formattedDateRange = () => this.getDateRange(false);

  unformattedDateRange = () => this.getDateRange(true);

  secondaryError = () => {
    const params = pathOr({}, ['match', 'params'], this.props);
    const { carrierCode = 'N/A', flightNumber = 'N/A', departureAirport = 'N/A' } = params;
    const dateRange = this.unformattedDateRange();
    return (
      <div>
        Ratings are calculated twice per month and require that the flight has
        flown a minimum number of times in the past two months in order
        to accurately judge performance against other flights.
        <ul>
          <li><em>Carrier:</em> <b>{carrierCode}</b></li>
          <li><em>Flight Number:</em> <b>{flightNumber}</b></li>
          <li><em>Departure Airport:</em> <b>{departureAirport}</b></li>
          <li><em>Date Range:</em> <b>{dateRange}</b></li>
        </ul>
      </div>
    );
  }

  errorText = () => {
    // leaving this here for possible expansion as needed
    const { ratings } = this.props;
    const errorTexts = {
      324: {
        title: 'No Rating Available',
        primary:
          'Sorry, this flight does not have an on-time performance rating',
        secondary: this.secondaryError(),
      },
    };
    const errorCode = ratings && ratings[0] && ratings[0].errorCode;
    return errorTexts[errorCode] || errorTexts[324];
  }

  resolveMetaParams = () => {
    const carrierName = pathOr('', ['ratings', 0, 'airline', 'name'], this.props);
    const [flightNumber, carrierCode] = [
      'flightNumber',
      'carrierCode']
      .map(a => pathOr('', ['match', 'params', a], this.props));
    return {
      carrierFsCode: carrierCode.toUpperCase(),
      carrierName,
      flightNumber,
    };
  }

  formatMetaTitle = ({
    carrierName,
    carrierFsCode,
    flightNumber,
  }) =>
    `${carrierName} ${carrierFsCode} ${flightNumber} On-Time Performance Rating`

  formatMetaDescription = ({ carrierName, carrierFsCode, flightNumber }) => [
    'Check the historical on-time performance rating for flight',
    `${carrierName} ${carrierFsCode} ${flightNumber}`,
    'to help avoid frequently delayed or cancelled flights',
  ].join(' ');

  formatMetaKeywords = ({ carrierName, carrierFsCode, flightNumber }) => [
    `${carrierFsCode} ${flightNumber} on-time performance rating`,
    'flight on-time performance rating',
    `(${carrierFsCode}) ${carrierName} ${flightNumber} On-Time Performance Rating`,
  ].join(',');

  render() {
    const {
      canonical,
      dispatch,
      loaded,
      match,
      name,
      routeName,
      shouldLoad,
      user,
    } = this.props;

    const isSubscribed = user && user.subscriptionActive;
    const rating = loaded &&
    !this.isInvalid() &&
    path(['ratings', 0], this.props);
    const metaParams = this.resolveMetaParams();
    const metaTitle = this.formatMetaTitle(metaParams);
    const {
      carrierFsCode,
      flightNumber,
    } = metaParams;
    const otherStopsBadAirport = loaded && this.isInvalid() &&
    path(['ratings', 0, 'otherStops'], this.props);

    const { top } = adUnits;
    const ad = (routeName === name) && loaded && !shouldLoad && top;
    const { title, primary, secondary } = this.errorText();

    return (
      <div>
        <Meta
          canonical={canonical}
          description={this.formatMetaDescription(metaParams)}
          keywords={this.formatMetaKeywords(metaParams)}
          match={match}
          title={metaTitle}
        />
        <div style={{ margin: '20px 0' }} >
          <Header
            params={{
              ...metaParams,
              carrierCode: carrierFsCode,
            }}
          />
          {rating && this.formattedDateRange()}
        </div>
        {loaded &&
          this.isInvalid() &&
          <NoResult
            sectionTitle={title}
            primaryContent={primary}
            secondaryContent={secondary}
            adUnit={ad}
            subscriptionActive={isSubscribed}
          />
        }
        {loaded &&
          this.isInvalid() &&
          otherStopsBadAirport &&
          otherStopsBadAirport.length > 0 &&
          <OtherStops
            dispatch={dispatch}
            carrierCode={carrierFsCode}
            flightNumber={flightNumber}
            stops={otherStopsBadAirport}
            subscriptionActive={isSubscribed}
          />
        }
        {loaded &&
          rating &&
          <OnTimePerformanceDetail
            dispatch={dispatch}
            rating={rating}
            subscribed={isSubscribed}
            carrierCode={carrierFsCode}
            flightNumber={flightNumber}
          />
        }
      </div>
    );
  }
}
