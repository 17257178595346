import { pathOr } from 'ramda';
import moment from 'moment';
import { parse } from 'qs';

import * as t from './actionTypes';
import {
  validateAirportFSCode,
  validateCarrierFSCode,
} from '../../../src/utils/validation';

const defaultHour = 6; // for loading by departure/arriving airport carrier and date
const backupDateParams = (date, k) => (date[k]() + (k === 'month' ? 1 : 0));

const extractParams = (params) => {
  const { queryString, carrier } = params;
  const parsedQueryString = parse(queryString, { ignoreQueryPrefix: true });
  const { hour } = parsedQueryString;
  const today = moment.utc().hour(12);
  const [year, month, day] = ['year', 'month', 'date'].map(k =>
    pathOr(backupDateParams(today, k), [k], parsedQueryString));
  const hourParam = hour || defaultHour;
  const carrierQuery = (carrier && `&carrier=${carrier}`) || '';
  return {
    ...params,
    year,
    month,
    day,
    hourParam,
    carrierQuery,
  };
};

const setLoaded = bool => ({
  type: t.UPDATE_LOADED,
  bool,
});

const asyncHelper = async (type, url, dispatch, client) => {
  dispatch({ type });
  try {
    const result = await client.get(url);
    dispatch({
      type: t.LOAD_SUCCESS,
      result,
    });
    dispatch(setLoaded(false));
  } catch (e) {
    dispatch({
      type: t.LOAD_FAIL,
      error: e,
    });
  }
};

export const setValidationError = bool => ({
  type: t.SET_VALIDATION_ERROR,
  bool,
});

export const loadByDepartureAirportCarrierAndDate = (params) => {
  const {
    departureAirportCode,
    year,
    month,
    day,
    hourParam,
    carrierQuery,
  } = extractParams(params);
  const url = [
    `/flights/dep/${departureAirportCode}/`,
    `${year}/${month}/${day}/${hourParam}`,
    `?numHours=6${carrierQuery}`,
  ].join('');
  return (dispatch, getStore, client) => {
    if (
      !validateAirportFSCode(departureAirportCode)
    ) {
      return dispatch(setValidationError(true));
    }
    const { carrier } = parse(carrierQuery, { ignoreQueryPrefix: true });
    if (carrier && !validateCarrierFSCode(carrier)) {
      return dispatch(setValidationError(true));
    }
    return asyncHelper(t.LOAD_BY_DEPARTURE_AIRPORT_CARRIER_AND_DATE, url, dispatch, client);
  };
};

export const loadByArrivingAirportCarrierAndDate = (params) => {
  const {
    arrivalAirportCode,
    year,
    month,
    day,
    hourParam,
    carrierQuery,
  } = extractParams(params);
  const url = [
    `/flights/arr/${arrivalAirportCode}/`,
    `${year}/${month}/${day}/${hourParam}`,
    `?numHours=6${carrierQuery}`,
  ].join('');

  return (dispatch, getStore, client) => {
    if (
      !validateAirportFSCode(arrivalAirportCode)
    ) {
      return dispatch(setValidationError(true));
    }
    const { carrier } = parse(carrierQuery, { ignoreQueryPrefix: true });
    if (carrier && !validateCarrierFSCode(carrier)) {
      return dispatch(setValidationError(true));
    }
    return asyncHelper(t.LOAD_BY_ARRIVAL_AIRPORT_CARRIER_AND_DATE, url, dispatch, client);
  };
};

export const loadByRouteCarrierAndDate = (params) => {
  const {
    arrivalAirportCode,
    departureAirportCode,
    year,
    month,
    day,
    hourParam,
    carrierQuery,
  } = extractParams(params);
  const url = [
    `/flights/route/${departureAirportCode}/${arrivalAirportCode}`,
    `/${year}/${month}/${day}`,
    `?numHours=6&hourOfDay=${hourParam}${carrierQuery}`,
  ].join('');

  return (dispatch, getStore, client) => {
    if (
      !validateAirportFSCode(arrivalAirportCode)
      || !validateAirportFSCode(departureAirportCode)
    ) {
      return dispatch(setValidationError(true));
    }
    const { carrier } = parse(carrierQuery, { ignoreQueryPrefix: true });
    if (carrier && !validateCarrierFSCode(carrier)) {
      return dispatch(setValidationError(true));
    }
    return asyncHelper(t.LOAD_BY_ROUTE_CARRIER_AND_DATE, url, dispatch, client);
  };
};

export const updatePageIndex = (pageIndex, searchUrl) => ({
  type: t.UPDATE_PAGE_INDEX,
  data: {
    pageIndex,
    searchUrl,
  },
});

export const updateMultiFlightSearchCodeShareToggle = toggle => ({
  type: t.UPDATE_MULTI_FLIGHT_SEARCH_CODESHARE_TOGGLE,
  toggle,
});

export const updateMultiFlightSearchDate = (date, triggeredByEnter) => ({
  type: t.UPDATE_MULTI_FLIGHT_SEARCH_DATE,
  data: {
    date,
    triggeredByEnter,
  },
});

export const updateMultiFlightSearchTime = (time, triggeredByEnter) => ({
  type: t.UPDATE_MULTI_FLIGHT_SEARCH_TIME,
  data: {
    time,
    triggeredByEnter,
  },
});

export const multiFlightMobileSearchSearching = bool => ({
  type: t.MULTI_FLIGHT_MOBILE_SEARCH_SEARCHING,
  bool,
});

export const updateMultiFlightSearchHasMounted = bool => ({
  type: t.MULTI_FLIGHT_SEARCH_HAS_MOUNTED,
  bool,
});

export const setMultiFlightResultsSearchError = error => ({
  type: t.MULTI_FLIGHT_SEARCH_RESULT_ERROR,
  error,
});

export const setMultiFlightResultsSearchAirlineInput = value => ({
  type: t.SET_MULTI_FLIGHT_SEARCH_AIRLINE_VALUE,
  value,
});

export const setMultiFlightFlights = flights => ({
  type: t.SET_MULTI_FLIGHT_FLIGHTS,
  flights,
});
