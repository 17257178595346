import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import { dateFormatSelect } from '../../../../../src/lib/date-time-format';
import componentBase from '../../../../../src/lib/component-base';
import TimeToDOM from '../../../../../src/components/TimeToDOM/TimeToDOM';

@componentBase('AirportDetailsHeader')
export default class AirportDetailsHeader extends React.Component {
  static propTypes = {
    airportCode: PropTypes.string,
    airportName: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    airportTZRegionName: PropTypes.string,
    title: PropTypes.string,
  };

  constructor(props, context) {
    super(props, context);

    this.state = { airportDateTime: null };
    this.utcText = ' --';
    this.muiDefaultProps = {
      airportNameAddressBlock: {
        padding: '10px 0 10px 20px',
        airportName: {
          fontSize: '20pt',
          fontWeight: 'bold',
          lineHeight: '25px',
        },
        airportAddress: {
          paddingTop: '5px',
          fontSize: '12pt',
        },
      },
      dateRightBlock: {
        padding: '10px 20px 10px 0',
        date: {
          fontSize: '18pt',
          lineHeight: '25px',
          textAlign: 'right',
        },
      },
      dateBlock: {
        padding: '0 20px 10px 20px',
        date: {
          fontSize: '18pt',
          lineHeight: '25px',
        },
      },
      contentBorder: {
        borderTop: '5px solid rgb(250, 167, 24)',
      },
      timeBlock: {
        padding: '10px 20px',
      },
    };
  }

  componentDidMount() {
    const { airportTZRegionName } = this.props;

    this.airportDateTime(airportTZRegionName);
  }

  componentWillReceiveProps(nextProps) {
    const { airportTZRegionName } = nextProps;

    this.airportDateTime(airportTZRegionName);
  }

  getAirportCode() {
    if (this.props.airportCode) {
      return this.props.airportCode;
    }

    return '--';
  }

  getAirportName() {
    if (this.props.airportName) {
      return this.props.airportName.replace('International', 'Intl.');
    }

    return '--';
  }

  getAddressLine1() {
    if (this.props.addressLine1) {
      return this.props.addressLine1;
    }

    return '--';
  }

  getAddressLine2() {
    if (this.props.addressLine2) {
      return this.props.addressLine2;
    }

    return '--';
  }

  getAddress() {
    if (this.props.addressLine2) {
      let result = (this.props.addressLine1) ? `${this.props.addressLine1}, ` : '';
      result += this.props.addressLine2;

      return result;
    }

    return '--';
  }

  getAirportDate() {
    if (this.state.airportDateTime) {
      const formatString = dateFormatSelect(this.context.currentUser, 'DD-MMM-YYYY', 'MMM-DD-YYYY');
      return this.state.airportDateTime.format(formatString);
    }

    return '--';
  }

  getAirportTime() {
    if (this.state.airportDateTime) {
      const time = this.state.airportDateTime.format('h:mmA');
      return (
        <TimeToDOM user={this.context.currentUser} time={time} isUtc={false} componentName='airport-details-header' />
      );
    }

    return '--';
  }

  getAirportTimeUTC() {
    if (this.state.airportDateTime) {
      this.utcText = ' UTC';
      return (
        <TimeToDOM user={this.context.currentUser} time={this.state.airportDateTime} isUtc componentName='airport-details-header' />
      );
    }

    return '--';
  }

  getAirportTZ() {
    if (this.state.airportDateTime) {
      return (
        <span className='time-zone' style={this.muiDefaultProps.timeBlock.ampm}>{this.state.airportDateTime.zoneAbbr()}</span>
      );
    }

    return '--';
  }

  airportDateTime(airportTZRegionName) {
    if (airportTZRegionName) {
      const airportDateTime = moment().tz(airportTZRegionName);

      this.setState({ airportDateTime });
    }
  }

  render() {
    // this.logger('render : props', this.props);

    return (
      <div className='row'>
        <div className='col-md-12 airport-details-header'>
          <div className='row'>
            <div className='col-sm-8 col-md-8 col-lg-8'>
              <div className='row' style={this.muiDefaultProps.airportNameAddressBlock}>
                <h1 className='col-md-12' style={this.muiDefaultProps.airportNameAddressBlock.airportName}>
                  {this.props.title}
                </h1>
                <div className='col-md-12' style={this.muiDefaultProps.airportNameAddressBlock.airportAddress}>
                  { this.getAddress() }
                </div>
              </div>
            </div>
            <div className='col-sm-4 col-md-4 col-lg-4'>
              <div className='row visible-xs' style={this.muiDefaultProps.dateBlock}>
                <div className='col-md-12' style={this.muiDefaultProps.dateBlock.date}>
                  { this.getAirportDate() }
                </div>
              </div>
              <div className='row hidden-xs' style={this.muiDefaultProps.dateRightBlock}>
                <div className='col-md-12' style={this.muiDefaultProps.dateRightBlock.date}>
                  { this.getAirportDate() }
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12' style={this.muiDefaultProps.contentBorder} />
          </div>
          <div className='row' style={this.muiDefaultProps.timeBlock}>
            <div className='col-md-12' style={this.muiDefaultProps.timeBlock.text}>
              At this airport, it is currently
            </div>
            <div className='col-md-12 airport-details-header-times'>
              <span>
                { this.getAirportTime() }
                <span className='airport-details-header-time-ampm'>&nbsp;{ this.getAirportTZ() }</span>
              </span>
              &nbsp;&nbsp;
              <span className='airport-details-header-utc'>{ this.getAirportTimeUTC() }{ this.utcText }</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
