import React from 'react';
import PropTypes from 'prop-types';
import componentBase from '../../../src/lib/component-base';
import FormLabel, { errorRed } from '../FormLabel/FormLabel';

const divBaseStyle = {
  backgroundColor: '#fff',
  borderRadius: '4px',
  borderColor: '#ccc',
  borderWidth: '2px',
  borderStyle: 'solid',
  cursor: 'default',
  display: 'table',
  borderSpacing: '0',
  borderCollapse: 'separate',
  height: '34px',
  outline: 'none',
  overflow: 'hidden',
  position: 'relative',
  width: '100%',
};

const divErrorStyle = {
  borderColor: errorRed,
};

const inputBaseStyle = {
  height: divBaseStyle.height,
  border: 'none',
  paddingLeft: '9px',
  width: 'calc(100% - 9px)',
  margin: 0,
};

@componentBase('TextInput')
export default class TextInput extends React.Component {
  static propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    name: PropTypes.string,
    id: PropTypes.string,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.string,
    type: PropTypes.oneOf(['text', 'tel', 'password', 'email', 'number', 'url']),
    disabled: PropTypes.bool,
    onInputKeyUp: PropTypes.func,
    onInputBlur: PropTypes.func,
    onInputClick: PropTypes.func,
    onInputChange: PropTypes.func,
    inputStyle: PropTypes.object,
    divStyle: PropTypes.object,
    labelStyle: PropTypes.object,
    spanStyle: PropTypes.object,
    inputClassName: PropTypes.string,
    inputDivClassName: PropTypes.string,
    spanErrorStyle: PropTypes.object,
    labelErrorStyle: PropTypes.object,
    divErrorStyle: PropTypes.object,
    errorMessage: PropTypes.string,
    inputRef: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  static defaultProps = {
    type: 'text',
    disabled: false,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      error: null,
    };

    this.onInputKeyUp = this.onInputKeyUp.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onInputClick = this.onInputClick.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errorMessage) {
      this.setState({
        divErrorStyle: Object.assign({}, divErrorStyle, nextProps.divErrorStyle),
      });
    } else {
      this.setState({
        divErrorStyle: null,
      });
    }
  }

  onInputChange(e) {
    if (this.props.onInputChange) {
      this.props.onInputChange(e.target.value);
    }
  }

  onInputBlur(e) {
    if (this.props.onInputBlur) {
      this.props.onInputBlur(e.target.value);
    }
  }


  onInputKeyUp(e) {
    if (this.props.onInputKeyUp) {
      this.props.onInputKeyUp(e.target.value);
    }
  }

  onInputClick(e) {
    if (this.props.onInputClick) {
      this.props.onInputClick(e.target.value);
    }
  }

  handleInputRef(ref) {
    if (this.props.inputRef) {
      this.props.inputRef(ref);
    }
  }

  divStyle() {
    return {
      ...divBaseStyle,
      ...this.props.divStyle,
      ...this.state.divErrorStyle,
    };
  }

  inputStyle() {
    return {
      ...inputBaseStyle,
      ...{ color: this.props.disabled ? '#ccc' : 'black' },
      ...this.props.inputStyle,
    };
  }

  render() {
    return (
      <div>
        <FormLabel
          text={this.props.label}
          name={this.props.name}
          labelStyle={this.props.labelStyle}
          spanStyle={this.props.spanStyle}
          spanErrorStyle={this.props.spanErrorStyle}
          labelErrorStyle={this.props.labelErrorStyle}
          errorMessage={this.props.errorMessage}
        />
        <div
          style={this.divStyle()}
          className={this.props.inputDivClassName}
        >
          <input
            className={this.props.inputClassName}
            defaultValue={this.props.defaultValue}
            value={this.props.value}
            name={this.props.name}
            id={this.props.id}
            placeholder={this.props.placeholder}
            type={this.props.type}
            style={this.inputStyle()}
            disabled={this.props.disabled}
            onKeyUp={this.onInputKeyUp}
            onBlur={this.onInputBlur}
            onChange={this.onInputChange}
            onClick={this.onInputClick}
            ref={ref => this.handleInputRef(ref)}
          />
        </div>
      </div>
    );
  }
}
