import React from 'react';
import PropTypes from 'prop-types';
import componentBase from '../../lib/component-base';

const DEFAULT_SIZE = '30px';

@componentBase('Icon')
class Icon extends React.Component {
  static propTypes = {
    size: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    src: PropTypes.string,
  };

  render() {
    const styles = {
      verticalAlign: 'middle',
      width: (this.props.size ? this.props.size : this.props.width) || DEFAULT_SIZE,
      height: (this.props.size ? this.props.size : this.props.height) || DEFAULT_SIZE,
    };

    const className = this.props.className || 'icon';
    const mergedProps = { alt: 'Icon', ...this.props };
    mergedProps.style = { ...styles, ...this.props.style };

    return <img src={this.props.src} className={className} {...mergedProps} />;
  }
}

export default Icon;
