import React from 'react';
import PropTypes from 'prop-types';
import componentBase from '../../../../src/lib/component-base';

// Soon to be deleted
const accountDefaultBackground = '//assets.flightstats.com/v2/beta-login-2880.jpg';

// Updated Backgrounds
const arrivalBoardBackground = '//assets.flightstats.com/v2/backgrounds/ArrivalBoard.jpg';
const blueRadarBackground = '//assets.flightstats.com/v2/backgrounds/BlueRadar.jpg';
const clockBackground = '//assets.flightstats.com/v2/backgrounds/Clock.jpg';
const cloudsBackground = '//assets.flightstats.com/v2/backgrounds/Clouds.jpg';
const gaguesBackground = '//assets.flightstats.com/v2/backgrounds/Gauges.jpg';
const landingBackground = '//assets.flightstats.com/v2/backgrounds/Landing.jpg';
const sadBaggageClaim = '//assets.flightstats.com/v2/backgrounds/SadBaggageClaim.jpg';
const takeoffBackground = '//assets.flightstats.com/v2/backgrounds/Takeoff.jpg';
const terminalBackground = '//assets.flightstats.com/v2/backgrounds/Terminal.jpg';
const windsockBackground = '//assets.flightstats.com/v2/backgrounds/Windsock.jpg';

@componentBase('PageBackground')
class PageBackground extends React.Component {
  static propTypes = {
    componentName: PropTypes.string,
  };

  constructor(props, context) {
    super(props, context);

    this.muiDefaultProps = {
      imageStyle: {
        position: 'fixed',
        marginTop: 'auto',
        marginLeft: 'auto',
        minWidth: '100%',
        minHeight: '100%',
        width: 'auto',
        height: 'auto',
        zIndex: -100,
        backgroundSize: 'cover',
      },
    };
    this.img = null;
  }

  render() {
    let backgroundImage = null;
    const { componentName } = this.props;
    const { imageStyle } = this.muiDefaultProps;

    if (componentName) {
      switch (componentName) {
        case 'AccountProfile':
        case 'PaymentInformation':
          backgroundImage = gaguesBackground;
          break;
        case 'BlockedAccount':
          // case 'FourOhFour':
          //   backgroundImage = sadBaggageClaim;
          break;
        case 'AirportDelayMap':
        case 'MultiFlight':
          backgroundImage = arrivalBoardBackground;
          break;
        case 'AirportCurrentConditions':
        case 'Homepage':
        case 'FlightTracker':
        case 'FourOhFour':
          backgroundImage = cloudsBackground;
          break;
        case 'AirportSearch':
          backgroundImage = windsockBackground;
          break;
        case 'DeparturesArrivalsLanding':
          backgroundImage = terminalBackground;
          break;
        case 'HistoricalSearch':
          backgroundImage = landingBackground;
          break;
        case 'UnifiedSearch':
        case 'AdvancedSearch':
          backgroundImage = takeoffBackground;
          break;
        case 'OnTimePerformance':
          backgroundImage = clockBackground;
          break;
        case 'VerifyEmail':
          backgroundImage = accountDefaultBackground;
          break;
        case 'TermsAndConditions':
          backgroundImage = blueRadarBackground;
          break;
        default:
          // default to radar image
          backgroundImage = blueRadarBackground;
          break;
      }
    }

    const image = (
      <img
        ref={ref => (this.img = ref)}
        alt='background'
        key={`img-${backgroundImage}`}
        style={imageStyle}
        src={backgroundImage}
        id='fullscreen-background'
      />
    );

    return (
      <div className='media-holder'>
        {image}
      </div>
    );
  }
}

export default PageBackground;
