require('babel-polyfill');

const environment = {
  development: {
    isProduction: false,
  },
  production: {
    isProduction: true,
  },
}[process.env.NODE_ENV || 'development'];

const configOptions = Object.assign({
  host: process.env.HOST || 'localhost',
  port: process.env.PORT || 3000,
  apiHost: process.env.APIHOST || process.env.HOST || 'localhost',
  apiPort: process.env.APIPORT || process.env.PORT || 3000,
  app: {
    title: 'FlightStats',
    description: 'FlightStats flight tracker and status',
    head: {
      titleTemplate: 'FlightStats %s',
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: 'FlightStats' },
        { name: 'apple-itunes-app', content: 'app-id: 572700574' },
        { name: 'google-play-app', content: 'app-id: com.conducivetech.android.traveler' },
        { name: 'twitter:site', content: '@flightstats' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { property: 'og:site_name', content: 'FlightStats' },
        { property: 'og:locale', content: 'en_US' },
        { property: 'og:type', content: 'website' },
        { property: 'og:title', content: 'FlightStats' },
        { property: 'og:description', content: 'FlightStats' },
        { property: 'og:card', content: 'summary' },
        { property: 'og:image', content: '//assets.flightstats.com/common/fs-meta-social.jpg' },
        { property: 'og:image:width', content: '1200' },
        { property: 'og:image:height', content: '630' },
        { property: 'fb:admins', content: '6305094086' },
      ],
    },
  },

}, environment);

export default configOptions;
