import React from 'react';
import PropTypes from 'prop-types';
import componentBase from '../../../src/lib/component-base';
import GenericModal from '../GenericModal/GenericModal';
import ShoppingCartIcon from '../../../static/images/subscription-icons/ShoppingCart-Icon.svg';
import { catchErrors } from '../../../modules/Account/redux/selectors';
import { createBraintreeCreditsTransaction, startSpinner, endSpinner } from '../../../modules/Account/redux/actions';

const mapOptionsToDollars = {
  'option-one': { USD: 10, credits: 100 },
  'option-two': { USD: 100, credits: 1000 },
  'option-three': { USD: 500, credits: 5000 },
  'option-four': { USD: 1000, credits: 10000 },
};

@componentBase('BuyCreditsGenericModal')
export default class BuyCreditsGenericModal extends GenericModal {
  static propTypes = {
    modalOpen: PropTypes.bool,
    closeModal: PropTypes.func,
    isHistoricalCredits: PropTypes.bool,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      selectedOption: 'option-one',
      buttonsDisabled: false,
    };
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleOptionSelect = this.handleOptionSelect.bind(this);
  }

  handleModalClose = (e) => {
    if (e && e.preventDefault) {
      // onRequestClose does not pass event with preventDefault
      e.preventDefault();
    }
    this.setState({ selectedOption: 'option-one', buttonsDisabled: false }, () => {
      this.props.closeModal();
    });
  }

  handleOptionChange = (e, value = null) => {
    const selectedOption = value || e.target.value;
    this.setState({
      selectedOption,
    });
  }

  handleOptionSelect = () => {
    const option = mapOptionsToDollars[this.state.selectedOption];
    this.setState({ buttonsDisabled: true });
    const { currentUser } = this.context;
    const { id, subscriptionBraintreeId, additionalInfo } = currentUser;
    const creditsAmount = option.credits;
    const purchaseAmount = option.USD;
    const dispatch = this.context.store.dispatch;
    const productType = this.props.isHistoricalCredits ? 'historical credits' : 'flight alerts';
    const analyticsType = this.props.isHistoricalCredits ? 'Historical' : 'FlightAlert';
    const purchase = {
      affiliation: `${analyticsType}Credits`,
      name: `${analyticsType}Credits-${creditsAmount}`,
    };

    const body = {
      userId: id,
      braintreeId: subscriptionBraintreeId,
      productName: `${creditsAmount}, ${productType}`,
      amount: purchaseAmount,
      billingInformation: additionalInfo,
    };

    dispatch(startSpinner());
    return dispatch(createBraintreeCreditsTransaction(body))
      .then((action) => {
        const { result } = action;
        const sale = result && result.sale;
        purchase.id = sale.braintreeId;
        purchase.revenue = sale.amount;
        this.context.reportPurchase(purchase);
        this.context.reportUserEvent('CreditPurchase', `${analyticsType}Credits`, creditsAmount);
        this.context.toast.success(`Thank you for purchasing ${creditsAmount} ${this.props.isHistoricalCredits ? 'Historical' : 'Flight Alert'} credits`, 'Success');
        this.setState({ buttonsDisabled: false }, () => {
          this.props.closeModal(true); // Fetch profile on close
        });
      })
      .catch((err) => {
        this.setState({
          buttonsDisabled: false,
        }, () => {
          this.props.closeModal(true); // Fetch profile on close
          dispatch(endSpinner());
          // console.error(catchErrors(err), `buy${analyticsType}Credits`);
          this.context.toast.error(catchErrors(err), 'Error', false, 3);
        });
      });
  }

  render() {
    const titleType = this.props.isHistoricalCredits ? 'Historical Look Up' : 'Flight Alert';
    const body = (
      <div className='buy-more-credits-modal'>
        <div className='buy-more-credits options'>
          <div className='select'>
            <div className='option-one'>
              <label htmlFor='radio-one'>
                <input
                  type='radio'
                  id='radio-one'
                  value='option-one'
                  checked={this.state.selectedOption === 'option-one'}
                  onChange={this.handleOptionChange}
                />
                <span className='circle'>
                  <span className='circle'>
                    <span className={`circle${this.state.selectedOption === 'option-one' ? ' selected' : ''}`} />
                  </span>
                </span>
              </label>
              <span
                className='details'
                onClick={e => this.handleOptionChange(e, 'option-one')}
              >100 Credits for $10
              </span>
            </div>

            <div className='option-two'>
              <label htmlFor='radio-two'>
                <input
                  type='radio'
                  id='radio-two'
                  value='option-two'
                  checked={this.state.selectedOption === 'option-two'}
                  onChange={this.handleOptionChange}
                />
                <span className='circle'>
                  <span className='circle'>
                    <span className={`circle${this.state.selectedOption === 'option-two' ? ' selected' : ''}`} />
                  </span>
                </span>
              </label>
              <span
                className='details'
                onClick={e => this.handleOptionChange(e, 'option-two')}
              >1,000 Credits for $100
              </span>
            </div>

            <div className='option-three'>
              <label htmlFor='radio-three'>
                <input
                  type='radio'
                  id='radio-three'
                  value='option-three'
                  checked={this.state.selectedOption === 'option-three'}
                  onChange={this.handleOptionChange}
                />
                <span className='circle'>
                  <span className='circle'>
                    <span className={`circle${this.state.selectedOption === 'option-three' ? ' selected' : ''}`} />
                  </span>
                </span>
              </label>
              <span
                className='details'
                onClick={e => this.handleOptionChange(e, 'option-three')}
              >5,000 Credits for $500
              </span>
            </div>

            <div className='option-four'>
              <label htmlFor='radio-four'>
                <input
                  type='radio'
                  id='radio-four'
                  value='option-four'
                  checked={this.state.selectedOption === 'option-four'}
                  onChange={this.handleOptionChange}
                />
                <span className='circle'>
                  <span className='circle'>
                    <span className={`circle${this.state.selectedOption === 'option-four' ? ' selected' : ''}`} />
                  </span>
                </span>
              </label>
              <span
                className='details'
                onClick={e => this.handleOptionChange(e, 'option-four')}
              >10,000 Credits for $1000
              </span>
            </div>
          </div>
        </div>
        <div className='disclaimer'>
          <p>
            All sales are final. There are no refunds for unused credits or accidental purchases.
          </p>
        </div>
      </div>
    );

    return (
      <GenericModal
        modalOpen={this.props.modalOpen}
        closeModal={this.handleModalClose}
        handleOptionSelect={this.handleOptionSelect}
        mainActionTitle='BUY CREDITS NOW'
        body={body}
        headerIcon={ShoppingCartIcon}
        headerString={`Buy More ${titleType} Credits`}
        disabled={this.state.buttonsDisabled}
      />
    );
  }
}
