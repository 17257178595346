import * as t from './actionTypes';

const initialState = {
  parameters: {},
  loading: false,
  loaded: false,
  pageIndex: 1,
};

const errorCode = (error) => {
  if (error && error.status === 500) {
    return 'SEARCH_ISSUES';
  } else if (error && error.status === 429) {
    return 'TOO_MANY_REQUESTS';
  }

  return 'HISTORICAL_FLIGHTS_LOAD_ERROR1';
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case t.START_SPINNER:
      return {
        ...state,
        loading: true,
      };
    case t.END_SPINNER:
      return {
        ...state,
        loading: false,
      };
    case t.LOAD_SUCCESS:
      return {
        ...state,
        loaded: true,
        data: action.result,
      };
    case t.LOAD_FAIL:
      return {
        ...state,
        loaded: false,
        error: Object.assign({}, action.error, { code: errorCode(action.error) }),
      };
    case t.LOAD_BY_ARRIVAL_AIRPORT:
      return {
        ...state,
        loaded: false,
      };
    case t.LOAD_BY_ARRIVAL_AIRPORT_SUCCESS:
      return {
        ...state,
        loaded: true,
        parameters: action.data.parameters,
        data: action.data.result,
      };
    case t.LOAD_BY_ARRIVAL_AIRPORT_FAIL:
      return {
        ...state,
        loaded: false,
        data: Object.assign({}, action.error, { code: errorCode(action.error) }),
      };
    case t.LOAD_BY_DEPARTURE_AIRPORT:
      return {
        ...state,
        loaded: false,
      };
    case t.LOAD_BY_DEPARTURE_AIRPORT_SUCCESS:
      return {
        ...state,
        loaded: true,
        parameters: action.data.parameters,
        data: action.data.result,
      };
    case t.LOAD_BY_DEPARTURE_AIRPORT_FAIL:
      return {
        ...state,
        loaded: false,
        data: Object.assign({}, action.error, { code: errorCode(action.error) }),
      };
    case t.LOAD_BY_ROUTE:
      return {
        ...state,
        loaded: false,
      };
    case t.LOAD_BY_ROUTE_SUCCESS:
      return {
        ...state,
        loaded: true,
        parameters: action.data.parameters,
        data: action.data.result,
      };
    case t.LOAD_BY_ROUTE_FAIL:
      return {
        ...state,
        loaded: false,
        data: Object.assign({}, action.error, { code: errorCode(action.error) }),
      };
    case t.UPDATE_PAGE_INDEX:
      return {
        ...state,
        pageIndex: action.data.pageIndex,
        searchUrl: action.data.searchUrl,
      };
    case t.SET_HISTORICAL_MULTI_ERROR_MESSAGE:
      return {
        ...state,
        historicalMultiErrorMessage: action.message,
      };
    default:
      return state;
  }
};
