import { Route, Redirect, Switch } from 'react-router-dom';
import { identity, inc, ifElse, pathSatisfies, props as extractProps, propOr } from 'ramda';
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

import { SegmentSelect, NewFlightAlert } from '../../FlightAlerts/components/FlightAlertWizard/index';
import { getRegion, redirectToRegion } from '../../AirportDelayMap/components/regions';
import AccountPanel from '../../Account/components/AccountPanel';
import AccountRegistrationContainer from '../../Account/components/AccountRegistration/AccountRegistrationContainer';
import AirportSearch from '../../AirportCurrentConditions/components/AirportSearch/AirportSearch';
import CurrentConditions from '../../AirportCurrentConditions/components/CurrentConditionsContainer';
import DelayMap from '../../AirportDelayMap/components/container';
import DeparturesArrivals from '../../AirportDeparturesArrivals/components/DeparturesArrivalsContainer';
import DeparturesArrivalsLanding from '../../AirportDeparturesArrivals/components/Landing/DeparturesArrivalsLanding';
import ExtendedDetails from '../../FlightTracker/components/ExtendedDetails/container';
import FlightAlertHistory from '../../FlightAlerts/components/FlightAlertsHistory/container';
import FlightAlertHistoryDetails from '../../FlightAlerts/components/FlightAlertsHistory/FlightAlertHistoryDetails/container';
import FlightAlertsIndex from '../../FlightAlerts/components/FlightAlertsIndex/container';
// import FlightTrackerContainer from '../../SingleFlight/container';
import FlightTrackerSearch from '../../FlightTracker/components/Search/container';
import ForgotPassword from '../../Account/components/ForgotPassword';
import FourOhFour from '../../../src/components/lib/FourOhFour';
import BlockedUser from '../../../src/components/lib/BlockedUser';
// import GlobalCancellationsDelays from '../../GlobalCancellationsDelays/components/container';
import HistoricalFlightTracker from '../../HistoricalFlightStatus/components/HistoricalFlightTracker/container';
import HistoricalFlightTrackerSearch from '../../HistoricalFlightStatus/components/HistoricalFlightTrackerSearch';
import HistoricalMultiFlight from '../../HistoricalFlightStatus/components/HistoricalMultiFlight/container';
import Homepage from '../../Homepage/container';
import Login from '../../Account/components/Login';
import MapAttributions from '../../../src/containers/TermsAndCredits/MapAttributions';
import MultiFlight from '../../MultiFlight/container';
import MyAccount from '../../Account/components/MyAccount/MyAccount';
import OnTimePerformance from '../../OnTimePerformance/components/container';
import RouteWrapper from '../../RouteWrapper';
import SiteMap from '../../Sitemap/container';
import Subscribe from '../../Account/components/Subscription/Subscribe';
import SubscriptionMarketing from '../../Account/components/Subscription/Marketing/container';
import SubscriptionSuccess from '../../Account/components/Subscription/Success/container';
import ViewPaymentHistoryDetail from '../../Account/components/ViewPaymentHistoryDetail/ViewPaymentHistoryDetail';
import TermsAndConditions from '../../TermsAndConditions/index';

const OnEnterSubscriptionFeatures = ['requireActiveSubscription', 'requireLogin', 'requireActiveAccount', 'requireVerifiedEmail'];
const formatDayParams = ifElse(
  day => day < 10,
  day => `0${day}`,
  day => day,
);
const dateFormatters = {
  year: identity, // simply return the year
  month: inc, // add one to the resulting month
  date: d => formatDayParams(d), // add '0' to single digit dates
};
const formatDateParams = () => {
  const now = moment();
  const funcs = Object.keys(dateFormatters);
  return funcs
    .map(a => now[a]()) // now.year(), now.month....
    .map((b, index) => dateFormatters[funcs[index]](b));
};

const Status = ({ code, children, name }) => (
  <RouteWrapper
    name={name}
    component={({ staticContext }) => {
      if (staticContext) {
        staticContext.status = code; // eslint-disable-line no-param-reassign
      }
      return children;
    }}
  />
);

Status.propTypes = {
  code: PropTypes.number,
  children: PropTypes.object,
  name: PropTypes.string,
};

export const NotFound = () => (
  <Status code={404} name='FourOhFour'>
    <FourOhFour />
  </Status>
);

export const BlockedAccount = () => (
  <Status code={403} name='BlockedUser'>
    <BlockedUser />
  </Status>
);

const dateKeys = ['year', 'month', 'day'];
const extractDateParams = ifElse(
  def => !!def,
  () => formatDateParams(),
  (def, params) => extractProps(dateKeys, params),
);

const formatUrl = (baseUrl, year, month, day, hour, optionalAdditionalPath, flightId) => {
  const queryParams = [];
  if (year && month && day) {
    queryParams.push(`year=${year}&month=${month}&date=${day}`);
  }
  if (hour) {
    queryParams.push(`hour=${hour}`);
  }
  if (flightId) {
    queryParams.push(`flightId=${flightId}`);
  }
  return `${baseUrl}${optionalAdditionalPath ? `/${optionalAdditionalPath}` : ''}${queryParams.length > 0 ? `?${queryParams.join('&')}` : ''}`;
};

const routeCodes = ['departureAirportCode', 'arrivalAirportCode', 'carrier'];
const extractRouteCodes = extractProps(routeCodes);
const flightTrackerFormatRouteURI = (renderProps) => {
  const { match: { params } } = renderProps;
  const hour = propOr(6, 'hour', params);
  const [year, month, day] = extractDateParams(false, params);
  const [
    departureAirportCode,
    arrivalAirportCode,
    carrier,
  ] = extractRouteCodes(params);

  const baseUrl = `/flight-tracker/route/${departureAirportCode}/${arrivalAirportCode}`;
  return formatUrl(baseUrl, year, month, day, hour, carrier);
};

const flightTrackerDepArrFormatUrl = (airportCode, depArr, params, noDateCarrier) => {
  const hour = propOr(noDateCarrier && 6, 'hour', params);
  const carrier = propOr(noDateCarrier, 'carrier', params);
  const [year, month, day] = extractDateParams(noDateCarrier, params);
  const baseUrl = `/flight-tracker/${depArr}/${airportCode}`;
  return formatUrl(baseUrl, year, month, day, hour, carrier);
};

const sharedFlightTrackerDetailsParse = (baseUrlPath, renderProps, mapOtherRoute) => {
  const { match: { params } } = renderProps;
  const [year, month, day] = extractDateParams(false, params);
  const { carrier, flightNumber, flightId } = params;
  const baseUrl = `/${baseUrlPath}/${carrier}/${flightNumber}`;
  return formatUrl(baseUrl, year, month, day, null, mapOtherRoute, flightId);
};

// const flightTrackerFormatTrackerURI = (renderProps, mapOtherRoute) => sharedFlightTrackerDetailsParse('flight-tracker', renderProps, mapOtherRoute);
const flightDetailsFormatTrackerURI = renderProps => sharedFlightTrackerDetailsParse('flight-details', renderProps);

/*
  add route name and optional 'exact' flag to
  /shared/lib/route-name-for-path/index.js routeNameForPathPatternMap
  for best results
*/
export default () => (
  <Switch>
    <RouteWrapper
      path='/'
      component={Homepage}
      name='Homepage'
      exact
    />
    <RouteWrapper
      path='/flight-tracker/search'
      name='UnifiedSearch'
      exact
      allowSitemap
      component={FlightTrackerSearch}
    />
    <Route
      path='/flight-tracker/search/advanced'
      render={renderProps => (
        <Redirect
          {...renderProps}
          to='/flight-tracker/search'
          state={{ status: 301 }}
        />
      )}
    />
    <Route
      path='/flight-tracker/search'
      component={NotFound}
    />
    <Route
      path='/flight-tracker/departing/:departureAirportCode/:carrier'
      exact
      strict
      render={(renderProps) => {
        const { params } = renderProps.match;
        const { departureAirportCode, carrier } = params;
        return (
          <Redirect
            {...renderProps}
            state={{ status: 301 }}
            to={flightTrackerDepArrFormatUrl(departureAirportCode, 'departures', params, carrier)}
          />
        );
      }}
    />
    <Route
      path='/flight-tracker/departures/:departureAirportCode/:year/:month/:day/:hour?/:carrier?'
      render={(renderProps) => {
        const { params } = renderProps.match;
        const { departureAirportCode } = params;
        return (
          <Redirect
            {...renderProps}
            state={{ status: 301 }}
            to={flightTrackerDepArrFormatUrl(departureAirportCode, 'departures', params)}
          />
        );
      }}
    />
    <RouteWrapper
      path='/flight-tracker/departures/:departureAirportCode/:carrier?'
      canonical='/flight-tracker/departures/:departureAirportCode'
      onEnter={['validateQueryStringTrackerDate']}
      component={MultiFlight}
      name='MultiFlight'
      allowSitemap
    />
    <Route
      path='/flight-tracker/departing/:departureAirportCode/:year?/:month?/:day?/:hour?/:carrier?'
      render={(renderProps) => {
        const { params } = renderProps.match;
        const { departureAirportCode } = params;
        return (
          <Redirect
            {...renderProps}
            state={{ status: 301 }}
            to={flightTrackerDepArrFormatUrl(departureAirportCode, 'departures', params)}
          />
        );
      }}
    />
    <Route
      path='/flight-tracker/departures'
      render={() => <NotFound />}
    />
    <Route
      path='/flight-tracker/arriving/:arrivalAirportCode/:carrier'
      exact
      strict
      render={(renderProps) => {
        const { params } = renderProps.match;
        const { arrivalAirportCode, carrier } = params;
        return (
          <Redirect
            {...renderProps}
            state={{ status: 301 }}
            to={flightTrackerDepArrFormatUrl(arrivalAirportCode, 'arrivals', params, carrier)}
          />
        );
      }}
    />
    <Route
      path='/flight-tracker/arrivals/:arrivalAirportCode/:year/:month/:day/:hour?/:carrier?'
      render={(renderProps) => {
        const { params } = renderProps.match;
        const { arrivalAirportCode } = params;
        return (
          <Redirect
            {...renderProps}
            state={{ status: 301 }}
            to={flightTrackerDepArrFormatUrl(arrivalAirportCode, 'arrivals', params)}
          />
        );
      }}
    />
    <RouteWrapper
      path='/flight-tracker/arrivals/:arrivalAirportCode/:carrier?'
      canonical='/flight-tracker/arrivals/:arrivalAirportCode'
      onEnter={['validateQueryStringTrackerDate']}
      component={MultiFlight}
      name='MultiFlight'
      allowSitemap
    />
    <Route
      path='/flight-tracker/arriving/:arrivalAirportCode/:year?/:month?/:day?/:hour?/:carrier?'
      render={(renderProps) => {
        const { params } = renderProps.match;
        const { arrivalAirportCode } = params;
        return (
          <Redirect
            {...renderProps}
            state={{ status: 301 }}
            to={flightTrackerDepArrFormatUrl(arrivalAirportCode, 'arrivals', params)}
          />
        );
      }}
    />
    <Route
      path='/flight-tracker/arrivals'
      render={() => <NotFound />}
    />
    <Route
      exact
      strict
      path='/flight-tracker/route/:departureAirportCode/:arrivalAirportCode/:year/:month/:day/:hour?/:carrier?'
      render={renderProps => (
        <Redirect
          {...renderProps}
          state={{ status: 301 }}
          to={flightTrackerFormatRouteURI(renderProps)}
        />
      )}
    />
    <RouteWrapper
      path='/flight-tracker/route/:departureAirportCode/:arrivalAirportCode/:carrier?'
      canonical='/flight-tracker/route/:departureAirportCode/:arrivalAirportCode'
      onEnter={['validateQueryStringTrackerDate']}
      component={MultiFlight}
      name='MultiFlight'
      allowSitemap
    />
    <Route
      path='/flight-tracker/route'
      render={() => <NotFound />}
    />
    {/*
      <RouteWrapper
      path='/flight-tracker/:carrier/:flightNumber/map'
      canonical='/flight-tracker/:carrier/:flightNumber/map'
      component={FlightTrackerContainer}
      name='FlightTrackerMap'
      onEnter={['validateQueryStringTrackerDate']}
      map
      />
      <RouteWrapper
      path='/flight-tracker/:carrier/:flightNumber/other-stops'
      canonical='/flight-tracker/:carrier/:flightNumber/other-stops'
      component={FlightTrackerContainer}
      name='FlightTrackerOtherStops'
      onEnter={['validateQueryStringTrackerDate']}
      isOtherStops
      />
      <Route
      path='/flight-tracker/:carrier/:flightNumber/:year/:month/:day/:flightId?/map'
      render={renderProps => (
        <Redirect
      {...renderProps}
      state={{ status: 301 }}
      to={flightTrackerFormatTrackerURI(renderProps, 'map')}
        />
      )}
      />
      <Route
      path='/flight-tracker/:carrier/:flightNumber/:year/:month/:day/:flightId?/other-stops'
      render={renderProps => (
        <Redirect
      {...renderProps}
      state={{ status: 301 }}
      to={flightTrackerFormatTrackerURI(renderProps, 'other-stops')}
        />
      )}
      />
      <Route
      path='/flight-tracker/:carrier/:flightNumber/:year/:month/:day/:flightId?'
      render={renderProps => (
        <Redirect
      {...renderProps}
      state={{ status: 301 }}
      to={flightTrackerFormatTrackerURI(renderProps)}
        />
      )}
      />
      <RouteWrapper
      exact
      path='/flight-tracker/:carrier/:flightNumber'
      canonical='/flight-tracker/:carrier/:flightNumber'
      component={FlightTrackerContainer}
      onEnter={['validateQueryStringTrackerDate']}
      allowSitemap
      name='FlightTracker'
      />
    */}
    <Redirect
      exact
      from='/flight-tracker/'
      to='/flight-tracker/search'
    />
    <RouteWrapper
      path='/airports/departing-arriving/search'
      component={DeparturesArrivalsLanding}
      onEnter={OnEnterSubscriptionFeatures}
      name='DeparturesArrivalsLanding'
      disallowRobots
      exact
    />
    <Route
      path='/airports/departing-arriving/search/'
      render={() => (<NotFound />)}
    />
    <RouteWrapper
      path='/airports/departing-arriving/:airportCode/:departureArrival/:year/:month/:day'
      component={DeparturesArrivals}
      onEnter={OnEnterSubscriptionFeatures}
      name='DeparturesArrivals'
      disallowRobots
    />
    <Route
      path='/airports/departing-arriving/:airportCode/:departureArrival'
      disallowRobots
      render={(renderProps) => {
        const { params } = renderProps.match;
        const [year, month, date] = formatDateParams();
        return (
          <Redirect
            {...renderProps}
            to={`/airports/departing-arriving/${params.airportCode}/${params.departureArrival}/${year}/${month}/${date}`}
          />
        );
      }}
    />
    <RouteWrapper
      path='/airports/departing-arriving/subscribe'
      name='SubscriptionMarketing'
      component={SubscriptionMarketing}
      subscriptionType='DEPARR'
      allowSitemap
      exact
    />
    <Route
      path='/airports/departing-arriving'
      disallowRobots
      exact
      render={() => (
        <Redirect
          to='/airports/departing-arriving/search'
        />
      )}
    />
    <RouteWrapper
      path='/subscription'
      component={SubscriptionMarketing}
      name='SubscriptionMarketing'
      exact
      allowSitemap
    />
    <RouteWrapper
      path='/account/subscribe'
      component={Subscribe}
      name='Subscribe'
      onEnter={['requireSignUp']}
    />
    <RouteWrapper
      path='/account/success'
      name='SubscriptionSuccess'
      component={SubscriptionSuccess}
      onEnter={['requireLogin']}
    />
    <RouteWrapper
      path='/account/invoice/:referenceNumber'
      component={ViewPaymentHistoryDetail}
      name='ViewPaymentHistoryDetail'
      onEnter={['requireLogin']}
    />
    <RouteWrapper
      // Don't name this route
      path='/my-account'
      component={MyAccount}
      onEnter={['requireLogin', 'requireVerifiedEmail']}
      disallowRobots
    />
    <RouteWrapper
      path='/account/login'
      name='Login'
      component={Login}
    />
    <RouteWrapper
      path='/account/forgot-password'
      name='ForgotPassword'
      component={ForgotPassword}
      disallowRobots
    />
    <RouteWrapper
      // Don't name this route
      path='/account'
      component={AccountPanel}
      disallowRobots
      // this is the last /account route for a reason
      // catchall redirect contained in AccountPanel
    />
    <Route
      path='/register'
      key='registration'
      component={AccountRegistrationContainer}
      disallowRobots
    />
    <RouteWrapper
      path='/map-attributions'
      component={MapAttributions}
      name='TermsAndCreditsMapAttributions'
      allowSitemap
    />
    <RouteWrapper
      path='/airport-conditions/search'
      exact
      component={AirportSearch}
      name='AirportSearch'
      allowSitemap
    />
    <Route
      path='/airport-conditions/search/'
      render={() => (<NotFound />)}
    />
    <RouteWrapper
      path='/airport-conditions/:airportCode'
      component={CurrentConditions}
      name='AirportCurrentConditions'
      allowSitemap
    />
    <Route
      exact
      path='/airport-conditions'
      render={() => (
        <Redirect
          to='/airport-conditions/search'
        />
      )}
    />
    <RouteWrapper
      path='/flight-alerts/subscribe'
      name='FlightAlertsSubscriptionMarketing'
      component={SubscriptionMarketing}
      subscriptionType='ALERTS'
      allowSitemap
      exact
    />
    <RouteWrapper
      path='/historical-flight/subscribe'
      name='HistoricalSubscriptionMarketing'
      component={SubscriptionMarketing}
      subscriptionType='HISTORICAL'
      allowSitemap
      exact
    />
    <RouteWrapper
      exact
      path='/historical-flight/search'
      name='HistoricalSearch'
      onEnter={OnEnterSubscriptionFeatures}
      component={HistoricalFlightTrackerSearch}
    />
    <RouteWrapper
      exact
      path='/historical-flight/departing/:departureAirportCode/:carrier?'
      name='HistoricalMultiFlight'
      onEnter={OnEnterSubscriptionFeatures}
      component={HistoricalMultiFlight}
    />
    <RouteWrapper
      path='/historical-flight/departing/:departureAirportCode/:year?/:month?/:day?/:carrier?'
      component={HistoricalMultiFlight}
      name='HistoricalMultiFlight'
      onEnter={OnEnterSubscriptionFeatures}
    />
    <RouteWrapper
      exact
      path='/historical-flight/arriving/:arrivalAirportCode/:carrier?'
      name='HistoricalMultiFlight'
      onEnter={OnEnterSubscriptionFeatures}
      component={HistoricalMultiFlight}
    />
    <RouteWrapper
      path='/historical-flight/arriving/:arrivalAirportCode/:year?/:month?/:day?/:carrier?'
      component={HistoricalMultiFlight}
      name='HistoricalMultiFlight'
      onEnter={OnEnterSubscriptionFeatures}
    />
    <RouteWrapper
      path='/historical-flight/route/:departureAirportCode/:arrivalAirportCode/:year?/:month?/:day?/:carrier?'
      component={HistoricalMultiFlight}
      name='HistoricalMultiFlight'
      onEnter={OnEnterSubscriptionFeatures}
    />
    <RouteWrapper
      path='/historical-flight/:carrier/:flightNumber/:year/:month/:day/:flightId?'
      name='HistoricalFlightTracker'
      component={HistoricalFlightTracker}
      onEnter={OnEnterSubscriptionFeatures}
    />
    <Redirect
      from='/historical-flight'
      to='/historical-flight/search'
    />
    <RouteWrapper
      path='/airports/departing-arriving/subscribe'
      name='DepArrSubscriptionMarketing'
      component={SubscriptionMarketing}
      subscriptionType='DEPARR'
      allowSitemap
      exact
    />
    {/* This route MUST be above 'flight-details/:carrier/:flightNumber' -@ma */}
    <Route
      path='/flight-details/:carrier/:flightNumber/:year/:month/:day/:flightId?'
      render={renderProps => (
        <Redirect
          {...renderProps}
          state={{ status: 301 }}
          to={flightDetailsFormatTrackerURI(renderProps)}
        />
      )}
    />
    <RouteWrapper
      onEnter={['validateQueryStringTrackerDate']}
      path='/flight-details/:carrier/:flightNumber'
      canonical='/flight-details/:carrier/:flightNumber'
      component={ExtendedDetails}
      name='ExtendedDetails'
      allowSitemap
    />
    <Route
      path='/flight-alerts'
      exact
      disallowRobots
      render={() => (
        <Redirect
          push={false}
          to='/flight-alerts/home'
        />
      )}
    />
    <RouteWrapper
      path='/flight-alerts/home'
      onEnter={OnEnterSubscriptionFeatures}
      component={FlightAlertsIndex}
      name='FlightAlerts'
      disallowRobots
    />
    <RouteWrapper
      path='/flight-alerts/new/:carrier/:flightNumber/:year/:month/:day/:departureAirport/:arrivalAirport/:trackerReferral?'
      onEnter={OnEnterSubscriptionFeatures}
      component={NewFlightAlert}
      name='NewFlightAlert'
      disallowRobots
    />
    <RouteWrapper
      path='/flight-alerts/new/:carrier/:flightNumber/:year/:month/:day'
      onEnter={OnEnterSubscriptionFeatures}
      component={SegmentSelect}
      name='FlightAlertsSegmentSelect'
      disallowRobots
      exact
    />
    <Redirect
      path='/flight-alerts/history'
      to='/flight-alerts/history/home'
      push={false}
      disallowRobots
      exact
    />
    <RouteWrapper
      path='/flight-alerts/history/home'
      onEnter={OnEnterSubscriptionFeatures}
      component={FlightAlertHistory}
      name='FlightAlertsHistory'
      disallowRobots
    />
    <RouteWrapper
      path='/flight-alerts/history/details/:wmaId'
      onEnter={OnEnterSubscriptionFeatures}
      component={FlightAlertHistoryDetails}
      name='FlightAlertsHistoryDetails'
      disallowRobots
    />
    <RouteWrapper
      path='/airport-delays'
      exact
      component={DelayMap}
      name='AirportDelayMap'
      allowSitemap
    />
    <Route
      path='/airport-delays/:region'
      name='AirportDelayMap'
      render={(props) => {
        // If route contains supported region like northAmerica
        const supportedRegion = pathSatisfies(p => getRegion(p || ''), ['match', 'params', 'region'], props);
        if (supportedRegion) {
          return (
            <RouteWrapper
              path='/airport-delays/:region?'
              component={DelayMap}
              name='AirportDelayMap'
              allowSitemap
            />
          );
        }

        // Possibly an old pf route like usFAA, should 301 to northAmerica
        const newRegion = pathSatisfies(p => redirectToRegion(p || ''), ['match', 'params', 'region'], props);
        if (newRegion) {
          return (
            <Redirect
              {...props}
              state={{ status: 301 }}
              to={`/airport-delays/${newRegion}`}
            />);
        }

        // else, 404
        return <NotFound />;
      }}
    />
    <RouteWrapper
      path='/flight-ontime-performance-rating/:carrierCode/:flightNumber/:departureAirport?/:arrivalAirport?'
      canonical='/flight-ontime-performance-rating/:carrierCode/:flightNumber'
      component={OnTimePerformance}
      name='OnTimePerformance'
      allowSitemap
    />
      <RouteWrapper
      path='/company/legal/flightstats-subscription-agreement-terms-and-conditions'
      exact
      name='TermsAndConditions'
      component={TermsAndConditions}
      allowSitemap
    />
    <Redirect
      exact
      from='/global-cancellations-and-delays'
      to='/'
    />
    {/* <RouteWrapper
      path='/global-cancellations-and-delays'
      exact
      component={GlobalCancellationsDelays}
      name='GlobalCancellationsDelays'
      allowSitemap
    /> */}
    <RouteWrapper
      path='/sitemap'
      exact
      name='SiteMap'
      component={SiteMap}
    />
    <Route
      path='/blocked'
      exact
      name='BlockedAccount'
      render={() => (<BlockedAccount />)}
    />
    <NotFound />
  </Switch>
);
