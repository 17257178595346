import { pathOr } from 'ramda';
import { resolveCanonical } from '../../../shared/lib/resolve-pathname-to-canonical';
import { NAME } from './constants';

export const resolveCanonicalFromPathname = (state) => {
  const { router = {}, [NAME]: App = {} } = state;
  const pathname = pathOr('', ['location', 'pathname'], router);
  const { routeConfig } = App;
  return resolveCanonical(routeConfig, pathname);
};
