import React from 'react';
import PropTypes from 'prop-types';
import Pagination from 'react-js-pagination';
import componentBase from '../../../src/lib/component-base';
import ResponsiveTable from '..//ResponsiveTable/ResponsiveTable';

@componentBase('PaginatedTable')
export default class PaginatedTable extends React.Component {
  static propTypes = {
    handlePageClick: PropTypes.func,
    header: PropTypes.array,
    rows: PropTypes.array,
    match: PropTypes.object,
    maxItemsPerPage: PropTypes.number,
    pageIndex: PropTypes.number,
    searchUrl: PropTypes.string,
    variant: PropTypes.string,
  };

  static defaultProps = {
    header: [],
    rows: [],
    maxItemsPerPage: 25,
  };

  componentWillMount() {
    const {
      match,
      pageIndex,
      searchUrl,
    } = this.props;

    if (!(searchUrl && pageIndex && match && match.url === searchUrl)) {
      // reset pagination to first page
      this.handlePageClick(1, '');
    }
  }

  get rowCount() {
    return this.props.rows.length;
  }

  get pageCount() {
    return Math.ceil(this.rowCount / this.props.maxItemsPerPage);
  }

  page = () => {
    this.pages = [];
    if (this.rowCount <= this.props.maxItemsPerPage) {
      this.pages = [this.props.rows];
    } else {
      for (let i = 0; i < this.pageCount; i++) {
        const rangeStart = i * this.props.maxItemsPerPage;

        const rangeEnd = (i === (this.pageCount - 1)) ? this.rowCount :
          ((i + 1) * this.props.maxItemsPerPage);

        this.pages[i] = this.props.rows.slice(rangeStart, rangeEnd);
      }
    }

    return this.pages[this.props.pageIndex - 1]; // props.pageIndex is 1 based, not 0
  }

  handlePageClick = (pageNumber) => {
    const {
      handlePageClick,
      match,
    } = this.props;
    handlePageClick(pageNumber, match.url);
  };

  render() {
    const { rows, variant } = this.props;
    if (rows) {
      const moreThanOnePage = this.pageCount > 1;
      const max3PageCount = Math.min(this.pageCount, 3);

      const paginator = () => (
        moreThanOnePage ? (
          <div className='paginatorContainer'>
            <Pagination
              activePage={this.props.pageIndex}
              itemsCountPerPage={this.props.maxItemsPerPage}
              totalItemsCount={rows.length}
              pageRangeDisplayed={max3PageCount}
              onChange={this.handlePageClick}
              activeClass='paginatorSelectedIndex'
              nextPageText='→'
              prevPageText='←'
            />
          </div>
        ) : null
      );

      return (
        <div className='multi-rows-list container'>
          <div className={`${moreThanOnePage ? 'paginatorContainerTop' : ''}`}>
            {paginator('Top')}
          </div>
          <ResponsiveTable header={this.props.header} rows={this.page()} variant={variant} />
          <div className={`${moreThanOnePage ? 'paginatorContainerBottom' : ''}`}>
            {paginator('Bottom')}
          </div>
        </div>
      );
    }

    return (<div />);
  }
}
