import * as t from './actionTypes';
import { generateMonthlySummaries, filterBySearchKeywords, findIndexOfCurrentMonth } from './transformer';
import { errorText } from '../../../../shared/lib/reducer-util';

const initialState = {
  loading: true,
  loaded: false,
  earliestAlert: '--',
  latestAlert: '--',
  costForTimePeriod: '--',
  keywordFilterResults: null,
  waitForInitialProcessing: true,
  data: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case t.LOAD:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case t.LOAD_HISTORICAL:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case t.LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: errorText(action.error),
      };
    case t.LOAD_HISTORICAL_SUCCESS:
      const noHistoricalResults = !!((action && action.result && action.result.length === 0));
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: action.result,
        currentFilteredData: action.result,
        noHistoricalResults,
      };
    case t.CHECK_HISTORICAL:
      return {
        ...state,
        error: null,
        loading: false,
      };
    case t.CHECK_HISTORICAL_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
      };
    case t.CHECK_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: errorText(action.error),
      };
    case t.LOAD_HISTORICAL_BY_ID_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        detail: action.result,
      };
    case t.SET_FILTER:
      const { startFilterDate, endFilterDate, costForTimePeriod } = action;
      return {
        ...state,
        startFilterDate,
        endFilterDate,
        earliestAlert: startFilterDate,
        latestAlert: endFilterDate,
        costForTimePeriod,
        keywordFilterResults: null,
      };
    case t.SET_FILTER_FROM_MONTH_SELECT:
      return {
        ...state,
        startFilterDate: action.startFilterDate,
        endFilterDate: action.endFilterDate,
        earliestAlert: action.startFilterDate,
        latestAlert: action.endFilterDate,
        costForTimePeriod: action.costForTimePeriod,
        currentBrushInxEnd: action.currentBrushInxEnd,
        currentBrushInxStart: action.currentBrushInxStart,
        keywordFilterResults: null,
      };
    case t.UPDATE_CURRENT_FILTER:
      return {
        ...state,
        earliestAlert: action.currentFilterStart,
        latestAlert: action.currentFilterEnd,
      };
    case t.FILTER_BY_DAY:
      return {
        ...state,
        filterBySearchDate: action.filterBySearchDate,
      };
    case t.FILTER_BY_MONTH:
      return {
        ...state,
        filterBySearchMonth: action.filterBySearchMonth,
      };
    case t.GENERATE_MONTHLY_SUMMARIES:
      const summaries = generateMonthlySummaries(action.data, action.mediaBreakpoint);
      const brushInxStart = findIndexOfCurrentMonth(summaries.receiptCounts);
      const brushInxEnd = brushInxStart;
      const beginFilteringMonth = summaries.receiptCounts[brushInxStart];
      const endFilteringMonth = summaries.receiptCounts[brushInxStart];
      const indexes = {
        currentBrushInxStart: brushInxStart,
        currentBrushInxEnd: brushInxEnd,
      };
      const startAndEndMonths = {
        // start and end FilterDate tell the selector to narrow down results
        startFilterDate: beginFilteringMonth.oneScheduledDepartureThisMonth,
        endFilterDate: endFilteringMonth.oneScheduledDepartureThisMonth,
        // currentFilteStart and End tell summary to re-render based on these dates (two from same month -> only only render activity summary from that month)
        earliestAlert: beginFilteringMonth.oneScheduledDepartureThisMonth,
        latestAlert: beginFilteringMonth.oneScheduledDepartureThisMonth,
      };
      const hasProcessed = {
        waitForInitialProcessing: false,
      };

      return {
        ...state,
        ...summaries,
        ...indexes,
        ...startAndEndMonths,
        ...hasProcessed,
      };
    case t.UPDATE_COST:
      return {
        ...state,
        costForTimePeriod: action.cost,
      };
    case t.SET_COLNAME_FILTER:
      return {
        ...state,
        searchColumnName: action.searchColumnName[0],
        searchColumnASCorDESC: action.searchColumnName[1],
        currentData: action.searchColumnName[2],
        keywordFilterResults: null,
      };
    case t.SET_KEYWORD_FILTER:
      const searchKeywords = action.searchKeywords[0];
      const currentData = action.searchKeywords[1];
      const keywordFilterResults = filterBySearchKeywords(searchKeywords, currentData);
      return {
        ...state,
        currentData,
        keywordFilterResults,
      };
    // /  these cases are for staging || development only
    case t.LOAD_NEW_ALERT:
      return {
        ...state,
        newTestAlertError: null,
        newTestAlertLoading: true,
        newTestAlertLoaded: false,
      };
    case t.LOAD_NEW_ALERT_SUCCESS:
      return {
        ...state,
        newTestAlertError: null,
        newTestAlertLoading: false,
        newTestAlertLoaded: true,
        data: action.result,
      };
    case t.LOAD_NEW_ALERT_FAIL:
      return {
        ...state,
        newTestAlertLoaded: false,
        newTestAlertLoading: false,
        newTestAlertError: errorText(action.error),
      };
    case t.LOAD_TEST_FLIGHT_SCHEDULE:
      return {
        ...state,
        testFlightScheduleError: null,
        testFlightScheduleLoading: true,
        testFlightScheduleLoaded: false,
      };
    case t.LOAD_TEST_FLIGHT_SCHEDULE_SUCCESS:
      return {
        ...state,
        testFlightScheduleError: null,
        testFlightScheduleLoading: false,
        testFlightScheduleLoaded: true,
        testFlightScheduleData: action.result,
      };
    case t.LOAD_TEST_FLIGHT_SCHEDULE_FAIL:
      return {
        ...state,
        testFlightScheduleLoaded: false,
        testFlightScheduleLoading: false,
        testFlightScheduleError: errorText(action.error),
      };
    case t.REHYDRATE_PROPS:
      const props = action.props;
      const { currentFilterStart, currentFilterEnd } = props;
      props.waitForInitialProcessing = false;
      props.earliestAlert = currentFilterStart;
      props.latestAlert = currentFilterEnd;
      return {
        ...state,
        ...props,
        rehydrated: true,
      };
    default:
      return state;
  }
};
