import moment from 'moment';
import { BLUEPARTOFGRAPH, REDPARTOFGRAPH, YELLOWPARTOFGRAPH } from './constants';
import { _getAlertWithLargestScheduledDeparture, _getAlertWithSmallestScheduledDeparture, _getNumberAlertsSent, _getNumberOfIssues } from '../../lib/helpers';
/* eslint-disable no-useless-escape */
const removeSymbols = str => str.replace(/[\[\]\.,\/#!$%\^&\*;:{}=\-_`~()]/g, ' ');
/* eslint-enable no-useless-escape */

export const constructWordMap = (datum) => {
  if (datum) {
    return datum.map((alert) => {
      // adding alertDescription to word map
      const {
        alertDescription,
        flightNumber,
        carrierCode,
        scheduledDeparture,
        departureAirportFsCode,
        arrivalAirportFsCode,
      } = alert;

      const alertDescriptionNoPunctuation = removeSymbols(alertDescription);
      let wordsToAddToWordMap = alertDescriptionNoPunctuation.split(' ');

      // adding other fields from the alert object ot search through
      wordsToAddToWordMap = wordsToAddToWordMap
        .concat(flightNumber)
        .concat(carrierCode)
        .concat(departureAirportFsCode)
        .concat(arrivalAirportFsCode)
        .concat(`${carrierCode}${flightNumber}`);

      // dealing with dates that are typed in
      const momentizedScheduledDeparture = moment.utc(scheduledDeparture);
      const MMMM = momentizedScheduledDeparture.format('MMMM');
      const MMM = momentizedScheduledDeparture.format('MMM');
      const DD = momentizedScheduledDeparture.format('DD');
      const ddd = momentizedScheduledDeparture.format('ddd');
      const dddd = momentizedScheduledDeparture.format('dddd');
      const YYYY = momentizedScheduledDeparture.format('YYYY');
      const Hmm = momentizedScheduledDeparture.format('H:mm');
      const hmm = momentizedScheduledDeparture.format('h:mm');
      const A = momentizedScheduledDeparture.format('A');

      wordsToAddToWordMap = wordsToAddToWordMap
        .concat(MMMM)
        .concat(MMM)
        .concat(DD)
        .concat(ddd)
        .concat(dddd)
        .concat(YYYY)
        .concat(Hmm)
        .concat(hmm)
        .concat(A);

      // need to go through flightAlertEvenents eventually
      // flightAlertEvents.forEach((event) => {
      //   const strNoSymbols = removeSymbols(event);
      //   const words = strNoSymbols.split(' ');
      //   wordsToAddToWordMap = wordsToAddToWordMap.concat(words);
      // });

      const wordMap = wordsToAddToWordMap.reduce((prev, current) => {
        const lowerCased = current.toLowerCase();
        prev[lowerCased] = lowerCased;
        return prev;
      }, {});

      return Object.assign({}, alert, { wordMap });
    });
  }
  return datum;
};

export const groupAlertsIntoBuckets = (alerts = [], monthlySummaries) => {
  const mSummaries = {
    ...monthlySummaries,
  };
  const newBuckets = [];

  const { numberOfMonths } = mSummaries;
  for (let i = 0; i < numberOfMonths; i++) {
    newBuckets.push([]);
  }

  if (alerts && alerts.length > 0) {
    alerts.forEach((alert) => {
      const momentizedScheduledDeparture = alert && moment.utc(alert.scheduledDeparture);
      const differenceFromEarliestAlert = momentizedScheduledDeparture && momentizedScheduledDeparture.diff(monthlySummaries.earliestAlert, 'months');
      const bucket = newBuckets[differenceFromEarliestAlert];
      bucket && bucket.push(alert);
    });
  }

  mSummaries.buckets = newBuckets;
  return mSummaries;
};

export const reduceMonthlyAlertsIntoSummaryObjects = (monthlySummaries) => {
  const newReceiptCounts = [];
  const mSummaries = {
    ...monthlySummaries,
  };

  mSummaries.buckets.forEach((month, i) => {
    const clonedBaseMonthSummary = Object.assign(
      {}, mSummaries.baseMonthSummary,
      {
        oneScheduledDepartureThisMonth: mSummaries.monthMapper[i][0],
        name: mSummaries.monthMapper[i][1],
        alerts: 0,
      },
    );

    const newMonth = month.reduce((prev, next) => {
      // need one arrival date from this 'bucket' so we know what month to search for
      prev.oneScheduledDepartureThisMonth = next && next.scheduledDeparture;

      return prev;
    }, clonedBaseMonthSummary);

    const newMonthAlertsSent = month && _getNumberAlertsSent(month);
    const newMonthIssuesIdentified = month && _getNumberOfIssues(month);

    newMonth.alertsSent = newMonthAlertsSent;
    newMonth.issuesIdentified = newMonthIssuesIdentified;

    newReceiptCounts.push(newMonth);
  });

  mSummaries.receiptCounts = newReceiptCounts;
  return mSummaries;
};

export const generateMonthlySummaries = (data) => {
  const baseMonthSummary = {};
  baseMonthSummary[BLUEPARTOFGRAPH] = 0;
  baseMonthSummary[YELLOWPARTOFGRAPH] = 0;
  baseMonthSummary[REDPARTOFGRAPH] = 0;

  const monthlySummaries = {
    buckets: [],
    baseMonthSummary,
    receiptCounts: [],
  };

  const mostRecentAlert = _getAlertWithLargestScheduledDeparture(data);
  const earliestAlert = _getAlertWithSmallestScheduledDeparture(data);
  const momentizedMostRecentAlert = mostRecentAlert && moment(mostRecentAlert.scheduledDeparture);

  const momentizedEarliestAlert = earliestAlert && moment(moment(earliestAlert.scheduledDeparture).format('MMMM-01-YYYY'), 'MMMM-DD-YYYY');
  monthlySummaries.earliestAlert = momentizedEarliestAlert;

  /*
    adding one makes sure that there is one 'bucket' rendered
    for each scheduledDeparture that is in the set of alerts
    let difference = momentizedMostRecentAlert &&
    momentizedMostRecentAlert.diff(this.state.earliestAlert, 'months') + 1;
  */
  let difference = momentizedMostRecentAlert && momentizedMostRecentAlert.diff(monthlySummaries.earliestAlert, 'months') + 1;
  monthlySummaries.numberOfMonths = difference;

  const monthMapper = {};
  monthlySummaries.monthMapper = monthMapper;

  if (!data.length) { // no data from initial database call
    monthlySummaries.earliestAlert = moment();
  }

  /*
    for now, we are rendering twelve months
    (current month is the right most bar chart on the graph)
    commented out portion would restore current month generating
    in the middle of the stacked bar chart (might want it back later?)
  */

  // const minimumNumberOfBarsToRender = mediaBreakpoint === 'desktopLg' ? 30 : 24;
  const minimumNumberOfBarsToRender = 12;
  const currentDate = moment.utc(moment.utc().format('MMMM-01-YYYY'), 'MMMM-DD-YYYY');

  // if (difference <= minimumNumberOfBarsToRender) {
  //   //if less than a year, generate enough buckets for a year
  //   monthlySummaries.numberOfMonths = minimumNumberOfBarsToRender;
  //   const minimumNumberOfBarsToRenderLessDiff = minimumNumberOfBarsToRender - difference;
  //   let monthsBefore = minimumNumberOfBarsToRenderLessDiff / 2;
  //   let monthsAfter = minimumNumberOfBarsToRenderLessDiff / 2;
  //   monthsBefore = monthsBefore % 2 === 0 ? monthsBefore : Math.ceil(monthsBefore);
  //   monthsAfter = monthsAfter % 2 === 0 ? monthsAfter : Math.floor(monthsAfter);
  //   difference = minimumNumberOfBarsToRender;

  //   const newEarliestAlert = moment(
  //     monthlySummaries.earliestAlert
  //   ).subtract(monthsBefore, 'months');
  //   monthlySummaries.earliestAlert = newEarliestAlert;
  // }

  // if (true) {
  monthlySummaries.numberOfMonths = minimumNumberOfBarsToRender;
  // const minimumNumberOfBarsToRenderLessDiff = minimumNumberOfBarsToRender - difference;
  // let monthsBefore = minimumNumberOfBarsToRenderLessDiff / 2;
  // let monthsAfter = minimumNumberOfBarsToRenderLessDiff / 2;
  // monthsBefore = monthsBefore % 2 === 0 ? monthsBefore : Math.ceil(monthsBefore);
  // monthsAfter = monthsAfter % 2 === 0 ? monthsAfter : Math.floor(monthsAfter);
  difference = minimumNumberOfBarsToRender;

  // const newEarliestAlert = moment(
  //   monthlySummaries.earliestAlert
  //   ).subtract(monthsBefore, 'months');
  const newEarliestAlert = currentDate.subtract(difference - 1, 'months');
  monthlySummaries.earliestAlert = newEarliestAlert;
  // }

  for (let i = 0; i < difference; i++) {
    const clone = moment.utc(monthlySummaries.earliestAlert.format('MMMM-YYYY'), 'MMMM-YYYY');// //need a clone because .add mutates the moment object
    const monthMapperValue = [];
    const newMonth = clone.add(i, 'months');
    monthlySummaries.latestAlert = newMonth;
    monthMapperValue[0] = newMonth.format();
    // first letter of each month will become x-axis ticks
    const firstLetterOfNewMonth = newMonth.format('MMMM')[0].toUpperCase();
    monthMapperValue[1] = firstLetterOfNewMonth;
    monthMapper[i] = monthMapperValue;
  }

  for (let i = 0; i < difference; i++) {
    monthlySummaries.buckets.push([]);
  }

  const monthlySummariesPassOne = groupAlertsIntoBuckets(data, monthlySummaries);
  const monthlySummariesPassTwo = reduceMonthlyAlertsIntoSummaryObjects(monthlySummariesPassOne);
  return monthlySummariesPassTwo;
};

export const filterBySearchKeywords = (searchKeywords, currentAlerts) => {
  /*
    if search results are also empty,
    the data that shows up should be the result of
    the current filters that are in place on the bar chart
    short circuit :^) in case no terms submitted
  */
  if (!searchKeywords.length || !searchKeywords[0].length) {
    return currentAlerts;
  }

  const maxScore = searchKeywords.length;
  const result = [];
  // setting up buckets to throw alerts into
  for (let i = 0; i < maxScore; i++) {
    result.push([]);
  }
  /* eslint-disable no-param-reassign */
  currentAlerts.forEach((alert) => {
    const score = searchKeywords.reduce((prev, current) => {
      const searchTerm = current.toLowerCase();

      if (alert.wordMap && alert.wordMap[searchTerm]) {
        return ++prev;
      }
      return prev;
    }, 0);

    // not gonna render results where the search terms don't show up and there are other results
    if (score === 0) return;
    const index = maxScore - score;
    const bucket = result[index];
    bucket && bucket.push(alert);
  });
  /* eslint-enable no-param-reassign */

  let IWantNoResultsFlag = false;
  const finalResult = result.reduce((prev, current, i) => {
    if (i === 0 && !current.length) {
      IWantNoResultsFlag = true;
      return [];
    }
    if (prev.length) return prev;
    return prev.concat(current);
  }, []);

  return IWantNoResultsFlag ? [] : finalResult;
};

export const findIndexOfCurrentMonth = (receiptCounts) => {
  const now = moment.utc();
  const currentMonthIndex = receiptCounts.reduce((prev, current, index) => {
    if (now.isSame(moment.utc(current.oneScheduledDepartureThisMonth), 'month')) {
      return index;
    }
    return prev;
  }, -1);
  return currentMonthIndex;
};
