import {
  isValidPostalCode,
  isValuePresent,
  MAX_POSTAL_CODE_FIELD_LENGTH,
} from '../../../shared/lib/validators';
import { isValidCarrier } from '../../../src/helpers/Validators';
import carrierOptions from '../../../shared/lib/carrierOptions';

const UNKNOWN_ERROR = 'An unknown error occured';

export const isLoaded = globalState => globalState &&
                                         globalState.Account &&
                                         globalState.Account.loaded;

export const getUser = globalState => globalState &&
                                        globalState.Account &&
                                        globalState.Account.user;

export const subscriptionSuspended = (globalState) => {
  const user = getUser(globalState);
  return user &&
        user.subscriptionStatus &&
        user.subscriptionStatus.startsWith('cancelled: ');
};

export const userSubscriptionSuspended = (user) => {
  return user &&
        user.subscriptionStatus &&
        user.subscriptionStatus.startsWith('cancelled: ');
};

export const catchErrors = (error) => {
  if (!error) {
    return null;
  }
  if (error.status) {
    if (parseInt(error.status, 10) > 399) {
      return `Temporarily unable to connect to our payment partners -
              please try again in a moment`;
    }
  }
  if (error.message) {
    return error.message;
  }
  return UNKNOWN_ERROR;
};

export const userSubscriptionLevel = user => user && user.subscriptionLevel;

export const userSubscriptionActive = user =>
  user && user.userSubscriptionActive;

export const formFieldsValid = (componentState) => {
  const newState = { ...componentState };
  const { additionalInfo } = newState;
  const errorsPresent = [{
    stateKey: 'name',
    errorMessage: 'Invalid name',
    validator: isValuePresent,
  }, {
    stateKey: 'streetAddress',
    errorMessage: 'Invalid street address',
    validator: isValuePresent,
  }, {
    stateKey: 'city',
    errorMessage: 'Invalid city',
    validator: isValuePresent,
  }, {
    stateKey: 'countryCode',
    errorMessage: 'Invalid country code',
    validator: isValuePresent,
  }, {
    stateKey: 'postalCode',
    errorMessage: `Enter a postal code less than ${MAX_POSTAL_CODE_FIELD_LENGTH} characters`,
    validator: isValidPostalCode,
  }].reduce((prev, curr) => {
    // postalCode and state are optional
    const { stateKey, errorMessage, validator } = curr;
    const value = additionalInfo[stateKey];

    if ((validator && !validator(value))) {
      newState[`${stateKey}Error`] = errorMessage;
      return true;
    }

    newState[`${stateKey}Error`] = null;
    return prev;
  }, false);
  if (errorsPresent) {
    newState.errorsPresent = true;
  }
  return newState;
};

export const handleSoftError = result =>
  (result && result.softError ? null : result && result.data);

export const responseError = (action) => {
  let err = null;

  if (action.error) {
    if (action.error.response && action.error.response.body) {
      err = action.error.response.body.errors ||
            action.error.response.body.error;
    }

    err = err || action.error.message;
  }

  return err;
};

export const combinedErrors = (action) => {
  if (action.validationErrors) {
    return action.validationErrors;
  }

  return responseError(action);
};

export const registrationValidationError = (action) => {
  const { error, registrationValidationError: validationError } = action;
  if (validationError) return validationError;
  const apiError = error && error.response;
  const parsedApiError = apiError && JSON.parse(apiError.text);
  return (
    parsedApiError && parsedApiError.error && parsedApiError.error[0]
  ) ||
  (apiError && apiError.error && apiError.error.message);
};

export const validateCarrierAndPhoneState = (options) => {
  const {
    flightMonitorWirelessNumber0,
    flightMonitorWirelessServiceCode0,
  } = options;

  if ((flightMonitorWirelessNumber0 && !flightMonitorWirelessServiceCode0) ||
      (!flightMonitorWirelessNumber0 && flightMonitorWirelessServiceCode0)) {
    return false;
  } else if (flightMonitorWirelessNumber0 &&
              flightMonitorWirelessServiceCode0) {
    if (!isValidCarrier(flightMonitorWirelessServiceCode0, carrierOptions)) {
      return false;
    }
  }
  return true;
};
