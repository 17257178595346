import React from 'react';
import PropTypes from 'prop-types';
import { Colors } from '../../../../../../src/constants/style-constants';
import { levelIcons, icons } from '../../../../lib/constants';
import ChangePaymentInfo from './ChangePaymentInfo';
import CreditCounter from './CreditCounter/CreditCounter';
import SubscriptionStatus from './SubscriptionStatus/SubscriptionStatus';
import FSFlatButton from '../../../../../../src/components/lib/FSFlatButton';
import IconHeader from '../../../../../../shared/components/IconHeader/IconHeader';
import UpdateBillingAddress from './UpdateBillingAddress/UpdateBillingAddress';
import TextInput from '../../../../../../shared/components/TextInput/TextInput';
import BuyCreditsGenericModal from '../../../../../../shared/components/BuyCreditsGenericModal/BuyCreditsGenericModal';
import UpgradeSubscriptionModal from '../../../../../App/components/children/Modals/UpgradeSubscriptionModal';
import LargeGenericModal from '../../../../../../shared/components/LargeGenericModal/LargeGenericModal';
import UnsubscribeModalBody from './UnsubscribeModalBody';

import { isBusinessUser } from '../../../../../../src/utils/isBusinessUser';

const SubscribedUserPaymentInfo = (props) => {
  const {
    additionalInfo,
    braintreeClientToken,
    braintreeSubscriptionError,
    buyFlightAlertCreditsModalOpen,
    buyHistoricalCreditsModalOpen,
    changePaymentInformationDialogOpen,
    cityError,
    countryCodeError,
    credits,
    endSpinner,
    flightsMonitored,
    getProfile,
    handleCountryChange,
    handleInput,
    handleUnsubscribeAccount,
    handleModalToggle,
    handleUpdateBillingAddress,
    isSubscriptionActive,
    isValid,
    nameError,
    onPaymentFormSubmitted,
    paymentDetailsLastFour,
    postalCodeError,
    reasonForUnsubscribing,
    renderErrorMessage,
    renewalDate,
    routerPush,
    setComponentRef,
    startSpinner,
    stateError,
    streetAddressError,
    subscriptionLevelText,
    successfullyUpdatedCCInfo,
    togglePaymentInformationDialog,
    unsubscribeButtonsDisabled,
    unsubscribeModalOpen,
    updateBillingButtonLabel,
    updateCardButtonsDisabled,
    upgradeSubscription,
    upgradeSubscriptionModalOpen,
    updateReasonForUnsubscribing,
    user,
  } = props;

  const { subscriptionLevel } = user;

  const {
    flightAlertsMonthlyCredits,
    flightAlertsReservedCredits,
    historicalMonthlyCredits,
    historicalReservedCredits,
    totalHistoricalCreditsUsed,
  } = credits;

  const {
    city, countryCode, name, postalCode, streetAddress, state,
  } = additionalInfo;
  const subscriptionLevelIcon = subscriptionLevel ?
    levelIcons[subscriptionLevel] : levelIcons[0];
  const canUpgrade = subscriptionLevel < 3;
  const {
    BillingAddressIcon,
    CreditCardIcon,
    creditTokensIcon,
    dangerIcon,
    subscriptionIcon,
  } = icons;

  return (
    <div className='billing'>
      <div className='row grey-bg payment-information'>
        <SubscriptionStatus
          defaultSubscriptionIcon={subscriptionIcon}
          customSubscriptionIcon={subscriptionLevelIcon}
          handleConfirmUnsubscribe={() => handleModalToggle('unsubscribeModalOpen', true)}
          isSubscriptionActive={isSubscriptionActive}
          handleConfirmUpgrade={() => handleModalToggle('upgradeSubscriptionModalOpen', true)}
          canUpgrade={canUpgrade}
          renewalDate={renewalDate}
          subscriptionLevelText={subscriptionLevelText}
          user={user}
        />
        <UpgradeSubscriptionModal
          closeModal={() => handleModalToggle('upgradeSubscriptionModalOpen', false)}
          endSpinner={endSpinner}
          getProfileReduxAction={getProfile}
          modalOpen={upgradeSubscriptionModalOpen}
          upgradeReduxAction={upgradeSubscription}
          routerPush={routerPush}
          startSpinner={startSpinner}
        />
        <LargeGenericModal
          modalOpen={unsubscribeModalOpen}
          closeModal={() => handleModalToggle('unsubscribeModalOpen', false)}
          title='Unsubscribe'
          image={dangerIcon}
          actionTitle='Yes, Unsubscribe Me'
          disableButtons={unsubscribeButtonsDisabled}
          selectAction={handleUnsubscribeAccount}
          body={
            (
              <UnsubscribeModalBody
                renewalDate={renewalDate}
                reasonForUnsubscribing={reasonForUnsubscribing}
                updateReasonForUnsubscribing={updateReasonForUnsubscribing}
              />
            )
          }
          secondaryActionTitle='No, I Want To Keep It'
          secondaryActionStyle={{ color: 'white' }}
          secondaryActionBackgroundColor={Colors.blue6}
          secondaryActionHoverColor={Colors.blue8}
        />
        <div className='col-sm-12 col-md-6'>
          <div className='subscription-level-title row'>
            <IconHeader
              icon={creditTokensIcon}
              title='Credits'
            />
            <CreditCounter
              title='Flight Alert Credits'
              credits={{
                used: flightsMonitored || 0,
                remaining: flightAlertsMonthlyCredits,
                reserved: flightAlertsReservedCredits,
              }}
              handleClick={() => handleModalToggle('buyFlightAlertCreditsModalOpen', true)}
            />
            {subscriptionLevel >= 3 &&
              <div style={{ marginTop: '17px' }}>
                <CreditCounter
                  title='Historical Look Up Credits'
                  credits={{
                    used: totalHistoricalCreditsUsed,
                    remaining: historicalMonthlyCredits,
                    reserved: historicalReservedCredits,
                  }}
                  handleClick={() => handleModalToggle('buyHistoricalCreditsModalOpen', true)}
                />
              </div>}
          </div>
        </div>
      </div>
      <BuyCreditsGenericModal
        modalOpen={buyFlightAlertCreditsModalOpen}
        closeModal={bool => handleModalToggle('buyFlightAlertCreditsModalOpen', false, bool)}
      />
      <BuyCreditsGenericModal
        modalOpen={buyHistoricalCreditsModalOpen}
        closeModal={bool => handleModalToggle('buyHistoricalCreditsModalOpen', false, bool)}
        isHistoricalCredits
      />
      {!isBusinessUser(user) && <div>
        <div className='spacer' />
        <div className='row'>
          <div className='col-xs-12 col-md-6'>
            <div className='grey-bg payment-information'>
              <IconHeader
                icon={CreditCardIcon}
                title='Your Payment Information'
              />
              <div className='row'>
                <div className='col-xs-7 number-on-card'>
                  <TextInput
                    labelStyle={{ fontWeight: 600 }}
                    onInputChange={() => handleInput('yourPaymentInformationCC')}
                    disabled
                    onInputClick={() => togglePaymentInformationDialog(true)}
                    placeholder={paymentDetailsLastFour}
                    label='Number on Card'
                  />
                </div>
                <div className='payment-information-button'>
                  <FSFlatButton
                    label='CHANGE PAYMENT INFORMATION'
                    className='button'
                    onClick={() => togglePaymentInformationDialog(true)}
                  />
                  <LargeGenericModal
                    modalOpen={changePaymentInformationDialogOpen}
                    closeModal={() => togglePaymentInformationDialog(false)}
                    title='Change Payment Information'
                    image={CreditCardIcon}
                    actionTitle='SAVE CREDIT CARD'
                    selectAction={onPaymentFormSubmitted}
                    disablePrimaryButton={updateCardButtonsDisabled}
                    secondaryActionBackgroundColor='transparent'
                    secondaryActionHoverColor='#c6c6c6'
                    body={
                      <ChangePaymentInfo
                        braintreeClientToken={braintreeClientToken}
                        braintreeClientTokenError={braintreeSubscriptionError}
                        togglePaymentInformationDialog={() => togglePaymentInformationDialog(false)}
                        onPaymentFormSubmitted={onPaymentFormSubmitted}
                        ref={ref => setComponentRef('updateCreditCardForm', ref)}
                        successfulUpdate={successfullyUpdatedCCInfo}
                      />
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='col-xs-12 col-md-6'>
            <IconHeader
              icon={BillingAddressIcon}
              title='Your Billing Address'
            />
            <UpdateBillingAddress
              handleInput={handleInput}
              name={name}
              nameError={nameError}
              isValid={() => isValid}
              streetAddress={streetAddress}
              streetAddressError={streetAddressError}
              state={state}
              stateError={stateError}
              billingAddressButtonLabel={updateBillingButtonLabel}
              handleCountryChange={handleCountryChange}
              handleUpdateBillingAddress={handleUpdateBillingAddress}
              city={city}
              cityError={cityError}
              postalCode={postalCode}
              postalCodeError={postalCodeError}
              countryCode={countryCode}
              countryCodeError={countryCodeError}
              renderErrorMessage={() => renderErrorMessage}
            />
          </div>
        </div>
      </div>}
    </div>
  );
};

SubscribedUserPaymentInfo.propTypes = {
  additionalInfo: PropTypes.object,
  braintreeClientToken: PropTypes.string,
  braintreeSubscriptionError: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  buyFlightAlertCreditsModalOpen: PropTypes.bool,
  buyHistoricalCreditsModalOpen: PropTypes.bool,
  changePaymentInformationDialogOpen: PropTypes.bool,
  cityError: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  countryCodeError: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  credits: PropTypes.object,
  endSpinner: PropTypes.func,
  flightsMonitored: PropTypes.number,
  getProfile: PropTypes.func,
  handleCountryChange: PropTypes.func,
  handleInput: PropTypes.func,
  handleUnsubscribeAccount: PropTypes.func,
  handleModalToggle: PropTypes.func,
  handleUpdateBillingAddress: PropTypes.func,
  isSubscriptionActive: PropTypes.bool,
  isValid: PropTypes.func,
  nameError: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onPaymentFormSubmitted: PropTypes.func,
  paymentDetailsLastFour: PropTypes.string,
  postalCodeError: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  reasonForUnsubscribing: PropTypes.string,
  renderErrorMessage: PropTypes.func,
  renewalDate: PropTypes.string,
  routerPush: PropTypes.func,
  setComponentRef: PropTypes.func,
  startSpinner: PropTypes.func,
  stateError: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  streetAddressError: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subscriptionLevelText: PropTypes.string,
  successfullyUpdatedCCInfo: PropTypes.bool,
  togglePaymentInformationDialog: PropTypes.func,
  unsubscribeButtonsDisabled: PropTypes.bool,
  unsubscribeModalOpen: PropTypes.bool,
  updateBillingButtonLabel: PropTypes.string,
  updateCardButtonsDisabled: PropTypes.bool,
  updateReasonForUnsubscribing: PropTypes.func,
  upgradeSubscription: PropTypes.func,
  upgradeSubscriptionModalOpen: PropTypes.bool,
  user: PropTypes.object,
};

export default SubscribedUserPaymentInfo;
