import React from 'react';
import PropTypes from 'prop-types';

import Ad from '../../../../../../shared/components/Ad/Ad';

const MultiFlightSearchMobileAd = ({
  hasAtLeastAStandardSubscription,
  resultsBottomAd,
}) => (
  <div className='multi-flight mobile-ad'>
    <Ad
      key={resultsBottomAd.id}
      {...resultsBottomAd}
      subscriptionActive={hasAtLeastAStandardSubscription}
    />
  </div>
);

MultiFlightSearchMobileAd.propTypes = {
  adHeight: PropTypes.string,
  adWidth: PropTypes.string,
  hasAtLeastAStandardSubscription: PropTypes.bool,
  isMobileScreen: PropTypes.bool,
  resultsBottomAd: PropTypes.object,
};

export default MultiFlightSearchMobileAd;
