/**
 * THIS IS THE ENTRY POINT FOR THE CLIENT, JUST LIKE server.js IS THE ENTRY POINT FOR THE SERVER.
 */
import React from 'react';
import ReactDOM from 'react-dom';

import 'babel-polyfill';

import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
// import createBrowserHistory from 'history/createBrowserHistory';
import getMuiTheme from 'material-ui/styles/getMuiTheme';

import { setAppHost } from '../src/lib/host-utils';
import ApiClient from '../src/helpers/ApiClient';
import Root from '../modules/App/components/container';
import createStore from '../src/redux/create';
import polyfills from './polyfills';
import withTracker from './ga-page-tracker';
import getHistory from '../modules/history';

// const browserHistory = createBrowserHistory({ basename: '/v2' });

if (process.NODE_ENV === 'development') {
  // This enables hot reloading
  require('../src/styles/main.scss');
}

const enableEloqua = __PRODUCTION__; // eslint-disable-line no-undef

function eloquaLoad() {
  const s = document.createElement('script');
  s.type = 'text/javascript'; s.async = true;
  s.src = '//img.en25.com/i/elqCfg.min.js';
  const x = document.getElementsByTagName('script')[0];
  x.parentNode.insertBefore(s, x);
}

if (enableEloqua) {
  if (window.addEventListener) window.addEventListener('DOMContentLoaded', eloquaLoad, false);
  else if (window.attachEvent) window.attachEvent('onload', eloquaLoad);
}

try {
  polyfills();

  // const b64DecodeUnicode = str =>
  // decodeURIComponent(
  // Array.prototype.map.call(atob(str),
  // c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));
  // decodeURIComponent(str);
  const browserHistory = getHistory();
  const client = new ApiClient();
  const dest = document.getElementById('content');
  const store = createStore(client, window.__data, browserHistory);

  const { Account, App } = store.getState();
  client.setServerTime(Account.serverTime);
  client.setCSRFToken(App.csrf);
  setAppHost(App.appHost);

  const { routeConfig } = App;

  if (enableEloqua) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = "var _elqQ = []; _elqQ.push(['elqSetSiteId', '1634']); _elqQ.push(['elqTrackPageView']);";
    document.head.appendChild(script);
  }

  // const scrollingHistory = useScroll(() => browserHistory)();

  const AppRoot = () => (
    <Switch>
      <Route
        render={props => (
          <Root
            {...props}
            name='App'
          />
        )}
      />
    </Switch>
  );
  const TrackedAppRoot = withTracker(AppRoot, { routeConfig });
  ReactDOM.hydrate(
    <Provider store={store} key='provider'>
      <MuiThemeProvider muiTheme={getMuiTheme({ userAgent: 'all' })}>
        <ConnectedRouter history={browserHistory}>
          <Route render={props => <TrackedAppRoot {...props} />} />
        </ConnectedRouter>
      </MuiThemeProvider>
    </Provider>, dest,
  );

  if (process.env.NODE_ENV !== 'production') {
    window.React = React; // enable debugger
  }
} catch (ex) {
  console.log(ex);
}
