import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';
import componentBase from '../../../../../../src/lib/component-base';

@componentBase('AccountError')
export default class AccountError extends Component {
  static propTypes = {
    errorCode: PropTypes.string,
    name: PropTypes.string,
  };

  static ErrorDisplayWording = {
    CARRIER_OR_PHONE: 'A phone and carrier are required when one or the other is specified',
    MISSING_EMAIL: 'An email address is required',
    INVALID_EMAIL: 'The email address provided is invalid',
    EMAIL_IN_USE: 'The email address provided is already in use',
    MISSING_PHONE: 'A phone number is required',
    INVALID_PHONE: 'Invalid phone number (example: 5551234567)',
    WRONG_OLD_PASSWORD: 'The old password is incorrect',
    MISSING_OLD_PASSWORD: 'Please enter your old password',
    MISSING_NEW_PASSWORD: 'Please enter your new password',
    PASSWORD_LENGTH_INVALID: 'Password length must be between 8 and 30 characters',
    PASSWORDS_MUST_MATCH: 'Passwords do not match. Please try again',
    MISSING_WIRELESS_SERVICE: 'Please choose a wireless service provider',
    UNKNOWN_ERROR: 'Error',
    PASSWORD_SHORT: 'Password length must be between 8 and 30 characters',
    PASSWORD_LONG: 'Password length must be between 8 and 30 characters',
    PASSWORD_NO_LOWERCASE: 'Password must satisfy the guidelines*',
    PASSWORD_NO_UPPERCASE: 'Password must satisfy the guidelines*',
    PASSWORD_NO_NUMBER: 'Password must satisfy the guidelines*',
    PASSWORD_NO_SPECIAL_CHAR: 'Password must satisfy the guidelines*',
  };

  errorWording = messageCode => (AccountError.ErrorDisplayWording[messageCode] || messageCode);

  errorClass = () => (this.props.errorCode ? 'error' : '');

  render() {
    const errorMessage = !this.props.errorCode ? null : (
      <p className='account-error-text'>{this.errorWording(this.props.errorCode)}</p>
    );

    return (
      <div>
        <Element name={`account-error-${this.props.name}`} />
        {errorMessage}
      </div>
    );
  }
}
