import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { path, pathOr } from 'ramda';
import AirportDelayMapZoomControl from './children/AirportDelayMapZoomControl';
import componentBase from '../../../src/lib/component-base';
import { actions, selectors } from '../redux';
import { NAME } from '../redux/constants';
import { shouldLoad } from '../../../src/redux/utils';
import * as delayIndex from '../../../shared/lib/flex-delay-index';
import Ad from '../../../shared/components/Ad/Ad';
import { AirportDelayMap as airportDelayMapAds } from './ads';
import Sidebar from './children/AirportDelayMap/children/Sidebar';
import { getRegionText } from './regions';
import { resolveCanonicalFromPathname } from '../../App/redux/selectors';
import Meta from '../../../shared/components/Meta/container';
import { PageHeader } from '../../../shared/components/PageHeader/PageHeader';

let AirportDelayMap = null;
const DEFAULT_REGION = 'northAmerica';

const {
  endSpinner,
  loadDelayIndices,
  loadDetailsByCode,
  setAirport,
  startSpinner,
  updateMap,
  updateAirportCode,
} = actions;

const { getTrackerState } = selectors;

@connect((state) => {
  const {
    delayIndices,
    delayObj,
    defaultAirports,
    airport,
    center,
    error,
    loading,
    loaded,
    zoom,
  } = state.AirportDelayMap;

  return {
    canonical: resolveCanonicalFromPathname(state),
    delayIndices,
    defaultAirports,
    delayObj,
    airport,
    center,
    error,
    loading,
    loaded,
    zoom,
    shouldLoad: shouldLoad(state, NAME),
    sidebarState: getTrackerState(state),
  };
})
@componentBase('DelayMap')
export default class DelayMap extends React.Component {
  static propTypes = {
    airport: PropTypes.string,
    canonical: PropTypes.string,
    center: PropTypes.array,
    defaultAirports: PropTypes.array,
    delayIndices: PropTypes.array,
    delayObj: PropTypes.object,
    dispatch: PropTypes.func,
    loaded: PropTypes.bool,
    loading: PropTypes.bool,
    location: PropTypes.object,
    match: PropTypes.object,
    name: PropTypes.string,
    shouldLoad: PropTypes.bool,
    sidebarState: PropTypes.object,
    zoom: PropTypes.number,
  }

  componentWillMount() {
    // it's the inital load on the server, so do the fetching
    if (this.props.shouldLoad) {
      this.props.dispatch(startSpinner());
      this.props.dispatch(loadDelayIndices());
    }
    const region = pathOr(DEFAULT_REGION, ['match', 'params', 'region'], this.props);
    if (region && !this.props.airport && this.props.delayObj) {
      this.loadRandomAirport(this.props);
    }

    if (this.props.loaded) {
      this.props.dispatch(endSpinner());
    }
  }

  componentDidMount() {
    if (!AirportDelayMap) {
      /*
        AirportDelayMap should not be `imported`
        AirportDelayMap child components
          Map
          AirportDelays
          SatTileLayer
          MapLabelsLayer
          MapRadarLayer
          MapZoomControl

          by way of leaflet, use `window` methods
          and do not appreciate server side rendering
      */
      AirportDelayMap = require('./children/AirportDelayMap/AirportDelayMap').default; //eslint-disable-line
    }
  }

  componentWillReceiveProps(nextProps) {
    const currentRegion = pathOr(DEFAULT_REGION, ['match', 'params', 'region'], this.props);
    const nextRegion = pathOr(DEFAULT_REGION, ['match', 'params', 'region'], nextProps);
    if ((!this.props.delayObj && nextProps.delayObj) || currentRegion !== nextRegion) {
      this.loadRandomAirport(nextProps);
    }
  }

  componentWillUnmount() {
    if (this.props.loading) {
      this.props.dispatch(endSpinner());
    }
  }

  setAirportSession(airportCode, map) {
    if (map) { // map is only passed in when a user selects an airport on the map
      const mapCenter = map.getCenter();
      this.props.dispatch(updateMap({
        center: [mapCenter.lat, mapCenter.lng],
        zoom: map.getZoom(),
      }));
    }

    this.props.dispatch(setAirport(airportCode));
  }

  randomAirportIndex = flightArrayLength => Math.floor(Math.random() * flightArrayLength);

  updateAirport = (newAirport, map) => {
    const airportCode = (newAirport && newAirport.code) ? newAirport.code : newAirport;
    if (airportCode) {
      this.setAirportSession(airportCode, map);
      this.props.dispatch(loadDetailsByCode({ airportCode }));
    }
  }

  loadRandomAirport = (props) => {
    const airportCode = this.randomAirport(props);
    this.updateAirport(airportCode);
    this.props.dispatch(updateAirportCode({ airportCode }));
  }

  randomAirport = (props) => {
    // random airport is a large/busy airport, if possible
    const region = pathOr(DEFAULT_REGION, ['match', 'params', 'region'], this.props);
    const { delayObj } = props;

    if (!delayObj || !region) {
      // If the delayObj prop is not populated yet (first load), don't continue
      return;
    }

    let randomAirportInNewRegion = null;
    const regionArray = delayObj[region];
    const filteredRegion = delayIndex.defaultAirports(regionArray);
    const randomIndex = this.randomAirportIndex(filteredRegion.length);
    randomAirportInNewRegion = filteredRegion[randomIndex];

    return randomAirportInNewRegion.code ?
      randomAirportInNewRegion.code : randomAirportInNewRegion;
  }

  formatMeta = (regionForMeta) => {
    const regionName = getRegionText(regionForMeta);
    const title = `${regionName || 'Worldwide'} Airport Delays`;
    const description = regionName ? `See airports in ${regionName} that are currently experiencing departure delays` : 'See airports worldwide that are currently experiencing departure delays';
    const keywords = [
      title,
      'worldwide airport delays',
      'airport delay map',
      'departure delays',
      'arrival delays',
      `flight delays ${regionName || 'worldwide'}`,
    ].join(',');
    return {
      title,
      description,
      keywords,
    };
  }

  render() {
    const { canonical, match } = this.props;
    const regionForMeta = path(['params', 'region'], match);
    const { description, keywords, title } = this.formatMeta(regionForMeta);
    const region = pathOr(DEFAULT_REGION, ['params', 'region'], match);
    const regionName = getRegionText(region);
    let map = (
      <div />
    );
    if (AirportDelayMap && Sidebar) {
      const subscriptionActive = (
        this.context &&
        this.context.currentUser &&
        this.context.currentUser.subscriptionActive
      );
      const boxAd = subscriptionActive ? null : (
        <center style={{ margin: '25px 0' }}>
          <Ad
            key={airportDelayMapAds.bottom.id}
            {...airportDelayMapAds.bottom}
            subscriptionActive={subscriptionActive}
          />
        </center>
      );

      map = (
        <div className='map-and-sidebar'>
          <AirportDelayMapZoomControl region={region} />
          <div className='delay-map-component'>
            <AirportDelayMap
              updateAirport={this.updateAirport}
              delayIndices={this.props.delayIndices}
              region={region}
              center={this.props.center}
              zoom={this.props.zoom}
            />
          </div>
          <div className='sidebar-container'>
            <Sidebar
              {...this.props.sidebarState}
              dispatch={this.props.dispatch}
            />
            {boxAd}
          </div>
        </div>
      );
    }

    return (
      <div>
        <Meta
          canonical={canonical}
          description={description}
          keywords={keywords}
          match={match}
          title={title}
        />
        <div className='delay-map-container'>
          <PageHeader title={`${regionName} Airport Delays`} />
          <div className='delay-map'>
            {map}
          </div>
        </div>
      </div>
    );
  }
}
