import React from 'react';
import PropTypes from 'prop-types';

const FeatureDot = (props) => {
  const {
    customClassName,
    tier,
  } = props;

  return (<div className={`${tier}-feature-dot ${customClassName || ''}`} />);
};

FeatureDot.propTypes = {
  customClassName: PropTypes.string,
  tier: PropTypes.string,
};

export default FeatureDot;
