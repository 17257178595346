import moment from 'moment';
import * as t from './actionTypes';

const initialState = {
  loading: false,
  loaded: false,
  searching: false,
  searched: false,
  usingFinalCredit: null,
  byFlightAirline: {},
  airlineByRoute: {},
  arrivalAirport: {},
  departureAirport: {},
  departureDate: moment.utc(),
  flightNumber: '',
  historicalByFlightSearch: '',
  historicalByAirportSearch: '',
  byFlightSecondaryAction: false,
  byAirlineSecondaryAction: false,
};


const errorCode = (error) => {
  if (error && error.status === 500) {
    return 'SEARCH_ISSUES';
  } else if (error && error.status === 429) {
    return 'TOO_MANY_REQUESTS';
  }

  return 'FLIGHT_LOAD_ERROR1';
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case t.START_SPINNER:
      return {
        ...state,
        loading: true,
      };
    case t.END_SPINNER:
      return {
        ...state,
        loading: false,
      };
    case t.HISTORICAL_BY_AIRPORT_SEARCH:
      return {
        ...state,
        historicalByAirportSearch: action.historicalByAirportSearch,
      };
    case t.HISTORICAL_BY_FLIGHT_SEARCH:
      return {
        ...state,
        historicalByFlightSearch: action.historicalByFlightSearch,
      };
    case t.LOAD_BY_ID:
      return {
        ...state,
        loaded: false,
        details: null,
        segments: null,
        error: null,
      };
    case t.LOAD_BY_ID_SUCCESS:
      return {
        ...state,
        loaded: true,
        details: action.result && action.result.details,
        segments: action.result && action.result.segments,
        error: null,
      };
    case t.LOAD_BY_ID_FAIL:
      return {
        ...state,
        loaded: true,
        error: {
          ...action.error,
          code: errorCode(action.error),
        },
      };
    case t.LOAD_SEARCH:
      return {
        ...state,
        loaded: false,
        segments: null,
        details: null,
        searching: true,
        searched: false,
        result: null,
      };
    case t.LOAD_SEARCH_SUCCESS:
      let result = null;
      const segments = action.result;
      if (segments && segments.length) {
        result = {
          carrier: segments[0].carrier.fs,
          flightNumber: segments[0].carrier.flightNumber,
          year: segments[0].year,
          month: segments[0].month,
          date: segments[0].date,
          flightId: segments.length > 1 ? null : segments[0].flightId,
          url: segments.length > 1 ? null : segments[0].url,
        };
      }

      return {
        ...state,
        loaded: true,
        searching: false,
        searched: false,
        result,
        segments,
        error: null,
      };
    case t.LOAD_SEARCH_FAIL:
      return {
        ...state,
        loaded: true,
        searching: false,
        searched: false,
        error: {
          ...action.error,
          code: errorCode(action.error),
        },
      };
    case t.USING_SINGLE_CREDIT:
    case t.USED_SINGLE_CREDIT:
      return {
        ...state,
        ...action.result,
      };
    case t.SET_HISTORICAL_SEARCH_INPUTS:
      const { byFlight, secondary } = action.result;
      const byFlightOrByAirline = byFlight ? 'byFlightSecondaryAction' : 'byAirlineSecondaryAction';
      return {
        ...state,
        ...action.result,
        [byFlightOrByAirline]: secondary,
      };
    case t.SET_BY_FLIGHT_SECONDARY_ACTION:
      return {
        ...state,
        byFlightSecondaryAction: action.bool,
      };
    case t.SET_BY_AIRLINE_SECONDARY_ACTION:
      return {
        ...state,
        byAirlineSecondaryAction: action.bool,
      };
    case t.SET_HISTORICAL_SINGLE_ERROR_MESSAGE:
      return {
        ...state,
        singleErrorMessage: action.message,
      };
    default:
      return state;
  }
};
