import logger from './logger';
import mixin from './mixin';
import reportException from '../../shared/lib/report-exception';
import defaultContextTypes from '../components/default-context-types';
import { setComponentState } from '../redux/modules/session/actions';
import { getComponentState } from '../redux/modules/session/selectors';
import reactComponentErrors from './react-component-errors';

// Add static properties to the decorated component here.
const staticMembers = (displayName, Component) => {
  const newComponent = Component;

  newComponent.contextTypes = defaultContextTypes;
  newComponent.displayName = displayName;

  return newComponent;
};

// Add instance properties to the decorated component here.
const instanceMembersMixin = displayName => mixin({
  logger: logger(displayName),
  exception(ex, context) {
    reportException(ex, context, 'component-base-exception');
  },
  frozenState: (store, componentName) => {
    let frozen = null;
    const storeState = store && store.getState();

    const { location } = storeState.router;

    if ((location && location.action === 'POP')
    || (location && location.query && location.query.error)) {
      frozen = componentName ? getComponentState(storeState, componentName) :
        getComponentState(storeState, displayName);
    } else {
      store.dispatch(setComponentState(displayName, null));
    }

    return frozen;
  },
  freezeState: (store, frozenState, componentName) => {
    if (componentName) {
      store.dispatch(setComponentState(componentName, frozenState));
    } else {
      store.dispatch(setComponentState(displayName, frozenState));
    }
  },
  promiseDispatch: (store) => {
    const rawDispatch = store.dispatch;
    return (action) => {
      if (typeof action.then === 'function') {
        return action.then(rawDispatch);
      }
      return rawDispatch(action);
    };
  },
});

export default displayName => (Component) => {
  reactComponentErrors(Component);
  const newComponent = staticMembers(displayName, Component);

  return instanceMembersMixin(displayName)(newComponent);
};
