// unit-measurement-format

export const distanceOptions = [
  { value: 0, label: 'Miles' },
  { value: 1, label: 'Kilometers' },
];

export const temperatureOptions = [
  { value: 0, label: 'Fahrenheit' },
  { value: 1, label: 'Celsius' },
];

export const speedOptions = [
  { value: 0, label: 'kts' },
  { value: 1, label: 'MPH' },
  { value: 2, label: 'km/h' },
];

const isAlternate = (user, attribute) => (
  user && user[attribute] !== 0
);

export const prefersCTempFormat = user => (
  isAlternate(user, 'unitsOfMeasurementTemperature')
);

export const prefersMetricDistanceFormat = user => (
  isAlternate(user, 'unitsOfMeasurementDistance')
);

export const prefersMPHSpeedFormat = user => (
  user && user.unitsOfMeasurementSpeed !== 0 && user.unitsOfMeasurementSpeed !== 2
);

export const prefersKPHSpeedFormat = user => (
  user && user.unitsOfMeasurementSpeed !== 0 && user.unitsOfMeasurementSpeed !== 1
);
