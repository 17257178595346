import * as t from './actionTypes';

const initialState = {};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case t.SET_USER_AGENT:
      return {
        ...state,
        userAgent: action.userAgent,
      };
    case t.SET_RECAPTCHA_SITE_KEY:
      return {
        ...state,
        siteKey: action.siteKey,
      };
    case t.SET_RELEASE:
      return {
        ...state,
        release: action.release,
      };
    case t.SET_ROUTE_NAME:
      return {
        ...state,
        routeName: action.routeName,
      };
    case t.ADD_VARIANTS:
      return {
        ...state,
        variants: { ...state.variants, ...action.variants },
      };
    case t.CLEAR_VARIANTS:
      return {
        ...state,
        variants: undefined,
      };
    case t.SET_ROUTE_CONFIG:
      return {
        ...state,
        routeConfig: action.routeConfig,
      };
    case t.SET_INTERVAL_ID:
      return {
        ...state,
        pollingIntervalId: action.pollingIntervalId,
      };
    case t.SET_SLOT_REFRESHED:
      return {
        ...state,
        slotsRefreshed: action.slotsRefreshed,
      };
    case t.SET_CURRENT_BREAKPOINT:
      return {
        ...state,
        mediaBreakpoint: action.mediaBreakpoint,
      };
    case t.SET_APP_HOST:
      return {
        ...state,
        appHost: action.appHost,
      };
    default:
      return state;
  }
};
