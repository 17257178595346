import React from 'react';
import PropTypes from 'prop-types';
import { pathOr } from 'ramda';
import moment from 'moment-timezone';
import componentBase from '../../../../src/lib/component-base';
import MultiFlightsList from './children/MultiFlightsList/MultiFlightsList';
import { timeFormatSelect } from '../../../../src/lib/date-time-format';
import handleFirstLoad from '../../../../shared/lib/handle-first-component-load';

@componentBase('FlightTrackerMultiFlightResults')
export default class FlightTrackerMultiFlightResults extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    errorText: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.node,
    ]),
    flights: PropTypes.object,
    departureTime: PropTypes.number,
    destOriginTitle: PropTypes.string,
    header: PropTypes.object,
    historicalFlightStatus: PropTypes.bool,
    loading: PropTypes.bool,
    location: PropTypes.object,
    match: PropTypes.object,
    multiFlightSearchAirline: PropTypes.object,
    multiFlightSearchDate: PropTypes.number,
    multiFlightSearchTime: PropTypes.number,
    multiFlightSearchCodeShareToggle: PropTypes.bool,
    multiFlightMobileSearchDepartureTime: PropTypes.number,
    multiFlightMobileSearchLoading: PropTypes.bool,
    params: PropTypes.object,
    pageIndex: PropTypes.number,
    results: PropTypes.object,
    searchUrl: PropTypes.string,
    secondaryAdvancedSearchActionTime: PropTypes.bool,
    user: PropTypes.object,
    variant: PropTypes.string,
  };

  static defaultProps = {
    flights: null,
    destOriginTitle: null,
    historicalFlightStatus: false,
  };

  state = {
    firstLoad: true,
  };

  componentWillReceiveProps() {
    const newState = this.handleFirstLoad(this.state.firstLoad);
    this.setState(newState);
  }

  reportEvent = () => {
    const { params = {} } = this.props.match;
    const {
      month,
      day,
      year,
      carrier,
      departureAirportCode,
      arrivalAirportCode,
    } = params;
    const { reportEvent, reportQueryTimeFrame } = this.context;
    reportQueryTimeFrame(day, month, year);
    carrier && reportEvent('Airline', carrier, null, null, true);
    departureAirportCode && reportEvent('Airport', departureAirportCode, 'Departing', null, true);
    arrivalAirportCode && reportEvent('Airport', arrivalAirportCode, 'Arriving', null, true);
  }

  handleFirstLoad = handleFirstLoad(this.reportEvent);

  timeOfTravelOptions = [
    { value: 0, label: timeFormatSelect(this.props.user, '00:00 - 06:00', '12:00 am - 6:00 am') },
    { value: 6, label: timeFormatSelect(this.props.user, '06:00 - 12:00', '6:00 am - 12:00 pm') },
    { value: 12, label: timeFormatSelect(this.props.user, '12:00 - 18:00', '12:00 pm - 6:00 pm') },
    { value: 18, label: timeFormatSelect(this.props.user, '18:00 - 00:00', '6:00 pm - 12:00 am') },
  ];

  defaultTimeValue = () => {
    const departure = pathOr(
      'Europe/London',
      ['flights', 'header', 'departureAirport', 'timeZoneRegionName'],
      this.props,
    );
    const fallBackTimezone = pathOr(
      departure,
      ['flights', 'header', 'arrivalAirport', 'timeZoneRegionName'],
      this.props,
    );
    const fallBackHour = moment().tz(fallBackTimezone).format('h');
    const value = pathOr(
      fallBackHour,
      ['match', 'params', 'hour'],
      this.props,
    );
    const defaultTime = this.timeOfTravelOptions.find(o => value < (o.value + 6)) || {};
    return pathOr(0, ['value'], defaultTime);
  }

  timeValue = time => ((time || time === 0) ? time : this.defaultTimeValue())

  renderResults = () => {
    const {
      errorText,
      destOriginTitle,
      dispatch,
      header,
      match,
      results,
      pageIndex,
      searchUrl,
      variant,
    } = this.props;

    return (
      <div className='multi-flight-container'>
        {
          header &&
          <MultiFlightsList
            {...results}
            {...destOriginTitle}
            errorText={errorText}
            dispatch={dispatch}
            match={match}
            pageIndex={pageIndex}
            searchUrl={searchUrl}
            variant={variant}
          />
        }
      </div>
    );
  }

  render() {
    if (this.props.loading) {
      return (<div />);
    }
    return (
      <div style={{ marginBottom: '40px' }}>
        {this.renderResults()}
      </div>
    );
  }
}
