import React from 'react';
import PropTypes from 'prop-types';

const noAdsHeightOverride = { minHeight: '110px', maxHeight: '110px' };
const AppBarChildren = (props) => {
  const {
    ad,
    headerNavArray,
    hideHeader,
    pathName,
    subscriptionActive,
    topRightButtons,
  } = props;
  if (hideHeader(pathName)) {
    return <span />;
  }
  const containerStyle = subscriptionActive ? noAdsHeightOverride : {};
  return (
    <div style={containerStyle} className='fs-header-logo-container'>
      {headerNavArray}
      {!subscriptionActive &&
      <div className='fs-header-ad-container' >
        {ad}
      </div>}
      {topRightButtons}
    </div>
  );
};

AppBarChildren.propTypes = {
  ad: PropTypes.node,
  headerNavArray: PropTypes.array,
  hideHeader: PropTypes.func,
  pathName: PropTypes.string,
  subscriptionActive: PropTypes.bool,
  topRightButtons: PropTypes.object,
};

export default AppBarChildren;
