const debug = require('debug');

(function logger() {
  const pattern = __DEVELOPMENT__ ? '*,-engine*,-jsonp' : null;
  try {
    window.debugLogger = debug;
    if (pattern) {
      window.debugLogger.enable(pattern);
    } else {
      window.debugLogger.disable();
    }
  } catch (e) {
    global.logger = debug(pattern);
  }
}());

export default debug;
