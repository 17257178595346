import React from 'react';
import PropTypes from 'prop-types';
import TextInput from '../../../../shared/components/TextInput/TextInput';

export const textInputLabelStyle = {
  textAlign: 'left',
};

export const textInputLabelErrorStyle = {
  float: 'left',
  top: '5px',
};

export const textInputSpanStyle = {
  color: 'white',
};

export const textInputInputStyle = {
};

const EmailTextInput = (props) => {
  const {
    errorMessage,
    inputRef,
    validateEmail,
  } = props;

  return (
    <TextInput
      errorMessage={errorMessage}
      inputClassName='account-input'
      inputRef={inputRef}
      inputStyle={textInputInputStyle}
      label='Email Address'
      labelErrorStyle={textInputLabelErrorStyle}
      labelStyle={textInputLabelStyle}
      onInputChange={validateEmail}
      placeholder='name@domain.com'
      spanStyle={textInputSpanStyle}
      type='email'
    />
  );
};

EmailTextInput.propTypes = {
  errorMessage: PropTypes.string,
  inputRef: PropTypes.func,
  validateEmail: PropTypes.func,
};

export default EmailTextInput;
