import * as t from './actionTypes';

const initialState = {
  modalType: null,
  modalProps: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case t.SHOW_MODAL:
      return {
        modalType: action.modalType,
        modalProps: action.modalProps,
      };
    case t.HIDE_MODAL:
      return initialState;
    case t.CONFIRM_MODAL:
      return {
        ...initialState,
        modalConfirmed: true,
      };
    case t.CANCEL_MODAL:
      return {
        ...initialState,
        modalCanceled: true,
      };
    default:
      return state;
  }
};
