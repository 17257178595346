exports.BIN_SIZE_MINUTES = 20;
exports.ALL_DAY_LABEL = 'All Day';
exports.CHART_ROW_HEIGHT = 260;
exports.PAGE_SIZE = 25;
exports.LIST_SORT_KEYS = {
  destination: 'Destination',
  origin: 'Origin',
  airline: 'Airline',
  equipment: 'Equipment',
  terminal: 'Terminal',
  gate: 'Gate',
  scheduledGate: 'Scheduled Gate',
  actualGate: 'Actual Gate',
  scheduledRunway: 'Scheduled Runway',
  actualRunway: 'Actual Runway',
  status: 'Status',
};
exports.DEFAULT_FILTER_SELECTED_VALUES = {
  airline: [],
  destOrigin: [],
  status: [],
  terminal: [],
  gate: [],
  equipment: [],
  baggage: [],
  delay: [],
  timespan: null,
  codeshare: 'hide',
};
