import React from 'react';
import PropTypes from 'prop-types';
import {
  IconTitleSet,
  OnTimePerformanceChart,
  Table,
  StarRatingCard,
  FeatureSetBackground,
} from '@flightstats/component-lib';
import CardAside from './children/CardAside';
import CardArticle from './children/CardArticle';
import AdCard from './children/AdCard';
import Ad from '../../../../../shared/components/Ad/Ad';
import { OnTimePerformance as onTimePerformanceAds } from '../../ads';
import OtherStops from '../OtherStops/OtherStops';

// import HistoryBooksIconWHITE from '../../../../../static/images/HistoryBooks-Icon-WHITE.svg';
import arrivalIcon from '../../../../../static/images/airport-arrival-icon.png';
import departureIcon from '../../../../../static/images/airport-departure-icon.png';
import booksIcon from '../../../../../static/images/HistoryBooks-Icon-WHITE.svg';

const renderTable = (statistics) => {
  if (statistics) {
    const {
      codeshares,
      max,
      mean,
      min,
      delayObservations,
      standardDeviation,
      totalObservations,
    } = statistics;
    const defaultProps = {
      title: 'Delay Statistics',
      subTitle: '(Aggregate Arrival Delay Statistics for this Flight)',
      borderColor: 'white',
      color: 'transparent',
      height: '250px',
      labelWidth: '55%',
      rowColor: '#2D4057',
      rowSpacers: true,
      labels: 'left',
      width: '100%',
    };
    const labels = [
      'Total Flights Observed',
      'Total Delays ( > 1 min )',
      'Codeshares',
      'Average Delay (min.)',
      'Standard Deviation',
      'Min',
      'Max',
    ];
    const values = [
      totalObservations,
      delayObservations,
      codeshares,
      mean,
      standardDeviation,
      min,
      max,
    ];
    const data = values.map((val, index) => ({
      label: labels[index],
      values: [val],
    })).filter(item => item.values && (item.values[0] || item.values[0] === 0));

    return (
      <Table
        title={defaultProps.title}
        subTitle={defaultProps.subTitle}
        color={defaultProps.colors}
        height={defaultProps.height}
        labelWidth={defaultProps.labelWidth}
        rowColor={defaultProps.rowColor}
        rowSpacers={defaultProps.rowSpacers}
        labels={defaultProps.labels}
        width={defaultProps.width}
        data={data}
      />
    );
  }
  return null;
};

const formatLocationText = (location, commaSpace) => (location ? `${commaSpace}${location}` : '');

const renderIconTitle = (airport, icon) => {
  if (airport) {
    const {
      city,
      country,
      fs,
      iata,
      name,
      state,
    } = airport;
    const locations = [city, state, country];
    const locationText = locations.filter(location => location)
      .map((item, index) => {
        const commaSpace = index !== 0 && item ? ', ' : '';
        return item && formatLocationText(item, commaSpace);
      })
      .join('');
    const mainText = `${`(${iata || fs})` || ''} ${name || ''}`;
    return (
      <IconTitleSet
        mainText={mainText}
        subText={locationText}
        imageURL={icon}
        imageWidth='35px'
        headingSize='small'
        subtextSize='tiny'
      />
    );
  }
  return null;
};

const ratings = (details, carrierCode, flightNumber) => {
  const OnTimeText = 'Percentage On-Time';
  const DelayText = 'Average Delay Time';
  const { delayPerformance, overall, otp } = details;

  return (
    <div style={{ border: '1px solid white', margin: '30px 0' }}>
      <FeatureSetBackground bgColor='transparent' borderColor='transparent' >
        <IconTitleSet
          bgColor='transparentWhite'
          mainText={`${carrierCode} ${flightNumber} On-Time Performance Details`}
          imageURL={booksIcon}
          imageWidth='40px'
          headingSize='small'
        />
        <div className='ratings-wrapper'>
          {
            [
              {
                className: 'overall',
                description: [
                  'FlightStats Rating is a merit measurement considering both on-time performance and delay severity.',
                  `The score, ${overall && overall.stars}`,
                  'shows that this flight has on-time performance characteristics better than',
                  `${overall && overall.cumulative}% of all other flights`,
                  'in the FlightStats database.',
                ].join(' '),
                rating: overall && overall.stars,
                ratingOne: overall && `${overall.ontimePercent}%`,
                ratingOneText: OnTimeText,
                ratingTwo: overall && `${overall.delayMean} min`,
                ratingTwoText: DelayText,
                stars: overall && overall.roundedStars,
                title: 'Overall Rating',
              },
              {
                className: 'on-time',
                description: [
                  `This flight has an on-time performance of ${otp && otp.ontimePercent}%.`,
                  'Statistically, when taking into consideration sample size, standard deviation, and mean,',
                  `this flight is on-time more often than ${otp && otp.cumulative}% of other flights.`,
                ].join(' '),
                rating: otp && otp.stars,
                ratingOne: otp && `${otp.ontimePercent}%`,
                ratingOneText: OnTimeText,
                stars: otp && otp.roundedStars,
                title: 'On-Time Performance Rating',
              },
              {
                className: 'delay',
                description: [
                  `This flight has an average delay of ${delayPerformance && delayPerformance.delayMean} minutes`,
                  `with a standard deviation of ${delayPerformance && delayPerformance.standardDeviation} minutes.`,
                  'Statistically, when taking into consideration sample size, standard deviation, and mean,',
                  'this flight has delay performance characteristics better than',
                  `${delayPerformance && delayPerformance.cumulative}% of other flights.`,
                ].join(' '),
                rating: delayPerformance && delayPerformance.stars,
                ratingOne: delayPerformance && `${delayPerformance.delayMean} min`,
                ratingOneText: DelayText,
                stars: delayPerformance && delayPerformance.roundedStars,
                title: 'Delay Performance',
              },
            ].map((args) => {
              const { className, title } = args;
              return (
                <div className={className} key={title}>
                  <StarRatingCard
                    {...args}
                  />
                </div>
              );
            })
          }
        </div>
      </FeatureSetBackground>
    </div>
  );
};

const renderChart = (chart) => {
  if (chart) {
    const {
      cancelled,
      diverted,
      excessive,
      late,
      onTime,
      veryLate,
    } = chart;
    return (
      <OnTimePerformanceChart
        cancelled={cancelled || 0}
        diverted={diverted || 0}
        excessive={excessive || 0}
        late={late || 0}
        onTime={onTime || 0}
        veryLate={veryLate || 0}
        width='100%'
      />
    );
  }
  return null;
};

const OnTimePerformanceDetail = (props) => {
  const {
    carrierCode,
    dispatch,
    flightNumber,
    rating,
    subscribed,
  } = props;
  if (rating) {
    const {
      arrivalAirport,
      chart,
      departureAirport,
      details,
      statistics,
      otherStops,
    } = rating;

    return (
      <div className='single-flight'>
        <div className='title-icons'>
          {renderIconTitle(departureAirport, departureIcon)}
          {renderIconTitle(arrivalAirport, arrivalIcon)}
        </div>
        <section className={`flexible-card-container ${subscribed ? 'subscribed' : ''}`}>
          <CardArticle subscribed={subscribed} >
            {renderChart(chart)}
          </CardArticle>
          {
            subscribed &&
            <CardAside>
              {renderTable(statistics)}
            </CardAside>
          }
          <AdCard subscribed={subscribed}>
            <Ad
              key={onTimePerformanceAds.top.id}
              {...onTimePerformanceAds.top}
              subscriptionActive={subscribed}
            />
          </AdCard>
          {
            !subscribed &&
            <CardArticle
              subscribed={subscribed}
            >
              {renderTable(statistics)}
            </CardArticle>
          }
          <AdCard subscribed={subscribed}>
            <Ad
              key={onTimePerformanceAds.bottom.id}
              {...onTimePerformanceAds.bottom}
              subscriptionActive={subscribed}
            />
          </AdCard>
        </section>
        { ratings(details, carrierCode, flightNumber) }
        { otherStops &&
          otherStops.length > 0 ?
            <OtherStops
              dispatch={dispatch}
              carrierCode={carrierCode}
              flightNumber={flightNumber}
              stops={otherStops}
            /> : '' }
      </div>
    );
  }
  return null;
};

OnTimePerformanceDetail.propTypes = {
  carrierCode: PropTypes.string,
  dispatch: PropTypes.func,
  flightNumber: PropTypes.string,
  rating: PropTypes.object,
  subscribed: PropTypes.bool,
};

export default OnTimePerformanceDetail;
