import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { pathOr } from 'ramda';
import { Multi } from '../../redux';
import PaginatedTable from '../../../../shared/components/PaginatedTable/PaginatedTable';
import { formattedTime, formattedDate } from '../../../../src/lib/date-time-format';
import { Colors } from '../../../../src/constants/style.json';
import componentBase from '../../../../src/lib/component-base';
import userHasHistoricalCredits from '../lib/helpers/userHasHistoricalCredits';
import { getProfile } from '../../../Account/redux/actions';
import { setHistoricalMultiErrorMessage } from '../../redux/Multi/actions';
import { PageHeader } from '../../../../shared/components/PageHeader/PageHeader';

const centeredTextStyle = {
  textAlign: 'center',
};

const timeStyle = {
  marginBottom: '5px',
  textTransform: 'lowercase',
  marginLeft: '5px',
};

const centeredTimeStyle = {
  marginBottom: '5px',
  textTransform: 'lowercase',
  textAlign: 'center',
};

const sameKeyValuePairs = (obj1, obj2) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  return keys1.length === keys2.length &&
    keys1.reduce((prev, key) => {
      if (!prev) {
        return prev;
      }
      return obj1[key] === obj2[key];
    }, true);
};

@connect(state => ({
  error: state.MultiHistoricalFlightStatus.error,
  flights: state.MultiHistoricalFlightStatus.data,
  historicalMultiErrorMessage: state.MultiHistoricalFlightStatus.historicalMultiErrorMessage,
  isMobile: state.App.userAgent.isMobile,
  loading: state.MultiHistoricalFlightStatus.loading,
  loaded: state.MultiHistoricalFlightStatus.loaded,
  pageIndex: state.MultiHistoricalFlightStatus.pageIndex,
  parameters: state.MultiHistoricalFlightStatus.parameters,
  searchUrl: state.MultiHistoricalFlightStatus.searchUrl,
  user: state.Account.user,
}))
@componentBase('HistoricalMultiFlight')
export default class HistoricalMultiFlight extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    error: PropTypes.object,
    flights: PropTypes.object,
    // historicalMultiErrorMessage: PropTypes.string, //Commented out for linter
    isMobile: PropTypes.bool,
    loaded: PropTypes.bool,
    loading: PropTypes.bool,
    match: PropTypes.object,
    pageIndex: PropTypes.number,
    parameters: PropTypes.object,
    searchUrl: PropTypes.string,
    user: PropTypes.object,
  };

  componentDidMount() {
    const {
      dispatch,
      match,
      parameters,
    } = this.props;
    dispatch(getProfile());

    if (sameKeyValuePairs(match.params, parameters)) {
      // same search done twice in a row!
      return match.params;
    }

    let loadFlights = Multi.actions.loadByDepartingAirportDateAndOptionalCarrier;

    if (match.params.arrivalAirportCode && !match.params.departureAirportCode) {
      loadFlights = Multi.actions.loadByArrivingAirportDateAndOptionalCarrier;
    } else if (match.params.arrivalAirportCode && match.params.departureAirportCode) {
      loadFlights = Multi.actions.loadByRouteDateAndOptionalCarrier;
    }

    dispatch(loadFlights(match.params));
  }

  componentWillReceiveProps(nextProps) {
    const noCredits = !userHasHistoricalCredits(nextProps.user) ||
      !userHasHistoricalCredits(this.props.user);
    if (noCredits) {
      this.handleErrorRedirect('NO_CREDITS');
    }

    if (!this.props.loaded && nextProps.loaded) {
      const {
        error,
        flights,
        user,
      } = nextProps;
      if (error) {
        return this.handleErrorRedirect(error.code);
      }
      if (!flights || !flights.flights || flights.flights.length === 0) {
        return this.handleErrorRedirect('FLIGHTS_LOAD_ERROR2');
      }
      if (!userHasHistoricalCredits(user)) {
        return this.handleErrorRedirect('NO_CREDITS');
      }
    }
  }

  flightRowTime = time => ({
    primary: {
      element: <h2 className='flights-list-bold-text flights-list-margined departureTimePadding' style={centeredTimeStyle}>{formattedTime(this.props.user, time, 'time24', 'timeAMPM')}</h2>,
      className: 'col-xs-3 col-sm-2',
    },
  });

  flightRowAirport = airport => ({
    primary: {
      element: <h2 className='flights-list-bold-text flights-list-margined' style={centeredTextStyle}>{airport.fs}</h2>,
      className: 'col-xs-3 col-sm-4',
    },
    secondary: {
      element: <p className='flights-list-subtitle-text' style={centeredTextStyle}>{airport.city}</p>,
      className: 'col-xs-3 col-sm-4',
    },
  });

  handleErrorRedirect = (query = '') => {
    this.props.dispatch(setHistoricalMultiErrorMessage(query));
    this.props.dispatch(push('/historical-flight/search'));
    return null;
  }

  handlePageClick = (pageIndex, searchUrl) => {
    this.props.dispatch(Multi.actions.updatePageIndex(pageIndex, searchUrl));
  }

  render() {
    if (this.props.loading) {
      return (
        <div />
      );
    }

    const {
      dispatch,
      flights,
      isMobile,
      match,
      pageIndex,
      searchUrl,
      user,
    } = this.props;

    if (
      flights && flights.flights &&
      userHasHistoricalCredits(user)
    ) {
      const header = [
        {
          title: 'Flight',
        },
      ];

      const { destOriginTitle } = flights;
      const isDepartureSearch = (destOriginTitle === 'Destination');

      if (destOriginTitle) {
        // is airport search
        if (isDepartureSearch) {
          header.push(
            {
              title: 'Departure Time',
              titleAlt: 'Departure',
              style: centeredTextStyle,
            },
            {
              title: 'Destination',
              style: centeredTextStyle,
            },
          );
        } else { // destOriginTitle === 'Origin'
          header.push(
            {
              title: 'Arrival Time',
              titleAlt: 'Arrival',
              style: centeredTextStyle,
            },
            {
              title: 'Origin',
              style: centeredTextStyle,
            },
          );
        }
      } else {
        // is route search
        header.push(
          {
            title: 'Departure Time',
            titleAlt: 'Departure',
          },
          {
            title: 'Arrival Time',
            titleAlt: 'Arrival',
          },
        );
      }

      header.push({
        title: 'Status',
        style: { marginLeft: 0 },
      });

      const rows = [];
      const hideCodeshares = pathOr(false, ['hideCodeshares'], user);

      flights.flights.forEach((flight) => {
        if (hideCodeshares && flight.isCodeshare) {
          return;
        }
        const operatedBy = flight.operatedBy ? ` / ${flight.operatedBy}` : '';
        const row = [
          {
            href: `${flight.url}`,
            onClick: () => (this.context.reportUserEvent('Click', 'TrackFlight')),
            primary: {
              element: <h2 className='flights-list-bold-text flights-list-margined leftText'>{`${flight.carrier.fs} ${flight.carrier.flightNumber}`}</h2>,
            },
            secondary: {
              element: <p className='hidden-xs flights-list-subtitle-text leftText'>{flight.carrier.name}{operatedBy}</p>,
              elementAlt: <p className='visible-xs flights-list-subtitle-text leftText'>{flight.carrier.name}{operatedBy.replace(/ on behalf.*/, '')}</p>,
              className: destOriginTitle ? 'col-xs-6 col-sm-5' : 'col-xs-9 col-sm-10',
            },
          },
        ];

        if (destOriginTitle) { // departureArrival search
          if (isDepartureSearch) {
            row.push(
              this.flightRowTime(flight.departureTime),
              this.flightRowAirport(flight.airport),
            );
          } else { // destOriginTitle === 'Origin'
            row.push(
              this.flightRowTime(flight.arrivalTime),
              this.flightRowAirport(flight.airport),
            );
          }
        } else { // Route search
          row.push(
            {
              primary: {
                element: <h2 className='flights-list-bold-text flights-list-margined departureTimePadding' style={timeStyle}>{formattedTime(user, flight.departureTime, 'time24', 'timeAMPM')}</h2>,
              },
            },
            {
              primary: {
                element: <h2 className='flights-list-light-text flights-list-margined' style={timeStyle}>{formattedTime(user, flight.arrivalTime, 'time24', 'timeAMPM')}</h2>,
              },
            },
          );
        }

        row.push({
          primary: {
            element: <h2 className='flights-list-light-text flights-list-margined' style={{ color: Colors[flight.color] }}>{flight.status || 'Unknown'}</h2>,
          },
        });

        rows.push(row);
      });

      const date = formattedDate(user, flights.header, 'date', 'dateMDY');

      return (
        <div className='container'>
          <Helmet title={`Historical ${flights.header.title}`} />
          <PageHeader
            isMobile={isMobile}
            title={flights.header.title}
            altTitle={flights.header.mobileTitle}
            secondaryText={date}
          />
          <PaginatedTable
            dispatch={dispatch}
            handlePageClick={this.handlePageClick}
            header={header}
            pageIndex={pageIndex}
            rows={rows}
            match={match}
            searchUrl={searchUrl}
          />
        </div>
      );
    }
    return (
      <div />
    );
  }
}
