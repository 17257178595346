import React from 'react';
import PropTypes from 'prop-types';
import CardAside from './CardAside';

const AdCard = ({ children, subscribed }) => (subscribed ? (<template />) : (
  <CardAside
    noBorder
  >
    {children}
  </CardAside>
));

AdCard.propTypes = {
  children: PropTypes.node,
  subscribed: PropTypes.bool,
};

export default AdCard;
