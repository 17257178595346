// @flow
import React from 'react';
import styled from 'styled-components';
import { props as getProps } from 'ramda';
import { Link } from 'react-router-dom';
import { ClickHandler } from '../../../../modules/common-types';

type OriginalLinkProps = {
  item: {
    href: string,
    onClick: ClickHandler,
  },
  innerRows: React.Node,
};

const getItemProps = getProps(['href', 'onClick']);

const StyledLink = styled(props => <Link {...props} />)`
  text-decoration: none;

  :hover {
    background-color: rgba(255, 255, 255, 0.3);
    cursor: pointer;
  }
`;

const RowContainer = styled.div`
  border-bottom: 2px solid #9b9b9b;
  display: flex;

  :hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
`;

const InnerRowsContainer = styled.div`
  flex: 0 0 100%;
  width: 100%;
`;

export const OriginalLink = ({
  item,
  innerRows,
}: OriginalLinkProps): React.Node => {
  const [href, clickHandler] = getItemProps(item);
  return (
    <StyledLink to={href} key={href} onClick={clickHandler}>
      <RowContainer>
        <InnerRowsContainer>{innerRows}</InnerRowsContainer>
      </RowContainer>
    </StyledLink>
  );
};
