/*
How to set up Google Optimize variants:

1) Go to https://optimize.google.com and sign in

2) Create an experiment and add some variants

3) Edit each variant, then add this script to the 'body' element after the opening tag.
Make sure you replace 'variant-N' with the actual variant number
e.g. variant-1, variant-2, variant-3

try {
  if (typeof(window) === 'object') {
    window.addGOVariant('basic-search-header', 'optional-value');
  }
} catch (err) {}

*/

const variantsPropName = '__variants__';

const variantsPropValue = () => window[variantsPropName];

const hasWindow = () => (typeof window === 'object');

export const variants = () => (hasWindow() && variantsPropValue()) || {};

export const getVariant = name => variants()[name];

export const hasVariant = name => !!getVariant(name);

export const watchVariants = (callback) => {
  if (!hasWindow() || !callback) return;

  if (typeof window.addGOVariant === 'function') {
    return;
  }

  window.addGOVariant = (variantName, optionalVariantValue) => {
    window[variantsPropName] = window[variantsPropName] || {};
    const variantValue = optionalVariantValue || true;
    window[variantsPropName][variantName] = variantValue;
    callback(variantName, variantValue);
  };

  window.clearGOVariants = () => {
    window[variantsPropName] = {};
    callback();
  };
};
