import * as t from './actionTypes';

export const showModal = (modalType, modalProps) => ({
  type: t.SHOW_MODAL,
  modalType,
  modalProps,
});

export const hideModal = () => ({ type: t.HIDE_MODAL });

export const confirmModal = () => ({ type: t.CONFIRM_MODAL });

export const cancelModal = () => ({ type: t.CANCEL_MODAL });

export const noSessionModal = () => ({ type: t.NO_SESSION_MODAL });
