import React, { Component } from 'react';
import PropTypes from 'prop-types';
import componentBase from '../../../../src/lib/component-base';
import AccountError from '../MyAccount/children/children/AccountError';
import { isValidEmail } from '../../../../src/helpers/Validators';

@componentBase('AccountAlertPreferences')
export default class AccountAlertPreferences extends Component {
  static propTypes = {
    errors: PropTypes.object,
    user: PropTypes.object,
    resetAuthState: PropTypes.func,
  };

  static defaultProps = {
    isMyAccountPage: false,
    // primaryAlertEmail: null,
    errors: {},
    user: {
      flightMonitorEmailEnabled: null,
    },
  };

  constructor(props) {
    super(props);
    this.flightMonitorEmail = null;
    this.mobilePhoneNumber = null;
  }

  state = {
    phoneNumberValue: this.props.user.flightMonitorWirelessNumber0,
    validEmailStyle: null,
  };

  errorClass = shouldShow => (shouldShow ? 'selectError' : '');

  validateEmail = () => {
    this.props.resetAuthState();
    const emailInput = this.flightMonitorEmail;
    if (!isValidEmail(emailInput.value)) {
      this.setState({
        validEmailStyle: 'red',
      });
    } else {
      this.setState({
        validEmailStyle: null,
      });
    }
  };

  render() {
    const errors = this.props.errors || {};
    return (
      <div className='alert-preferences'>
        <div className='row'>
          <AccountError name='flightMonitorEmail' errorCode={errors.flightMonitorEmail} />
          <div className='col-xs-10 col-sm-9'>
            <h3 className='account-input-title'>Email</h3>
            <input
              type='text'
              ref={ref => (this.flightMonitorEmail = ref)}
              name='flightMonitorEmail'
              style={{ padding: '3px 3px 3px 5px', display: 'inline' }}
              defaultValue={this.props.user.flightMonitorEmailAddress0 || this.props.user.email}
              placeholder='name@domain.com'
              className={`account-input ${this.state.validEmailStyle} ${this.errorClass(errors.flightMonitorEmail)}`}
              onKeyUp={this.validateEmail}
            />
          </div>
        </div>
      </div>
    );
  }
}
