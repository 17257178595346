import React, { Component } from 'react';
import PropTypes from 'prop-types';
import componentBase from '../../../../../../../src/lib/component-base';
import IconHeader from '../../../../../../../shared/components/IconHeader/IconHeader';
import FSFlatButton from '../../../../../../../src/components/lib/FSFlatButton';
import { isBusinessUser } from '../../../../../../../src/utils/isBusinessUser';

@componentBase('SubscriptionStatus')
export default class SubscriptionStatus extends Component {
  static propTypes = {
    customSubscriptionIcon: PropTypes.string,
    defaultSubscriptionIcon: PropTypes.string,
    handleConfirmUnsubscribe: PropTypes.func,
    handleConfirmUpgrade: PropTypes.func,
    isSubscriptionActive: PropTypes.bool,
    canUpgrade: PropTypes.bool,
    renewalDate: PropTypes.string,
    subscriptionLevelText: PropTypes.string,
    user: PropTypes.object,
  };

  get icons() {
    if (this.props) {
      const {
        defaultSubscriptionIcon,
        customSubscriptionIcon,
      } = this.props;
      return {
        defaultSubscriptionIcon,
        customSubscriptionIcon,
      };
    }
    return {
      defaultSubscriptionIcon: '',
      customSubscriptionIcon: '',
    };
  }

  get renewalDate() {
    if (!this.props || !this.props.renewalDate) {
      return '';
    }
    return this.props.renewalDate;
  }

  render() {
    const iconHeaderContainerStyle = {
      padding: '0 15px',
      border: '3px solid rgb(250, 167, 24)',
      backgroundColor: 'rgba(250, 167, 24, .25)',
    };

    if (this.props.user.subscriptionLevel >= 3) {
      iconHeaderContainerStyle.border = '3px solid rgb(16, 50, 91)';
      iconHeaderContainerStyle.backgroundColor = 'rgba(23, 76, 140, .25)';
    }

    return (
      <div className='col-sm-10 col-md-6 subscription-status'>
        <IconHeader
          icon={this.icons.defaultSubscriptionIcon}
          title='Subscription Status'
        />
        <div className='row'>
          <div className='col-md-11 col-sm-9 subscription-status-block'>
            <p className='tier-text'>Subscription Tier</p>
            <div className='row'>
              <div className='content-container'>
                <IconHeader
                  icon={this.icons.customSubscriptionIcon}
                  title={this.props.subscriptionLevelText || ''}
                  containerStyle={iconHeaderContainerStyle}
                  iconStyle={{ width: '30px' }}
                  titleStyle={{ fontSize: '13pt' }}
                />
              </div>
              {this.props.canUpgrade ? <FSFlatButton
                style={FSFlatButton.style({ height: '42px' })}
                label='UPGRADE'
                primary
                className='button'
                onClick={this.props.handleConfirmUpgrade.bind(this)}
              /> : null}
            </div>
          </div>
        </div>
        {!isBusinessUser(this.props.user) && <div className='row'>
          <div className='col-md-11 col-sm-9 subscription-status-block' style={{ marginBottom: '20px' }}>
            <p className='renewal-text'>Next Monthly Renewal Date</p>
            <div>
              <div className='content-container'>
                <div className='renewal-date-container'>
                  <p className='renewal-date'>{this.renewalDate}</p>
                </div>
              </div>
              {this.props.isSubscriptionActive ? <FSFlatButton
                style={FSFlatButton.style({ height: '42px' })}
                label='UNSUBSCRIBE'
                backgroundColor='rgb(227, 70, 34)'
                hoverColor='rgb(199, 70, 34)'
                className='button unsubscribe-button'
                onClick={this.props.handleConfirmUnsubscribe.bind(this)}
              /> : null}
            </div>
          </div>
        </div>}
      </div>
    );
  }
}
