const has = Object.prototype.hasOwnProperty;

export default function mixin(behaviour) {
  const instanceKeys = Reflect.ownKeys(behaviour);
  const typeTag = Symbol('isa');

  function mixinCb(clazz) {
    for (const property of instanceKeys) {
      if (!has.call(clazz.prototype, property)) {
        Object.defineProperty(clazz.prototype, property, {
          value: behaviour[property],
          writable: true,
        });
      }
    }

    Object.defineProperty(clazz.prototype, typeTag, { value: true });
    return clazz;
  }

  Object.defineProperty(mixinCb, Symbol.hasInstance, {
    value: i => !!i[typeTag],
  });

  return mixinCb;
}
