import React from 'react';
import { Colors } from '../../constants/style-constants';
import componentBase from '../../lib/component-base';

const attributionsLinks = [
  { attrURL: 'http://www.openstreetmap.org/copyright', linkText: 'OpenStreetMap contributors' },
  { attrURL: 'http://onearth.jpl.nasa.gov/', linkText: 'NASA/JPL-Caltech' },
  { attrURL: 'http://www.openmapsurfer.uni-hd.de/contact.html', linkText: 'OpenMapSurfer' },
  { attrURL: 'http://www.fsa.usda.gov/FSA/apfoapp?area=home&subject=prog&topic=nai', linkText: 'U.S. Dept. of Agriculture, Farm Service Agency' },
  { attrURL: 'http://mapbox.com', linkText: 'Mapbox' },
  { attrURL: 'http://developer.geoiq.com/tools/acetate/', linkText: 'geoIQ' },
  { attrURL: 'http://www.naturalearthdata.com/', linkText: 'Natural Earth' },
  { attrURL: 'http://srtm.csi.cgiar.org/', linkText: 'CGIAR-CSI' },
];

// const linkStyle = { color: styleConstants.Colors.fsOrange, fontWeight: '400' };

@componentBase('MapAttributions')
export default class MapAttributions extends React.Component {
  renderLinks = () => attributionsLinks.map(l => (
    <li key={l.linkText}><a alt={l.attrURL} href={l.attrURL}>{l.linkText}</a></li>))

  render() {
    return (
      <div className='attr-component'>
        <h1 style={{ borderBottom: `4px solid ${Colors.fsOrange}`, fontWeight: 'bold', width: '100%' }}>Attribution, Credits, and Terms of Use</h1>
        <div className='map-attributions'>
          <p>The maps used in this application are copyrighted,
            and provided under Creative Commons Attribution-ShareAlike license CC-BY-SA
            (<a className='attr-link' href='http://creativecommons.org/licenses/by-sa/2.0/'>2.0</a> or
            <a className='attr-link' href='http://creativecommons.org/licenses/by-sa/3.0/'> 3.0</a>).
            <a className='attr-link' href='http://www.openstreetmap.org/'> Open Street Map </a>
            data is available under the
            <a className='attr-link' href='http://opendatacommons.org/licenses/odbl/'> Open Database License(ODbL).</a>
            &nbsp;You are free to make a copy of these maps and use them in your own applications
            under the same terms. But you are not allowed to use our servers or bandwidth to serve map
            tiles for your application. Note that these maps are only partial, and more complete maps
            are available from the sources listed below. Data and imagery used in these maps comes
            from one or more of the following sources:
          </p>
          <ul>
            {this.renderLinks()}
          </ul>
        </div>
      </div>
    );
  }
}
