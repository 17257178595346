import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import componentBase from '../../../../../src/lib/component-base';
import { prefersMDYDateFormat } from '../../../../../src/lib/date-time-format';
import FlightAlertsDetailHeader from '../../../../../shared/components/FlightAlertsDetailHeader/FlightAlertsDetailHeader';
import { Historical } from '../../../redux';

const { generateMonthlySummaries } = Historical.transformer;
const { getHistoricalFlightAlertDetails, checkHistoricalFlights } = Historical.actions;

@connect(state => ({
  alertDetails: state.FlightAlertHistory.detail,
  loaded: state.FlightAlertHistory.loaded,
  loading: state.FlightAlertHistory.loading,
}))

@componentBase('FlightAlertHistoryDetails')
export default class FlightAlertHistoryDetails extends React.Component {
  static propTypes = {
    alertDetails: PropTypes.object,
    loading: PropTypes.bool,
    match: PropTypes.object,
    dispatch: PropTypes.func,
  }

  componentDidMount() {
    const { match } = this.props;
    const { params } = match;
    const { wmaId } = params;
    this.props.dispatch(getHistoricalFlightAlertDetails(wmaId));

    const flightAlertHistoryDisplayName = 'FlightAlertHistory';
    const frozenState = { ...this.frozenState(this.context.store, flightAlertHistoryDisplayName) };

    // if Flight Alert History has been visited, there will be a frozen state for it
    if (!(Object.keys(frozenState).length === 0 && frozenState.constructor === Object)) {
      this.props.dispatch(checkHistoricalFlights())
        .then((res) => {
          const monthlySummaries = generateMonthlySummaries(res);
          frozenState.receiptCounts = monthlySummaries.receiptCounts;
          frozenState.filteredData = res;
          frozenState.rehydrated = true;
          this.freezeState(this.context.store, frozenState, flightAlertHistoryDisplayName);
        })
        .catch(() => {
          console.log('checking for historical flights failed');
        });
    }
  }

  get email() {
    const emails = this.props && this.props.alertDetails && this.props.alertDetails.emails;
    let emailsString = (emails && emails[0]) || this.context.currentUser.email;
    if (emails && emails.length > 1) {
      emailsString = emails.join(', ');
    }
    return emailsString;
  }

  renderEventRows = events => events.map((event, index) => {
    const alertDate = prefersMDYDateFormat(this.context.currentUser) ?
      event.dateMDY :
      event.dateDMY;
    const key = `k_${index}`;

    return (
      <div className='alert-history-detail-event-row row' key={key}>
        <div className='col-xs-4 col-sm-2'>
          <p>{alertDate}</p>
        </div>
        <div className='col-xs-4 col-md-3'>
          <p>{event.alert}</p>
        </div>
        <div className='hidden-xs col-sm-3'>
          <p>{event.deliveryPoint}</p>
        </div>
        <div className='col-xs-2' style={{ color: event.statusColor }}>
          <p>{event.status}</p>
        </div>
      </div>
    );
  });

  render() {
    // this.logger('props: ', this.props);
    if (this.props && this.props.alertDetails) {
      let messageIcon = null;

      if (this.props.alertDetails.messageType === 'Departure') {
        messageIcon = require('../../../../../static/images/flight-alerts/Default State/FAIcon-DepartureMessages.svg');
      } else if (this.props.alertDetails.messageType === 'Arrival') {
        messageIcon = require('../../../../../static/images/flight-alerts/Default State/FAIcon-ArrivalMessages.svg');
      } else if (this.props.alertDetails.messageType === 'Traveler') {
        messageIcon = require('../../../../../static/images/flight-alerts/Default State/FAIcon-TravelerMessages.svg');
      } else {
        messageIcon = require('../../../../../static/images/flight-alerts/Default State/FAIcon-AllMessages-ALT3.svg');
      }

      return (
        <div className='alert-history-details-container'>
          <div className='alert-history-details-header row'>
            <div className='col-xs-11 col-sm-7'>
              <h5 className='alert-history-details-title'>Flight Alert Details for {this.props.alertDetails.carrierCode}{this.props.alertDetails.flightNumber}</h5>
            </div>
            <div className='col-xs-11 col-sm-5'>
              <h5 className='alert-history-details-date'>{prefersMDYDateFormat(this.context.currentUser) ? this.props.alertDetails.departureDateMDY : this.props.alertDetails.departureDateDMY}</h5>
            </div>
          </div>
          <div className='alert-history-details-description'>
            <h5>Description</h5>
            <h3>{this.props.alertDetails.alertDescription}</h3>
          </div>
          <FlightAlertsDetailHeader flightInfo={this.props.alertDetails} />
          <div className='alert-history-details-row row'>
            <div className='alert-history-details-alert-type col-xs-12'>
              <p>Alert Type:</p>
              <div className='img-container'>
                <img alt={`${this.props.alertDetails.messageType} icon`} src={messageIcon} />
              </div>
              <p>{this.props.alertDetails.messageType} Messages</p>
            </div>
          </div>
          <div className='alert-history-details-event-table'>
            <p className='col-xs-12'>Message Sent Timeline</p>
            <p className='col-xs-12 hidden-sm hidden-md hidden-lg'>Messages sent to {this.email}</p>
            <div className='alert-history-detail-events-header row'>
              <div className='col-xs-4 col-sm-2'>
                <p className=''>Date (UTC)</p>
              </div>
              <div className='col-xs-4 col-md-3'>
                <p className=''>Alert</p>
              </div>
              <div className='hidden-xs col-sm-3'>
                <p className=''>Delivery Point</p>
              </div>
              <div className='col-xs-2'>
                <p className=''>Status</p>
              </div>
            </div>
            {this.renderEventRows(this.props.alertDetails.events)}
          </div>
        </div>
      );
    }

    if (!this.props.loading && !this.props.alertDetails) {
      return (
        <div>
          <p>Sorry, we could not find the alert</p>
        </div>
      );
    }

    return (
      <div />
    );
  }
}
