import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { barChart } from 'dc';
import { scale, time } from 'd3';
import { floorTimeToBinSize } from './_helpers';
import constants from './_constants';
import componentBase from '../../../../../../src/lib/component-base';
import onResize from '../../../../../../src/lib/on-resize';
import { timeFormatSelect } from '../../../../../../src/lib/date-time-format';

// disableTransitions = true;

// if (__CLIENT__) {
//   function getComponent() {
//     return import(/* webpackChunkName: "main" */ 'd3').then(results => {
//       console.log('omg works', results);
//    }).catch(error => console.log('E', error))
//   }
//
//   getComponent();
// }


@onResize()
@componentBase('Chart')
class Chart extends React.Component {
  static propTypes = {
    beginningOfDay: PropTypes.func,
    endOfDay: PropTypes.func,
    dimensions: PropTypes.object,
    onChartFiltered: PropTypes.func,
    filterSelectedValues: PropTypes.object,
    items: PropTypes.object,
    currentTimespanKey: PropTypes.string,
  };

  constructor(props, context) {
    super(props, context, 'Chart');
    this.chartWidth = 0;
  }

  componentDidMount() {
    this.chart = barChart(this.refs.chart);

    this.initResizeListener();
    this.handleResize();

    const bod = this.props.beginningOfDay();
    const eod = this.props.endOfDay();

    this.chart
      .height(constants.CHART_ROW_HEIGHT)
      .x(scale.linear().domain([bod, eod]))
      .brushOn(true)
      .gap(1)
      .centerBar(false)
      .round(d => floorTimeToBinSize(new Date(d)).getTime())
      .alwaysUseRounding(true)
      .xUnits(() => time.minutes(bod, eod, constants.BIN_SIZE_MINUTES))
      .title(d => `${moment(d.key).format('h:mma')}\nDelayed: ${d.value.delayedCount}\nCancelled: ${d.value.cancelledCount}\nOn Time: ${d.value.onTimeCount}`)
      .yAxisLabel('Flights')
      .dimension(this.props.dimensions.flightsByTimespan)
      .group(this.props.dimensions.group, 'On Time')
      .valueAccessor(d => d.value.onTimeCount)
      .stack(this.props.dimensions.group, 'Delayed', d => d.value.delayedCount)
      .stack(this.props.dimensions.group, 'Cancelled', d => d.value.cancelledCount)
      .on('filtered', (c, f) => {
        if (f) {
          const startTime = moment(f[0]);
          const endTime = moment(f[1]);
          this.props.onChartFiltered(startTime, endTime);
        }
      });

    const tickValues = [
      bod.toDate().getTime(),
      bod.clone().add(3, 'hours').toDate().getTime(),
      bod.clone().add(6, 'hours').toDate().getTime(),
      bod.clone().add(9, 'hours').toDate().getTime(),
      bod.clone().add(12, 'hours').toDate().getTime(),
      bod.clone().add(15, 'hours').toDate().getTime(),
      bod.clone().add(18, 'hours').toDate().getTime(),
      bod.clone().add(21, 'hours').toDate().getTime(),
      bod.clone().add(24, 'hours').toDate().getTime(),
    ];

    const timeFormatString = timeFormatSelect(this.context.currentUser, 'HH:mm', 'hA');
    this.chart.xAxis().tickValues(tickValues);
    this.chart.xAxis().tickFormat(v => moment(v).format(timeFormatString)); // .format('hA'));

    if (this.props.filterSelectedValues.timespan && this.props.filterSelectedValues.timespan !== this.props.currentTimespanKey && this.props.items.timespans[this.props.filterSelectedValues.timespan]) {
      this.props.items.timespans[this.props.filterSelectedValues.timespan].fnc();
    } else {
      this.props.items.timespans[this.props.currentTimespanKey].fnc();
    }
  }

  shouldComponentUpdate(newProps) {
    return (newProps.currentTimespanKey !== this.props.currentTimespanKey) || newProps.chartWasFiltered;
  }

  componentWillUnmount() {
    this.removeResizeListener();
  }

  handleResize = () => {
    if (this.refs.wrapper) {
      // if (this.mediaBreakpoint.name === 'tablet' || this.mediaBreakpoint.name === 'mobile') {
      this.chartWidth = this.refs.wrapper.clientWidth;
      // }
      // else {
      //  this.chartWidth = this.refs.wrapper.clientWidth * (2/3);
      // }
      this.chart.width(this.chartWidth);
      this.forceUpdate();
    }
  };

  _filter = (arg) => {
    this.chart.filter(arg);
  };

  render() {
    if (this.chart) this.chart.render();
    return (
      <div ref='wrapper' className='col-md-12' style={{ width: '100%' }}>
        <div ref='chart' style={{ width: '100%', height: `${constants.CHART_ROW_HEIGHT}px` }} />
      </div>
    );
  }
}

export default Chart;
