import React from 'react';
import PropTypes from 'prop-types';
import componentBase from '../../../../../../src/lib/component-base';
import ToggleSwitch from '../../../../../../src/components/lib/ToggleSwitch';

@componentBase('OnTimePerformance')
class OnTimePerformance extends React.Component {
  static propTypes = {
    items: PropTypes.object,
    currentTimespanKey: PropTypes.string,
  }

  constructor(props, context) {
    super(props, context, 'OnTimePerformance');

    this.state = {
      otpNumbersDisplay: 'none',
      otpPercentsDisplay: 'block',
    };
  }

  _handleOTPToggle = () => {
    if (this.refs.otpToggle) {
      this.setState({
        otpNumbersDisplay: this.refs.otpToggle.value === 'numbers' ? 'block' : 'none',
        otpPercentsDisplay: this.refs.otpToggle.value === 'numbers' ? 'none' : 'block',
      });
    }
  };

  render() {
    let onTimeFlights = 0;
    let delayedFlights = 0;
    let cancelledFlights = 0;
    let totalNonCodeShareFlights = 0;

    for (const f in this.props.items.flights) {
      // this.logger('inChart', this.props.items.flights[f].inChart);
      if (this.props.items.flights[f].inChart && this.props.items.timespans[this.props.currentTimespanKey].rangeFilter.isFiltered(this.props.items.flights[f].timeBin)) {
        totalNonCodeShareFlights++;
        if (this.props.items.flights[f].rowColor === 'green') {
          onTimeFlights++;
        } else if (this.props.items.flights[f].rowColor === 'yellow') {
          delayedFlights++;
        } else if (this.props.items.flights[f].status === 'Cancelled') {
          cancelledFlights++;
        }
      }
    }

    const onTimePercentage = totalNonCodeShareFlights ? (onTimeFlights / totalNonCodeShareFlights * 100.0).toFixed(1) : 0.0;
    const delayedPercentage = totalNonCodeShareFlights ? (delayedFlights / totalNonCodeShareFlights * 100.0).toFixed(1) : 0.0;
    const cancelledPercentage = totalNonCodeShareFlights ? (cancelledFlights / totalNonCodeShareFlights * 100.0).toFixed(1) : 0.0;
    // const otpStyle = constants.CHART_ROW_HEIGHT ? { height: `${constants.CHART_ROW_HEIGHT - otpPadding}px`, paddingTop: `${otpPadding}px` } : {};

    const toggleTool = (
      <div className='row'>
        <div className='col-xs-5 right-align'>
          <em>Actual Flights</em>
        </div>
        <div className='col-xs-2 center-align right-spaced'>
          <ToggleSwitch
            name='otpToggle'
            ref='otpToggle'
            onValue='percentages'
            offValue='numbers'
            callback={this._handleOTPToggle}
            on
          />
        </div>
        <div className='col-xs-4 mobile-justification'>
          <em>Percentages</em>
        </div>
      </div>
    );

    return (
      <div className='otp-block'>
        <h4 className='otp-header'>On-Time Performance Stats</h4>
        <div style={{ padding: '0 10px' }}>
          <div className='otp-percentage-block'>
            <div style={{ display: this.state.otpPercentsDisplay }}>
              <div className='hidden-xs otp-percentage-right'>
                {onTimePercentage}<span className='otp-percentage-percent'>%</span>
              </div>
              <div className='visible-xs otp-percentage'>
                {onTimePercentage}<span className='otp-percentage-percent'>%</span>
              </div>
              <div className='otp-percentage-title' style={{ color: '#6FFF00' }}>
                On-Time
              </div>
            </div>
            <div style={{ display: this.state.otpNumbersDisplay }}>
              <div className='hidden-xs otp-percentage-right'>
                {onTimeFlights}
              </div>
              <div className='visible-xs otp-percentage'>
                {onTimeFlights}
              </div>
              <div className='otp-percentage-title' style={{ color: '#6FFF00' }}>
                On-Time
              </div>
            </div>
          </div>
          <div className='otp-percentage-block'>
            <div style={{ display: this.state.otpPercentsDisplay }}>
              <div className='hidden-xs otp-percentage-right'>
                {delayedPercentage}<span className='otp-percentage-percent'>%</span>
              </div>
              <div className='visible-xs otp-percentage'>
                {delayedPercentage}<span className='otp-percentage-percent'>%</span>
              </div>
              <div className='otp-percentage-title' style={{ color: '#FFFF00' }}>
                Delayed
              </div>
            </div>
            <div style={{ display: this.state.otpNumbersDisplay }}>
              <div className='hidden-xs otp-percentage-right'>
                {delayedFlights}
              </div>
              <div className='visible-xs otp-percentage'>
                {delayedFlights}
              </div>
              <div className='otp-percentage-title' style={{ color: '#FFFF00' }}>
                Delayed
              </div>
            </div>
          </div>
          <div className='otp-percentage-block'>
            <div style={{ display: this.state.otpPercentsDisplay }}>
              <div className='hidden-xs otp-percentage-right'>
                {cancelledPercentage}<span className='otp-percentage-percent'>%</span>
              </div>
              <div className='visible-xs otp-percentage'>
                {cancelledPercentage}<span className='otp-percentage-percent'>%</span>
              </div>
              <div className='otp-percentage-title' style={{ color: 'red' }}>
                Cancelled
              </div>
            </div>
            <div style={{ display: this.state.otpNumbersDisplay }}>
              <div className='hidden-xs otp-percentage-right'>
                {cancelledFlights}
              </div>
              <div className='visible-xs otp-percentage'>
                {cancelledFlights}
              </div>
              <div className='otp-percentage-title' style={{ color: 'red' }}>
                Cancelled
              </div>
            </div>
          </div>
          <div className='row' style={{ padding: '10px 5px 0 0' }}>
            <div className='col-sm-12 col-md-8'>
              <div className='otp-toggle-container' style={{ overflow: 'hidden' }}>
                { toggleTool }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OnTimePerformance;
