import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ConfirmModal from './ConfirmModal';
import UpgradeSubscriptionModalContainer from './UpgradeSubscriptionModalContainer';
import { NoSessionModal } from './NoSessionModal';

const MODAL_COMPONENTS = {
  CONFIRM: ConfirmModal,
  UPGRADE_SUBSCRIPTION: UpgradeSubscriptionModalContainer,
  NO_SESSION: NoSessionModal,
};

@connect(state => state.Modal)
export default class RootModal extends Component {
  static propTypes = {
    modalType: PropTypes.string,
    modalProps: PropTypes.object,
  };

  render() {
    if (!this.props.modalType) {
      return null; // as of React v15 you can return null here
    }

    const SpecificModal = MODAL_COMPONENTS[this.props.modalType];
    if (!SpecificModal) return null;
    return <SpecificModal {...this.props.modalProps} />;
  }
}
