import React from 'react';
import { FeatureDot, FeatureSubText } from '../components/shared/SubscriptionFeatureChart/ChartCell';

export const getChartItemChildren = (feature) => {
  const {
    standardText,
    proText,
  } = feature;
  return ['free', 'standard', 'pro']
    .map((tier, index) => {
      if (feature[`${tier}Feature`]) {
        const customClassName = !!standardText || !!proText ? 'with-subtitle' : '';
        const key = `${tier}-${index}`;
        const dotChild = (
          <FeatureDot
            tier={tier}
            key={key}
            customClassName={customClassName}
          />
        );

        const text = tier === 'standard' ? standardText : proText;
        let textChild = null;
        if (text) {
          const k = `${tier}-${index}-text`;
          textChild = (
            <FeatureSubText
              subText={text}
              key={k}
            />
          );
        }
        return [dotChild, textChild];
      }
      return null;
    });
};
