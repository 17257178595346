import constants from './style.json';

const { Colors, Spacing } = constants;

const breakpoints = {
  mobile: 500,
  tablet: 768,
  desktopSm: 980,
  desktopLg: 1200,
};

export {
  Colors,
  Spacing,
  breakpoints,
};
