import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { dateFormatString } from '../../../../../../../src/lib/date-time-format';
import { openTab } from '../../../../../../../src/utils/openTab';

const expectedKeys = {
  referenceNumber: '',
  purchaseDate: '',
  amountCharged: '0.00',
  description: '',
};

const ViewPaymentHistory = (props) => {
  const dedupe = (paymentHistories) => {
    const referenceNumberHashTable = {};
    return paymentHistories.filter((paymentHistory) => {
      if (referenceNumberHashTable[paymentHistory.braintreeId]) {
        // not going to render something twice
        return false;
      }
      // update hash table
      referenceNumberHashTable[paymentHistory.braintreeId] = true;
      return true;
    });
  };

  const handleMissingKeys = obj => (
    { ...expectedKeys, ...obj }
  );

  const formatPurchaseDate = (date) => {
    if (!date) {
      return '--';
    }
    return moment(date).format(dateFormatString(props.user));
  };

  const formatKinds = (kind) => {
    let description = kind;
    if (kind === 'subscription_charged_successfully') {
      description = 'Monthly Subscription';
    } else if (kind.toLowerCase().indexOf('historical flight status by') > -1) {
      description = `Data Export - ${kind}`;
    } else if (!kind.includes('credits')) {
      description = `${kind} credits`;
    }
    return description;
  };

  const renderInvoiceChild = item => (
    <li className={`invoice-item-group invoice-item-${item.color}`} >
      <div className='row'>
        <div className='col-xs-3 invoice-item-reference-number'>
          <p>{item.referenceNumber}</p>
        </div>
        <div className='col-xs-3 invoice-item-date'>
          <p>{item.purchaseDate}</p>
        </div>
        <div className='col-xs-3 invoice-item-amount'>
          <p>{`${item.amountCharged} USD`}</p>
        </div>
        <div className='col-xs-3 invoice-item-description'>
          <p>{item.description.replace(/,/, '').replace(/(^| )(\w)/g, x => x.toUpperCase())}</p>
        </div>
      </div>
    </li>
  );

  const renderInvoiceItem = item => (
    <div
      key={item.key}
      onClick={(e) => {
        const { link } = item;
        if (link) {
          openTab(link, e);
        }
      }
      }
    >
      {renderInvoiceChild(item)}
    </div>
  );

  const paymentHistories = (invoiceItems) => {
    if (invoiceItems && invoiceItems.length) {
      const newestOnTopItems = [...invoiceItems].reverse();
      return dedupe(newestOnTopItems).map((item, index) => {
        const {
          amount,
          braintreeId,
          createdAt,
          kind,
        } = item;
        const obj = handleMissingKeys({
          referenceNumber: braintreeId,
          amountCharged: amount,
          description: formatKinds(kind),
          key: braintreeId,
          purchaseDate: formatPurchaseDate(createdAt),
          color: (index % 2 === 0 ? 'light-blue-row' : 'neutral-row'),
          link: (!braintreeId ? '/v2/my-account/payment' : `/v2/account/invoice/${braintreeId}`),
        });
        return renderInvoiceItem(obj);
      });
    }
    return (
      <div className='no-history'>
        <p>No Payment History to display</p>
      </div>
    );
  };

  const renderHeaderRow = () => (
    <div className='row invoice-item-header' key='unique-header-key'>
      <div className='col-xs-3 invoice-item-reference-number'>
        <p>Reference Number</p>
      </div>
      <div className='col-xs-3 invoice-item-date'>
        <p>Purchase Date</p>
      </div>
      <div className='col-xs-3 invoice-item-amount'>
        <p>Amount Charged</p>
      </div>
      <div className='col-xs-3 invoice-item-description'>
        <p>Description</p>
      </div>
    </div>
  );

  return (
    <section className='payment-history'>
      <div className='payment-history-inner-container'>
        <div className='payment-history-title-header payment-title billing'>
          <div className='payment-history-icon-container icon payment-icon' >
            <img src={props.icon} className='image' alt='icon' />
          </div>
          <h2>View Payment History</h2>
        </div>
        <p>Click on a row below to get a printable receipt</p>
        {renderHeaderRow()}
        <ul className='payment-history-items'>
          {paymentHistories(props.invoiceItems)}
        </ul>
      </div>
    </section>
  );
};

ViewPaymentHistory.propTypes = {
  invoiceItems: PropTypes.array,
  icon: PropTypes.string,
  user: PropTypes.object,
};

export default ViewPaymentHistory;
