import React from 'react';
import PropTypes from 'prop-types';
import componentBase from '../../../../../../src/lib/component-base';
import { prefersCTempFormat } from '../../../../../../src/lib/unit-measurement-format';

@componentBase('WeatherSkyConditions')
export default class WeatherSkyConditions extends React.Component {
  static propTypes = {
    icon: PropTypes.string,
    coverage: PropTypes.string,
    tempF: PropTypes.number,
    tempC: PropTypes.number,
  };

  constructor(props, context) {
    super(props, context);

    this.muiDefaultProps = {
      topStyle: {
        padding: '0',
        height: 'inherit',
        background: 'rgba(255, 255, 255, .1)',
      },
    };
  }

  _getIconUrl() {
    if (this.props.icon) {
      return this.context.images.weatherIcons[this.props.icon];
    }

    return null;
  }

  _getCoverage() {
    if (this.props.coverage) {
      return this.props.coverage;
    }

    return null;
  }

  _getTempF() {
    if (this.props.tempF || this.props.tempF === 0) {
      return this.props.tempF;
    }

    return '--';
  }

  _getTempC() {
    if (this.props.tempC || this.props.tempC === 0) {
      return this.props.tempC;
    }

    return '--';
  }

  render() {
    const weatherIconUrl = this._getIconUrl();
    const coverageDesc = this._getCoverage();

    const mainTemperature = { temp: this._getTempF(), format: 'F' };
    const subTemperature = { temp: this._getTempC(), format: 'C' };

    if (prefersCTempFormat(this.context.currentUser)) {
      mainTemperature.temp = this._getTempC();
      mainTemperature.format = 'C';
      subTemperature.temp = this._getTempF();
      subTemperature.format = 'F';
    }

    // weather icon div
    let weatherIconDivHeight = (coverageDesc) ? '30%' : '40%';
    let weatherIconImg = null;
    if (weatherIconUrl) {
      weatherIconDivHeight = '55%';
      weatherIconImg = (
        <img src={weatherIconUrl} className='airport-weather-icon' alt={this.props.icon || 'icon'} />
      );
    }
    const weatherIconDiv = (
      <div className='col-xs-12 col-sm-12 col-md-12' style={{ padding: '0', height: weatherIconDivHeight }}>
        <div
          className='col-xs-12 col-sm-12 col-md-12'
          style={{
 position: 'absolute', padding: '0', bottom: '-5px', textAlign: 'center',
}}
        >
          { weatherIconImg }
        </div>
      </div>
    );

    // coverage description div
    let coverageDiv = null;
    if (coverageDesc) {
      coverageDiv = (
        <div className='col-xs-12 col-sm-12 col-md-12' style={{ padding: '0', height: '15%' }}>
          <div
            className='col-xs-12 col-sm-12 col-md-12'
            style={{
 position: 'absolute', padding: '0', top: '0', textAlign: 'center',
}}
          >
            <span style={{ fontSize: '8pt' }}>{ coverageDesc }</span>
          </div>
        </div>
      );
    }

    // temperature div
    const tempHeight = (coverageDesc) ? '30%' : '45%';
    const tempDiv = (
      <div className='col-xs-12 col-sm-12 col-md-12' style={{ padding: '0', height: tempHeight }}>
        <div
          className='col-xs-12 col-sm-12 col-md-12'
          style={{
 position: 'absolute', padding: '0', top: '0', textAlign: 'center',
}}
        >
          <span style={{ fontSize: '20pt', fontWeight: 'bold' }}>{ mainTemperature.temp }&deg;{ mainTemperature.format }</span>
          <span style={{ fontSize: '8pt', paddingLeft: '2px' }}>({ subTemperature.temp }&deg;{ subTemperature.format })</span>
        </div>
      </div>
    );

    return (
      <div className='row' style={this.muiDefaultProps.topStyle}>
        { weatherIconDiv }
        { coverageDiv }
        { tempDiv }
      </div>
    );
  }
}
