import React, { Component } from 'react';
import FSFlatButton from './FSFlatButton';
import componentBase from '../../lib/component-base';

@componentBase('FourOhFour')
export default class FourOhFour extends Component {
  render() {
    const containerStyle = {
      textAlign: 'center',
    };

    const contentStyle = {
      paddingTop: '200px',
    };

    return (
      <div style={containerStyle}>
        <div className='fourOhFourCallout'>
          <div className='row'>
            <div className='col-xs-12'>
              <div style={contentStyle}>
                <img src={this.context.images.dangerZoneThumb} alt='Page not found' />
                <h1 className='dark-text'>Page not found</h1>
                <h4>
                  <a href='/'>
                    <FSFlatButton
                      label='Home'
                      style={FSFlatButton.style({ margin: null, minWidth: null })}
                      labelStyle={{ verticalAlign: 'top' }}
                    />
                  </a>
                </h4>
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}
