import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import moment from 'moment';
import { connect } from 'react-redux';
import { push, go } from 'connected-react-router';
import { createConfirmation } from 'react-confirm';
import { F, both, equals, propOr, pathOr, or, ifElse, gt } from 'ramda';
import { Current } from '../../redux';
import { getProfile, endSpinner } from '../../../Account/redux/actions';
import { catchErrors } from '../../../Account/redux/selectors';
import FlightAlertsHeader from './children/FlightAlertsHeader/FlightAlertsHeader';
import FlightAlertsConsole from './children/FlightAlertsConsole/FlightAlertsConsole';
import UpcomingFlightAlerts from './children/UpcomingFlightAlerts/UpcomingFlightAlerts';
import componentBase from '../../../../src/lib/component-base';
import { dateFormatString } from '../../../../src/lib/date-time-format';
import { errorMessageForCode } from '../../../../src/lib/errors';
import ConfirmModalDialog from '../shared/ConfirmModalDialog';
import TestAlertModalDialog from '../shared/TestAlertModalDialog';
import { isBusinessUser } from '../../../../src/utils/isBusinessUser';
import { flightNumberInt } from '../../../../src/utils/validation';
import { addMetaCanonical } from '../../../../shared/lib/meta-tags';

const { getSortedAlerts } = Current.selectors;
const getFlightsMonitored = pathOr(0, ['userHistoryData', 'flightsMonitored']);
const getReserveCredits = propOr(0, 'flightAlertsReservedCredits');
const getMonthlyCredits = propOr(0, 'flightAlertsMonthlyCredits');
const {
  testAlert,
  getCurrentFlightAlertsForUser,
  getCurrentServerDate,
  deactivateFlightAlert,
  reactivateFlightAlert,
  deleteFlightAlert,
  setSortColumnAndOrder,
  loadUserAlertHistory,
  checkFlightSchedulesByCarrierFlightNumberAndDate,
  errorCheckingSchedulesBool,
  setSecondaryActionBool,
  setQueryError,
} = Current.actions;

const confirm = __CLIENT__ && createConfirmation(ConfirmModalDialog);
const testAlertModal = __CLIENT__ && createConfirmation(TestAlertModalDialog);

@connect(state => ({
  flightAlerts: getSortedAlerts(state),
  flightAlertsError: state.FlightAlerts.flightAlertsError,
  flightsMonitored: state.FlightAlerts.flightsMonitored,
  deleteFlightAlertError: state.FlightAlerts.deleteFlightAlertError,
  deactivateError: state.FlightAlerts.deactivateError,
  checkFlightsResult: state.FlightAlerts.checkFlightsResult,
  checkFlightsLoaded: state.FlightAlerts.checkFlightsLoaded,
  locationBeforeTransitions: state.router.locationBeforeTransitions,
  reactivateError: state.FlightAlerts.reactivateError,
  userHistoryError: state.FlightAlerts.userHistoryError,
  userHistoryLoaded: state.FlightAlerts.userHistoryLoaded,
  userHistoryData: state.FlightAlerts.userHistoryResult,
  newAlertSuccess: state.FlightAlerts.newAlertSuccess,
  newAlertError: state.FlightAlerts.newAlertError,
  currentDate: state.FlightAlerts.currentDateResult,
  schedulesResultsValidationError: state.FlightAlerts.checkingSchedulesResultError,
  couldNotDestroyError: state.FlightAlerts.couldNotDestroyError,
  queryError: state.FlightAlerts.queryError,
  secondaryAction: state.FlightAlerts.secondaryAction,
  user: state.Account.user,
}))

@componentBase('FlightAlerts')
export default class FlightAlerts extends React.Component {
  static propTypes = {
    couldNotDestroyError: PropTypes.string,
    currentDate: PropTypes.string,
    deactivateError: PropTypes.object,
    deleteFlightAlertError: PropTypes.object,
    dispatch: PropTypes.func,
    flightAlerts: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    flightAlertsError: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    flightsMonitored: PropTypes.number,
    frozenState: PropTypes.object,
    location: PropTypes.object,
    locationBeforeTransitions: PropTypes.object,
    match: PropTypes.object,
    newAlertError: PropTypes.string,
    newAlertSuccess: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    reactivateError: PropTypes.object,
    secondaryAction: PropTypes.bool,
    schedulesResultsValidationError: PropTypes.bool,
    style: PropTypes.object,
    queryError: PropTypes.string,
    user: PropTypes.object,
    userHistoryData: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);

    const { user } = props;
    const creditsMonthlyAllowance = this.formatCredits(user, 'flightAlertsMonthlyCredits');
    const reserveCredits = this.formatCredits(user, 'flightAlertsReservedCredits');
    const flightsMonitored = getFlightsMonitored(props);
    const hasUnlimitedFlertsCredits = isBusinessUser(user);

    this.state = {
      buttonDisabled: false,
      date: moment().format(dateFormatString(user)),
      errorOnSubmit: false,
      errorMessages: [],
      sortOrderAscending: true,
      currentSortColumn: 'DATE',
      // for adding a new flight alert
      searchDate: moment(),
      flightNumber: '',
      airline: this.initialAirline,
      error: null,
      buyCreditsModalOpen: false,
      creditsMonthlyAllowance,
      reserveCredits,
      flightsMonitored,
      buyFlertsModalButtonDisabled: false,
      hasUnlimitedFlertsCredits,
      secondaryAction: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDateChange = this.dateChangeHandler.bind(this);
    this.handleFlightNumberChange = this.handleFlightNumberChange.bind(this);
    this.handleAirlineChange = this.handleAirlineChange.bind(this);
    this.openOrCloseBuyCreditsModal = this.openOrCloseBuyCreditsModal.bind(this);
  }

  componentWillMount() {
    const update = { currentDate: this.props.currentDate };

    this.setState({
      ...this.state,
      ...update,
    });
  }

  componentDidMount() {
    const {
      schedulesResultsValidationError,
    } = this.props;

    if (!schedulesResultsValidationError) {
      this.props.dispatch(getCurrentFlightAlertsForUser());
      this.props.dispatch(getCurrentServerDate());
      this.props.dispatch(loadUserAlertHistory(this.props.user.id));
      this.props.dispatch(getProfile());
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      flightAlertsError,
      deleteFlightAlertError,
      deactivateError,
      reactivateError,
      newAlertSuccess,
      newAlertError,
      couldNotDestroyError,
      flightAlerts,
      user,
    } = nextProps;

    const newState = {
      errorOnSubmit: false,
      errorMessages: [],
    };
    // when a flight alert is deleted
    if (flightAlerts.length < this.props.flightAlerts.length) {
      newState.flightsMonitored = this.state.flightsMonitored - 1;
    }

    const nextFlightsMonitored = getFlightsMonitored(nextProps);

    if (nextFlightsMonitored !== getFlightsMonitored(this.props)) {
      newState.flightsMonitored = nextFlightsMonitored;
    }

    const currentReserveCredits = getReserveCredits(user);
    const prevReserveCredits = getReserveCredits(this.props.user);
    const currentMonthlyCredits = getMonthlyCredits(user);
    const prevMonthlyCredits = getMonthlyCredits(this.props.user);


    const handleUpdatedProfile = ifElse(
      or(),
      () => {
        newState.creditsMonthlyAllowance = this.formatCredits(user, 'flightAlertsMonthlyCredits');
        newState.reserveCredits = this.formatCredits(user, 'flightAlertsReservedCredits');
      },
      () => false,
    );

    handleUpdatedProfile(
      equals(currentReserveCredits, prevReserveCredits),
      equals(currentMonthlyCredits, prevMonthlyCredits),
    );

    if (couldNotDestroyError) {
      newState.errorOnSubmit = true;
      newState.errorMessages.push('We could not delete that alert as messages have already been sent to your inbox, we deactivated it and no further messages will be sent unless you manually reactivate it.');
    }

    if (flightAlertsError && !newAlertSuccess) {
      newState.errorOnSubmit = true;
      newState.errorMessages.push('There was an error retrieving your flight alerts. Please try again.');
    }

    if (flightAlertsError && newAlertSuccess) {
      newState.errorOnSubmit = true;
      newState.errorMessages.push('This page is out of date. Please manually refresh your browser.');
    }

    if (newAlertError) {
      newState.errorOnSubmit = true;
      newState.errorMessages.push('There was an error saving that flight alert. Please try again.');
    }

    if (deleteFlightAlertError) {
      newState.errorOnSubmit = true;
      newState.errorMessages.push('There was an error deleting that record. Please try again.');
    }

    if (deactivateError) {
      newState.errorOnSubmit = true;
      newState.errorMessages.push('There was an error deactivating that record. Please try again.');
    }

    if (reactivateError) {
      newState.errorOnSubmit = true;
      newState.errorMessages.push('There was an error reactivating that record. Please try again.');
    }

    const getCheckFlightsRes = propOr([], 'checkFlightsResult');
    const prevCheckFlightsRes = getCheckFlightsRes(this.props);
    const nextCheckFlightsRes = getCheckFlightsRes(nextProps);
    const conditions = prevCheckFlightsRes && nextCheckFlightsRes ? both(
      () => gt(nextCheckFlightsRes.length, 0),
      () => !equals(nextCheckFlightsRes.length, prevCheckFlightsRes.length),
    ) : F;

    if (conditions()) {
      const url = this.buildUrl(this.state);
      if (this.allFlightsHaveInvalidState(nextCheckFlightsRes, 'isLandedFlight')) {
        // all flights landed, nothing to track
        newState.queryError = 'The flight\'s status does not allow it to be monitored.';
        this.handleCheckingSchedulesError('/flight-alerts/home', 'FLIGHT_ALERT_ERROR2');
      } else if (this.allFlightsHaveInvalidState(nextCheckFlightsRes, 'isCancelledFlight')) {
        // all flights are cancelled, nothing to track
        newState.queryError = 'One or more of our data sources indicate this flight has been cancelled.';
        this.handleCheckingSchedulesError('/flight-alerts/home', 'FLIGHT_ALERT_ERROR');
      } else if (nextCheckFlightsRes.length === 1) {
        // there is one segment available, so it is automatically selected
        const selectedSegment = nextCheckFlightsRes[0];

        if (selectedSegment.isCancelledFlight) {
          newState.queryError = 'The flight\'s status of cancelled does not allow it to be monitored.';
          this.handleCheckingSchedulesError('/flight-alerts/home', 'FLIGHT_ALERT_ERROR3');
        } else if (!selectedSegment.isLandedFlight) {
          const { departureAirportFsCode, arrivalAirportFsCode } = selectedSegment;
          this.props.dispatch(push(`${url}/${departureAirportFsCode}/${arrivalAirportFsCode}`));
        }
      } else if (nextCheckFlightsRes.length) { // results came back
        this.props.dispatch(push(url));
      }
    }

    this.setState({
      ...this.state,
      ...newState,
    }, () => {
      if (newState.queryError) {
        this.props.dispatch(setQueryError(newState.queryError));
      }
    });
  }

  componentWillUnmount() {
    if (this.props.schedulesResultsValidationError) {
      this.props.dispatch(errorCheckingSchedulesBool(false));
    }
    // if we got here from the flight tracker page and the back button creates a
    // 'POP' event and the form has already been used,
    // go back to the flight tracker instead of an already submitted form
    const {
      locationBeforeTransitions,
      match,
    } = this.props;

    const { params } = match;

    if (params.trackerReferral) {
      if (locationBeforeTransitions &&
          locationBeforeTransitions.action &&
          locationBeforeTransitions.action === 'POP') {
        this.props.dispatch(go(-2));
      }
    }

    this.props.dispatch(setQueryError(''));
  }

  get initialAirline() {
    let airline = null;
    if (this.props.frozenState && this.props.frozenState.airline) {
      airline = this.props.frozenState.airline;
    } else if (this.props.user && this.props.user.airlineCode) {
      const code = this.props.user.airlineCode;
      airline = code && { _id: code };
    }
    return airline;
  }

  get history() {
    let userHistory = {
      alertsSent: 0,
      issuesCount: 0,
    };
    if (this.props && this.props.userHistoryData) {
      const {
        alertsSent,
        issuesCount,
      } = this.props.userHistoryData;
      userHistory = {
        alertsSent: alertsSent || 0,
        issuesCount: issuesCount || 0,
      };
    }
    return userHistory;
  }

  getMetaTags = () => {
    const title = 'Flight Alerts';
    const description = 'Stay informed by allowing FlightStats to monitor your flight and notify you via email about any expected delays, cancellation or other issues';
    const keywords = 'flight alerts, flight notification';

    const metaWithoutCanonical = {
      title,
      meta: [
        { name: 'keywords', content: keywords },
        { name: 'description', content: description },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
      ],
    };
    const { location } = this.props;
    const pathname = location && location.pathname;
    return addMetaCanonical(metaWithoutCanonical, pathname);
  }

  setSecondaryActionBool = bool => this.props.dispatch(setSecondaryActionBool(bool));

  dateChangeHandler = (searchDate) => {
    const update = { searchDate };

    this.setState({
      ...this.state,
      ...update,
    });
  }

  handleAirlineChange = (update, triggeredByEnter) => {
    this.setState({
      ...this.state,
      ...update,
    }, () => {
      this.props.dispatch(setSecondaryActionBool(triggeredByEnter));
    });
  };

  handleFlightNumberChange = (e) => {
    const update = { flightNumber: e.target.value };

    this.setState({
      ...this.state,
      ...update,
    });
  }

  handleCheckingSchedulesError = (urlToAppend, errorCode) => {
    const { dispatch } = this.props;
    const queryError = errorMessageForCode(errorCode);

    dispatch(setQueryError(queryError));
    dispatch(push(urlToAppend));
    dispatch((errorCheckingSchedulesBool(true)));
  }

  handleNewFlightAlert = (newAlert) => {
    /* eslint-disable no-underscore-dangle */
    const carrier = newAlert.airline._id;
    /* eslint-enable no-underscore-dangle */
    const flightNumber = flightNumberInt(newAlert.flightNumber);
    const year = newAlert.searchDate.year();
    const month = newAlert.searchDate.month() + 1;
    const day = newAlert.searchDate.date();
    const params = {
      carrier, flightNumber, year, month, day,
    };

    this.props.dispatch(checkFlightSchedulesByCarrierFlightNumberAndDate(params));
  }

  handleFakeTest = (i, flexId) => {
    if (flexId) {
      testAlertModal({ title: 'What alert do you want to test?', options: { hideText: true } })
        .then(
          (resultOk) => {
            this.props.dispatch(testAlert(i, flexId, resultOk.button));
          },
          () => false,
        );
    }
  }

  handleDeactivationReactivation = (wmaId, index) => {
    if ((this.props.flightAlerts[index].status !== 2) &&
      (this.props.flightAlerts[index].status !== 5)) {
      return confirm({
        title: 'Are you sure want to deactivate monitoring of this flight?',
        confirmation: `Deactivating this alert rule will stop all future messaging for this flight.
        Are you sure you want to deactivate?`,
        options: { hideText: false },
      })
        .then(
          () => {
            this.context.reportUserEvent('ManageFlightAlert', 'Deactivate');
            this.props.dispatch(deactivateFlightAlert(wmaId, index, this.props.flightAlerts));
          },
          () => false,
        );
    }
    return confirm({ title: 'You will not recieve messages that occurred while this flight was deactivated.', confirmation: 'Reactivating this alert rule will turn back on monitoring for this flight and you will receive messages for events that occur going forward. However, you will not receive any messages that would have normally been sent while it was deactivated. Reactivate?', options: { hideText: false } })
      .then(() => {
        this.context.reportUserEvent('ManageFlightAlert', 'Reactivate');
        this.props.dispatch(reactivateFlightAlert(wmaId, index, this.props.flightAlerts));
      }, () => false);
    // TODO: add validation for non-null and error catching
  }

  handleAlertDeletion = (options, index) => confirm({ title: 'Are you sure you want to stop monitoring this flight?', options: { hideText: false } })
    .then(() => {
      this.props.dispatch(deleteFlightAlert(options, this.props.flightAlerts, index))
        .then(() => this.props.dispatch(getProfile()));
    });

  formatCredits = (user, credits) => {
    if (user) {
      return (isBusinessUser(user) ? Infinity : `${user[credits] || 0}`);
    }
    return '0';
  }

  handleSubmit = () => {
    this.setState({
      buttonDisabled: true,
    });
    if (this.history && this.history.reserveCredits === 0) {
      this.openOrCloseBuyCreditsModal.call(this, true);
    } else if (this.stateIsValid()) {
      // good to go
      this.handleNewFlightAlert(this.state);
    }
    this.setState({
      buttonDisabled: false,
    }, () => {
      this.props.dispatch(setSecondaryActionBool(false));
    });
    this.context.reportButtonPress('New Flight Alert');
  }

  openOrCloseBuyCreditsModal = (bool, fetchProfile = false) => {
    const { dispatch } = this.props;
    if (fetchProfile === true) {
      dispatch(getProfile())
        .then(() => this.setState({
          ...this.state,
          buyCreditsModalOpen: false,
          buttonDisabled: false,
        }))
        .catch((err) => {
          this.setState({ buyCreditsModalOpen: false, buttonDisabled: false }, () => {
            console.error(catchErrors(err), 'openOrCloseBuyCreditsModal-purchase');
            this.context.toast.error(catchErrors(err), 'Error', false, 3);
          });
        });
    } else {
      this.setState({
        buyCreditsModalOpen: bool,
        buttonDisabled: bool,
      });
    }
    dispatch(endSpinner());
  }

  allFlightsHaveInvalidState = (flightArray, key) => flightArray.reduce((prev, current) => {
    if (!current[key]) {
      return false;
    }
    return prev;
  }, true)

  buildUrl = (newAlert) => {
    /* eslint-disable no-underscore-dangle */
    const urlBuilder = {
      carrierCode: newAlert.airline._id,
      flightNumber: flightNumberInt(newAlert.flightNumber),
      year: newAlert.searchDate.year(),
      month: newAlert.searchDate.month() + 1,
      day: newAlert.searchDate.date(),
    };
    /* eslint-enable no-underscore-dangle */
    let url = '/flight-alerts/new';
    for (const key in urlBuilder) {
      if (Object.prototype.hasOwnProperty.call(urlBuilder, key)) {
        if (urlBuilder[key] === null || urlBuilder[key] === undefined ||
          urlBuilder[key].length < 1) {
          // TODO: handle the error and break out.
          console.log('error: ', urlBuilder[key]);
          url = false;
          return false;
        }
        url += `/${urlBuilder[key]}`;
      }
    }
    return url;
  }

  sortPropsBySelectedColumn = (options) => {
    const { columnName, bool } = options;
    this.setState({
      useNewAlertProps: false,
      currentSortColumn: columnName,
      sortOrderAscending: bool,
    });
    this.props.dispatch(setSortColumnAndOrder(columnName, bool));
  }

  stateIsValid = () => {
    const update = { queryError: null };
    const { searchDate, airline, flightNumber } = this.state;

    if (!searchDate) {
      update.queryError = 'Invalid Search Date';
    } else if (!airline) {
      update.queryError = 'Please Enter an Airline';
    } else if (!flightNumber) {
      update.queryError = 'Please Enter a Flight Number';
    } else if ((flightNumber && !this.isNumeric(flightNumber))) {
      update.queryError = 'Invalid Flight Number';
    }
    if (!searchDate && !airline && !flightNumber) {
      update.queryError = 'Please Enter an Airline, Flight Number, and Departure Date';
    }

    if (update.queryError != null) { // eslint-disable-line eqeqeq
      // display errors
      const newState = Object.assign({}, this.state, update);
      this.props.dispatch(setQueryError(update.queryError));
      this.setState(newState, () => {
        const errorMessage = document && document.getElementById('flight-alert-errors');
        if (errorMessage) errorMessage.scrollIntoView();
      });
      return false;
    }
    return true;
  }

  isNumeric = flightNumber => (!isNaN(parseFloat(flightNumber)) && isFinite(flightNumber));

  render() {
    const headerDate = this.state.currentDate ? moment(this.state.currentDate).format(dateFormatString(this.props.user)) : '';
    const {
      canonical,
      meta,
      title,
    } = this.getMetaTags();

    return (
      <div style={this.props.style}>
        <section className='flight-alerts-container'>
          <Helmet
            canonical={canonical}
            meta={meta}
            title={title}
          />
          <FlightAlertsHeader date={headerDate} leftSide='Flight Alerts Console' />
        </section>
        <FlightAlertsConsole
          buttonDisabled={this.state.buttonDisabled}
          handleSubmit={this.handleSubmit}
          handleDateChange={this.dateChangeHandler}
          handleFlightNumberChange={this.handleFlightNumberChange}
          handleAirlineChange={this.handleAirlineChange}
          searchDate={this.state.searchDate}
          queryError={this.props.queryError}
          airline={this.state.airline}
          flightNumber={this.state.flightNumber}
          currentDate={this.state.currentDate}
          alertsSent={this.history.alertsSent || 0}
          flightsMonitored={this.state.flightsMonitored}
          issuesCount={this.history.issuesCount || 0}
          creditsMonthlyAllowance={this.state.creditsMonthlyAllowance}
          reserveCredits={this.state.reserveCredits}
          buyCreditsModalOpen={this.state.buyCreditsModalOpen}
          closeBuyCreditsModal={bool => this.openOrCloseBuyCreditsModal(false, bool)}
          openBuyCreditsModal={() => this.openOrCloseBuyCreditsModal(true)}
          setSecondaryActionBool={this.setSecondaryActionBool}
          secondaryAction={this.props.secondaryAction}
          user={this.props.user}
        />
        <UpcomingFlightAlerts
          alerts={this.props.flightAlerts}
          handleFakeTest={this.handleFakeTest}
          sortPropsBySelectedColumn={this.sortPropsBySelectedColumn}
          handleDeactivationReactivation={this.handleDeactivationReactivation}
          handleAlertDeletion={this.handleAlertDeletion}
          errorOnSubmit={this.state.errorOnSubmit}
          errorMessages={this.state.errorMessages}
          sortOrder={this.state.sortOrderAscending}
          currentSortColumn={this.state.currentSortColumn}
        />
      </div>
    );
  }
}
