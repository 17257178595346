import React from 'react';
import PropTypes from 'prop-types';
import { pathOr, identity } from 'ramda';
import { parse } from 'qs';
import moment from 'moment-timezone';
import { timeFormatSelect, dateFormatString } from '../../../../src/lib/date-time-format';
import FlightTrackerMultiFlightSearch from './children/FlightTrackerMultiFlightSearch/FlightTrackerMultiFlightSearch';
import TimeFilter from './children/TimeFilter/TimeFilter';
import { updateMultiFlightSearchTime, updateMultiFlightSearchDate } from '../../redux/actions';

export default class FlightTrackerMultiSearch extends React.Component {
  static propTypes = {

    byAirportAirline: PropTypes.object,
    dispatch: PropTypes.func,
    headerDate: PropTypes.string,
    isMobileScreen: PropTypes.bool,
    location: PropTypes.object,
    match: PropTypes.object,
    multiFlightSearchDate: PropTypes.number,
    multiFlightSearchTime: PropTypes.number,
    multiFlightSearchCodeShareToggle: PropTypes.bool,
    multiFlightResultsSearchError: PropTypes.string,
    multiFlightResultsAirlineSelectValue: PropTypes.string,
    resultsTopAd: PropTypes.object,
    reportUserEvent: PropTypes.func,
    secondaryAdvancedSearchActionTime: PropTypes.bool,
    user: PropTypes.object,
  };

  componentDidMount() {
    const {
      dispatch,
      location: { search: propsQueryString },
      user,
    } = this.props;

    const parsedQueryString = parse(propsQueryString, { ignoreQueryPrefix: true });
    const { hour } = parsedQueryString;
    const today = moment().hour(12);
    const backupDateParams = (date, k) => (date[k]() + (k === 'month' ? 1 : 0));
    const [year, month, day] = ['year', 'month', 'date'].map(k =>
      pathOr(backupDateParams(today, k), [k], parsedQueryString));

    if (hour) {
      dispatch(updateMultiFlightSearchTime({
        value: +hour, // gotta be a number
      }));
    } else {
      // default to 6am - 12noon
      dispatch(updateMultiFlightSearchTime(6));
    }

    // set the correct value for the date filter select
    const dateOptions = this.dateOptions(user);
    const userDatePref = dateFormatString(user);
    const momentizedParams = moment(`${month}-${day}-${year}`, 'MM-DD-YYYY');
    const value =
      dateOptions.find(option => option.label === momentizedParams.format(userDatePref)) ||
      { value: 3 };

    dispatch(updateMultiFlightSearchDate(value));
  }

  timeOfTravelOptions = [
    { value: 0, label: timeFormatSelect(this.props.user, '00:00 - 06:00', '12:00 am - 6:00 am') },
    { value: 6, label: timeFormatSelect(this.props.user, '06:00 - 12:00', '6:00 am - 12:00 pm') },
    { value: 12, label: timeFormatSelect(this.props.user, '12:00 - 18:00', '12:00 pm - 6:00 pm') },
    { value: 18, label: timeFormatSelect(this.props.user, '18:00 - 00:00', '6:00 pm - 12:00 am') },
  ];

  defaultTimeValue = () => {
    const departure = pathOr(
      'Europe/London',
      ['flights', 'header', 'departureAirport', 'timeZoneRegionName'],
      this.props,
    );
    const fallBackTimezone = pathOr(
      departure,
      ['flights', 'header', 'arrivalAirport', 'timeZoneRegionName'],
      this.props,
    );
    const fallBackHour = moment().tz(fallBackTimezone).format('h');
    const value = pathOr(
      fallBackHour,
      ['match', 'params', 'hour'],
      this.props,
    );
    const defaultTime = this.timeOfTravelOptions.find(o => value < (o.value + 6)) || {};
    return pathOr(0, ['value'], defaultTime);
  }

  createDateSelectOption = (value, momentObj, dateFormat = 'YYYY/MM/DD') => (
    {
      value,
      label: momentObj.format(dateFormat),
    }
  );

  dateOptions = (user) => {
    const userDatePref = dateFormatString(user);

    const currentDate = moment.utc();
    return (
      [
        this.createDateSelectOption(0, currentDate.clone().subtract(3, 'days'), userDatePref),
        this.createDateSelectOption(1, currentDate.clone().subtract(2, 'days'), userDatePref),
        this.createDateSelectOption(2, currentDate.clone().subtract(1, 'days'), userDatePref),
        this.createDateSelectOption(3, currentDate.clone(), userDatePref),
        this.createDateSelectOption(4, currentDate.clone().add(1, 'days'), userDatePref),
        this.createDateSelectOption(5, currentDate.clone().add(2, 'days'), userDatePref),
        this.createDateSelectOption(6, currentDate.clone().add(3, 'days'), userDatePref),
      ]
    );
  };

  timeValue = time => ((time || time === 0) ? time : this.defaultTimeValue())

  renderRefineSearch = () => {
    const {
      byAirportAirline,
      dispatch,
      headerDate,
      isMobileScreen,
      location: { search: queryString },
      match,
      multiFlightSearchDate,
      multiFlightSearchTime,
      multiFlightSearchCodeShareToggle,
      multiFlightResultsSearchError,
      multiFlightResultsAirlineSelectValue,
      reportUserEvent,
      resultsTopAd,
      secondaryAdvancedSearchActionTime,
      user,
    } = this.props;
    const dates = this.dateOptions(user);
    return (
      <div>
        <FlightTrackerMultiFlightSearch
          byAirportAirline={byAirportAirline}
          dates={dates}
          dispatch={dispatch}
          isMobileScreen={isMobileScreen}
          match={match}
          multiFlightSearchDate={multiFlightSearchDate}
          multiFlightSearchTime={multiFlightSearchTime}
          multiFlightSearchCodeShareToggle={multiFlightSearchCodeShareToggle}
          multiFlightResultsSearchError={multiFlightResultsSearchError}
          multiFlightResultsAirlineSelectValue={multiFlightResultsAirlineSelectValue}
          queryString={queryString}
          reportUserEvent={reportUserEvent}
          resultsTopAd={resultsTopAd}
          timeOfTravelOptions={this.timeOfTravelOptions}
          user={user}
        />
        <TimeFilter
          dispatch={dispatch}
          headerDate={headerDate}
          match={match}
          secondaryAction={identity}
          secondaryAdvancedSearchActionTime={secondaryAdvancedSearchActionTime}
          timeOfTravelOptions={this.timeOfTravelOptions}
          multiFlightSearchTime={multiFlightSearchTime}
          multiFlightResultsSearchError={multiFlightResultsSearchError}
          queryString={queryString}
          reportUserEvent={reportUserEvent}
          user={user}
        />
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderRefineSearch()}
      </div>
    );
  }
}
