import React from 'react';
import L, { tileLayer } from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import componentBase from '../../lib/component-base';

// fix for https://github.com/Leaflet/Leaflet/issues/4968
const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

@componentBase('SatTileLayer')
export default class SatTileLayer extends React.Component {
  constructor(props, context) {
    super(props);

    this.map = context.map;
  }

  componentDidMount() {
    const satTileRoute = window.devicePixelRatio > 1 ? 'https://tiles-s3.flightstats.com/satellite/{z}/{x}/{y}@2x.png' : 'https://tiles-s3.flightstats.com/satellite/{z}/{x}/{y}.png';
    const satLayer = tileLayer(satTileRoute, {
      name: 'satellite',
      subdomains: '1234',
      zIndex: 2,
      minZoom: 2,
      maxZoom: 6,
      attribution: "<a id='attr-link' className='' alt='link to attributions list' href='/map-attributions'>Map licensing</a>",
    });

    const streetTileRoute = window.devicePixelRatio > 1 ? 'https://tiles-s3.flightstats.com/street/{z}/{x}/{y}@2x.png' : 'https://tiles-s3.flightstats.com/street/{z}/{x}/{y}.png';
    const streetLayer = tileLayer(streetTileRoute, {
      name: 'street',
      subdomains: '1234',
      zIndex: 1,
      minZoom: 7,
      maxZoom: 10,
    });

    this.map.addLayer(satLayer);
    this.map.addLayer(streetLayer);
  }

  render() {
    return (<div />);
  }
}
